import { thunkCreator } from '@src/utils'
import * as models from './models'


export const [
  loadIntegrationList, loadIntegrationListExtra,
] = thunkCreator(models.ANALYTICS_INTEGRATION_LIST, '/analytics/rvimis_reports')

export const [
  loadIntegrationFilterMo, loadIntegrationFilterMoExtra,
] = thunkCreator(models.ANALYTICS_INTEGRATION_FILTER_MO, '/analytics/rvimis_reports_filter_mo', { extractor: d => d })

export const [
  loadIntegrationFilterExtSystems, loadIntegrationFilterExtSystemsExtra,
] = thunkCreator(models.ANALYTICS_INTEGRATION_FILTER_EXT_SYSTEMS, '/analytics/rvimis_reports_filter_ext_systems', { extractor: d => d })

export const [
  loadIntegrationFilterEhr, loadIntegrationFilterEhrExtra,
] = thunkCreator(models.ANALYTICS_INTEGRATION_FILTER_EHR, '/analytics/rvimis_reports_filter_ehr', { extractor: d => d })

export const [
  loadIntegrationFilterDeparts, loadIntegrationFilterDepartsExtra,
] = thunkCreator(models.ANALYTICS_INTEGRATION_FILTER_DEPARTS, '/analytics/rvimis_reports_filter_departs', { extractor: d => d })

const extraReducers = {
  ...loadIntegrationListExtra,
  ...loadIntegrationFilterMoExtra,
  ...loadIntegrationFilterExtSystemsExtra,
  ...loadIntegrationFilterEhrExtra,
  ...loadIntegrationFilterDepartsExtra,
}

export default extraReducers
