import { useContext } from 'react'
import { Combobox, Icon, IfNotReadonly } from '@src/components'
import { AdditionalCondition } from '@src/modules/methodology/pages/MethRegConditionsPage/Conditions/AdditionalCondition'
import { TerritorialCondition } from '@src/modules/methodology/pages/MethRegConditionsPage/Conditions/TerritorialCondition'
import { AccessContext } from '@src/context'
import { TimeCondition } from './TimeCondition'
import './Conditions.scss'

export const Conditions = ({
  condition, foundationOptions, onConditionUpdate, onAddTerritoryBlock,
  onClearAddCondition, canClearAddCondition,
  onClearTimeCondition, canClearTimeCondition,
}) => {
  const accessContext = useContext(AccessContext)

  return <div className='root-reg-conditions-body scroll-y'>
    <div className='foundation-npa'>
      <span className='foundation-npa-title'>Документ—основание</span>
      <Combobox
        className='foundation-npa-select'
        options={foundationOptions}
        value={condition.documentId}
        disabled={accessContext.readonly}
        onChange={documentId => onConditionUpdate({ documentId })}
      />
    </div>
    {
      condition.moTerritoryList?.map((territory, numberTerritory) => (
        <TerritorialCondition
          key={territory.uuid}
          territory={territory}
          numberTerritory={numberTerritory + 1}
          onChangeMoTerritory={moTerritoryList => onConditionUpdate({ moTerritoryList })}
          moTerritoryList={condition.moTerritoryList}
        />))
    }
    <IfNotReadonly>
      <NewTerritorialCondition onAddTerritoryBlock={onAddTerritoryBlock} />
    </IfNotReadonly>
    <AdditionalCondition
      condition={condition.addCondition}
      onConditionChange={addCondition => onConditionUpdate({ addCondition })}
      onClearAddCondition={onClearAddCondition}
      canClearAddCondition={canClearAddCondition}
    />
    <TimeCondition
      timeCondition={condition.timeCondition}
      onTimeConditionChange={timeCondition => onConditionUpdate({ timeCondition })}
      onClearTimeCondition={onClearTimeCondition}
      canClearTimeCondition={canClearTimeCondition}
    />
  </div>
}

const NewTerritorialCondition = ({ onAddTerritoryBlock }) => {
  return <div className='root-new-territorial-condition' onClick={onAddTerritoryBlock}>
    <Icon icon='plus_cr_fr'/>
    <span>Добавить новое территориальное условие</span>
  </div>
}
