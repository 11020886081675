import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { isString } from 'lodash'
import { ErrorText, LinearProgress, EmptyState, Pagination } from '@src/components'
import { DEFAULT_PAGE_SIZE, DEFAULT_ROW_HEIGHT, SORT_ORDER } from '@src/constants'
import { NOOP_FN, cn } from '@src/utils'
import { useDebounce } from '@src/hooks'
import { DataGridHeader } from './DataGridHeader'
import { DataGridBody } from './DataGridBody'
import { useResizeObserver, useRowKey } from './hooks'
import './DataGrid.scss'

export const DataGrid = ({ columns, data, value, onChange, onReset, selectable, rowKey, onSelectionChange,
  pagination, paginationTotal, onPaginationChange, variableSizeList, rowHeight = DEFAULT_ROW_HEIGHT, ...props }) => {
  const rootRef = useRef()
  const headerRef = useRef()
  const listOuterRef = useRef()
  const [listHeight, setListHeight] = useState(0)
  const [resize, onResize] = useState(false)
  const [scrollbarWidth, setScrollbarWidth] = useState(0)
  const debouncedResize = useDebounce(() => onResize(p => !p), 500)

  useEffect(() => {
    rootRef?.current?.addEventListener('resize', debouncedResize)

    return rootRef?.current?.removeEventListener('resize', debouncedResize)
  }, [debouncedResize, rootRef])

  const initialFilters = useMemo(() => {
    return Object.entries(columns).reduce((acc, [key, column]) => {
      return {
        ...acc,
        [key]: column.header.defaultValue,
      }
    }, {})
  }, [columns])

  const initialSort = useMemo(() => {
    const defaultSortColumnKey = Object.keys(columns)
      .find(key => columns[key].defaultSort)

    return {
      column: isString(columns?.[defaultSortColumnKey]?.sort) ? columns[defaultSortColumnKey].sort :  defaultSortColumnKey,
      order: columns?.[defaultSortColumnKey]?.defaultSort,
    }
  }, [columns])

  const reset = useCallback(() => {
    const newValue = {
      filters: initialFilters,
      sort: initialSort,
      nullSearches: {},
      isInvalid: false,
      isDirty: false,
      selectedCheckboxes: selectable && {},
      pagination: pagination && {
        currentPage: 1,
        pageSize: DEFAULT_PAGE_SIZE,
      },
    }

    onChange(newValue)
    onReset(newValue)
  }, [initialFilters, initialSort, onChange, onReset, pagination, selectable])

  useEffect(() => {
    if(!value) reset()
  }, [value, reset])

  const handleResize = useCallback(() => {
    setListHeight(
      rootRef?.current?.offsetHeight && headerRef?.current?.offsetHeight
        ? rootRef.current.offsetHeight - headerRef.current.offsetHeight - 32
        : 0
    )
    setScrollbarWidth(listOuterRef?.current?.offsetWidth - listOuterRef?.current?.clientWidth || 0)
  }, [rootRef, headerRef])

  useEffect(() => {
    setScrollbarWidth(listOuterRef?.current?.offsetWidth - listOuterRef?.current?.clientWidth || 0)
  }, [data])

  useResizeObserver(rootRef, handleResize)

  const [getKeyForRowIndex] = useRowKey(data, rowKey)

  const virtualListData = useMemo(() => {
    const listData = selectable
      ? data.map((row, index) => ({ ...row, __checkbox: value?.selectedCheckboxes[getKeyForRowIndex(index)] }))
      : data

    // бэк может возвращать лишние записи, обрезаем по размеру страницы, если включена пагинация для консистентности UI
    return pagination
      ? listData.slice(0, value?.pagination?.pageSize || DEFAULT_PAGE_SIZE)
      : listData
  }, [selectable, data, pagination, value, getKeyForRowIndex])

  const handleSelectionChange = useCallback(() => {
    // неконсистентное поведение
    onSelectionChange(data.filter((e, i) => value.selectedCheckboxes[i]))
  }, [data, value, onSelectionChange])

  const handlePageChange = useCallback((currentPage) => {
    const newValue = {
      ...value,
      pagination: {
        ...value.pagination,
        currentPage,
      },
    }

    onChange(newValue)
    onPaginationChange(newValue)
  }, [onChange, onPaginationChange, value])

  const handlePageSizeChange = useCallback((pageSize) => {
    const newValue = {
      ...value,
      pagination: {
        ...value.pagination,
        currentPage: 1,
        pageSize,
      },
    }

    onChange(newValue)
    onPaginationChange(newValue)
  }, [onChange, onPaginationChange, value])

  const templateColumnsWidth = useMemo(() => {
    /**
     * @const totalWidth - Сумма ширины всех включенных столбцов
     * @const columnsKeysWidth - Array<string> значений всех включенных столбцов + addColKeyWidth
     * @const defaultCheckboxColWidth - Ширина столбца с чекбоксами по умолчанию
     * @const windowViewportWidth - Ширина window viewport минус ширина сайдбара
     * @const addColKeyWidth - Размер добавляемый к ширине столбцов, если ширина
     * всех включенных столбцов меньше, чем windowViwportWidth
     * @const styleWidth - Ширина таблицы
     * Если ширина всех включенных столбцов меньше, чем windowViwportWidth
     * То ширина таблицы равна windowViwportWidth, иначе она равна totalWidth
     */
    const defaultCheckboxColWidth = 40
    const sidebarWidth = 48
    const totalWidth = (selectable ? defaultCheckboxColWidth : 0) + Object.values(columns).reduce((acc, col) => acc + col.width, 0) + scrollbarWidth
    const windowViwportWidth = rootRef?.current?.clientWidth || window.innerWidth - sidebarWidth
    const styleWidth = windowViwportWidth > totalWidth ? windowViwportWidth : totalWidth
    const addColKeyWidth = styleWidth > totalWidth ? Math.round((styleWidth - totalWidth) / Object.keys(columns).length) : 0
    const columnsKeysWidth = [
      ...(selectable ? [defaultCheckboxColWidth] : []),
      ...Object.values(columns).map(key  => key.width + addColKeyWidth),
      ...(scrollbarWidth ? [scrollbarWidth] : []),
    ]

    return { scrollbarWidth, styleWidth, columnsKeysWidth }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectable, columns, resize, scrollbarWidth, data])

  const getHeight = useMemo(() => {
    const height = variableSizeList
      ?  Math.min(listHeight, virtualListData.length ? virtualListData?.reduce((acc, cur, curIndex) => acc + variableSizeList.itemSize(curIndex), 0) : 0)
      : Math.min(listHeight, virtualListData.length * rowHeight)
    return height
  }, [variableSizeList, virtualListData, listHeight, rowHeight])

  return (
    <div
      style={{
        ...{ maxWidth: templateColumnsWidth?.styleWidth + 4 },
        ...props.style,
      }}
      ref={rootRef}
      className={cn('root-data-grid scroll-x', {
        'grid-table-scrollbar': pagination && scrollbarWidth,
        'data-grid-pagination': pagination,
      }, props.className)}
    >
      <LinearProgress className='data-grid-loader' hidden={!props.loading} />
      <DataGridHeader
        ref={headerRef}
        columns={columns}
        data={data}
        value={value}
        onChange={onChange}
        onSelectionChange={handleSelectionChange}
        selectable={selectable}
        initialFilters={initialFilters}
        initialSort={initialSort}
        rowKey={rowKey}
        pagination={pagination}
        templateColumnsWidth={templateColumnsWidth}
        {...props}
      />
      {
        props.error
          ? <ErrorText className='pa-8'>{props.error}</ErrorText>
          : !props.loading && !virtualListData.length
            ? <EmptyState {...props.emptyStateProps}/>
            : <DataGridBody
              ref={listOuterRef}
              value={value}
              columns={columns}
              data={virtualListData}
              selectable={selectable}
              onChange={onChange}
              onSelectionChange={handleSelectionChange}
              height={getHeight}
              rowHeight={rowHeight}
              rowKey={rowKey}
              templateColumnsWidth={templateColumnsWidth}
              variableSizeList={variableSizeList}
              {...props}
            />
      }
      {
        pagination && value?.pagination
          ? <Pagination
            shown={virtualListData.length}
            total={paginationTotal}
            pageSize={value.pagination.pageSize}
            currentPage={value.pagination.currentPage}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
          : null
      }
    </div>
  )
}

DataGrid.propTypes = {
  columns: PropTypes.objectOf(PropTypes.shape({
    header: PropTypes.oneOfType([             // Заголовок столбца. Может быть строкой, а может быть компонентом колоночного поиска
      PropTypes.string,
      PropTypes.shape({
        title: PropTypes.string.isRequired,   // Заголовок столбца
        component: PropTypes.func.isRequired, // Input-компонент фильтра
        props: PropTypes.shape({              // props для component
          isInvalid: PropTypes.func,          // Функция, вычисляющая валидное значение (результат отображается в value.isInvalid)
        }),
        defaultValue: PropTypes.any,          // Значение по-умолчанию для инпута
      }),
    ]).isRequired,

    width: PropTypes.number.isRequired,       // Относительная ширина колонки
    dataIndex: PropTypes.string.isRequired,   // поле из data, используемое для сопоставления данных с колонкой
    dataRender: PropTypes.func,               // render функция для ячейки строки. dataRender(data[dataIndex], row). Если не задана, используется column[dataIndex]
    titleRender: PropTypes.func,              // render функция для тултипа ячейки. Если не задана, используется результат dataRender
    sort: PropTypes.oneOfType([               // Сортировка столбца. true для отображения сортировки на столбце. Если передана строка, то
      PropTypes.bool,                         // value.sort.column для этого столбца будет возвращать указанную строку
      PropTypes.string,
    ]),
    nullSearch: PropTypes.bool,               // Поиск пустых значений. true для отображения иконки поиска пустых значений на столбце
    defaultSort: PropTypes.oneOf([
      SORT_ORDER.ASC, SORT_ORDER.DESC,        // Если задано, данный столбец будет выбран сортировкой по-умолчанию
    ]),
    cellClassName: PropTypes.oneOfType([      // Дополнительный класс для ячейки строки.
      PropTypes.string,                       // Может быть строкой
      PropTypes.func,                         // либо функцией, которая принимает row
    ]),
  })).isRequired,

  data: PropTypes.arrayOf(PropTypes.object),  // Массив данных, ключ объекта из элемента массива сопоставляется со столбцом через dataIndex
  rowHeight: PropTypes.number,                // высота строки таблицы
  rowKey: PropTypes.oneOfType([               // react key для строки таблицы
    PropTypes.string,                         // может быть строкой, тогда принимает название поля из data
    PropTypes.func,                           // может быть функцией, тогда rowKey(index, data)
  ]).isRequired,

  selectable: PropTypes.bool,                 // Включает столбец с чекбоксами

  pagination: PropTypes.bool,                 // true, если задан, то будет отображен блок пагинации
  paginationTotal: PropTypes.number,          // Всего записей в таблице

  variableSizeList: PropTypes.shape({
    itemSize: PropTypes.func,
  }),

  value: PropTypes.shape({                    // Объект значений контроллируемого компонента. Если передан null, то инициализируется автоматически
    filters: PropTypes.object,
    sort: PropTypes.shape({
      column: PropTypes.string,
      order: PropTypes.oneOf(
        [SORT_ORDER.ASC, SORT_ORDER.DESC]
      ),
    }),
    pagination: PropTypes.shape({
      currentPage: PropTypes.number,          // текущая страница в пагинации
      pageSize: PropTypes.number,             // кол-во показываемых строк в пагинации
    }),
    isDirty: PropTypes.bool,                  // true, если фильтры или сортировки не соответствуют исходным
    isInvalid: PropTypes.bool,                // true, если хотя бы один из фильтров содержит невалидное значение
    selectedCheckboxes: PropTypes.object,     // Объект вида { [row[rowKey]]: boolean }, true для отмеченных строк.
    // Строки остаются отмеченными при изменениях фильтров, сбрасываются при сбросе фильтров
  }),

  onChange: PropTypes.func,                   // Обработчик onChange контролируемого компонента

  onReset: PropTypes.func,                    // callback, вызывается при сбросе value, в т.ч. при первоначальном рендере. Принимает параметром (value)
  onFilterChange: PropTypes.func,             // callback, вызывается при изменении фильтров. Принимает параметром (value)
  onSortChange: PropTypes.func,               // callback, вызывается при изменении сортировок. Принимает параметром (value)
  onScrolledToBottom: PropTypes.func,         // callback, вызывается при скроле до дна таблицы. Принимает параметром (value, rowCount), rowCount - количество строк в таблице
  onRowAuxClick: PropTypes.func,              // callback, на  клик по строке таблицы колесиком. Принимает параметром объект нажатой строки
  onRowDoubleClick: PropTypes.func,           // callback, на двойной клик по строке таблицы. Принимает параметром объект нажатой строки
  onRowClick: PropTypes.func,                 // callback, на клик по строке таблицы. Принимает параметром объект нажатой строки
  onDirtyChange: PropTypes.func,              // callback, вызывается при изменении фалага isDirty. isDirty = true, когда фильтры или сортировки не соответствуют изначальным. onDirtyChange(isDirty)
  onSelectionChange: PropTypes.func,          // callback, вызывается при изменении состояния чекбоксов. Принимает массив обьектов выбранных строк
  onPaginationChange: PropTypes.func,         // callback, вызывается при изменении пагинации. Принимает параметром value
  onNullSearchChange: PropTypes.func,         // callback, вызывается при изменении фильтра пустых значений. Принимает параметром value

  emptyStateProps: PropTypes.object,          // props для EmptyState

  loading: PropTypes.bool,
  error: PropTypes.string,

  style: PropTypes.object,
  className: PropTypes.string,
}

DataGrid.defaultProps = {
  onScrolledToBottom: NOOP_FN,
  onRowDoubleClick: NOOP_FN,
  onRowAuxClick: NOOP_FN,
  onRowClick: NOOP_FN,
  onDirtyChange: NOOP_FN,
  onSelectionChange: NOOP_FN,
  onPaginationChange: NOOP_FN,
  onFilterChange: NOOP_FN,
  onSortChange: NOOP_FN,
  onChange: NOOP_FN,
  onReset: NOOP_FN,
  onNullSearchChange: NOOP_FN,
  data: [],
  emptyStateProps: {
    caption: 'Нет ни одной записи',
    description: 'Попробуйте задать другие параметры поиска',
  },
}
