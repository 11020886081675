import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useMemo } from 'react'
import { Card, LinkWithAudit } from '@src/components'
import { loadPatientStatusOcc, loadPatientStatusAkineoMother, selectPatientNeonatalScreening, selectPatientStatusChild,
  selectPatientInfoModel, selectPatientStatusModel, loadPatientNeonatalScreening, loadPatientStatusChild } from '@src/modules/monitoring/store'
import { AUDIT_PS, URLS } from '@src/constants'
import { useMountEffect } from '@src/hooks'
import { TabStatusBase } from '@src/modules/monitoring/components'
import { withMntAudit } from '@src/hoc'
import { getFullname } from '@src/utils'
import { usePageAppSettings } from '@src/hooks/useAppSettings'

const TabStatus = ({ occasionPatientId, patientId, ...props }) => {
  const dispatch = useDispatch()

  const patientStatusModel = useSelector(selectPatientStatusModel)
  const patientInfoModel = useSelector(selectPatientInfoModel)
  const neonatalScreening = useSelector(selectPatientNeonatalScreening)
  const patientStatusChildModel = useSelector(selectPatientStatusChild)

  const status = patientStatusModel.payload?.data
  const info = patientInfoModel.payload?.data
  const screening = neonatalScreening.payload?.data?.[0]
  const patientStatusChild = patientStatusChildModel.payload?.data

  useMountEffect(() => {
    dispatch(loadPatientStatusOcc({
      occasion_patient_id: occasionPatientId,
      list_type: '25',
      patient_id: patientId,
    }))
    dispatch(loadPatientStatusAkineoMother({ occasion_patient_id: occasionPatientId }))
    dispatch(loadPatientNeonatalScreening({ occasion_patient_id: occasionPatientId }))
    dispatch(loadPatientStatusChild({ occasion_patient_id: occasionPatientId }))
  })

  const pageAppSettings = usePageAppSettings()

  const widgetsAppSettings = useMemo(() => {
    return pageAppSettings?.tabs?.status?.widgets || {}
  }, [pageAppSettings?.tabs?.status?.widgets])

  const widgetsWithAppSettingsGrouped = useMemo(() => {
    return [
      {
        key: 'newborn',
        group: 0,
        title: 'Новорожденный',
        loading: patientStatusModel.loading || patientInfoModel.loading,
        error: patientStatusModel.error || patientInfoModel.error,
        data: info && status && [
          {
            key: 'mother_fullname',
            title: 'ФИО матери',
            data: getFullname(info) ? getFullname(info) : '-',
          },
          {
            key: 'child_lastname',
            title: 'Фамилия новорожденного',
            data: info.child_lastname ? info.child_lastname : '-',
          },
          {
            key: 'gender',
            title: 'Пол',
            data: info?.gender ?? '-',
          },
          {
            key: 'birth_date',
            title: 'Дата рождения',
            data: status?.birth_date ? moment.utc(status.birth_date).format('DD.MM.YYYY') : '-',
          },
          {
            key: 'birthweight',
            title: 'Масса тела при рождении (грамм)',
            data: status?.birthweight ?? '-',
          },
          {
            key: 'newborn_length',
            title: 'Длина тела при рождении (см)',
            data: status?.newborn_length ?? '-',
          },
          {
            key: 'med_birth_certificate',
            title: 'Номер МСР',
            data: `${status?.med_birth_certificate_series ?? ''} ${status?.med_birth_certificate_number ?? ''}` || '-',
          },
          {
            key: 'med_birth_certificate_date',
            title: 'Дата выдачи МСР',
            data: status?.med_birth_certificate_date ? moment(status.med_birth_certificate_date).format('DD.MM.YYYY') : '-',
          },
        ],
      },
      {
        key: 'mother',
        group: 1,
        title: 'Данные о матери',
        loading: patientStatusModel.loading,
        error: patientStatusModel.error,
        data: status && [
          {
            key: 'mother_fullname',
            title: 'Фамилия Имя Отчество',
            data: () => getFullname(info)
              ? <LinkWithAudit
                to={`${URLS.MONITORING__AKINEO__PREGNANT}/patient/${status.mother_patient_id}/${status.mother_occasion_patient_id}/status`}
                auditPs={AUDIT_PS.PATIENTMON}
                auditMessage='Просмотр карточки матери'
              >{getFullname(info)}</LinkWithAudit>
              : '-',
          },
          {
            key: 'mother_birthdate',
            title: 'Дата рождения',
            data: status.mother_birthdate ? moment(status.mother_birthdate).format('DD.MM.YYYY') : '-',
          },
        ],
      },
      {
        key: 'diagnosis',
        group: 1,
        title: 'Диагноз',
        loading: patientStatusModel.loading,
        error: patientStatusModel.error,
        data: status && [
          {
            key: 'diagnosis',
            title: 'Основной диагноз',
            data: status.mkb_code || status.mkb_name
              ? `${status.mkb_code} ${status.mkb_name}`
              : '-',
          },
        ],
      },
      {
        key: 'screening',
        group: 1,
        title: 'Результаты скрининга',
        loading: patientStatusModel.loading,
        error: patientStatusModel.error,
        data: status && screening && patientStatusChild && [
          {
            key: 'biopsy_sampling_time',
            title: 'Дата и время взятия крови',
            data: status.biopsy_sampling_time ? moment.utc(status.biopsy_sampling_time).format('DD.MM.YYYY HH:mm:ss') : '-',
          },
          {
            key: 'ns_vnz_existence',
            title: 'Наличие патологии на скрининге (НС)',
            data: typeof patientStatusChild.ns_vnz_existence === 'boolean' ? patientStatusChild.ns_vnz_existence ? 'Да' : 'Нет' : '-',
          },
          {
            key: 'ns_vnz_pathology',
            title: 'Результат скрининга на НС',
            data: patientStatusChild.ns_vnz_pathology ?? '-',
          },
          {
            key: 'ns_vnz_date',
            title: 'Дата результата скрининга на НС',
            data: patientStatusChild.ns_vnz_date ? moment(patientStatusChild.ns_vnz_date).format('DD.MM.YYYY') : '-',
          },
          {
            key: 'rns_vnz_existence',
            title: 'Наличие патологии на скрининге (РНС)',
            data: typeof patientStatusChild.rns_vnz_existence === 'boolean' ? patientStatusChild.rns_vnz_existence ? 'Да' : 'Нет' : '-',
          },
          {
            key: 'rns_vnz_pathology',
            title: 'Результат скрининга на РНС',
            data: patientStatusChild.rns_vnz_pathology ?? '-',
          },
          {
            key: 'rns_vnz_date',
            title: 'Дата результата скрининга на РНС',
            data: patientStatusChild.rns_vnz_date ? moment(patientStatusChild.rns_vnz_date).format('DD.MM.YYYY') : '-',
          },
          {
            key: 'tms_lab_mo_name',
            title: 'Название лаборатории ТМС',
            data: screening.tms_lab_mo_name ?? '-',
          },
          {
            key: 'tms_result_date',
            title: 'Дата результата ТМС',
            data: screening.tms_result_date ? moment(screening.tms_result_date).format('DD.MM.YYYY') : '-',
          },
          {
            key: 'tms_result',
            title: 'Результат ТМС',
            data: screening.tms_result ?? '-',
          },
          {
            key: 'mgi_lab_mo_name',
            title: 'Название лаборатории МГИ',
            data: screening.mgi_lab_mo_name ?? '-',
          },
          {
            key: 'mgi_result_date',
            title: 'Дата результата МГИ',
            data: screening.mgi_result_date ? moment(screening.mgi_result_date).format('DD.MM.YYYY') : '-',
          },
          {
            key: 'mgi_result',
            title: 'Результат МГИ',
            data: screening.mgi_result ?? '-',
          },
        ],
      },
    ]
      // Фильтруем отключенные в настройках виджеты
      .filter(widget => !widgetsAppSettings?.[widget.key]?.disabled)
      // Фильтруем отключенные в настройках поля виджетов
      .reduce((acc, widget) => [
        ...acc,
        {
          ...widget,
          data: widget.data?.filter(field => !widgetsAppSettings?.[widget.key]?.fields?.[field.key]?.disabled),
        },
      ], [])
      // Группируем виджеты в колонки для отображения
      .reduce((acc, widget) => {
        const index = widget.group

        return [
          ...acc.slice(0, index),
          acc[index] ? [...acc[index], widget]: [widget],
          ...acc.slice(index + 1),
        ]
      }, [])
  }, [info, patientInfoModel.error, patientInfoModel.loading, patientStatusChild, patientStatusModel.error, patientStatusModel.loading, screening, status, widgetsAppSettings])

  return (
    <TabStatusBase {...props}>
      {
        widgetsWithAppSettingsGrouped.map((group, i) => <div
          key={i}
          style={{ flexBasis: '50%' }}
          className={i !== widgetsWithAppSettingsGrouped.length - 1 ? 'mr-16' : ''}
        >
          {
            group.map(widget => <Card
              key={widget.key}
              title={widget.title}
              loading={widget.loading}
              error={widget.error}
              className='mb-16'
              data={widget.data}
            />)
          }
        </div>)
      }
    </TabStatusBase>
  )
}

export const TabStatusWithAudit = withMntAudit(TabStatus)

TabStatusWithAudit.tabTitle = 'Статус'
TabStatusWithAudit.tabPath = 'status'
