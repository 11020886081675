import { selectDictMoModel } from '@src/modules/dictionaries/store'
import { Combobox } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class MoNameColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.MO_NAME

  constructor (store, settings) {
    const column = {
      header: {
        title: 'МО прикрепления',
        component: Combobox,
        props: {
          allowClear: true,
          mode: 'multiple',
          options: selectDictMoModel(store.getState()).payload.data.map(mo => ({
            label: mo.name_short,
            value: mo.mo_id,
            title: mo.name_full,
          })),
        },
        defaultValue: [],
      },
      width: 300,
      dataIndex: 'mo_name',
      dataRender: mo_name => mo_name ? mo_name : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    return {
      control: {
        filters: nullSearches.mo_name || (filters?.mo_name && filters?.mo_name.length)
          ? [{
            field: 'mo_oid',
            value: nullSearches.mo_name ? null : filters.mo_name,
            operator: '=',
            invalid: false,
            cast: 'right-any',
          }]
          : [],
      },
    }
  }
}
