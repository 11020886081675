import cn from 'classnames'
import PropTypes from 'prop-types'
import { Component, Fragment } from 'react'
import { withClickAudit } from '@src/hoc'
import { Icon, Loader } from '@src/components'
import './Button.scss'

/**
 * @class Button
 * @param loading {bool} Displays the inscription loading and transfers the button to disabled mode
 * @param label {string} The label displayed in the button
 * @param icon {string} The name for the SVG is the icon displayed inside the button. For possible options see in @src/assets
 * @param iconRight {bool} Display the icon to the right of the text (by default, to the left)
 * @param className {string} Adds a property className to root-button
 * @param title {string} Tooltip text on hover
 * @param onClick {func} Pointer to the function that will be called every time the click event is triggered on the button
 * @param disabled {bool} Disables the button. Prevents an click event on the button, hover effects and specific styles
 * @param isSecondary {bool} Red button
 * @param isSuccess {bool} Greeb buton
 * @param isIconButton {bool} Specific styles for buttons that only display an image (no text)
 * @param isIconSmall {bool} Reduced maximum dimensions for a button
 */
export default class Button extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    label: PropTypes.string,
    icon: PropTypes.string,
    iconRight: PropTypes.bool,
    className: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    isSecondary: PropTypes.bool,
    isSuccess: PropTypes.bool,
    isIconButton: PropTypes.bool,
    isIconSmall: PropTypes.bool,
    cornersRight: PropTypes.bool,
    cornersLeft: PropTypes.bool,
    counter: PropTypes.number,
  }

  componentDidMount () {
    if(this.props.iconRight && !this.props.icon)
      throw SyntaxError(`Pending a name for the icon. But received ${typeof this.props.icon}`)
    if(this.props.isIconButton && this.props.label)
      throw SyntaxError('Using parameter isIconButton implies the absence of parameter label')
    if(this.props.isSuccess && this.props.isSecondary)
      throw SyntaxError('You cannot use parameters isSuccess and isSecondary together')
  }

  handleClick = SyntheticEvent => !this.props.disabled && !this.props.loading && this.props.onClick && this.props.onClick(SyntheticEvent)

  render () {
    const { label, icon, className, disabled, loading, iconRight, isSecondary, isSuccess, isIconButton, title, isIconSmall, counter, cornersRight, cornersLeft } = this.props

    return (
      <div
        onClick={this.handleClick}
        title={title}
        className={cn(
          isSuccess && 'success',
          isSecondary && 'secondary',
          disabled && 'disabled',
          isIconButton && 'icon-button',
          isIconSmall && 'icon-small',
          loading && 'disabled loading',
          cornersRight && 'corners-right',
          cornersLeft && 'corners-left',
          'root-button',
          className)}
      >
        {
          loading
            ? <><span className='button-loading-text'>{label}</span> <Loader className='root-buttom-loader' /></>
            : !isIconButton
              ? <Fragment>
                {
                  icon && !iconRight
                    ? <Icon className='button-icon-left' icon={icon} alt='' />
                    : null
                }
                <div className='button-label'>{label}</div>
                {
                  typeof(counter) === 'number' || counter
                    ? <span className='button-counter'>{counter}</span>
                    : null
                }
                {
                  icon && iconRight
                    ? <Icon className='button-icon-right' icon={icon} alt='' />
                    : null
                }
              </Fragment>
              : <Icon className='button-icon' icon={icon} alt='' />
        }
      </div>
    )
  }
}
export const ButtonWithAudit = withClickAudit(Button)
