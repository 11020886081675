import { isArray, isNil } from 'lodash'
import moment from 'moment'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Combobox, DataGrid, DateRangePicker, DocStatusTag, Tag, TextInput } from '@src/components'
import { SORT_ORDER, TAG_COLORS, URLS } from '@src/constants'
import {
  loadCrList, loadCrListCount, selectCrListCountModel, selectCrListModel, selectDocProfilesModel,
} from '@src/modules/methodology/store'
import { Mkb10InputWithState } from '@src/modules/monitoring/components/Mkb10Input/Mkb10InputWithState'
import { isValidMomentDate } from '@src/utils'
import { useDebounce } from '@src/hooks'
import { UpdateTimestamp } from '../UpdateTimestamp/UpdateTimestamp'
import { MethTabs } from '../MethTabs/MethTabs'
import './ClinRecTable.scss'

const CHUNK_SIZE = 100

export const ClinRecTable = () => {
  const [gridValue, setGridValue] = useState(null)

  const clinrecListModel = useSelector(selectCrListModel)
  const docProfilesModel = useSelector(selectDocProfilesModel)
  const clinrecListCountModel = useSelector(selectCrListCountModel)

  const history = useHistory()
  const dispatch = useDispatch()

  const listFilters = (value) => {
    return [
      ...(!isNil(value?.filters?.document_status)
        ? [{ field: 'document_status', value: value?.filters?.document_status, operator: '=', invalid: false }] : []),
      ...(!isNil(value?.filters?.number)
        ? [{ field: 'number', value: value?.filters?.number, operator: 'like_both' }] : []),
      ...(!isNil(value?.filters?.name_full)
        ? [{ field: 'name_full', value: value?.filters?.name_full, operator: 'like_both' }] : []),
      ...(!isNil(value?.filters?.mkb)
        ? [{ field: 'mkb', value: Object.entries(value?.filters?.mkb).map(d => d[1].ref_item_code).join(' '), operator: 'like_both', invalid: false }] : []),
      ...(!isNil(value?.filters?.age_group)
        ? [{ field: 'age_group_caption', value: value?.filters?.age_group, operator: '=' }] : []),
      ...(!isNil(value?.filters?.profile_caption)
        ? [{ field: 'profile_caption', value: value?.filters?.profile_caption, operator: '=', invalid: false }] : []),
      ...(!isNil(value?.filters?.revision_effective_date?.[0])
        ? [{ field: 'revision_effective_date', value: moment(value?.filters?.revision_effective_date?.[0]).local().toISOString(true), operator: '>=', invalid: false }] : []),
      ...(!isNil(value?.filters?.revision_effective_date?.[1])
        ? [{ field: 'revision_effective_date', value: moment(value?.filters?.revision_effective_date?.[1]).local().toISOString(true), operator: '<=', invalid: false }] : []),
    ]
  }

  const handleSearch = useCallback((value, rowCount = 0) => {
    const listControl = {
      filters: listFilters(value),
      sorts: [{ field: value.sort.column, sort: value.sort.order }],
      range: { chunk_start:  rowCount, chunk_end: rowCount + CHUNK_SIZE - 1 },
    }

    dispatch(loadCrListCount({ args: {}, control: { ...listControl, count: true } }))
    dispatch(loadCrList({ args: {}, control: listControl }))
  }, [dispatch])

  const debouncedHandleSearch = useDebounce(handleSearch, 500)

  const typesClinRec = useMemo(() => {
    return {
      document_status: [
        { name: 'Действующий' },
        { name: 'Новый' },
        { name: 'Опубликован' },
        { name: 'Утратил силу' },
        { name: 'Черновик' },
        { name: 'Недействующий' },
      ].map(data => ({
        label: data.name,
        value: data.name,
      })),
      age_group_caption: [
        { name: 'Взрослые' },
        { name: 'Дети' },
        { name: 'Взрослые; Дети' },
      ].map(data => ({
        label: data.name,
        value: data.name,
      })),
      profile_caption: (docProfilesModel.payload?.data ?? []).map(data => ({
        label: data.caption,
        value: data.caption,
      })),
    }
  }, [docProfilesModel.payload?.data])

  const tableHeaders = useMemo(() => {
    return {
      document_status: {
        width: 100,
        dataIndex: 'document_status',
        sort: true,
        dataRender: row => <DocStatusTag className='table-row-tag'>{row}</DocStatusTag>,
        header: 'Статус',
        // header: {
        //   title: 'Статус',
        //   component: Combobox,
        //   props: {
        //     placeholder: 'Все',
        //     allowClear: true,
        //     showSearch: true,
        //     options: typesClinRec.document_status,
        //   },
        // },                                               //VIMISVIMIS-21048 VIMISVIMIS-21051
      },
      // number: {
      //   dataIndex: 'number',
      //   sort: true,
      //   width: 65,
      //   header: {
      //     title: '№',
      //     component: TextInput,
      //     props: {
      //       allowClear: true,
      //       icon: 'search',
      //     },
      //     defaultValue: '',
      //   },
      // },                                                 //VIMISVIMIS-21048 VIMISVIMIS-21051
      name_full: {
        width: 250,
        dataIndex: 'name_full',
        sort: true,
        defaultSort: SORT_ORDER.ASC,
        header: {
          component: TextInput,
          title: 'Наименование клинических рекомендаций',
          props: {
            allowClear: true,
            icon: 'search',
          },
          defaultValue: '',
        },
      },
      mkb: {
        dataIndex: 'mkb',
        width: 125,
        sort: true,
        header: {
          component: Mkb10InputWithState,
          defaultValue: [],
          title: 'Кодирование по МКБ-10',
          dataRender: diag_list => isArray(diag_list) ? diag_list.join(', ') : '',
        },
      },
      age_group: {
        dataIndex: 'age_group',
        sort: true,
        dataRender: row => <AgeComponent data={JSON.parse(row) ?? []} />,
        width: 100,
        header: {
          component: Combobox,
          title: 'Возраст.группа',
          props: {
            placeholder: 'Все',
            allowClear: true,
            showSearch: true,
            options: typesClinRec.age_group_caption,
          },
        },
      },
      // profile_caption: {
      //   dataIndex: 'profile_caption',
      //   sort: true,
      //   width: 100,
      //   header: {
      //     component: Combobox,
      //     title: 'Профиль ОМП',
      //     props: {
      //       placeholder: 'Все',
      //       allowClear: true,
      //       showSearch: true,
      //       options: typesClinRec.profile_caption,
      //     },
      //   },
      // },                                                             //VIMISVIMIS-21048 VIMISVIMIS-21051
      revision_effective_date: {
        width: 145,
        sort: true,
        dataIndex: 'revision_effective_date',
        dataRender: row => isValidMomentDate(row)?.format('DD.MM.YYYY'),
        header: {
          component: DateRangePicker,
          title: 'Дата последней редакции',
          props: {
            allowEmpty: [true, true],
            allowClear: true,
            disabledDate: c => !!c && !c.isBetween(moment('1900-01-01'), moment().add(1, 'day'), 'days'),
          },
        },
      },
    }
  }, [typesClinRec.age_group_caption])

  const handleRowDoubleClick = useCallback((row) => {
    history.push(`${URLS.METHODOLOGY__CLINREC__DATA}/${row.id}/information`)
  }, [history])

  const handleRowAuxClick = useCallback((row) => {
    window.open(`${URLS.METHODOLOGY__CLINREC__DATA}/${row.id}/information`, '_blank')
  }, [])

  const handleResetFilters = () => {
    setGridValue(null)
  }

  const hasMoreToLoad = useMemo(() => {
    if(!clinrecListModel.loading && !clinrecListCountModel.loading &&
        clinrecListModel.loaded && clinrecListCountModel.loaded)
      return clinrecListModel.payload.data.length < clinrecListCountModel.payload.data[0]._count_

    return false
  }, [clinrecListModel, clinrecListCountModel])

  const handleLoadMore = useCallback(() => {
    if (!clinrecListModel.loading && hasMoreToLoad)
      handleSearch(gridValue, clinrecListModel.payload?.data?.length)
  }, [clinrecListModel.loading, clinrecListModel.payload?.data?.length, gridValue, handleSearch, hasMoreToLoad])

  return (
    <div className='root-methodology-clinrec-list'>
      <MethTabs label='Клинические рекомендации' link={URLS.METHODOLOGY__CLINREC}/>

      <div className='list-container'>
        <div className='list-controls'>
          <Button
            label='Сбросить фильтры'
            onClick={handleResetFilters}
            icon='close_cr_fr'
            disabled={!gridValue?.isDirty}
          />
          <UpdateTimestamp className='ml-auto'/>
        </div>
        <DataGrid
          columns={tableHeaders}
          data={clinrecListModel.payload?.data ?? []}
          rowKey='id'
          loading={clinrecListModel.loading}
          error={clinrecListModel.error}
          value={gridValue}
          onReset={handleSearch}
          onChange={setGridValue}
          onFilterChange={debouncedHandleSearch}
          onSortChange={handleSearch}
          onRowDoubleClick={handleRowDoubleClick}
          onRowAuxClick={handleRowAuxClick}
          onScrolledToBottom={handleLoadMore}
        />
      </div>
    </div>
  )
}

const AgeComponent = ({ data }) => {
  return data.length === 2
    ? <Tag className='clinrec-tag' color={TAG_COLORS.LIGHT_BEIGE}>Взрослые; Дети</Tag>
    : !Number(data[0])
      ? <Tag color={TAG_COLORS.LAVENDER}>Взрослые</Tag>
      : <Tag color={TAG_COLORS.GREEN_TEA}>Дети</Tag>
}
