import cn from 'classnames'
import { isFunction } from 'lodash'
import PropTypes from 'prop-types'
import { Component, createRef } from 'react'
import { Icon } from '@src/components'
import { NOOP_FN } from '@src/utils'
import './TextInput.scss'

export default class TextInput extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    name: PropTypes.string,
    icon: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    allowClear: PropTypes.bool,
    onClear: PropTypes.func,
    readonly: PropTypes.bool,
    onClickToContainer: PropTypes.func,
    title: PropTypes.string,
    pointer: PropTypes.bool,
    onClickCapture: PropTypes.func,
    greyBackground: PropTypes.bool,
    cornersRight: PropTypes.bool,
    cornersLeft: PropTypes.bool,
    allowedChars: PropTypes.instanceOf(RegExp),
    isInvalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    autoFocus: PropTypes.bool,
  }

  static defaultProps = {
    placeholder: '',
    disabled: false,
    allowClear: false,
    readonly: false,
    autoFocus: false,
    onChange: NOOP_FN,
    value: '',
  }

  constructor () {
    super()
    this.inputRef = createRef('')
  }

  handleChange = (event) => {
    let value = event.target.value

    if(value && this.props.allowedChars) {
      value = value.split('').reduce((acc, char) => acc + (this.props.allowedChars.test(char) ? char : ''), '')
    }

    if (this.props.value !== value) {
      this.props.onChange(value)
    }
  }

  handleClear = () => {
    if(this.props.onClear)
      this.props.onClear()
    else
      this.props.onChange('')
  }

  componentDidMount () {
    if (this.props.autofocus) {
      setTimeout(() => this.inputRef.current.focus())
    }
  }

  render () {
    const {
      field,
      value,
      name,
      placeholder,
      className,
      onBlur,
      disabled,
      onFocus,
      allowClear,
      readonly,
      onClickToContainer,
      icon,
      title,
      pointer,
      onClickCapture,
      greyBackground,
      cornersRight,
      cornersLeft,
      isInvalid,
      tabIndex,
      autoFocus,
    } = this.props

    const invalid = isFunction(isInvalid) ? isInvalid(value) : isInvalid

    return (
      <div
        className={cn(
          'root-text-input',
          icon && 'text-input-whitch-icon',
          disabled && 'disabled',
          cornersRight && 'corners-right',
          cornersLeft && 'corners-left',
          invalid && 'text-input-invalid',
          className,
        )}
        onClick={onClickToContainer}
      >
        <input
          ref={this.inputRef}
          tabIndex={tabIndex}
          autoComplete='off'
          disabled={disabled}
          placeholder={placeholder}
          onChange={this.handleChange}
          onBlur={onBlur}
          value={value || ''}
          name={name}
          onFocus={onFocus}
          type='text'
          readOnly={readonly}
          title={title}
          onClickCapture={onClickCapture}
          autoFocus={autoFocus}
          className={cn('text-input-input', pointer && 'input-pointer', greyBackground && 'grey-background')}
          {...field}
        />
        {
          allowClear && value !== ''
            ? <Icon className='text-input-icon-clear' icon='close' alt='' onClick={this.handleClear}/>
            : icon
              ? <Icon className='text-input-icon' icon={icon} alt=''/>
              : null
        }
      </div>
    )
  }
}
