import { createAsyncThunk } from '@reduxjs/toolkit'
import { remotesManager, withAuthorization } from '@src/remotes'
import { selectAccessToken } from '@src/store'
import { extraCreator, extraNestedCreator, mapDataWithMeta, thunkCreator, thunkCreatorInfinityScroll } from '@src/utils'
import * as models from './models'

export const loadAdminUserList = createAsyncThunk(
  'admin/loadAdminUserList',
  async ({ args, controls }, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('/admin/users/list', {
        args: {
          searchText: null,
          token: accessToken,
          ...args,
        },
        control: {
          datamode: 'full',
          range: {
            ...controls.range,
          },
          filters: [
            ...controls.filters,
          ],
          sorts: [
            ...controls.sorts,
          ],
        },
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      return {
        data: mapDataWithMeta(data),
      }
    } catch(e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const loadAdminRolesList = createAsyncThunk(
  'admin/loadAdminRolesList',
  async (arg, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('/admin/roles/list', { 'userCount': true }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      return {
        data: mapDataWithMeta(data),
      }
    } catch (e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const loadAdminRolesById = createAsyncThunk(
  'admin/loadAdminRoleById',
  async (id, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('/admin/roles/list', {
        args: {
          id: id,
        },
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      return {
        data: mapDataWithMeta(data),
      }
    } catch (e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const loadAdminUserRoles = createAsyncThunk(
  'admin/loadAdminUserRoles',
  async ({ args, controls = { range: {}, filters: [], sorts: [] } }, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('/admin/roles/user', {
        args: {
          token: accessToken,
          ...args,
        },
        control: {
          datamode: 'scroll',
          range: {
            ...controls.range,
          },
          filters: [
            ...controls.filters,
          ],
          sorts: [
            ...controls.sorts,
          ],
        },
      }, withAuthorization(accessToken))
      if (data.error) { throw data }
      return {
        data: mapDataWithMeta(data),
      }
    } catch(e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const loadOrgsByMclIdByTerritoryLevel = createAsyncThunk(
  'admin/loadOrgsByMclIdByTerritoryLevel',
  async ({ mclId, territoryLevel }, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('/admin/organisations/list/byMcl', {
        mcl_id: mclId,
        territory_level: territoryLevel,
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      return {
        data: data.data,
        mclId,
      }
    } catch(e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const loadAppointmentsByRoleId = createAsyncThunk(
  'admin/loadAppointmentsByRoleId',
  async ({ roleId }, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('/admin/appointments/byRole', {
        args: {
          role_id: roleId,
        },
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      return {
        data: mapDataWithMeta(data),
        roleId,
      }
    } catch(e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const loadAppointmentsByPositionsList = createAsyncThunk(
  'admin/loadAppointmentsByPositionsList',
  async (args, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('/admin/appointments/list', {
        controls: {
          datamode: 'full',
          sorts: [],
        },
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      return {
        data: mapDataWithMeta(data),
      }
    } catch(e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const loadAppointmentsByPositions = createAsyncThunk(
  'admin/loadAppointmentsByPositions',
  async ({ roleId, roleName }, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('/admin/appointments/byRole', {
        args: {
          role_id: roleId,
          role_name: roleName,
        },
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      return {
        data: mapDataWithMeta(data),
      }
    } catch(e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const insertRoleAppointments = createAsyncThunk(
  'admin/insertRoleAppointments',
  async ({ roleId, appointmentId }, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('admin/appointments/insertRoleAppointment', {
        args: {
          appointment_id: appointmentId,
          role_id: roleId,
        },
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      return {
        data: mapDataWithMeta(data),
      }
    } catch(e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const deleteRoleAppointments = createAsyncThunk(
  'admin/deleteRoleAppointments',
  async ({ role_id, appointment_id }, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('admin/appointments/deleteRoleAppointment', {
        args: {
          role_id,
          appointment_id,
        },
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      return {
        data: mapDataWithMeta(data),
      }
    } catch(e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const deleteAppointments = createAsyncThunk(
  'admin/deleteAppointments',
  async (id, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('admin/appointments/deleteAppointment', {
        args: {
          id: id,
        },
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      return {
        data: mapDataWithMeta(data),
      }
    } catch(e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const insertAppointments = createAsyncThunk(
  'admin/insertAppointments',
  async ({ id, appointment }, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('admin/appointments/insertAppointment', {
        args: {
          id: id,
          appointment: appointment,
        },
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      return {
        data: mapDataWithMeta(data),
      }
    } catch(e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const updateAppointments = createAsyncThunk(
  'admin/updateAppointments',
  async ({ id, appointment }, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('admin/appointments/updateAppointment', {
        args: {
          id: id,
          appointment: appointment,
        },
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      return {
        data: mapDataWithMeta(data),
      }
    } catch(e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const [
  upsertUserRole, upsertUserRoleExtra,
] = thunkCreator(models.ADMIN_UPSERT_USER_ROLE, '/admin/roles/user/set')

export const [
  setMainRole, setMainRoleExtra,
] = thunkCreator(models.ADMIN_SET_MAIN_ROLE, '/admin/roles/user/set_main')

export const deleteUserRole = createAsyncThunk(
  'admin/deleteUserRole',
  async ({ roleId }, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('/admin/roles/user/remove', {
        args: {
          id: roleId,
        },
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      return { data }
    } catch (e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const loadAdminResourcesByRoleId = createAsyncThunk(
  'admin/loadAdminResourcesByRoleId',
  async ({ roleId }, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('/admin/roles/resources', {
        args: {
          role_id: roleId,
        },
        control: {
          datamode: 'full',
          sorts: [],
        },
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      return {
        data: mapDataWithMeta(data),
      }
    } catch(e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const loadAdminRoleResourcesList = createAsyncThunk(
  'admin/loadAdminRoleResourcesList',
  async (arg, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('/admin/roles/resources/list', {
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      return mapDataWithMeta(data).map(res => ({
        ...res,
        acl: JSON.parse(res.acl),
      }))
    } catch(e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const loadAdminAclByRoleByResource = createAsyncThunk(
  'admin/loadAdminAclByRoleByResource',
  async ({ roleId, resourceId }, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('/admin/roles/resource/acl', {
        args: {
          role_id: roleId,
          resource_id: resourceId,
        },
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      return mapDataWithMeta(data)
    } catch(e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const removeAdminRoleResource = createAsyncThunk(
  'admin/removeAdminRoleResource',
  async ({ roleId, path }, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('/admin/roles/resources/remove', {
        args: {
          role_id: roleId,
          path: path,
        },
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      return data
    } catch(e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const setAdminResourceAcl = createAsyncThunk(
  'admin/setAdminResourceAcl',
  async ({ roleId, path, action, scope, transform, id }, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('/admin/resources/acl/set', {
        args: {
          role_id: roleId,
          path,
          action,
          scope,
          transform,
          id,
        },
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      return data
    } catch(e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const setAdminRole = createAsyncThunk(
  'admin/setAdminRole',
  async ({ mainPage, mclProfileId, responseLevelId, roleName, id, role_code, isCopy }, { dispatch, getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('/admin/roles/set', {
        args: {
          main_page: mainPage,
          mcl_profile_id: mclProfileId,
          response_level_id: responseLevelId,
          role_name: roleName,
          id: id,
          role_code: role_code,
          isCopy,
        },
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      await dispatch(loadAdminRolesList())
      return data
    } catch(e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const removeAdminRole = createAsyncThunk(
  'admin/removeAdminRole',
  async ({ roleId }, { dispatch, getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('/admin/roles/remove', {
        args: {
          id: roleId,
        },
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      await dispatch(loadAdminRolesList())
      return data
    } catch(e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const copyAdminRole = createAsyncThunk(
  'admin/copyAdminRole',
  async ({ ...args }, { dispatch, getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('admin/roles/set', {
        args: {
          ...args,
        },
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      await dispatch(loadAdminRolesList())
      return data
    } catch(e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const adminUserDelete = createAsyncThunk(models.ADMIN_USER_DELETE,
  async (id, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('/admin/users/delete', { id }, withAuthorization(accessToken))

      if (data.error) throw new Error(data.error)
      return data
    } catch(e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

export const [
  loadExternalSystemsList, loadExternalSystemsListExtra,
] = thunkCreator(models.ADMIN_EXTERNAL_SYSTEMS_LIST, '/admin/extsystems/all')

export const [
  insertExternalSystem, insertExternalSystemExtra,
] = thunkCreator(models.ADMIN_INSERT_EXTERNAL_SYSTEM, '/admin/extsystems/set', { extractor: response => response })

export const [
  suspendAllExternalSystems, suspendAllExternalSystemsExtra,
] = thunkCreator(models.ADMIN_SUSPEND_ALL_EXTERNAL_SYSTEMS, '/admin/extsystems/blockall', { extractor: response => response })

export const [
  resumeAllExternalSystems, resumeAllExternalSystemsExtra,
] = thunkCreator(models.ADMIN_RESUME_ALL_EXTERNAL_SYSTEMS, '/admin/extsystems/unblockall', { extractor: response => response })

export const [
  loadAdminUsersList, loadAdminUsersListExtra,
] = thunkCreatorInfinityScroll(models.ADMIN_USERS_LIST, '/admin/users/list')

export const [
  getExtSystemsHistory, getExtSystemsHistoryExtra,
] = thunkCreator(models.ADMIN_EXT_SYSTEMS_HISTORY, '/admin/extsystems/get_history')

const extraReducers = {
  ...extraCreator(loadAdminUserList, models.ADMIN_USER_LIST),
  [loadAdminUserList.fulfilled]: (state, action) => {
    state[models.ADMIN_USER_LIST].payload = action.meta?.arg?.controls?.range?.chunk_start > 0 ? {
      ...action.payload,
      data: [
        ...state[models.ADMIN_USER_LIST].payload.data,
        ...action.payload.data,
      ],
      hasMoreToLoad: action.payload.data.length,
    } : {
      ...action.payload,
      hasMoreToLoad: true,
    }
    state[models.ADMIN_USER_LIST].loaded = true
    state[models.ADMIN_USER_LIST].error = null
    state[models.ADMIN_USER_LIST].loading = false
  },

  ...extraCreator(loadAdminRolesById, models.ADMIN_ROLES_BY_ID),
  ...extraCreator(loadAdminRolesList, models.ADMIN_ROLES_LIST),
  ...upsertUserRoleExtra,
  ...setMainRoleExtra,
  ...extraCreator(deleteUserRole, models.ADMIN_DELETE_USER_ROLE),
  ...extraCreator(loadAdminRoleResourcesList, models.ADMIN_ROLE_RESOURCES_LIST),
  ...extraCreator(setAdminRole, models.ADMIN_SET_ROLE),
  ...extraCreator(removeAdminRole, models.ADMIN_REMOVE_ROLE),
  ...extraCreator(copyAdminRole, models.ADMIN_COPY_ROLE),
  ...extraCreator(loadAppointmentsByPositionsList, models.ADMIN_APPOINTMENTS_POSITIONS_LIST),
  ...extraCreator(insertRoleAppointments, models.ADMIN_APPOINTMENTS_INSERT_ROLE),
  ...extraCreator(deleteRoleAppointments, models.ADMIN_APPOINTMENTS_DELETE_ROLE),
  ...extraCreator(deleteAppointments, models.ADMIN_APPOINTMENTS_DELETE),
  ...extraCreator(adminUserDelete, models.ADMIN_USER_DELETE),

  ...loadExternalSystemsListExtra,
  ...insertExternalSystemExtra,
  ...suspendAllExternalSystemsExtra,
  ...resumeAllExternalSystemsExtra,
  ...loadAdminUsersListExtra,
  ...getExtSystemsHistoryExtra,

  ...extraNestedCreator(loadAdminResourcesByRoleId, models.ADMIN_RESOURCES_BY_ROLE_ID, arg => [arg.roleId]),
  ...extraNestedCreator(loadAdminAclByRoleByResource, models.ADMIN_ACL_BY_ROLE_BY_RESOURCE, arg => [arg.roleId, arg.resourceId]),
  ...extraNestedCreator(loadAdminUserRoles, models.ADMIN_ROLES_BY_USER_ID, arg => [arg.args.user_id]),
  ...extraNestedCreator(loadOrgsByMclIdByTerritoryLevel, models.ADMIN_ORGS_BY_MCL_ID_BY_TERRITORY_LEVEL, arg => [arg.mclId, arg.territoryLevel]),
  ...extraNestedCreator(loadAppointmentsByRoleId, models.ADMIN_APPOINTMENTS_ROLE_ID, arg => [arg.roleId]),
  ...extraNestedCreator(loadAppointmentsByPositions, models.ADMIN_APPOINTMENTS_POSITIONS, arg => [arg.roleId, arg.roleName]),
  ...extraNestedCreator(insertAppointments, models.ADMIN_APPOINTMENTS_INSERT, arg => [arg.id, arg.appointment]),
  ...extraNestedCreator(updateAppointments, models.ADMIN_APPOINTMENTS_UPDATE, arg => [arg.id, arg.appointment]),
}

export default extraReducers
