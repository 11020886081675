import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from '@src/components'
import {
  loadRegisterEmergencyNoticeList,
  selectPatientStatusModel,
  loadRegisterEmergencyNoticeWidget,
  loadRegisterEmergencyNoticeWidgetCSP,
  selectPatientEmergencyNoticeWidgetModel,
  loadPatientStatusInfection,
  selectPatientStatusInfection,
} from '@src/modules/monitoring/store'
import { useMountEffect } from '@src/hooks'
import { withMntAudit } from '@src/hoc'
import { TabStatusBase } from '@src/modules/monitoring/components'
import { getFullname } from '@src/utils'

const TabStatus = ({ patientId, occasionPatientId, ehrDescriptionId, ...props }) => {
  const dispatch = useDispatch()
  const patientStatusModel = useSelector(selectPatientStatusModel)
  const patientStatusWidgetModel = useSelector(selectPatientEmergencyNoticeWidgetModel)
  const patientStatusInfectionModel = useSelector(selectPatientStatusInfection)
  const status = patientStatusModel.payload?.data
  const statusWidget = patientStatusWidgetModel.payload?.data
  const statusInfection = patientStatusInfectionModel.payload?.data

  useMountEffect(() => {
    dispatch(loadRegisterEmergencyNoticeList({ list_type: '29',
      patient_id: patientId }))
    if(occasionPatientId) {
      dispatch(loadPatientStatusInfection({ patient_id: patientId, occasion_patient_id: occasionPatientId }))
      dispatch(loadRegisterEmergencyNoticeWidget({ occasion_patient_id: occasionPatientId }))
    }
    else dispatch(loadRegisterEmergencyNoticeWidgetCSP({ ehr_description_id: ehrDescriptionId }))
  })

  const latestStatusWidget = statusWidget?.sort((a, b) => new Date(b?.emergency_notification_begin_on) - new Date(a?.emergency_notification_begin_on))[0]
  const hasOccasionId = (!isNaN(occasionPatientId) && occasionPatientId !== null) ? true : false

  return <TabStatusBase className='grid-12' style={{ display: 'grid' }} {...props}>
    <div className='x6'>
      <Card
        className='mb-16'
        loading={patientStatusModel.loading}
        error={patientStatusModel.error}
        title='Пациент'
        data={status && [
          {
            title: 'ФИО',
            data: getFullname(status) || '—',
          },
          {
            title: 'Пол',
            data: status.gender ?? '-',
          },
          {
            title: 'Дата рождения',
            data: status.birth_date ? moment(status.birth_date).format('DD.MM.YYYY') : '—',
          },
          {
            title: 'Возраст',
            data: status.patient_age ?? '-',
          },
        ]}
      />

      <Card
        loading={patientStatusWidgetModel.loading}
        error={patientStatusWidgetModel.error}
        title='Сведения об извещении'
        data={latestStatusWidget && [
          {
            title: 'Дата создания извещения',
            data: latestStatusWidget?.emergency_notification_begin_on ? moment(latestStatusWidget?.emergency_notification_begin_on).format('DD.MM.YYYY') : '—',
          },
          {
            title: 'Медицинская организация, направившая экстренное извещение',
            data: latestStatusWidget.mo_emergency_notification ? latestStatusWidget.mo_emergency_notification : '-',
          },
          {
            title: 'Вид извещения',
            data: latestStatusWidget.treatment_name ? latestStatusWidget.treatment_name : '-',
          },
        ]}
      />
    </div>

    <div className='x6'>
      <Card
        loading={patientStatusWidgetModel.loading}
        error={patientStatusWidgetModel.error}
        className='mb-16'
        title='Диагноз'
        data={latestStatusWidget && [
          {
            title: 'Диагноз МКБ-10',
            data:  latestStatusWidget?.crct_diagn !== null || latestStatusWidget?.prv_diagn !== null
              ? (latestStatusWidget.crct_diagn !== null  ? latestStatusWidget.crct_diagn : latestStatusWidget.prv_diagn)
              : '-',
          },
          {
            title: 'Дата установления диагноза',
            data: latestStatusWidget?.crct_diagn_date !== null || latestStatusWidget?.prv_diagn_date !== null
              ? (latestStatusWidget?.crct_diagn_date !== null ? moment(latestStatusWidget?.crct_diagn_date).format('DD.MM.YYYY') : moment(latestStatusWidget?.prv_diagn_date).format('DD.MM.YYYY'))
              : '-',
          },
          {
            title: 'Наличие лабораторного подтверждения',
            data: latestStatusWidget.lab_confirmation === true ? 'Да' : 'Нет',
          },
        ]}
      />

      <Card
        loading={patientStatusWidgetModel.loading && patientStatusInfectionModel.loading }
        error={patientStatusWidgetModel.error && patientStatusInfectionModel.error }
        title='Сведения о случае'
        data={(latestStatusWidget || statusInfection) && [
          {
            title: 'Дата первичного обращения (выявления)',
            data: latestStatusWidget?.first_appeal_date !== null ? moment(latestStatusWidget?.first_appeal_date).format('DD.MM.YYYY') : '-',
          },
          {
            title: 'Место выявления',
            data: hasOccasionId
              ? statusInfection?.place_detection
              : latestStatusWidget?.place_detection,

          },
          {
            title: 'Сведения о возможных способах инфицирования',
            data: latestStatusWidget?.method_of_infection_name ? latestStatusWidget?.method_of_infection_name.join('; ') : '-',
          },
          {
            title: 'Сведения о наиболее вероятном пути передачи',
            data: latestStatusWidget?.method_transmission_name ? latestStatusWidget?.method_transmission_name.join('; ') : '-',
          },
          {
            title: 'Сведения о наиболее вероятном источнике инфекции',
            data: latestStatusWidget?.source_infection_name || '-',
          },
          {
            title: 'Исход случая',
            data: hasOccasionId
              ? (statusInfection?.outcome || '-')
              : (latestStatusWidget?.outcome || '-'),
          },
          {
            title: 'Дата исхода случая',
            data:hasOccasionId
              ? (statusInfection?.outcome_date !== null ? moment(statusInfection?.outcome_date).format('DD.MM.YYYY') : '-')
              : (latestStatusWidget?.outcome_date !== null ? moment(latestStatusWidget?.outcome_date).format('DD.MM.YYYY') : '-'),
          },
        ]}
      />
    </div>

  </TabStatusBase>
}

export const TabStatusWithAudit = withMntAudit(TabStatus)

TabStatusWithAudit.tabTitle = 'Статус'
TabStatusWithAudit.tabPath = 'status'
