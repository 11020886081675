export const JOURNAL_SEMD_LIST = 'journalSemdList'
export const JOURNAL_SEMD_DETAILS = 'journalSemdDetails'
export const JOURNAL_AUDIT_LIST = 'journalAuditList'
export const JOURNAL_EXT_SYSTEMS_LIST = 'journalExtSystemsList'
export const JOURNAL_SEMD_DOC_TYPE_LIST = 'journalSemdDocTypeList'
export const JOURNAL_SEMD_STATUS_LIST = 'journalSemdStatusList'
export const JOURNAL_AUDIT_WRITE = 'journalAuditWrite'
export const JOURNAL_MESSAGE_ATTRIBUTES = 'journalIncomingMessage'
export const JOURNAL_OUTGOING_MESSAGE = 'journalOutgoingMessage'
export const JOURNAL_PROCESSING_LOG = 'journalProcessingLog'
export const JOURNAL_MSG_INDEX = 'journalMsgIndex'
export const JOURNAL_FILTERS = 'journalFilters'
