import { Modal } from '@src/components'
import { withMntAudit } from '@src/hoc'
import './ServicesModal.scss'

const ServicesModal = ({ services, ...props }) => {
  return <Modal
    className='root-services-modal'
    title='Услуги'
    {...props}
  >
    <div className='services-modal-services'>
      {
        services.map((s, index) => (
          <div
            className='services-modal-service'
            key={`service-${index}`}
          >
            <div className='services-modal-service-block'>
              <div className='services-modal-service-block-title'>Код</div>
              <div className='services-modal-service-block-data'>{s.service_code}</div>
            </div>
            <div className='services-modal-service-block'>
              <div className='services-modal-service-block-title'>Наименование</div>
              <div className='services-modal-service-block-data'>{s.service_name}</div>
            </div>
          </div>
        ))
      }
    </div>
  </Modal>
}

export const ServicesModalWithAudit = withMntAudit(ServicesModal)
