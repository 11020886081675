import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import { withAcl } from '@src/hoc'
import { InternalError404, LoadedOrError } from '@src/components'
import { AUDIT_PS, URLS } from '@src/constants'
import { useLoadModels } from '@src/hooks'
import { loadMclList, selectMclListModel, loadDictMo, selectDictMoModel } from '@src/modules/dictionaries/store'
import { loadExtSystemsList, loadSemdDocTypeList, loadSemdStatusList, selectExtSystemsListModel,
  selectSemdDocTypeListModel, selectSemdStatusListModel } from '@src/modules/journal/store'

import { JournalAuditPageWithAudit } from '../JournalAuditPage/JournalAuditPage'
import { JournalSemdPage } from '../JournalSemdPage/JournalSemdPage'
import './JournalPage.scss'

const JournalPage = () => {
  const [loaded, loading, error] = useLoadModels(
    [useSelector(selectMclListModel), loadMclList()],
    [useSelector(selectExtSystemsListModel), loadExtSystemsList()],
    [useSelector(selectSemdDocTypeListModel), loadSemdDocTypeList()],
    [useSelector(selectSemdStatusListModel), loadSemdStatusList()],
    [useSelector(selectDictMoModel), loadDictMo()]
  )

  return <div className='root-journal-page'>
    <LoadedOrError loaded={loaded} loading={loading} error={error}>
      <Switch>
        <Route path={URLS.JOURNAL__SEMD}>
          <JournalSemdPage />
        </Route>
        <Route path={`${URLS.JOURNAL__AUDIT}`}>
          <JournalAuditPageWithAudit
            auditPs={AUDIT_PS.JOURNAL}
            auditMessage='Просмотр Журнала действий пользователя'
          />
        </Route>
        <Route component={InternalError404}/>
      </Switch>
    </LoadedOrError>
  </div>
}

export const JournalPageWithAcl = withAcl(JournalPage)