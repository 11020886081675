import { createSlice } from '@reduxjs/toolkit'
import { initialModel } from '@src/utils'

import extraReducers from './lib/thunks'
import reducers from './lib/reducers'
import * as models from './lib/models'

const initialState = {
  [models.ANALYTICS_INTEGRATION_LIST]: initialModel,
  [models.ANALYTICS_INTEGRATION_FILTER_MO]: initialModel,
  [models.ANALYTICS_INTEGRATION_FILTER_EXT_SYSTEMS]: initialModel,
  [models.ANALYTICS_INTEGRATION_FILTER_EHR]: initialModel,
  [models.ANALYTICS_INTEGRATION_FILTER_DEPARTS]: initialModel,
}

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers,
  extraReducers,
})

export default analyticsSlice.reducer
export * from './lib/thunks'
export * from './lib/selectors'
export const { resetIntegrationReportState } = analyticsSlice.actions
