export const DICT_MCL_LIST = 'dictionariesMclList'
export const DICT_RESPONSE_LEVEL_LIST = 'dictResponseLevelList'
export const DICT_GENDERS = 'dictGenders'
export const DICT_PREGNANCY_OUTCOMES = 'dictPregnancyOutcomes'
export const DICT_IMMUNIZATION = 'dictImmunization'
export const DICT_MO = 'dictMo'
export const DICT_REGION = 'dictRegion'
export const DICT_DISEASES = 'dictDiseases'
export const DIC_DN_REASON = 'dicDnReason'
export const DIC_RESULT_DETECTION = 'dicResultDetection'
export const DIC_DETECTION_PLACE = 'dicDetectionPlace'
export const DIC_DETECTION_PLACE_INFECTION = 'dicDetectionPlaceInfection'
export const DIC_VALIDITY_DEGREE = 'dicValidityDegree'
export const DIC_NOTICE_TYPE = 'dicNoticeType'
export const DIC_STATUS_INFECTION = 'dicStatusInfection'
export const DICT_TYPES_OF_NOTIFICATION = 'dictTypesOfNotification'
