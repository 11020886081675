import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { InternalError404, LoadedOrError } from '@src/components'
import { URLS } from '@src/constants'
import { withAcl } from '@src/hoc'
import { useLoadModels } from '@src/hooks'
import { loadDictMo, loadDictRegion, loadDictDiseases, loadDictPregnancyOutcomes,
  selectDictMoModel, selectDictRegionModel, selectDictDiseasesModel, selectDictPregnancyOutcomesModel,
  loadDictDnReason, selectDictDnReason, selectDictResultDetection, loadDictResultDetection,
  selectDictDetectionPlace, selectDictStatusInfectionType, loadDictDetectionPlace, loadDictValidityDegree, selectDictValidityDegree,
  loadDictDetectionPlaceInfection, selectDictDetectionPlaceInfection, selectDictNoticeType, selectDictTypesOfNotification,
  loadDictNoticeType, loadDictStatusInfectionType, loadDictTypesOfNotification } from '@src/modules/dictionaries/store'
import { RegisterAkineoNewborn } from '../RegisterAkineoNewborn/RegisterAkineoNewborn'
import { RegisterAkineoPregnant } from '../RegisterAkineoPregnant/RegisterAkineoPregnant'
import { RegisterAkineoNns } from '../RegisterAkineoNns/RegisterAkineoNns'
import { RegisterOnkoPredzno } from '../RegisterOnkoPredzno/RegisterOnkoPredzno'
import { RegisterOnkoZno } from '../RegisterOnkoZno/RegisterOnkoZno'
import { RegisterProfDn } from '../RegisterProfDn/RegisterProfDn'
import { RegisterProfImm } from '../RegisterProfImm/RegisterProfImm'
import { RegisterProfPmoD } from '../RegisterProfPmoD/RegisterProfPmoD'
import { RegisterProfPmoTeen } from '../RegisterProfPmoTeen/RegisterProfPmoTeen'
import { RegisterSszOks } from '../RegisterSszOks/RegisterSszOks'
import { RegisterSszOnmk } from '../RegisterSszOnmk/RegisterSszOnmk'
import { RegisterInfection } from '../RegisterInfection/RegisterInfection'
import { RegisterEmergencyNotice } from '../RegisterEmergencyNotice/RegisterEmergencyNotice'
import './MonitoringPage.scss'

export const MonitoringPage = () => {
  const [loaded, loading, error] = useLoadModels(
    [useSelector(selectDictMoModel), loadDictMo()],
    [useSelector(selectDictRegionModel), loadDictRegion()],
    [useSelector(selectDictDiseasesModel), loadDictDiseases()],
    [useSelector(selectDictPregnancyOutcomesModel), loadDictPregnancyOutcomes()],
    [useSelector(selectDictDnReason), loadDictDnReason()],
    [useSelector(selectDictResultDetection), loadDictResultDetection()],
    [useSelector(selectDictDetectionPlace), loadDictDetectionPlace()],
    [useSelector(selectDictDetectionPlaceInfection), loadDictDetectionPlaceInfection()],
    [useSelector(selectDictValidityDegree), loadDictValidityDegree()],
    [useSelector(selectDictNoticeType), loadDictNoticeType()],
    [useSelector(selectDictStatusInfectionType), loadDictStatusInfectionType()],
    [useSelector(selectDictTypesOfNotification), loadDictTypesOfNotification()]
  )

  return <LoadedOrError loaded={loaded} loading={loading} error={error}>
    <div className='root-monitoring-page'>
      <Switch>
        <Route path={URLS.MONITORING__ONKO}>
          <Switch>
            <Route path={URLS.MONITORING__ONKO__ZNO}><RegisterOnkoZno /></Route>
            <Route path={URLS.MONITORING__ONKO__PREDZNO}><RegisterOnkoPredzno /></Route>
            <Route path='*' component={InternalError404}/>
          </Switch>
        </Route>
        <Route path={URLS.MONITORING__SSZ}>
          <Switch>
            <Route path={URLS.MONITORING__SSZ__OKS}><RegisterSszOks /></Route>
            <Route path={URLS.MONITORING__SSZ__ONMK}><RegisterSszOnmk /></Route>
            <Route path='*' component={InternalError404}/>
          </Switch>
        </Route>
        <Route path={URLS.MONITORING__AKINEO}>
          <Switch>
            <Route path={URLS.MONITORING__AKINEO__NEWBORN}><RegisterAkineoNewborn /></Route>
            <Route path={URLS.MONITORING__AKINEO__PREGNANT}><RegisterAkineoPregnant /></Route>
            <Route path={URLS.MONITORING__AKINEO__NNS}><RegisterAkineoNns /></Route>
            <Route path='*' component={InternalError404}/>
          </Switch>
        </Route>
        <Route path={URLS.MONITORING__PROF}>
          <Switch>
            <Route path={URLS.MONITORING__PROF__DN}><RegisterProfDn /></Route>
            <Route path={URLS.MONITORING__PROF__IMM}><RegisterProfImm /></Route>
            <Route path={URLS.MONITORING__PROF__PMO_D}><RegisterProfPmoD /></Route>
            <Route path={URLS.MONITORING__PROF__PMO_TEEN}><RegisterProfPmoTeen /></Route>
            <Route path='*' component={InternalError404}/>
          </Switch>
        </Route>
        <Route path={URLS.MONITORING__INFECTION}>
          <Switch>
            <Route path={URLS.MONITORING__INFECTION__IB}><RegisterInfection /></Route>
            <Route path={URLS.MONITORING__INFECTION__EMERGENCY_NOTICE}><RegisterEmergencyNotice /></Route>
            <Route path='*' component={InternalError404}/>
          </Switch>
        </Route>
        <Route path='*' component={InternalError404}/>
      </Switch>
    </div>
  </LoadedOrError>
}

export const MonitoringPageWithAcl = withAcl(MonitoringPage)
