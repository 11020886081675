import { Drawer } from 'antd'
import { isArray, isBoolean, isNil } from 'lodash'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { cn } from '@src/utils'
import { selectDictMoModel } from '@src/modules/dictionaries/store'
import './RegionalConditionsDrawer.scss'

const POINT_UNCHECKED = 1
const POINT_WRONG = 5
const POINT_RIGHT = 4

export function RegionalConditionsDrawer ({ point, regionalConditions, ...props }) {
  const { begin_on: pointDate } = point

  return <Drawer
    className='root-regional-conditions-drawer'
    title={point?.point_name}
    width={560}
    footer={null}
    {...props}
  >
    {
      pointDate && <BlockCard
        className='mb-20'
        titles={['Дата подэтапа']}
        columns={[
          [{ text: moment(pointDate).format('DD.MM.YYYY') }],
        ]}
      />
    }
    {
      regionalConditions.map(condition => <ConditionBlock key={condition?.condition_code} point={point} condition={condition} />)
    }
  </Drawer>
}

const ConditionBlock = ({ condition, point = {} }) => {
  const { reg_color: conditionState, mo_name: factMo } = point

  const MoConditions = moConditionsComponentByState(conditionState)
  const TimeCondition = timeConditionComponentByState(conditionState)

  return <div className='condition-block'>
    <h2 className='mb-16'>№{condition?.document_npa?.doc_number} {condition?.document_npa?.doc_name}</h2>
    {
      isArray(condition?.mo_territory_list) && <MoConditions
        factMo={factMo}
        moTerritoryList={condition?.mo_territory_list}
        correctness={condition?.right_mo}
      />
    }
    {

      condition?.time_condition && <TimeCondition
        condition={condition?.time_condition}
        correctness={condition?.right_time}
      />
    }
    {
      condition?.add_condition && <BlockCard
        titles={['Дополнительные параметры']}
        columns={[
          [{ text: <div><p className='mb-4' style={{ fontWeight: 500 }}>{condition?.add_condition?.add_condition_name}</p> <p>{condition?.add_condition?.add_condition_description}</p></div> }],
        ]}
      />
    }
  </div>
}

const moConditionsComponentByState = (conditionState) => {
  switch (conditionState) {
  case POINT_RIGHT:
  case POINT_WRONG: return MoConditionsChecked
  case POINT_UNCHECKED:
  default: return MoConditionsUnchecked
  }
}

const MoConditionsUnchecked = ({ moTerritoryList }) => {
  const dictMoModel = useSelector(selectDictMoModel)

  return moTerritoryList?.map(mo_territory => <BlockCard
    key={mo_territory?.uuid}
    titles={['Рекомендуемая МО']}
    columns={[
      (mo_territory?.mo_list || []).map(moOid => ({ text: (dictMoModel?.payload?.data?.find(mo => mo.mo_id === moOid))?.name_short || moOid })),
    ]}
  />)
}

const MoConditionsChecked = ({ factMo, moTerritoryList, correctness }) => {
  const dictMoModel = useSelector(selectDictMoModel)

  return moTerritoryList?.map(mo_territory => <BlockCard
    key={mo_territory?.uuid}
    titles={['Фактическая МО', 'Рекомендуемая МО']}
    columns={[
      [{ text: factMo || 'Данные отсутствуют', correctness }],
      mo_territory?.mo_list.map(moOid => ({ text: (dictMoModel?.payload?.data?.find(mo => mo.mo_id === moOid))?.name_short || moOid })) || [],
    ]}
  />)
}

const timeConditionComponentByState = (conditionState) => {
  switch (conditionState) {
  case POINT_RIGHT:
  case POINT_WRONG: return TimeConditionChecked
  case POINT_UNCHECKED:
  default: return TimeConditionUnchecked
  }
}

const TimeConditionUnchecked = ({ condition }) => {
  return <BlockCard
    titles={['Рекомендуемый срок']}
    columns={[
      [{ text: condition?.time_condition_description || getTimeConditionString(condition) }],
    ]}
  />
}

const TimeConditionChecked = ({ condition, correctness }) => {
  return <BlockCard
    titles={['Фактический срок', 'Рекомендуемый срок']}
    columns={[
      [{ text: getTimeConditionFactString(condition), correctness }],
      [{ text: condition?.time_condition_description || getTimeConditionString(condition) }],
    ]}
  />
}

/**
 * @param titles Массив строк, каждая строка станет колонкой в заголовке, пример ['Фактическая МО', 'МО']
 * @param columns Массив вида [column, column], каждый элемент массива станет колонкой в строке контента карточки
 * @param column массив объектов вида { text, correctness }, например [{ text: '123', correctness: false }]
 * элементы массива column выстраиваются в столбик внутри своей колонки.
 */
const BlockCard = ({ titles, columns, className }) => {
  return <div className={cn('root-block-card', className)}>
    <div className='block-card-titles'>
      {
        titles.map((title, index) => <div key={index} className='block-card-title-column'>
          <span>{title}</span>
        </div>)
      }
    </div>
    <div className='block-card-content'>
      {
        columns.map((column, index) => <div key={index} className='block-card-content-column'>
          {
            column.map(({ text, correctness }, index) => <span
              className={correctnessClass(correctness)}
              key={index}>{text}</span>)
          }
        </div>)
      }
    </div>
  </div>
}

// css класс для признака выполненного или невыполненого условия
// только явно заданные true/false имеют значение
const correctnessClass = (cor) => {
  if(isBoolean(cor)) {
    return cor ? 'right' : 'wrong'
  }
  return ''
}

// Сформировать строку фактического срока для рег. условия
const getTimeConditionFactString = (condition) => {
  return (!isNil(condition?.fact_time)
    ? [
      condition?.fact_time,
      condition.time_units_short_name,
    ]
    :[
      'Данные отсутствуют',
    ]).filter(e => !isNil(e)).join(' ')
}

// Сформировать строку рек. срока из условия
const getTimeConditionString = (condition) => {
  return (condition?.period
    ? [
      condition?.start_attribute_name,
      'от',
      condition?.value_from,
      'до',
      condition?.value_to,
      condition?.time_units_short_name,
      condition?.inclusive ? 'включительно' : null,
    ]
    :[
      condition?.start_attribute_name,
      condition?.operation_name,
      condition?.value_from,
      condition?.time_units_short_name,
    ]).filter(e => !isNil(e)).join(' ')
}
