import { TextInput } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class PolicyEnpColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.POLICY_ENP

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Номер полиса ОМС',
        component: TextInput,
        defaultValue: '',
        props: {
          icon: 'search',
          allowedChars: /[0-9]/,
          isInvalid: policy_enp => policy_enp && policy_enp.length !== 0 && policy_enp.length < 3,
          allowClear: true,
        },
      },
      width: 150,
      dataIndex: 'policy_enp',
      dataRender: policy_enp => policy_enp ? policy_enp : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let requestArgs = []

    if (filters?.policy_enp) {
      requestArgs = [{ field: 'policy_enp', value: filters?.policy_enp, operator: 'like_both', invalid: false }]
    }

    if (nullSearches[this.type]) {
      requestArgs = [{
        field: this.type,
        operator: '=',
        value: null,
        invalid: false,
      }]
    }

    return {
      control: {
        filters: requestArgs,
      },
    }
  }
}
