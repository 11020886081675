import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from '@src/components'
import { selectDictMoModel, selectDictDnReason } from '@src/modules/dictionaries/store'
import { loadPatientStatusInfection, selectPatientStatusInfection, loadPatientInfoInfection, selectPatientInfoInfection,
  loadDnWidgetInfection, selectDnWidgetInfection } from '@src/modules/monitoring/store'
import { getFullname } from '@src/utils'
import { useMountEffect } from '@src/hooks'
import { withMntAudit } from '@src/hoc'
import { TabStatusBase } from '@src/modules/monitoring/components'

const TabStatus = ({ patientId, occasionPatientId, ...props }) => {
  const dispatch = useDispatch()
  const patientStatusModel = useSelector(selectPatientStatusInfection)
  const patientInfoModel = useSelector(selectPatientInfoInfection)
  const dnWidgetInfection = useSelector(selectDnWidgetInfection)
  const moList = useSelector(selectDictMoModel)
  const dnReasonList = useSelector(selectDictDnReason)

  const status = patientStatusModel.payload?.data
  const info = patientInfoModel.payload?.data
  const widgetInfection = dnWidgetInfection.payload?.data?.[0]

  useMountEffect(() => {
    dispatch(loadPatientStatusInfection({ patient_id: patientId, occasion_patient_id: occasionPatientId }))
    dispatch(loadPatientInfoInfection({ patient_id: patientId }))
    dispatch(loadDnWidgetInfection({ occasion_patient_id: occasionPatientId  }))
  })

  return <TabStatusBase {...props}>
    <div style={{ flexBasis: '40%' }}>
      <Card
        title='Пациент'
        loading={patientInfoModel.loading}
        error={patientInfoModel.error}
        className='mr-16'
        data={info && [
          {
            title: 'ФИО',
            data: getFullname(info) || '—',
          },
          {
            title: 'Пол',
            data: info.gender || '—',
          },
          {
            title: 'Дата рождения',
            data: info.birth_date ? moment(info.birth_date).format('DD.MM.YYYY') : '—',
          },
          {
            title: 'Возраст',
            data: info.patient_age ? info.patient_age : '—',
          },
        ]}
      />
      <Card
        title='Диспансерное наблюдение'
        loading={patientInfoModel.loading}
        error={patientInfoModel.error}
        className='mt-16'
        data={widgetInfection && [
          {
            title: 'Взят на ДН',
            data: widgetInfection.dn_begin_on ? moment(widgetInfection.dn_begin_on).format('DD.MM.YYYY') : '—',
          },
          {
            title: 'Медицинская организация, осуществляющая ДН',
            data: moList.payload?.data?.filter(mo => mo.mo_id === widgetInfection.dn_mo_oid)?.[0]?.name_full ?? '—',
          },
          {
            title: 'Снят с ДН',
            data: widgetInfection.dn_end_on ? moment(widgetInfection.dn_end_on).format('DD.MM.YYYY') : '—',
          },
          {
            title: 'Причина снятия с ДН',
            data: dnReasonList.payload?.filter(reason => reason.item_code === widgetInfection.dn_reason_code)?.[0]?.name ?? '—',
          },
        ]}
      />
      <Card
        title='Сведения о случае'
        loading={patientInfoModel.loading}
        error={patientInfoModel.error}
        className='mt-16'
        data={status && [
          {
            title: 'Место выявления',
            data: status.place_detection || '—',
          },
          {
            title: 'Исход случая',
            data: status.outcome || '—',
          },
          {
            title: 'Дата исхода случая',
            data: status.outcome_date ? moment(status.outcome_date).format('DD.MM.YYYY') : '—',
          },
        ]}
      />
    </div>
    <div style={{ flexBasis: '60%' }}>
      <Card
        title='Диагноз'
        loading={patientStatusModel.loading}
        error={patientStatusModel.error}
        className='ml-16'
        data={(status) && [
          {
            title: 'Диагноз МКБ-10',
            data: status?.diagnosis || '—',
          },
          {
            title: 'Дата установления диагноза впервые в жизни',
            data: status?.first_diag_begin_on ? moment(status?.first_diag_begin_on).format('DD.MM.YYYY') : '—',
          },
          {
            title: 'Степень обоснованности диагноза',
            data: status?.validity_degree || '—',
          },
          {
            title: 'Сопутствующие заболевания',
            data: status?.concomitant_mkb || '—',
          },
          {
            title: 'Осложнение основного заболевания',
            data: status?.complication_mkb || '—',
          },
          {
            title: 'Диагноз предварительный',
            data: status?.preliminary_mkb || '—',
          },
          {
            title: 'Дата установления предварительного диагноза',
            data: status?.preliminary_mkb_date !== null ? moment(status?.preliminary_mkb_date).format('DD.MM.YYYY') : '-',
          },
          {
            title: 'Диагноз уточненный',
            data: status?.verified_mkb || '—',
          },
          {
            title: 'Дата установления уточненного диагноза',
            data: status?.verified_mkb_date !== null ? moment(status?.verified_mkb_date).format('DD.MM.YYYY') : '-',
          },
          {
            title: 'Наличие лабораторного подтверждения диагноза',
            data: typeof status?.lab_confirmation === 'boolean' ? status?.lab_confirmation ? 'Да' : 'Нет' : '—',
          },
        ]}
      />
    </div>
  </TabStatusBase>
}

export const TabStatusWithAudit = withMntAudit(TabStatus)

TabStatusWithAudit.tabTitle = 'Статус'
TabStatusWithAudit.tabPath = 'status'
