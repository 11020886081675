import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'
import { loadPatientList, loadPatientListCount, selectPatientListModel, selectPatientListCountModel, selectPatientListFilters,
  setPatientListFilters } from '@src/modules/monitoring/store'
import { DataGrid, Tag, Button, ColumnsSettings } from '@src/components'
import { TAG_COLORS, REGISTER_COLUMNS, AUDIT_PS } from '@src/constants'
import { RegisterColumnsController } from '@src/modules/monitoring/components'
import { useColumns, useDebounce } from '@src/hooks'
import { concatObjects } from '@src/utils'
import { withMntAudit } from '@src/hoc'
import './PatientList.scss'

const CHUNK_SIZE  = 100

const PatientList = ({ title, list_type, columns, getPatientCardLinkPath, logToAudit, rowKey }) => {
  const [columnsController] = useState(new RegisterColumnsController(columns))
  const [displayedColumns, columnOptions, toggleColumn] = useColumns(columnsController?.columns)

  const dispatch = useDispatch()
  const history = useHistory()

  const patientListFilters = useSelector(selectPatientListFilters)
  const patientListModel = useSelector(selectPatientListModel)
  const patientListCountModel = useSelector(selectPatientListCountModel)

  const requestArgs = useCallback(({ filters, sort, nullSearches, rowCount = 0 }) => {
    return concatObjects(
      {
        args: {
          list_type: list_type,
        },
        control: {
          datamode: 'scroll',
          range: {
            chunk_start: rowCount,
            chunk_end: rowCount + CHUNK_SIZE - 1,
          },
        },
      },
      columnsController.requestArgs({ filters, sort, nullSearches, rowCount })
    ) }, [columnsController, list_type])

  const handleSearch = useCallback(({ filters, sort, nullSearches }) => {
    const args = requestArgs({ filters, sort, nullSearches })

    logToAudit({
      auditPs: AUDIT_PS.PATIENTMON,
      auditMessage: `Поиск в реестре ${title}`,
      auditDescription: args,
    })
    dispatch(loadPatientListCount(args))
    dispatch(loadPatientList(args))
  }, [dispatch, logToAudit, requestArgs, title])

  const debouncedHandleSearch = useDebounce(handleSearch, 500, {}, [handleSearch])

  const handleRowDoubleClick = useCallback(row => history.push(getPatientCardLinkPath(row)), [getPatientCardLinkPath, history])

  const handleRowAuxClick = useCallback(row => window.open(getPatientCardLinkPath(row), '_blank'), [getPatientCardLinkPath])

  const handlePatientListFiltersChange = useCallback(value => dispatch(setPatientListFilters(value)), [dispatch])

  const searchIfValidDebounced = useCallback(({ filters, sort, nullSearches, isInvalid }) => {
    if (!isInvalid) debouncedHandleSearch({ filters, sort, nullSearches })
  }, [debouncedHandleSearch])

  const searchIfValid = useCallback(({ filters, sort, nullSearches, isInvalid }) => {
    if (!isInvalid) handleSearch({ filters, sort, nullSearches })
  }, [handleSearch])

  const handleLoadMore = useCallback(({ filters, sort, nullSearches }, rowCount) => {
    if (!patientListModel.loading && rowCount < patientListCountModel?.payload?.data[0]?.cnt_case) {
      dispatch(loadPatientList(requestArgs({ filters, sort, nullSearches, rowCount })))
    }
  }, [dispatch, patientListCountModel?.payload?.data, patientListModel.loading, requestArgs])

  const handleResetFilters = useCallback(() => {
    dispatch(setPatientListFilters(null))
  }, [dispatch])

  return (
    <div className='root-patient-list'>
      <div className='patient-list-controls'>
        <Button
          className='reset-filter-button'
          label='Сбросить фильтры'
          onClick={handleResetFilters}
          disabled={!patientListFilters || !patientListFilters.isDirty}
          icon='close_cr_fr'
        />
        <span>Количество пациентов:</span>
        <Tag className={cn('patient-list-controls-tag', patientListCountModel.loading && 'loading')} color={TAG_COLORS.LAVENDER}>{patientListCountModel?.payload?.data[0]?.cnt || 0}</Tag>
        <span className='ml-16'>Количество случаев:</span>
        <Tag className={cn('patient-list-controls-tag', patientListCountModel.loading && 'loading')} color={TAG_COLORS.LAVENDER}>{patientListCountModel?.payload?.data[0]?.cnt_case || 0}</Tag>
        <ColumnsSettings
          className='ml-auto'
          columnOptions={columnOptions}
          onToggleColumn={toggleColumn}
        />
      </div>
      <DataGrid
        className='patient-list-grid'
        value={patientListFilters}
        onChange={handlePatientListFiltersChange}
        onFilterChange={searchIfValidDebounced}
        onSortChange={searchIfValid}
        onNullSearchChange={searchIfValid}
        onRowDoubleClick={handleRowDoubleClick}
        onRowAuxClick={handleRowAuxClick}
        onScrolledToBottom={handleLoadMore}
        onReset={handleSearch}

        columns={displayedColumns}
        data={patientListModel.payload?.data}
        rowKey= {rowKey ? rowKey : 'occasion_patient_id'}
        loading={patientListModel.loading}
        error={patientListModel.error}
      />
    </div>
  )
}

PatientList.propTypes = {
  title: PropTypes.string,
  list_type: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf(Object.values(REGISTER_COLUMNS)).isRequired,
      settings: PropTypes.shape({
        column: PropTypes.object,
        requestArgs: PropTypes.func,
      }),
    }),
    PropTypes.oneOf(Object.values(REGISTER_COLUMNS)),
  ])).isRequired,
  getPatientCardLinkPath: PropTypes.func.isRequired,
}

export const PatientListWithAudit = withMntAudit(PatientList)
