import { createAsyncThunk } from '@reduxjs/toolkit'
import { remotesManager, withAuthorization } from '@src/remotes'
import { setUserPreferences, selectUserPreferences } from '@src/store'
import { extraCreator, thunkCreator } from '@src/utils'
import { selectAccessToken } from '../security'
import * as models from './models'

export const [
  getUiSettings, getUiSettingsExtra,
] = thunkCreator(models.GET_UI_SETTINGS, '/admin/users/settings/get', {
  extractor: data => data?.app?.csp?.pages,
  extraBuilder: (thunk, key) => ({
    ...extraCreator(thunk, key),
    [thunk.fulfilled]: (state, action) => {
      state[key].loading = false
      state[key].loaded = true
      state[key].error = null
      state[key].payload = action.payload
      state[models.USER_PREFERENCES] = action.payload || {}
    },
  }),
})

export const postUiSettings: any = createAsyncThunk(
  `REQUEST/${models.POST_UI_SETTINGS}`,
  async (args = null, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(setUserPreferences(args
        ? {
          ...selectUserPreferences(getState()),
          [args.pageKey]: args?.newPreferences,
        }
        : {}))

      await remotesManager.BACKEND_API.post('/admin/users/settings/set', args
        ? {
          settings: args?.newPreferences,
          path: `app,csp,pages,${args?.pageKey}`,
        } : null, withAuthorization(selectAccessToken(getState())))
    } catch (e) {
      return rejectWithValue(e.response.data.error)
    }
  }
)

export const [
  getAppSettings, getAppSettingsExtra,
] = thunkCreator(models.APP_SETTINGS, '/admin/subsystems/settings/get', {
  extractor: d => d?.csp || {},
})

const extraReducers = {
  ...getUiSettingsExtra,
  ...getAppSettingsExtra,
  ...extraCreator(postUiSettings, models.POST_UI_SETTINGS),
}

export default extraReducers
