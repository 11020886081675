import { isNil } from 'lodash'
import moment from 'moment'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button, Combobox, DataGrid, DateRangePicker, DocStatusTag, TextInput } from '@src/components'
import { SORT_ORDER, URLS, NOT_SET } from '@src/constants'
import {
  loadNpaList, loadNpaListCount, selectDocProfilesModel,
  selectDocTypesModel, selectNpaListCountModel, selectNpaListModel,
} from '@src/modules/methodology/store'
import { isValidMomentDate } from '@src/utils'

import { useDebounce } from '@src/hooks'
import { UpdateTimestamp } from '../UpdateTimestamp/UpdateTimestamp'
import { MethTabs } from '../MethTabs/MethTabs'
import './NpaTable.scss'

const CHUNK_SIZE  = 100

export const NpaTable = () => {
  const [gridValue, setGridValue] = useState(null)

  const npaListModel = useSelector(selectNpaListModel)
  const docProfilesModel = useSelector(selectDocProfilesModel)
  const docTypesModel = useSelector(selectDocTypesModel)
  const npaListCountModel = useSelector(selectNpaListCountModel)

  const history = useHistory()
  const dispatch = useDispatch()

  const handleSearch = useCallback((value, rowCount = 0) => {
    const listControl = {
      filters: listFilters(value),
      sorts: [{ field: value.sort.column, sort: value.sort.order }],
      range: { chunk_start:  rowCount, chunk_end: rowCount + CHUNK_SIZE - 1 },
    }

    dispatch(loadNpaListCount({ control: { ...listControl, count: true } }))
    dispatch(loadNpaList({ control: listControl }))
  }, [dispatch])

  const debouncedHandleSearch = useDebounce(handleSearch, 500)

  const listFilters = (value) => {
    return [
      ...(!isNil(value?.filters.document_status)
        ? [{ field: 'document_status', value: value?.filters.document_status, operator: '=', invalid: false }] : []),
      ...(!isNil(value?.filters?.form_caption)
        ? [{ field: 'form_caption', value: value?.filters?.form_caption, operator: '=', invalid: false }] : []),
      ...(!isNil(value?.filters?.number)
        ? [{ field: 'number', value: value?.filters?.number, operator: 'like_both' }] : []),
      ...(!isNil(value?.filters?.name_short)
        ? [{ field: 'name_short', value: value?.filters?.name_short, operator: 'like_both' }] : []),
      ...(!isNil(value?.filters?.profile_caption)
        ? [{ field: 'profile_caption', value: value?.filters?.profile_caption === -1 ? null : value?.filters?.profile_caption, operator: '=', invalid: false }] : []),
      ...(!isNil(value?.filters?.revision_effective_date?.[0])
        ? [{ field: 'revision_effective_date', value: moment(value?.filters?.revision_effective_date?.[0]).local().toISOString(true), operator: '>=', invalid: false }] : []),
      ...(!isNil(value?.filters?.revision_effective_date?.[1])
        ? [{ field: 'revision_effective_date', value: moment(value?.filters?.revision_effective_date?.[1]).local().toISOString(true), operator: '<=', invalid: false }] : []),
    ]
  }

  const typesNpa = useMemo(() => {
    return {
      document_status: [
        { name: 'Действующий' },
        { name: 'Новый' },
        { name: 'Опубликован' },
        { name: 'Утратил силу' },
      ].map(data => ({
        label: data.name,
        value: data.name,
      })),
      form_caption: (docTypesModel.payload?.data ?? []).map(data => ({
        label: data.caption,
        value: data.caption,
      })),
      profile_caption: [
        { label: NOT_SET, value: -1 },
        ...(docProfilesModel.payload?.data ?? []).map(data => ({
          label: data.caption,
          value: data.caption,
        })),
      ],
    }
  }, [docProfilesModel.payload?.data, docTypesModel.payload?.data])

  const tableHeaders = useMemo(() => {
    return {
      document_status: {
        width: 80,
        sort: true,
        dataIndex: 'document_status',
        dataRender: row => <DocStatusTag className='table-row-tag'>{row}</DocStatusTag>,
        header: 'Статус',
        // header: {
        //   title: 'Статус',
        //   component: Combobox,
        //   props: {
        //     placeholder: 'Все',
        //     allowClear: true,
        //     showSearch: true,
        //     options: typesNpa.document_status,
        //   },
        // },                                                 //VIMISVIMIS-21048 VIMISVIMIS-21051
      },
      form_caption: {
        width: 65,
        dataIndex: 'form_caption',
        sort: true,
        header: 'Форма',
        // header: {
        //   title: 'Форма',
        //   component: Combobox,
        //   props: {
        //     placeholder: 'Все',
        //     allowClear: true,
        //     showSearch: true,
        //     options: typesNpa.form_caption,
        //   },
        // },                                                 //VIMISVIMIS-21048 VIMISVIMIS-21051
      },
      // number: {
      //   width: 65,
      //   dataIndex: 'number',
      //   sort: true,
      //   header: {
      //     component: TextInput,
      //     title: '№',
      //     props: {
      //       allowClear: true,
      //       icon: 'search',
      //     },
      //     defaultValue: '',
      //   },
      // },                                                   //VIMISVIMIS-21048 VIMISVIMIS-21051
      name_short: {
        width: 250,
        dataIndex: 'name_short',
        sort: true,
        defaultSort: SORT_ORDER.ASC,
        header: {
          component: TextInput,
          title: 'Наименование НПА',
          props: {
            allowClear: true,
            icon: 'search',
          },
          defaultValue: '',
        },
      },
      profile_caption: {
        width: 100,
        dataIndex: 'profile_caption',
        sort: true,
        header: {
          component: Combobox,
          title: 'Профиль ОМП',
          props: {
            placeholder: 'Все',
            allowClear: true,
            showSearch: true,
            options: typesNpa.profile_caption,
          },
        },
      },
      revision_effective_date: {
        width: 80,
        dataIndex: 'revision_effective_date',
        sort: true,
        dataRender: row => isValidMomentDate(row)?.format('DD.MM.YYYY'),
        header: {
          component: DateRangePicker,
          title: 'Вступило в силу',
          props: {
            allowEmpty: [true, true],
            allowClear: true,
            disabledDate: c => !!c && !c.isBetween(moment('1900-01-01'), moment().add(1, 'day'), 'days'),
          },
        },
      },
    }
  }, [typesNpa.profile_caption])

  const handleRowDoubleClick = useCallback((row) => {
    history.push(`${URLS.METHODOLOGY__NPA__DATA}/${row.id}/information`)
  }, [history])

  const handleRowAuxClick = useCallback((row) => {
    window.open(`${URLS.METHODOLOGY__NPA__DATA}/${row.id}/information`, '_blank')
  }, [])

  const hasMoreToLoad = useMemo(() => {
    if(!npaListModel.loading && !npaListCountModel.loading &&
        npaListModel.loaded && npaListCountModel.loaded)
      return npaListModel.payload.data.length < npaListCountModel.payload.data[0]._count_

    return false
  }, [npaListCountModel, npaListModel])

  const handleLoadMore = useCallback(() => {
    if (!npaListModel.loading && hasMoreToLoad)
      handleSearch(gridValue, npaListModel.payload?.data?.length)
  }, [gridValue, handleSearch, hasMoreToLoad, npaListModel])

  const handleResetFilters = () => {
    setGridValue(null)
  }

  return (
    <div className='root-methodology-npa-list'>
      <MethTabs label='Порядки ОМП' link={URLS.METHODOLOGY__NPA}/>

      <div className='list-container'>
        <div className='list-controls'>
          <Button
            label='Сбросить фильтры'
            onClick={handleResetFilters}
            icon='close_cr_fr'
            disabled={!gridValue?.isDirty}
          />
          <UpdateTimestamp className='ml-auto'/>
        </div>
        <DataGrid
          columns={tableHeaders}
          data={npaListModel.payload?.data ?? []}
          rowKey='id'
          loading={npaListModel.loading}
          error={npaListModel.error}
          value={gridValue}
          onReset={handleSearch}
          onChange={setGridValue}
          onFilterChange={debouncedHandleSearch}
          onSortChange={handleSearch}
          onRowDoubleClick={handleRowDoubleClick}
          onRowAuxClick={handleRowAuxClick}
          onScrolledToBottom={handleLoadMore}
        />
      </div>
    </div>
  )
}
