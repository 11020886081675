import { useCallback, useState } from 'react'

/**
 * @description Простой хук для использования с компонентом Switch
 * @param {Boolean} initial - начальное состояние переключателя
 * @returns {[value: Boolean, Function, Function]}
 */
export const useSwitch = (initial) => {
  const [value, setValue] = useState(initial)
  const handleSwitch = useCallback(() => {
    setValue(!value)
  }, [value])

  return [value, handleSwitch, setValue]
}
