import { createSelector } from 'reselect'
import * as models from './models'

export const selectJournal = state => state.journal

export const selectJournalSemdListModel = createSelector(
  selectJournal,
  journal => journal[models.JOURNAL_SEMD_LIST],
)

export const selectMessageAttributesModel = createSelector(
  selectJournal,
  journal => journal[models.JOURNAL_MESSAGE_ATTRIBUTES],
)

export const selectOutgoingMessageModel = createSelector(
  selectJournal,
  journal => journal[models.JOURNAL_OUTGOING_MESSAGE],
)

export const selectProcessingLogModel = createSelector(
  selectJournal,
  journal => journal[models.JOURNAL_PROCESSING_LOG],
)

export const selectMsgIndexModel = createSelector(
  selectJournal,
  journal => journal[models.JOURNAL_MSG_INDEX],
)

export const selectJournalAuditListModel = createSelector(
  selectJournal,
  journal => journal[models.JOURNAL_AUDIT_LIST],
)

export const selectExtSystemsListModel = createSelector(
  selectJournal,
  journal => journal[models.JOURNAL_EXT_SYSTEMS_LIST],
)

export const selectSemdDocTypeListModel = createSelector(
  selectJournal,
  journal => journal[models.JOURNAL_SEMD_DOC_TYPE_LIST],
)

export const selectSemdStatusListModel = createSelector(
  selectJournal,
  journal => journal[models.JOURNAL_SEMD_STATUS_LIST],
)

export const selectAuditWriteModel = createSelector(
  selectJournal,
  journal => journal[models.JOURNAL_SEMD_STATUS_LIST],
)

export const selectJournalFilters = createSelector(
  selectJournal,
  journal => journal[models.JOURNAL_FILTERS],
)
