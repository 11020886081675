export const EHR = 'ehr' // ПС01 - Хранение
export const PROCESSING = 'processing' // ПС02 - Обработка
export const JOURNAL = 'journal' // ПС03 - Журналирование
export const ADMIN = 'admin' // ПС04 - Администрирование
export const INTEGRATION = 'integration' // ПС05 - Интеграция
export const PASSPORT = 'passport' // ПС06 - Паспорт службы
export const MDS = 'mds' // ПС07 - Мониторинг деятельности службы
export const PATIENTMON = 'patientmon' // ПС08 - Мониторинг пациентов
export const KM = 'km' // ПС09 - Контрольные мероприятия
export const METHOD = 'method' // ПС10 - Методология
export const PSI = 'psi' // ПС11 - Профильно справочная информация
export const ANALYTIC = 'analytic' // ПС12 - Аналитика
export const DESKTOP = 'desktop' // ПС13 - Рабочие пространства
export const PERSONAL = 'personal' // ПС14 - Персональные данные
