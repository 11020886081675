import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { Table } from '@src/components'
import { withMntAudit } from '@src/hoc'
import './OutgoingTab.scss'

const OutgoingTab = ({ outgoing, showModal }) => {

  const headers = useMemo(() => {
    return [
      {
        field: 'created_on',
        headerName: 'Дата создания',
        width: 160,
      },
      {
        field: 'msg_type',
        headerName: 'Тип сообщения',
        width: 220,
      },
      {
        field: 'msg_text',
        headerName: 'Текст сообщения',
      },
    ]
  }, [])

  const renderLongData = useCallback((data) => {
    return data && data.length > 150
      ? () => <div className='table-action' onClick={() => showModal(data)}>Просмотр</div>
      : data
  }, [showModal])

  const rows = useMemo(() => {
    return outgoing.map(out => ({
      created_on: out.created_on
        ? moment(out.created_on).local().format('DD.MM.YYYY HH:mm:ss')
        : '-',
      msg_type: out.msg_type,
      msg_text: renderLongData(out.msg_text),
    }))
  }, [outgoing, renderLongData])

  return (
    <div className='root-journal-semd-details-outgoing-tab'>
      <Table
        headers={headers}
        rows={rows}
      ></Table>
    </div>
  )
}

export const OutgoingTabWithAudit = withMntAudit(OutgoingTab)
