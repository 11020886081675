import { isNumber } from 'lodash'
import React, { useCallback, useContext, useImperativeHandle, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { selectGraph, selectGraphDescriptionModel } from '@src/modules/methodology/store'
import { GRAPH_STATUSES, TAG_COLORS, URLS } from '@src/constants'
import { Accordion, EmptyState, Icon, LoadedOrError, Tag } from '@src/components'
import './TabRouteDescription.scss'
import { withMntAudit } from '@src/hoc'
import { AccessContext } from '@src/context'

const TabRouteDescription = () => {
  const stagesRef = useRef([])
  const graphStatusId = useSelector(selectGraph).payload?.data[0]?.graph_ext_info?.graph_ext_status_id

  const graphDescriptionModel = useSelector(selectGraphDescriptionModel)
  const blocks = graphDescriptionModel?.payload?.data[0]?.route_stage

  const onClickMenu = (indexBlock, indexItem) => {
    stagesRef.current[indexBlock].expand(indexItem)
  }

  return (
    <LoadedOrError
      error={graphDescriptionModel.error}
      loading={graphDescriptionModel.loading}
      loaded={graphDescriptionModel.loaded}
    >
      {
        blocks?.length
          ? <div className='root-route-description-tab'>
            <div className='route-description-tab-navigation-menu scroll-y mr-16'>
              {
                blocks?.map((block, indexBlock) => {
                  return <div key={block.stage_id} className='route-description-tab-navigation-menu-container'>
                    <div className='route-description-tab-navigation-menu-item route-description-tab-navigation-menu-item-header' onClick={() => onClickMenu(indexBlock)}>
                      {block.stage_caption}
                    </div>
                    {
                      block.route_state.map((item, indexItem) => {
                        return <div key={item.route_state_id} className='route-description-tab-navigation-menu-item' onClick={() => onClickMenu(indexBlock, indexItem)}>
                          {item.name}
                        </div>
                      })
                    }
                  </div>
                })
              }
            </div>
            <div className='route-description-tab-content'>
              {
                blocks.map((block, index) => (<StageAccordion key={block.stage_id} ref={el => stagesRef.current[index] = el} block={block} graphStatusId={graphStatusId}/>))
              }
            </div>
          </div>
          : <EmptyState caption='Отсутствуют этапы маршрута'/>
      }
    </LoadedOrError>
  )
}

const StageAccordion = React.forwardRef(({ block, graphStatusId }, ref) => {
  const accordionListRef = useRef(null)
  const accordionRef = useRef(null)

  useImperativeHandle(ref, () => {
    return {
      expand (itemIndex) {
        accordionRef.current.expand()

        if (isNumber(itemIndex)) {
          accordionListRef.current.expand(itemIndex)
        } else {
          accordionRef.current.scrollIntoView()
        }
      },
    }
  }, [])

  return <Accordion
    ref={accordionRef}
    key={block.stage_id}
    className='route-description-tab-content-accordion'
    title={block.stage_caption}
    titleJsx={
      <div className='d-flex align-items-center'>
        {
          block.route_state.find(item => item.has_regional_condition)
            ? <Icon className='route-description-tab-content-location-icon mr-16' icon='location_gray' />
            : null
        }
      Подэтапов: <span className='mr-16'>{block.route_state.length}</span>
      </div>
    }
    dataComponent={<AccordionList stage_id={block.stage_id} graphStatusId={graphStatusId} blockChildren={block.route_state} ref={accordionListRef} />}
    defaultCollapsed
  />
})

const AccordionList = React.forwardRef(({ graphStatusId, blockChildren, stage_id }, ref) => {
  const accessContext = useContext(AccessContext)
  const { graph_id, version } = useParams()
  const history = useHistory()

  const refs = useRef([])

  useImperativeHandle(ref, () => {
    return {
      expand (itemIndex) {
        if (isNumber(itemIndex)) {
          refs.current[itemIndex].expand()
          setTimeout(() => {
            refs.current[itemIndex].scrollIntoView()
          }, 0)
        }
      },
    }
  }, [])

  const openRegConditions = useCallback((e, route_state) => {
    e.stopPropagation()
    history.push(`${URLS.METHODOLOGY__ROUTES}/${graph_id}/${version}/conditions/${stage_id}/${route_state.route_state_id}`)
  }, [graph_id, history, stage_id, version])

  return blockChildren.map((item, index) => {
    return <Accordion
      key={item.route_state_id}
      ref={el => refs.current[index] = el}
      className='route-description-tab-content-accordion'
      index={item.sort}
      title={item.name}
      titleJsx={
        <>
          {
            graphStatusId === GRAPH_STATUSES.DRAFT.status_id && !accessContext.readonly
              ? item.has_regional_condition
                ? <Icon onClick={e => openRegConditions(e, item)} className='route-description-tab-content-icon mr-16' icon='edit_green' />
                : <Icon onClick={e => openRegConditions(e, item)} className='route-description-tab-content-icon mr-16' icon='plus_cr_fr' />
              : item.has_regional_condition
                ? <Icon onClick={e => openRegConditions(e, item)} className='route-description-tab-content-icon mr-16' icon='eye' />
                : null
          }
        </>
      }
      dataComponent={
        <div>
          <p className='route-description-tab-content-tag-title'>Описание подэтапа</p>
          <span>{item?.description || item?.name}</span>
          {item?.medication_service?.service_list ? <p className='route-description-tab-content-tag-title'>{item?.medication_service?.activity_name}</p> : null }
          {item?.medication_service?.service_list?.length ? item?.medication_service?.service_list.map(d => (
            <div className='route-description-tab-content-tag-container' key={d.service_code}>
              <Tag color={TAG_COLORS.GREY_100}>{d.service_code}</Tag>
              <span>{d.service_name}</span>
            </div>
          )) : null}
        </div>
      }
      isBlueGray
      defaultCollapsed
    />
  })
})

export const TabRouteDescriptionWithAudit = withMntAudit(TabRouteDescription)

TabRouteDescriptionWithAudit.tabTitle = 'Описание маршрута'
TabRouteDescriptionWithAudit.tabPath = 'description'
