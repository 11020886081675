import cn from 'classnames'
import { useCallback } from 'react'

import { Button, Tag } from '@src/components'
import { AUDIT_PS, TAG_COLORS } from '@src/constants'
import { modalService } from '@src/services'

import { ImportModalWithAudit } from './ImportModal/ImportModal'
import { FrnsiListControlsProps } from './types'
import './NsiFrnsiListPage.scss'

export const FrnsiListControls = ({ canResetFilters, onResetFilters, isDictLoading, selectedDicts, dictCount } : FrnsiListControlsProps) => {
  const handleImportClick = useCallback(() => {
    modalService.open(ImportModalWithAudit, {
      dicts: selectedDicts,
      auditPs: AUDIT_PS.PSI,
      auditMessage: 'Импорт справочников ФРНСИ',
      auditDescription: { dicts: selectedDicts.map(d => d.full_name) },
    })
  }, [selectedDicts])

  return <div className='frnsi-controls'>
    <div className={cn('list-counter', isDictLoading && 'loading')}>
      <span>Справочников:</span>
      <Tag className='ml-8' color={TAG_COLORS.LIGHT_GREEN}>{dictCount}</Tag>
    </div>

    {
      canResetFilters
        ? <Button
          className='ml-16'
          label='Сбросить фильтры'
          onClick={onResetFilters}
          icon='close_cr_fr'
        />
        : null
    }

    {
      selectedDicts.length
        ? <div className='list-counter ml-auto'>
          <span>Выбрано:</span>
          <Tag className='ml-8' color={TAG_COLORS.LIGHT_GREEN}>{selectedDicts.length}</Tag>
        </div>
        : null
    }

    <Button
      className={cn(selectedDicts.length ? 'ml-24' : 'ml-auto')}
      disabled={!selectedDicts.length}
      label='Импортировать'
      onClick={handleImportClick}
      icon='import_white'
      isSuccess
    />
  </div>
}
