import { Component } from 'react'
import { modalService } from '@src/services'
import './AppModalStack.scss'

export default class AppModalStack extends Component {
  constructor (props) {
    super(props)

    this.state = {
      stack: [],
    }
  }

  replaceStack = (stack) => {
    this.setState({ stack })
  }

  componentDidMount () {
    modalService.on('open', this.replaceStack)
    modalService.on('close', this.replaceStack)
    modalService.on('remove', this.replaceStack)
  }

  componentWillUnmount () {
    modalService.off('open', this.replaceStack)
    modalService.off('close', this.replaceStack)
    modalService.off('remove', this.replaceStack)
  }

  closeModal (modal) {
    modalService.close(modal)
  }

  renderModal = (modal) => {
    const { component: Component, modalId, open, props = {} } = modal
    return <Component
      key={modalId}
      open={open}
      onClose={() => this.closeModal(modal)}
      {...props}
    />
  }

  render () {
    const { stack } = this.state
    return stack.map(this.renderModal)
  }
}
