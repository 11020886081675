import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import moment from 'moment'
import { Combobox, DateRangePicker, DocStatusTag, TextInput } from '@src/components'
import { selectMethRouteStatusList, selectMethVmclList } from '@src/modules/methodology/store'
import { SORT_ORDER } from '@src/constants'

export const useMethRoutesColumns = () => {
  const routeStatusList = useSelector(selectMethRouteStatusList)
  const vmclList = useSelector(selectMethVmclList)

  const columns = useMemo(() => {
    return {
      graph_status_id: {
        width: 100,
        dataIndex: 'graph_status_id',
        sort: 'graph_status_name',
        header: {
          title: 'Статус',
          component: Combobox,
          props: {
            allowClear: true,
            options: routeStatusList.payload.data.map(opt => ({
              value: opt.id,
              label: opt.caption,
            })),
            placeholder: 'Все',
            mode: 'multiple',
          },
        },
        defaultSort: SORT_ORDER.ASC,
        dataRender: (_, row) => <DocStatusTag>{row.graph_status_name}</DocStatusTag>,
        enabled: true,
      },
      graph_caption: {
        width: 400,
        dataIndex: 'graph_caption',
        sort: true,
        enabled: true,
        header: {
          title: 'Наименование маршрута',
          component: TextInput,
          defaultValue: '',
          props: {
            allowClear: true,
            icon: 'search',
            isInvalid: fullname => fullname && fullname.length !== 0 && fullname.length < 3,
          },
        },
      },
      graph_vmcl_id: {
        width: 100,
        dataIndex: 'graph_vmcl_id',
        sort: 'graph_vmcl_name',
        enabled: true,
        header: {
          title: 'Направление ОМП',
          component: Combobox,
          props: {
            allowClear: true,
            options: vmclList.payload.data.map(opt => ({
              value: opt.id,
              label: opt.short_name,
            })),
            placeholder: 'Все',
          },
        },
        dataRender: (_, row) => row.graph_vmcl_name,
      },
      started_on: {
        width: 150,
        dataIndex: 'started_on',
        sort: true,
        enabled: true,
        header: {
          title: 'Дата начала действия',
          component: DateRangePicker,
          defaultValue: [null, null],
          props: {
            allowClear: true,
            allowEmpty: [true, true],
          },
        },
        dataRender: date => date ? moment(date).format('DD.MM.YYYY') : null,
      },
      expirated_on: {
        width: 150,
        dataIndex: 'expirated_on',
        sort: true,
        enabled: true,
        header: {
          title: 'Дата окончания действия',
          component: DateRangePicker,
          defaultValue: [null, null],
          props: {
            allowClear: true,
            allowEmpty: [true, true],
          },
        },
        nullSearch: true,
        dataRender: date => date ? moment(date).format('DD.MM.YYYY') : null,
      },
      graph_ext_existence: {
        width: 100,
        dataIndex: 'graph_ext_existence',
        sort: true,
        enabled: true,
        header: {
          title: 'Наличие региональной маршрутизации',
          component: Combobox,
          props: {
            allowClear: true,
            options: [
              { label: 'Да', value: true },
              { label: 'Нет', value: false },
            ],
            placeholder: 'Все',
          },
        },
        nullSearch: true,
        dataRender: (_, row) => row.graph_ext_existence_name,
      },
      graph_ext_status_id: {
        width: 150,
        dataIndex: 'graph_ext_status_id',
        sort: 'graph_ext_status_name',
        enabled: true,
        header: {
          title: 'Статус региональной маршрутизации',
          component: Combobox,
          props: {
            mode: 'multiple',
            allowClear: true,
            options: routeStatusList.payload.data.map(opt => ({
              value: opt.id,
              label: opt.caption,
            })),
            placeholder: 'Все',
          },
        },
        nullSearch: true,
        dataRender: (_, row) => row.graph_ext_status_id ? <DocStatusTag>{row.graph_ext_status_name}</DocStatusTag> : null,
      },
      document_name: {
        width: 200,
        dataIndex: 'document_name',
        dataRender: document_name => document_name?.join(', '),
        sort: true,
        enabled: true,
        header: {
          title: 'Наименование и номер регионального НПА',
          component: TextInput,
          props: {
            allowClear: true,
            icon: 'search',
            isInvalid: fullname => fullname && fullname.length !== 0 && fullname.length < 3,
          },
        },
        nullSearch: true,
      },
      graph_ext_version: {
        width: 100,
        dataIndex: 'graph_ext_version',
        sort: true,
        enabled: true,
        header: {
          title: 'Версия регионального маршрута',
          component: TextInput,
          props: {
            allowClear: true,
            icon: 'search',
            isInvalid: fullname => fullname && fullname.length !== 0 && fullname.length < 3,
          },
        },
        nullSearch: true,
      },
    }
  }, [routeStatusList.payload.data, vmclList.payload.data])

  return columns
}
