import { Route, Switch } from 'react-router-dom'
import { AUDIT_PS, URLS } from '@src/constants'
import { InternalError404 } from '@src/components'
import { JournalSemdDetailsPageWithAudit } from '../JournalSemdDetailsPage/JournalSemdDetailsPage'
import { JournalSemdListPageWithAudit } from '../JournalSemdListPage/JournalSemdListPage'
import './JournalSemdPage.scss'

export const JournalSemdPage = () => {
  return (
    <div className='root-journal-semd-page'>
      <Switch>
        <Route path={`${URLS.JOURNAL__SEMD}/:msgId/*`}>
          <JournalSemdDetailsPageWithAudit
            auditPs={AUDIT_PS.JOURNAL}
            auditMessage='Просмотр карточки СЭМД'
          />
        </Route>
        <Route path={URLS.JOURNAL__SEMD}>
          <JournalSemdListPageWithAudit
            auditPs={AUDIT_PS.JOURNAL}
            auditMessage='Просмотр Журнала СЭМД'
          />
        </Route>
        <Route component={InternalError404}/>
      </Switch>
    </div>
  )
}
