import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router'

import { selectAppSettingsModel, selectCurrentRole, switchSidebar } from '@src/store'
import config from '@src/config'
import { Icon, MultiIcon, TextInput } from '@src/components'
import { normalizeStr } from '@src/utils'

import SidebarMenuEntry from './SidebarMenuEntry/SidebarMenuEntry'
import SidebarSubmenuEntry from './SidebarSubmenuEntry/SidebarSubmenuEntry'
import { menuFactory } from './menu'
import './AppSidebar.scss'

class AppSidebar extends Component {
  static propTypes = {
    currentRole: PropTypes.object,
    collapsed: PropTypes.bool,
    switchSidebar: PropTypes.func,
    appSettingsModel: PropTypes.object,
  }

  constructor (props) {
    super(props)
    this.menu = menuFactory(props.appSettingsModel.payload)

    this.state = {
      selectedSubmenu: null,
      selectedThirdMenu: null,
      searchStr: '',
    }
  }

  handleOnClickEntry = (event, entry) => {
    if(entry.label === config.integrations.akineo_mis.menu_name)
      return window.open(config.integrations.akineo_mis.uri, '_blank')

    event.stopPropagation()
    this.setState({ selectedSubmenu: entry.label, selectedThirdMenu: null })
    this.props.collapsed && this.props.switchSidebar()
  }

  handleOnClickSubmenuEntry = (e, entry) => {
    e.stopPropagation()

    if(!entry?.children?.length) {
      this.props.switchSidebar()
    }
    else
      this.setState({ selectedThirdMenu: entry.label })
  }

  handleOnClickSidebar = (e) => {
    e.stopPropagation()
    this.setState({ selectedSubmenu: null, selectedThirdMenu: null, searchStr: '' })
    this.props.switchSidebar()
  }

  handleClickOutside = () => {
    this.props.switchSidebar()
    this.setState({ searchStr: '' })
  }

  get filteredMenu () {
    const search = normalizeStr(this.state.searchStr)

    return this.menu.reduce((acc, cur) => {
      return normalizeStr(cur.label).includes(search)
        || cur.children.find(child => normalizeStr(child.label).includes(search))
        || cur.children.find(e => !!e?.children?.length && !!e.children.find(c => normalizeStr(c.label).includes(search)))
        ? [
          ...acc,
          {
            ...cur,
            children: [
              ...cur.children.filter(c => normalizeStr(c.label).includes(search)
                || (!!c?.children?.length && !!c.children.filter(k => normalizeStr(k.label).includes(search)))
              ),
            ],
          },
        ]
        : acc
    }, [])
  }

  get submenu () {
    const entry = this.filteredMenu.find(entry => entry.label === this.state.selectedSubmenu)

    return entry ? entry.children : []
  }

  get thirdMenu () {
    const search = normalizeStr(this.state.searchStr)
    let entry = []

    for(let i = 0; i < this.filteredMenu.length; i++) {
      if(!this.filteredMenu[i]?.children?.length) continue
      else
        entry = [...entry, this.filteredMenu[i].children.find(e => e.label === this.state.selectedThirdMenu)]
    }

    entry = entry.filter(i => i)[0]?.children ? entry.filter(i => i)[0]?.children : []

    return entry.filter(i => !search ? true : normalizeStr(i.label).includes(search))
  }

  render () {
    return (
      <>
        <div
          className={cn(
            'root-app-sidebar',
            !this.state.selectedSubmenu && !this.state.selectedThirdMenu && 'root-app-sidebar-nosubmenu',
            this.state.selectedSubmenu && !this.state.selectedThirdMenu && 'root-app-sidebar-selected-submenu',
            this.state.selectedThirdMenu && 'root-app-sidebar-selected-three-submenu',
            this.props.collapsed && 'root-app-sidebar-collapsed',
          )}
          onClick={this.handleOnClickSidebar}
        >
          <div className='app-sidebar'>
            <div className='sidebar-menu-entry app-sidebar-icon-container'>
              <div className='app-sidebar-icon'>
                <MultiIcon
                  icon='menu'
                  onClick={switchSidebar}
                  className={this.props.collapsed ? 'sidebar-menu-icon open' : 'sidebar-menu-icon close'}
                  active={!this.props.collapsed}
                />
              </div>
              <p className='app-sidebar-icon-container-text'>Меню ЦСП</p>
            </div>
            {
              this.filteredMenu.map((entry, index) => (
                <SidebarMenuEntry
                  key={`sidebar-link-${index}`}
                  entry={entry}
                  onClick={e => this.handleOnClickEntry(e, entry)}
                  allowAcl={entry.acl}
                />
              ))
            }
            <div className='app-sidebar-input-container'>
              <TextInput
                placeholder='Поиск'
                tabIndex={this.props.collapsed ? -1 : 0}
                className='app-sidebar-input-container-input'
                icon='search'
                onClickCapture={e => e.stopPropagation()}
                value={this.state.searchStr}
                onChange={e => this.setState({ searchStr: e })}
              />
            </div>
            <div className='app-sidebar-copyright'>
              <div className='app-sidebar-copyright-copy'>
                <Icon icon='vector_130' />
                <p>ЦСП</p>
              </div>
              <div className='d-flex flex-direction-column align-items-center justify-content-center py-4 maxw-48 minh-24'>
                <NavLink onClick={e => e.stopPropagation()} to='/about' className='app-sidebar-copyright-ver'>v{config.version}</NavLink>
                {
                  config?.mode === 'development' && <>
                    <p className='app-sidebar-copyright-ver'>dev</p>
                    <p className='app-sidebar-copyright-ver'>{config.commit_hash}</p>
                  </>
                }
              </div>
            </div>
          </div>
          {
            !!(this.state.selectedSubmenu || this.state.selectedThirdMenu)
              && (
                <div className='app-sidebar-submenu'>
                  <div onClick={e => e.stopPropagation()} className='app-sidebar-submenu-entry' />
                  {
                    this.submenu.filter(entry => entry.enabled).map((entry, index) => (
                      <SidebarSubmenuEntry
                        key={`submeny-link-${index}`}
                        entry={entry}
                        allowAcl={entry.acl}
                        onClick={e => this.handleOnClickSubmenuEntry(e, entry)}
                      />
                    ))
                  }
                </div>
              )
          }
          {
            !!this.state.selectedThirdMenu
              && (
                <div className='app-sidebar-submenu third-menu'>
                  <div onClick={e => e.stopPropagation()} className='app-sidebar-submenu-entry' />
                  {
                    this.thirdMenu.filter(entry => entry.enabled).map((entry, index) => (
                      <SidebarSubmenuEntry
                        key={`submeny-link-${index}`}
                        entry={entry}
                        allowAcl={entry.acl}
                        onClick={e => this.handleOnClickSubmenuEntry(e, entry)}
                      />
                    ))
                  }
                </div>
              )
          }
        </div>
        <div
          className={cn('app-sidebar-shadow', !this.props.collapsed ? null : 'app-sidebar-shadow-hidden')}
          onClick={this.handleClickOutside}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    collapsed: !state.ui.isSidebarOpen,
    currentRole: selectCurrentRole(state),
    appSettingsModel: selectAppSettingsModel(state),
  }
}

const mapDispatchToProps = dispatch => ({
  switchSidebar: () => dispatch(switchSidebar()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppSidebar))
