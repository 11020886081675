import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { getUiSettings, postUiSettings, selectGetUiSettingsModel, selectUserPreferences } from '@src/store'
import { useMountEffect } from './useMountEffect'

/**
 * @function useUserPreferences
 * @summary Получение и обновление пользовательских настроек
 * @description Хук предоставляет объект пользовательских настроек для
 * текущей страницы с возможностью их изменения и автоматическим сохранением на сервере
 * Текущая страница определяется по path параметру <Route>
 * setPreferences позволяет определять только подмножество настроек, например
 * preferences = { a: 1, b: 2 }
 * setPreferences({ b: 200, c: 300 })
 * результат: { a: 1, b: 200, c: 300 }
 * Для сброса всех настроек текущей страницы передать null или {} в setPreferences
 * @returns [preferences, setPreferences]
 */
export const useUserPreferences = () => {
  const routeMatch = useRouteMatch()
  const dispatch = useDispatch()
  const getUiSettingsModel = useSelector(selectGetUiSettingsModel)
  const userPreferences = useSelector(selectUserPreferences)

  useMountEffect(() => {
    if (!getUiSettingsModel.loaded && !getUiSettingsModel.loading) dispatch(getUiSettings())
  })

  const pageKey = useMemo(() => {
    return routeMatch.path
  }, [routeMatch.path])

  const pagePreferences = useMemo(() => {
    return userPreferences?.[pageKey] || {}
  }, [userPreferences, pageKey])

  const setPreferences = useCallback((pref: typeof pagePreferences | null) => {
    const newPreferences = Object.assign({}, pagePreferences, pref)

    dispatch(postUiSettings({ newPreferences, pageKey }))
  }, [pagePreferences, dispatch, pageKey])

  return [pagePreferences, setPreferences]
}
