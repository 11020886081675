import extraCommon from './common'
import extraOnko from './onko'
import extraSsz from './ssz'
import akineoSsz from './akineo'
import extraProf from './prof'
import extraDeprecated from './deprecated'
import infection from './infection'

export * from './common'
export * from './onko'
export * from './ssz'
export * from './akineo'
export * from './prof'
export * from './deprecated'
export * from './infection'

export default {
  ...extraCommon,
  ...extraOnko,
  ...extraSsz,
  ...akineoSsz,
  ...extraProf,
  ...extraDeprecated,
  ...infection,
}
