import { isNil } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { Accordion, Button, Card, Combobox, Modal, Tag } from '@src/components'
import { TAG_COLORS } from '@src/constants'
import { loadGraphList, selectGraphListModel } from '@src/modules/methodology/store'
import { remotesManager } from '@src/remotes'
import { modalService } from '@src/services'
import { withMntAudit } from '@src/hoc'
import { RevisionSelector } from '../../RevisionSelector/RevisionSelector'
import './TabRoutes.scss'

const initialState = {
  selectedRouteId: null,
  selectedRouteData: null,
  isLoading: false,
  isError: false,
  img: null,
}

class _TabRoutes extends React.Component {
  static tabTitle = 'Маршруты'

  static tabPath = 'routes'

  constructor (props) {
    super(props)

    this.state = { ...initialState }
  }

  componentDidMount () {
    if (this.props.selectedRevisionId) this.graphLoad(this.props.selectedRevisionId)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.selectedRevisionId !== this.props.selectedRevisionId) {
      this.setState({ ...initialState })
      if (this.props.selectedRevisionId) this.graphLoad(this.props.selectedRevisionId)
    }
  }

  graphLoad = revisionId => revisionId && this.props.loadGraphList({ revision_id: revisionId })

  renderAccordionData (route_state_item) {
    return (
      <div>
        <p className='routes-npa-card-tag-title'>Описание этапа</p>
        <span>{route_state_item?.description || route_state_item?.name}</span>
        {route_state_item?.service_list ? <p className='routes-npa-card-tag-title'>Медицинские услуги</p> : null }
        {route_state_item?.service_list ? route_state_item.service_list.map(d => (
          <div className='routes-npa-card-tag-container' key={d.service_code}>
            <Tag color={TAG_COLORS.LAVENDER}>{d.service_code}</Tag>
            <span>{d.service_name}</span>
          </div>
        )) : null}
      </div>
    )
  }

  getAccordionSubData = (data) => {
    if(!data) return null

    return data.route_state.map((d, i) => (
      <Accordion
        key={`${this.state.selectedRouteId}-${i}`}
        className='root-routes-accordion'
        title={d.label}
        dataComponent={this.renderAccordionData(d)}
        isBlueGray
        index={i+1}
      />
    ))
  }

  accordionsList = () => {
    if(!this.props.selectGraphListModel.payload && this.state.selectedRouteData)
      return null

    return this.state.selectedRouteData?.graph_info ? this.state.selectedRouteData.graph_info.map((d, i) => (
      <Accordion
        key={`routes-npa-acordion-sub-${i}`}
        className='root-routes-accordion'
        title={d.stage_caption}
        titleJsx={<div>Подэтапов: <span className='mr-16'>{d.route_state.length}</span></div>}
        dataComponent={this.getAccordionSubData(d)}
      />
    )) : null
  }

  renderRouteImage = () => {
    return <div className='npa-route-image'>
      <img src={this.state.img} alt=''/>
    </div>

  }

  getRouteOptions = () => {
    return this.props.selectGraphListModel.payload && this.props.selectedRevisionId
      ? this.props.selectGraphListModel.payload.map(d => (
        {
          label: d.caption,
          value: d.graph_id,
        }))
      : []
  }

  mainCardHeaderData = () => {
    const routeOptions = this.getRouteOptions()

    return (
      <div className='npa-routes-main-card-header-data'>
        <div className='routes-main-card-header-data-text-input'>
          <Combobox
            className='tab-routes-combox'
            options={routeOptions}
            onChange={e => this.setState({
              selectedRouteId: e,
              selectedRouteData: this.props.selectGraphListModel.payload.filter(i => i.graph_id === e)[0],
              isError: false,
              isLoading: false,
            }, this.loadRouteImg)}
            placeholder={routeOptions.length ? 'Выберите нозологию' : 'Нет доступных нозологий'}
            value={this.state.selectedRouteId}
            loading={this.props.selectGraphListModel.loading}
            disabled={!this.props.selectGraphListModel.payload || !routeOptions.length}
          />
        </div>
        <div className='routes-main-card-header-data-container'>
          <div className='routes-main-card-header-data-row-left'>
            {!!this.state.selectedRouteData?.mkb_str && <span className='routes-main-card-header-data-text'>МКБ-10</span>}
            <div>
              {
                !!this.state.selectedRouteData?.mkb_str
                  && <Tag className='npa-routes-tag' title={this.state.selectedRouteData.mkb_str} color={TAG_COLORS.LAVENDER}>
                    {this.state.selectedRouteData.mkb_str}
                  </Tag>
              }
            </div>
          </div>
          <Button
            className='routes-main-card-header-data-row-right'
            label='Диаграмма маршрута'
            icon='route'
            onClick={() => modalService.open(Modal, {
              width: '80vw',
              title: this.state.selectedRouteData?.caption,
              children: this.renderRouteImage(this.state.img),
            })}
            disabled={isNil(this.state.selectedRouteId) || this.state.isError}
            loading={this.state.isLoading}
          />
        </div>
      </div>
    )
  }

  loadRouteImg = () => {
    if(!this.state.selectedRouteData?.uuid_code) this.setState({ isError: true })
    else {
      this.setState({ isLoading: true })
      remotesManager.BACKEND_API.get(`/method/get_graph_image/${this.state.selectedRouteData.uuid_code}`,
        { data: null, headers: { 'Content-Type': 'image' }, responseType: 'blob' })
        .then(responce => this.setState({ img: URL.createObjectURL(responce.data) }))
        .catch((err) => { this.setState({ isError: true }); console.error(err) })
        .finally(() => this.setState({ isLoading: false }))
    }
  }

  handleRevisionChange = (revisionId) => {
    this.props.onRevisionChange(revisionId)
    this.graphLoad(revisionId)
  }

  render () {
    return (
      <div className='root-tab-routes'>
        <div className='root-routes-card-container'>
          <RevisionSelector
            onRevisionChange={this.handleRevisionChange}
            selectedRevisionId={this.props.selectedRevisionId}
          />
        </div>
        <div className='root-routes-accordion-data-container'>
          <Card
            className='root-routes-main-card'
            headerFuncPointer={this.mainCardHeaderData}
            dataComponent={this.accordionsList}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    selectGraphListModel: selectGraphListModel(state),
  }
}

const mapDispatchToProps = dispatch => ({
  loadGraphList: revisionId => dispatch(loadGraphList(revisionId)),
})

const TabRoutes = connect(mapStateToProps, mapDispatchToProps)(_TabRoutes)

export const TabRoutesWithAudit = withMntAudit(TabRoutes)

TabRoutesWithAudit.tabTitle = 'Маршруты'
TabRoutesWithAudit.tabPath = 'routes'
