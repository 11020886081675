import { TextInput } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class ChildbirthNumberColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.CHILDBIRTH_NUMBER_COLUMN

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Порядковый номер при рождении',
        component: TextInput,
        defaultValue: '',
        props: {
          icon: 'search',
          allowedChars: /[0-9]/,
        },
      },
      width: 150,
      dataIndex: 'childbirth_number',
      dataRender: childbirth_number => childbirth_number ? childbirth_number : '-',
      sort: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters }) {
    return {
      control: {
        filters: filters?.[REGISTER_COLUMNS.CHILDBIRTH_NUMBER_COLUMN]
          ? [{ field: 'childbirth_number', value: filters?.[REGISTER_COLUMNS.CHILDBIRTH_NUMBER_COLUMN], operator: '=', invalid: false }]
          : [],
      },
    }
  }
}
