import { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './Loader.scss'

export class Loader extends Component {
  static propTypes = {
    className: PropTypes.string,
    small: PropTypes.bool,
  }

  render () {
    const { className, small } = this.props
    /**
     * Loader from @link {https://codemyui.com/material-design-google-loader-in-css/}
     */
    return (
      <div className={cn('showbox', small && 'small', className)}>
        <div className='loader'>
          {
            small
              ? <svg className='circular' viewBox='12 12 24 24'>
                <circle className='path' cx='24' cy='24' r='9' fill='none' strokeWidth='2' strokeMiterlimit='10' />
              </svg>

              : <svg className='circular' viewBox='25 25 50 50'>
                <circle className='path' cx='50' cy='50' r='20' fill='none' strokeWidth='2' strokeMiterlimit='10' />
              </svg>

          }
        </div>
      </div>
    )
  }
}
