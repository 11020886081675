import { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './ErrorText.scss'

export class ErrorText extends Component {
  static propTypes = {
    className: PropTypes.string,
  }

  render () {
    const { className } = this.props

    return (
      <div className={cn('root-error-text', className)}>
        {this.props.children}
      </div>
    )
  }
}
