import { RangeInput } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class GestationalAgeColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.GESTATIONAL_AGE

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Срок беременности',
        component: RangeInput,
        defaultValue: ['', ''],
      },
      width: 200,
      dataIndex: 'gestational_age',
      titleRender: gAge => gAge && Math.min(gAge, 45),
      dataRender: gAge => gAge
        ? (
          <div
            title={gAge > 45 ? 'Расчетный срок беременности превышает максимально допустимый, но нет данных о завершении беременности' : undefined}
            style={gAge > 45 ? { color: '#FF794D' } : {}}
          >{gAge && Math.min(gAge, 45)}</div>
        )
        : '-',
      sort: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters }) {
    return {
      control: {
        filters: [
          ...(filters?.[REGISTER_COLUMNS.GESTATIONAL_AGE]?.[0]
            ? [{ field: 'gestational_age', value: filters[REGISTER_COLUMNS.GESTATIONAL_AGE][0], operator: '>=', invalid: false }] : []),
          ...(filters?.[REGISTER_COLUMNS.GESTATIONAL_AGE]?.[1]
            ? [{ field: 'gestational_age', value: filters[REGISTER_COLUMNS.GESTATIONAL_AGE][1], operator: '<=', invalid: false }] : []),
        ],
      },
    }
  }
}
