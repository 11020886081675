import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import './AppTabs.scss'

AppTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.node,
  })),
  linkTabs: PropTypes.bool,
  activeTab: PropTypes.string,
  onClick: PropTypes.func,
}

export default function AppTabs ({ tabs, linkTabs, activeTab, onClick }) {
  return (
    <div className='root-app-tabs'>
      {
        !linkTabs
          ? tabs?.map((t, i) => (
            <div onClick={() => onClick(t)} key={i} className={`app-tabs-item ${activeTab === t.link ? 'app-tabs-item-active' : ''}`}>
              <span>{t.label}</span>
            </div>
          ))
          : tabs?.map((t, i) => (
            <NavLink
              key={i}
              to={t.link}
              className='app-tabs-item'
              activeClassName='app-tabs-item-active'
            >
              {t.label}
            </NavLink>
          ))
      }
    </div>
  )
}
