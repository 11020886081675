/* eslint-disable @typescript-eslint/no-explicit-any */
import { CanceledError } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import { remotesManager } from '@src/remotes'
import { selectAccessToken } from '../../store/security/lib/selectors'
import { ExtraCreator, ExtraNestedCreator, PathBuilder, ResponseDataObject, ThunkArg, ThunkCreatorOptions } from '../types'

export const initialModel = {
  payload: null,
  loading: false,
  loaded: false,
  error: null,
}

export const extraCreator = (thunk: any, key: string): ExtraCreator => {
  return {
    [thunk.pending]: (state, action) => {
      state[key].loading = true
      state[key].loaded = false
      state[key].error = null
    },
    [thunk.rejected]: (state, action) => {
      if(!action.meta.canceled) {
        state[key].loading = false
        state[key].loaded = false
        state[key].error = action.payload || action.error?.error || action.error?.message || action.error?.error?.message || `Error in ${thunk.rejected}`
      }
    },
    [thunk.fulfilled]: (state, action) => {
      state[key].loading = false
      state[key].loaded = true
      state[key].error = null
      state[key].payload = action.payload
    },
  }
}

export const extraNestedCreator = (thunk: any, stateKey: string, pathBuilder: PathBuilder): ExtraNestedCreator => {
  return {
    [thunk.pending]: (state, action) => {
      const keys = [stateKey, ...pathBuilder(action.meta.arg)]
      _.set(state, keys, {
        loading: true,
        loaded: false,
        error: null,
      })
    },
    [thunk.rejected]: (state, action) => {
      const keys = [stateKey, ...pathBuilder(action.meta.arg)]
      _.set(state, keys, {
        loading: false,
        loaded: false,
        error: action?.payload || action.error?.error || action.error?.message || `Error in ${thunk.rejected}`,
      })
    },
    [thunk.fulfilled]: (state, action) => {
      const keys = [stateKey, ...pathBuilder(action.meta.arg)]
      _.set(state, keys, {
        payload: action.payload,
        loading: false,
        loaded: true,
        error: null,
      })
    },
  }
}

export const mapDataWithMeta = (data: ResponseDataObject) => {
  return data.rowMode === 'array' && _.isArray(data.data?.[0])
    ? data.data.map((e) => {
      return e.reduce((acc, cur, index) => {
        return {
          ...acc,
          [data.metaData[index].name]: cur,
        }
      }, {})
    })
    : data.data
}


/**
 * Функция позволяет упростить создание танков для шаблонных ситуаций
 *
 * @param {String} model Ключ в хранилище Redux, по которому будут сохранены результат запроса и его состояние
 * @param {String} url Адрес эндпоинта для запроса
 * @param {Object} [options] Набор дополнительных аргументов
 */

export const thunkCreator = (model: string, url: string, options: ThunkCreatorOptions = {}): any => {
  const {
    extractor = data => ({ data: mapDataWithMeta(data) }),
    extraBuilder = extraCreator,
    method = 'post',
    headers = {},
    exclusive = true,
    targetModel = model,
    ...restOptions
  } = options

  const abortControllers: { [key: string]: AbortController } = {}

  const thunk = createAsyncThunk(
    `REQUEST/${model}`,
    /**
     *
     * args попадает напрямую в Payload запроса.
     * args.queryParams попадают в URL параметры запроса
     * args.pathParams делают замену параметров пути в url,
     * например url = '/api/get/:abc', pathParams = { abc: 123 }, будет заменено на '/api/get/123'
     *
     */
    async (args: ThunkArg, { getState, rejectWithValue, requestId }) => {
      const accessToken = selectAccessToken(getState())

      if (exclusive) Object.values(abortControllers).filter(ac => !ac.signal.aborted).forEach(ac => ac.abort())
      abortControllers[requestId] = new AbortController()

      const urlWithPathParams = _.isObject(args?.pathParams) &&
        Object.entries(args.pathParams).reduce((acc, [param, value]) => acc.replace(`:${param}`, value), url)

      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const response = await remotesManager.BACKEND_API!.request({
          ...restOptions,
          url: urlWithPathParams || url,
          method,
          data: urlWithPathParams ? { ...args, pathParams: undefined } : args,
          headers: {
            ...headers,
            Authorization: `Bearer ${accessToken}`,
          },
          signal: abortControllers[requestId].signal,
          params: _.merge(options.params, args?.queryParams),
        })

        const data = response?.data

        if (abortControllers[requestId].signal.aborted) throw new CanceledError()
        if (data.error) throw new Error(data?.error?.error || data?.error?.message || data.error)
        return extractor(data)
      } catch (e: any) {

        if (e instanceof CanceledError) return (rejectWithValue as any)(null, { canceled: true })
        console.error(e)
        const message = e?.response?.data?.error || e.error || e.message
        return rejectWithValue(_.isString(message) ? message : JSON.stringify(message))
      } finally {
        delete abortControllers[requestId]
      }
    },
  )

  const extraReducers = extraBuilder(thunk, targetModel)
  return [thunk, extraReducers]
}

/**
 * @description thunkCreator для таблиц c подгрузкой данных
 */
export const thunkCreatorInfinityScroll = (model: string, url: string, options: ThunkCreatorOptions = {}) => {
  return thunkCreator(model, url, {
    ...options,
    extraBuilder: (thunk, key) => ({
      ...extraCreator(thunk, key),
      [thunk.fulfilled]: (state, action) => {
        state[key].loading = false
        state[key].loaded = true
        state[key].error = null
        state[key].payload = action.meta.arg.control.range.chunk_start > 0 ? {
          ...action.payload,
          data: [
            ...state[key].payload.data,
            ...action.payload.data,
          ],
          hasMoreToLoad: !!action.payload.data.length,
        } : {
          ...action.payload,
          hasMoreToLoad: true,
        }
      },
    }),
  })
}

/**
 * @description thunkCreator для extraNestedCreator
 */
export const thunkCreatorNested = (model: string, url: string, pathBuilder: PathBuilder, options: ThunkCreatorOptions = {}) => {
  return thunkCreator(model, url, {
    ...options,
    extraBuilder: (thunk, stateKey) => extraNestedCreator(thunk, stateKey, pathBuilder),
  })
}
