import { thunkCreator, thunkCreatorInfinityScroll } from '@src/utils'
import * as models from '../models'

export const [
  loadCrList, loadCrListExtra,
] = thunkCreatorInfinityScroll(models.CR_LIST, '/method/get_klinrec_list')

export const [
  loadCrListCount, loadCrListCountExtra,
] = thunkCreator(models.CR_LIST_COUNT, '/method/get_klinrec_list')

export const [
  loadThesisList, loadThesisListExtra,
] = thunkCreator(models.THESIS_LIST, '/method/get_thesis_list_by_revision_id')

const extraReducers = {
  ...loadCrListExtra,
  ...loadCrListCountExtra,
  ...loadThesisListExtra,
}

export default extraReducers