import { Combobox } from '@src/components'
import { selectDictStatusInfectionType } from '@src/modules/dictionaries/store'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class InfectionStatus extends RegisterColumn {
  static type = REGISTER_COLUMNS.INFECTION_STATUS

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Статус случая',
        component: Combobox,
        props: {
          allowClear: true,
          mode: 'multiple',
          options: selectDictStatusInfectionType(store.getState()).payload?.map(mo => ({
            label: mo.name,
            value: mo.row_code,
            title: mo.name_full,
          })),
        },
        defaultValue: [],
      },
      width: 300,
      dataIndex: 'infection_status',
      dataRender: infection_status => infection_status ? infection_status : '-',
      sort: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let requestArgs = []

    if (filters?.infection_status && filters?.infection_status.length) {
      requestArgs = [{ field: 'infection_status_id', value: filters.infection_status, operator: '=', invalid: false, cast: 'right-any' }]
    }

    if (nullSearches[this.type]) {
      requestArgs = [{ field: this.type, operator: '=', value: null, invalid: false }]
    }

    return {
      control: {
        filters: requestArgs,
      },
    }
  }
}
