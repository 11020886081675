import { Popover } from 'antd'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { logout, selectCurrentRole, selectUser } from '@src/store'
import { Icon } from '../Icon/Icon'
import { UserRoleSelect } from '../UserRoleSelect/UserRoleSelect'

import './AppHeader.scss'

class AppHeader extends Component {
  static propTypes = {
    user: PropTypes.object,
    logout: PropTypes.func,
    page: PropTypes.string,
  }

  constructor (props) {
    super(props)

    this.state = { isUserRoleSelectShown: false }
  }

  closeUserRoleSelect = () => this.setState({ isUserRoleSelectShown: false })

  switchUserRoleSelect = () => this.setState(prevState => ({ isUserRoleSelectShown: !prevState.isUserRoleSelectShown }))

  render () {
    const { user, logout } = this.props

    return (
      <div className='root-app-header'>
        <div className='app-header-left'>
          {/* Портал для breadcrumbs*/}
          <div className='app-header-breadcrumbs' id='app-nav-breadcrumbs'></div>
        </div>
        {
          user ? (
            <div className='app-header-right'>
              <Popover
                content={<UserRoleSelect onClose={this.closeUserRoleSelect}/>}
                overlayClassName='app-header-user-role'
                trigger='click'
                onOpenChange={open => !open && this.closeUserRoleSelect()}
                open={this.state.isUserRoleSelectShown}
                placement='bottomRight'
              >
                <div className='app-header-user' onClick={this.switchUserRoleSelect}>
                  <Icon className='app-header-user-icon' icon='user' />
                  <div className='app-header-user-name'>{`${user.first_name} ${user.last_name}`}</div>
                </div>
              </Popover>

              <Icon className='app-header-logout' icon='logout' onClick={logout}/>
            </div>
          ) : null
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: selectUser(state),
    currentRole: selectCurrentRole(state),
  }
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader)
