import React from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, Combobox, TextInput, Drawer } from '@src/components'
import './AssignRoleDrawer.scss'

export const AssignRoleDrawer = ({
  userLogin, selectedRole, roleSelectorOptions, onRoleChange, orgId, orgSelectorOptions, onOrgChange, isLoadingOrgs,
  appointmentId, appointmentSelectorOptions, onAppointmentChange, isLoadingAppointments, isMain, open,
  onIsMainChange, isIsMainDisabled, isSaveInProgress, onSave, onClose, error, edit,
}) => {
  return (
    <Drawer
      className='assign-role-drawer'
      title={edit ? 'Редактировать роль пользователя' : 'Создать роль пользователя'}
      width={560}
      onClose={onClose}
      open={open}
      footer={<DrawerFooter {...{ onClose, isSaveInProgress, orgId, selectedRole, onSave, edit }} />}
    >
      <div className='assign-role-drawer-content'>
        <div className='assign-role-drawer-content-org'>
          <div className='assign-role-drawer-content-role-title'>Пользователь</div>
          <TextInput
            disabled
            className='assign-role-drawer-content-role-field'
            value={userLogin}
          />
        </div>

        <div className='assign-role-drawer-content-org'>
          <div className='assign-role-drawer-content-role-title'>Роль</div>
          <Combobox
            className='assign-role-drawer-content-role-field'
            onChange={onRoleChange}
            value={selectedRole?.id}
            options={roleSelectorOptions}
            placeholder='Выберите роль'
            showSearch
          />
        </div>

        <div className='assign-role-drawer-content-org'>
          <div className='assign-role-drawer-content-org-title'>Организация</div>
          <Combobox
            className='assign-role-drawer-content-org-field'
            disabled={!selectedRole}
            value={orgId}
            onChange={onOrgChange}
            options={orgSelectorOptions}
            loading={isLoadingOrgs}
            placeholder='Выберите организацию'
            showSearch
          />
        </div>

        <div className='assign-role-drawer-content-appointment'>
          <div className='assign-role-drawer-content-appointment-title'>Должность</div>
          <Combobox
            className='assign-role-drawer-content-appointment-field'
            disabled={!selectedRole}
            value={appointmentId}
            onChange={onAppointmentChange}
            options={appointmentSelectorOptions}
            loading={isLoadingAppointments}
            placeholder='Выберите должность'
          />
        </div>

        <div className='assign-role-drawer-content-is-main'>
          <Checkbox
            className='assign-role-drawer-content-is-main-cb'
            value={isMain}
            onChange={onIsMainChange}
            disabled={isIsMainDisabled}
          />
          <div className='assign-role-drawer-content-is-main-title'>Главная роль</div>
        </div>

      </div>
      <div className='assign-role-drawer-error'>{error}</div>
    </Drawer>
  )
}

const DrawerFooter = ({ onClose, isSaveInProgress, orgId, selectedRole, onSave, edit }) => {
  return (
    <div className='assign-role-drawer-actions'>
      <Button
        onClick={onClose}
        label='Отмена'
      />
      <Button
        className='actions-button'
        loading={isSaveInProgress}
        disabled={!orgId || !selectedRole}
        label={edit ? 'Изменить' : 'Сохранить'}
        onClick={onSave}
        isSuccess
        icon='check_cr_fr_white'
      />
    </div>
  )
}

AssignRoleDrawer.propTypes = {
  userLogin: PropTypes.string,
  edit: PropTypes.bool,
  selectedRole: PropTypes.object,
  roleSelectorOptions: PropTypes.array,
  onRoleChange: PropTypes.func,

  orgId: PropTypes.string,
  orgSelectorOptions: PropTypes.array,
  onOrgChange: PropTypes.func,
  isLoadingOrgs: PropTypes.bool,

  appointmentId: PropTypes.string,
  appointmentSelectorOptions: PropTypes.array,
  onAppointmentChange: PropTypes.func,
  isLoadingAppointments: PropTypes.bool,

  isMain: PropTypes.bool,
  onIsMainChange: PropTypes.func,
  isIsMainDisabled: PropTypes.bool,

  isSaveInProgress: PropTypes.bool,
  saveButtonLabel: PropTypes.string,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  error: PropTypes.string,
  handleRemoveRole: PropTypes.func,
}