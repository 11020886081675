import { createSlice } from '@reduxjs/toolkit'
import { initialModel } from '@src/utils'

import reducers from './lib/reducers'
import extraReducers from './lib/thunks'
import * as models from './lib/models'

const initialState = {
  [models.ADMIN_USER_LIST]: initialModel,
  [models.ADMIN_ROLES_BY_ID]: initialModel,
  [models.ADMIN_ROLES_LIST]: initialModel,
  [models.ADMIN_UPSERT_USER_ROLE]: initialModel,
  [models.ADMIN_SET_MAIN_ROLE]: initialModel,
  [models.ADMIN_DELETE_USER_ROLE]: initialModel,
  [models.ADMIN_EXTERNAL_SYSTEMS_LIST]: initialModel,
  [models.ADMIN_INSERT_EXTERNAL_SYSTEM]: initialModel,
  [models.ADMIN_ROLE_RESOURCES_LIST]: initialModel,
  [models.ADMIN_SET_ROLE]: initialModel,
  [models.ADMIN_REMOVE_ROLE]: initialModel,
  [models.ADMIN_COPY_ROLE]: initialModel,
  [models.ADMIN_APPOINTMENTS_INSERT_ROLE]: initialModel,
  [models.ADMIN_APPOINTMENTS_DELETE_ROLE]: initialModel,
  [models.ADMIN_APPOINTMENTS_DELETE]: initialModel,
  [models.ADMIN_APPOINTMENTS_POSITIONS_LIST]: initialModel,
  [models.ADMIN_USER_DELETE]: initialModel,
  [models.ADMIN_SUSPEND_ALL_EXTERNAL_SYSTEMS]: initialModel,
  [models.ADMIN_RESUME_ALL_EXTERNAL_SYSTEMS]: initialModel,
  [models.ADMIN_EXT_SYSTEMS_HISTORY]: initialModel,

  [models.ADMIN_USERS_LIST]: {},
  [models.ADMIN_RESOURCES_BY_ROLE_ID]: {},
  [models.ADMIN_ACL_BY_ROLE_BY_RESOURCE]: {},
  [models.ADMIN_ROLES_BY_USER_ID]: {},
  [models.ADMIN_ORGS_BY_MCL_ID_BY_TERRITORY_LEVEL]: {},
  [models.ADMIN_APPOINTMENTS_ROLE_ID]: {},
  [models.ADMIN_APPOINTMENTS_POSITIONS]: {},
  [models.ADMIN_APPOINTMENTS_INSERT]: {},
  [models.ADMIN_APPOINTMENTS_UPDATE]: {},

}

export const slice = createSlice({
  name: 'admin',
  initialState,
  reducers,
  extraReducers,
})

export default slice.reducer

export const { updateExternalSystem, resetAdminUsersList, clearAdminExtSystemsHistory } = slice.actions
export * from './lib/thunks'
export * from './lib/selectors'
