import { Input, TreeSelect } from 'antd'
import cn from 'classnames'
import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectDictDeviationValue, selectGetAttributeTreeByType, selectGetAttributeTreeByTypeForSelector } from '@src/modules/methodology/store'
import { ActionMenu, Button, Card, Checkbox, Combobox, Icon, IfNotReadonly, TextInput } from '@src/components'
import { AccessContext } from '@src/context'

const OPERATION_OPTIONS = [
  { item_code: '1', icon: 'less_outline_16', label: 'Меньше' },
  { item_code: '3', icon: 'equals_outline_16', label: 'Равно' },
  { item_code: '6', icon: 'notequals_outline_16', label: 'Не равно' },
  { item_code: '2', icon: 'greater_outline_16', label: 'Больше' },
  { item_code: '5', icon: 'greaterequals_outline_16', label: 'Больше или равно' },
  { item_code: '4', icon: 'lessequals_outline_16', label: 'Меньше или равно' },
]

export const TimeCondition = ({ timeCondition, onTimeConditionChange, onClearTimeCondition, canClearTimeCondition }) => {
  const accessContext = useContext(AccessContext)
  const attributeTreeByTypeModel = useSelector(selectGetAttributeTreeByType)
  const attributeTree = useSelector(selectGetAttributeTreeByTypeForSelector)
  const dictDeviationValue = useSelector(selectDictDeviationValue)

  const dictDeviationList = useMemo(() => {
    return dictDeviationValue?.payload?.data.map(e => ({
      value: e.code,
      label: e.caption,
    }))
  }, [dictDeviationValue])

  const selectedOperation = useMemo(() => {
    return OPERATION_OPTIONS.find(opt => opt.item_code === timeCondition?.operation) || OPERATION_OPTIONS[0]
  }, [timeCondition?.operation])

  return <Card
    className='root-time-condition mb-16'
    title='Рекомендуемые сроки'
    headerFuncPointer={() => <div className='d-flex justify-content-end'>
      <IfNotReadonly>
        <Button
          icon='eraser'
          label='Очистить'
          disabled={!canClearTimeCondition}
          onClick={onClearTimeCondition}
        />
      </IfNotReadonly>
    </div>}
    data={[
      {
        title: 'Начало отсчета срока',
        data: <div className='time-condition-beginning-countdown'>
          <div className='time-condition-beginning-countdown-container mb-16'>
            <TreeSelect
              className='time-condition-beginning-countdown-attribute mr-8'
              popupClassName='attribute-tree-selector-dropdown'
              loading={attributeTreeByTypeModel.loading}
              treeData={attributeTree}
              showSearch
              treeIcon
              treeLine={{ showLeafIcon: false }}
              treeNodeFilterProp='caption'
              fieldNames={{
                label: 'caption',
                value: 'id',
              }}
              value={timeCondition?.beginningOfCountdown}
              disabled={accessContext.readonly}
              onSelect={val => onTimeConditionChange({ ...timeCondition, beginningOfCountdown: val })}
            />
            {
              !timeCondition?.period
                ? <ActionMenu disabled={accessContext.readonly} options={OPERATION_OPTIONS.map(({ item_code, ...rest }) => ({ ...rest,
                  onClick: () => onTimeConditionChange({ ...timeCondition, operation: item_code }) }))}
                >
                  <Icon
                    className={cn('time-condition-beginning-countdown-operation action-menu h-16 mr-16 ml-8', accessContext.readonly &&'disabled')}
                    icon={selectedOperation?.icon}
                    title={selectedOperation?.label}
                  />
                </ActionMenu>
                : 'от'
            }
            <TextInput
              className={cn('time-condition-beginning-countdown-field-small ml-4 mr-8', !timeCondition?.period && 'time-condition-beginning-countdown-field')}
              value={timeCondition?.valueFrom}
              disabled={accessContext.readonly}
              onChange={val => onTimeConditionChange({ ...timeCondition, valueFrom: val })}
            />
            {
              timeCondition?.period
                ? <>
                  до
                  <TextInput
                    className='time-condition-beginning-countdown-field-small ml-4 mr-8'
                    value={timeCondition?.valueTo}
                    disabled={accessContext.readonly}
                    onChange={val => onTimeConditionChange({ ...timeCondition, valueTo: val })}
                  />
                </>
                : null
            }
            <Combobox
              className='time-condition-beginning-countdown-measurement'
              options={dictDeviationList}
              value={timeCondition?.measurement}
              disabled={accessContext.readonly}
              onChange={val => onTimeConditionChange({ ...timeCondition, measurement: val })}
            />
          </div>
          <div className='d-flex align-items-center'>
            <Checkbox
              className='mr-16'
              Label='Период'
              value={timeCondition?.period}
              disabled={accessContext.readonly}
              onChange={val => onTimeConditionChange({ ...timeCondition, period: val })}
            />
            {
              timeCondition?.period
                ? <Checkbox
                  Label='Включительно'
                  value={timeCondition?.inclusive}
                  disabled={accessContext.readonly}
                  onChange={val => onTimeConditionChange({ ...timeCondition, inclusive: val })}
                />
                : null
            }
          </div>
        </div>,
      },
      {
        title: 'Описание условий сроков',
        data: <Input.TextArea
          rows={4}
          placeholder='Введите описание условий сроков'
          onChange={evt => onTimeConditionChange({ ...timeCondition, condition_comment: evt.currentTarget.value })}
          value={timeCondition?.condition_comment}
          disabled={accessContext.readonly}
          allowClear
        />,
      },
    ]}
  />
}
