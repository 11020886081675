import { cloneDeep } from 'lodash'
import { createSelector } from 'reselect'
import { makeAttributeTreeForSelector } from '@src/modules/methodology/store/utils'
import * as models from './models'

export const selectMethodology = state => state.methodology

export const selectRevisionsListModel = createSelector(
  selectMethodology,
  methodology => methodology[models.REVISION_LIST],
)

export const selectRevisionDataModel = createSelector(
  selectMethodology,
  methodology => methodology[models.REVISION_DATA],
)

export const selectDocumentModel = createSelector(
  selectMethodology,
  methodology => methodology[models.METHODOLOGY_DOCUMENT],
)

export const selectCrListCountModel = createSelector(
  selectMethodology,
  methodology => methodology[models.CR_LIST_COUNT],
)

export const selectCrListModel = createSelector(
  selectMethodology,
  methodology => methodology[models.CR_LIST],
)

export const selectThesisListModel = createSelector(
  selectMethodology,
  methodology => methodology[models.THESIS_LIST],
)

export const selectNpaListModel = createSelector(
  selectMethodology,
  methodology => methodology[models.NPA_LIST],
)

export const selectNpaListCountModel = createSelector(
  selectMethodology,
  methodology => methodology[models.NPA_LIST_COUNT],
)

export const selectStaffPlacesListModel = createSelector(
  selectMethodology,
  methodology => methodology[models.STAFF_PLACES_LIST],
)

export const selectPlaceRequirementsModel = createSelector(
  selectMethodology,
  methodology => methodology[models.STAFF_REQUIREMENTS],
)

export const selectEquipmentRequirementsModel = createSelector(
  selectMethodology,
  methodology => methodology[models.EQUIPMENT_REQUIREMENTS],
)

export const selectEquipmentPlacesListModel = createSelector(
  selectMethodology,
  methodology => methodology[models.EQUIPMENT_PLACES_LIST],
)

export const selectGraphListModel = createSelector(
  selectMethodology,
  methodology => methodology[models.GRAPH_LIST],
)

export const selectDocTypesModel = createSelector(
  selectMethodology,
  methodology => methodology[models.DOC_TYPES],
)

export const selectDocProfilesModel = createSelector(
  selectMethodology,
  methodology => methodology[models.DOC_PROFILES],
)

export const selectLastMethDocsModel = createSelector(
  selectMethodology,
  methodology => methodology[models.LAST_METH_DOCS],
)

export const selectMethRoutesListModel = createSelector(
  selectMethodology,
  methodology => methodology[models.METH_ROUTES_LIST],
)

export const selectMethRoutesListCountModel = createSelector(
  selectMethodology,
  methodology => methodology[models.METH_ROUTES_LIST_COUNT],
)

export const selectMethRouteStatusList = createSelector(
  selectMethodology,
  methodology => methodology[models.METH_ROUTES_STATUS_LIST],
)

export const selectMethVmclList = createSelector(
  selectMethodology,
  methodology => methodology[models.METH_VMCL_LIST],
)

export const selectGraphVersions = createSelector(
  selectMethodology,
  methodology => methodology[models.GRAPH_VERSIONS],
)

export const selectGraph = createSelector(
  selectMethodology,
  methodology => methodology[models.GRAPH],
)

export const selectGraphDescriptionModel = createSelector(
  selectMethodology,
  methodology => methodology[models.METH_GRAPH_DESCRIPTION],
)

export const selectCheckStatusModel = createSelector(
  selectMethodology,
  methodology => methodology[models.CHECK_STATUS],
)
export const selectGetConditionsListCsp = createSelector(
  selectMethodology,
  methodology => methodology[models.GET_CONDITIONS_LIST_CSP],
)

export const selectPutGraph = createSelector(
  selectMethodology,
  methodology => methodology[models.PUT_GRAPH],
)

export const selectOperationValueIdListModel = createSelector(
  selectMethodology,
  methodology => methodology[models.OPERATION_VALUE_ID_LIST],
)

export const selectAttributesTreeModel = createSelector(
  selectMethodology,
  methodology => methodology[models.ATTRIBUTE_TREE],
)

export const selectValueTypeListModel = createSelector(
  selectMethodology,
  methodology => methodology[models.VALUE_TYPE_LIST],
)

// Мемоизированный селектор опций для дерева атрибутов
// Дополнен иконками для типа значения атрибута
// value_type_id атрибутов приведены к корректным значениям
export const selectAttributesTreeForSelector = createSelector(
  selectAttributesTreeModel,
  selectValueTypeListModel,
  (attributesTreeModel, valueTypeListModel) => {
    if(!attributesTreeModel.payload?.data || !valueTypeListModel.payload?.data) return []

    // Нельзя мутировать объект из запроса, создаем копию
    const attributesTreeClone = cloneDeep(attributesTreeModel.payload?.data)
    return makeAttributeTreeForSelector(attributesTreeClone, valueTypeListModel.payload.data)
  }
)

export const selectRefbookDataAttributeModel = createSelector(
  selectMethodology,
  methodology => methodology[models.REFBOOK_DATA_ATTRIBUTE],
)

export const selectReferenceAttributeModel = createSelector(
  selectMethodology,
  methodology => methodology[models.REFERENCE_ATTRIBUTE],
)

export const selectGetAttributeTreeByType = createSelector(
  selectMethodology,
  methodology => methodology[models.GET_ATTRIBUTE_TREE_BY_TYPE],
)

export const selectGetAttributeTreeByTypeForSelector = createSelector(
  selectGetAttributeTreeByType,
  selectValueTypeListModel,
  (attributesTreeModel, valueTypeListModel) => {
    if(!attributesTreeModel.payload?.data || !valueTypeListModel.payload?.data) return []

    // Нельзя мутировать объект из запроса, создаем копию
    const attributesTreeClone = cloneDeep(attributesTreeModel.payload?.data)
    return makeAttributeTreeForSelector(attributesTreeClone, valueTypeListModel.payload.data)
  }
)

export const selectDictDeviationValue = createSelector(
  selectMethodology,
  methodology => methodology[models.DICT_DEVIATION_VALUE],
)

export const selectGetConditionFormModel = createSelector(
  selectMethodology,
  methodology => methodology[models.GET_CONDITION_FORM],
)

export const selectPostConditionFormModel = createSelector(
  selectMethodology,
  methodology => methodology[models.POST_CONDITION_FORM],
)

export const selectGetCopyModalRoutesModel = createSelector(
  selectMethodology,
  methodology => methodology[models.COPY_CONDITION_MODAL_ROUTES],
)

export const selectGetCopyModalVersionsModel = createSelector(
  selectMethodology,
  methodology => methodology[models.COPY_CONDITION_MODAL_VERSIONS],
)

export const selectGetCopyModalStatesModel = createSelector(
  selectMethodology,
  methodology => methodology[models.COPY_CONDITION_MODAL_STATES],
)

export const selectGetCopyModalConditionsModel = createSelector(
  selectMethodology,
  methodology => methodology[models.COPY_CONDITION_MODAL_CONDITIONS],
)

export const selectGetCopyModalSourceConditionModel = createSelector(
  selectMethodology,
  methodology => methodology[models.COPY_CONDITION_MODAL_SOURCE_CONDITION],
)

export const selectGetCopyModalSaveConditionModel = createSelector(
  selectMethodology,
  methodology => methodology[models.COPY_CONDITION_MODAL_SAVE_CONDITION],
)
