import { RangeInput } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class DaysLifeColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.DAYS_LIFE

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Сутки жизни новорожденного (недоношенного) на момент взятия крови',
        component: RangeInput,
        defaultValue: ['', ''],
      },
      width: 200,
      dataIndex: 'days_of_life',
      dataRender: days_of_life => days_of_life ?? '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let requestArgs = [
      ...(filters?.[REGISTER_COLUMNS.DAYS_LIFE]?.[0]
        ? [{ field: 'days_of_life', value: filters[REGISTER_COLUMNS.DAYS_LIFE][0], operator: '>=', invalid: false }] : []),
      ...(filters?.[REGISTER_COLUMNS.DAYS_LIFE]?.[1]
        ? [{ field: 'days_of_life', value: filters[REGISTER_COLUMNS.DAYS_LIFE][1], operator: '<=', invalid: false }] : []),
    ]

    if (nullSearches[this.type]) {
      requestArgs = [{ field: this.type, operator: '=', value: null, invalid: false }]
    }

    return {
      control: {
        filters: requestArgs,
      },
    }
  }
}
