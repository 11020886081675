import { Route, Switch } from 'react-router-dom'
import { AUDIT_PS, URLS } from '@src/constants'
import { withMntAudit } from '@src/hoc'
import { InternalError404 } from '@src/components'
import { AdminUsersPageTableWithAudit } from './AdminUsersPageTable/AdminUsersPageTable'

const AdminUsersPage = () => {
  return (
    <Switch>
      <Route path={URLS.ADMIN__USERS}>
        <AdminUsersPageTableWithAudit
          auditPs={AUDIT_PS.ADMIN}
          auditMessage='Просмотр списка пользователей системы'
        />
      </Route>
      <Route component={InternalError404}/>
    </Switch>
  )
}

export const AdminUsersPageWithAudit = withMntAudit(AdminUsersPage)
