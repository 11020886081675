import { v4 as uuidv4 } from 'uuid'
import packageJson from '@src/../package.json'

const config: AppConfig = {}
export default config

const loadConfig = () => {
  return fetch('/config.json')
    .then(res => res.json())
    .then((newconfig) => {
      for(const prop in config) {
        delete config[prop]
      }
      for(const prop in newconfig) {
        config[prop] = newconfig[prop]
      }

      config.session_id = uuidv4()
      config.version = packageJson.version
      config.commit_hash = __COMMIT_HASH__
      return config
    })
}

export {
  loadConfig,
}
