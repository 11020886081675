import { Component } from 'react'
import config from '@src/config'
import { REGISTER_COLUMNS, URLS } from '@src/constants'
import { Register, TabMedRecordsWithAudit } from '@src/modules/monitoring/components'
import { TabRouteNewbornWithAudit } from './TabRouteNewborn'
import { TabStatusWithAudit } from './TabStatus'

export class RegisterAkineoNewborn extends Component {
  get basepath () {
    return URLS.MONITORING__AKINEO__NEWBORN
  }

  get navTitle () {
    return 'Реестр новорожденных'
  }

  get columns () {
    return [
      {
        type: REGISTER_COLUMNS.FULLNAME,
        settings: {
          column: {
            header: { title: 'ФИО матери' },
            enabled: true,
            nullSearch: false,
          },
        },
      },
      {
        type: REGISTER_COLUMNS.SNILS,
        settings: {
          column: {
            header: { title: 'СНИЛС матери' },
            enabled: true,
            nullSearch: false,
          },
        },
      },
      { type: REGISTER_COLUMNS.BIRTH_DATE, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.GENDER_ID, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.BIRTHWEIGHT_COLUMN, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.CHILDBIRTH_NUMBER_COLUMN, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.DIAGNOSIS, settings: { column: { enabled: true } } },
    ]
  }

  get tabs () {
    return [
      TabStatusWithAudit,
      TabMedRecordsWithAudit,
      ...(config.app.modules.enabled.monitoring_routes ? [TabRouteNewbornWithAudit] : []),
    ]
  }

  patientCardTitleRenderer = fullname => fullname ? `${fullname} (мать)` : 'Новорожденный'

  render () {
    return <Register
      list_type='4'
      columns={this.columns}
      basepath={this.basepath}
      navTitle={this.navTitle}
      tabs={this.tabs}
      patientCardTitleRenderer={this.patientCardTitleRenderer}
    />
  }
}
