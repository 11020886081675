import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [],
  },
  reducers: {
    pushNotification: (state, action) => {
      state.notifications = [...state.notifications, {
        message: action.payload.message,
        type: action.payload.type,
        notificationId: uuidv4(),
        autoclose: action.payload.autoclose,
      }]
    },
    removeNotification: (state, action) => {
      state.notifications = state.notifications.filter(n => n.notificationId !== action.payload)
    },
  },
})

export const { pushNotification, removeNotification } = notificationsSlice.actions
export * from './selectors'

export default notificationsSlice.reducer
