import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { cn } from '@src/utils'
import { Icon } from '../Icon/Icon'
import './IconWithArea.scss'

export const IconWithArea = forwardRef(({ icon, onClick, iconProps, className, ...props }, ref) => {
  return <div
    className={cn('root-icon-with-area d-flex align-items-center justify-content-center', className)}
    ref={ref}
    onClick={onClick}
    {...props}
  >
    <Icon icon={icon} className='w-16 h-16' {...iconProps}/>
  </div>
})

IconWithArea.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  iconProps: PropTypes.object,
}
