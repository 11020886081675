import * as FileSaver from 'file-saver'
import { Component } from 'react'
import * as XLSX from 'xlsx'
import { ButtonWithAudit } from '../Button/Button'


export class ExportXlsWithAudit extends Component {
  exportToXLS = (xlsData, xlsTitles, fileName, subTitle) => {
    const rows = [
      ...(subTitle ? [[subTitle]] : []), // Заголовок файла
      xlsTitles, // Заголовки таблицы
      ...xlsData.map(r => Object.values(r)), // Данные
    ]
    const ws = XLSX.utils.aoa_to_sheet(rows)

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
    FileSaver.saveAs(data, fileName + (subTitle ? ` ${subTitle}` : '') + '.xlsx')
  }

  render () {
    const { xlsData, fileName, className, xlsTitles, icon, disabled, subTitle, ...props } = this.props

    return (
      <ButtonWithAudit
        icon={icon}
        className={className}
        disabled={disabled}
        onClick={() => this.exportToXLS(xlsData, xlsTitles, fileName, subTitle)}
        label='Экспорт XLS'
        {...props}
      />
    )
  }
}
