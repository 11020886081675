import { createSelector } from 'reselect'
import { CHANGE_USER_CURRENT_ROLE, PS_VERSIONS, LOAD_USER } from './models'

export const selectSecurity = state => state.security
export const selectAuth = state => state.security.auth
export const selectAccessToken = state => state.security.auth.access_token
export const selectRefreshToken = state => state?.security?.auth?.refresh_token

export const selectAuthModel = createSelector(
  selectSecurity,
  security => ({
    loading: security.authLoading,
    loaded: security.authLoaded,
    error: security.authError,
    payload: security.auth,
  }),
)

export const selectChangeUserCurrentRoleModel = createSelector(
  selectSecurity,
  security => security[CHANGE_USER_CURRENT_ROLE],
)

export const selectPsVersionsModel = createSelector(
  selectSecurity,
  security => security[PS_VERSIONS],
)

export const selectLoadUserModel = createSelector(
  selectSecurity,
  security => security[LOAD_USER],
)

export const selectUser = createSelector(
  selectLoadUserModel,
  loadUser => loadUser.payload?.user,
)

export const selectCurrentRole = createSelector(
  selectLoadUserModel,
  loadUser => loadUser.payload?.roles && (loadUser.payload?.roles.find(r => r.is_main) || loadUser.payload?.roles[0]),
)

export const selectUserRoles = createSelector(
  selectLoadUserModel,
  loadUser => loadUser.payload?.roles,
)