import cn from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { AppNav, Button, Checkbox, Loader, MultiIcon, RadioGroup, TextInput } from '@src/components'
import { AUDIT_PS, URLS } from '@src/constants'
import {
  loadAdminAclByRoleByResource, loadAdminResourcesByRoleId, loadAdminRoleResourcesList, removeAdminRoleResource, selectAdminAclByRoleByResource,
  selectAdminResourcesByRoleId, selectAdminRoleResourcesListModel, selectAdminRoleResourcesTree, selectAdminRolesListModel, selectAdminUserListModel, setAdminResourceAcl,
} from '@src/modules/admin/store'
import { normalizeStr } from '@src/utils'

import { withMntAudit } from '@src/hoc'
import './AdminRolesPageRoles.scss'

class _AdminRolesPageRoles extends React.Component {
  static propTypes = {
    loadAdminResourcesByRoleId: PropTypes.func,
    loadAdminRoleResourcesList: PropTypes.func,
    loadAdminAclByRoleByResource: PropTypes.func,
    removeAdminRoleResource: PropTypes.func,
    setAdminResourceAcl: PropTypes.func,
    resourcesTree: PropTypes.object,
    adminAclByRoleByResource: PropTypes.object,
    roleId: PropTypes.number,
    adminResourcesByRoleId: PropTypes.objectOf(PropTypes.shape({
      loaded: PropTypes.bool,
      loading: PropTypes.bool,
      error: PropTypes.string,
      payload: PropTypes.object,
    })),
    logToAudit: PropTypes.func,
  }

  constructor (props) {
    super(props)

    this.state = {
      expandedResources: {},
      selectedResource: null,
      search: '',
    }
  }

  componentDidMount () {
    !this.props.adminRoleResourcesListModel?.loaded && this.props.loadAdminRoleResourcesList()
    this.props.loadAdminResourcesByRoleId({ roleId: this.props.roleId })
  }

  renderResourcesSubtree = (subtree) => {
    if (!subtree.title) return null

    const { expandedResources } = this.state

    return (
      <React.Fragment key={`resource-${subtree.path}`}>
        <li
          className={cn(subtree.path === this.state.selectedResource?.path ? 'selected' : null, 'subtree-recource')}
          onClick={() => this.selectResource(subtree)}
        >
          {
            subtree.children ?
              <div className='expand' onClick={e => this.switchExpandResource(e, subtree.path)}>
                <MultiIcon className='expand-icon' icon='table_icon' active={expandedResources[subtree.path]} />
              </div>
              : <span className='expand placeholder'>=</span>
          }
          {
            this.resourcesByRoleId.filter(i => subtree.path === i.path).length
              ? <Checkbox className='subtree-checkbox' value={true} />
              : null
          }
          {subtree.title}
        </li>
        {
          subtree.children && expandedResources[subtree.path]
            ? (
              <ul>
                {subtree.children.map(this.renderResourcesSubtree)}
              </ul>
            )
            : null
        }
      </React.Fragment>
    )
  }

  switchExpandResource (e, res) {
    e.stopPropagation()
    this.setState(prevState => ({
      expandedResources: {
        ...prevState.expandedResources,
        [res]: !prevState.expandedResources[res],
      },
    }))
  }

  selectResource = (res) => {
    const { loadAdminAclByRoleByResource } = this.props

    const roleId = this.getSelectSelectedRole().id
    const resourceId = res.path

    this.setState({ selectedResource: res }, () => {
      (!this.roleResourceAcl || this.roleResourceAcl.error) && loadAdminAclByRoleByResource({ roleId, resourceId })
    })
  }

  get resourcesByRoleId () {
    return this.props.adminResourcesByRoleId[this.props.roleId]?.payload?.data ?? []
  }

  get roleResourceAcl () {
    const { adminAclByRoleByResource } = this.props
    const { selectedResource } = this.state
    const roleId = this.getSelectSelectedRole().id
    const resourceId = selectedResource?.path

    return adminAclByRoleByResource?.[roleId]?.[resourceId]
  }

  setRoleResourceAcl = async (roleId, path, action, scope = null, transform = null, id = null) => {
    const arg = { roleId, path, action, scope, transform, id }
    this.props.logToAudit({
      auditPs: AUDIT_PS.ADMIN,
      auditMessage: 'Добавление ресурса',
      auditDescription: { arg },
    })
    await this.props.setAdminResourceAcl(arg)
    this.props.loadAdminAclByRoleByResource({ roleId, resourceId: path })
    this.props.loadAdminResourcesByRoleId({ roleId: this.props.roleId })
  }

  removeRoleResourceAcl = async (roleId, path, acl) => {
    const arg = { roleId, path: [path, acl].join('.') }
    this.props.logToAudit({
      auditPs: AUDIT_PS.ADMIN,
      auditMessage: 'Удаление ресурса',
      auditDescription: { arg },
    })
    await this.props.removeAdminRoleResource(arg)
    this.props.loadAdminAclByRoleByResource({ roleId, resourceId: path })
    this.props.loadAdminResourcesByRoleId({ roleId: this.props.roleId })
  }

  findIn = (target) => {
    if(normalizeStr(String(target?.title)).includes(normalizeStr(this.state.search)))
      return true
    else if(Array.isArray(target?.children)) {
      for(let i of target.children) {
        if(this.findIn(i))
          return true
      }
    }
    else
      return false
  }

  renderResourcesTree = () => {
    const { resourcesTree } = this.props
    const { search } = this.state

    return <ul className='admin-roles-resources-tree'>
      {
        resourcesTree.children
          ? resourcesTree.children
            .filter(a => a.title)
            .sort((a, b) => a.title.localeCompare(b.title))
            .filter(i => !search ? true : this.findIn(i))
            .map(this.renderResourcesSubtree)
          : null
      }
    </ul>
  }

  renderAcl = () => {
    if (!this.state.selectedResource?.acl?.action) return null

    return this.roleResourceAcl
      ? this.roleResourceAcl.loading
        ? (
          <tbody>
            <tr>
              <td>
                <Loader />
              </td>
            </tr>
          </tbody>
        )
        : this.roleResourceAcl.error
          ? (
            <tbody>
              <tr>
                <td>
                  <div className='error'>{this.roleResourceAcl.error}</div>
                </td>
              </tr>
            </tbody>
          )
          : this.state.selectedResource.acl.action
            .map((action, index) => this.renderAclRow(this.state.selectedResource.acl, action, index))
      : null
  }

  renderAclRow = (acl, action, rowIndex) => {
    const normalizedScope = acl.scope && acl.scope.map((s) => {

      if (s.transform || !acl.transform) return s

      return {
        ...s,
        transform: acl.transform,
      }
    })

    const normalizedAction = action.scope || !acl.scope
      ? action
      : {
        ...action,
        scope: normalizedScope,
      }

    return this.renderAclAction(normalizedAction, rowIndex)
  }

  renderAclAction = (action, rowIndex) => {
    const { selectedResource } = this.state
    const roleId = this.getSelectSelectedRole().id

    const aclMap = this.roleResourceAcl.payload.map((acl) => {
      const path = acl.path.substring(selectedResource.path.length + 1)
      const [action, scope, transform] = path.split('.')
      return {
        action,
        scope,
        transform,
        id: acl.id,
      }
    }).filter(acl => acl.action === action.name)

    const scopeLen = action?.scope?.length
    const key = `acl-action-${selectedResource.path}-${rowIndex}`

    return (
      <tbody className='admin-roles-acl-row' key={key}>
        {
          action.scope
            ? (
              action.scope.map((scope, sindex) => (
                <tr key={`${key}-${action.name}-${sindex}`}>
                  {
                    sindex === 0
                      ? (
                        <td rowSpan={scopeLen}>
                          <div className='admin-roles-acl-row-cb'>
                            <Checkbox
                              name={`action-${rowIndex}-${action.name}`}
                              value={Boolean(aclMap.length)}
                              onChange={
                                Boolean(aclMap.length)
                                  ? () => this.removeRoleResourceAcl(roleId, selectedResource.path, action.name)
                                  : () => this.setRoleResourceAcl(roleId, selectedResource.path, action.name)
                              }
                            />
                            <label htmlFor={`action-${rowIndex}-${action.name}`}>{action.label}</label>
                          </div>
                        </td>
                      )
                      : null
                  }
                  <td>
                    <div className='admin-roles-acl-row-cb'>
                      <Checkbox
                        name={`action-${rowIndex}-${action.name}-${scope.name}`}
                        disabled={!aclMap.length}
                        value={Boolean(aclMap.find(acl => acl.scope === scope.name))}
                        onChange={
                          Boolean(aclMap.find(acl => acl.scope === scope.name))
                            ? () => this.removeRoleResourceAcl(roleId, selectedResource.path, [action.name, scope.name].join('.'))
                            : () => this.setRoleResourceAcl(roleId, selectedResource.path, action.name, scope.name)
                        }
                      />
                      <label className={!aclMap.length ? 'disabled' : null} htmlFor={`action-${rowIndex}-${action.name}-${scope.name}`}>{scope.label}</label>
                    </div>
                  </td>
                  <td>
                    {
                      scope.transform
                        ? this.renderAclRadioGroup(key, rowIndex, aclMap, scope, roleId, selectedResource.path, action)
                        : null
                    }
                  </td>
                </tr>
              ))
            )
            : (
              <tr key={`${key}-${action.name}`}>
                <td>
                  <div className='admin-roles-acl-row-cb'>
                    <Checkbox
                      name={`action-${rowIndex}-${action.name}`}
                      value={!!aclMap.length}
                      onChange={
                        !!aclMap.length
                          ? () => this.removeRoleResourceAcl(roleId, selectedResource.path, action.name)
                          : () => this.setRoleResourceAcl(roleId, selectedResource.path, action.name)
                      }
                    />
                    <label htmlFor={`action-${rowIndex}-${action.name}`}>{action.label}</label>
                  </div>
                </td>
              </tr>
            )
        }
      </tbody>
    )
  }

  renderAclRadioGroup = (key, rowIndex, aclMap, scope, roleId, path, action) => {
    const isScopeEnabled = !!aclMap.find(acl => acl.scope === scope.name)
    const scopeValue = aclMap.find(acl => acl.scope === scope.name)
    const transformValue = aclMap.find(acl => acl.scope === scope.name && scope.transform.map(t => t.name).includes(acl.transform))

    return <RadioGroup
      name={`${key}-transform-${rowIndex}`}
      disabled={!isScopeEnabled}
      className='admin-roles-page-roles-radio-group'
      value={
        isScopeEnabled
          ? transformValue
            ? transformValue.transform
            : ''
          : null
      }
      onChange={
        transform => this.setRoleResourceAcl(roleId, path, action.name, scope.name, transform === '' ? null : transform, scopeValue.id)
      }
      options={[
        {
          name: 'Нет',
          value: '',
        },
        ...scope.transform.map(t => ({
          name: t.label,
          value: t.name,
        })),
      ]}
    />
  }

  getSelectSelectedRole = () => {
    if(!this.props.adminRolesList.length) return null
    return this.props.adminRolesList.filter(d => Number(d.id) === this.props.roleId)[0]
  }

  get selectedRole () {
    const { resourcesTree } = this.props

    if (this.getSelectSelectedRole() && 'children' in resourcesTree)
      return this.renderResourcesTree()

    return <Loader />
  }

  render () {
    if(!this.getSelectSelectedRole()) return <Loader />
    return (
      <React.Fragment>
        <AppNav
          title={this.getSelectSelectedRole().role_name}
          breadcrumbs={[
            {
              label: 'Администрирование',
              link: URLS.ADMIN__ROLES,
            }, {
              label: 'Прикладные роли',
              link: URLS.ADMIN__ROLES,
            }, {
              label: `${this.getSelectSelectedRole().role_name}`,
              link: `${URLS.ADMIN__ROLES__ROLE}/${this.getSelectSelectedRole().id}`,
            },
          ]}
        />
        <div className='root-admin-rooles-page-actions'>
          <div>
            <Button icon='backward' onClick={() => this.props.history.push(URLS.ADMIN__ROLES)} label='Назад' />
          </div>
        </div>
        <div className='root-admin-rooles-page-rooles'>
          <div className='admin-roles-resources'>
            <div className='admin-roles-resources-title'>Ресурс</div>
            <div className='root-admin-search-container'>
              <TextInput
                placeholder='Введите ресурс'
                className='search'
                value={this.state.search}
                onChange={e => this.setState({ search: e, selectedResource: null })}
                icon='search'
              />
            </div>
            {this.selectedRole}
          </div>
          <div className='admin-roles-acl'>
            <table>
              <thead>
                <tr>
                  <th>
                    <div>Действие</div>
                  </th>
                  <th>
                    <div>Ограничение</div>
                  </th>
                  <th>
                    <div>Преобразование</div>
                  </th>
                </tr>
              </thead>
              {this.renderAcl()}
            </table>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    adminResourcesByRoleId: selectAdminResourcesByRoleId(state),
    adminRoleResourcesListModel: selectAdminRoleResourcesListModel(state),
    adminAclByRoleByResource: selectAdminAclByRoleByResource(state),
    resourcesTree: selectAdminRoleResourcesTree(state),
    adminRolesList: selectAdminRolesListModel(state).payload.data,
    adminUserListModel: selectAdminUserListModel(state),
    roleId: Number(ownProps.match.params.roleId),
  }
}

const mapDispatchToProps = dispatch => ({
  loadAdminResourcesByRoleId: args => dispatch(loadAdminResourcesByRoleId(args)),
  loadAdminRoleResourcesList: args => dispatch(loadAdminRoleResourcesList(args)),
  loadAdminAclByRoleByResource: args => dispatch(loadAdminAclByRoleByResource(args)),
  removeAdminRoleResource: args => dispatch(removeAdminRoleResource(args)),
  setAdminResourceAcl: args => dispatch(setAdminResourceAcl(args)),
})

const AdminRolesPageRoles = withRouter(connect(mapStateToProps, mapDispatchToProps)(_AdminRolesPageRoles))
export const AdminRolesPageRolesWithAudit = withMntAudit(AdminRolesPageRoles)
