import { useCallback } from 'react'
import { debounce } from 'lodash'

/**
 * @function useDebounce
 * @summary Возвращает мемоизированный колбэк для debounce
 */

export const useDebounce = (func, wait=0, options={}, dependencies=[]) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(debounce(func, wait, options), dependencies)
}
