import { selectDictGendersModel } from '@src/modules/dictionaries/store'
import { Combobox, GenderTag } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class GenderIdColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.GENDER_ID

  constructor (store, settings) {
    const column = {
      header: {
        title:'Пол',
        component: Combobox,
        props: {
          allowClear: true,
          options: selectDictGendersModel(store.getState()).payload.map(g => ({
            label: g.name,
            value: g.item_code,
          })),
        },
      },
      dataRender: gender => gender
        ? <GenderTag>{gender}</GenderTag>
        : '-',
      titleRender: gender => gender,
      width: 150,
      dataIndex: 'gender',
      sort: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters }) {
    return {
      control: {
        filters: filters?.[REGISTER_COLUMNS.GENDER_ID]
          ? [{ field: 'gender_id', value: filters?.[REGISTER_COLUMNS.GENDER_ID], operator: '=', invalid: false }]
          : [],
      },
    }
  }
}
