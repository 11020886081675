import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isArray } from 'lodash'
import { Drawer, ErrorText, Loader } from '@src/components'
import { useMountEffect } from '@src/hooks'
import { selectPersonInfoModel } from '@src/modules/monitoring/store'

import { useLoadRoutes } from '../hooks'
import { RouteView } from '../RouteView'
import './ExtraRouteDrawer.scss'

export function ExtraRouteDrawer ({ extraRouteOccasionPatientId, extraRouteNosologyCode, ...props }) {
  const [routes, loadRoutes, loading] = useLoadRoutes()
  const personInfoModel = useSelector(selectPersonInfoModel)

  useMountEffect(() => {
    loadRoutes({ nosology_code: extraRouteNosologyCode, snils: personInfoModel.payload?.data?.[0].snils })
  })

  const routeData = useMemo(() => {
    if (isArray(routes)) {
      return routes.find(r => r.occasion_patient_id === extraRouteOccasionPatientId)
    }
  }, [routes, extraRouteOccasionPatientId])

  const title = useMemo(() => {
    return routeData ? `${routeData.graph_name}, ${routeData.doc_number}` : ''
  }, [routeData])

  return <Drawer
    className='root-extra-route-drawer'
    title={title}
    width={'95%'}
    {...props}
  >
    {
      loading
        ? <Loader />
        : routeData
          ? <RouteView routeData={routeData}/>
          : <ErrorText>Не удалось загрузить маршрут</ErrorText>
    }
  </Drawer>
}
