import { selectDictPregnancyOutcomesModel } from '@src/modules/dictionaries/store'
import { Combobox } from '@src/components'
import { REGISTER_COLUMNS, NOT_SET } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class PregnancyOutcomeColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.PREGNANCY_OUTCOME

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Исход беременности',
        component: Combobox,
        props: {
          showSearch: true,
          options: [
            { label: NOT_SET, value: 'нет_данных' },
            ...selectDictPregnancyOutcomesModel(store.getState()).payload.data.map(outcome => ({
              label: outcome.caption,
              value: outcome.id,
            })),
          ],
        },
      },
      width: 200,
      dataIndex: 'pregnancy_outcome',
      dataRender: pregnancy_outcome => pregnancy_outcome ? pregnancy_outcome : '-',
      sort: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters }) {
    return {
      args: { pregnancy_outcome_id: filters?.[REGISTER_COLUMNS.PREGNANCY_OUTCOME] ? [filters[REGISTER_COLUMNS.PREGNANCY_OUTCOME]] : null },
    }
  }
}
