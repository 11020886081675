import { useMemo } from 'react'
import { ACLS, URLS } from '@src/constants'
import { AppNav } from '@src/components'
import { useUserHasResource } from '@src/hooks'
import config from '@src/config'

export const MethTabs = ({ label, link }) => {
  const hasNpaAccess = useUserHasResource(ACLS.METHOD_DOCUMENTS_NPA_READ)
  const hasClinrecAccess = useUserHasResource(ACLS.METHOD_DOCUMENTS_CLINREC_READ)
  const hasRoutesAccess = useUserHasResource(ACLS.METHOD_GRAPH_READ)

  const breadcrumbs = useMemo(() => {
    return [
      { label: 'Методология', link: URLS.METHODOLOGY__NPA },
      { label, link },
    ]
  }, [label, link])

  const tabs = useMemo(() => ([
    ...hasNpaAccess ? [{ label: 'Порядки ОМП', link: URLS.METHODOLOGY__NPA }] : [],
    ...hasClinrecAccess ? [{ label: 'Клинические рекомендации', link: URLS.METHODOLOGY__CLINREC }] : [],
    ...(hasRoutesAccess && (config?.app?.modules?.enabled?.regional_routes) ?
      [{ label: 'Маршруты', link: URLS.METHODOLOGY__ROUTES }]
      : []),
  ]), [hasClinrecAccess, hasNpaAccess, hasRoutesAccess])

  return (
    <AppNav
      title='Методология'
      breadcrumbs={breadcrumbs}
      tabs={tabs}
    />
  )
}
