import { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import _, { isFunction } from 'lodash'
import { Button, Loader, ErrorText } from '@src/components'

import './Card.scss'

/**
 * @class Card
 * @param title {string} Title inscription (first)
 * @param buttonLabel {string}  Defines the text for buttons in the title (Added in monitoring)
 * @param buttonIcon {string} Adds buttons to the title (Added in monitoring)
 * @param data {array} The main array of objects describing the content
 * @param className {string} Adds a property className to root-card
 * @param isHeaderHidden {bool} Hides the entire title. For those cases when the body is there but the title is not needed (Added in methodology)
 * @param overflow {bool} Hardcodes the length and disallows overflow (for scrolling use) (Added in methodology TabText)
 * @param headerFuncPointer {function} Pointer to the function that will be called in the header of Card (it is expected to return JSX Element)
 * @param bottomFuncPointer {function} Pointer to the function that will be called in the bottom of Card (it is expected to return JSX Element)
 * @param loading {bool} Displays the progress of loading data for Card
 */
export default class Card extends Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
    buttonLabel: PropTypes.string,
    buttonIcon: PropTypes.string,
    className: PropTypes.string,
    isHeaderHidden: PropTypes.bool,
    overflow: PropTypes.bool,
    data: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        data: PropTypes.any,
        html: PropTypes.any,
        link: PropTypes.object,
        action: PropTypes.shape({
          onClick: PropTypes.func,
          label: PropTypes.string,
        }),
        components: PropTypes.arrayOf(PropTypes.shape({
          component: PropTypes.func,
          props: PropTypes.object,
        })),
        component: PropTypes.func,
        props: PropTypes.object,
      })),
      PropTypes.oneOf([false]),
    ]),
    headerFuncPointer: PropTypes.func,
    bottomFuncPointer: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.any,
    dataComponent: PropTypes.func,
  }

  getComponent = (Component, props, counter) => {
    return <Component key={counter ? counter : null} {...props} />
  }

  render () {
    const isEmpty = (!this.props.data || !this.props.data.length) && !this.props.dataComponent

    return (
      <div
        className={cn(
          'root-card',
          this.props.className,
          this.props.overflow && 'root-card-overflow',
          this.props.isHeaderHidden && 'card-header-hidden')}
        style={this.props.style}
      >
        <div className={cn('card-header',
          isEmpty && 'header-empty',
          this.props.error && 'header-error',
        )}>
          <div className='card-header-title'>
            {
              this.props.title
                ? isFunction(this.props.title)
                  ? this.props.title()
                  : <div className='card-header-title-title'>{this.props.title}</div>
                : null
            }
            <div className='card-header-title-data ml-auto'>
              {
                this.props.buttonLabel
                  ? <Button label={this.props.buttonLabel} icon={this.props.buttonIcon} />
                  : null
              }
              {
                this.props.loading
                  ? <Loader small />
                  : isEmpty && !this.props.error
                    ? <div>(Нет данных)</div>
                    : null
              }
              {
                this.props.headerFuncPointer instanceof Function
                  ? this.props.headerFuncPointer()
                  : null
              }
            </div>
          </div>
        </div>
        {
          this.props.dataComponent
            ? <div className='card-column-data'>{this.props.dataComponent()}</div>
            : null
        }
        <div className={cn('card-body', _.isEmpty(this.props.data) && 'card-body-empty')}>
          {
            this.props.error
              ? <ErrorText className='pa-16'>{this.props.error}</ErrorText>
              : this.props.data && this.props.data.map((col, k) => (
                <div key={k} className={cn('card-column', this.props.loading && 'loading')}>
                  {
                    col.title
                      ? <div className='card-column-title'>{col.title}</div>
                      : null
                  }
                  {
                    col.data instanceof Function
                      ? <div className='card-column-data'>{col.data()}</div>
                      : col.html
                        ? <div dangerouslySetInnerHTML={{ __html: col.html }} className='card-column-data'></div>
                        : col.link
                          ? <Link to={col.link.to}>{col.link.label}</Link>
                          : col.action
                            ? <div className='card-column-action' onClick={col.action.onClick}>{col.action.label}</div>
                            : col.component
                              ? <div className='card-column-data'>{this.getComponent(col.component, col.props)}</div>
                              : col.components
                                ? <div className='card-column-data-row'>
                                  {
                                    col.components.map((data, i) => (
                                      this.getComponent(data.component, data.props, i)
                                    ))
                                  }
                                </div>
                                : <div className='card-column-data'>{col.data}</div>
                  }
                </div>
              ))
          }
        </div>
        {
          this.props.bottomFuncPointer instanceof Function
            ? this.props.bottomFuncPointer()
            : null
        }
      </div>
    )
  }
}
