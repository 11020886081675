import { useMemo } from 'react'
import { REGISTER_COLUMNS, URLS } from '@src/constants'
import { Register, TabMedRecordsWithAudit, TabPersonalWithAudit } from '@src/modules/monitoring/components'
import { TabStatusWithAudit } from './TabStatus'


export const RegisterEmergencyNotice = () => {
  const basepath = URLS.MONITORING__INFECTION__EMERGENCY_NOTICE
  const navTitle = 'Реестр экстренных извещений'

  const columns = useMemo(() => {
    return [
      { type: REGISTER_COLUMNS.FULLNAME, settings: { column: { enabled: true } } },
      {
        type: REGISTER_COLUMNS.BIRTH_DATE,
        settings: {
          column: {
            header: {
              title: 'Дата рождения (возраст)',
            },
            enabled: true,
          },
        },
      },
      { type: REGISTER_COLUMNS.GENDER_CODE, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.SNILS, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.DOCUMENTATION_TYPE, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.NOTIFICATION_DATE, settings: { column: { enabled: true } } },
      {
        type: REGISTER_COLUMNS.MO_NAME,
        settings: {
          column: {
            header: {
              title: 'МО, направившая экстренное извещение',
            },
            enabled: true,
          },
        },
      },
      { type: REGISTER_COLUMNS.DIAGNOSIS, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.DIAGNOSIS_DATE, settings: { column: { enabled: true } } },

      {
        type: REGISTER_COLUMNS.LABORATORY_CONFIRMATION_DIAGNOSIS,
        settings: {
          column: {
            enabled: false,
            nullSearch: false,
          },
        },
      },
      { type: REGISTER_COLUMNS.NOTIFICATION_KIND, settings: { column: { enabled: false } } },
      { type: REGISTER_COLUMNS.DETECTION_PLACE, settings: { column: { enabled: false } } },
      { type: REGISTER_COLUMNS.OUTCOME, settings: { column: { enabled: false } } },
      { type: REGISTER_COLUMNS.OUTCOME_DATE, settings: { column: { enabled: false } } },
      { type: REGISTER_COLUMNS.PATIENT_ID, settings: { column: { enabled: false } } },
    ]
  }, [])

  const tabs = useMemo(() => {
    return [
      TabStatusWithAudit,
      TabMedRecordsWithAudit,
      TabPersonalWithAudit,
    ]
  }, [])

  return <Register
    list_type='29'
    columns={columns}
    basepath={basepath}
    navTitle={navTitle}
    tabs={tabs}
    rowKey={'ehr_description_id'}
  />
}
