import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { cloneDeep, isEmpty } from 'lodash'
import { Button, IfNotReadonly } from '@src/components'
import { Alert } from '@src/modals'
import './TabGeneral.scss'
import { AUDIT_PS, URLS } from '@src/constants'
import { selectGraphVersions, selectGraph, putGraph, selectPutGraph, selectCheckStatusModel,
  loadGraphVersions, loadGraph } from '@src/modules/methodology/store'
import { modalService } from '@src/services'
import { withMntAudit } from '@src/hoc'
import { ButtonWithAudit } from '@src/components/Button/Button'
import { CardsBlock } from './CardsBlock/CardsBlock'
import { VersionBlock } from './VersionBlock/VersionBlock'

function TabGeneral () {
  const wrapper = useRef(null)
  const { graph_id: id, version: revisionId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const versionGraph = useSelector(selectGraphVersions)
  const graphModel = useSelector(selectGraph)
  const putGraphModel = useSelector(selectPutGraph)
  const checkStatusModel = useSelector(selectCheckStatusModel)
  //Текущая версия маршрута
  const [currentVersion, setCurrentVersion] = useState(revisionId)
  // Это новая версия?
  const [isNewVersion, setIsNewVersion] = useState(false)
  // Статус "Не черновик", для отключения кнопок Добавления и Удаления Док оснований
  const [isntStatusRoughDraft, setIsntStatusRoughDraft] = useState(false)
  // Были ли изменения в документе, которые надо сохранить при выходе
  const [isDirtyDocument, setIsDirtyDocument] = useState(false)
  // Проверка заполнены ли Документы-основания
  const [isBlockSaveBtn, setIsBlockSaveBtn] = useState(false)
  const [isBlockSaveBtnByTime, setIsBlockSaveBtnByTime] = useState(false)
  // Текущий статус Региональной маршрутизации
  const [currentStatus, setCurrentStatus] = useState(null)
  const dataGI = useMemo(() => cloneDeep(graphModel?.payload?.data?.[0]) || {}, [graphModel?.payload?.data])
  //Сохранение данных для изменения на фронте
  const [loadedDataGI, setLoadedDataGI] = useState(dataGI)
  const [dataForClean] = useState(dataGI)
  const [isBlockByStatus, setIsBlockByStatus] = useState(false)
  const [tooltipInfo, setTooltipInfo] = useState({ show: false, text: '', prevTooltip: false })

  const handleSetCurrentVersion = useCallback(value => setCurrentVersion(value), [])
  const handleSetIsNewVersion = useCallback(value => setIsNewVersion(value), [])
  const handleSetIsntStatusRoughDraft = useCallback(value => setIsntStatusRoughDraft(value), [])
  const handleSetIsDirtyDocument = useCallback(value => setIsDirtyDocument(value), [])
  const handleSetCurrentStatus = useCallback(value => setCurrentStatus(value), [])
  const handleSetLoadedDataGI = useCallback(value => setLoadedDataGI(value), [])
  const handleSetIsBlockSaveBtnByTime = useCallback(value => setIsBlockSaveBtnByTime(value), [])

  const [dataRegionStartedOn, setDataRegionStartedOn] = useState(loadedDataGI?.graph_ext_info?.graph_ext_started_on ? moment(loadedDataGI?.graph_ext_info?.graph_ext_started_on) : null)
  const [dataRegionExpiratedOn, setDataRegionExpiratedOn] = useState(loadedDataGI?.graph_ext_info?.graph_ext_expirated_on ? moment(loadedDataGI?.graph_ext_info?.graph_ext_expirated_on) : null)
  const handleSetDataRegionStartedOn = useCallback(value => setDataRegionStartedOn(value), [])
  const handleSetDataRegionExpiratedOn = useCallback(value => setDataRegionExpiratedOn(value), [])
  const handleSetIsBlockByStatus = useCallback(value => setIsBlockByStatus(value), [])
  const handleSetTooltipInfo = useCallback(value => setTooltipInfo(value), [])

  useEffect(() => {
    setCurrentStatus(p => p === null && dataGI?.graph_ext_info
      ? ({ value: dataGI.graph_ext_info.graph_ext_status_id, label: dataGI.graph_ext_info.graph_ext_status_caption })
      : p
    )
  }, [dataGI])

  useEffect(() => {
    const startValue = currentStatus?.label || loadedDataGI?.graph_ext_info?.graph_ext_status_caption
    handleSetIsntStatusRoughDraft(startValue !== 'Черновик')
  }, [handleSetIsntStatusRoughDraft, currentStatus, loadedDataGI])

  useEffect(() => {
    if (isDirtyDocument) {
      const unblock = history.block((location) => {
        modalService.open(Alert, {
          warn: true,
          onOk: () => {
            unblock()
            history.push(location)
            handleSetIsDirtyDocument(false)
            handleSetCurrentStatus(null)
            handleSetIsNewVersion(false)
            handleSetTooltipInfo({ show: false, text: '', prevTooltip: false })
            handleSetIsBlockByStatus(false)
            handleSetCurrentVersion(revisionId)
            handleSetLoadedDataGI(dataForClean)
          },
          okText: 'Покинуть страницу',
          title: 'Подтверждение перехода',
          children: 'На странице есть несохраненные данные. Вы уверены, что хотите покинуть страницу?',
        })

        return false
      })

      return () => unblock()
    }

  }, [
    history,
    isDirtyDocument,
    handleSetIsDirtyDocument,
    handleSetCurrentVersion,
    handleSetIsNewVersion,
    handleSetLoadedDataGI,
    handleSetTooltipInfo,
    handleSetIsBlockByStatus,
    handleSetCurrentStatus,
    dataForClean,
    revisionId,
  ])

  // Обновление текущей версии при смене адреса
  useEffect(() => {
    if(revisionId && revisionId !== 'null') {
      handleSetCurrentVersion(revisionId)
    }
  }, [revisionId, handleSetCurrentVersion])

  useEffect(() => {
    handleSetLoadedDataGI(dataGI)
  }, [graphModel?.loaded, dataGI, handleSetLoadedDataGI])

  // Проверка есть ли Документы основания у новых документов
  useEffect(() => {
    if((isNewVersion && isEmpty(loadedDataGI?.graph_ext_info?.graph_ext_list_document_npa)))
      setIsBlockSaveBtn(true)
    else if(isEmpty(loadedDataGI?.graph_ext_info?.graph_ext_list_document_npa))
      setIsBlockSaveBtn(true)
    else if(checkStatusModel.loaded && checkStatusModel.payload.ret_code !== 0 && parseInt(currentStatus?.value) !== 1)
      setIsBlockSaveBtn(true)
    else if(!isNewVersion || isEmpty(loadedDataGI?.graph_ext_info?.graph_ext_list_document_npa))
      setIsBlockSaveBtn(false)
    else
      setIsBlockSaveBtn(false)
  }, [loadedDataGI, isNewVersion, setIsBlockSaveBtn, checkStatusModel, dataGI.graph_ext_info, currentStatus?.value])

  // Сохранение документа
  async function handleSaveDocument () {
    handleSetIsDirtyDocument(false)

    // Если это новая версия документа, то нужно обнулить некоорые данные перед отправкой
    if(isNewVersion) {
      dataGI.graph_ext_info.graph_ext_id = null
      dataGI.graph_ext_info.graph_ext_uuid = null
      dataGI.graph_ext_info.graph_ext_status_caption = currentStatus.label
      dataGI.graph_ext_info.graph_ext_status_id = currentStatus.value
      handleSetLoadedDataGI(dataGI)
    }

    dispatch(putGraph({
      graph: { graph_id: id },
      graph_ext_info: {
        ...loadedDataGI.graph_ext_info,
        ...(isNewVersion ?
          {
            graph_ext_id: null,
            graph_ext_uuid: null,
            graph_ext_status_caption: currentStatus.label,
            graph_ext_status_id: currentStatus.value,
          } : {}),

      },
    }))

    dispatch(loadGraph({ graph_id: id, graph_ext_id: parseInt(revisionId) }))
  }

  useEffect(() => {
    if(putGraphModel.loaded && !putGraphModel.error) {
      history.push(`${URLS.METHODOLOGY__ROUTES}/${id}/${putGraphModel.payload?.data?.id}/general`)
      dispatch(loadGraphVersions({ args: { graph_id: id } }))
    }
  }, [putGraphModel, id, history, dispatch])

  // Очистка документа с выходом в Маршруты
  function handleCleanDocument () {
    // history.push(`${URLS.METHODOLOGY__ROUTES}`)
    // }
  }

  return (
    <div className='root-tab-general-route' ref={wrapper}>
      <div className='route-tab-wraper pd-16'>
        <VersionBlock
          id={id}
          revisionId={revisionId}
          dataGI={dataGI}
          currentStatus={currentStatus}
          currentVersion={currentVersion}
          isNewVersion={isNewVersion}
          isDirtyDocument={isDirtyDocument}

          versionGraph={versionGraph}

          handleSetIsDirtyDocument={handleSetIsDirtyDocument}
          handleSetCurrentStatus={handleSetCurrentStatus}
          handleSetLoadedDataGI={handleSetLoadedDataGI}
          handleSetIsNewVersion={handleSetIsNewVersion}
          handleSetCurrentVersion={handleSetCurrentVersion}
          handleSaveDocument={handleSaveDocument}
          handleSetDataRegionStartedOn={handleSetDataRegionStartedOn}
          handleSetDataRegionExpiratedOn={handleSetDataRegionExpiratedOn}
        />
        <CardsBlock
          id={id}
          revisionId={revisionId}
          graphModel={graphModel}
          dataGI={dataGI}

          currentVersion={currentVersion}
          versionGraph={versionGraph}
          loadedDataGI={loadedDataGI}
          currentStatus={currentStatus}
          isNewVersion={isNewVersion}
          isDirtyDocument={isDirtyDocument}
          isntStatusRoughDraft={isntStatusRoughDraft}
          dataRegionStartedOn={dataRegionStartedOn}
          dataRegionExpiratedOn={dataRegionExpiratedOn}
          isBlockByStatus={isBlockByStatus}
          tooltipInfo={tooltipInfo}

          handleSetIsDirtyDocument={handleSetIsDirtyDocument}
          handleSetCurrentStatus={handleSetCurrentStatus}
          handleSetLoadedDataGI={handleSetLoadedDataGI}
          handleSetDataRegionStartedOn={handleSetDataRegionStartedOn}
          handleSetDataRegionExpiratedOn={handleSetDataRegionExpiratedOn}
          handleSetIsBlockSaveBtn={setIsBlockSaveBtn}
          handleSetIsBlockSaveBtnByTime={handleSetIsBlockSaveBtnByTime}
          handleSetIsBlockByStatus={handleSetIsBlockByStatus}
          handleSetTooltipInfo={handleSetTooltipInfo}

          wrapper={wrapper}
        />
      </div>
      <div className='bottom-buttons'>
        <IfNotReadonly>
          <div className='d-flex justify-content-end'>
            <Button
              className='mr-8'
              label='Отмена'
              onClick={handleCleanDocument}
              disabled={!isDirtyDocument}
            />
            <ButtonWithAudit
              icon='check_cr_fr_white'
              label='Сохранить'
              onClick={handleSaveDocument}
              disabled={!isDirtyDocument || isBlockSaveBtn || isBlockSaveBtnByTime}
              auditPs={AUDIT_PS.METHOD}
              auditMessage='Сохранение версии Маршрута'
              isSuccess
            />
          </div>
        </IfNotReadonly>
      </div>
    </div>
  )
}

export const TabGeneralWithAudit = withMntAudit(TabGeneral)

TabGeneralWithAudit.tabTitle = 'Общая информация'
TabGeneralWithAudit.tabPath = 'general'
