import { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './Tag.scss'

export default class Tag extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    color: PropTypes.string.isRequired,
    title: PropTypes.any,
  }

  static defaultProps = {
    className: '',
    children: '',
  }

  render () {
    const { className, children, color, title } = this.props

    return (
      <div title={title || children} className={cn(className, 'root-tag-component', color)}>
        <p>{children}</p>
      </div>
    )
  }
}
