import cn from 'classnames'
import { Button, Modal } from '@src/components'

import './Alert.scss'

export const Alert = ({ onOk, okText, okButtonProps, onCancel, cancelText, onClose, warn, ...props }) => {
  const handleOk = () => {
    onOk && onOk()
    onClose()
  }

  const handleCancel = () => {
    onCancel && onCancel()
    onClose()
  }

  return <Modal
    className='root-alert'
    onClose={onClose}
    {...props}
    footer={<AlertFooter
      warn={warn}
      onOk={handleOk}
      okText={okText}
      okButtonProps={okButtonProps}
      onCancel={handleCancel}
      cancelText={cancelText}
    />}
  ></Modal>
}

const AlertFooter = ({ onOk, okText='Подтвердить', okButtonProps, onCancel, cancelText='Отмена', warn }) => {
  return <div className='alert-footer'>
    <Button isSuccess className={cn(warn && 'button-warn', 'mr-8')} onClick={onOk} label={okText} {...okButtonProps}/>
    <Button onClick={onCancel} label={cancelText}/>
  </div>
}
