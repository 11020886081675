import { createSelector } from 'reselect'
import * as models from './models'

export const selectMonitoring = state => state.monitoring

export const selectPatientListModel = createSelector(
  selectMonitoring,
  monitoring => monitoring[models.PATIENT_LIST],
)

export const selectPatientListCountModel = createSelector(
  selectMonitoring,
  monitoring => monitoring[models.PATIENT_LIST_COUNT],
)

export const selectPatientInfoModel = createSelector(
  selectMonitoring,
  monitoring => monitoring[models.PATINT_INFO],
)

export const selectPersonInfoModel = createSelector(
  selectMonitoring,
  monitoring => monitoring[models.PERSON_INFO],
)

export const selectPatientMedRecordsModel = createSelector(
  selectMonitoring,
  monitoring => monitoring[models.PATIENT_MED_RECORDS],
)

export const selectPatientStatusModel = createSelector(
  selectMonitoring,
  monitoring => monitoring[models.PATIENT_STATUS],
)

export const selectPatientStatusAkineoMotherModel = createSelector(
  selectMonitoring,
  monitoring => monitoring[models.PATIENT_STATUS_AKINEO_MOTHER],
)

export const selectPatientServiceListModel = createSelector(
  selectMonitoring,
  monitoring => monitoring[models.PATIENT_SERVICE_LIST],
)

export const selectPatientListFilters = createSelector(
  selectMonitoring,
  monitoring => monitoring[models.PATIENT_LIST_FILTERS],
)

export const selectMkb10Diagnosis = createSelector(
  selectMonitoring,
  monitoring => monitoring[models.MKB_10_DIAGNOSIS],
)

export const selectOccasionInfoModel = createSelector(
  selectMonitoring,
  monitoring => monitoring[models.OCCASION_INFO],
)

export const selectPatientLastEhrModel = createSelector(
  selectMonitoring,
  monitoring => monitoring[models.PATIENT_LAST_EHR],
)

export const selectPatientNeonatalScreening = createSelector(
  selectMonitoring,
  monitoring => monitoring[models.PATIENT_NEONATAL_SCREENING],
)

export const selectPatientStatusChild = createSelector(
  selectMonitoring,
  monitoring => monitoring[models.PATIENT_STATUS_CHILD],
)

export const selectPatientStatusInfection = createSelector(
  selectMonitoring,
  monitoring => monitoring[models.PATIENT_STATUS_INFECTION],
)

export const selectPatientInfoInfection = createSelector(
  selectMonitoring,
  monitoring => monitoring[models.PATIENT_INFO_INFECTION],
)

export const selectEmergencyNoticeWidgetInfection = createSelector(
  selectMonitoring,
  monitoring => monitoring[models.EMERGENCY_NOTICE_WIDGET_INFECTION],
)

export const selectDnWidgetInfection = createSelector(
  selectMonitoring,
  monitoring => monitoring[models.DN_WIDGET_INFECTION],
)

export const selectPatientEmergencyNoticeModel = createSelector(
  selectMonitoring,
  monitoring => monitoring[models.PATIENT_EMERGENCY_NOTICE],
)

export const selectPatientEmergencyNoticeWidgetModel = createSelector(
  selectMonitoring,
  monitoring => monitoring[models.PATIENT_EMERGENCY_NOTICE_WIDGET],
)