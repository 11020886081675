import { createSlice } from '@reduxjs/toolkit'
import { initialModel } from '@src/utils'

import * as models from './lib/models'
import reducers from './lib/reducers'
import extraReducers from './lib/thunks'

const initialState = {
  [models.JOURNAL_SEMD_LIST]: initialModel,
  [models.JOURNAL_AUDIT_LIST]: initialModel,
  [models.JOURNAL_SEMD_DETAILS]: initialModel,
  [models.JOURNAL_EXT_SYSTEMS_LIST]: initialModel,
  [models.JOURNAL_SEMD_DOC_TYPE_LIST]: initialModel,
  [models.JOURNAL_SEMD_STATUS_LIST]: initialModel,
  [models.JOURNAL_AUDIT_WRITE]: initialModel,
  [models.JOURNAL_MESSAGE_ATTRIBUTES]: initialModel,
  [models.JOURNAL_OUTGOING_MESSAGE]: initialModel,
  [models.JOURNAL_PROCESSING_LOG]: initialModel,
  [models.JOURNAL_MSG_INDEX]: initialModel,
}

export const journalSlice = createSlice({
  name: 'journal',
  initialState,
  reducers,
  extraReducers,
})

export * from './lib/selectors'
export * from './lib/thunks'
export default journalSlice.reducer

export const { setJournalFilters } = journalSlice.actions
