import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isString, isArray } from 'lodash'
import { selectCurrentRole } from '@src/store'


function withAcl (WrappedComponent) {
  class WithAclHoc extends React.Component {
    static propTypes = {
      currentRole: PropTypes.object,
      allowAcl: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      deniedComponent: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
      ]),
    }

    static defaultProps = {
      deniedComponent: null,
    }

    get isAccessAllowed () {
      const { currentRole, allowAcl } = this.props

      if(!allowAcl) return true

      const acls = isString(allowAcl)
        ? [allowAcl]
        : allowAcl

      return currentRole && isArray(currentRole.acl) &&
        acls.find(acl => currentRole.acl.includes(acl))

    }

    render () {
      return this.isAccessAllowed
        ? <WrappedComponent {...this.props} />
        : this.props.deniedComponent
    }
  }

  const mapStateToProps = (state) => {
    return {
      currentRole: selectCurrentRole(state),
    }
  }

  const mapDispatchToProps = () => ({
  })

  return connect(mapStateToProps, mapDispatchToProps)(WithAclHoc)
}
export default withAcl
