import { isBoolean } from 'lodash'
import { Combobox } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class LaboratoryConfirmationDiagnosisColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.LABORATORY_CONFIRMATION_DIAGNOSIS

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Лабораторное подтверждение диагноза',
        component: Combobox,
        props: {
          allowClear: true,
          placeholder: 'Все',
          options: [
            { label: 'Да', value: true, title: 'Да' },
            { label: 'Нет', value: false, title: 'Нет' },
          ],
        },
      },
      width: 150,
      dataIndex: 'lab_confirmation',
      dataRender: lab_confirmation => typeof lab_confirmation === 'boolean' ? (lab_confirmation ? 'Да' : 'Нет') : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let requestFilters = []

    if (isBoolean(filters?.lab_confirmation)) {
      requestFilters = [{ field: 'lab_confirmation', value: filters.lab_confirmation, operator: '=', invalid: false }]
    }

    if (nullSearches[this.type]) {
      requestFilters = [{ field: this.type, operator: '=', value: null, invalid: false }]
    }

    return {
      control: {
        filters: requestFilters,
      },
    }
  }
}
