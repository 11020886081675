import { createSelector } from 'reselect'
import * as models from './models'

export const selectDictionaries = state => state.dictionaries

export const selectMclListModel = createSelector(
  selectDictionaries,
  dictionaries => dictionaries[models.DICT_MCL_LIST],
)

export const selectDictResponseLevelListModel = createSelector(
  selectDictionaries,
  dictionaries => dictionaries[models.DICT_RESPONSE_LEVEL_LIST],
)

export const selectDictGendersModel = createSelector(
  selectDictionaries,
  dictionaries => dictionaries[models.DICT_GENDERS],
)

export const selectDictPregnancyOutcomesModel = createSelector(
  selectDictionaries,
  dictionaries => dictionaries[models.DICT_PREGNANCY_OUTCOMES],
)
export const selectDictImmunizationModel = createSelector(
  selectDictionaries,
  dictionaries => dictionaries[models.DICT_IMMUNIZATION],
)

export const selectDictMoModel = createSelector(
  selectDictionaries,
  dictionaries => dictionaries[models.DICT_MO],
)

export const selectDictRegionModel = createSelector(
  selectDictionaries,
  dictionaries => dictionaries[models.DICT_REGION],
)

export const selectDictDiseasesModel = createSelector(
  selectDictionaries,
  dictionaries => dictionaries[models.DICT_DISEASES],
)

export const selectDictDnReason = createSelector(
  selectDictionaries,
  dictionaries => dictionaries[models.DIC_DN_REASON],
)

export const selectDictResultDetection = createSelector(
  selectDictionaries,
  dictionaries => dictionaries[models.DIC_RESULT_DETECTION],
)

export const selectDictDetectionPlace = createSelector(
  selectDictionaries,
  dictionaries => dictionaries[models.DIC_DETECTION_PLACE],
)

export const selectDictDetectionPlaceInfection = createSelector(
  selectDictionaries,
  dictionaries => dictionaries[models.DIC_DETECTION_PLACE_INFECTION],
)

export const selectDictValidityDegree = createSelector(
  selectDictionaries,
  dictionaries => dictionaries[models.DIC_VALIDITY_DEGREE],
)

export const selectDictNoticeType = createSelector(
  selectDictionaries,
  dictionaries => dictionaries[models.DIC_NOTICE_TYPE],
)

export const selectDictStatusInfectionType = createSelector(
  selectDictionaries,
  dictionaries => dictionaries[models.DIC_STATUS_INFECTION],
)

export const selectDictTypesOfNotification = createSelector(
  selectDictionaries,
  dictionaries => dictionaries[models.DICT_TYPES_OF_NOTIFICATION],
)
