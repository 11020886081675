import { isBoolean } from 'lodash'
import { Combobox } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'


export class BloodTransfusionsColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.BLOOD_TRANSFUSIONS


  constructor (store, settings) {
    const column = {
      header: {
        title: 'Факт переливания крови',
        component: Combobox,
        props: {
          allowClear: true,
          placeholder: 'Все',
          options: [
            { label: 'Да', value: true, title: 'Да' },
            { label: 'Нет', value: false, title: 'Нет' },
          ],
        },
      },
      width: 300,
      dataIndex: 'blood_transfusions',
      dataRender: blood_transfusions => typeof blood_transfusions === 'boolean' ? (blood_transfusions ? 'Да' : 'Нет') : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let requestFilters = []

    if(isBoolean(filters?.blood_transfusions)) {
      requestFilters = [{ field: 'blood_transfusions', value: filters.blood_transfusions, operator: '=', invalid: false }]
    }

    if (nullSearches[this.type]) {
      requestFilters = [{ field: this.type, operator: '=', value: null, invalid: false }]
    }

    return {
      control: {
        filters: requestFilters,
      },
    }
  }
}
