import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataGrid, Drawer, TextInput } from '@src/components'
import { SORT_ORDER } from '@src/constants'
import { withMntAudit } from '@src/hoc/withAudit/withAudit'
import { useDebounce } from '@src/hooks/useDebounce'
import { useMountEffect } from '@src/hooks/useMountEffect'
import { loadAdminUsersList, resetAdminUsersList, selectAdminUsersListModel } from '@src/modules/admin/store'
import { normalizeStr } from '@src/utils'
import './EmployeesDrawer.scss'

const CHUNK_SIZE = 100

const EmployeesDrawer = ({ roleId, onClose, ...props }) => {
  const [gridValue, setGridValue] = useState(null)
  const handleSearch = (value, rowCount = 0) => {
    dispatch(loadAdminUsersList({
      args: {
        role_id: roleId,
        ...value?.filters?.login ? { searchText: normalizeStr(value.filters?.login) } : null,
      },
      control: {
        filters: [],
        sorts: value?.sort ? [{ field: value.sort.column, sort: value.sort.order }] : [],
        range: { chunk_start: rowCount, chunk_end: rowCount + CHUNK_SIZE - 1 },
      },
    }))
  }
  const debouncedHandleSearch = useDebounce(handleSearch, 500)
  const dispatch = useDispatch()
  const adminUsersListModel = useSelector(selectAdminUsersListModel)
  const handleLoadMore = (value, rowCount) => !adminUsersListModel.loading && adminUsersListModel.payload.hasMoreToLoad && handleSearch(value, rowCount)

  useMountEffect(() => {
    return () => dispatch(resetAdminUsersList())
  })

  useEffect(() => debouncedHandleSearch(gridValue), [gridValue, debouncedHandleSearch])

  const tableHeaders = {
    login: {
      width: 80,
      sort: true,
      dataIndex: 'login',
      defaultSort: SORT_ORDER.ASC,
      header: {
        title: 'Логин',
        component: TextInput,
        props: {
          icon: 'search',
          allowClear: true,
          onChange: e => setGridValue({ ...gridValue, filters: { login: e, fio: e } }),
        },
      },
    },
    fio: {
      width: 100,
      sort: true,
      dataIndex: 'fio',
      header: {
        title: 'ФИО',
        component: TextInput,
        props: {
          icon: 'search',
          allowClear: true,
          onChange: e => setGridValue({ ...gridValue, filters: { login: e, fio: e } }),
        },
      },
    },
  }

  return (
    <Drawer
      className='root-employees-drawer'
      title='Список сотрудников'
      width={560}
      onClose={onClose}
      {...props}
    >
      <DataGrid
        columns={tableHeaders}
        data={adminUsersListModel?.payload?.data ?? []}
        rowKey='id'
        loading={adminUsersListModel.loading}
        error={adminUsersListModel.error}
        value={gridValue}
        onChange={setGridValue}
        onReset={handleSearch}
        onSortChange={debouncedHandleSearch}
        onFilterChange={debouncedHandleSearch}
        onScrolledToBottom={handleLoadMore}
      />
    </Drawer>
  )
}

EmployeesDrawer.propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func,
}

export const EmployeesDrawerWithAudit = withMntAudit(EmployeesDrawer)
