import { thunkCreator, thunkCreatorNested } from '@src/utils'
import * as models from './models'

export const [
  loadNsiDictList, loadNsiDictListExtra,
] = thunkCreator(models.NSI_FRNSI_DICT_LIST, '/psi/update/NSI/fetch', { method: 'GET', timeout: 120 * 1000 })

export const [
  loadNsiDict, loadNsiDictExtra,
] = thunkCreatorNested(
  models.NSI_FRNSI_DICTS,
  '/psi/refbook/id/:refbook_identifier',
  arg => [arg.pathParams.refbook_identifier, 'dict'],
  {
    extractor: d => d.data,
    method: 'GET',
  }
)

export const [
  loadNsiDictVersions, loadNsiDictVersionsExtra,
] = thunkCreatorNested(
  models.NSI_FRNSI_DICT_VERSIONS,
  '/psi/refbook/id/:refbook_identifier/version',
  arg => [arg.pathParams.refbook_identifier, 'versions'],
  {
    extractor: d => d?.data?.items,
    method: 'GET',
    targetModel: models.NSI_FRNSI_DICTS,
  }
)

export const [
  loadNsiDictVersionRecords, loadNsiDictVersionRecordsExtra,
] = thunkCreator(
  models.NSI_FRNSI_DICTS_RECORDS_LIST,
  '/psi/refbook/id/:refbook_identifier/:version_tag/data',
  {
    method: 'GET',
    extractor: d => d?.data?.items,
  }
)

export const [
  importNsiFrnsiDict, importNsiFrnsiDictExtra,
] = thunkCreator(models.IMPORT_NSI_FRNSI_DICT, '/psi/update/NSI/:refbook_identifier/pull', { method: 'GET', timeout: 120 * 1000 })

const extraReducers = {
  ...loadNsiDictExtra,
  ...loadNsiDictListExtra,
  ...loadNsiDictVersionsExtra,
  ...loadNsiDictVersionRecordsExtra,
  ...importNsiFrnsiDictExtra,
}

export default extraReducers
