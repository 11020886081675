import { Component } from 'react'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import { PropTypes } from '@src/utils'
import { Button, Combobox, TextInput, Drawer } from '@src/components'
import { loadAdminRolesById, selectAdminRolesById, selectAdminSetRoleModel, setAdminRole } from '@src/modules/admin/store'
import { selectDictResponseLevelListModel, selectMclListModel } from '@src/modules/dictionaries/store'
import { pushNotification } from '@src/store'
import { AUDIT_PS, NOTIFICATION_TYPES } from '@src/constants'
import { withAudit } from '@src/hoc/withAudit/withAudit'
import './CreateRoleDrawer.scss'

class _CreateRoleDrawer extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    mclList: PropTypes.array,
    responseLevelList: PropTypes.array,
    setAdminRole: PropTypes.func,
    adminSetRoleModel: PropTypes.model,
    adminRole: PropTypes.model,
    logToAudit: PropTypes.func,
    open: PropTypes.bool,
    selectedRole: PropTypes.object,
  }

  constructor (props) {
    super(props)

    this.initialState = {
      mclProfileIdInput: !props.selectedRole ? null : String(props.selectedRole.mcl_profile_id),
      roleNameInput: !props.selectedRole ? '' : props.selectedRole.role_name,
      responseLevelIdInput: !props.selectedRole ? null : String(props.selectedRole.response_level_id),
      mainPageInput: !props.selectedRole ? '' : props.selectedRole.main_page,
      roleCode: !props.selectedRole ? '' : props.selectedRole.role_code,
      id: !props.selectedRole ? '' : this.props.selectedRole.id,
    }

    this.state = this.initialState
  }

  handleSave = async () => {
    if(this.props.selectedRole) {
      await this.props.loadAdminRoleById(this.state.id)

      if(this.props.adminRole.data[0].is_assigned) {
        this.props.pushNotification({ type: NOTIFICATION_TYPES.ERROR, message: 'Данную роль уже назначили пользователю' })
        return false
      }
    }

    const arg = {
      id: this.props.selectedRole ? this.props.selectedRole.id : null,
      mclProfileId: this.state.mclProfileIdInput,
      roleName: this.state.roleNameInput,
      responseLevelId: this.state.responseLevelIdInput,
      mainPage: this.state.mainPageInput,
      role_code: this.state.roleCode,
    }
    this.props.logToAudit({
      auditPs: AUDIT_PS.ADMIN,
      auditMessage: `${this.props.selectedRole ? 'Редактирование' : 'Добавление'} роли ${this.state.roleNameInput}`,
      auditDescription: { arg },
    })
    await this.props.setAdminRole(arg)
    if(this.props.adminSetRoleModel.error)
      return false

    this.props.onClose()
  }

  render () {
    const { mclList, responseLevelList, adminSetRoleModel, onClose, open, selectedRole } = this.props

    return (
      <Drawer
        className='add-user-role-section'
        title={selectedRole ? 'Редактирование роли' : 'Добавление роли'}
        width={590}
        onClose={onClose}
        open={open}
        footer={<DrawerFooter {...
          { onClose,
            selectedRole,
            loading: adminSetRoleModel.loading,
            handleSave: this.handleSave,
            disabled: isEqual(this.state, this.initialState) }
        }
        />}
      >
        <div className='add-user-role-section-content'>
          <div className='add-user-role-section-content-input'>
            <div className='add-user-role-section-content-input-title'>Направление ОМП</div>
            <Combobox
              className='add-user-role-section-content-input-field'
              disabled={selectedRole}
              onChange={val => this.setState({ mclProfileIdInput: val })}
              value={this.state.mclProfileIdInput}
              options={mclList.map(mcl => ({ label: mcl.short_name, value: String(mcl.id) }))}
              placeholder='Выберите направление ОМП'
            />
          </div>

          <div className='add-user-role-section-content-input'>
            <div className='add-user-role-section-content-input-title'>Имя роли</div>
            <TextInput
              className='add-user-role-section-content-input-field'
              name='roleNameInput'
              value={this.state.roleNameInput}
              onChange={val => this.setState({ roleNameInput: val })}
            />
          </div>

          <div className='add-user-role-section-content-input'>
            <div className='add-user-role-section-content-input-title'>Код роли</div>
            <TextInput
              disabled={!!selectedRole}
              className='add-user-role-section-content-input-field'
              name='roleNameInput'
              value={this.state.roleCode}
              onChange={val => this.setState({ roleCode: val })}
            />
          </div>

          <div className='add-user-role-section-content-input'>
            <div className='add-user-role-section-content-input-title'>Уровень</div>
            <Combobox
              className='add-user-role-section-content-input-field'
              onChange={val => this.setState({ responseLevelIdInput: val })}
              value={this.state.responseLevelIdInput}
              options={responseLevelList.map(level => ({ label: level.name, value: String(level.id) }))}
              disabled={selectedRole ? selectedRole?.is_assigned : false}
              placeholder='Выберите уровень'
            />
          </div>

          <div className='add-user-role-section-content-input'>
            <div className='add-user-role-section-content-input-title'>URL главной страницы</div>
            <TextInput
              className='add-user-role-section-content-input-field'
              name='mainPageInput'
              value={this.state.mainPageInput}
              onChange={val => this.setState({ mainPageInput: val })}
            />
          </div>
        </div>
      </Drawer>
    )
  }
}

const DrawerFooter = ({ onClose, disabled, handleSave, loading }) => {
  return (
    <div className='add-user-role-section-footer'>
      <Button
        className='save'
        label='Отмена'
        onClick={onClose}
      />
      <Button
        disabled={disabled}
        label='Сохранить'
        onClick={handleSave}
        loading={loading}
        icon='check_cr_fr_white'
        isSuccess
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    mclList: selectMclListModel(state).payload.data,
    responseLevelList: selectDictResponseLevelListModel(state).payload.data,
    adminSetRoleModel: selectAdminSetRoleModel(state),
    adminRole: selectAdminRolesById(state).payload,
  }
}

const mapDispatchToProps = dispatch => ({
  setAdminRole: args => dispatch(setAdminRole(args)),
  loadAdminRoleById: id => dispatch(loadAdminRolesById(id)),
  pushNotification: args => dispatch(pushNotification(args)),
})

const CreateRoleDrawer = connect(mapStateToProps, mapDispatchToProps)(_CreateRoleDrawer)
export const CreateRoleDrawerWithAudit = withAudit(CreateRoleDrawer)
