import { Component } from 'react'
import { REGISTER_COLUMNS, URLS } from '@src/constants'
import { Register, TabMedRecordsWithAudit, TabPersonalWithAudit } from '@src/modules/monitoring/components'
import { TabStatusWithAudit } from './TabStatus'

export class RegisterInfection extends Component {
  get basepath () {
    return URLS.MONITORING__INFECTION__IB
  }

  get navTitle () {
    return 'Реестр больных инфекционными заболеваниями'
  }

  get columns () {
    return [
      { type: REGISTER_COLUMNS.FULLNAME, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.BIRTH_DATE, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.GENDER_CODE, settings: { column: { enabled: true } } },
      {
        type: REGISTER_COLUMNS.SNILS,
        settings: {
          column: {
            nullSearch: false,
            enabled: true,
          },
        },
      },
      {
        type: REGISTER_COLUMNS.DIAGNOSIS,
        settings: {
          column: {
            nullSearch: false,
            enabled: true,
          },
        },
      },
      { type: REGISTER_COLUMNS.VALIDITY_DEGREE, settings: { column: { enabled: true, width: 300 } } },
      {
        type: REGISTER_COLUMNS.DIAG_DATE,
        settings: {
          column: {
            nullSearch: false,
            enabled: true,
          },
        },
      },
      { type: REGISTER_COLUMNS.FIRST_DIAG_BEGIN_ON, settings: { column: { enabled: false } } },
      { type: REGISTER_COLUMNS.EMERGENCY_NOTIFICATION_FLAG, settings: { column: { enabled: false } } },
      { type: REGISTER_COLUMNS.COMPLICATION_DIAG_LIST, settings: { column: { enabled: false } } },
      { type: REGISTER_COLUMNS.CONCOMITANT_DIAG_LIST, settings: { column: { enabled: false } } },
      { type: REGISTER_COLUMNS.LAB_DIAGNOSTICS_LIST, settings: { column: { enabled: false } } },
      { type: REGISTER_COLUMNS.MEDICATION_LIST, settings: { column: { enabled: false } } },
      { type: REGISTER_COLUMNS.DN_DOC_LIST, settings: { column: { enabled: false } } },
      { type: REGISTER_COLUMNS.DN_MO_ID, settings: { column: { enabled: false } } },
      { type: REGISTER_COLUMNS.DN_END_ON, settings: { column: { enabled: false } } },
      { type: REGISTER_COLUMNS.DN_REASON_ID, settings: { column: { enabled: false } } },
      {
        type: REGISTER_COLUMNS.OUTCOME,
        settings: {
          column: {
            header: {
              title: 'Исход случая',
            },
            enabled: false,
          },
        },
      },
      { type: REGISTER_COLUMNS.OUTCOME_DATE, settings: { column: { enabled: false } } },
      { type: REGISTER_COLUMNS.INFECTION_STATUS, settings: { column: { enabled: false } } },
      {
        type: REGISTER_COLUMNS.DEATH_DATE,
        settings: {
          column: {
            header: {
              title: 'Дата смерти',
            },
            enabled: false,
          },
        },
      },
      { type: REGISTER_COLUMNS.DEATH_DIAG, settings: { column: { enabled: false } } },
      { type: REGISTER_COLUMNS.DEATH_PLACE, settings: { column: { enabled: false } } },
      { type: REGISTER_COLUMNS.PATIENT_ID, settings: { column: { enabled: false } } },
    ]
  }

  get tabs () {
    return [
      TabStatusWithAudit,
      TabMedRecordsWithAudit,
      TabPersonalWithAudit,
    ]
  }

  render () {
    return <Register
      list_type='22'
      columns={this.columns}
      basepath={this.basepath}
      navTitle={this.navTitle}
      tabs={this.tabs}
    />
  }
}
