import { Drawer, Input } from 'antd'
import { isNil } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, IfNotReadonly, LoadedOrError } from '@src/components'
import { useMountEffect } from '@src/hooks'
import { getReferenceAttribute, selectReferenceAttributeModel } from '@src/modules/methodology/store'
import { normalizeStr } from '@src/utils'
import { AccessContext } from '@src/context'
import { ValueSelectorPanel } from './ValueSelectorPanel'
import './RefbookValueSelectorDrawer.scss'

export const RefbookValueSelectorDrawer = ({ initialValues=[], rb_key_field_id, rb_lookup_field, rb_lookup_list, maxSelectCount, onAccept, readonly, onClose, ...props }) => {
  const dispatch = useDispatch()

  const referenceAttributeModel = useSelector(selectReferenceAttributeModel)

  useMountEffect(() => {
    dispatch(getReferenceAttribute({ args: {
      rb_key_field_id,
      rb_lookup_field,
      rb_lookup_list,
      is_list: false, // параметр is_list, походу, вообще не участвует в процессе отбора данных, но его обязательность проверяется на стороне бэка
    } }))
  })

  // ra = reference attribute
  const raData = useMemo(() => {
    return referenceAttributeModel.payload?.data?.[0]
  }, [referenceAttributeModel.payload?.data])

  const allValues = useMemo(() => {
    return (raData && raData.rb_value_left_list) || []
  }, [raData])

  // Преобразуем список всех доступных значений в мапку, чтобы исключить избыточные обходы массива в дальнейшем
  const allValuesMap = useMemo(() => {
    const map = {}

    allValues.forEach(v => map[v.value] = v)

    return map
  }, [allValues])

  const [filter, setFilter] = useState('')
  const filteredValues = useMemo(() => {
    if(filter.length < 3) return allValues

    const filterCaseInsensibleWihtoutSpaces = (value, filter) => {
      if (!value) return false
      const f = normalizeStr(filter)
      const v = normalizeStr(String(value))

      return v.includes(f)
    }

    return allValues.filter(v => filterCaseInsensibleWihtoutSpaces(v['ref_item_id'], filter) ||
      filterCaseInsensibleWihtoutSpaces(v['ref_item_code'], filter) ||
      filterCaseInsensibleWihtoutSpaces(v['ref_item_name'], filter)
    )
  }, [allValues, filter])

  // Массив value выбранных значений
  const [selectedValues, setSelectedValues] = useState(initialValues.map(v => v.value).filter(v => !isNil(v)))

  const handleSelect = useCallback((value) => {
    setSelectedValues((prev) => {
      if (prev.length < maxSelectCount) return [...prev, value]
      return prev
    })
  }, [maxSelectCount])

  const handleDeselect = useCallback((value) => {
    setSelectedValues(prev => prev.filter(e => e !== value))
  }, [])

  const handleSave = useCallback(() => {
    onAccept(selectedValues.map(v => allValuesMap[v]))
    onClose()
  }, [allValuesMap, onAccept, onClose, selectedValues])

  return <AccessContext.Provider value={{ readonly }}>
    <Drawer
      className='root-refbook-value-selector-drawer'
      title={raData?.rb_name || 'Выбор справочного значения'}
      placement='bottom'
      height='93%'
      maskClosable={false}
      onClose={onClose}
      footer={
        <IfNotReadonly>
          <RefbookValueSelectorDrawerFooter
            disabled={referenceAttributeModel.loading}
            onSave={handleSave}
            selectedCount={selectedValues.length}
            maxSelectCount={maxSelectCount}
          />
        </IfNotReadonly>
      }
      {...props}
    >
      <div className='value-selector-filters pa-16 d-flex'>
        <Input
          status={filter.length > 0 && filter.length < 3 && 'warning'}
          className='w-300'
          placeholder='Поиск по значениям'
          value={filter}
          onChange={e => setFilter(e.target.value)}
          allowClear
          disabled={referenceAttributeModel.loading}
        />
        <IfNotReadonly>
          <Button
            disabled={referenceAttributeModel.loading}
            className='ml-auto'
            label='Очистить все'
            icon='minus_sq_fr_gray'
            onClick={() => setSelectedValues([])}
          />
        </IfNotReadonly>
      </div>
      <LoadedOrError
        loading={referenceAttributeModel.loading}
        loaded={referenceAttributeModel.loaded}
        error={referenceAttributeModel.error}
      >
        {
          raData &&  <ValueSelectorPanel
            values={filteredValues}
            valuesMap={allValuesMap}
            selectedValues={selectedValues}
            onSelectValue={handleSelect}
            onDeselectValue={handleDeselect}
            selectionDisabled={selectedValues.length >= maxSelectCount}
          />
        }
      </LoadedOrError>
    </Drawer>
  </AccessContext.Provider>
}

const RefbookValueSelectorDrawerFooter = ({ disabled, loading, selectedCount, maxSelectCount, onSave }) => {
  const selectedStr = '(' + selectedCount + (maxSelectCount !== Number.MAX_SAFE_INTEGER ? `/${maxSelectCount}` : '') + ')'

  return <div className='h-44'>
    <div className='h-100p d-flex flex-direction-row align-items-center'>
      <Button
        className='ml-auto'
        isSuccess
        icon='check_cr_fr_white'
        label={'Завершить выбор' + selectedStr}
        disabled={disabled}
        loading={loading}
        onClick={onSave}
      />
    </div>
  </div>
}
