import { isArray } from 'lodash'
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { AppNav, Icon, LoadedOrError } from '@src/components'
import { ACLS, GRAPH_STATUSES, URLS } from '@src/constants'
import { AccessContext } from '@src/context'
import { useLoadModels, useMountEffect, useUserHasResource } from '@src/hooks'
import { Alert } from '@src/modals'
import { loadDictMo, selectDictMoModel } from '@src/modules/dictionaries/store'
import { CopyConditionModal } from '@src/modules/methodology/pages/MethRegConditionsPage/CopyConditionModal/CopyConditionModal'
import {
  getValueTypeList,
  loadDeleteConditionCsp, loadDictDeviationValue, loadGetAttributeTreeByType,
  loadGetConditionsListCsp, loadGraphDescription, resetMethRegConditionsState,
  selectDictDeviationValue, selectGetAttributeTreeByType, selectGetConditionsListCsp, selectGraph, selectGraphDescriptionModel, selectValueTypeListModel,
} from '@src/modules/methodology/store'
import { withMntAudit } from '@src/hoc'
import { modalService } from '@src/services'
import { ConditionsContainer } from './Conditions/ConditionsContainer'
import { ConditionsList } from './ConditionsList/ConditionsList'
import './MethRegConditionsPage.scss'

const MethRegConditionsPage = () => {
  const { graph_id, version, stage_id, route_state_id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const graphModel = useSelector(selectGraph)
  const graphDescriptionModel = useSelector(selectGraphDescriptionModel)
  const getConditionsListCspModel = useSelector(selectGetConditionsListCsp) // Получить списко условий для <ConditionList/>
  const canEditRoutes = useUserHasResource(ACLS.METHOD_GRAPH_EDIT)

  const [loaded, loading, error] = useLoadModels(
    [useSelector(selectGetAttributeTreeByType), loadGetAttributeTreeByType({
      type_id: 5,
      time_cond_limitation: true,
    })],
    [useSelector(selectDictDeviationValue), loadDictDeviationValue()],
    [useSelector(selectDictMoModel), loadDictMo()],
    [useSelector(selectValueTypeListModel), getValueTypeList()],
    [graphModel],
    [graphDescriptionModel],
    [getConditionsListCspModel]
  )

  const routeState = useMemo(() => {
    const stage = graphDescriptionModel?.payload?.data?.[0]?.route_stage?.find(stage => Number(stage.stage_id) === Number(stage_id))
    return stage?.route_state?.find(state => Number(state?.route_state_id) === Number(route_state_id))
  }, [graphDescriptionModel?.payload?.data, route_state_id, stage_id])

  const graph = useMemo(() => {
    return graphModel?.payload?.data?.[0]?.graph
  }, [graphModel?.payload?.data])

  const graphExtInfo = useMemo(() => {
    return graphModel?.payload?.data?.[0]?.graph_ext_info
  }, [graphModel?.payload?.data])

  // Статус маршрута, для проверки является ли маршрут черновиком или нет
  const graphStatusId = useMemo(() => {
    return graphExtInfo?.graph_ext_status_id
  }, [graphExtInfo?.graph_ext_status_id])

  // Редьюсер для управления состоянием списка условий и выбранным условием
  const [{ selectedCondition, conditionList }, dispatchCondition] = useReducer((state, action) => {
    switch (action.type) {
    case 'CONDITION_LIST_LOADED': {
      const newConditionList = (action.conditionList || [])
        ?.slice()
        ?.sort((a, b) => a.number_condition - b.number_condition)

      return {
        conditionList: newConditionList,
        // Выбираем первое условие, если оно есть
        selectedCondition: newConditionList.length ? newConditionList[0] : null,
      }
    }
    // Создаем новое условие, добавляем его в конец списка,и автоматически выбираем
    case 'INSERT_NEW_CONDITION': {
      const newCondition = {
        number_condition: getNextConditionNumber(state.conditionList),
        number_condition_id: null,
        number_condition_uuid: null,
      }
      return {
        conditionList: [...state.conditionList, newCondition],
        selectedCondition: newCondition,
      }
    }
    case 'UPDATE_CONDITION': {
      // Индекс обновляемого условия
      const index = state.conditionList.findIndex(cond => cond.number_condition === action.condition.number_condition)

      return {
        selectedCondition: state.selectedCondition.number_condition === action.condition.number_condition
          ? action.condition
          : selectedCondition,
        conditionList: [
          ...state.conditionList.slice(0, index),
          action.condition,
          ...state.conditionList.slice(index + 1),
        ],
      }
    }
    case 'SELECT_CONDITION': return {
      ...state,
      selectedCondition: action.condition,
    }
    case 'DELETE_CONDITION': {
      // Индекс удаляемого условия
      const index = state.conditionList.findIndex(cond => cond.number_condition === action.condition.number_condition)
      const newConditionList = [
        ...state.conditionList.slice(0, index),
        ...state.conditionList.slice(index + 1),
      ]
      return {
        conditionList: newConditionList,
        // Если после удаления массив остался не пуст, и мы удалили выбранное условие, то выбираем первое условие
        selectedCondition: newConditionList.length
          ? state.selectedCondition.number_condition === action.condition.number_condition
            ? newConditionList[0]
            : state.selectedCondition
          : null,
      }
    }
    default: return state
    }
  }, {
    selectedCondition: null,
    conditionList: null,
  })

  // Состояние подэтапов могло измениться в результате редактирования, обновляем graphDescription при размонтировании
  useMountEffect(() => {
    return () => {
      dispatch(loadGraphDescription({ graph_id, region_graph_id: parseInt(version) }))
    }
  })

  // Загружаем список условий для подэтапа
  useEffect(() => {
    dispatch(loadGetConditionsListCsp({
      graph_ext_id: version,
      route_state_id,
    }))

    return () => {
      dispatch(resetMethRegConditionsState())
    }
  }, [dispatch, route_state_id, version])

  // После загрузки условий с бэка устанавливаем внутренний стейт списка условий
  useEffect(() => {
    if(getConditionsListCspModel.loaded) {
      dispatchCondition({ type: 'CONDITION_LIST_LOADED', conditionList: getConditionsListCspModel.payload?.data?.[0]?.route_state_condition })
    }
  }, [getConditionsListCspModel.loaded, getConditionsListCspModel.payload?.data])

  // Если массив условий оказался пуст, автоматически добавляем новое
  useEffect(() => {
    if(isArray(conditionList) && conditionList.length === 0) {
      dispatchCondition({ type: 'INSERT_NEW_CONDITION' })
    }
  }, [conditionList])

  const handleDeleteCondition = useCallback(async (condition) => {
    dispatchCondition({ type: 'DELETE_CONDITION', condition })
    // Если условие уже сохранено на бэке - удаляем его запросом
    if (condition.number_condition_id) {
      dispatch(loadDeleteConditionCsp({
        route_state_id,
        number_condition_id: condition.number_condition_id,
      }))
    }
  }, [dispatch, route_state_id])

  const handleUpdateCondition = useCallback((condition) => {
    dispatchCondition({ type: 'UPDATE_CONDITION', condition })
  }, [])

  const handleAddCondition = useCallback(() => {
    dispatchCondition({ type: 'INSERT_NEW_CONDITION' })
  }, [])

  const handleCopyCondition = useCallback(() => {
    modalService.open(CopyConditionModal, {
      routeStateId: route_state_id,
      routeStateCode: routeState?.state_code,
      routeVersionId: version,
      routeDocuments: graphExtInfo?.graph_ext_list_document_npa,
      conditionNumber: getNextConditionNumber(conditionList?.filter(e => e.number_condition_id)), // Учитываем только уже сохраненные условия
      onSuccess: (newCondition) => {
        dispatchCondition('SELECT_CONDITION', newCondition)
        dispatch(loadGetConditionsListCsp({
          graph_ext_id: version,
          route_state_id,
        }))
      },
    })
  }, [conditionList, dispatch, graphExtInfo?.graph_ext_list_document_npa, routeState?.state_code, route_state_id, version])

  // Состояние isFormDirty поднято наверх из ConditionsContainer, тк нужно отслеживать переход между условиями
  // Выставлять isFormDirty вручную _не_ нужно
  const [isFormDirty, setIsFormDirty] = useState(false)

  const handleSelectCondition = useCallback((condition) => {
    if(isFormDirty) {
      confirm(() => dispatchCondition({ type: 'SELECT_CONDITION', condition }))
    } else {
      dispatchCondition({ type: 'SELECT_CONDITION', condition })
    }
  }, [isFormDirty])

  // Блокируем переход с формы через роутер, если есть несохраненные изменения
  const unblock = useRef(null)
  useEffect(() => {
    if (isFormDirty) {
      unblock.current = history.block((location) => {
        confirm(() => {
          unblock.current()
          unblock.current = null
          history.push(location)
        })

        return false
      })

    } else if(unblock?.current) {
      unblock.current()
      unblock.current = null
    }

    return () => {
      if (unblock.current) {
        unblock.current()
        unblock.current = null
      }
    }
  }, [history, isFormDirty])

  return <AccessContext.Provider value={{
    readonly: graphStatusId !== GRAPH_STATUSES.DRAFT.status_id || !canEditRoutes,
  }}>
    <AppNav title={() => <div className='d-flex align-items-center'>
      { routeState?.sort
        ? <div className='stage-title-badge mr-4'>{routeState?.sort}</div>
        : null
      }
      {routeState?.name || ''}
    </div>}
    breadcrumbs={[
      { label: 'Методология', link: URLS.METHODOLOGY },
      { label: 'Маршруты', link: URLS.METHODOLOGY__ROUTES },
      { label: graph?.graph_caption || '', link: `${URLS.METHODOLOGY__ROUTES}/${graph_id}/${version}/description` },
      { label: routeState?.name, style: { color: '#9FA5B9' } },
    ]}
    />
    <div className='root-meth-reg-conditions-page'>
      <Icon className='meth-reg-conditions-page-cancel' icon='cancel_16_m' onClick={() => history.push(`${URLS.METHODOLOGY__ROUTES}/${graph_id}/${version}/description`)} />
      <LoadedOrError loaded={loaded} loading={loading} error={error}>
        <ConditionsList
          conditionList={conditionList}
          selectedCondition={selectedCondition}
          onSelectCondition={handleSelectCondition}
          onAddCondition={handleAddCondition}
          onDeleteCondition={handleDeleteCondition}
          onCopyCondition={handleCopyCondition}
        />
        {
          selectedCondition && <ConditionsContainer
            key={`${selectedCondition.number_condition}-${selectedCondition.number_condition_id}`}
            routeState={routeState}
            selectedCondition={selectedCondition}
            graphExtInfo={graphExtInfo}
            onSelectedConditionUpdate={handleUpdateCondition}
            isFormDirty={isFormDirty}
            onFormDirtyChange={setIsFormDirty}
          />
        }
      </LoadedOrError>
    </div>
  </AccessContext.Provider>
}

// Подтверждение действия пользователя через модальное окно
const confirm = (cb) => {
  modalService.open(Alert, {
    onOk: cb,
    okText: 'Подтвердить',
    title: 'Завершить работу с формой?',
    children: 'Несохраненные данные будут утеряны',
  })
}

// Номер следующего регионального условия
// Максимальный порядковый номер условия + 1,
const getNextConditionNumber = conditionList => Math.max(0, ...conditionList.map(el => el.number_condition)) + 1

export const MethRegConditionsPageWithAudit = withMntAudit(MethRegConditionsPage)
