import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { PropTypes } from '@src/utils'
import { Button, Icon, Loader } from '@src/components'
import { Alert } from '@src/modals'
import { modalService } from '@src/services'
import { withAudit } from '@src/hoc'
import { AUDIT_PS } from '@src/constants'
import { loadAdminUserRoles, selectAdminDeleteUserRoleModel,
  selectAdminUserListModel, selectRolesByUserId, deleteUserRole } from '@src/modules/admin/store'
import config from '@src/config'
import { AssignRoleDrawerWithAudit } from '../AssignRoleDrawer/AssignRoleDrawerContainer'
import './AdminUsersRoles.scss'

class _AdminUsersRoles extends React.Component {
  static propTypes = {
    rolesByUserId: PropTypes.object,
    adminUserListModel: PropTypes.model,
    adminDeleteUserRoleModel: PropTypes.model,
    loadAdminUserRoles: PropTypes.func,
    deleteUserRole: PropTypes.func,
    user: PropTypes.object,
  }

  constructor (props) {
    super(props)

    this.state = { selectedRole: null }
  }

  loadRoles = () => this.props.user && this.props.loadAdminUserRoles({ args: { user_id: this.props.user.id } })

  componentDidUpdate (prevProps) {
    if(prevProps.user?.id !== this.props.user?.id && this.props.user)
      this.props.logToAudit({
        auditPs: AUDIT_PS.ADMIN,
        auditMessage: `Просмотр ролей пользователя ${this.props.user.login}`,
      })
  }

  handleOpenAssignRoleDrawer = (role) => {
    modalService.open(AssignRoleDrawerWithAudit, {
      user: this.props.user,
      accessProfile: role,
    })
  }

  get userRoles () {
    return this.props.user && this.props.rolesByUserId[this.props.user.id]?.payload?.data
  }

  handleRemoveRole = async () => {
    this.props.logToAudit({
      auditPs: AUDIT_PS.ADMIN,
      auditMessage: `Удаление роли ${this.state.selectedRole.role_name} пользователя ${this.props.user.login}`,
    })

    await this.props.deleteUserRole({ userId: this.props.user.id, roleId: this.state.selectedRole.id })

    if(this.props.adminDeleteUserRoleModel.error)
      return false

    this.loadRoles()
    this.setState({ selectedRole: null })
  }

  render () {
    return (
      <div className='root-admin-users-roles'>
        <div className='roles-button-container'>
          <Button
            icon='plus_cr_fr'
            disabled={!this.props.user}
            label='Добавить роль'
            onClick={() => this.handleOpenAssignRoleDrawer(null)}
            className='roles-button'
          />
          <Button
            label='Удалить роль'
            disabled={!this.state.selectedRole}
            onClick={() => modalService.open(Alert, {
              warn: true,
              onOk: this.handleRemoveRole,
              okText: 'Удалить',
              title: 'Удаление роли',
              children: `Вы уверены, что хотите удалить роль "${this.state.selectedRole.role_name}"?`,
            })}
            isSecondary
            icon='trash_can'
            className='actions-button'
          />
          <Button
            label='Редактировать роль'
            disabled={!this.state.selectedRole}
            onClick={() => this.handleOpenAssignRoleDrawer(this.state.selectedRole)}
            icon='file_copy'
          />
          <Button
            icon='settings'
            label='Настройки пользователей'
            onClick={() => window.open(config.api.auth.uri, '_blank')}
          />
        </div>
        <div className='admin-users-roles'>
          <div className='roles-table-container'>
            <table>
              <thead>
                <tr>
                  <th>Организация</th>
                  <th>Роль</th>
                  <th>Профиль ОМП</th>
                  <th>Главная роль</th>
                  <th>Должность</th>
                </tr>
              </thead>
              {
                this.props.adminUserListModel.loading
                  ? <tfoot>
                    <tr className='loader-container'>
                      <td colSpan='6'>
                        <Loader />
                      </td>
                    </tr>
                  </tfoot>
                  : <tbody>
                    {
                      this.props.user
                        ? this.userRoles
                          ? (
                            <>
                              {
                                this.userRoles.map((role, i) => (
                                  <tr
                                    key={i}
                                    onClick={() => this.setState({ selectedRole: role })}
                                    className={this.state.selectedRole?.role_id === role.role_id ? 'selected-role' : ''}
                                  >
                                    <td>{role.organisation_name || '-'}</td>
                                    <td>{role.role_name}</td>
                                    <td>{role.mcl_profile}</td>
                                    <td className='is-main'>
                                      {role.is_main && <Icon icon='check_cr_fr' />}
                                    </td>
                                    <td>{role.appointment}</td>
                                  </tr>
                                ))
                              }
                            </>
                          )
                          : (
                            <tr className='loader-container'>
                              <td colSpan='6'>
                                <Loader className='loader' />
                              </td>
                            </tr>
                          )
                        : null
                    }
                  </tbody>
              }
            </table>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  adminUserListModel: selectAdminUserListModel(state),
  rolesByUserId: selectRolesByUserId(state),
  adminDeleteUserRoleModel: selectAdminDeleteUserRoleModel(state),
})

const mapDispatchToProps = dispatch => ({
  loadAdminUserRoles: args => dispatch(loadAdminUserRoles(args)),
  deleteUserRole: args => dispatch(deleteUserRole(args)),
})

export const AdminUsersRolesWithAudit = withAudit(withRouter(connect(mapStateToProps, mapDispatchToProps)(_AdminUsersRoles)))
