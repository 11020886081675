import './TabStatusBase.scss'
import cn from 'classnames'

export const TabStatusBase = (props) => {
  return <div className={cn(
    'patient-status-tab-layout',
    props.className ?? props.className,
  )}
  style={props.style ?? props.style}>
    {props.children}
  </div>
}
