
import { useState } from 'react'
import { TextInput } from '@src/components'
import { Alert } from '../Alert/Alert'

export const AlertInput = ({ value: initialValue='', onOk, onClose, inputProps, ...props }) => {
  const [value, setValue] = useState(initialValue)
  const handleOk = () => {
    onOk && onOk(value)
    onClose()
  }

  return <Alert onOk={handleOk} onClose={onClose} {...props}><TextInput autofocus value={value} onChange={setValue} {...inputProps}/></Alert>
}
