import { selectDictImmunizationModel } from '@src/modules/dictionaries/store'
import { Combobox } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class StageImmunizationColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.STAGE_IMMUNIZATION

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Этап иммунизации',
        component: Combobox,
        props: {
          allowClear: true,
          mode: 'multiple',
          options: selectDictImmunizationModel(store.getState()).payload.map(imm => ({
            label: imm.name,
            value: imm.row_code,
          })),
        },
        defaultValue: [],
      },
      width: 500,
      dataIndex: 'stage_immunization',
      dataRender: stage_immunization => stage_immunization ? stage_immunization : '-',
      sort: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters }) {
    return {
      control: {
        filters: filters.stage_immunization && filters.stage_immunization.length
          ? [{ field: 'stage_immunization_code', value: filters.stage_immunization, operator: '=', invalid: false, cast: 'right-any' }]
          : [],
      },
    }
  }
}
