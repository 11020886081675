import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { InternalError404, LoadedOrError } from '@src/components'
import { AUDIT_PS, URLS } from '@src/constants'
import { useLoadModels } from '@src/hooks'
import { withAcl } from '@src/hoc'
import { AdminAppointmentsPageWithAudit } from '@src/modules/admin/pages/AdminAppointmentsPage/AdminAppointmentsPage'
import { loadAdminRolesList, selectAdminRolesListModel } from '@src/modules/admin/store'
import { loadMclList, selectMclListModel } from '@src/modules/dictionaries/store'
import { AdminExternalsPageWithAudit } from '../AdminExternalsPage/AdminExternalsPage'
import { AdminResourcesPageWithAudit } from '../AdminResourcesPage/AdminResourcesPage'
import { AdminRolesPageWithAudit } from '../AdminRolesPage/AdminRolesPage'
import { AdminUsersPageWithAudit } from '../AdminUsersPage/AdminUsersPage'
import './AdminPage.scss'


const AdminPage = () => {
  const [loaded, loading, error] = useLoadModels(
    [useSelector(selectMclListModel), loadMclList()],
    [useSelector(selectAdminRolesListModel), loadAdminRolesList()]
  )

  return <LoadedOrError loaded={loaded} loading={loading} error={error}>
    <div className='root-admin-page'>
      <Switch>
        <Route path={URLS.ADMIN__USERS}>
          <AdminUsersPageWithAudit
            auditPs={AUDIT_PS.ADMIN}
            auditMessage='Просмотр раздела Пользователи'
          />
        </Route>
        <Route path={URLS.ADMIN__ROLES}>
          <AdminRolesPageWithAudit
            auditPs={AUDIT_PS.ADMIN}
            auditMessage='Просмотр раздела Прикладные роли'
          />
        </Route>
        <Route path={URLS.ADMIN__EXTERNALS}>
          <AdminExternalsPageWithAudit
            auditPs={AUDIT_PS.ADMIN}
            auditMessage='Просмотр раздела Внешние системы'
          />
        </Route>
        <Route path={URLS.ADMIN__RESOURCES}>
          <AdminResourcesPageWithAudit
            auditPs={AUDIT_PS.ADMIN}
            auditMessage='Просмотр раздела Ресурсы'
          />
        </Route>
        <Route path={URLS.ADMIN__APPOINTMENTS}>
          <AdminAppointmentsPageWithAudit
            auditPs={AUDIT_PS.ADMIN}
            auditMessage='Просмотр раздела Должности'
          />
        </Route>
        <Route component={InternalError404}/>
      </Switch>
    </div>
  </LoadedOrError>
}

export const AdminPageWithAcl = withAcl(AdminPage)
