import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { Tag } from '@src/components'
import { REGISTER_COLUMNS, URLS } from '@src/constants'
import { usePageAppSettings } from '@src/hooks/useAppSettings'
import { Register, TabMedRecordsWithAudit } from '@src/modules/monitoring/components'
import { getFullname } from '@src/utils'
import { TabStatusWithAudit } from './TabStatus'


export const RegisterAkineoNns = () => {
  const basepath = URLS.MONITORING__AKINEO__NNS
  const navTitle = 'Реестр новорожденных (ННС)'

  const pageAppSettings = usePageAppSettings(`${basepath}/patient/:patientId/:occasionPatientId`)
  const isMotherNameHidden = pageAppSettings?.tabs?.status?.widgets?.mother?.disabled

  const columns = useMemo(() => {
    return [
      { type: REGISTER_COLUMNS.MED_BIRTH_CERTIFICATE, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.MED_BIRTH_CERTIFICATE_DATE, settings: { column: { enabled: true } } },
      {
        type: REGISTER_COLUMNS.BIRTH_DATE,
        settings: {
          column: {
            header: {
              title: 'Дата и время рождения новорожденного',
              defaultValue: [null, null],
              props: {
                showTime: true,
                disabledDate: currentDate => currentDate && currentDate.isAfter(moment()),
              },
            },
            dataRender: birth_date => birth_date ? `${moment.utc(birth_date).format('DD.MM.YYYY HH:mm:ss')} (${moment().diff(birth_date, 'years')})` : '-',
            enabled: true,
          },
        },
      },
      { type: REGISTER_COLUMNS.BIOPSY_SAMPLING_TIME, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.MO_CHILDBIRTH, settings: { column: { enabled: true } } },
      {
        type: REGISTER_COLUMNS.MO_NAME,
        settings: {
          column: {
            header: { title: 'Наименование МО забора крови' },
            enabled: true,
          },
        },
      },
      { type: REGISTER_COLUMNS.CHILD_FULLNAME, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.GENDER_ID, settings: { column: { enabled: true } } },
      {
        type: REGISTER_COLUMNS.BIRTHWEIGHT_COLUMN,
        settings: {
          column: {
            header: { title: 'Масса тела при рождении (г)' },
            enabled: true,
          },
        },
      },
      { type: REGISTER_COLUMNS.DUE_DATE, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.BIRTH_ORDER, settings: { column: { enabled: true } } },
      REGISTER_COLUMNS.DIAGNOSIS,
      {
        type: REGISTER_COLUMNS.FULLNAME,
        settings: {
          column: {
            header: { title: 'ФИО матери' },
            enabled: true,
          },
        },
      },
      REGISTER_COLUMNS.MOTHER_BIRTHDATE,
      {
        type: REGISTER_COLUMNS.SNILS,
        settings: {
          column: {
            header: { title: 'СНИЛС матери' },
          },
        },
      },
      REGISTER_COLUMNS.POLICY_ENP,
      REGISTER_COLUMNS.CHILDBIRTH_REGION,
      REGISTER_COLUMNS.MO_REGION,
      REGISTER_COLUMNS.DAYS_LIFE,
      REGISTER_COLUMNS.BLOOD_TRANSFUSIONS,
      REGISTER_COLUMNS.TRANSFUSIONS_BEGIN_ON,
      REGISTER_COLUMNS.STANDART_NEONATAL_SCREENING_RESULT,
      REGISTER_COLUMNS.STANDART_NEONATAL_SCREENING_RESULT_DATE,
      REGISTER_COLUMNS.ADVANCED_NEONATAL_SCREENING_LAB_MO,
      REGISTER_COLUMNS.ADVANCED_NEONATAL_SCREENING_RESULT,
      REGISTER_COLUMNS.ADVANCED_NEONATAL_SCREENING_RESULT_DATE,
      REGISTER_COLUMNS.DISEASES,
      REGISTER_COLUMNS.DISCHARGE,
      REGISTER_COLUMNS.DEATH_DATE,
      REGISTER_COLUMNS.TMS_LAB_MO,
      REGISTER_COLUMNS.TMS_RESULT_DATE,
      REGISTER_COLUMNS.TMS_RESULT,
      REGISTER_COLUMNS.MGI_LAB_MO,
      REGISTER_COLUMNS.MGI_RESULT_DATE,
      REGISTER_COLUMNS.MGI_RESULT,
      REGISTER_COLUMNS.PATIENT_ID,
    ]
  }, [])

  const tabs = useMemo(() => {
    return [
      TabStatusWithAudit,
      TabMedRecordsWithAudit,
    ]
  }, [])

  const patientCardTitleRenderer = useCallback(fullname => !isMotherNameHidden && fullname ? `${fullname} (мать)` : 'Новорожденный', [isMotherNameHidden])
  const patientCardBreadCrumbsTitleRender = useCallback((patientData) => {
    const fullname = getFullname(patientData)
    return !isMotherNameHidden && fullname
      ? <>{fullname} <Tag className='ml-4' color='light-lavender'>Мать</Tag></>
      : null
  }, [isMotherNameHidden])

  return <Register
    list_type='25'
    columns={columns}
    basepath={basepath}
    navTitle={navTitle}
    tabs={tabs}
    patientCardTitleRenderer={patientCardTitleRenderer}
    patientCardBreadCrumbsTitleRender={patientCardBreadCrumbsTitleRender}
  />
}
