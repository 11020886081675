import PropTypes from 'prop-types'
import cn from 'classnames'
import { DatePicker as AntdDatePicker } from 'antd'
import './DateRangePicker.scss'

export const DateRangePicker = ({ value, className, onChange, showTime, allDay, ...restProps }) => {
  const handleChange = (time) => {
    if(restProps?.showTime) return onChange(time)

    if(allDay)
      return onChange(time?.map((d, c) => c ? d.endOf('day') : d.startOf('day')))

    onChange(time)
  }

  return (
    <AntdDatePicker.RangePicker
      className={cn('root-date-range-picker', className)}
      showTime={showTime}
      allowClear={false}
      value={value}
      onChange={handleChange}
      format={showTime ? 'DD.MM.YYYY HH:mm:ss' : 'DD.MM.YYYY'}
      placeholder={showTime ? ['дд.мм.гггг чч:мм:сс', 'дд.мм.гггг чч:мм:сс'] : ['дд.мм.гггг', 'дд.мм.гггг']}
      {...restProps}
    />
  )
}

DateRangePicker.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  showTime: PropTypes.bool,
  allDay: PropTypes.bool,
}
