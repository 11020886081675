import { Base64 } from 'js-base64'
import { isArray, isEmpty } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { modalService } from '@src/services'
import { DEVIATION_KIND_CODES } from '@src/constants'
import { DeviationsDrawer } from './DeviationsDrawer/DeviationsDrawer'
import { ExtraRouteDrawer } from './ExtraRouteDrawer/ExtraRouteDrawer'
import { RegionalConditionsDrawer } from './RegionalConditionsDrawer/RegionalConditionsDrawer'
import './TabRoute.scss'

export const RouteView = ({ routeData }) => {
  const [listeners, setListeners] = useState([])

  const routeHtml = useMemo(() => {
    return routeData?.route ? Base64.decode(routeData.route) : null
  }, [routeData])

  useEffect(() => {

    if (routeHtml) {
      // Обработчки отклонений
      const deviation_list = routeData?.route_data?.deviation_list
      if(isArray(deviation_list)) {
        // Отклонения этапов
        const stageDeviations = deviation_list.reduce((acc, dev) => ({
          ...acc,
          [dev.stage_code]: acc[dev.stage_code] ? [...acc[dev.stage_code], dev] : [dev],
        }), {})

        // Обработчики счетчиков отклонений этапа для модалки отклонений
        Object.entries(stageDeviations).forEach(([stage_code, deviations]) => {
          const stageName = routeData.route_data.stage_list?.find(stage => stage.stage_code === stage_code)?.stage_name

          setupDeviationListener(`stage-pgg-deviation-count-${stage_code}`, stageName, deviations, DEVIATION_KIND_CODES.PGG)
          setupDeviationListener(`stage-pomp-deviation-count-${stage_code}`, stageName, deviations, DEVIATION_KIND_CODES.POMP)
          setupDeviationListener(`stage-cr-deviation-count-${stage_code}`, stageName, deviations, DEVIATION_KIND_CODES.KR)
        })

        // Отклонения подэтапов
        const stateDeviations = deviation_list.reduce((acc, dev) => ({
          ...acc,
          [dev.state_code]: acc[dev.state_code] ? [...acc[dev.state_code], dev] : [dev],
        }), {})

        Object.entries(stateDeviations).forEach(([state_code, deviations]) => {
          const pointName = routeData.route_data.point_list?.find(point => point.state_code === state_code)?.point_name

          // Обработчики счетчиков отклонений подэтапа для модалки отклонений маршрута типа bpmn
          setupDeviationListener(`point-pgg-deviation-count-${state_code}`, pointName, deviations, DEVIATION_KIND_CODES.PGG)
          setupDeviationListener(`point-pomp-deviation-count-${state_code}`, pointName, deviations, DEVIATION_KIND_CODES.POMP)
          setupDeviationListener(`point-cr-deviation-count-${state_code}`, pointName, deviations, DEVIATION_KIND_CODES.KR)

          // Обработчики счетчиков отклонений подэтапа для модалки отклонений маршрута типа brief
          const pointElements = document.querySelectorAll(`[data-deviation-code="deviation-code-${state_code}"]`)
          if(pointElements.length) {
            pointElements.forEach((el) => {
              const deviation_kind = el.attributes['data-deviation-kind'].value
              const cb = () => handleDeviationCounterClick(deviations, pointName, deviation_kind)
              el.addEventListener('click', cb)
              setListeners(prevState => [...prevState, { el, cb }])
            })
          }
        })
      }

      // Обработчики дополнительных маршрутов
      const route_list = routeData?.route_data?.route_list
      if(isArray(route_list)) {
        route_list.forEach((extra_route) => {
          const [el] = document.querySelectorAll(`[data-route-num="${extra_route.route_num}"]`)
          if(el) {
            const cb = () => handleExtraRouteClick(extra_route.occasion_patient_id, extra_route.nosology_code)
            el.addEventListener('click', cb)
            setListeners(prevState => [...prevState, { el, cb }])
          }
        })
      }

      // Обработчики региональных дополнений
      const point_list = routeData?.route_data?.point_list
      if(isArray(point_list)) {
        point_list.forEach((point) => {
          if (!isEmpty(point.regional_conditions)) {
            const el = document.getElementById(`regional-point-code-${point.state_code}`)

            if(el) {
              const cb = () => handleRegionalConditionsClick(point, point.regional_conditions)
              el.addEventListener('click', cb)
              setListeners(prevState => [...prevState, { el, cb }])
            }
          }
        })
      }
    }

    return () => {
      removeAllListeners()
      setListeners([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeHtml])

  const setupDeviationListener = (selector, name, deviations, deviationKindCode) => {
    const el = document.getElementById(selector)
    if(el) {
      const cb = () => handleDeviationCounterClick(deviations, name, String(deviationKindCode))
      el.addEventListener('click', cb)
      setListeners(prevState => [...prevState, { el: el, cb }])
    }
  }

  const handleDeviationCounterClick = (deviations, title, deviation_kind) => {
    modalService.open(DeviationsDrawer, { deviations, title, defaultTab: deviation_kind })
  }

  const handleExtraRouteClick = (extraRouteOccasionPatientId, extraRouteNosologyCode) => {
    modalService.open(ExtraRouteDrawer, { extraRouteOccasionPatientId, extraRouteNosologyCode })
  }

  const handleRegionalConditionsClick = (point, regionalConditions) => {
    modalService.open(RegionalConditionsDrawer, { point, regionalConditions })
  }

  const removeAllListeners = () => {
    listeners.forEach(({ el, cb }) => el.removeEventListener('click', cb))
  }

  return !routeHtml
    ? (
      <div className='not-selected'>
        <p>Для отображения маршрута</p>
        <p>нажмите кнопку "Получить маршрут из ВИМИС"</p>
      </div>
    ) : (
      <div className='wrapper-route'>
        <div dangerouslySetInnerHTML={{ __html: routeHtml }} />
      </div>
    )
}
