import { Combobox } from '@src/components'
import { REGISTER_COLUMNS, SORT_ORDER } from '@src/constants'
import { selectDictTypesOfNotification } from '@src/modules/dictionaries/store'
import { RegisterColumn } from './RegisterColumn'

export class DocumentationTypeColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.DOCUMENTATION_TYPE

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Тип извещения',
        component: Combobox,
        props: {
          options: selectDictTypesOfNotification(store.getState()).payload?.map(mo => ({
            label: mo.name,
            value: mo.name,
            title: mo.name,
          })),
          allowClear: true,
        },
      },
      width: 200,
      dataIndex: 'type_documentation',
      dataRender: type_documentation => type_documentation ? type_documentation : '-',
      sort: true,
      defaultSort: SORT_ORDER.ASC,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let requestFilters = []

    if (filters[this.type]) {
      requestFilters = [{ field: 'type_documentation', value: filters?.type_documentation, operator: 'like_both', invalid: false }]
    }

    if (nullSearches[this.type]) {
      requestFilters = [{ field: this.type, value: null, operator: '=', invalid: false }]
    }

    return {
      control: {
        filters: requestFilters,
      },
    }
  }
}
