const j = (...urls) => urls.join('')

/** MONITORING */
export const MONITORING = '/monitoring'

export const MONITORING__ONKO = j(MONITORING, '/onko')
export const MONITORING__ONKO__ZNO = j(MONITORING__ONKO, '/zno')
export const MONITORING__ONKO__PREDZNO = j(MONITORING__ONKO, '/predzno')

export const MONITORING__SSZ = j(MONITORING, '/ssz')
export const MONITORING__SSZ__OKS = j(MONITORING__SSZ, '/oks')
export const MONITORING__SSZ__ONMK = j(MONITORING__SSZ, '/onmk')

export const MONITORING__AKINEO = j(MONITORING, '/akineo')
export const MONITORING__AKINEO__NEWBORN = j(MONITORING__AKINEO, '/newborn')
export const MONITORING__AKINEO__PREGNANT = j(MONITORING__AKINEO, '/pregnant')
export const MONITORING__AKINEO__NNS = j(MONITORING__AKINEO, '/nns')
export const MONITORING__AKINEO__VNZ_MOTHER = j(MONITORING__AKINEO, '/vnz_mother')
export const MONITORING__AKINEO__VNZ_CHILD = j(MONITORING__AKINEO, '/vnz_child')

export const MONITORING__PROF = j(MONITORING, '/prof')
export const MONITORING__PROF__DN = j(MONITORING__PROF, '/dn')
export const MONITORING__PROF__IMM = j(MONITORING__PROF, '/imm')
export const MONITORING__PROF__PMO_D = j(MONITORING__PROF, '/pmo_d')
export const MONITORING__PROF__PMO_TEEN = j(MONITORING__PROF, '/pmo_teen')

export const MONITORING__INFECTION = j(MONITORING, '/infection')
export const MONITORING__INFECTION__IB = j(MONITORING__INFECTION, '/ib')
export const MONITORING__INFECTION__EMERGENCY_NOTICE = j(MONITORING__INFECTION, '/emergency_notice')


/** ADMIN */
export const ADMIN = '/admin'

export const ADMIN__USERS = j(ADMIN, '/users')

export const ADMIN__ROLES = j(ADMIN, '/roles')
export const ADMIN__ROLES__ROLE = j(ADMIN__ROLES, '/role')

export const ADMIN__EXTERNALS = j(ADMIN, '/externals')
export const ADMIN__RESOURCES = j(ADMIN, '/resources')
export const ADMIN__APPOINTMENTS = j(ADMIN, '/appointments')

/** NSI */
export const NSI = '/nsi'
export const NSI__FRNSI = j(NSI, '/frnsi')
export const NSI__FRNSI__DICT = j(NSI__FRNSI, '/dict')

/** METHODOLOGY */
export const METHODOLOGY = '/methodology'
export const METHODOLOGY__NPA = j(METHODOLOGY, '/npa')
export const METHODOLOGY__NPA__DATA = j(METHODOLOGY__NPA, '/data')

export const METHODOLOGY__CLINREC = j(METHODOLOGY, '/clinrec')
export const METHODOLOGY__CLINREC__DATA = j(METHODOLOGY__CLINREC, '/data')

export const METHODOLOGY__ROUTES = j(METHODOLOGY, '/routes')

/** ANALYTICS */
export const ANALYTICS = '/analytics'
export const ANALYTICS__REPORTS = j(ANALYTICS, '/reports')

/** JOURNAL */
export const JOURNAL = '/journal'
export const JOURNAL__SEMD  = j(JOURNAL, '/semd')
export const JOURNAL__AUDIT  = j(JOURNAL, '/audit')

/** ABOUT */
export const ABOUT = '/about'
