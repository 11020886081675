import { useCallback, useContext } from 'react'
import { Button, Card, IfNotReadonly } from '@src/components'
import { modalService } from '@src/services'
import { AccessContext } from '@src/context'
import { AUDIT_PS } from '@src/constants'
import { AttributesDrawerWithAudit } from '../AttributesDrawer/AttributesDrawer'

export const AdditionalCondition = ({ condition, onConditionChange, onClearAddCondition, canClearAddCondition }) => {
  const accessContext = useContext(AccessContext)

  const handleOpenAttributesDrawer = useCallback(() => {
    modalService.open(AttributesDrawerWithAudit, {
      auditPs: AUDIT_PS.METHOD,
      auditMessage: 'Просмотр формы дополнительных параметров',
      readonly: accessContext.readonly,
      condition,
      onAccept: onConditionChange,
    })
  }, [condition, accessContext.readonly, onConditionChange])

  return <Card
    className='mb-16'
    title='Дополнительные параметры'
    headerFuncPointer={() => <div className='d-flex justify-content-end'>
      <IfNotReadonly deniedComponent={
        <Button
          icon='eye'
          label='Просмотр'
          disbled={!condition}
          onClick={handleOpenAttributesDrawer}
        />
      }>
        <>
          <Button
            className='mr-8'
            icon={condition ? 'edit_green' : 'plus_cr_fr'}
            label={condition ? 'Редактировать' : 'Создать'}
            onClick={handleOpenAttributesDrawer}
          />
          <Button
            icon='eraser'
            label='Очистить'
            disabled={!canClearAddCondition}
            onClick={onClearAddCondition}
          />
        </>
      </IfNotReadonly>
    </div>}
    data={[
      {
        title: 'Наименование условия',
        data: condition?.condition_name ?? '—',
      },
      {
        title: 'Текстовое описание обоснования условия',
        data: condition?.condition_comment ?? '—',
      },
    ]}
  />
}
