import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Card, DocStatusTag } from '@src/components'
// import { Card, DocStatusTag, Tag } from '@src/components'                  //VIMISVIMIS-21048 VIMISVIMIS-21051
import { selectDocumentModel } from '@src/modules/methodology/store'
import { withMntAudit } from '@src/hoc'
import './TabInformation.scss'

function TabInformation () {
  const clinrecInfo = useSelector(selectDocumentModel)
  const cardData = useMemo(() => {
    const data = clinrecInfo.payload

    return !data
      ? null
      : [
        {
          title: 'Статус документа',
          data: () => <DocStatusTag>{data.document_status ?? '-'}</DocStatusTag>,
        },
        {
          title: 'Форма документа',
          data: 'Клинические рекомендации',
        },
        {
          title: 'Профиль ОМП',
          data: data?.profile_caption ?? '-',
        },
        // {
        //   title: 'Номер',
        //   data: data?.number ? <Tag color='light-beige'>{data?.number}</Tag> : '-',
        // },                                                                           //VIMISVIMIS-21048 VIMISVIMIS-21051
        {
          title: 'Полное наименование',
          data: data.name_full ?? '-',
        },
        {
          title: 'Краткое наименование',
          data: data?.name_short ?? '-',
        },
        {
          title: 'Кодирование по МКБ-10',
          data: <span className='clinrec-information-tab-tag'>{data?.mkb.join(', ') ?? '-'}</span>,
        },
      ]
  }, [clinrecInfo])

  return (
    <div className='root-clinrec-information-tab'>
      <Card title='О документе' loading={clinrecInfo.loading} data={cardData} />
    </div>
  )
}

export const TabInformationWithAudit = withMntAudit(TabInformation)

TabInformationWithAudit.tabTitle = 'Информация'
TabInformationWithAudit.tabPath = 'information'
