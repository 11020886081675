import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { URLS } from '@src/constants'
import { withMntAudit } from '@src/hoc'
import { InternalError404 } from '@src/components'
import { NpaTable } from '../../components/NpaTable/NpaTable'
import { NpaDataPage } from '../../pages/NpaDataPage/NpaDataPage'
import './NpaPage.scss'

const NpaPage = () => {
  return (
    <div className='root-npa-page'>
      <Switch>
        <Route path={`${URLS.METHODOLOGY__NPA__DATA}/:id`}>
          <NpaDataPage />
        </Route>
        <Route path={URLS.METHODOLOGY__NPA}>
          <NpaTable />
        </Route>
        <Route component={InternalError404}/>
      </Switch>
    </div>
  )
}

export const NpaPageWithAudit = withMntAudit(NpaPage)
