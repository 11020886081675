import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { ACLS, URLS } from '@src/constants'
import { AccessContext } from '@src/context'
import { useUserHasResource } from '@src/hooks'
import { withMntAudit } from '@src/hoc'
import { InternalError404 } from '@src/components'
import MethRoutesTable from '../../components/MethRoutesTable/MethRoutesTable'
import MethRouteCard from '../../components/MethRouteCard/MethRouteCard'
import './MethRoutesPage.scss'

const MethRoutesPage = () => {
  const readonly = !useUserHasResource(ACLS.METHOD_GRAPH_EDIT)

  return (
    <div className='root-npa-page'>
      <AccessContext.Provider value={{ readonly }}>
        <Switch>
          <Route path={`${URLS.METHODOLOGY__ROUTES}/:graph_id/:version`}>
            <MethRouteCard />
          </Route>
          <Route path={URLS.METHODOLOGY__ROUTES}>
            <MethRoutesTable />
          </Route>
          <Route component={InternalError404}/>
        </Switch>
      </AccessContext.Provider>
    </div>
  )
}

export const MethRoutesPageWithAudit = withMntAudit(MethRoutesPage)
