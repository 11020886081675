import { cloneDeep, isEqual } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { Button, IfNotReadonly, LoadedOrError } from '@src/components'
import { ButtonWithAudit } from '@src/components/Button/Button'
import { AUDIT_PS, NOTIFICATION_TYPES } from '@src/constants'
import { useMountEffect } from '@src/hooks'
import { getConditionForm, postConditionForm, resetMethRegConditionsFormState, selectGetConditionFormModel, selectPostConditionFormModel } from '@src/modules/methodology/store'
import { pushNotification } from '@src/store'
import { Conditions } from './Conditions'
import './Conditions.scss'

// Компонент отвечает за инициализацию значений формы, загрузку условия с бэка и сохранение условия на бэк
export const ConditionsContainer = ({ routeState, selectedCondition, graphExtInfo, onSelectedConditionUpdate, isFormDirty, onFormDirtyChange }) => {
  const { route_state_id, version } = useParams()

  const dispatch = useDispatch()
  const conditionFormModel = useSelector(selectGetConditionFormModel)

  const foundationOptions = useMemo(() => {
    return graphExtInfo.graph_ext_list_document_npa.map(npa => ({
      value: npa?.document_id,
      label: `№${npa?.document_number} ${npa?.document_name}`,
    }))
  }, [graphExtInfo.graph_ext_list_document_npa])

  // У формы есть три состояния: пустое, изначальное и текущее
  const cleanState = useMemo(() => ({
    documentId: foundationOptions?.[0]?.value,
    addCondition: null,
    timeCondition: null,
    moTerritoryList: [{
      uuid: uuidv4(),
      mo_list: [],
      territory_list: [],
    }],
  }), [foundationOptions])


  const [initialState, setInitialState] = useState(cleanState)
  const [currentState, setCurrentState] = useState(cleanState)

  useMountEffect(() => {
    // Если у условия есть number_condition_id, значит, условие есть на бэке, загружаем данные для него
    if (selectedCondition.number_condition_id) {
      dispatch(getConditionForm({
        route_state_id,
        graph_ext_id: version,
        number_condition_id: selectedCondition.number_condition_id,
      }))
    }

    return () => dispatch(resetMethRegConditionsFormState())
  }, [])

  // Устанавливаем изначальное состояние объектов, содержащих изменения формы
  const handleResetForm = useCallback(() => {
    setCurrentState(initialState)
  }, [initialState])

  // Устанавливаем значения формы из запроса сразу после загрузки данных с бэка
  useEffect(() => {
    if (conditionFormModel.loaded && conditionFormModel.payload.number_condition_id === selectedCondition.number_condition_id) {
      const state = {
        documentId: conditionFormModel.payload.document_id,
        addCondition: cloneDeep(conditionFormModel.payload.add_condition),
        moTerritoryList: cloneDeep(conditionFormModel.payload.mo_territory_list),
        timeCondition: cloneDeep(conditionFormModel.payload.time_condition),
      }
      setInitialState(state)
      setCurrentState(state)
    }
  }, [conditionFormModel.loaded, conditionFormModel.payload, selectedCondition.number_condition_id])

  const handleAddTerritoryBlock = () => {
    setCurrentState(prev => ({
      ...prev,
      moTerritoryList: [
        ...prev.moTerritoryList,
        {
          uuid: uuidv4(),
          mo_list: [],
          territory_list: [],
        },
      ],
    }))
  }

  const postConditionFormModel = useSelector(selectPostConditionFormModel)
  const handleSave = async () => {
    const result = await dispatch(postConditionForm({
      route_state_id,
      state_code: routeState.state_code,
      graph_ext_id: version,
      number_condition: selectedCondition.number_condition,
      number_condition_uuid: selectedCondition.number_condition_uuid,
      number_condition_id: selectedCondition.number_condition_id,
      document_id: currentState.documentId,
      mo_territory_list: currentState.moTerritoryList,
      add_condition: currentState.addCondition,
      time_condition: currentState.timeCondition,
    }))

    // Наполняем форму актуальными значениями с бэка, чтобы не перезагружать
    if (result?.payload?.number_condition_uuid) {
      const state = {
        documentId: result.payload.document_id,
        addCondition: cloneDeep(result.payload.add_condition),
        moTerritoryList: cloneDeep(result.payload.mo_territory_list),
        timeCondition: cloneDeep(result.payload.time_condition),
      }

      setInitialState(state)
      setCurrentState(state)
      // Дополняем selectedCondition новыми полями, чтобы не перезагружать форму
      onSelectedConditionUpdate({
        ...selectedCondition,
        number_condition: Number(result.payload.number_condition),
        number_condition_id: Number(result.payload.number_condition_id),
        number_condition_uuid: result.payload.number_condition_uuid,
      })

      dispatch(pushNotification({ type: NOTIFICATION_TYPES.SUCCESS, message: 'Условие сохранено', autoclose: true }))
    }
  }

  // Следим за изменениями в форме, выставляем признак того, что были внесены изменения
  useEffect(() => {
    onFormDirtyChange(!isEqual(initialState, currentState))
  }, [currentState, initialState, onFormDirtyChange])

  return <div className='root-reg-conditions'>
    <LoadedOrError
      loaded={true}
      loading={
        // Форма может в состоянии loading, только если определен number_condition_id
        selectedCondition.number_condition_id && (
          // и если текущее состояние формы соответствует пустому состоянию формы
          isEqual(cleanState, currentState) ||
          // или условие непосредственно загружается
          conditionFormModel.loading
          // Все это нужно, чтобы избежать дерганий и перерисовок интерфейса при сохранении нового условия
        )
      }
      error={conditionFormModel.error}
    >
      <Conditions
        condition={currentState}
        foundationOptions={foundationOptions}
        onConditionUpdate={condition => setCurrentState(prev => ({ ...prev, ...condition }))}
        onAddTerritoryBlock={handleAddTerritoryBlock}

        onClearAddCondition={() => setCurrentState(prev => ({ ...prev, addCondition: null }))}
        canClearAddCondition={currentState.addCondition !== null}

        onClearTimeCondition={() => setCurrentState(prev => ({ ...prev, timeCondition: null }))}
        canClearTimeCondition={currentState.timeCondition !== null}
      />
      <IfNotReadonly>
        <Footer
          onSave={handleSave}
          onCancel={handleResetForm}
          cancelDisabled={!isFormDirty}
          saveDisabled={!isFormDirty}
          saveLoading={postConditionFormModel.loading}
        />
      </IfNotReadonly>
    </LoadedOrError>
  </div>
}

const Footer = ({ onCancel, onSave, cancelDisabled, saveDisabled, saveLoading }) => {

  return <div className='bottom-buttons mt-auto'>
    <div className='d-flex justify-content-end'>
      <Button
        className='mr-8'
        label='Отменить изменения'
        onClick={onCancel}
        disabled={cancelDisabled || saveLoading}
      />
      <ButtonWithAudit
        icon='check_cr_fr_white'
        label='Сохранить'
        isSuccess
        loading={saveLoading}
        disabled={saveDisabled}
        onClick={onSave}
        auditPs={AUDIT_PS.METHOD}
        auditMessage='Сохранение регионального условия'
      />
    </div>
  </div>
}
