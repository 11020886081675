import { thunkCreator, thunkCreatorInfinityScroll } from '@src/utils'
import * as models from '../models'

export const [
  loadNpaList, loadNpaListExtra,
] = thunkCreatorInfinityScroll(models.NPA_LIST, '/method/get_npa_list')

export const [
  loadNpaListCount, loadNpaListCountExtra,
] = thunkCreator(models.NPA_LIST_COUNT, '/method/get_npa_list')

export const [
  loadStaffPlacesList, loadStaffPlacesListExtra,
] = thunkCreator(models.STAFF_PLACES_LIST, '/method/get_ps_place_list', { extractor: d => d.data[0] })

export const [
  loadPlaceRequirements, loadPlaceRequirementsExtra,
] = thunkCreator(models.STAFF_REQUIREMENTS, '/method/get_ps_req_list')

export const [
  loadEquipmentPlacesList, loadEquipmentPlacesListExtra,
] = thunkCreator(models.EQUIPMENT_PLACES_LIST, '/method/get_eq_place_list', { extractor: d => d.data[0] })

export const [
  loadEquipmentRequirements, loadEquipmentRequirementsExtra,
] = thunkCreator(models.EQUIPMENT_REQUIREMENTS, '/method/get_eq_req_list')

export const [
  loadGraphList, loadGraphListExtra,
] = thunkCreator(models.GRAPH_LIST, '/method/get_list_graphs_from_revision', { extractor: d => d })

const extraReducers = {
  ...loadNpaListExtra,
  ...loadNpaListCountExtra,
  ...loadGraphListExtra,
  ...loadStaffPlacesListExtra,
  ...loadPlaceRequirementsExtra,
  ...loadEquipmentPlacesListExtra,
  ...loadEquipmentRequirementsExtra,
}

export default extraReducers