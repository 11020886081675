import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs } from 'antd'
import { AUDIT_PS, URLS } from '@src/constants'
import { loadDocument, loadRevisionsList, resetClinrecCardState, selectDocumentModel, loadRevisionData } from '@src/modules/methodology/store'
import { AppNav, LoadedOrError } from '@src/components'
// import { AppNav, Tag } from '@src/components'
// import { TAG_COLORS } from '@src/constants'
// import { TabText } from '@src/modules/methodology/components/TabText/TabText'    //VIMISVIMIS-21048 VIMISVIMIS-21051
import { useMountEffect } from '@src/hooks'
import { TabInformationWithAudit } from '../../components/ClinRecTabs/TabInformation/TabInformation'
import { TabThesesWithAudit } from '../../components/ClinRecTabs/TabTheses/TabTheses'
import './ClinRecDataPage.scss'

const TABS = [
  TabInformationWithAudit,
  // TabText,                                                                       //VIMISVIMIS-21048 VIMISVIMIS-21051
  TabThesesWithAudit,
]

export const ClinRecDataPage = () => {
  const [selectedRevisionId, setSelectedRevisionId] = useState(null)
  const match = useRouteMatch()

  const clinrecInfoModel = useSelector(selectDocumentModel)
  const clinrecId = match.params.id

  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  useMountEffect(() => {
    dispatch(loadRevisionsList({ args: { document_id: clinrecId } }))
    dispatch(loadDocument({ args: { docId: clinrecId } }))

    return () => dispatch(resetClinrecCardState())
  })

  useEffect(() => {
    if(clinrecInfoModel.payload?.revision_id) {
      const urlRevisionId = new URLSearchParams(location.search).get('revisionId')
      const docRevisionId = clinrecInfoModel.payload?.revision_id

      const initialRevision = urlRevisionId || docRevisionId
      if (initialRevision) setSelectedRevisionId(initialRevision)
    }
  }, [clinrecInfoModel.payload?.revision_id, location.search])

  const titleString = useMemo(() => {
    if(clinrecInfoModel.payload)
      return clinrecInfoModel.payload?.name_short
    return ''
  }, [clinrecInfoModel.payload])

  const titleJsxString = () => {
    if(clinrecInfoModel.payload)
      return <span className='clinrec-data-title'>
        {clinrecInfoModel.payload?.name_short}
        {/* <Tag color={TAG_COLORS.BEIGE}>{clinrecInfoModel.payload?.number}</Tag>                   //VIMISVIMIS-21048 VIMISVIMIS-21051 */}
      </span>
    return null
  }

  useEffect(() => {
    if(selectedRevisionId) {
      dispatch(loadRevisionData({ revision_id: selectedRevisionId }))
      history.replace({
        pathname: location.pathname,
        search: new URLSearchParams({ revisionId: selectedRevisionId }).toString(),
      })
    }
  }, [dispatch, history, location.pathname, selectedRevisionId])

  const tabActiveKey = useMemo(() => {
    const Tab = TABS.find(Tab => location.pathname.endsWith(Tab.tabPath))
    return Tab?.tabPath || TABS[0].tabPath
  }, [location.pathname])

  return (
    <div className='root-clinrec-data-page'>
      <AppNav title={titleJsxString}
        breadcrumbs={[
          { label: 'Методология', link: URLS.METHODOLOGY },
          { label: 'Клинические рекомендации', link: URLS.METHODOLOGY__CLINREC__DATA },
          {
            label: titleString?.length > 30 ? `${titleString.slice(0, 30)}...` : titleString,
          },
        ]}
        tabs={TABS.map(tab => ({
          label: tab.tabTitle,
          link: `${URLS.METHODOLOGY__CLINREC__DATA}/${match.params.id}/${tab.tabPath}${location.search}`,
        }))}
      />

      <LoadedOrError loaded={clinrecInfoModel.loaded} loading={clinrecInfoModel.loading} error={clinrecInfoModel.error}>
        <Tabs
          activeKey={tabActiveKey}
          renderTabBar={() => null}
          items={TABS.map(TabWithAudit => ({
            key: TabWithAudit.tabPath,
            children: <TabWithAudit selectedRevisionId={selectedRevisionId} onRevisionChange={setSelectedRevisionId}
              auditPs={AUDIT_PS.METHOD}
              auditMessage={`Просмотр вкладки '${TabWithAudit.tabTitle}' карточки КР ${titleString ?? '-'}`}
            />,
          }))}
        />
      </LoadedOrError>
    </div>
  )
}
