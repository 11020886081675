export const ADMIN_USER_LIST = 'adminUserList'
export const ADMIN_ROLES_BY_ID = 'adminRoles'
export const ADMIN_ROLES_LIST = 'adminRolesList'
export const ADMIN_UPSERT_USER_ROLE = 'adminUpsertUserRole'
export const ADMIN_DELETE_USER_ROLE = 'adminDeleteUserRole'
export const ADMIN_EXTERNAL_SYSTEMS_LIST = 'adminExternalSystemsList'
export const ADMIN_INSERT_EXTERNAL_SYSTEM = 'adminInsertExternalSystem'
export const ADMIN_RESOURCES_BY_ROLE_ID = 'adminResourcesByRoleId'
export const ADMIN_ROLE_RESOURCES_LIST = 'adminRoleResourcesList'
export const ADMIN_ACL_BY_ROLE_BY_RESOURCE = 'adminAclByRoleByResource'
export const ADMIN_SET_ROLE = 'adminSetRole'
export const ADMIN_REMOVE_ROLE = 'adminRemoveRole'
export const ADMIN_COPY_ROLE = 'adminCopyRole'
export const ADMIN_ROLES_BY_USER_ID = 'adminRolesByUserId'
export const ADMIN_ORGS_BY_MCL_ID_BY_TERRITORY_LEVEL = 'adminOrgsByMclIdByTerritoryLevel'
export const ADMIN_APPOINTMENTS_ROLE_ID = 'adminAppointmentsRoleId'
export const ADMIN_APPOINTMENTS_POSITIONS = 'adminAppointmentsPositions'
export const ADMIN_APPOINTMENTS_POSITIONS_LIST = 'adminAppointmentsPositionsList'
export const ADMIN_APPOINTMENTS_INSERT_ROLE = 'adminAppointmentsInsertRole'
export const ADMIN_APPOINTMENTS_DELETE_ROLE = 'adminAppointmentsDeleteRole'
export const ADMIN_APPOINTMENTS_DELETE = 'adminAppointmentsDelete'
export const ADMIN_APPOINTMENTS_INSERT = 'adminAppointmentsInsert'
export const ADMIN_APPOINTMENTS_UPDATE = 'adminAppointmentsUpdate'
export const ADMIN_USERS_LIST = 'adminUsersList'
export const ADMIN_USER_DELETE = 'adminUserDlete'
export const ADMIN_SUSPEND_ALL_EXTERNAL_SYSTEMS = 'adminSuspendAllExternalSystems'
export const ADMIN_RESUME_ALL_EXTERNAL_SYSTEMS = 'adminResumeAllExternalSystems'
export const ADMIN_EXT_SYSTEMS_HISTORY = 'ADMIN_EXT_SYSTEMS_HISTORY'
export const ADMIN_SET_MAIN_ROLE = 'ADMIN_SET_MAIN_ROLE'
