import { maxBy } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Checkbox, Combobox, Icon, If, Loader } from '@src/components'
import { ButtonWithAudit } from '@src/components/Button/Button'
import config from '@src/config'
import { AUDIT_PS } from '@src/constants'
import { useLoadRegionalRoute, useLoadRoutes } from './hooks'
import { RouteView } from './RouteView'
import './TabRoute.scss'

export const TabRouteBase = ({ paramsLoadRoutes, paramsLoadRoutesError }) => {
  const [selectedRouteIndex, setSelectedRoute] = useState(null)
  const [routes, loadRoutes, loading, options, timestamp] = useLoadRoutes()
  const [displayRegional, setDisplayRegional] = useState(false)

  const handleLoadRoutes = useCallback(() => {
    loadRoutes(paramsLoadRoutes)
  }, [loadRoutes, paramsLoadRoutes])

  useEffect(() => {
    if(routes) {
      const lastRoute = maxBy(routes, route => new Date(route.occasion_begin_on))
      const index = routes.findIndex(route => route === lastRoute)
      setSelectedRoute(index === -1 ? null : index)
    }
  }, [options, routes])

  const selectedRoute = useMemo(() => {
    return routes?.[selectedRouteIndex]
  }, [routes, selectedRouteIndex])

  const [regionalRoute, regionalLoading] = useLoadRegionalRoute(selectedRoute, paramsLoadRoutes)

  // Отображаем маршрут с рег. дополнениями если установлен чекбокс
  // Иначе, если чекбокс не установлен, отображаем федеральный маршрут
  const routeToDisplay = (displayRegional && regionalRoute) || (!displayRegional && selectedRoute)

  const regionalErrorLoading = useMemo(() => {
    // Рег. маршрут уже не грузится, но regionalRoute не установлен
    return selectedRoute && !regionalLoading && ! regionalRoute
  }, [regionalLoading, regionalRoute, selectedRoute])

  const regionalSameAsFed = useMemo(() => {
    // Рег маршрут загружен, и html рег маршрута совпадает с html фед маршрута
    return regionalRoute && selectedRoute && regionalRoute.route === selectedRoute.route
  }, [regionalRoute, selectedRoute])

  return (
    <div className='root-tab-route'>
      <div className='route-tab-wraper'>
        <div className='wrapper-top'>
          <div className='wrapper-top-left'>
            <div className='wrapper-top-section'>
              <span className='wrapper-top-left-title'>Маршрут</span>
              <Combobox
                className='route-tab-select'
                placeholder='Выберите'
                onChange={setSelectedRoute}
                value={selectedRouteIndex}
                options={options}
                disabled={!options?.length}
              />
            </div>
            <div className='wrapper-top-section ml-24'>
              <span className='wrapper-top-left-title' >Последнее обновление</span>
              <span className='mt-auto'>{timestamp || '—'}</span>
            </div>

            {/* Скрываем раздел, если он отключен в конфиге */}
            <If condition={config?.app?.modules?.enabled?.regional_routes}>
              <div className='wrapper-top-section ml-24 mr-8'>
                <div className='d-flex'>
                  <span className='wrapper-top-left-title'>Региональные дополнения
                    {
                      regionalErrorLoading && <Icon
                        className='w-12 h-12 minh-12 minw-12 ml-2'
                        icon='notification_error'
                        title='Не удалось загрузить региональные условия для федерального маршрута'
                      />
                    }
                    {
                      regionalSameAsFed && <Icon
                        className='w-12 h-12 minh-12 minw-12 ml-2'
                        icon='notification_warning'
                        title='Региональные условия для данного пациента не найдены'
                      />
                    }
                  </span>
                </div>
                {
                  displayRegional && regionalLoading
                    ? <Loader small/>
                    : <Checkbox
                      className='mt-auto'
                      Label='Отобразить'
                      value={displayRegional}
                      onChange={setDisplayRegional}
                      disabled={!selectedRoute}
                    />
                }
              </div>
            </If>
          </div>
          <div className='wrapper-top-button-wrapper'>
            <ButtonWithAudit
              icon='update_outline_16'
              label='Получить маршрут из ВИМИС'
              onClick={handleLoadRoutes}
              loading={loading}
              disabled={!paramsLoadRoutes}
              title={!paramsLoadRoutes ? paramsLoadRoutesError : null}
              auditPs={AUDIT_PS.PATIENTMON}
              auditMessage='Получение маршрута из ВИМИС'
            />
          </div>
        </div>
        <RouteView
          routeData={routeToDisplay}
        />
      </div>
    </div>
  )
}
