import { TextInput } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class PatientIdColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.PATIENT_ID

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Идентификатор пациента',
        component: TextInput,
        defaultValue: '',
        props: {
          allowClear: true,
          icon: 'search',
          allowedChars: /[0-9]/,
        },
      },
      width: 150,
      dataIndex: 'patient_id',
      dataRender: patient_id => patient_id ? patient_id : '-',
      sort: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    return {
      control: {
        filters: nullSearches[this.type]
          ? [{ field: this.type, operator: '=', value: null, invalid: false }]
          : filters?.patient_id
            ? [{ field: 'patient_id', value: filters?.patient_id, operator: 'like_both', invalid: false }]
            : [],
      },
    }
  }
}
