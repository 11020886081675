import { Switch as AntdSwitch } from 'antd'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { withClickAudit } from '@src/hoc'
import './Switch.scss'

export const Switch = ({ checked, onChange, disabled, loading, className,
  // Избегаем передачи logToAudit в AntdSwitch, т.к. будет warning распаковки этого prop в атрибуты DOM элемента
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  logToAudit,
  ...restProps }) => {
  return (
    <AntdSwitch
      className={cn('root-switch-default', className)}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      loading={loading}
      {...restProps}
    />
  )
}

Switch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
}

export const SwitchWithAudit = withClickAudit(Switch)
