import moment from 'moment'
import { DateRangePicker } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'
import { requestArgsDateRange } from './columnsReqArgs'

export class OccBeginOnColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.OCC_BEGIN_ON

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Дата начала ПМО или диспансеризации',
        component: DateRangePicker,
        defaultValue: [null, null],
        props: {
          allowEmpty: [true, true],
          allDay: true,
          disabledDate: currentDate => currentDate && currentDate.isAfter(moment()),
        },
      },
      width: 250,
      dataIndex: 'occ_begin_on',
      dataRender: occ_begin_on => occ_begin_on ? moment(occ_begin_on).format('DD.MM.YYYY') : '-',
      sort: true,
    }

    super(column, settings)
  }

  _requestArgs = reqArgs => requestArgsDateRange(this.type, reqArgs)
}
