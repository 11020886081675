import { createSelector } from 'reselect'
import * as models from './models'

export const selectAdmin = state => state.admin

export const selectAdminUserListModel = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_USER_LIST],
)

export const selectAdminRolesById = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_ROLES_BY_ID],
)

export const selectAdminRolesListModel = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_ROLES_LIST],
)

export const selectAdminUpsertUserRoleModel = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_UPSERT_USER_ROLE],
)

export const selectAdminSetMainRoleModel = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_SET_MAIN_ROLE],
)

export const selectAdminDeleteUserRoleModel = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_DELETE_USER_ROLE],
)

export const selectAdminExternalSystemsListModel = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_EXTERNAL_SYSTEMS_LIST],
)

export const selectAdminInsertExternalSystemModel = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_INSERT_EXTERNAL_SYSTEM],
)

export const selectAdminRoleResourcesListModel = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_ROLE_RESOURCES_LIST],
)

export const selectAdminSetRoleModel = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_SET_ROLE],
)

export const selectAdminRemoveRoleModel = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_REMOVE_ROLE],
)

export const selectAdminCopyRoleModel = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_COPY_ROLE],
)

export const selectAdminResourcesByRoleId = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_RESOURCES_BY_ROLE_ID],
)

export const selectAdminAclByRoleByResource = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_ACL_BY_ROLE_BY_RESOURCE],
)

export const selectRolesByUserId = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_ROLES_BY_USER_ID],
)

export const selectOrgsByMclIdByTerritoryLevel = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_ORGS_BY_MCL_ID_BY_TERRITORY_LEVEL],
)

export const selectAppointmentsByRoleId = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_APPOINTMENTS_ROLE_ID],
)

export const selectAppointmentsPosition = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_APPOINTMENTS_POSITIONS],
)

export const selectAppointmentsPositionList = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_APPOINTMENTS_POSITIONS_LIST],
)

export const selectAppointmentsInsertRole = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_APPOINTMENTS_INSERT_ROLE],
)

export const selectAppointmentsDeleteRole = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_APPOINTMENTS_DELETE_ROLE],
)

export const selectAppointmentsDelete = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_APPOINTMENTS_DELETE],
)

export const selectAppointmentsInsert = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_APPOINTMENTS_INSERT],
)

export const selectAppointmentsUpdate = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_APPOINTMENTS_UPDATE],
)

export const selectAdminUsersListModel = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_USERS_LIST],
)

export const selectAdminSuspendAllExternalSystems = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_SUSPEND_ALL_EXTERNAL_SYSTEMS],
)

export const selectAdminResumeAllExternalSystems = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_RESUME_ALL_EXTERNAL_SYSTEMS],
)

export const selectAdminExtSystemsHistory = createSelector(
  selectAdmin,
  admin => admin[models.ADMIN_EXT_SYSTEMS_HISTORY],
)

const addNode = (tree, curPath, restPath, data) => {
  if (!restPath.length)
    Object.assign(tree, data)
  else {
    const [next, ...rest] = restPath
    const nextPath = curPath ? [curPath, next].join('.') : next

    const existing = tree.children && tree.children.find(c => c.path === nextPath)

    const st = existing || { path: nextPath }
    if (!existing) { tree.children = [...(tree.children || []), st] }
    addNode(st, nextPath, rest, data)
  }
}
const createResourcesTree = (rows) => {
  const tree = {
    path: '',
  }

  for (let i=0; i < rows.length; i++) {
    addNode(tree, null, rows[i].path.split('.'), rows[i])
  }

  return tree
}

export const selectAdminRoleResourcesTree = createSelector(
  selectAdminRoleResourcesListModel,
  list => list.payload
    ? createResourcesTree(list.payload)
    : {},
)
