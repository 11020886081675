interface IfProps {
  condition: boolean,
  children: React.ReactNode,
}

/**
 * Компонент проверяет, если condition истинно, то рендерит свое содержимое
 * Иначе - скрывает
 * @param {boolean}           props.condition
 * @param {React.ReactNode}   props.children
 */
export const If = ({ condition, children }: IfProps) => condition ? children : null
