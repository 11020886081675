import { isEmpty } from 'lodash'
import { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react'
import { FixedSizeList, VariableSizeList } from 'react-window'

import { DataGridRow } from './DataGridRow'
import { useRowKey } from './hooks'

export const DataGridBody = forwardRef(({ value, columns, data, height, rowKey, rowHeight, selectable, onSelectionChange,
  onScrolledToBottom, onChange, variableSizeList, templateColumnsWidth, ...props }, ref) => {
  const handleListScroll = useCallback(({ scrollDirection }) => {
    if (!ref?.current || scrollDirection === 'backward') return
    const {
      scrollHeight,
      scrollTop,
      clientHeight,
    } = ref.current

    if (scrollTop === 0) return

    if (Math.abs(scrollHeight - (scrollTop + clientHeight)) < 1) {
      onScrolledToBottom(value, data.length)
    }
  }, [value, data, ref, onScrolledToBottom])

  const [getKeyForRowIndex] = useRowKey(data, rowKey)

  const VirtualRow = useMemo(() => {
    return rowProps => <DataGridRow
      value={value}
      selectable={selectable}
      columns={columns}
      rowKey={rowKey}
      onSelectionChange={onSelectionChange}
      onChange={onChange}
      templateColumnsWidth={templateColumnsWidth}
      {...props}
      {...rowProps}
    />
  }, [value, selectable, columns, rowKey, onSelectionChange, onChange, templateColumnsWidth, props])

  const List = variableSizeList ? VariableSizeList : FixedSizeList

  const listRef = useRef(null)

  useEffect(() => {
    if (variableSizeList && !isEmpty(data) && listRef?.current) {
      listRef.current.resetAfterIndex(0, true)
    }
  }, [data, variableSizeList])

  return <List
    ref={listRef}
    className='grid-table-body scroll-y'
    style={{ width: templateColumnsWidth.styleWidth }}
    itemCount={data.length}
    itemSize={rowHeight}
    itemKey={getKeyForRowIndex}
    itemData={data}
    height={height}
    width='100%'
    outerRef={ref}
    onScroll={handleListScroll}
    children={VirtualRow}
    {...(variableSizeList || {})}
  />
})
