import { Form, Input, InputNumber } from 'antd'
import { useCallback } from 'react'

import { get } from 'lodash'
import moment from 'moment'
import { Button, DatePicker, Drawer, Icon, IfNotReadonly } from '@src/components'
import { AccessContext } from '@src/context'
import './ValueListDrawer.scss'

export const ValueListDrawer = ({ value, onAccept, value_type_code, readonly, onClose, ...props }) => {
  const [form] = Form.useForm()

  const addRow = (add) => {
    add({
      value: null,
      value_id: null,
      type_code: value_type_code,
    })
  }

  const handleSave = useCallback(() => {
    const formValues = form.getFieldsValue().list
    onAccept(formValues.filter(e => e.value))
    onClose()
  }, [form, onAccept, onClose])

  return <AccessContext.Provider value={{ readonly }}>
    <Form
      form={form}
      name='ValueListDrawer'
      layout='vertical'
      onFinish={handleSave}
      disabled={readonly}
    >
      <Drawer
        className='root-list-drawer'
        title='Редактировать список'
        placement='right'
        height='99%'
        onClose={onClose}
        footer={
          <IfNotReadonly>
            <div className='h-44'>
              <div className='h-100p d-flex flex-direction-row align-items-center'>
                <Button
                  className='ml-auto'//ml-8'
                  isSuccess
                  icon='check_cr_fr_white'
                  label='Применить'
                  disabled={readonly}
                  onClick={() => form.submit()}
                />
              </div>
            </div>
          </IfNotReadonly>
        }
        {...props}
      >
        <Form.List name='list' initialValue={value?.map(row => ({
          value: row.value ?? row,
          value_id: row.value_id ?? null,
          type_code: row.type_code ?? value_type_code,
        })) ?? []}>
          {
            (fields, { add, remove }) => {
              return (
                <>
                  {
                    fields.map((field, index) => <Row
                      key={field.key}
                      index={index}
                      remove={remove}
                      value_type_code={value_type_code}
                    />)
                  }
                  <IfNotReadonly>
                    <Button
                      label='Добавить новое значение'
                      onClick={() => addRow(add)}
                      disabled={readonly}
                    />
                  </IfNotReadonly>
                </>
              )
            }
          }
        </Form.List>
      </Drawer>
    </Form>
  </AccessContext.Provider>
}

const Row = ({ index, remove, value_type_code }) => {
  return <div className='h-30 d-flex flex-direction-row justify-content-between align-items-center mb-16'>
    <RowValue value_type_code={value_type_code} index={index} />

    <IfNotReadonly>
      <div onClick={() => remove(index)} className='row-delete-icon w-32 h-32 d-flex justify-content-center align-items-center'>
        <Icon className='w-16 h-16' icon='cancel_s_outline_16' />
      </div>
    </IfNotReadonly>
  </div>
}

const RowValue = ({ value_type_code, index }) => {

  switch (value_type_code) {
  case 'int': return <RowValueInt index={index} />
  case 'float': return <RowValueFloat index={index} />
  case 'string': return <RowValueString index={index} />
  case 'datetime': return <RowValueDate index={index} />
  default: return <RowValueFallback value_type_code={value_type_code} />
  }
}

const RowValueInt = ({ index }) => {

  return <Form.Item
    noStyle
    name={[index, 'value']}
  >
    <InputNumberInteger />
  </Form.Item>
}

const InputNumberInteger = ({ value, onChange, ...props }) => {

  return <InputNumber
    className='w-100p'
    min={0}
    step={1}
    value={value}
    onChange={evt => onChange(Number(String(evt).replace(/\./g, '')))}
    {...props}
  />
}

const RowValueFloat = ({ index }) => {

  return <Form.Item
    noStyle
    name={[index, 'value']}
  >
    <InputNumber
      className='w-100p'
      min={0}
      step={0.1}
    />
  </Form.Item>

}

const RowValueString = ({ index }) => {

  return <Form.Item
    noStyle
    name={[index, 'value']}
  >
    <Input
      className='w-100p'
    />
  </Form.Item>

}

const RowValueDate = ({ index }) => {
  const path = ['list', index, 'value']

  return <Form.Item
    noStyle
    shouldUpdate={(prev, cur) => get(prev, path) !== get(cur, path)}
  >
    {
      (form) => {
        const value = form.getFieldValue(path)

        return <DatePicker
          className='w-100p'
          value={value && moment(value)}
          onChange={v => form.setFieldValue(path, v && v.format('YYYY-MM-DD'))}
        />
      }
    }
  </Form.Item>
}

const RowValueFallback = (value_type_code) => {

  return <Input readOnly status='warning' value={`Неподдерживаемый тип значения '${value_type_code}'`} />
}
