import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { remotesManager, withAuthorization } from '@src/remotes'
import { pushNotification, selectAccessToken } from '@src/store'
import { selectOccasionInfoModel } from '@src/modules/monitoring/store'
import config from '@src/config'

export const useLoadRoutes = () => {
  const accessToken = useSelector(selectAccessToken)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [timestamp, setTimestamp] = useState(null)
  const [routes, setRoutes] = useState(null)
  const [options, setOptions] = useState(null)

  const loadRoutes = useCallback(async (params) => {
    setLoading(true)
    setOptions(null)
    setTimestamp(null)
    setRoutes(null)

    try {
      const { data } = await remotesManager.BACKEND_API.get('/adapters/patient_route_int', withAuthorization(accessToken, { params }))
      if (!data.is_success) throw new Error(data)
      const routes = data?.data?.routes

      setRoutes(routes)
      setTimestamp(moment().format('DD.MM.YYYY HH:mm'))
      setOptions(routes.map((route, index) => ({
        label: `Маршрут ${route.graph_name}, ${route.doc_number}`,
        value: index,
      })))
    } catch (e) {
      const message = e.response?.data?.error || e.response?.data?.errors?.[0] || e.message
      dispatch(pushNotification({ type: 'error', message }))
    } finally {
      setLoading(false)
    }

  }, [dispatch, accessToken])

  return [routes, loadRoutes, loading, options, timestamp]
}

export const useLoadRegionalRoute = (fedRoute, paramsLoadRoutes) => {

  const accessToken = useSelector(selectAccessToken)
  const dispatch = useDispatch()

  const occasionInfoModel = useSelector(selectOccasionInfoModel)

  const [loading, setLoading] = useState(false)
  const [routeCacheMap, setRouteCacheMap] = useState({})

  const occMappingParams = useMemo(() => {
    return {
      // для маршрутов новорожденных СНИЛС содержится в mother_snils
      snils: paramsLoadRoutes?.snils || paramsLoadRoutes?.mother_snils,
      vmcl: paramsLoadRoutes?.vmcl,
      mkb_code: occasionInfoModel?.payload?.data?.[0]?.mkb_code,
    }
  }, [occasionInfoModel?.payload?.data, paramsLoadRoutes?.mother_snils, paramsLoadRoutes?.snils, paramsLoadRoutes?.vmcl])

  const load = useCallback(async (fedRoute) => {
    setLoading(true)

    try {
      let result = null

      // вызываем маппинг для фед. маршрута с пациентами ЦСП
      result = await remotesManager.BACKEND_API.post('/processing-patient-route/route_occ_mapping', [fedRoute], withAuthorization(accessToken, {
        params: occMappingParams,
      }))
      if (!result?.data?.is_success) throw new Error(result?.data)

      // Вызываем обогащение фед. маршрута региональными условиями
      result = await remotesManager.BACKEND_API.post('/processing-patient-route/apply_regional_settings', result?.data?.data, withAuthorization(accessToken, {
        params: { snils: occMappingParams.snils },
      }))
      if (!result?.data?.is_success) throw new Error(result?.data)

      // Обогащаем html маршрута рег. условиями
      result = await remotesManager.BACKEND_API.post('/patient-monitoring/get_regional_html', result?.data?.data, withAuthorization(accessToken))
      if (!result?.data?.is_success) throw new Error(result?.data)

      const route = result?.data?.data?.[0]
      return route
    } catch (e) {
      const message = e.response?.data?.error || e.response?.data?.errors?.[0] || e.message
      dispatch(pushNotification({ type: 'error', message }))
    } finally {
      setLoading(false)
    }

  }, [accessToken, occMappingParams, dispatch])

  useEffect(() => {
    // Не грузим решиональные условия, если они отключены через конфиг
    if (!config?.app?.modules?.enabled?.regional_routes) return

    if (fedRoute && !routeCacheMap[fedRoute?.graph_code]) load(fedRoute).then(route => route && setRouteCacheMap(prev => ({
      ...prev,
      [route.graph_code]: route,
    })))
  }, [fedRoute, load, routeCacheMap])

  const currentRoute = useMemo(() => {
    return fedRoute && routeCacheMap[fedRoute?.graph_code]
  }, [fedRoute, routeCacheMap])

  return [currentRoute, loading]
}
