import { useState } from 'react'
import { isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import { Modal, Button, Combobox } from '@src/components'
import { selectDictMoModel } from '@src/modules/dictionaries/store'
import './MoModal.scss'

export const MoModal = (props) => {
  const [selectedMo, setSelectedMo] = useState(props.mo_list)
  const MoModel = useSelector(selectDictMoModel)

  const handleOk = () => {
    props.onOk && props.onOk(selectedMo?.map(({ MO_OID }) => MO_OID))
    props.onClose()
  }

  return (
    <Modal
      title='Выбор медицинской организации (МО)'
      className='root-mo-modal'
      footer={<AlertFooter
        onOk={handleOk}
        onCancel={props.onClose}
        disabled={isEqual(selectedMo, props.mo_list)}
      />}
      {...props}
    >
      <div className='mo-modal-body-block'>
        <p className='mo-modal-body-block-title'>Наименование МО</p>
        <Combobox
          options={MoModel.payload?.data?.map(i => ({ ...i, label: i.name_short, value: i.mo_id, title: i.name_full, MO_OID: i.mo_id, MO_NAME: i.name_full }))}
          showSearch
          showArrow
          placeholder='Выберите'
          onChange={(e, e_obj) => setSelectedMo(e_obj)}
          mode='multiple'
          maxTagCount={0}
          value={selectedMo}
          maxTagPlaceholder={omittedValues => `Выбрано: ${omittedValues.length}`}
          placement={'topLeft'}
        />
      </div>
    </Modal>
  )
}

const AlertFooter = ({ onOk, onCancel, disabled }) => {
  return (
    <div className='mo-modal-footer'>
      <Button onClick={onCancel} className='mg-r-8' label='Отмена' />
      <Button disabled={disabled} onClick={onOk} isSuccess label='Применить' icon='check_cr_fr_white' />
    </div>
  )
}
