import config from '@src/config'
import { ACLS, URLS } from '@src/constants'

// Если понадобится контролировать меню в зависимости от appSettings - есть такая возможность
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const menuFactory = appSettings => [
  {
    label: 'Медицинские данные',
    to: URLS.MONITORING,
    enabled: true,
    acl: ACLS.DESKTOP_MAINMENU_PATIENTMON_SHOW,
    icon: 'medical_chart',
    children: [
      {
        label: 'Реестр пациентов с ЗНО',
        to: URLS.MONITORING__ONKO__ZNO,
        enabled: config.app?.modules?.enabled?.onko,
        acl: ACLS.DESKTOP_MAINMENU_PATIENTMON_PATIENTS_LIST_ONKO_SHOW,
      },
      {
        label: 'Реестр пациентов с предопухолевыми заболеваниями',
        to: URLS.MONITORING__ONKO__PREDZNO,
        enabled: config.app?.modules?.enabled?.onko,
        acl: ACLS.DESKTOP_MAINMENU_PATIENTMON_PATIENTS_LIST_ONKO_SHOW,
      },
      {
        label: 'Реестр пациентов с ОКС',
        to: URLS.MONITORING__SSZ__OKS,
        enabled: config?.app?.modules?.enabled?.ssz,
        acl: ACLS.DESKTOP_MAINMENU_PATIENTMON_OKS_LIST_SHOW,
      },
      {
        label: 'Реестр пациентов с ОНМК',
        to: URLS.MONITORING__SSZ__ONMK,
        enabled: config?.app?.modules?.enabled?.ssz,
        acl: ACLS.DESKTOP_MAINMENU_PATIENTMON_ONMK_LIST_SHOW,
      },
      {
        label: 'Реестр новорожденных',
        to: URLS.MONITORING__AKINEO__NEWBORN,
        enabled: config?.app?.modules?.enabled?.akineo,
        acl: ACLS.DESKTOP_MAINMENU_PATIENTMON_NEWBORN_LIST_SHOW,
      },
      {
        label: 'Реестр новорожденных (ННС)',
        to: URLS.MONITORING__AKINEO__NNS,
        enabled: config?.app?.modules?.enabled?.akineo,
        acl: ACLS.PATIENTMON_MENU_AKINEO_NNS_LIST_SHOW,
      },
      {
        label: 'Реестр беременных',
        to: URLS.MONITORING__AKINEO__PREGNANT,
        enabled: config?.app?.modules?.enabled?.akineo,
        acl: ACLS.DESKTOP_MAINMENU_PATIENTMON_PREGNANTS_LIST_SHOW,
      },
      {
        label: 'Реестр вакцинированных',
        to: URLS.MONITORING__PROF__IMM,
        enabled: config?.app?.modules?.enabled?.prof,
        acl: ACLS.PATIENTMON_MENU_PROF_IMM_LIST_SHOW,
      },
      {
        label: 'Реестр больных инфекционными заболеваниями',
        to: URLS.MONITORING__INFECTION__IB,
        enabled: config?.app?.modules?.enabled?.infection,
        acl: ACLS.PATIENTMON_MENU_INF_REGISTRY_IB_SHOW,
      },
      {
        label: 'Реестр пациентов, подлежащих ДН',
        to: URLS.MONITORING__PROF__DN,
        enabled: config?.app?.modules?.enabled?.prof,
        acl: ACLS.PATIENTMON_MENU_PROF_DN_LIST_SHOW,
      },
      {
        label: 'Реестр взрослого населения, проходящих ПМО и диспансеризацию',
        to: URLS.MONITORING__PROF__PMO_D,
        enabled: config?.app?.modules?.enabled?.prof,
        acl: ACLS.PATIENTMON_MENU_PROF_PMO_D_SHOW,
      },
      {
        label: 'Реестр несовершеннолетних, проходящих ПМО',
        to: URLS.MONITORING__PROF__PMO_TEEN,
        enabled: config?.app?.modules?.enabled?.prof,
        acl: ACLS.PATIENTMON_MENU_PROF_PMO_TEENAGER_SHOW,
      },
      {
        label: 'Реестр экстренных извещений',
        to: URLS.MONITORING__INFECTION__EMERGENCY_NOTICE,
        enabled: config?.app?.modules?.enabled?.infection,
        acl: ACLS.PATIENTMON_MENU_INFECTION__EMERGENCY_NOTICE_SHOW,
      },
    ],
  },
  {
    label: 'НСИ',
    to: URLS.NSI,
    enabled: true,
    acl: ACLS.DESKTOP_MAINMENU_PSI_SHOW,
    icon: 'medical_case',
    children: [
      {
        label: 'Справочники ФРНСИ',
        to: URLS.NSI__FRNSI,
        enabled: true,
        acl: ACLS.DESKTOP_MAINMENU_PSI_DICT_SEARCH_SHOW,
      },
    ],
  },
  {
    label: 'Методология',
    to: URLS.METHODOLOGY,
    enabled: config?.app?.modules?.enabled?.methodology,
    acl: ACLS.METHOD_ACCESS,
    icon: 'folder_list',
    children: [
      {
        label: 'Порядки ОМП',
        to: URLS.METHODOLOGY__NPA,
        enabled: true,
        acl: ACLS.METHOD_DOCUMENTS_NPA_READ,
      },
      {
        label: 'Клинические рекомендации',
        to: URLS.METHODOLOGY__CLINREC,
        enabled: true,
        acl: ACLS.METHOD_DOCUMENTS_CLINREC_READ,
      },
      {
        label: 'Маршруты',
        to: URLS.METHODOLOGY__ROUTES,
        enabled: config?.app?.modules?.enabled?.regional_routes,
        acl: ACLS.METHOD_GRAPH_READ,
      },
    ],
  },
  {
    label: 'Аналитика',
    to: URLS.ANALYTICS,
    enabled: true,
    acl: ACLS.DESKTOP_MAINMENU_ANALYZE_SHOW,
    icon: 'line_02_up',
    children: [
      {
        label: 'Отчеты',
        to: URLS.ANALYTICS__REPORTS,
        enabled: true,
        acl: ACLS.DESKTOP_MAINMENU_ANALYZE_MONITORING_SHOW,
      },
    ],
  },
  {
    label: 'Администрирование',
    to: URLS.ADMIN,
    enabled: true,
    acl: ACLS.DESKTOP_MAINMENU_ADM_SHOW,
    icon: 'lock',
    children: [
      {
        label: 'Журнал СЭМД',
        to: URLS.JOURNAL__SEMD,
        enabled: true,
        acl: ACLS.DESKTOP_MAINMENU_JOURNAL_SEARCH_JOURNAL_PG_SHOW,
      },
      {
        label: 'Журнал действий пользователей',
        to: URLS.JOURNAL__AUDIT,
        enabled: true,
        acl: ACLS.DESKTOP_MAINMENU_JOURNAL_ACTIONS_JOURNAL_SHOW,
      },
      {
        label: 'Пользователи',
        to: URLS.ADMIN__USERS,
        enabled: true,
        acl: ACLS.DESKTOP_MAINMENU_ADM_USERS_SHOW,
      },
      {
        label: 'Прикладные роли',
        to: URLS.ADMIN__ROLES,
        enabled: true,
        acl: ACLS.DESKTOP_MAINMENU_ADM_ROLES_SHOW,
      },
      {
        label: 'Внешние Системы',
        to: URLS.ADMIN__EXTERNALS,
        enabled: true,
        acl: ACLS.DESKTOP_MAINMENU_ADM_EXTERNAL_SYSTEMS_SHOW,
      },
      {
        label: 'Ресурсы',
        to: URLS.ADMIN__RESOURCES,
        enabled: config?.app?.modules?.enabled?.admin_resources,
        acl: ACLS.DESKTOP_MAINMENU_ADM_RESOURCES_SHOW,
      },
      {
        label: 'Должности',
        to: URLS.ADMIN__APPOINTMENTS,
        enabled: config?.app?.modules?.enabled?.admin_appointments,
        acl: ACLS.DESKTOP_MAINMENU_ADM_APPOINTMENTS_SHOW,
      },
    ],
  },
  {
    label: config.integrations.akineo_mis.menu_name,
    to: config.integrations.akineo_mis.uri,
    enabled: config?.integrations?.akineo_mis?.enabled,
    acl: ACLS.DESKTOP_MAINMENU_RISAR_SHOW,
    icon: 'logo_risar',
    children: [],
  },
]
