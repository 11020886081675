import moment from 'moment'
import { isArray } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from '@src/components'
import { loadPersonInfo, selectPersonInfoModel } from '@src/modules/monitoring/store'
import { formatSnils } from '@src/utils'
import { useMountEffect } from '@src/hooks'
import { withMntAudit } from '@src/hoc'

import './TabPersonal.scss'

const TabPersonal = ({ patientId }) => {
  const dispatch = useDispatch()
  const personInfoModel = useSelector(selectPersonInfoModel)

  const personal = personInfoModel.payload?.data?.[0]

  useMountEffect(() => {
    if(!personInfoModel.loaded && !personInfoModel.loading) dispatch(loadPersonInfo({ args: { patient_id: patientId } }))
  })

  return <div className='root-tab-personal'>
    <Card
      title='Общие сведения'
      loading={personInfoModel.loading}
      error={personInfoModel.error}
      className='mb-8'
      data={personal && [
        {
          title: 'Фамилия Имя Отчество',
          data: `${personal.lastname} ${personal.firstname} ${personal.patronymic}`,
        },
        {
          title: 'Пол',
          data: personal.gender || '-',
        },
        {
          title: 'Дата рождения',
          data: personal.birth_date
            ? moment(personal.birth_date).format('DD.MM.YYYY')
            : '—',
        },
        {
          title: 'СНИЛС',
          data: personal.snils
            ? formatSnils(personal.snils)
            : '-',
        },
        {
          title: 'Место рождения',
          data: personal.birth_place || '-',
        },
      ]}
    />
    <Card
      title='Документы'
      loading={personInfoModel.loading}
      error={personInfoModel.error}
      className='mb-8'
      data={isArray(personal?.document_list) && personal.document_list.map(document => ([
        {
          title: 'Тип документа',
          data: document.persdoc_type || '-',
        },
        {
          title: 'Номер',
          data: document.persdoc_number || '-',
        },
        {
          title: 'Выдан',
          data: document.issued_by || '-',
        },
      ])).flat()}
    />
    <Card
      title='Адреса'
      loading={personInfoModel.loading}
      error={personInfoModel.error}
      className='mb-8'
      data={isArray(personal?.address_list) && personal.address_list.map(address => ([
        {
          title: 'Тип адреса',
          data: address.address_type || '-',
        },
        {
          title: 'Дата регистрации',
          data: address.date_begin
            ? moment(address.date_begin).format('DD.MM.YYYY')
            : '-',
        },
        {
          title: 'Адрес',
          data: address.text,
        },
      ])).flat()}
    />
    <Card
      title='Контакты'
      loading={personInfoModel.loading}
      error={personInfoModel.error}
      data={isArray(personal?.contact_list) && personal.contact_list.map(contact => ([
        {
          title: 'Тип контакта',
          data: contact.contact_type || '-',
        },
        {
          title: 'Контакт',
          data: contact.contact || '-',
        },
      ])).flat()}
    />
  </div>
}

export const TabPersonalWithAudit = withMntAudit(TabPersonal)

TabPersonalWithAudit.tabTitle = 'Персональные данные'
TabPersonalWithAudit.tabPath = 'personal'
