import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { cloneDeep, isEmpty  } from 'lodash'
import { Button, Combobox, IfNotReadonly } from '@src/components'
import { URLS } from '@src/constants'
import { modalService } from '@src/services'
import { SaveDocBeforeExitModal } from '@src/modules/methodology/components/SaveDocBeforeExitModal/SaveDocBeforeExitModal'

export function VersionBlock ({
  id,
  revisionId,
  dataGI,
  currentStatus,
  currentVersion,
  isNewVersion,
  isDirtyDocument,

  versionGraph,

  handleSetIsDirtyDocument,
  handleSetCurrentStatus,
  handleSetLoadedDataGI,
  handleSetIsNewVersion,
  handleSetCurrentVersion,
  handleSaveDocument,
  handleSetDataRegionStartedOn,
  handleSetDataRegionExpiratedOn,
}) {
  const history = useHistory()
  const firstOptions = useMemo(() => [], [])
  const cleanRegionObject = useMemo(() => ({
    'graph_ext_id': null,
    'graph_ext_status_id': null,
    'graph_ext_created_by': null,
    'graph_ext_uuid': null,
    'graph_ext_started_on': null,
    'graph_ext_expirated_on': null,
    'graph_ext_name_version': null,
    'graph_ext_created_by_fio': null,
    'graph_ext_status_caption': null,
    'graph_ext_list_document_npa': [],
  }), [])

  // Список версий для селектора
  let options = cloneDeep((versionGraph?.payload?.data ?? []).map(item => ({
    label: `${item.full_text} ${item.region_status_caption}`,
    value: String(item.region_graph_rev_id),
    ...item,
  })))

  // Сортировка версий
  if(!isEmpty(options)) {
    options.sort((a, b) => {
      if (+a.value > +b.value) return 1
      if (+a.value === +b.value) return 0
      if (+a.value < +b.value) return -1
      return 0
    })
  }

  // Установка версии, если нет версий в маршруте
  useEffect(() => {
    if((!revisionId || revisionId === 'null') && !isEmpty(dataGI)) {
      firstOptions.push({
        label: 'Версия №1',
        value: 1,
      })
      handleSetCurrentVersion('Версия №1')
      dataGI.graph_ext_info = cloneDeep(cleanRegionObject)
      dataGI.graph_ext_info.region_graph_rev_id = 1
      dataGI.graph_ext_info.graph_ext_status_caption = 'Черновик'
      dataGI.graph_ext_info.graph_ext_status_id = 1
      handleSetCurrentStatus({ label: 'Черновик', value: 1, key: 1 })
      handleSetLoadedDataGI(dataGI)
      //handleSetIsDirtyDocument(true)
      handleSetIsNewVersion(true)
    }
  }, [
    dataGI,
    revisionId,
    firstOptions,
    handleSetCurrentVersion,
    handleSetIsDirtyDocument,
    handleSetIsNewVersion,
    handleSetLoadedDataGI,
    handleSetCurrentStatus,
    cleanRegionObject,
  ])

  // Сортировка версий
  if(!isEmpty(options)) {
    options.sort((a, b) => {
      if (+a.value > +b.value) return 1
      if (+a.value === +b.value) return 0
      if (+a.value < +b.value) return -1
      return 0
    })
  }

  // Установка версии
  function handleVersionGraph (value, e_obj) {
    setVersionGraph(e_obj)
  }

  // Добавление новой версии
  function handleAddVersion () {
    if(isDirtyDocument) {
      openSaveDataModal('addVersion')
      return
    }
    addVersion()
  }

  // Модалка сохранения документа
  function openSaveDataModal (type, valueInput) {
    modalService.open(SaveDocBeforeExitModal, {
      typeChange: type,
      valueInput,
      cb: resultSaveModal,
    })
  }

  function resultSaveModal (val, type, valueInput) {
    if(!val) {
      if(type === 'addVersion') {
        addVersion()
      }
    }
    if(!val) {
      return
    }
    if(type === 'changeVersion') {
      handleSetIsNewVersion(false)
      handleSetIsDirtyDocument(false)
      // dataGI.graph_ext_info = cloneDeep(cleanRegionObject)
      dataGI.graph_ext_info.graph_ext_id = null
      dataGI.graph_ext_info.graph_ext_uuid = null
      dataGI.graph_ext_info.graph_ext_status_caption = currentStatus.label
      dataGI.graph_ext_info.graph_ext_status_id = currentStatus.value
      handleSetLoadedDataGI(dataGI)
      setVersionGraph(valueInput)
      handleSetCurrentStatus(null)
      // handleSaveDocument()
    }
    if(type === 'addVersion') {
      // addVersion()
    }
    if(type === 'exit') {
      handleSaveDocument()
    }
    if(type === 'clean') {
      history.push(`${URLS.METHODOLOGY__ROUTES}`)
    }
  }

  // Добавление новой версии
  function addVersion () {
    const lastVersion = +getLastVersion() + 1
    options.push({
      label: `Версия №${lastVersion}`,
      value: lastVersion,
    })
    handleSetCurrentStatus({ label: 'Черновик', value: 1, key: 1 })
    handleSetCurrentVersion(`Версия №${lastVersion}`)
    dataGI.graph_ext_info = cloneDeep(cleanRegionObject)
    dataGI.graph_ext_info.graph_ext_name_version = lastVersion
    dataGI.graph_ext_info.graph_ext_status_caption = currentStatus.label || 'Черновик'
    dataGI.graph_ext_info.graph_ext_status_id = currentStatus.value || 1
    dataGI.graph_ext_info.graph_ext_started_on = null
    dataGI.graph_ext_info.graph_ext_expirated_on = null
    dataGI.graph_ext_info.graph_ext_list_document_npa = []
    handleSetDataRegionStartedOn(null)
    handleSetDataRegionExpiratedOn(null)
    handleSetLoadedDataGI(dataGI)
    handleSetIsDirtyDocument(true)
    handleSetIsNewVersion(true)
  }

  // Установка версии с переходом по адресу
  function setVersionGraph (version) {
    handleSetCurrentStatus({ label: version.region_status_caption, value: version.region_status_id })
    if(!isDirtyDocument) {
      handleSetCurrentVersion(version.value)
      history.push(`${URLS.METHODOLOGY__ROUTES}/${id}/${version.value}/general`)
    } else {
      history.push(`${URLS.METHODOLOGY__ROUTES}/${id}/${version.value}/general`)
    }
  }

  // Возврат последней версии документа
  function getLastVersion () {

    const result = versionGraph?.payload?.data?.reduce((prev, current) => {
      // не менять поле region_name_version на graph_ext_name_version, это из другого запроса
      if (+current.region_name_version > +prev.region_name_version) {
        return current
      } else {
        return prev
      }
    })
    if(isEmpty(result)) {
      return 0
    }
    return isEmpty(result) ? 0 : result.region_name_version
  }

  return <div className='wrapper-top mb-16'>
    <div className='grid-8'>
      <div className='x3'>
        <Combobox
          style={{ width: '100%' }}
          placeholder='Выберите'
          onChange={handleVersionGraph}
          defaultValue={revisionId}
          value={String(currentVersion)}
          options={isEmpty(options) ? firstOptions : options}
          loading={versionGraph.loading}
          disabled={!options?.length}
        />
      </div>
      <div className='x5 d-flex'>
        <IfNotReadonly>
          <Button
            icon={'plus_cr_fr'}
            label='Добавить новую версию'
            onClick={handleAddVersion}
            disabled={isNewVersion}

          />
        </IfNotReadonly>
      </div>
    </div>
  </div>
}
