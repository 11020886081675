import { uniqBy } from 'lodash'
import { OPERATION_CODE_MAP } from './constants'

// Функция фильтрует список доступных операций для выбора пользователем, в зависимости от attr_type_id атрибута
export const getAvailableOperationList = (operationValueIdList, attr_type_id) => {
  if (!operationValueIdList || !attr_type_id)
    return []

  // Фильтруем список всех операций, оставляем только подходящие.
  // Подходят только те операции, у которых тип значения атрибута(attr_type_id) совпадает с attr_type_id выбранного атрибута в конъюнкте
  // И оставляем только уникальные по operation_id значения
  const operationsOfAttribute = uniqBy(operationValueIdList.filter(el => el.attr_type_id === attr_type_id), 'operation_id')

  // Список опций для выбора в выпадающем меню выбора операции
  // Среди подходящих оставляем только те, код которых определен в OPERATION_CODE_MAP
  // Операции, которые доступны в двух вариантах(с логическим признаком НЕ) предоставляем дважды
  // У них одинаковый operation_id, но разный признак logical_no_applied
  const operations = []

  operationsOfAttribute?.forEach((operation) => {
    OPERATION_CODE_MAP[operation.operation_code]?.forEach((operation_option) => {
      operations.push({
        ...operation,
        ...operation_option,
      })
    })
  })

  return operations.sort((a, b) => +a.operation_id - +b.operation_id)
}
