import { useCallback, useMemo } from 'react'
import { isFunction, isString } from 'lodash'
import { Checkbox } from '@src/components'
import { cn } from '@src/utils'
import { useRowKey } from './hooks'

export const DataGridRow = ({ style, index, data, columns, value, selectable, rowKey, onChange, onRowDoubleClick,
  onRowAuxClick, onRowClick, onSelectionChange, templateColumnsWidth }) => {
  const row = data[index]

  const [getKeyForRowIndex] = useRowKey(data, rowKey)

  const handleCheckboxClick = useCallback((e) => {
    e.stopPropagation()
    const rowIndex = Number(e.currentTarget.getAttribute('data-row-index'))
    const key = getKeyForRowIndex(rowIndex)

    onChange({
      ...value,
      selectedCheckboxes: { ...value.selectedCheckboxes, [key]: !value.selectedCheckboxes[key] },
    })
    onSelectionChange()
  }, [value, onSelectionChange, onChange, getKeyForRowIndex])

  const handleRowDoubleClick = useCallback((e) => {
    const rowIndex = Number(e.currentTarget.getAttribute('data-row-index'))
    onRowDoubleClick(data[rowIndex])
  }, [data, onRowDoubleClick])

  const gridTemplateColumns = useMemo(() => {
    return { gridTemplateColumns: templateColumnsWidth.columnsKeysWidth.join('px ').concat('px') }
  }, [templateColumnsWidth.columnsKeysWidth])

  const handleRowAuxClick = useCallback((e) => {
    const rowIndex = Number(e.currentTarget.getAttribute('data-row-index'))
    onRowAuxClick(data[rowIndex])
  }, [data, onRowAuxClick])

  const handleRowClick = useCallback((e) => {
    const rowIndex = Number(e.currentTarget.getAttribute('data-row-index'))
    onRowClick(data[rowIndex])
  }, [data, onRowClick])

  return (
    <div
      style={{ ...gridTemplateColumns, ...style }}
      className={cn('grid-table-body-row grid-gap-0', index%2 && 'grid-even', selectable && 'row-selectable')}
      onDoubleClick={handleRowDoubleClick}
      onAuxClick={handleRowAuxClick}
      onClick={handleRowClick}
      data-row-index={index}
    >
      {
        selectable && <div data-row-index={index} onClick={handleCheckboxClick} className='grid-table-body-col'>
          <Checkbox value={row.__checkbox} />
        </div>
      }
      {
        Object.keys(columns).map((key) => {
          const column = columns[key]
          const display = column.dataRender ? column.dataRender(row[column.dataIndex], row) : row[column.dataIndex]
          const title = column.titleRender ? column.titleRender(row[column.dataIndex], row) : display
          const cellClassName = column.cellClassName
            ? isFunction(column.cellClassName)
              ? column.cellClassName(row)
              : column.cellClassName
            : null

          return <div className={cn('grid-table-body-col', cellClassName)} key={key} title={title}>
            {
              isString(display)
                ? <p className='col-text'>{display}</p>
                : display
            }
          </div>
        })
      }
    </div>
  )
}
