import cn from 'classnames'
import { isFunction } from 'lodash'
import PropTypes from 'prop-types'
import { Component } from 'react'
import MaskedInput from 'react-text-mask'
import { Icon } from '@src/components'
import { NOOP_FN } from '@src/utils'
import './MaskedTextInput.scss'

export default class MaskedTextInput extends Component {
  static propTypes = {
    mask: PropTypes.array,
    placeholderChar: PropTypes.string,
    showMask: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    name: PropTypes.string,
    icon: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    allowClear: PropTypes.bool,
    onClear: PropTypes.func,
    isInvalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  }

  static defaultProps = {
    placeholder: '',
    disabled: false,
    allowClear: false,
    onChange: NOOP_FN,
    value: '',
  }

  handleClear = () => {
    if(this.props.onClear)
      this.props.onClear()
    else
      this.props.onChange('')
  }

  render () {
    const { value, name, placeholder, className, onChange, onBlur, disabled, mask, placeholderChar, showMask, allowClear, icon, isInvalid } = this.props

    const invalid = isFunction(isInvalid) ? isInvalid(value) : isInvalid

    return (
      <div className={cn('root-masked-text-input', invalid && 'root-masked-text-input-invalid', className)}>
        <MaskedInput
          className='masked-text-input-input'
          mask={mask}
          placeholderChar={placeholderChar}
          showMask={showMask}
          disabled={!!disabled}
          placeholder={placeholder ?? ''}
          onChange={e => onChange(e.target.value)}
          onBlur={onBlur}
          value={value}
          id={name}
          autoComplete='off'
        >
        </MaskedInput>
        {
          allowClear && value !== ''
            ? <Icon className='masked-text-input-icon-clear' icon='close' alt='Очистить' onClick={this.handleClear} />
            : this.props.icon
              ? <Icon className='masked-text-input-icon' icon={icon} alt='Search' />
              : null
        }
      </div>
    )
  }
}
