import { TAG_COLORS } from './tagColors'

export const DOC_STATUSES = {
  ACTIVE: 'Действующий',
  PROJECT: 'Проект',
  APPROVEMENT: 'Рассмотрение и одобрение',
  INACTIVE: 'Недействующий',
  DRAFT: 'Черновик',
  NEW: 'Новый',
  OUTDATED: 'Утратил силу',
  PUBLISHED: 'Опубликован',
}

export const docStatusToTagColor = (status) => {
  switch (status) {
  case DOC_STATUSES.ACTIVE: return TAG_COLORS.DUSTY_BLUE
  case DOC_STATUSES.PROJECT: return TAG_COLORS.BEIGE
  case DOC_STATUSES.APPROVEMENT: return TAG_COLORS.LIGHT_BEIGE
  case DOC_STATUSES.INACTIVE: return TAG_COLORS.KRAYOLA_THISTLE
  case DOC_STATUSES.DRAFT: return TAG_COLORS.LAVENDER
  case DOC_STATUSES.NEW: return TAG_COLORS.GREEN_TEA
  case DOC_STATUSES.OUTDATED: return TAG_COLORS.KRAYOLA_THISTLE
  case DOC_STATUSES.PUBLISHED: return TAG_COLORS.LIGHT_BEIGE
  default: return TAG_COLORS.GREY_100
  }
}
