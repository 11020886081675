import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import monitoringReducer from '@src/modules/monitoring/store'
import analyticsReducer from '@src/modules/analytics/store'
import adminReducer from '@src/modules/admin/store'
import journalReducer from '@src/modules/journal/store'
import dictionariesReducer from '@src/modules/dictionaries/store'
import nsiReducer from '@src/modules/nsi/store'
import methodologyReducer from '@src/modules/methodology/store'
import securityReducer from './security'
import uiReducer from './ui'
import notificationsReducer from './notifications'
import { refreshJwtMiddleware, notificationsMiddleware } from './middlewares'

const store = configureStore({
  reducer: {
    security: persistReducer<any, any>({ key: 'security', whitelist: [/* 'auth' */], storage }, securityReducer),
    ui: uiReducer,
    notifications: notificationsReducer,
    monitoring: monitoringReducer,
    analytics: analyticsReducer,
    admin: adminReducer,
    journal: journalReducer,
    dictionaries: dictionariesReducer,
    nsi: nsiReducer,
    methodology: methodologyReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware(
    {
      serializableCheck: false,
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  )
    .concat(refreshJwtMiddleware)
    .concat(notificationsMiddleware)
  ,
})

const persistor = persistStore(store)

export { store, persistor }
export * from './security'
export * from './ui'
export * from './notifications'

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
