import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from '@src/components'
import { loadPatientServiceList, loadPatientStatusOnko, selectPatientInfoModel,
  selectPatientStatusModel } from '@src/modules/monitoring/store'
import { getFullname } from '@src/utils'
import { useMountEffect } from '@src/hooks'
import { withMntAudit } from '@src/hoc'
import { TabStatusBase } from '@src/modules/monitoring/components'

const TabStatus = ({ patientId, occasionPatientId, ...props }) => {
  const dispatch = useDispatch()
  const patientStatusModel = useSelector(selectPatientStatusModel)
  const patientInfoModel = useSelector(selectPatientInfoModel)

  const status = patientStatusModel.payload?.data?.[0]
  const info = patientInfoModel.payload?.data

  useMountEffect(() => {
    dispatch(loadPatientStatusOnko({ args: { patient_id: patientId, occasion_patient_id: occasionPatientId } }))
    dispatch(loadPatientServiceList({ args: { occasion_patient_id: occasionPatientId } }))
  })

  return <TabStatusBase {...props}>
    <Card
      title='Пациент'
      loading={patientInfoModel.loading}
      error={patientInfoModel.error}
      style={{ flexBasis: '40%' }}
      className='mr-16'
      data={info && [
        {
          title: 'Имя',
          data: getFullname(info) || '-',
        },
        {
          title: 'Пол',
          data: info.gender || '-',
        },
        {
          title: 'Дата рождения',
          data: info.birth_date ? moment(info.birth_date).format('DD.MM.YYYY') : '-',
        },
        {
          title: 'Возраст',
          data: info.patient_age ? info.patient_age : '-',
        },
      ]}
    />
    <div style={{ flexBasis: '60%' }}>
      <Card
        title='Диагноз'
        loading={patientStatusModel.loading}
        error={patientStatusModel.error}
        className='mb-16'
        data={status && [
          {
            title: 'Степень обоснованности диагноза',
            data: status.validity_degree || '—',
          },
          {
            title: 'Дата установки',
            data: status.diag_date
              ? moment(status.diag_date).format('DD.MM.YYYY')
              : '—',
          },
          {
            title: 'Диагноз МКБ-10',
            data: `${status.mkb_code} ${status.mkb_name}` || '—',
          },
          {
            title: 'Сопутствующие диагнозы',
            data: status.concomitant_diagnosis || '—',
          },
        ]}
      />
    </div>
  </TabStatusBase>
}

export const TabStatusWithAudit = withMntAudit(TabStatus)

TabStatusWithAudit.tabTitle = 'Статус'
TabStatusWithAudit.tabPath = 'status'
