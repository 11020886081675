import { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './Table.scss'

export default class Table extends Component {
  static propTypes = {
    headers: PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string,
      headerName: PropTypes.string,
      width: PropTypes.number,
    })),
    rows: PropTypes.array,
    className: PropTypes.string,
  }

  render () {
    const { headers, rows, className } = this.props
    const headersKeys = headers.map(i => i.field)

    return (
      <div className={cn('root-table', className)}>
        <table>
          <thead>
            <tr>
              {
                headers.map(header => (
                  <th style={header.width ? { width: `${header.width}px` } : {}} key={`header-${header.field}`}>{header.headerName}</th>
                ))
              }
            </tr>
          </thead>
          <tbody>
            {
              rows.map((r, rindex) => (
                <tr key={`record-${rindex}`}>
                  {
                    headersKeys.map(hKey => (
                      <td key={`cell-${rindex}-${hKey}`}>{
                        r[hKey] instanceof Function
                          ? r[hKey]()
                          : r[hKey]
                      }</td>
                    ))
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    )
  }
}
