import extraCommon from './common'
import extraNpa from './npa.js'
import extraClinrec from './clinrec'
import extraRoutes from './routes'

export * from './common'
export * from './npa.js'
export * from './clinrec'
export * from './routes'

export default {
  ...extraCommon,
  ...extraNpa,
  ...extraClinrec,
  ...extraRoutes,
}
