import { useCallback } from 'react'
import { Accordion, Button, EmptyState, IfNotReadonly } from '@src/components'
import { modalService } from '@src/services'
import { Alert, MoModal, TerritoryMoModal } from '@src/modals'
import { SubblockTerritorial } from './SubblockTerritorial'

export const TerritorialCondition = ({ territory, numberTerritory, onChangeMoTerritory, moTerritoryList }) => {

  const handleChangeMoTerritory = useCallback((updater) => {
    onChangeMoTerritory(updater(moTerritoryList))
  }, [moTerritoryList, onChangeMoTerritory])

  const openTerritoryMoModal = () => {
    modalService.open(TerritoryMoModal, { territory_list: territory.territory_list, onOk: handleAddTerritory })
  }

  const openMoModal = () => {
    modalService.open(MoModal, { mo_list: territory.mo_list, onOk: handleAddMo })
  }

  const handleAddMo = (mo) => {
    const aoguid_arr = moTerritoryList?.map(territory_item => territory.uuid === territory_item.uuid ? [] : territory_item?.mo_list).flat(2)

    for(let i = 0; i < mo.length; i++) {
      let index = aoguid_arr.findIndex(k => mo[i] === k)
      if(~index)
        return modalService.open(Alert, {
          title: 'Внимание',
          children: 'Медицинская организация уже добавлена в условие!',
          okText: 'Ок',
        })
    }

    handleChangeMoTerritory(p => p.map(i => i.uuid === territory.uuid ? ({ ...i, mo_list: mo }) : i))
  }

  const handleRemoveMoTerritory = (uuid, name) => {
    modalService.open(Alert, {
      warn: true,
      onOk: () => moTerritoryList.length - 1 && handleChangeMoTerritory(prevState => prevState.filter(e => e.uuid !== uuid)),
      title: 'Удаление',
      children: `${name} будут удалены`,
      okText: 'Удалить',
    })
  }

  const handleAddTerritory = (territory_add) => {
    const aoguid_arr = moTerritoryList?.map(territory_i => territory_i?.territory_list?.map(territory_item => territory_item?.aoguid_list ?? [])).flat(2)

    for(let i = 0; i < territory_add.aoguid_list.length; i++) {
      let index = aoguid_arr.findIndex(k => territory_add.aoguid_list[i] === k)
      if(~index)
        return modalService.open(Alert, {
          title: 'Внимание',
          children: (territory_add.houseList.length
            ? `Здание: ${territory_add.houseList[i]?.RELNAME}`
            : `Территория: ${territory_add.territory_name}`) + ' уже добавлена в условие!',
          okText: 'Ок',
        })
    }

    delete territory_add.houseList

    handleChangeMoTerritory(p => p.map(i => i.uuid === territory.uuid ? ({ ...i, territory_list: [...i?.territory_list ?? [], territory_add] }) : i))
  }

  const handleDeleteItemMo = (item) => {
    modalService.open(Alert, {
      warn: true,
      onOk: () => handleChangeMoTerritory(p => p.map(i => i.uuid === territory.uuid ? ({ ...i, mo_list: i.mo_list.filter(t => t !== item) }) : i)),
      title: 'Удаление',
      children: 'Выбранная мед организация будет удалена из списка Медицинских организаций',
      okText: 'Удалить',
    })
  }

  const handleDeleteItemTerritory = (item) => {
    modalService.open(Alert, {
      warn: true,
      onOk: () => handleChangeMoTerritory(p => p.map(i => i.uuid === territory.uuid ? ({ ...i, territory_list: i.territory_list.filter(t => t.territory_name !== item.territory_name) }) : i)),
      title: 'Удаление',
      children: `${item?.territory_name ?? item.TERRITORY_NAME} будет удалён из Территории для определения МО`,
      okText: 'Удалить',
    })
  }

  const handleClearTerritory = () => {
    modalService.open(Alert, {
      warn: true,
      onOk: () => handleChangeMoTerritory(p => p.map(i => i.uuid === territory.uuid ? ({ ...i, territory_list: [] }) : i)),
      title: 'Удаление',
      children: 'Очистить все Территории для определения МО',
      okText: 'Удалить',
    })
  }

  const handleClearMo = () => {
    modalService.open(Alert, {
      warn: true,
      onOk: () => handleChangeMoTerritory(p => p.map(i => i.uuid === territory.uuid ? ({ ...i, mo_list: [] }) : i)),
      title: 'Удаление',
      children: 'Очистить все Медицинские организации?',
      okText: 'Удалить',
    })
  }

  return <Accordion
    className='root-territorial-condition'
    index={numberTerritory}
    title='Территориальные параметры регионального условия'
    titleJsx={
      <IfNotReadonly>
        <Button
          className='ma-16 w-50'
          label='Удалить'
          icon='trash_can_green'
          onClick={(e) => {
            handleRemoveMoTerritory(territory.uuid, `Территориальное условие ${numberTerritory}`)
            e.stopPropagation()
          }}
          disabled={moTerritoryList.length < 2}
        />
      </IfNotReadonly>
    }
    dataContainerClassName='territorial-condition-container'
    dataComponent={
      <div className='territorial-condition-block h-300'>
        <SubblockTerritorial
          caption='Территория для определения МО'
          territory_list={territory.territory_list ?? []}
          handleAddition={openTerritoryMoModal}
          handleDeleteItemTerritory={handleDeleteItemTerritory}
          handleCleaning={handleClearTerritory}
          isTerritory
        />
        <SubblockTerritorial
          caption='Медицинская организация (МО)'
          territory_list={territory.territory_list ?? []}
          mo_list={territory.mo_list ?? []}
          handleAddition={openMoModal}
          handleDeleteItemMo={handleDeleteItemMo}
          handleCleaning={handleClearMo}
        />
        {
          !territory?.territory_list?.length
            ? <EmptyState
              className='territorial-condition-empty territorial-condition-empty-description'
              caption='Здесь пока ничего нет.'
              description='Для начала работы добавьте территорию для определения МО'
            />
            : null
        }
      </div>
    }
  />
}
