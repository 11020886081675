import { Form } from 'antd'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { cn } from '@src/utils'
import { selectOperationValueIdListModel } from '@src/modules/methodology/store'
import { Icon, Badge, IfNotReadonly } from '@src/components'
import { VALUE_CODE_DEFAULT_VALUE_MAP } from '@src/modules/methodology/pages/MethRegConditionsPage/AttributesDrawer/constants'
import { FormContext } from './FormContext'
import { getAvailableOperationList } from './utils'
import { AttributeSelector } from './AttributeSelector'
import { Conjunct } from './Conjunct'

export const Disjunct = ({ title, onDeleteDisjunct }) => {
  const formContext = useContext(FormContext)
  const operationValueIdListModel = useSelector(selectOperationValueIdListModel)

  return <div className='root-disjunct mb-16'>
    <div className='disjunct-header d-flex align-items-center px-16 py-12'>
      <span>{title}</span>
      <IfNotReadonly>
        <div onClick={onDeleteDisjunct} className='disjunct-trash-icon w-32 h-32 ml-auto d-flex align-items-center justify-content-center'>
          <Icon className='w-16 h-16' icon='trash_can' />
        </div>
      </IfNotReadonly>
    </div>
    <Form.Item noStyle>
      <Form.List name={[formContext.currentDisjunctFieldName, 'condition_conjunct']}>
        {(fields, conjunctOperation) => <div className='disjunct-content pa-16 d-flex align-items-center flex-direction-column'>
          <div className={cn('conjunct-list', {
            'mb-16': fields.length,
            'display-line': fields.length > 1,
          }
          )}>
            {fields.length > 1 && <Badge>и</Badge>}
            {fields.map((field => <FormContext.Provider key={field.key} value={{
              ...formContext,
              currentConjunctFieldName: field.name,
            }}>
              <Conjunct
                onDeleteConjunct={() => conjunctOperation.remove(field.name)}
              />
            </FormContext.Provider>
            ))}
          </div>

          <IfNotReadonly>
            <AttributeSelector onPickAttribute={(id, attribute_node) => {
              // Находим соответствующий type_code операции Равно для данного атрибута
              const type_code = getAvailableOperationList(operationValueIdListModel.payload?.data, attribute_node.attr_type_id)
                .find(op => op.operation_id === '1')
                .value_type_code

              conjunctOperation.add({
                attribute_id: attribute_node.id,
                operation_id: '1',
                logical_no_applied: false,
                value_description: {
                  type_code,
                  value: VALUE_CODE_DEFAULT_VALUE_MAP[type_code],
                },
              })
            }} />
          </IfNotReadonly>
          {/* Необходимость функционала типового блока пока под вопросом */}
          {/* <div className='disjunct-add-typical-block d-flex align-items-center justify-content-center mt-16'>
              <Icon className='mr-8 w-16 h-16' icon='plus_cr_fr'/>
              <span>Добавить типовой блок</span>
            </div> */}
        </div>}
      </Form.List>
    </Form.Item>
  </div>
}
