import EventEmitter from 'events'
import { v4 as uuidv4 } from 'uuid'

export default class ModalService extends EventEmitter {
  constructor (props) {
    super(props)

    this.stack = []
  }

  open (component, props) {
    this.stack.push({
      modalId: uuidv4(),
      component,
      open: true,
      props,
    })
    this.emit('open', this.stack)
  }

  remove (modal) {
    this.stack.splice(this.stack.findIndex(item => item.modalId === modal.modalId), 1)
    this.emit('remove', this.stack)
  }

  close (modal) {
    const item = this.stack.find(item => item.modalId === modal.modalId)
    item.open = false
    setTimeout(() => this.remove(modal), 200)
    this.emit('close', this.stack)
  }
}
