export const METHODOLOGY_DOCUMENT = 'methodologyDocument'
export const REVISION_LIST = 'revisionsList'
export const REVISION_DATA = 'revisionsData'
export const NPA_LIST = 'npaList'
export const GRAPH_LIST = 'graphList'
export const NPA_LIST_COUNT = 'npaListCount'
export const STAFF_PLACES_LIST = 'staffPlacesList'
export const STAFF_REQUIREMENTS = 'staffRequirements'
export const EQUIPMENT_REQUIREMENTS = 'equipmentRequirements'
export const EQUIPMENT_PLACES_LIST = 'equipmentRequirementsPlaces'
export const THESIS_LIST = 'thesisList'
export const CR_LIST = 'crList'
export const CR_LIST_COUNT = 'crListCount'
export const DOC_TYPES = 'dictsDocTypes'
export const LAST_METH_DOCS = 'lastMethDocs'
export const DOC_PROFILES = 'dictsDocProfiles'
export const METH_ROUTES_LIST = 'METH_ROUTES_LIST'
export const METH_ROUTES_LIST_COUNT = 'METH_ROUTES_LIST_COUNT'
export const METH_ROUTES_STATUS_LIST = 'METH_ROUTES_STATUS_LIST'
export const METH_VMCL_LIST = 'METH_VMCL_LIST'
export const GRAPH_VERSIONS = 'GRAPH_VERSIONS'
export const GRAPH = 'GRAPH'
export const PUT_GRAPH = 'PUT_GRAPH'
export const VERSION_GRAPH_CSP = 'VERSION_GRAPH_CSP'
export const GRAPH_CSP = 'GRAPH_CSP'
export const PUT_GRAPH_CSP = 'PUT_GRAPH_CSP'
export const METH_GRAPH_DESCRIPTION = 'METH_GRAPH_DESCRIPTION'
export const CHECK_STATUS = 'CHECK_STATUS'
export const GET_CONDITIONS_LIST_CSP = 'GET_CONDITIONS_LIST_CSP'
export const DELETE_CONDITIONS_CSP = 'DELETE_CONDITIONS_CSP'
export const ATTRIBUTE_TREE = 'ATTRIBUTE_TREE'
export const OPERATION_VALUE_ID_LIST = 'OPERATION_VALUE_ID_LIST'
export const VALUE_TYPE_LIST = 'VALUE_TYPE_LIST'
export const REFBOOK_DATA_ATTRIBUTE = 'REFBOOK_DATA_ATTRIBUTE'
export const REFERENCE_ATTRIBUTE = 'REFERENCE_ATTRIBUTE'
export const GET_ATTRIBUTE_TREE_BY_TYPE = 'GET_ATTRIBUTE_TREE_BY_TYPE'
export const DICT_DEVIATION_VALUE = 'DICT_DEVIATION_VALUE'
export const GET_CONDITION_FORM = 'GET_CONDITION_FORM'
export const POST_CONDITION_FORM = 'POST_CONDITION_FORM'

export const COPY_CONDITION_MODAL_ROUTES = 'COPY_CONDITION_MODAL_ROUTES'
export const COPY_CONDITION_MODAL_VERSIONS = 'COPY_CONDITION_MODAL_VERSIONS'
export const COPY_CONDITION_MODAL_STATES = 'COPY_CONDITION_MODAL_STATES'
export const COPY_CONDITION_MODAL_CONDITIONS = 'COPY_CONDITION_MODAL_CONDITIONS'
export const COPY_CONDITION_MODAL_SOURCE_CONDITION = 'COPY_CONDITION_MODAL_SOURCE_CONDITION'
export const COPY_CONDITION_MODAL_SAVE_CONDITION = 'COPY_CONDITION_MODAL_SAVE_CONDITION'
