import { thunkCreator } from '@src/utils'
import * as models from '../models'

export const [
  loadRevisionsList, loadRevisionsListExtra,
] = thunkCreator(models.REVISION_LIST, '/method/get_list_revision')

export const [
  loadRevisionData, loadRevisionDataExtra,
] = thunkCreator(models.REVISION_DATA, '/method/get_revision', { extractor: d => d.data[0] })

export const [
  loadDocument, loadDocumentExtra,
] = thunkCreator(models.METHODOLOGY_DOCUMENT, '/method/get_document_by_id', { extractor: d => d?.data?.[0] })

export const [
  loadDocTypes, loadDocTypesExtra,
] = thunkCreator(models.DOC_TYPES, '/method/get_document_form_list_npa')

export const [
  loadDocProfiles, loadDocProfilesExtra,
] = thunkCreator(models.DOC_PROFILES, '/method/get_medical_help_profile_list')

export const [
  loadMethUpdateTimestamp, loadMethUpdateTimestampExtra,
] = thunkCreator(models.LAST_METH_DOCS, '/method/get_last_meth_docs', { method: 'get', extractor: e => e.data })

const extraReducers = {
  ...loadRevisionsListExtra,
  ...loadRevisionDataExtra,
  ...loadDocumentExtra,
  ...loadDocTypesExtra,
  ...loadDocProfilesExtra,
  ...loadMethUpdateTimestampExtra,
}

export default extraReducers
