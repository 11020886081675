import { thunkCreator } from '@src/utils'
import * as models from '../models'

export const [
  loadPatientStatusInfection, loadPatientStatusInfectionExtra,
] = thunkCreator(models.PATIENT_STATUS_INFECTION, '/patient-monitoring/get_patient_status_occ')

export const [
  loadPatientInfoInfection, loadPatientInfoInfectionExtra,
] = thunkCreator(models.PATIENT_INFO_INFECTION, '/patient-monitoring/get_patient_info')

export const [
  loadEmergencyNoticeWidgetInfection, loadEmergencyNoticeWidgetInfectionExtra,
] = thunkCreator(models.EMERGENCY_NOTICE_WIDGET_INFECTION, '/patient-monitoring/get_emergency_notice_widget')

export const [
  loadDnWidgetInfection, loadDnWidgetInfectionExtra,
] = thunkCreator(models.DN_WIDGET_INFECTION, '/patient-monitoring/get_dn_widget_infection')

export const [
  loadRegisterEmergencyNoticeList, loadRegisterEmergencyNoticeListExtra,
] = thunkCreator(models.PATIENT_EMERGENCY_NOTICE, '/patient-monitoring/get_patient_info',
  { targetModel: models.PATIENT_STATUS })

export const [
  loadRegisterEmergencyNoticeWidget, loadRegisterEmergencyNoticeWidgetExtra,
] = thunkCreator(models.PATIENT_EMERGENCY_NOTICE_WIDGET, '/patient-monitoring/get_emergency_notice_widget',)


export const [
  loadRegisterEmergencyNoticeWidgetCSP, loadRegisterEmergencyNoticeWidgetCSPExtra,
] = thunkCreator(models.PATIENT_EMERGENCY_NOTICE_WIDGET, '/patient-monitoring/get_emergency_notice_widget_csp',)


export default {
  ...loadPatientStatusInfectionExtra,
  ...loadPatientInfoInfectionExtra,
  ...loadEmergencyNoticeWidgetInfectionExtra,
  ...loadDnWidgetInfectionExtra,
  ...loadRegisterEmergencyNoticeListExtra,
  ...loadRegisterEmergencyNoticeWidgetExtra,
  ...loadRegisterEmergencyNoticeWidgetCSPExtra,
}
