import { TextInput } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class ChildFullnameColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.CHILD_FULLNAME

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Фамилия новорожденного',
        component: TextInput,
        defaultValue: '',
        props: {
          icon: 'search',
          allowedChars: /[А-Яа-я\- ]/,
          isInvalid: fullname => fullname && fullname.length !== 0 && fullname.length < 3,
          allowClear: true,
        },
      },
      width: 200,
      dataIndex: 'child_fullname',
      dataRender: child_fullname => child_fullname ? child_fullname : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let requestFilters = []

    if (filters?.child_fullname) {
      requestFilters = [{ field: 'child_fullname', value: filters?.child_fullname, operator: 'like_both', invalid: false }]
    }

    if (nullSearches.child_fullname) {
      requestFilters = [{ field: 'child_fullname', value: null, operator: '=', invalid: false }]
    }

    return {
      control: {
        filters: requestFilters,
      },
    }
  }
}
