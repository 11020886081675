import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { URLS } from '@src/constants'
import { withMntAudit } from '@src/hoc'
import { InternalError404 } from '@src/components'
import { ClinRecDataPage } from '../../pages/ClinRecDataPage/ClinRecDataPage'
import { ClinRecTable } from '../../components/ClinRecTable/ClinRecTable'
import './ClinRecPage.scss'

const ClinRecPage = () => {
  return (
    <div className='root-clin-rec-page'>
      <Switch>
        <Route path={`${URLS.METHODOLOGY__CLINREC__DATA}/:id`}>
          <ClinRecDataPage />
        </Route>
        <Route path={URLS.METHODOLOGY__CLINREC}>
          <ClinRecTable />
        </Route>
        <Route component={InternalError404}/>
      </Switch>
    </div>
  )
}

export const ClinRecPageWithAudit = withMntAudit(ClinRecPage)
