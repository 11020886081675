import { groupBy } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectRevisionDataModel, selectRevisionsListModel } from '@src/modules/methodology/store'
import { Card, Combobox, Tag } from '@src/components'

export const RevisionSelector = ({ onRevisionChange, selectedRevisionId, ...props }) => {
  const [cardData, setCardData] = useState(null)
  const revisionDataModel = useSelector(selectRevisionDataModel)

  useEffect(() => {
    const data = revisionDataModel.payload
    setCardData([
      {
        title: 'Статус редакции',
        components: [
          {
            component: Tag,
            props: {
              children: data?.status_caption ?? '-',
              color: 'dusty-blue',
            },
          },
        ],
      },
      {
        title: 'Вступило в силу',
        data: data?.effective_date ? moment(data.effective_date).format('D.MM.YYYY') : '-',
      },
      {
        title: 'Дата утраты силы',
        data: data?.expiration_date ? moment(data.expiration_date).format('D.MM.YYYY') : '-',
      },
      // {
      //   title: 'Последние изменения',
      //   data: data?.lastchange_date ? moment(data.lastchange_date).format('D.MM.YYYY') : '-',
      // },                                                                                       //VIMISVIMIS-21048 VIMISVIMIS-21051
    ])

  }, [revisionDataModel])

  return <Card
    {...props}
    data={cardData}
    headerFuncPointer={() => <CardHeader selectedRevisionId={selectedRevisionId} onRevisionChange={onRevisionChange} />}
  />
}

const CardHeader = ({ onRevisionChange, selectedRevisionId }) => {
  const [options, setOptions] = useState([])
  const [selectedRevision, setSelectedRevision] = useState(null)
  const revisionsListModel = useSelector(selectRevisionsListModel)

  useEffect(() => {
    if (revisionsListModel.payload) {
      const groups = groupBy(revisionsListModel.payload.data, 'caption')

      setOptions(
        Object.entries(groups)
          .map(([caption]) => ({
            label: caption,
            options: groups[caption].map(rev => ({ value: rev.id, label: rev.name })),
          }))
      )
    }
  }, [revisionsListModel])

  useEffect(() => {
    if(!selectedRevisionId || !options) {
      setSelectedRevision(null)
    } else {
      const opt = options.map(o => o.options).flat().find(opt => opt.value === selectedRevisionId)
      setSelectedRevision(opt)
    }
  }, [selectedRevisionId, options])

  const handleChange = (option) => {
    if (option.value !== selectedRevisionId) onRevisionChange(option)
  }

  return <Combobox
    onChange={handleChange}
    value={selectedRevision?.value}
    options={options}
    className='w-100p'
  />
}
