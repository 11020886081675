import config from '@src/config'
import { ACLS, URLS } from '@src/constants'


export const reportsFactory = () => {
  const REPORTS_INTEGRATION = [
    {
      enabled: config?.app?.modules?.enabled?.onko,
      path: `${URLS.ANALYTICS__REPORTS}/integration_onko`,
      title: 'Отчет об информационном взаимодействии Подсистемы «Онкология» с внешними ИС-поставщиками СЭМД',
      allowAcl: ACLS.ANALYTICS_REPORTS_READ_REPORT_ONKO_IS,
      mclId: 1,
    },
    {
      enabled: config?.app?.modules?.enabled?.akineo,
      path: `${URLS.ANALYTICS__REPORTS}/integration_akineo`,
      title: 'Отчет об информационном взаимодействии Подсистемы «АКиНЕО» с внешними ИС-поставщиками СЭМД',
      allowAcl: ACLS.ANALYTICS_REPORTS_READ_REPORT_AKINEO_IS,
      mclId: 3,
    },
    {
      enabled: config?.app?.modules?.enabled?.ssz,
      path: `${URLS.ANALYTICS__REPORTS}/integration_ssz`,
      title: 'Отчет об информационном взаимодействии Подсистемы «Сердечно-сосудистые заболевания» с внешними ИС-поставщиками СЭМД',
      allowAcl: ACLS.ANALYTICS_REPORTS_READ_REPORT_SSZ_IS,
      mclId: 4,
    },
    {
      enabled: config?.app?.modules?.enabled?.prof,
      path: `${URLS.ANALYTICS__REPORTS}/integration_prof`,
      title: 'Отчет об информационном взаимодействии Подсистемы «Профилактика» с внешними ИС-поставщиками СЭМД',
      allowAcl: ACLS.ANALYTICS_REPORTS_READ_REPORT_PROF_IS,
      mclId: 2,
    },
    {
      enabled: config?.app?.modules?.enabled?.infection,
      path: `${URLS.ANALYTICS__REPORTS}/integration_infection`,
      title: 'Отчет об информационном взаимодействии Подсистемы «Профилактика» с внешними ИС-поставщиками СЭМД (Инфекционные болезни)',
      allowAcl: ACLS.ANALYTICS_REPORTS_READ_REPORT_PROF_IB_IS,
      mclId: 5,
    },
  ]
  const REPORTS_VIMIS = [
    {
      enabled: config?.app?.modules?.enabled?.onko,
      path: `${URLS.ANALYTICS__REPORTS}/integration_vimis_onko`,
      title: 'Отчет об информационном взаимодействии Подсистемы «Онкология» с ВИМИС',
      allowAcl: ACLS.ANALYTICS_REPORTS_READ_REPORT_ONKO,
      mclId: 1,
    },
    {
      enabled: config?.app?.modules?.enabled?.akineo,
      path: `${URLS.ANALYTICS__REPORTS}/integration_vimis_akineo`,
      title: 'Отчет об информационном взаимодействии Подсистемы «АКиНЕО» с ВИМИС',
      allowAcl: ACLS.ANALYTICS_REPORTS_READ_REPORT_AKINEO,
      mclId: 3,
    },
    {
      enabled: config?.app?.modules?.enabled?.ssz,
      path: `${URLS.ANALYTICS__REPORTS}/integration_vimis_ssz`,
      title: 'Отчет об информационном взаимодействии Подсистемы «Сердечно-сосудистые заболевания» с ВИМИС',
      allowAcl: ACLS.ANALYTICS_REPORTS_READ_REPORT_SSZ,
      mclId: 4,
    },
    {
      enabled: config?.app?.modules?.enabled?.prof,
      path: `${URLS.ANALYTICS__REPORTS}/integration_vimis_prof`,
      title: 'Отчет об информационном взаимодействии Подсистемы «Профилактика» с ВИМИС',
      allowAcl: ACLS.ANALYTICS_REPORTS_READ_REPORT_PROF,
      mclId: 2,
    },
    {
      enabled: config?.app?.modules?.enabled?.infection,
      path: `${URLS.ANALYTICS__REPORTS}/integration_vimis_infection`,
      title: 'Отчет об информационном взаимодействии Подсистемы «Профилактика» с ВИМИС Инфекционные болезни',
      allowAcl: ACLS.ANALYTICS_REPORTS_READ_REPORT_PROF_IB,
      mclId: 5,
    },
  ]

  return {
    REPORTS_INTEGRATION,
    REPORTS_VIMIS,
  }
}
