// Мапка кодов операций в иконку и признак отрицания оператора(logical_no_applied)
export const OPERATION_CODE_MAP = {
  '=': [
    { icon: 'equals_outline_16', logical_no_applied: false },
    { icon: 'notequals_outline_16', logical_no_applied: true },
  ],
  '>': [{ icon: 'greater_outline_16', logical_no_applied: false }],
  '<': [{ icon: 'less_outline_16', logical_no_applied: false }],
  '>=': [{ icon: 'greaterequals_outline_16', logical_no_applied: false }],
  '<=': [{ icon: 'lessequals_outline_16', logical_no_applied: false }],
  'in_list': [
    { icon: 'in_list', logical_no_applied: false },
    { icon: 'not_in_list', logical_no_applied: true },
  ],
  'exists': [
    { icon: 'operation_exists', logical_no_applied: false },
    { icon: 'operation_not_exists', logical_no_applied: true },
  ],
}

export const VALUE_CODE_DEFAULT_VALUE_MAP = {
  'int': null,
  'float': null,
  'string': null,
  'datetime': null,
  'bool': false,
  'list': [],
  'ref_code': null,
  'array': null,
  'object': null,
}
