import { isBoolean } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useUserPreferences } from './useUserPreferences'

/**
 * @description Хук помогает управлять скрытием/отображением колонок таблицы
 * @param {Object} initialColumns
 * @returns
 */
export const useColumns = (columns = []) => {
  const [preferences, setPreferences] = useUserPreferences()

  const columnsWithPreferences = useMemo(() => {
    return Object.entries(columns).reduce((acc, [key, column]) => {
      return {
        ...acc,
        [key]: isBoolean(preferences[key])
          ? {
            ...column,
            enabled: preferences[key],
          }
          : column,
      }
    }, {})
  }, [columns, preferences])

  const columnOptions = useMemo(() => {
    return Object.keys(columnsWithPreferences).map(key => ({
      enabled: columnsWithPreferences[key].enabled,
      label: columnsWithPreferences[key].header,
      key,
    }))
  }, [columnsWithPreferences])

  const enabledColumns = useMemo(() => {
    return Object.entries(columnsWithPreferences).reduce((acc, [key, column]) => {
      return column.enabled ? { ...acc, [key]: column } : acc
    }, {})
  }, [columnsWithPreferences])

  const toggleColumn = useCallback((key) => {
    setPreferences({ [key]: !columnsWithPreferences[key].enabled })
  }, [columnsWithPreferences, setPreferences])

  return [enabledColumns, columnOptions, toggleColumn]
}
