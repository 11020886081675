import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@src/components'
import { cn } from '@src/utils'
import './EmptyState.scss'

export function EmptyState ({ caption, description, icon, className }) {
  return (
    <div className={cn('root-empty-state', className)}>
      <div className='empty-state-container'>
        <Icon className='empty-state-icon' icon={icon} />
        <p className='empty-state-caption'>{caption}</p>
        <p className='empty-state-description'>{description}</p>
      </div>
    </div>
  )
}

EmptyState.propTypes = {
  caption: PropTypes.node,
  description: PropTypes.node,
  icon: PropTypes.string,
}

EmptyState.defaultProps = {
  caption: 'Нет данных для отображения',
  description: '',
  icon: 'file_search_ou_lc',
}
