import { isFunction } from 'lodash'
import { JSXElementConstructor, useCallback } from 'react'
import { useAudit, UseAudit, useMountEffect } from '@src/hooks'

export const withAudit = (WrappedComponent: JSXElementConstructor<any>) => ({ auditPs, auditMessage, auditDescription, auditErrorText, auditLogType, ...props }: UseAudit) => {
  const logToAudit = useAudit({ auditPs, auditMessage, auditDescription, auditErrorText, auditLogType })

  return <WrappedComponent logToAudit={logToAudit} {...props}/>
}

export const withMntAudit = (WrappedComponent: JSXElementConstructor<any>) => ({ auditPs, auditMessage, auditDescription, auditErrorText, auditLogType, ...props }: UseAudit) => {
  const logToAudit = useAudit({ auditPs, auditMessage, auditDescription, auditErrorText, auditLogType })

  useMountEffect(() => {
    logToAudit()
  })

  return <WrappedComponent logToAudit={logToAudit} {...props}/>
}

interface WithClickAudit extends UseAudit {
  onClick(): void
}

export const withClickAudit = (WrappedComponent: JSXElementConstructor<any>) => ({ auditPs, auditMessage, auditDescription, auditErrorText, auditLogType, onClick, ...props }: WithClickAudit) => {
  const logToAudit = useAudit({ auditPs, auditMessage, auditDescription, auditErrorText, auditLogType })

  const handleClick = useCallback(() => {
    logToAudit()
    if (isFunction(onClick)) onClick()
  }, [logToAudit, onClick])

  return <WrappedComponent logToAudit={logToAudit} onClick={handleClick} {...props}/>
}
