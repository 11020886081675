import { createSlice } from '@reduxjs/toolkit'
import reducers from './reducers'
import extraReducers from './thunks'
import { initialState } from './initialState'

const slice = createSlice({
  name: 'monitoring',
  initialState,
  reducers,
  extraReducers,
})

export const { resetPatientList, setPatientListFilters, resetPatientCard } = slice.actions
export default slice.reducer
export * from './thunks'
export * from './selectors'
