import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import config from '@src/config'
import { AUDIT_LOG_TYPES, AUDIT_PS } from '@src/constants'
import { writeAuditLog } from '@src/modules/journal/store'
import { selectUserPreferences } from '@src/store'

export interface UseAudit {
  auditPs?: typeof AUDIT_PS[keyof typeof AUDIT_PS],
  auditMessage?: string,
  auditDescription?: { [key: string]: any },
  auditErrorText?: string,
  auditLogType?: typeof AUDIT_LOG_TYPES[keyof typeof AUDIT_LOG_TYPES],
}

/**
 * @description Хук предоставляет метод logToAudit для записи в журнал аудита
 * @param {UseAudit}
 * @returns logToAudit
 */
export const useAudit = ({ auditPs, auditMessage, auditDescription, auditErrorText, auditLogType }: UseAudit = {}) => {
  const dispatch = useDispatch()
  const userPreferences = useSelector(selectUserPreferences)

  const logToAudit = useCallback((audit: UseAudit = {}) => {
    dispatch(writeAuditLog({
      log_type: audit.auditLogType || auditLogType || (auditErrorText ? AUDIT_LOG_TYPES.ERROR : AUDIT_LOG_TYPES.AUDIT),
      error_text: audit.auditErrorText || auditErrorText,
      description: Object.assign(
        { session_id: config.session_id, user_preferences: userPreferences, device_info: getDeviceInfo() },
        auditDescription,
        audit.auditDescription,
      ),
      source_name: audit.auditMessage || auditMessage,
      target_name: audit.auditPs || auditPs,

      target: window.location.href.replace(window.location.origin, ''),
      client_name: 'browser',
      stage_name: 'audit',
      result: 0,
    }))
  }, [dispatch, auditLogType, auditErrorText, userPreferences, auditDescription, auditMessage, auditPs])

  return logToAudit
}

const getDeviceInfo = () => {
  return {
    userAgent: window.navigator.userAgent,
    screenWidth: window?.screen.width,
    screenHeight: window?.screen.height,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  }
}
