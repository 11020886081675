import cn from 'classnames'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { Link } from 'react-router-dom'
import { withAcl } from '@src/hoc'
import './SidebarSubmenuEntry.scss'

class SidebarSubmenuEntry extends Component {
  static propTypes = {
    entry: PropTypes.shape({
      to: PropTypes.string.isRequired,
      label: PropTypes.string,
      children: PropTypes.array,
    }),
    onClick: PropTypes.func,
  }

  isEntryActive = (entry) => {
    const path = window.location.pathname
    return path.startsWith(entry.to)
      || (entry.children && entry.children.find(c => path.startsWith(c.to)))
  }

  render () {
    const { entry, onClick } = this.props

    return (
      <Link
        to={entry.to}
        className={cn('sidebar-submenu-entry', !!this.isEntryActive(entry) && 'active')}
        onClick={onClick}
      >
        <div
          className='sidebar-submenu-label'
          title={entry.label}
        >
          <span>{entry.label}</span>
          {
            !!entry?.children?.length && <div className='sidebar-menu-chevron'>›</div>
          }
        </div>
      </Link>
    )
  }
}

export default withAcl(SidebarSubmenuEntry)
