import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route, withRouter } from 'react-router-dom'
import { loadNsiDict, selectNsiFrnsiDicts } from '@src/modules/nsi/store'
import { AppNav, InternalError404, Loader } from '@src/components'
import { URLS } from '@src/constants'
import { withMntAudit } from '@src/hoc'
import { ContentTab } from './tabs/contentTab/contentTab'
import { PassportTab } from './tabs/passportTab/passportTab'
import './NsiFrnsiDictPage.scss'

class _NsiFrnsiDictPage extends Component {
  static propTypes = {
    refbookId: PropTypes.number,
    nsiFrnsiDicts: PropTypes.object,

    loadNsiDict: PropTypes.func,
  }

  componentDidMount () {
    const { nsiFrnsiDicts, loadNsiDict, refbookId } = this.props
    if (!nsiFrnsiDicts[refbookId]) {
      loadNsiDict({ pathParams: { refbook_identifier: this.props.refbookId } })
    }
  }

  render () {
    const { refbookId, nsiFrnsiDicts } = this.props
    const dict = nsiFrnsiDicts?.[refbookId]?.dict?.payload

    return (
      <div className='root-nsi-frnsi-dict-page'>
        <AppNav
          title={dict?.short_name}
          breadcrumbs={[
            { label: 'Справочники ФРНСИ', link: URLS.NSI__FRNSI },
            { label: dict?.short_name },
          ]}
          tabs={[
            { label: 'Паспорт справочника', link: `${URLS.NSI__FRNSI__DICT}/${refbookId}/passport` },
            { label: 'Содержание', link: `${URLS.NSI__FRNSI__DICT}/${refbookId}/content` },
          ]}
        />
        {
          !dict
            ? <Loader className='loader'/>
            : (
              <Switch>
                <Route path={`${URLS.NSI__FRNSI__DICT}/:refbookId/passport`}>
                  <PassportTab
                    dict={dict}
                  />
                </Route>
                <Route path={`${URLS.NSI__FRNSI__DICT}/:refbookId/content`}>
                  <ContentTab
                    refbookId={refbookId}
                    dict={dict}
                    nsiFrnsiDicts={nsiFrnsiDicts}
                  />
                </Route>
                <Route component={InternalError404}/>
              </Switch>
            )
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const refbookId = Number(ownProps.match.params.refbookId)

  return {
    refbookId,
    nsiFrnsiDicts: selectNsiFrnsiDicts(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadNsiDict: args => dispatch(loadNsiDict(args)),
  }
}

const NsiFrnsiDictPage = withRouter(connect(mapStateToProps, mapDispatchToProps)(_NsiFrnsiDictPage))
export const NsiFrnsiDictPageWithAudit = withMntAudit(NsiFrnsiDictPage)
