import { Form, Input, InputNumber } from 'antd'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useCallback, useContext } from 'react'
import { NOOP_FN } from '@src/utils'
import { modalService } from '@src/services'
import { AccessContext } from '@src/context'
import { Combobox, DatePicker, Icon, Switch } from '@src/components'
import { useConjunctAttributeNode, useConjunctValueDescription, useVDTypeCode, useVDValue } from './FormContext'
import { RefbookValueSelectorDrawer } from './RefbookValueSelectorDrawer/RefbookValueSelectorDrawer'
import { ValueListDrawer } from './ValueListDrawer/ValueListDrawer'

export const ConjunctValue = () => {
  const [type_code] = useVDTypeCode()

  switch (type_code) {
  case 'int': return <ConjunctValueInt />
  case 'float': return <ConjunctValueFloat />
  case 'string': return <ConjunctValueString />
  case 'datetime': return <ConjunctValueDate />
  case 'bool': return <ConjunctValueBool />
  case 'list': return <ConjunctValueList />
  case 'ref_code': return <ConjunctValueRefcode maxSelectCount={1}/>
  case 'array': return <ConjunctValueNull />
  case 'object': return <ConjunctValueNull />
  default: return <ConjunctValueFallback />
  }
}

const ConjunctValueInt = () => {
  const [value, setValue, valueShouldUpdate] = useVDValue()

  return <Form.Item
    noStyle
    shouldUpdate={valueShouldUpdate}
  >
    {() => {
      return <InputNumber
        className='w-100p'
        min={0}
        step={1}
        value={value}
        onChange={evt => setValue(Number(String(evt).replace(/\./g, '')))}
      />
    }}
  </Form.Item>

}

const ConjunctValueFloat = () => {
  const [value, setValue, valueShouldUpdate] = useVDValue()

  return <Form.Item
    noStyle
    shouldUpdate={valueShouldUpdate}
  >
    {() => {
      return <InputNumber
        className='w-100p'
        min={0}
        step={0.1}
        value={value}
        onChange={setValue}
      />
    }}
  </Form.Item>

}

const ConjunctValueString = () => {
  const [value, setValue, valueShouldUpdate] = useVDValue()

  return <Form.Item
    noStyle
    shouldUpdate={valueShouldUpdate}
  >
    {() => {
      return <Input
        value={value}
        onChange={evt => setValue(evt.target.value)}
      />
    }}
  </Form.Item>
}

const ConjunctValueDate = () => {
  const [value, setValue, valueShouldUpdate] = useVDValue()

  return <Form.Item
    noStyle
    shouldUpdate={valueShouldUpdate}
  >
    {() => {
      return <DatePicker
        className='w-100p'
        value={value && moment(value)}
        onChange={v => setValue(v && v.format('YYYY-MM-DD'))}
      />
    }}
  </Form.Item>
}

const ConjunctValueBool = () => {
  const [value, setValue, valueShouldUpdate] = useVDValue()

  return <Form.Item
    noStyle
    shouldUpdate={valueShouldUpdate}
  >
    {() => {
      return <Switch
        size='small'
        onChange={setValue}
        checked={value}
        checkedChildren='Истинно'
        unCheckedChildren='Ложно' />
    }}
  </Form.Item>

}

const ConjunctValueList = () => {
  const attribute_node = useConjunctAttributeNode()

  // Для значения list опираемся на attr_type_code, чтобы понять, выбираем мы значения из справочников, или же просто заполняем массив значений
  switch (attribute_node.attr_type_code) {
  case 'ref_code': return <ConjunctValueListRefcode attribute_node={attribute_node}/> //
  default: return <ConjunctValueListDefault attribute_node={attribute_node}/>
  }
}

const ConjunctValueListRefcode = ({ attribute_node }) => {
  const [vd, setVd, vdShouldUpdate] = useConjunctValueDescription()
  const [type_code] = useVDTypeCode()

  const handleChange = useCallback((values) => {
    setVd({
      value_id: vd.value_id,
      value: values.map(v => ({
        value: v.value,
        value_label: v.view_data,
        type_code: attribute_node.attr_type_code,
      })),
      type_code,
    })
  }, [attribute_node.attr_type_code, setVd, type_code, vd.value_id])

  return <RefcodeSelector
    values={vd.value}
    shouldUpdate={vdShouldUpdate}
    maxSelectCount={Number.MAX_SAFE_INTEGER}
    attribute_node={attribute_node}
    onChange={handleChange}
  />
}

const ConjunctValueListDefault = ({ attribute_node }) => {
  const [value, setValue, valueShouldUpdate] = useVDValue()
  const accessContext = useContext(AccessContext)

  const handleChange = value => setValue(value)

  return <Form.Item
    noStyle
    shouldUpdate={valueShouldUpdate}
  >
    {() => {
      return <Combobox
        open={false}
        className='w-100p'
        removeIcon={null}
        value={value}
        mode='multiple'
        maxTagCount={Number.MAX_SAFE_INTEGER}
        onClick={() => {
          modalService.open(ValueListDrawer, {
            value,
            onAccept: handleChange,
            value_type_code: attribute_node.value_type_code,
            readonly: accessContext.readonly,
          })
        }}
      />
    }}
  </Form.Item>
}

const ConjunctValueNull = () => {
  return <Input readOnly value='Пусто' onChange={NOOP_FN}/>
}

const ConjunctValueFallback = () => {
  const [type_code] = useVDTypeCode()

  return <Input readOnly status='warning' value={`Неподдерживаемый тип значения '${type_code}'`} />
}

const ConjunctValueRefcode = () => {
  const attribute_node = useConjunctAttributeNode()
  const [vd, setVd, vdShouldUpdate] = useConjunctValueDescription()

  const handleChange = useCallback((values) => {
    if (values.length) {
      setVd({
        value_id: vd.value_id,
        value: values[0].value,
        value_label: values[0].view_data,
        type_code: attribute_node.attr_type_code,
      })
    } else {
      setVd({
        value_id: vd.value_id,
        value: null,
        value_label: null,
        type_code: attribute_node.attr_type_code,
      })
    }
  }, [attribute_node.attr_type_code, setVd, vd.value_id])

  return <RefcodeSelector
    values={vd ? [vd] : []}
    shouldUpdate={vdShouldUpdate}
    maxSelectCount={1}
    attribute_node={attribute_node}
    onChange={handleChange}
  />
}

const RefcodeSelector = ({ values, shouldUpdate, maxSelectCount, attribute_node, onChange }) => {
  const accessContext = useContext(AccessContext)
  const isAttributeConfigured = attribute_node.rb_key_field_id && attribute_node.rb_lookup_field && !isEmpty(attribute_node.rb_lookup_list)

  return isAttributeConfigured
    ? <Form.Item
      noStyle
      shouldUpdate={shouldUpdate}
    >
      <Combobox
        open={false}
        className='w-100p'
        mode='multiple'
        value={values.map(v => v.value_label)}
        suffixIcon={<Icon icon='folder_open_solid_16' className='h-16 w-16'/>}
        onClick={() => modalService.open(RefbookValueSelectorDrawer, {
          initialValues: values,
          rb_key_field_id: attribute_node.rb_key_field_id,
          rb_lookup_field: attribute_node.rb_lookup_field,
          rb_lookup_list: attribute_node.rb_lookup_list,
          maxSelectCount,
          onAccept: onChange,
          readonly: accessContext.readonly,
        })}
      />
    </Form.Item>
    : <Input
      readOnly
      value='Атрибут не настроен'
      status='warning'
    />
}
