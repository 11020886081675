import { Icon } from '@src/components'
import { traverseTree } from '@src/utils'

/**
 * @description Наполняет дерево атрибутов иконками, приводит формат ответа к формату компонента TreeSelect
 * Мутирует исходный объект
 */
export const makeAttributeTreeForSelector = (attributesTree, valueTypeList) => {
  // Мапка value_type_code в иконки
  const VALUE_TYPE_CODE_ICONS_MAP = {
    'list': 'invoice_solid_16', // Список
    'int': 'array_numbers_solid_16', // Целое число
    'float': 'array_numbers_outline_16', // Вещественное число
    'string': 'typography_solid_16', // Строка
    'datetime': 'calendar_solid_16', // Дата-время
    'bool': 'puzzle_solid_16', // Логический
    'ref_code': 'folder_open_solid_16', // Справочник
    'array': 'array_solid_16', // Массив JSON
    'object': 'object_solid_16', // Объект
  }

  // Пропсы для иконки атрибута
  const ATTRIBUTE_ICONS_BY_VALUE_TYPE_ID = valueTypeList.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.type_id]: {
        title: cur.caption,
        icon: VALUE_TYPE_CODE_ICONS_MAP[cur.type_code],
      },
    }
  }, {})

  // Мапка типов значений в типы кодов
  const mapValueTypeIdToTypeCode = valueTypeList.reduce((acc, type) => {
    return {
      ...acc,
      [type.type_id]: type.type_code,
    }
  }, {})

  const res = traverseTree(attributesTree, (node) => {
    node.selectable = node.is_selectable
    node.disabled = !node.selectable
    node.value_type_id ??= '4'  // Если value_type_id null или undefined, то по-умолчанию - строка

    // Тип значения атрибута, т.е. чем является сам атрибут
    let attr_type_id = node.value_type_id

    // if(node.is_list)

    // attr_kind, для которых устанавливаем value_type_id = 7(refbook)
    // 0 - Узел дерева атрибутов
    // 1 - Справочник ФНСИ
    // 2 - Иной (внутренний) справочник
    // 3 - Произвольное значение
    const REFBOOK_ATTR_KINDS = [1, 2]
    if (REFBOOK_ATTR_KINDS.includes(node.attr_kind)) {
      attr_type_id = '7' // ref_code
    }

    node.attr_type_id = attr_type_id

    node.attr_type_code = mapValueTypeIdToTypeCode[node.attr_type_id]
    node.value_type_code = mapValueTypeIdToTypeCode[node.value_type_id]

    node.icon =  node.selectable && ATTRIBUTE_ICONS_BY_VALUE_TYPE_ID[node.attr_type_id]?.icon
      ? <Icon
        {...ATTRIBUTE_ICONS_BY_VALUE_TYPE_ID[node.attr_type_id]}
        className='w-14 h-14'
        style={{ verticalAlign: 'middle' }}
      /> : null
  })

  return res
}
