import cn from 'classnames'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ColumnsSettings, Combobox, DatePicker, Icon, Switch, Tag, TextInput } from '@src/components'
import { AUDIT_SOURCE_TYPES, SORT_ORDER, TAG_COLORS } from '@src/constants'
import { useSwitch, useUrlQuery } from '@src/hooks'
import { selectMclListModel } from '@src/modules/dictionaries/store'

export const JournalAuditControls = ({ listModel, onChange, columnOptions, onToggleColumn }) => {
  const [query, setQuery] = useUrlQuery(unpackQuery, packQuery)
  const [mcl, setMcl] = useState(query.mcl || [])
  const [date, setDate] = useState(query.date || moment())
  const [search, setSearch] = useState(query.search || '')
  const [system, switchSystem, setSystem] = useSwitch(query.system || false)
  const [hoursStart, hoursEnd, setHoursStart, setHoursEnd, hoursStartOptions, hoursEndOptions, requestDate, startHour, endHour] = useHoursRange(query.hoursStart, query.hoursEnd, date)
  const mclList = useSelector(selectMclListModel).payload.data

  const mclOptions = useMemo(() => {
    return mclList.map(mcl => ({
      value: mcl.id,
      label: mcl.name,
    }))
  }, [mclList])

  const isValidFilters = useCallback(() => {
    if(search && search.length && search.length < 3) {
      return false
    }
    return true
  }, [search])

  const filter = useMemo(() => {
    return {
      requestDate,
      startHour,
      endHour,
      msgClient: search,
      mcl_id: mcl,
      source_type: [AUDIT_SOURCE_TYPES.UI, ...(system ? [AUDIT_SOURCE_TYPES.API] : [])],
      show_system_call: false,
      control: {
        sorts: [
          {
            field: 'created_on',
            sort: SORT_ORDER.DESC,
          },
        ],
      },
    }
  }, [requestDate, startHour, endHour, search, mcl, system])

  useEffect(() => {
    if (isValidFilters()) {
      setQuery({ mcl, date, search, system, hoursStart, hoursEnd })
    }
  }, [mcl, date, search, system, hoursStart, hoursEnd, isValidFilters, setQuery])

  useEffect(() => {
    if (isValidFilters()) {
      onChange(filter)
    }
  }, [isValidFilters, onChange, filter])

  const resetFilters = useCallback(() => {
    setMcl([])
    setDate(moment())
    setSearch('')
    setSystem(false)
    setHoursStart(9)
    setHoursEnd(18)
  }, [setMcl, setDate, setSearch, setSystem, setHoursStart, setHoursEnd])

  const refreshTable = useCallback(() => {
    onChange(filter)
  }, [filter, onChange])

  return <div className='audit-controls'>
    <div className='control-block w-250'>
      <label>Направление ОМП</label>
      <Combobox
        className='w-100p'
        value={mcl}
        onChange={setMcl}
        options={mclOptions}
        mode='multiple'
        allowClear
        placeholder='Все'
      />
    </div>
    <div className='control-block ml-16'>
      <label>Дата запроса</label>
      <DatePicker
        allowClear={null}
        value={date}
        onChange={setDate}
      />
    </div>
    <div className='control-block ml-16 w-80'>
      <label>От</label>
      <Combobox
        value={hoursStart}
        onChange={setHoursStart}
        options={hoursStartOptions}
      />
    </div>
    <div className='control-block ml-8 w-80'>
      <label>До</label>
      <Combobox
        value={hoursEnd}
        onChange={setHoursEnd}
        options={hoursEndOptions}
      />
    </div>
    <div className='control-block ml-16 w-300'>
      <label>Поиск по тексту</label>
      <TextInput
        value={search}
        onChange={setSearch}
        icon='search'
        allowClear
        isInvalid={t => t.length && t.length < 3}
      />
    </div>
    <div className='control-block ml-16 mr-16'>
      <label>Системные вызовы</label>
      <div className='syscall-switch h-32'>
        <Switch
          checked={system}
          onChange={switchSystem}
        />
      </div>
    </div>

    <div className={cn('list-counter ml-auto h-32', listModel.loading && 'loading')}>
      <span>Всего записей:</span>
      <Tag className='ml-8' color={TAG_COLORS.GREEN_TEA}>{listModel.payload?.data?.length || 0}</Tag>
    </div>

    <div className='controls-actions ml-16 h-32'>
      <Icon
        className='maxw-22 maxh-22'
        icon='reset_filter_16'
        title='Сбросить фильтры'
        onClick={resetFilters}
      />
      <Icon
        className='maxw-18 maxh-18 ml-8'
        icon='update_outline_16'
        title='Обновить'
        onClick={refreshTable}
      />
      <ColumnsSettings
        className='ml-8'
        columnOptions={columnOptions}
        onToggleColumn={onToggleColumn}
      />
    </div>
  </div>
}

const packQuery = (args) => {
  return {
    ...args,
    system: Number(args.system),
    date: args.date.format('DD.MM.YYYY'),
  }
}

const unpackQuery = (args) => {
  return {
    search: args.search?.[0],
    system: args.system?.[0] ? Boolean(Number(args.system[0])) : undefined,
    hoursStart: args.hoursStart?.[0] ? Number(args.hoursStart[0]) : undefined,
    hoursEnd: args.hoursEnd?.[0] ? Number(args.hoursEnd[0]) : undefined,
    date: args.date?.[0] ? moment(args.date[0], 'DD.MM.YYYY') : undefined,
    mcl: args.mcl?.[0] ? args.mcl[0].split(',').map(Number) : undefined,
  }
}

const useHoursRange = (initialStart = 9, initialEnd = 18, date) => {
  const [start, setStart] = useState(initialStart)
  const [end, setEnd] = useState(initialEnd)

  const startOptions = useMemo(() => {
    return new Array(25).fill().map((e, index) => ({
      disabled: index >= end,
      value: index,
      label: String(index).padStart(2, '0') + ':00',
    }))
  }, [end])

  const endOptions = useMemo(() => {
    return new Array(25).fill().map((e, index) => ({
      disabled: index <= start,
      value: index,
      label: String(index).padStart(2, '0') + ':00',
    }))
  }, [start])

  const [requestDate, startHour, endHour] = useMemo(() => {
    const local = date.startOf('day').hours(start)
    // Локальное время преобразуем в диапазон по utc, тк в бд все данные хранятся в utc
    const diff = end - start
    const offset = Math.trunc(local.utcOffset() / 60)

    const requestDate = local.format('YYYY-MM-DD')
    const startHour = local.hours() - offset
    // Параметр endHour в API работает как "До, включительно"
    // Поэтому, чтобы выбрать записи строго До указанного часа, нужно отнять 1
    const endHour = startHour + diff - 1

    return [requestDate, startHour, endHour]
  }, [start, end, date])

  return [start, end, setStart, setEnd, startOptions, endOptions, requestDate, startHour, endHour]
}
