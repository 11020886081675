import { ActionMenu, Button, IconWithArea, IfNotReadonly } from '@src/components'
import { cn } from '@src/utils'
import './ConditionsList.scss'

export const ConditionsList = ({ conditionList, selectedCondition, onSelectCondition, onAddCondition, onCopyCondition, onDeleteCondition }) => {

  return <div className='root-reg-conditions-list scroll-y'>
    {
      conditionList?.length
        ? conditionList.map(condition => <ConditionListEntry
          key={condition.number_condition}
          selected={selectedCondition?.number_condition === condition.number_condition}
          condition={condition}
          onDeleteCondition={onDeleteCondition}
          onSelectCondition={onSelectCondition}
        />)
        : null
    }
    <IfNotReadonly>
      <div className='button-board'>
        <Button
          label='Скопировать условие'
          icon='copy'
          onClick={onCopyCondition}
        />
        <Button
          isSuccess
          className='ml-auto'
          label='Добавить новое условие'
          icon='plus_cr_fr_white'
          disabled={!!conditionList?.find(cond => !cond.number_condition_id)}
          onClick={onAddCondition}
        />
      </div>
    </IfNotReadonly>
  </div>
}

const ConditionListEntry = ({ condition, selected, onDeleteCondition, onSelectCondition }) => {

  return <div
    className={cn('root-condition-list-entry', selected  && 'selected', !condition.number_condition_id && 'condition-new')}
    onClick={() => onSelectCondition(condition)}
  >
    <span>Региональное условие {condition.number_condition}</span>
    <IfNotReadonly>
      <ActionMenu options={[
        { label: 'Удалить', icon: 'trash_can', onClick: () => onDeleteCondition(condition) },
      ]}>
        <IconWithArea className='ml-auto' icon='three_dots' onClick={e => e.stopPropagation()}/>
      </ActionMenu>
    </IfNotReadonly>
  </div>
}
