import { TextInput } from '@src/components'
import { REGISTER_COLUMNS, SORT_ORDER } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class FullnameColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.FULLNAME

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Фамилия Имя Отчество',
        component: TextInput,
        defaultValue: '',
        props: {
          icon: 'search',
          allowedChars: /[А-Яа-я\- ]/,
          isInvalid: fullname => fullname && fullname.length !== 0 && fullname.length < 3,
          allowClear: true,
        },
      },
      width: 350,
      dataIndex: 'fullname',
      dataRender: fullname => fullname ? fullname : '-',
      sort: true,
      defaultSort: SORT_ORDER.ASC,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let requestFilters = []

    if (filters[this.type]) {
      requestFilters = [{ field: 'fullname', value: filters?.fullname, operator: 'like_both', invalid: false }]
    }

    if (nullSearches[this.type]) {
      requestFilters = [{ field: this.type, value: null, operator: '=', invalid: false }]
    }

    return {
      control: {
        filters: requestFilters,
      },
    }
  }
}
