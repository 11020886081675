import { ReactComponent as medical } from './icons/medical.svg'
import { ReactComponent as bag } from './icons/bag.svg'
import { ReactComponent as analytics } from './icons/analytics.svg'
import { ReactComponent as admin } from './icons/admin.svg'
import { ReactComponent as logo } from './icons/logo.svg'
import { ReactComponent as user } from './icons/user.svg'
import { ReactComponent as logout } from './icons/logout.svg'
import { ReactComponent as arrow_up } from './icons/arrow_up.svg'
import { ReactComponent as arrow_left } from './icons/arrow_left.svg'
import { ReactComponent as arrow_right } from './icons/arrow_right.svg'
import { ReactComponent as bell } from './icons/bell.svg'
import { ReactComponent as mail } from './icons/mail.svg'
import { ReactComponent as backward } from './icons/backward_02.svg'
import { ReactComponent as file_text } from './icons/file_text_01.svg'
import { ReactComponent as vector_130 } from './icons/vector 130.svg'
import { ReactComponent as search } from './icons/search.svg'
import { ReactComponent as chevron } from './icons/chevron_left_16_M.svg'
import { ReactComponent as edit } from './icons/edit.svg'
import { ReactComponent as eye } from './icons/eye.svg'
import { ReactComponent as del } from './icons/delete.svg'
import { ReactComponent as add } from './icons/add.svg'
import { ReactComponent as doc } from './icons/doc.svg'
import { ReactComponent as check_cr_fr } from './icons/check_cr_fr.svg'
import { ReactComponent as plus_cr_fr } from './icons/plus_cr_fr.svg'
import { ReactComponent as check_cr_fr_white } from './icons/check_cr_fr_white.svg'
import { ReactComponent as spinner_small } from './icons/spinner_small.svg'
import { ReactComponent as protocol } from './icons/protocol.svg'
import { ReactComponent as history } from './icons/history.svg'
import { ReactComponent as print } from './icons/print.svg'
import { ReactComponent as electrical_plug } from './icons/electrical_plug.svg'
import { ReactComponent as copy } from './icons/copy.svg'
import { ReactComponent as eraser } from './icons/eraser.svg'
import { ReactComponent as close } from './icons/close.svg'
import { ReactComponent as close_cr_fr } from './icons/close_cr_fr.svg'
import { ReactComponent as imports } from './icons/import.svg'
import { ReactComponent as exports } from './icons/export.svg'
import { ReactComponent as sortIcon } from './icons/sort.svg'
import { ReactComponent as search_color_light } from './icons/search_color_light.svg'
import { ReactComponent as spinner } from './icons/spinner.svg'
import { ReactComponent as file_copy } from './icons/file_copy.svg'
import { ReactComponent as trash_can } from './icons/trash_can.svg'
import { ReactComponent as link } from './icons/link.svg'
import { ReactComponent as info_cr_fr } from './icons/info_cr_fr.svg'
import { ReactComponent as import_white } from './icons/import_white.svg'
import { ReactComponent as plus_cr_fr_white } from './icons/plus_cr_fr_white.svg'
import { ReactComponent as sync_16_regular } from './icons/sync_16_regular.svg'
import { ReactComponent as edit_green } from './icons/edit_green.svg'
import { ReactComponent as trash_can_green } from './icons/trash_can_green.svg'
import { ReactComponent as exclamation_mark_cr_fr } from './icons/exclamation_mark_cr_fr.svg'
import { ReactComponent as triangle_dovn_16_s } from './icons/triangle_dovn_16_s.svg'
import { ReactComponent as triangle_dovn_16_s_gray_60 } from './icons/triangle_dovn_16_s_gray_60.svg'
import { ReactComponent as notification_error } from './icons/notification_error.svg'
import { ReactComponent as notification_warning } from './icons/notification_warning.svg'
import { ReactComponent as notification_info } from './icons/notification_info.svg'
import { ReactComponent as notification_success } from './icons/notification_success.svg'
import { ReactComponent as notification_close } from './icons/notification_close.svg'
import { ReactComponent as route } from './icons/route.svg'
import { ReactComponent as printer } from './icons/printer.svg'
import { ReactComponent as zoom_in } from './icons/zoom_in.svg'
import { ReactComponent as zoom_out } from './icons/zoom_out.svg'
import { ReactComponent as calendar } from './icons/calendar.svg'
import { ReactComponent as sort_regular } from './icons/sort_regular.svg'
import { ReactComponent as sort_desc } from './icons/sort_desc.svg'
import { ReactComponent as sort_asc } from './icons/sort_asc.svg'
import { ReactComponent as logo_risar_normal } from './icons/logo_risar_normal.svg'
import { ReactComponent as more_cr_fr } from './icons/more_cr_fr.svg'
import { ReactComponent as play_16_regular } from './icons/play_16_regular.svg'
import { ReactComponent as history_green } from './icons/history_green.svg'
import { ReactComponent as play_green } from './icons/play_green.svg'
import { ReactComponent as edit_all_green } from './icons/edit_all_green.svg'
import { ReactComponent as people_16_outline } from './icons/people_16_outline.svg'
import { ReactComponent as update_outline_16 } from './icons/update-outline-16.svg'
import { ReactComponent as reset_filter_16 } from './icons/reset-filter-16.svg'
import { ReactComponent as cancel_16_m } from './icons/cancel-16-m.svg'
import { ReactComponent as null_icon } from './icons/null.svg'
import { ReactComponent as pencil_green } from './icons/pencil_green.svg'

import { ReactComponent as plus_cr_fr_gray } from './icons/plus_cr_fr_gray.svg'
import { ReactComponent as minus_sq_fr_gray } from './icons/minus_sq_fr_gray.svg'
import { ReactComponent as plus_sq_fr_1 } from './icons/plus_sq_fr_1.svg'
import { ReactComponent as minus_sq_fr } from './icons/minus_sq_fr.svg'
import { ReactComponent as medical_chart_normal } from './icons/menu/medical_chart_normal.svg'
import { ReactComponent as medical_chart_hover_active } from './icons/menu/medical_chart_hover_active.svg'
import { ReactComponent as medical_case_normal } from './icons/menu/medical_case_normal.svg'
import { ReactComponent as medical_case_hover_active } from './icons/menu/medical_case_hover_active.svg'
import { ReactComponent as line_02_up_normal } from './icons/menu/line_02_up_normal.svg'
import { ReactComponent as line_02_up_hover_active } from './icons/menu/line_02_up_hover_active.svg'
import { ReactComponent as lock_normal } from './icons/menu/lock_normal.svg'
import { ReactComponent as lock_hover_active } from './icons/menu/lock_hover_active.svg'
import { ReactComponent as menu_open } from './icons/menu/menu_open.svg'
import { ReactComponent as menu_close } from './icons/menu/menu_close.svg'
import { ReactComponent as folder_list_normal } from './icons/menu/folder_list_normal.svg'
import { ReactComponent as folder_list_hover_active } from './icons/menu/folder_list_hover_active.svg'
import { ReactComponent as settings } from './icons/settings.svg'
import { ReactComponent as ellipse } from './icons/ellipse.svg'
import { ReactComponent as ellipse_in_rhombus } from './icons/ellipse_in_rhombus.svg'
import { ReactComponent as plus_in_rhombus } from './icons/plus_in_rhombus.svg'
import { ReactComponent as ellipse_bold } from './icons/ellipse_bold.svg'
import { ReactComponent as rhombus } from './icons/ellipse_bold.svg'
import { ReactComponent as file_search_ou_lc } from './icons/file_search_ou_lc.svg'
import { ReactComponent as three_dots } from './icons/three_dots.svg'
import { ReactComponent as equals_outline_16 } from './icons/equals_outline_16.svg'
import { ReactComponent as notequals_outline_16 } from './icons/notequals_outline_16.svg'
import { ReactComponent as greater_outline_16 } from './icons/greater_outline_16.svg'
import { ReactComponent as less_outline_16 } from './icons/less_outline_16.svg'
import { ReactComponent as greaterequals_outline_16 } from './icons/greaterequals_outline_16.svg'
import { ReactComponent as lessequals_outline_16 } from './icons/lessequals_outline_16.svg'
import { ReactComponent as cancel_s_outline_16 } from './icons/cancel_s_outline_16.svg'
import { ReactComponent as puzzle_outline_16 } from './icons/puzzle_outline_16.svg'
import { ReactComponent as list_unordered_outline_16 } from './icons/list_unordered_outline_16.svg'
import { ReactComponent as typography_solid_16 } from './icons/typography_solid_16.svg'
import { ReactComponent as calendar_m_solid_16 } from './icons/calendar_m_solid_16.svg'
import { ReactComponent as array_numbers_solid_16 } from './icons/array_numbers_solid_16.svg'
import { ReactComponent as calendar_solid_16 } from './icons/calendar_solid_16.svg'
import { ReactComponent as invoice_solid_16 } from './icons/invoice_solid_16.svg'
import { ReactComponent as array_numbers_outline_16 } from './icons/array_numbers_outline_16.svg'
import { ReactComponent as array_outline_16 } from './icons/array_outline_16.svg'
import { ReactComponent as folder_solid_16 } from './icons/folder_solid_16.svg'
import { ReactComponent as folder_open_outline_16 } from './icons/folder_open_outline_16.svg'
import { ReactComponent as folder_open_solid_16 } from './icons/folder_open_solid_16.svg'
import { ReactComponent as object_outline_16 } from './icons/object_outline_16.svg'
import { ReactComponent as array_solid_16 } from './icons/array_solid_16.svg'
import { ReactComponent as puzzle_solid_16 } from './icons/puzzle_solid_16.svg'
import { ReactComponent as object_solid_16 } from './icons/object_solid_16.svg'
import { ReactComponent as location_gray } from './icons/location_gray.svg'
import { ReactComponent as in_list } from './icons/in_list.svg'
import { ReactComponent as not_in_list } from './icons/not_in_list.svg'
import { ReactComponent as operation_exists } from './icons/operation_exists.svg'
import { ReactComponent as operation_not_exists } from './icons/operation_not_exists.svg'

import { ReactComponent as error_400 } from './icons/error_400.svg'
import { ReactComponent as error_403 } from './icons/error_403.svg'
import { ReactComponent as error_404 } from './icons/error_404.svg'
import { ReactComponent as error_500 } from './icons/error_500.svg'
import { ReactComponent as error_503 } from './icons/error_503.svg'
import { ReactComponent as error_button_icon } from './icons/error_button_icon.svg'

const icons = {
  medical,
  bag,
  analytics,
  admin,
  logo,
  user,
  logout,
  arrow_up,
  arrow_left,
  arrow_right,
  bell,
  mail,
  backward,
  file_text,
  vector_130,
  search,
  chevron,
  edit,
  eye,
  del,
  add,
  check_cr_fr,
  plus_cr_fr,
  check_cr_fr_white,
  spinner_small,
  doc,
  protocol,
  history,
  print,
  electrical_plug,
  close,
  copy,
  eraser,
  equals_outline_16,
  notequals_outline_16,
  greater_outline_16,
  less_outline_16,
  greaterequals_outline_16,
  lessequals_outline_16,
  imports,
  close_cr_fr,
  exports,
  sortIcon,
  search_color_light,
  spinner,
  file_copy,
  trash_can,
  link,
  info_cr_fr,
  import_white,
  plus_cr_fr_white,
  sync_16_regular,
  plus_cr_fr_gray,
  edit_green,
  trash_can_green,
  exclamation_mark_cr_fr,
  triangle_dovn_16_s,
  triangle_dovn_16_s_gray_60,
  notification_error,
  notification_warning,
  notification_info,
  notification_success,
  notification_close,
  route,
  printer,
  zoom_in,
  zoom_out,
  calendar,
  sort_regular,
  sort_desc,
  sort_asc,
  more_cr_fr,
  play_16_regular,
  history_green,
  play_green,
  edit_all_green,
  people_16_outline,
  settings,
  ellipse,
  ellipse_in_rhombus,
  plus_in_rhombus,
  ellipse_bold,
  rhombus,
  update_outline_16,
  cancel_16_m,
  reset_filter_16,
  file_search_ou_lc,
  three_dots,
  drop_out_select: [plus_cr_fr_gray, minus_sq_fr_gray],
  table_icon: [plus_sq_fr_1, minus_sq_fr],
  medical_chart: [medical_chart_normal, medical_chart_hover_active],
  medical_case: [medical_case_normal, medical_case_hover_active],
  line_02_up: [line_02_up_normal, line_02_up_hover_active],
  lock: [lock_normal, lock_hover_active],
  menu: [menu_open, menu_close],
  folder_list: [folder_list_normal, folder_list_hover_active],
  method: [medical_chart_normal, medical_chart_hover_active],
  logo_risar: [logo_risar_normal, logo_risar_normal],
  bam: [people_16_outline, people_16_outline],
  error_400,
  error_403,
  error_404,
  error_500,
  error_503,
  error_button_icon,
  null_icon,
  pencil_green,
  cancel_s_outline_16,
  puzzle_outline_16,
  list_unordered_outline_16,
  typography_solid_16,
  calendar_m_solid_16,
  array_numbers_solid_16,
  invoice_solid_16,
  calendar_solid_16,
  array_numbers_outline_16,
  array_outline_16,
  folder_solid_16,
  folder_open_outline_16,
  folder_open_solid_16,
  object_outline_16,
  array_solid_16,
  puzzle_solid_16,
  object_solid_16,
  minus_sq_fr_gray,
  location_gray,
  in_list,
  not_in_list,
  operation_exists,
  operation_not_exists,
}

export {
  icons,
}
