import cn from 'classnames'
import { isArray, isUndefined, mergeWith } from 'lodash'
import moment from 'moment'
import config from '@src/config'

export const getAuthParams = ({ redirectSuffix }) => {
  const query = new URLSearchParams(window.location.search)
  query.delete('code')
  const queryString = query.toString()

  return {
    grant_type: 'authorization_code',
    response_type: 'code',
    client_id: config.api.auth.client_id,
    redirect_uri: `${config.api.auth.redirect_uri}${redirectSuffix || ''}${queryString ? `?${queryString.toString()}` : ''}`,
  }
}

export const getRefreshParams = ({ redirectSuffix }) => {
  return {
    grant_type: 'refresh_token',
    client_id: config.api.auth.client_id,
    redirect_uri: `${config.api.auth.redirect_uri}${redirectSuffix || ''}`,
  }
}

export const getLogoutParams = () => {
  return {
    client_id: config.api.auth.client_id,
    post_logout_redirect_uri: config.api.auth.redirect_uri,
  }
}

export const getAuthUri = ({ redirectSuffix }) => {
  return `${config.api.auth.am_uri}?${new URLSearchParams(getAuthParams({ redirectSuffix })).toString()}`
}

export const formatSnils = (s) => {
  if (!s) return s
  if(s.length < 11)
    return s
  else
    return `${s[0]}${s[1]}${s[2]}-${s[3]}${s[4]}${s[5]}-${s[6]}${s[7]}${s[8]} ${s[9]}${s[10]}`
}

export const normalizeStr = str => typeof str === 'string' ?  str.toLowerCase().replace(/\s/g, '') : ''

export const toLowerCaseWithoutSpaces = str => typeof str === 'string' ? normalizeStr(str) : str

export const getFullname  = ({ firstname, lastname, patronymic } = {}) => {
  return [lastname, firstname, patronymic].filter(e => !!e).join(' ')
}

/**
 * @function isValidMomentDate
 * @param {Object} date Moment Date @see{@link https://momentjscom.readthedocs.io/en/latest/moment/01-parsing/15-is-valid/#invalid-moments}
 * @param {*} r Returned value
 * @description Check moment date to valid and return {r} or null
 */
export const isValidMomentDate = (date, r=null) => !isUndefined(date) && moment(date)._isValid ? moment(date) : r

export const momentToISOString = (date, r=null) => !isUndefined(date) && moment(date)._isValid ? moment(date).toISOString() : r

export const stripSnils = snils => snils ? String(snils).replace(/[#\s-_ ]/g, '') : null

/**
 * @description Делает merge двух объектов, конкатенируя вложенные массивы
 */
export const concatObjects = (object, ...sources) => mergeWith(object, ...sources, (a, b) => { if(isArray(a)) return a.concat(b) })

/**
 * @description Форматирует единицу времени с учетом склонения
 */
export const relativeTimeWithPlural = (number, key) => {
  var format = {
    ss: 'секунда_секунды_секунд',
    mm: 'минута_минуты_минут',
    hh: 'час_часа_часов',
    dd: 'день_дня_дней',
    ww: 'неделя_недели_недель',
    MM: 'месяц_месяца_месяцев',
    yy: 'год_года_лет',
  }
  return number + ' ' + plural(format[key], +number)
}

function plural (word, num) {
  var forms = word.split('_')
  return num % 10 === 1 && num % 100 !== 11
    ? forms[0]
    : num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)
      ? forms[1]
      : forms[2]
}

export const getRange = (rowCount, chunkSize=100) => ({ chunk_start: !rowCount ? rowCount : rowCount + 1, chunk_end: rowCount + chunkSize - 1 })

export const NOOP_FN = () => {}

export * from './lib/objectShallowCompare'
export * from './lib/propTypes'
export * from './lib/reduxUtils'
export * from './lib/traverseTree'
export * from './lib/findOneInTree'
export { cn }
