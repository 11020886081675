import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { Table, Tag } from '@src/components'
import { TAG_COLORS } from '@src/constants'

import { withMntAudit } from '@src/hoc'
import './ProcessingTab.scss'

const ProcessingTab = ({ processingLog, showModal }) => {

  const headers = useMemo(() => {
    return [
      {
        field: 'created_on',
        headerName: 'Дата создания',
        width: 80,
      },
      {
        field: 'stage_name',
        headerName: 'Стадия обработки',
        width: 200,
      },
      {
        field: 'description',
        headerName: 'Лог клиента',
        width: 200,
      },
      {
        field: 'internal_description',
        headerName: 'Лог системы',
        width: 100,
      },
      {
        field: 'log_type',
        headerName: 'Тип лога',
        width: 50,
      },
    ]
  }, [])

  const getLogComponent = (logType) => {
    switch(logType) {
    case 'Err': return <Tag color={TAG_COLORS.ALMOND_CRAIOLA}>{logType}</Tag>
    case 'Ok': return <Tag color={TAG_COLORS.DUSTY_BLUE}>{logType}</Tag>
    case 'Bad': return <Tag color={TAG_COLORS.BEIGE}>{logType}</Tag>

    default: return logType
    }
  }

  const renderLongData = useCallback((data) => {
    return data && data.length > 75
      ? () => <div className='table-action' onClick={() => showModal(data)}>Просмотр</div>
      : data
  }, [showModal])

  const rows = useMemo(() => {
    return processingLog.map(log => ({
      created_on: log.created_on
        ? moment(log.created_on).local().format('DD.MM.YYYY HH:mm:ss')
        : '-',
      stage_name: log.stage_name,
      description: renderLongData(log.description),
      internal_description: renderLongData(log.internal_description),
      log_type: () => getLogComponent(log.log_type),
    }))
  }, [processingLog, renderLongData])

  return (
    <div className='root-journal-semd-details-processing-tab'>
      <Table
        headers={headers}
        rows={rows}
      ></Table>
    </div>
  )
}

export const ProcessingTabWithAudit = withMntAudit(ProcessingTab)
