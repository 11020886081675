import { selectDictMoModel } from '@src/modules/dictionaries/store'
import { Combobox } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class TmsLabMoColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.TMS_LAB_MO

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Название лаборатории ТМС',
        component: Combobox,
        props: {
          allowClear: true,
          mode: 'multiple',
          options: selectDictMoModel(store.getState()).payload.data.map(mo => ({
            label: mo.name_short,
            value: mo.mo_id,
            title: mo.name_full,
          })),
        },
        defaultValue: [],
      },
      width: 300,
      dataIndex: 'tms_lab_mo_name',
      dataRender: tms_lab_mo_name => tms_lab_mo_name ? tms_lab_mo_name : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let requestFilters = []

    if (filters?.tms_lab_mo_oid && filters?.tms_lab_mo_oid.length) {
      requestFilters = [{ field: 'tms_lab_mo_oid', value: filters.tms_lab_mo_oid, operator: '=', invalid: false, cast: 'right-any' }]
    }

    if (nullSearches[this.type]) {
      requestFilters = [{ field: this.type, operator: '=', value: null, invalid: false }]
    }

    return {
      control: {
        filters: requestFilters,
      },
    }
  }
}
