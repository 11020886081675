import { RangeInput } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class PatientAgeColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.PATIENT_AGE

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Возраст',
        component: RangeInput,
        defaultValue: ['', ''],
      },
      width: 200,
      dataIndex: 'patient_age',
      dataRender: patient_age => patient_age ? patient_age : '-',
      sort: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters }) {
    return {
      control: {
        filters: [
          ...(filters?.[REGISTER_COLUMNS.PATIENT_AGE]?.[0]
            ? [{ field: 'patient_age', value: filters[REGISTER_COLUMNS.PATIENT_AGE][0], operator: '>=', invalid: false }] : []),
          ...(filters?.[REGISTER_COLUMNS.PATIENT_AGE]?.[1]
            ? [{ field: 'patient_age', value: filters[REGISTER_COLUMNS.PATIENT_AGE][1], operator: '<=', invalid: false }] : []),
        ],
      },
    }
  }
}
