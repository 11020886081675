import { Component } from 'react'
import { connect } from 'react-redux'
import { PropTypes } from '@src/utils'
import { AUDIT_PS } from '@src/constants'
import { withAudit } from '@src/hoc/withAudit/withAudit'
import { Alert } from '@src/modals'
import { modalService } from '@src/services'
import {
  loadAppointmentsByRoleId, loadOrgsByMclIdByTerritoryLevel, selectAdminRolesListModel, selectAdminUpsertUserRoleModel, selectAppointmentsByRoleId,
  selectOrgsByMclIdByTerritoryLevel, upsertUserRole, selectRolesByUserId, loadAdminUserRoles, setMainRole, selectAdminSetMainRoleModel,
} from '@src/modules/admin/store'
import { AssignRoleDrawer } from './AssignRoleDrawer'

const initialState = {
  selectedRole: null,
  selectedOrgId: null,
  selectedAppointmentId: null,
  selectedIsMain: false,
  error: null,
}

class _AssignRoleDrawerContainer extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    accessProfile: PropTypes.object,
    user: PropTypes.object,
    open: PropTypes.bool,

    adminRolesList: PropTypes.array,
    orgsByMclIdByTerritoryLevel: PropTypes.object,
    appointmentsByRoleId: PropTypes.object,
    upsertUserRole: PropTypes.func,
    loadOrgsByMclIdByTerritoryLevel: PropTypes.func,
    loadAppointmentsByRoleId: PropTypes.func,
    adminUpsertUserRoleModel: PropTypes.object,
    logToAudit: PropTypes.func,
    rolesByUserId: PropTypes.object,
  }

  constructor (props) {
    super(props)

    this.state = initialState
  }

  componentDidMount = async () => {
    if (!this.props.accessProfile)
      return false

    const role = this.props.adminRolesList.find(r => Number(r.id) === Number(this.props.accessProfile.role_id))

    await this.setState({
      selectedRole: role,
      selectedOrgId: this.props.accessProfile.mo_path,
      selectedAppointmentId: this.props.accessProfile.appointment_id,
      selectedIsMain: this.props.accessProfile.is_main,
    })

    await this.handleRoleChange(role.id)

    await this.setState({
      selectedRole: role,
      selectedOrgId: this.props.accessProfile.mo_path,
      selectedAppointmentId: this.props.accessProfile.appointment_id,
      selectedIsMain: this.props.accessProfile.is_main,
    })
  }

  loadRoles = () => this.props.loadAdminUserRoles({ args: { user_id: this.props.user.id } })

  handleSave = async () => {
    const arg = {
      id: this.props.accessProfile?.id || null,
      role_id: this.state.selectedRole.id,
      appointment_id: this.state.selectedAppointmentId,
      work_place_id: this.state.selectedOrgId,
      access_area: [this.state.selectedOrgId],
      user_id: this.props.user.id,
    }

    const is_main = this.state.selectedIsMain

    this.props.logToAudit({
      auditPs: AUDIT_PS.ADMIN,
      auditMessage: this.props.accessProfile ? 'Редактирование роли пользователя' : 'Добавление роли пользователю',
      auditDescription: { arg },
    })
    await this.props.upsertUserRole({ args: arg })

    if (this.props.adminUpsertUserRoleModel.error)
      this.setState({ error: this.props.adminUpsertUserRoleModel.error })
    else {

      if (is_main) {
        // Если был установлен признак "Главная роль"

        let main_role_arg = null

        if (!this.props.accessProfile) {
          // Если новая роль
          main_role_arg = {
            id: this.props.adminUpsertUserRoleModel.payload?.data?.[0]?.id,
            user_id: arg.user_id,
          }
        } else if (!this.props.accessProfile.is_main) {
          // Если роль отредактирована и был добавлен признак "главная"
          main_role_arg = {
            id: arg.id,
            user_id: arg.user_id,
          }

        }

        if (main_role_arg) {
          this.props.logToAudit({
            auditPs: AUDIT_PS.ADMIN,
            auditMessage: `Роль '${this.state.selectedRole.role_name}' назначена главной для пользователя ${this.props.user.fio}`,
            auditDescription: { arg: main_role_arg },
          })

          await this.props.setMainRole({ args: main_role_arg })
          if (this.props.adminSetMainRoleModel.error) {
            this.setState({ error: this.props.adminSetMainRoleModel.error })
            return
          }
        }
      }
      this.loadRoles()
      this.props.onClose()
    }
  }

  handleRoleChange = async (roleId) => {
    await this.setState({
      selectedRole: this.props.adminRolesList.find(r => r.id === roleId),
      selectedAppointmentId: null,
      selectedOrgId: null,
    })

    if (!this.orgs)
      this.props.loadOrgsByMclIdByTerritoryLevel({
        mclId: this.state.selectedRole.mcl_profile_id,
        territoryLevel: this.getTerritoryLevelByResponseLevel(this.state.selectedRole.response_level),
      })

    if (!this.props.appointmentsByRoleId[this.state.selectedRole.id])
      this.props.loadAppointmentsByRoleId({ roleId: this.state.selectedRole.id })
  }

  // VIMISVIMIS-12358
  getTerritoryLevelByResponseLevel = (response_level) => {
    if(response_level === 'Уровень субъект РФ')
      return 2
    else
      return 3
  }

  // VIMISVIMIS-10632
  getOrgsFilterByResponseLevel = (response_level) => {
    switch (response_level) {
    case 'Уровень субъект РФ':
      return /^\d+\.\d+\.\d+$/
    case 'Уровень муниципального образования':
    case 'Уровень МО':
      return /^\d+\.\d+\.\d+\.\d+$/
    default:
      return /./
    }
  }

  get appointmentSelectorOptions () {
    return this.props.appointmentsByRoleId?.[this.state.selectedRole?.id]?.loaded
      ? this.props.appointmentsByRoleId[this.state.selectedRole.id].payload.data.map(app => ({ label: app.name, value: app.id }))
      : []
  }

  get orgSelectorOptions () {
    return this.orgs?.loaded
      ? this.orgs.payload.data
        .filter(org => org[2].search(this.getOrgsFilterByResponseLevel(this.state.selectedRole.response_level)) === 0)
        .map(org => ({ label: org[1], value: org[2] }))
      : []
  }

  get roleSelectorOptions () {
    return this.props.adminRolesList.map(r => ({
      label: `${r.role_name} (${r.response_level}/${r.mcl_short_name})`,
      value: r.id,
    }))
  }

  get orgs () {
    const mclId = this.state.selectedRole?.mcl_profile_id
    const territoryLevel = this.getTerritoryLevelByResponseLevel(this.state.selectedRole?.response_level)
    return this.props.orgsByMclIdByTerritoryLevel?.[mclId]?.[territoryLevel]
  }

  handleRemoveRoleClick = () => {
    modalService.open(Alert, {
      warn: true,
      onOk: this.handleRemoveRole,
      title: 'Удаление роли',
      children: `Удалить роль: ${this.state.selectedRole.role_name}?`,
      okText: 'Удалить',
    })
  }

  render () {
    return (
      <AssignRoleDrawer
        userLogin={this.props.user.login}

        selectedRole={this.state.selectedRole}
        onRoleChange={this.handleRoleChange}
        roleSelectorOptions={this.roleSelectorOptions}
        edit={!!this.props.accessProfile}

        orgId={this.state.selectedOrgId}
        orgSelectorOptions={this.orgSelectorOptions}
        onOrgChange={e => this.setState({ selectedOrgId: e })}
        isLoadingOrgs={!!this.orgs?.loading}

        appointmentId={this.state.selectedAppointmentId}
        appointmentSelectorOptions={this.appointmentSelectorOptions}
        onAppointmentChange={e => this.setState({ selectedAppointmentId: e })}
        isLoadingAppointments={this.props.appointmentsByRoleId?.[this.state.selectedRole?.id]?.loading}

        isMain={this.state.selectedIsMain}
        onIsMainChange={e => this.setState({ selectedIsMain: e })}
        isIsMainDisabled={this.props.accessProfile?.is_main}

        isSaveInProgress={this.props.adminUpsertUserRoleModel.loading || this.props.adminSetMainRoleModel.loading}
        handleRemoveRoleClick={this.handleRemoveRoleClick}
        onSave={this.handleSave}
        onClose={this.props.onClose}
        open={this.props.open}
        error={this.state.error}
      />
    )
  }
}

const mapStateToProps = state => ({
  adminRolesList: selectAdminRolesListModel(state).payload.data,
  orgsByMclIdByTerritoryLevel: selectOrgsByMclIdByTerritoryLevel(state),
  appointmentsByRoleId: selectAppointmentsByRoleId(state),
  adminUpsertUserRoleModel: selectAdminUpsertUserRoleModel(state),
  adminSetMainRoleModel: selectAdminSetMainRoleModel(state),
  rolesByUserId: selectRolesByUserId(state),
})

const mapDispatchToProps = dispatch => ({
  upsertUserRole: args => dispatch(upsertUserRole(args)),
  setMainRole: args => dispatch(setMainRole(args)),
  loadOrgsByMclIdByTerritoryLevel: args => dispatch(loadOrgsByMclIdByTerritoryLevel(args)),
  loadAppointmentsByRoleId: args => dispatch(loadAppointmentsByRoleId(args)),
  loadAdminUserRoles: args => dispatch(loadAdminUserRoles(args)),
})

const AssignRoleDrawerContainer = connect(mapStateToProps, mapDispatchToProps)(_AssignRoleDrawerContainer)
export const AssignRoleDrawerWithAudit = withAudit(AssignRoleDrawerContainer)
