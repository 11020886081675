import { ErrorText } from '../ErrorText/ErrorText'
import { Loader } from '../Loader/Loader'

export const LoadedOrError = ({ loaded, loading, error, children }) => {
  return error
    ? <ErrorText>Ошибка: {error}</ErrorText>
    : loading
      ? <Loader />
      : loaded
        ? <>{ children }</>
        : null
}
