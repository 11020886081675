import { initialModel } from '@src/utils'
import * as models from './models'

export const initialClinrecTableState  = {
  [models.METHODOLOGY_DOCUMENT]: initialModel,
  [models.CR_LIST]: initialModel,
  [models.CR_LIST_COUNT]: initialModel,
}

export const initialClinrecCardState  = {
  [models.THESIS_LIST]: initialModel,
  [models.REVISION_LIST]: initialModel,
  [models.REVISION_DATA]: initialModel,
}

export const initialNpaTableState = {
  [models.METHODOLOGY_DOCUMENT]: initialModel,
  [models.NPA_LIST]: initialModel,
  [models.NPA_LIST_COUNT]: initialModel,
}

export const initialNpaCardState = {
  [models.GRAPH_LIST]: initialModel,
  [models.REVISION_LIST]: initialModel,
  [models.REVISION_DATA]: initialModel,
  [models.STAFF_PLACES_LIST]: initialModel,
  [models.STAFF_REQUIREMENTS]: initialModel,
  [models.EQUIPMENT_PLACES_LIST]: initialModel,
  [models.EQUIPMENT_REQUIREMENTS]: initialModel,
}

export const initialCommonCardState = {
  [models.DOC_TYPES]: initialModel,
  [models.DOC_PROFILES]: initialModel,
  [models.LAST_METH_DOCS]: initialModel,
  [models.METH_ROUTES_STATUS_LIST]: initialModel,
  [models.METH_VMCL_LIST]: initialModel,
}

export const initialMethRoutesTableState = {
  [models.METH_ROUTES_LIST]: initialModel,
  [models.METH_ROUTES_LIST_COUNT]: initialModel,
}

export const initialMethRoutesCardState = {
  [models.GRAPH]: initialModel,
  [models.GRAPH_VERSIONS]: initialModel,
  [models.PUT_GRAPH]: initialModel,
  [models.METH_GRAPH_DESCRIPTION]: initialModel,
  [models.CHECK_STATUS]: initialModel,
}

export const initialMethRegConditionsState = {
  [models.GET_CONDITIONS_LIST_CSP]: initialModel,
  [models.DELETE_CONDITIONS_CSP]: initialModel,
}

export const initialMethRegConditionsFormState = {
  [models.GET_CONDITION_FORM]: initialModel,
  [models.POST_CONDITION_FORM]: initialModel,
}

export const initialMethRefbookSelectorState = {
  [models.REFBOOK_DATA_ATTRIBUTE]: initialModel,
  [models.REFERENCE_ATTRIBUTE]: initialModel,
}

export const initialMethCopyConditionModalState = {
  [models.COPY_CONDITION_MODAL_ROUTES]: initialModel,
  [models.COPY_CONDITION_MODAL_VERSIONS]: initialModel,
  [models.COPY_CONDITION_MODAL_STATES]: initialModel,
  [models.COPY_CONDITION_MODAL_CONDITIONS]: initialModel,
  [models.COPY_CONDITION_MODAL_SOURCE_CONDITION]: initialModel,
  [models.COPY_CONDITION_MODAL_SAVE_CONDITION]: initialModel,
}

export const initialState = {
  [models.GET_ATTRIBUTE_TREE_BY_TYPE]: initialModel,
  [models.DICT_DEVIATION_VALUE]: initialModel,
  [models.ATTRIBUTE_TREE]: initialModel,
  [models.OPERATION_VALUE_ID_LIST]: initialModel,
  [models.VALUE_TYPE_LIST]: initialModel,
  ...initialClinrecTableState,
  ...initialClinrecCardState,
  ...initialCommonCardState,
  ...initialNpaTableState,
  ...initialNpaCardState,
  ...initialMethRoutesTableState,
  ...initialMethRoutesCardState,
  ...initialMethRegConditionsState,
  ...initialMethRefbookSelectorState,
  ...initialMethRegConditionsFormState,
  ...initialMethCopyConditionModalState,
}
