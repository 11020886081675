import { Mkb10InputWithState } from '@src/modules/monitoring/components/Mkb10Input/Mkb10InputWithState'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class DeathDiag extends RegisterColumn {
  static type = REGISTER_COLUMNS.DEATH_DIAG

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Основная причина смерти',
        component: Mkb10InputWithState,
        defaultValue: [],
      },
      width: 500,
      dataIndex: 'death_diagn',
      dataRender: death_diagn => death_diagn ? death_diagn : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let death_diagn = filters?.[this.type]?.length ? filters?.[this.type] : undefined

    if (nullSearches[this.type]) death_diagn = null

    return {
      args: {
        mkb_id_death_reason: death_diagn,
      },
    }
  }
}
