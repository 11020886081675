import moment from 'moment'
import { DateRangePicker } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'
import { requestArgsDateRange } from './columnsReqArgs'

export class DnEndOn extends RegisterColumn {
  static type = REGISTER_COLUMNS.DN_END_ON

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Снят с ДН',
        component: DateRangePicker,
        defaultValue: [null, null],
        props: {
          allowClear: true,
          allDay: true,
          allowEmpty: [true, true],
          disabledDate: currentDate => currentDate && currentDate.isAfter(moment()),
        },
      },
      width: 250,
      dataIndex: 'dn_end_on',
      dataRender: dn_end_on => dn_end_on ? moment(dn_end_on).format('DD.MM.YYYY') : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs = reqArgs => requestArgsDateRange(this.type, reqArgs)
}
