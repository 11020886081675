import React from 'react'
import PropTypes from 'prop-types'
import { DatePicker as AntdDatePicker } from 'antd'
import cn from 'classnames'
import './DatePicker.scss'

class DatePicker extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    className: PropTypes.string,
  }

  render () {
    const { value, className, onChange, ...restProps } = this.props

    return (
      <AntdDatePicker
        className={cn('root-date-picker', className)}
        value={value}
        onChange={onChange}
        format={'DD.MM.YYYY'}
        placeholder='дд.мм.гггг'
        {...restProps}
      />

    )
  }
}

export default DatePicker
