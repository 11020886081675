import { createSlice } from '@reduxjs/toolkit'
import reducers from './reducers'
import extraReducers from './thunks'
import { initialState } from './initialState'

const slice = createSlice({
  name: 'methodology',
  initialState,
  reducers,
  extraReducers,
})

export const { resetClinrecTableState, resetClinrecCardState, resetNpaTableState, resetNpaCardState,
  resetMethRoutesTableState, resetMethRoutesCardState, resetMethRegConditionsState,
  resetMethRefbookSelectorState, resetMethRegConditionsFormState, resetCopyConditionModalState } = slice.actions
export default slice.reducer
export * from './thunks'
export * from './selectors'
