import { useMemo } from 'react'

/**
 * @description Хук принимает массив моделей и возвращает агрегированное состояние loaded, loading, error
 * @param {...[models: StateModel]} models
 * @returns {[loaded: boolean, loading: boolean, error: (string | null)]}
 */
export const useModels = (models) => {
  const loaded = useMemo(() => {
    return models.reduce((acc, model) => acc && model.loaded, true)
  }, [models])

  const loading = useMemo(() => {
    return models.reduce((acc, model) => acc || model.loading, false)
  }, [models])

  const error = useMemo(() => {
    return models.reduce((acc, model) => acc || model.error, false)
  }, [models])

  return [loaded, loading, error]
}
