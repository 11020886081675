import { isArray } from 'lodash'

/**
 * Осуществляет обход дерева tree и применяет функцию visitor к элементам дерева
 * Мутирует исходный объект
 * @param {*} tree дерево, объект с полем children, либо массив таких объектов
 * @param {(node) => undefined} visitor функция, вызываемая для каждого элемента дерева node. Операции мутируют изначальный объект node
 * @param {string} [childrenKey='children'] ключ в node для определения массива потомков. По-умолчанию 'children'
 * @return tree
 */
export const traverseTree = (tree, visitor, childrenKey='children') => {
  if (!tree) return tree

  if (isArray(tree)) {
    tree.forEach(node => traverseTree(node, visitor, childrenKey))
  } else {
    visitor(tree)
    tree[childrenKey]?.forEach(node => traverseTree(node, visitor, childrenKey))
  }


  return tree
}
