import { countBy, isEqual, isFunction, isObject, isString, size, some } from 'lodash'
import { forwardRef, useCallback, useMemo } from 'react'
import { Checkbox, Icon, SortIcon } from '@src/components'
import { SORT_ORDER } from '@src/constants'
import { cn } from '@src/utils'

import { useRowKey } from './hooks'

export const DataGridHeader = forwardRef(({ columns, data, value, onChange, selectable, rowKey, templateColumnsWidth,
  onSelectionChange, onFilterChange, onSortChange, onDirtyChange, onNullSearchChange, initialFilters, initialSort, pagination }, ref) => {

  const handleFilterChange = useCallback((columnKey, filterValue) => {
    if (value.filters[columnKey] !== filterValue) {
      const isInvalid = Object.entries(columns).reduce((acc, [key, col]) => {
        const fn = col.header?.props?.isInvalid
        const invalid = isFunction(fn) && !!fn(key === columnKey ? filterValue : value.filters[key])
        return acc || invalid
      }, false)

      const newFilters = {
        ...value.filters,
        [columnKey]: filterValue,
      }
      const isDirty =
        !isEqual(newFilters, initialFilters) ||
        !isEqual(value.sort, initialSort) ||
         !!(value.nullSearches && Object.values(value.nullSearches).filter(e => e).length)

      const newValue = {
        ...value,
        filters: newFilters,
        nullSearches: {
          ...value.nullSearches,
          [columnKey]: false,
        },
        isInvalid,
        isDirty,
        pagination: pagination && {
          ...value.pagination,
          currentPage: 1,
        },
      }

      onChange(newValue)
      onFilterChange(newValue)

      if(isDirty !== value.isDirty) onDirtyChange(isDirty)
    }
  }, [value, columns, initialFilters, initialSort, pagination, onChange, onFilterChange, onDirtyChange])

  const handleSortChange = useCallback((key) => {
    const newSort = {
      column: key,
      order: value.sort.column === key
        ? value.sort.order === SORT_ORDER.ASC
          ? SORT_ORDER.DESC
          : SORT_ORDER.ASC
        : SORT_ORDER.ASC,
    }
    const isDirty =
      !isEqual(newSort, initialSort) ||
      !isEqual(value.filters, initialFilters) ||
      !!(value.nullSearches && Object.values(value.nullSearches).filter(e => e).length)

    const newValue = {
      ...value,
      sort: newSort,
      isDirty,
    }
    onChange(newValue)
    onSortChange(newValue)
    if(isDirty !== value.isDirty) onDirtyChange(isDirty)
  }, [value, onChange, onSortChange, onDirtyChange, initialFilters, initialSort])

  const handleNullSearchChange = useCallback((columnKey) => {
    const newNullSearches = {
      ...value.nullSearches,
      [columnKey]: !value.nullSearches[columnKey],
    }

    const isDirty =
      !isEqual(value.filters, initialFilters) ||
        !isEqual(value.sort, initialSort) ||
        !!(newNullSearches && Object.values(newNullSearches).filter(e => e).length)

    const newValue = {
      ...value,
      filters: {
        ...value.filters,
        [columnKey]: initialFilters[columnKey],
      },
      nullSearches: newNullSearches,
      isDirty: !!isDirty,
    }

    onChange(newValue)
    onNullSearchChange(newValue)
    if(isDirty !== value.isDirty) onDirtyChange(isDirty)
  }, [initialFilters, initialSort, onChange, onDirtyChange, onNullSearchChange, value])

  const [getKeyForRowIndex] = useRowKey(data, rowKey)

  const handleMainCheckboxClick = useCallback(() => {
    onChange({
      ...value,
      selectedCheckboxes: size(value.selectedCheckboxes)
        ? {}
        : data.reduce((acc, cur, index) => ({ ...acc, [getKeyForRowIndex(index)]: true }), {}),
    })
    onSelectionChange()
  }, [data, value, onChange, getKeyForRowIndex, onSelectionChange])

  const gridTemplateColumns = useMemo(() => {
    return { gridTemplateColumns: templateColumnsWidth.columnsKeysWidth.join('px ').concat('px') }
  }, [templateColumnsWidth.columnsKeysWidth])

  return (
    <div
      style={{ width: templateColumnsWidth.styleWidth }}
      className='grid-table-header'
      ref={ref}
    >
      <div
        className={cn('grid-table-header-row', selectable && 'row-selectable')}
        style={gridTemplateColumns}
      >
        {
          selectable && <div onClick={handleMainCheckboxClick} className='grid-table-header-col'>
            <Checkbox
              className={cn(countBy(value?.selectedCheckboxes, Boolean).true < data.length && 'grid-table-header-checkbox-state-minus')}
              value={some(value?.selectedCheckboxes, Boolean)}
            />
          </div>
        }
        {
          Object.keys(columns).map(key => <ColumnHeader
            colKey={key}
            column={columns[key]}
            filter={value?.filters[key]}
            sort={value?.sort} key={key}
            nullSearches={value?.nullSearches}
            onFilterChange={handleFilterChange}
            onSortChange={handleSortChange}
            onNullSearchChange={handleNullSearchChange}
          />)
        }
        <div className='header-ponytail'></div>
      </div>
    </div>
  )
})

const ColumnHeader = ({ column, filter, sort, nullSearches, colKey, onFilterChange, onSortChange, onNullSearchChange }) => {
  const Input = column.header.component
  const title = isObject(column.header) ? column.header.title : column.header

  const sortColKey = isString(column.sort) ? column.sort : colKey

  return (
    <div className={cn('grid-table-header-col')} key={colKey} title={title}>
      <div className='grid-table-header-col-title'>
        <div className='grid-table-header-col-title-text'>{title}</div>
        <div className='grid-table-header-col-icons'>
          {
            column.nullSearch && <Icon
              onClick={() => onNullSearchChange(colKey)}
              icon='null_icon'
              style={{ color: nullSearches?.[colKey] ? '#1C3D45' : undefined }}
              className='grid-table-header-col-icon-null'
              title='Отобразить только пустые значения'
            />
          }
          {
            column.sort && <SortIcon
              sortField={sortColKey}
              currentSortField={sort?.column}
              sortType={sort?.order === SORT_ORDER.ASC}
              onClick={() => onSortChange(sortColKey)}
            />
          }
        </div>
      </div>
      {
        isObject(column.header)
          ? <Input
            value={filter}
            onChange={value => onFilterChange(colKey, value)}
            {...(isObject(column.header.props) ? column.header.props : {})}/>
          : null
      }
    </div>
  )
}
