import React, { useCallback, useEffect, useState } from 'react'
import { isNull } from 'lodash'
import moment from 'moment'
import { isValidMomentDate, normalizeStr } from '@src/utils'
import { Drawer, Icon, DateRangePicker, TextInput, Button, AppTabs, Card, Tag, DeviationKindTag } from '@src/components'
import { deviationKindToTagColor, deviationKindToText, TAG_COLORS } from '@src/constants'
import './DeviationsDrawer.scss'

export function DeviationsDrawer ({ deviations, title, defaultTab, ...props }) {
  const [dateInput, setDateInput] = useState([null, null])
  const [searchInput, setSearch] = useState('')
  const [activeTab, setActiveTab] =useState(defaultTab ?? '0')
  const [deviationsData, setDeviations] = useState(deviations)

  useEffect(() => {
    setDeviations(deviations
      .filter(i => activeTab === '0' ? true : activeTab === String(i.deviation_kind))
      .filter(i => isNull(dateInput[0]) && isNull(dateInput[1]) ? true : i.completed_on ? isValidMomentDate(i.completed_on, moment()).isBetween(dateInput[0], dateInput[1]) : false)
      .filter(i => !searchInput ? true : normalizeStr(i.warning_message).includes(normalizeStr(searchInput)))
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, dateInput, searchInput])

  const getCardTitle = (date, deviation_kind) => {
    return (
      <div className='deviation-title'>
        <div>{isValidMomentDate(date)?.format('DD.MM.YYYY')}</div>
        <DeviationKindTag className='ml-32'>{deviationKindToText(deviation_kind)}</DeviationKindTag>
      </div>
    )
  }

  const DeviationsDrawerHeader = () => {
    return <div className='deviations-drawer-header'>
      <span className='header-title'>
        {title}
        <Tag className='deviations-drawer-tag' color={TAG_COLORS.LIGHT_PINK}>Отклонения</Tag>
      </span>
      <Icon className='w-14 h-14 minw-14 minh-14 ml-auto' onClick={props.onClose} icon='cancel_16_m' />
    </div>
  }

  const resetFilters = useCallback(() => {
    setDateInput([null, null])
    setSearch('')
  }, [setDateInput, setSearch])

  return <Drawer
    closable={false}
    className='root-deviations-drawer'
    width={920}
    {...props}
  >
    <DeviationsDrawerHeader />
    <div className='deviations-controls'>
      <DateRangePicker className='deviation-date-picker' allowClear={false} value={dateInput} onChange={e => setDateInput(e)} />
      <TextInput className='deviations-search' icon='search' placeholder='Поиск' value={searchInput} onChange={setSearch} />
      <Button onClick={resetFilters} label='Очистить' />
    </div>
    <div className='deviations-drawer-tabs'>
      <AppTabs activeTab={activeTab} onClick={e => setActiveTab(e.link)} tabs={[
        { label: <TabLabel deviations={deviations} deviation_kind={0}/>, link: '0' },
        { label: <TabLabel deviations={deviations} deviation_kind={1}/>, link: '1' },
        { label: <TabLabel deviations={deviations} deviation_kind={2}/>, link: '2' },
        { label: <TabLabel deviations={deviations} deviation_kind={3}/>, link: '3' },
      ]} />
    </div>
    <div className='deviations-drawer-body'>
      {
        deviationsData.map((d, index) => (
          <Card
            className='deviation-card mb-8'
            key={index}
            title={ () => getCardTitle(d.completed_on,  d.deviation_kind)}
            data={[{ data: d.warning_message }]}
          />
        ))
      }
    </div>
  </Drawer>
}

const TabLabel = ({ deviations, deviation_kind }) => {
  const text = deviation_kind ? deviationKindToText(deviation_kind) : 'Все'
  const count = deviation_kind ? deviations.filter(dev => dev.deviation_kind === deviation_kind).length : deviations.length

  return <div className='tab-label'>
    <span>{text}</span>
    <Tag className='tab-tag' color={deviationKindToTagColor(text)}>{count}</Tag>
  </div>
}
