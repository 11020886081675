import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { isArray } from 'lodash'
import { Card } from '@src/components'
import { loadPatientStatusProfPmoTeen, selectPatientStatusModel } from '@src/modules/monitoring/store'
import { useMountEffect } from '@src/hooks'
import { withMntAudit } from '@src/hoc'
import { TabStatusBase } from '@src/modules/monitoring/components'

const TabStatus = ({ patientId, occasionPatientId, ...props }) => {
  const dispatch = useDispatch()
  const patientStatusModel = useSelector(selectPatientStatusModel)

  const status = patientStatusModel.payload?.data

  useMountEffect(() => {
    dispatch(loadPatientStatusProfPmoTeen({ args: { patient_id: patientId, occasion_patient_id: occasionPatientId } }))
  })

  return <TabStatusBase {...props}>
    <Card
      loading={patientStatusModel.loading}
      error={patientStatusModel.error}
      style={{ flexBasis: '50%' }}
      title='Диагноз'
      data={status && [
        {
          title: 'Диагноз МКБ-10',
          data: () => isArray(status.diag_list)
            ? <div>
              { status.diag_list.map((d, i) => <div key={i} className='mb-8'>{d.diagnosis}<br/></div>) }
            </div>
            : '—',
        },
        {
          title: 'Дата установки',
          data: status.occ_begin_on ? moment(status.occ_begin_on).format('DD.MM.YYYY') : '—',
        },
        {
          title: 'Сопутствующие диагнозы',
          data: '—',
        },
        {
          title: 'Дата начала мероприятия',
          data: status.occ_begin_on ? moment(status.occ_begin_on).format('DD.MM.YYYY') : '—',
        },
        {
          title: 'Группа здоровья',
          data: status.health_group_after_pmo_d || '—',
        },
      ]}
    />
  </TabStatusBase>
}

export const TabStatusWithAudit = withMntAudit(TabStatus)

TabStatusWithAudit.tabTitle = 'Статус'
TabStatusWithAudit.tabPath = 'status'
