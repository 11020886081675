export const PATIENT_LIST = 'patientList'
export const PATIENT_LIST_COUNT = 'patientListCount'
export const PATIENT_LIST_FILTERS = 'patientListFilters'
export const PATINT_INFO = 'patientInfo'
export const PERSON_INFO = 'personInfo'
export const PATIENT_MED_RECORDS = 'patientMedRecords'
export const PATIENT_STATUS = 'patientStatus'
export const PATIENT_SERVICE_LIST = 'patientServiceList'
export const PATIENT_STATUS_PROF_DN = 'patientStatusProfDn'
export const PATIENT_STATUS_PROF_IMM = 'patientStatusProfImm'
export const PATIENT_STATUS_PROF_PMO_D = 'patientStatusProfPmoD'
export const PATIENT_STATUS_PROF_PMO_TEEN = 'patientStatusProfPmoTeen'
export const PATIENT_STATUS_ONKO = 'patientStatusOnko'
export const PATIENT_STATUS_INFECTION = 'patientStatusInfection'
export const PATIENT_INFO_INFECTION = 'patientInfoInfection'
export const EMERGENCY_NOTICE_WIDGET_INFECTION = 'emergencyNoticeWidgetInfection'
export const DN_WIDGET_INFECTION = 'dnWidgetInfection'
export const PATIENT_STATUS_SSZ_OKS = 'patientStatusSszOks'
export const PATIENT_STATUS_SSZ_ONMK = 'patientStatusSszOnmk'
export const PATIENT_STATUS_OCC = 'patientStatusOcc'
export const PATIENT_STATUS_AKINEO_CHARACTERISTIC = 'patientStatusAkineoCharacteristic'
export const PATIENT_STATUS_AKINEO_MOTHER = 'patientStatusAkineoMother'
export const MKB_10_DIAGNOSIS = 'mkb10Diagnosis'
export const OCCASION_INFO = 'occasionInfo'
export const PATIENT_LAST_EHR = 'PATIENT_LAST_VISITS'
export const PATIENT_NEONATAL_SCREENING = 'patientNeonatalScreening'
export const PATIENT_STATUS_CHILD = 'patientStatusChild'
export const PATIENT_EMERGENCY_NOTICE = 'PATIENT_EMERGENCY_NOTICE'
export const PATIENT_EMERGENCY_NOTICE_WIDGET = 'PATIENT_EMERGENCY_NOTICE_WIDGET'
