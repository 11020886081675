import { Component } from 'react'
import { Register, TabMedRecordsWithAudit, TabPersonalWithAudit, TabRouteWithAudit } from '@src/modules/monitoring/components'
import { REGISTER_COLUMNS, URLS } from '@src/constants'
import config from '@src/config'
import { TabStatusWithAudit } from './TabStatus'

export class RegisterProfDn extends Component {
  get basepath () {
    return URLS.MONITORING__PROF__DN
  }

  get navTitle () {
    return 'Реестр пациентов, подлежащих диспансерному наблюдению'
  }

  get columns () {
    return [
      { type: REGISTER_COLUMNS.FULLNAME, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.SNILS, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.BIRTH_DATE, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.GENDER_CODE, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.DIAGNOSIS, settings: { column: { enabled: true } } },
    ]
  }

  get tabs () {
    return [
      TabStatusWithAudit,
      TabMedRecordsWithAudit,
      TabPersonalWithAudit,
      ...(config.app.modules.enabled.monitoring_routes ? [TabRouteWithAudit] : []),
    ]
  }

  render () {
    return <Register
      list_type='10'
      columns={this.columns}
      basepath={this.basepath}
      navTitle={this.navTitle}
      tabs={this.tabs}
    />
  }
}
