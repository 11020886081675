import { createSlice } from '@reduxjs/toolkit'
import { initialModel } from '@src/utils'
import * as models from './models'
import extraReducers from './thunks'
import reducers from './reducers'

const initialState = {
  isSidebarOpen: false,
  [models.USER_PREFERENCES]: {} as {[key: string]: any},
  [models.GET_UI_SETTINGS]: initialModel,
  [models.POST_UI_SETTINGS]: initialModel,
  [models.APP_SETTINGS]: {} as {[key: string]: any},
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: reducers,
  extraReducers: extraReducers,
})

export const { switchSidebar, setUserPreferences } = uiSlice.actions

export default uiSlice.reducer

export * from './thunks'
export * from './selectors'
