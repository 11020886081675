import * as models from './models'

const reducers = {
  switchSidebar: (state) => {
    state.isSidebarOpen = !state.isSidebarOpen
  },
  setUserPreferences: (state, action) => {
    state[models.USER_PREFERENCES] = action.payload
  },
}

export default reducers
