import moment from 'moment'
import { LongDataModal } from '@src/modals'
import { modalService } from '@src/services'

const messageRender = (message) => {
  const handleClick = () => {
    modalService.open(LongDataModal, {
      title: 'Сообщение',
      data: message,
    })
  }
  return <div className='journal-audit-message-cell w-100p h-100p' onClick={handleClick}>Просмотреть</div>
}

export const COLUMNS = {
  user_full_name: {
    header: 'Пользователь',
    width: 100,
    dataIndex: 'user_full_name',
    enabled: true,
  },
  created_on: {
    header: 'Дата создания записи',
    width: 100,
    dataIndex: 'created_on',
    dataRender: created_on => created_on ? moment(created_on).format('DD.MM.YYYY HH:mm:ss') : '',
    enabled: true,
  },
  mcl_profile: {
    header: 'Направление ОМП',
    width: 100,
    dataIndex: 'mcl_profile',
    enabled: true,
  },
  source_name: {
    header: 'Действие',
    width: 100,
    dataIndex: 'source_name',
    enabled: true,
  },
  role_name: {
    header: 'Роль',
    width: 100,
    dataIndex: 'role_name',
    enabled: true,
  },
  mo_name_full: {
    header: 'Медицинская организация',
    width: 100,
    dataIndex: 'mo_name_full',
    enabled: true,
  },
  appointment: {
    header: 'Должность',
    width: 100,
    dataIndex: 'appointment',
    enabled: true,
  },
  source: {
    header: 'Источник запроса',
    width: 100,
    dataIndex: 'source',
    enabled: false,
  },
  target: {
    header: 'Назначение запроса',
    width: 100,
    dataIndex: 'target',
    enabled: false,
  },
  log_type_name: {
    header: 'Тип сообщения',
    width: 100,
    dataIndex: 'log_type_name',
    enabled: false,
  },
  ipaddr_text: {
    header: 'IP адрес источника запроса',
    width: 100,
    dataIndex: 'ipaddr_text',
    enabled: false,
  },
  error_text: {
    header: 'Текст ошибки',
    width: 100,
    dataIndex: 'error_text',
    enabled: false,
  },
  stage_name: {
    header: 'Стадия обработки',
    width: 100,
    dataIndex: 'stage_name',
    enabled: false,
  },
  message: {
    header: 'Сообщение',
    width: 100,
    dataIndex: 'description',
    titleRender: () => 'Просмотреть сообщение',
    dataRender: messageRender,
    enabled: false,
  },
}
