import { REFBOOKS } from '@src/constants'
import { thunkCreator } from '@src/utils'
import * as models from './models'

export const [
  loadMclList, loadMclListExtra,
] = thunkCreator(models.DICT_MCL_LIST, '/admin/mclOmp/items')

export const [
  loadDictResponseLevelList, loadDictResponseLevelListExtra,
] = thunkCreator(models.DICT_RESPONSE_LEVEL_LIST, '/admin/responseLevel/list')

export const [
  loadDictPregnancyOutcomes, loadDictPregnancyOutcomesExtra,
] = thunkCreator(models.DICT_PREGNANCY_OUTCOMES, '/storage/get_pregnancy_outcome_list')

export const [
  loadDictImmunization, loadDictImmunizationExtra,
] = thunkCreator(models.DICT_IMMUNIZATION, `/psi/refbook/${REFBOOKS.IMMUNIZATION}/latest/data`, { method: 'GET', extractor: response => response.data.items })

export const [
  loadDictGenders, loadDictGendersExtra,
] = thunkCreator(models.DICT_GENDERS, `/psi/refbook/${REFBOOKS.GENDERS}/latest/data`, { method: 'GET', extractor: response => response.data.items })

export const [
  loadDictMo, loadDictMoExtra,
] = thunkCreator(models.DICT_MO, '/admin/organisations/list/mo/accessible')

export const [
  loadDictRegion, loadDictnRegionExtra,
] = thunkCreator(models.DICT_REGION, `/psi/refbook/${REFBOOKS.REGION}/latest/data`, { method: 'GET', extractor: response => response.data.items })

export const [
  loadDictDiseases, loadDictDiseasesExtra,
] = thunkCreator(models.DICT_DISEASES, `/psi/refbook/${REFBOOKS.DISEASES}/latest/data`, { method: 'GET', extractor: response => response.data.items })

export const [
  loadDictDnReason, loadDictDnReasonExtra,
] = thunkCreator(models.DIC_DN_REASON, `/psi/refbook/${REFBOOKS.DIC_DN_REASON}/latest/data`, { method: 'GET', extractor: response => response.data.items })

export const [
  loadDictResultDetection, loadDictResultDetectionExtra,
] = thunkCreator(models.DIC_RESULT_DETECTION, `/psi/refbook/${REFBOOKS.DIC_RESULT_DETECTION}/latest/data`, { method: 'GET', extractor: response => response.data.items })

export const [
  loadDictDetectionPlace, loadDictDetectionPlaceExtra,
] = thunkCreator(models.DIC_DETECTION_PLACE, `/psi/refbook/${REFBOOKS.DIC_DETECTION_PLACE}/latest/data`, { method: 'GET', extractor: response => response.data.items })

export const [
  loadDictDetectionPlaceInfection, loadDictDetectionPlaceInfectionExtra,
] = thunkCreator(models.DIC_DETECTION_PLACE_INFECTION, `/psi/refbook/${REFBOOKS.DIC_DETECTION_PLACE_INFECTION}/latest/data`, { method: 'GET', extractor: response => response.data.items })

export const [
  loadDictValidityDegree, loadDictValidityDegreeExtra,
] = thunkCreator(models.DIC_VALIDITY_DEGREE, `/psi/refbook/${REFBOOKS.DIC_VALIDITY_DEGREE}/latest/data`, { method: 'GET', extractor: response => response.data.items })

export const [
  loadDictNoticeType, loadDictNoticeTypeExtra,
] = thunkCreator(models.DIC_NOTICE_TYPE, `/psi/refbook/${REFBOOKS.DIC_NOTICE_TYPE}/latest/data`, { method: 'GET', extractor: response => response.data.items })

export const [
  loadDictStatusInfectionType, loadDictStatusInfectionTypeExtra,
] = thunkCreator(models.DIC_STATUS_INFECTION, `/psi/refbook/${REFBOOKS.DIC_STATUS_INFECTION}/latest/data`, { method: 'GET', extractor: response => response.data.items })

export const [
  loadDictTypesOfNotification, loadloadDictTypesOfNotificationExtra,
] = thunkCreator(models.DICT_TYPES_OF_NOTIFICATION, `/psi/refbook/${REFBOOKS.DICT_TYPES_OF_NOTIFICATION}/latest/data`, { method: 'GET', extractor: response => response.data.items })


const extraReducers = {
  ...loadMclListExtra,
  ...loadDictResponseLevelListExtra,
  ...loadDictPregnancyOutcomesExtra,
  ...loadDictImmunizationExtra,
  ...loadDictMoExtra,
  ...loadDictGendersExtra,
  ...loadDictnRegionExtra,
  ...loadDictDiseasesExtra,
  ...loadDictDnReasonExtra,
  ...loadDictResultDetectionExtra,
  ...loadDictDetectionPlaceExtra,
  ...loadDictDetectionPlaceInfectionExtra,
  ...loadDictValidityDegreeExtra,
  ...loadDictNoticeTypeExtra,
  ...loadDictStatusInfectionTypeExtra,
  ...loadloadDictTypesOfNotificationExtra,
}

export default extraReducers
