export const REFBOOKS = {
  IMMUNIZATION: '1.2.643.5.1.13.13.99.2.824',
  GENDERS: '1.2.643.5.1.13.13.11.1040',
  REGION: '1.2.643.5.1.13.13.99.2.206',
  DISEASES: '1.2.643.5.1.13.13.99.2.1021',
  DIC_DN_REASON: '1.2.643.5.1.13.13.11.1045',
  DIC_RESULT_DETECTION: '1.2.643.5.1.13.13.11.1491',
  DIC_DETECTION_PLACE: '1.2.643.5.1.13.13.99.2.20',
  DIC_DETECTION_PLACE_INFECTION: '1.2.643.5.1.13.13.11.1008',
  DIC_VALIDITY_DEGREE: '1.2.643.5.1.13.13.99.2.795',
  DIC_NOTICE_TYPE: '1.2.643.5.1.13.13.11.1007',
  DIC_STATUS_INFECTION: 'dict_status_infection',
  DICT_TYPES_OF_NOTIFICATION: '1.2.643.5.1.13.13.99.2.838',
}
