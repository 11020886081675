import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from '@src/components'
import { loadPatientServiceList, loadPatientStatusSszOks, selectPatientInfoModel, selectPatientServiceListModel,
  selectPatientStatusModel } from '@src/modules/monitoring/store'
import { getFullname } from '@src/utils'
import { useMountEffect } from '@src/hooks'
import { withMntAudit } from '@src/hoc'
import { TabStatusBase } from '@src/modules/monitoring/components'
import { useAppSettings } from '@src/hooks/useAppSettings'

const TabStatus = ({ patientId, occasionPatientId, ...props }) => {
  const dispatch = useDispatch()
  const patientStatusModel = useSelector(selectPatientStatusModel)
  const patientInfoModel = useSelector(selectPatientInfoModel)
  const patientServiceListModel = useSelector(selectPatientServiceListModel)

  const status = patientStatusModel.payload?.data?.[0]
  const info = patientInfoModel.payload?.data
  const diag = patientServiceListModel.payload?.data?.servicelist_diag
  const medic = patientServiceListModel.payload?.data?.servicelist_medic

  const appSettings = useAppSettings()

  useMountEffect(() => {
    dispatch(loadPatientStatusSszOks({ args: { patient_id: patientId, occasion_patient_id: occasionPatientId } }))
    dispatch(loadPatientServiceList({ args: { occasion_patient_id: occasionPatientId } }))
  })

  return <TabStatusBase {...props}>
    <Card
      title='Пациент'
      loading={patientInfoModel.loading}
      error={patientInfoModel.error}
      style={{ flexBasis: '40%' }}
      className='mr-16'
      data={info && [
        {
          title: 'Имя',
          data: getFullname(info) || '-',
        },
        {
          title: 'Пол',
          data: info.gender || '-',
        },
        {
          title: 'Дата рождения',
          data: info.birth_date ? moment(info.birth_date).format('DD.MM.YYYY') : '-',
        },
        {
          title: 'Возраст',
          data: info.patient_age ? info.patient_age : '-',
        },
      ]}
    />
    <div style={{ flexBasis: '60%' }}>
      <Card
        title='Диагноз'
        loading={patientStatusModel.loading}
        error={patientStatusModel.error}
        className='mb-16'
        data={status && [
          {
            title: 'Степень обоснованности диагноза',
            data: status.validity_degree || '—',
          },
          {
            title: 'Дата установки',
            data: status.diagnosis_begin_on
              ? moment(status.diagnosis_begin_on).format('DD.MM.YYYY')
              : '—',
          },
          {
            title: 'Диагноз МКБ-10',
            data: status.diagnosis_main || '—',
          },
          {
            title: 'Сопутствующие диагнозы',
            data: status.diagnosis_related || '—',
          },
          ...(
            appSettings?.monitoring?.ssz?.patient_card?.enable_diagnosis_complications
              ? [
                {
                  title: 'Осложнения',
                  data: status.diagnosis_complications || '—',
                },
              ]
              : []
          ),
        ]}
      />
      <Card
        title='Диагностика'
        loading={patientServiceListModel.loading}
        error={patientServiceListModel.error}
        className='mb-16'
        data={diag && diag.map(service => (
          {
            title: service.service_date ? moment(service.service_date).format('DD.MM.YYYY') : '-',
            data: `${service.service_code} ${service.service_name}`,
          }
        ))}
      />
      <Card
        title='Лечение'
        loading={patientServiceListModel.loading}
        error={patientServiceListModel.error}
        data={medic && medic.map(service => (
          {
            title: service.service_date ? moment(service.service_date).format('DD.MM.YYYY') : '-',
            data: `${service.service_code} ${service.service_name}`,
          }
        ))}
      />
    </div>
  </TabStatusBase>
}

export const TabStatusWithAudit = withMntAudit(TabStatus)

TabStatusWithAudit.tabTitle = 'Статус'
TabStatusWithAudit.tabPath = 'status'
