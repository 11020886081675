import { useMemo, useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { cloneDeep, isEmpty, isObject } from 'lodash'
import { Tooltip } from 'antd'
import { Card, Combobox, DocStatusTag, Button, DataGrid, Icon, DatePicker, TextInput, IfNotReadonly } from '@src/components'
import { selectMethRouteStatusList, loadCheckStatus } from '@src/modules/methodology/store'
import { modalService } from '@src/services'
import { AccessContext } from '@src/context'
import { DeleteFoundationDocumentModal } from '../../../DeleteFoundationDocumentModal/DeleteFoundationDocumentModal'
import { AddFoundationDocumentDrawer } from '../../../AddFoundationDocumentDrawer/AddFoundationDocumentDrawer'
import { EditFoundationDocumentDrawer } from '../../../EditFoundationDocumentDrawer/EditFoundationDocumentDrawer'

export function CardsBlock ({
  id,
  revisionId,
  graphModel,
  dataGI,

  loadedDataGI,
  currentStatus,
  isNewVersion,
  dataRegionStartedOn,
  dataRegionExpiratedOn,
  isBlockByStatus,
  tooltipInfo,

  handleSetIsDirtyDocument,
  handleSetCurrentStatus,
  handleSetLoadedDataGI,
  handleSetDataRegionStartedOn,
  handleSetDataRegionExpiratedOn,
  handleSetIsBlockSaveBtn,
  handleSetIsBlockSaveBtnByTime,
  handleSetIsBlockByStatus,
  handleSetTooltipInfo,
  wrapper,
}) {
  const accessContext = useContext(AccessContext)
  const dispatch = useDispatch()
  const routeStatusList = useSelector(selectMethRouteStatusList)
  const graph = cloneDeep(graphModel?.payload?.data?.[0]?.graph) || {}
  const region = cloneDeep(graphModel?.payload?.data?.[0]?.graph_ext_info) || {}
  const [updatedFoundationData, setUpdatedFoundationData] = useState(loadedDataGI?.graph_ext_info?.graph_ext_list_document_npa)
  const [npaNameValue, setNpaNameValue] = useState()
  const [npaNumberValue, setNpaNumberValue] = useState()
  const [checkStatusLoading, setCheckStatusLoading] = useState(false)

  // Блокировка элементов если статус Недействующий
  useEffect(() => {
    const isValidStatus = dataGI?.graph_ext_info?.graph_ext_status_caption === 'Недействующий'

    if(isValidStatus)
      handleSetIsBlockByStatus(true)
    else
      handleSetIsBlockByStatus(false)
  }, [handleSetIsBlockByStatus, dataGI])

  // Обновление документов основания, для последующего зименения данных
  useEffect(() => {
    if(loadedDataGI?.graph_ext_info?.graph_ext_list_document_npa) {
      setUpdatedFoundationData(loadedDataGI?.graph_ext_info?.graph_ext_list_document_npa)
    }
  }, [loadedDataGI?.graph_ext_info, setUpdatedFoundationData])

  // Проверяем даты, дата завершения олжна быть больше даты начала
  useEffect(() => {
    // Если новый статус Черновик, то проверка дат не нужна
    if(parseInt(currentStatus?.value) === 1) {
      handleSetIsBlockSaveBtnByTime(false)
    }

    // Если новый статус Действующий, то нужна дата начала
    if(parseInt(currentStatus?.value) === 2) {
      if(!moment(dataRegionExpiratedOn).format('x')) {
        handleSetIsBlockSaveBtnByTime(true)
      } else {
        handleSetIsBlockSaveBtnByTime(false)
      }
    }
    // Если новый статус Недействующий, то нужны даты начала и окончания
    // и дата окончания должна быть не меньше даты начала
    if(parseInt(currentStatus?.value) === 3) {
      if((moment(dataRegionExpiratedOn).format('x') && moment(dataRegionStartedOn).format('x'))
       && moment(dataRegionExpiratedOn).format('x') < moment(dataRegionStartedOn).format('x')) {
        handleSetIsBlockSaveBtnByTime(true)
      } else {
        handleSetIsBlockSaveBtnByTime(false)
      }
    }
  }, [dataRegionStartedOn, dataRegionExpiratedOn, handleSetIsBlockSaveBtnByTime, currentStatus])

  // Выставляем даты в компоненты датапикера
  useEffect(() => {
    if(loadedDataGI?.graph_ext_info?.graph_ext_started_on) {
      handleSetDataRegionStartedOn(moment(loadedDataGI?.graph_ext_info?.graph_ext_started_on))
    } else {
      handleSetDataRegionStartedOn(null)
    }
    if(loadedDataGI?.graph_ext_info?.graph_ext_expirated_on) {
      handleSetDataRegionExpiratedOn(moment(loadedDataGI?.graph_ext_info?.graph_ext_expirated_on))
    } else {
      handleSetDataRegionExpiratedOn(null)
    }
  }, [loadedDataGI, handleSetDataRegionStartedOn, handleSetDataRegionExpiratedOn])

  //Сохранение данных для изменения на фронте
  const statuses = useMemo(() => routeStatusList?.payload?.data.map((item) => {
    return {
      label: item.caption,
      value: item.id,
    } }), [routeStatusList?.payload?.data])

  //Столбцы для Документов-оснований
  const columns = {
    document_name: {
      width: 700,
      dataIndex: 'document_name',
      sort: false,
      enabled: true,
      header: {
        title: 'Наименование регионального НПА',
        component: TextInput,
        defaultValue: '',
        props: {
          allowClear: true,
          icon: 'search',
          onChange: e => searchNPADoc(e, 'document_name'),
          value: npaNameValue,
        },
      },

    },
    document_number: {
      width: 700,
      dataIndex: 'document_number',
      sort: false,
      enabled: true,
      header: {
        title: 'Номер регионального НПА',
        component: TextInput,
        defaultValue: '',
        props: {
          allowClear: true,
          icon: 'search',
          onChange: e => searchNPADoc(e, 'document_number'),
          value: npaNumberValue,
        },
      },
    },
    ...!accessContext.readonly
      ? {
        buttons_edit_column: {
          width: 76,
          dataIndex: 'buttons_edit',
          sort: false,
          enabled: true,
          titleRender: () => 'Редактировать',
          dataRender: (item, record) => !isBlockByStatus
            ? <>
              <div className='grid-icon-area d-flex justify-content-center align-items-center w-30 h-30'>
                <Icon
                  className='w-16 h-16'
                  onClick={() => editFoundationRow(record)}
                  icon='edit'
                />
              </div>
              <div className='grid-icon-area d-flex justify-content-center align-items-center w-30 h-30'>
                <Icon
                  className='w-16 h-16'
                  onClick={() => deleteFoundationRow(record)}
                  icon='trash_can'
                />
              </div>
            </>
            : null,
          'header': '',
        },
      }
      : {},
  }
  // Фильтруем данные для Документы основания на основе вводимых данных
  function searchNPADoc (event, type) {
    // записываем данные для фильтрации с другими полями
    if(type === 'document_name')
      setNpaNameValue(event)
    // записываем данные для фильтрации с другими полями
    if(type === 'document_number')
      setNpaNumberValue(event)


    let result = []

    result = loadedDataGI?.graph_ext_info?.graph_ext_list_document_npa.filter((item) => {
      if(type === 'document_name') {
        // Проверка есть ли в другом столбце (Наименование НПА) данные и фильтруем либо с ними либо без
        if(isEmpty(npaNumberValue)) {
          if(isEmpty(event)) {
            return item
          }
          return item.document_name.includes(event)
        }
        if(!isEmpty(npaNumberValue)) {
          if(isEmpty(event)) {
            return item.document_number.includes(npaNumberValue)
          }
          return item.document_number.includes(npaNumberValue) && item.document_name.includes(event)
        }
      }
      // Проверка есть ли в другом столбце (номер НПА) данные и фильтруем либо с ними либо без
      if(type === 'document_number') {
        if(isEmpty(npaNameValue)) {
          if(isEmpty(event)) {
            return item
          }
          return item.document_number.includes(event)
        }
        if(!isEmpty(npaNameValue)) {
          if(isEmpty(event)) {
            return item.document_name.includes(npaNameValue)
          }
          return item.document_name.includes(npaNameValue) && item.document_number.includes(event)
        }
      }
      return item
    })
    setUpdatedFoundationData(result)
  }

  // Корректный список статусов в зависимости от текущего
  function getActualStatusOptions () {
    // Убрал переменную currentName из аргументов, так как теперь нужна возможность откатить выбор
    const currentName = region.graph_ext_status_caption
    const valueIsObject = isObject(currentName) ? currentName.label : currentName
    if(isNewVersion) {
      return [{ label: 'Черновик', value: 1 }]
    }
    switch (valueIsObject) {
    case 'Черновик': return [{ label: 'Черновик', value: 1, key: 1 }, { label: 'Действующий', value: 2, key: 2 }]
    case 'Действующий': return [{ label: 'Действующий', value: 2, key: 2 }, { label: 'Недействующий', value: 3, key: 3 }]
    case 'Недействующий': return [{ label: 'Недействующий', value: 3, key: 3 }]
    default: break
    }
  }

  // Подставляем корректный статус для РМ
  function getComboboxStatus (currentName) {
    const currentObjectValue = statuses.filter(item => item.label === currentName)
    return <Combobox
      className='route-tab-select'
      placeholder='Выберите'
      onChange={handleChangeStatus}
      loading={checkStatusLoading}
      defaultValue={currentObjectValue[0]}
      value={isEmpty(currentStatus) ? currentObjectValue[0] : currentStatus}
      options={getActualStatusOptions(currentObjectValue[0])}
      // disabled={isNewVersion || isDirtyDocument}
      disabled={isBlockByStatus || checkStatusLoading || accessContext.readonly}
      status={tooltipInfo.prevTooltip === 'status'  ? 'error' : ''}
    />
  }

  // смена статуса РМ
  async function handleChangeStatus (val, status) {
    handleSetCurrentStatus(status)

    // Проверка новый статус или текущий не сохраненный
    if(dataGI.graph_ext_info.graph_ext_status_id !== status.value) {
      handleSetIsDirtyDocument(true)
      const resultCheckStatus = await checkStatus({ statusId : status })
      handleSetLoadedDataGI(p => ({ ...p, graph_ext_info: { ...p.graph_ext_info, graph_ext_status_id: val, graph_ext_status_caption: status.label } }))

      // Если есть ошибка, вывод и блокировка сохранения
      if(resultCheckStatus.ret_code)
        handleSetTooltipInfo({ show: 'status', text: resultCheckStatus.ret_message, prevTooltip: 'status' })
      else
        handleSetTooltipInfo({ show: false, text: '', prevTooltip: false })
      return
    }
    else
      handleSetTooltipInfo({ show: false, text: '', prevTooltip: false })

    handleSetIsDirtyDocument(true)
    switch(status.label) {
    case 'Действующий':
      if(val !== region.graph_ext_status_id) {
        dataGI.graph_ext_info.graph_ext_started_on = moment().format()
      } else {
        dataGI.graph_ext_info.graph_ext_started_on = region.graph_ext_started_on
        dataGI.graph_ext_info.graph_ext_expirated_on = region.graph_ext_expirated_on
      }
      dataGI.graph_ext_info.graph_ext_status_caption = 'Действующий'
      dataGI.graph_ext_info.graph_ext_status_id = 2
      handleSetLoadedDataGI(dataGI); break
    case 'Недействующий':
      if(val !== region.graph_ext_status_id) {
        dataGI.graph_ext_info.graph_ext_expirated_on = moment().format()
      } else {
        dataGI.graph_ext_info.graph_ext_expirated_on = region.graph_ext_expirated_on
      }
      dataGI.graph_ext_info.graph_ext_status_caption = 'Недействующий'
      dataGI.graph_ext_info.graph_ext_status_id = 3
      handleSetLoadedDataGI(dataGI); break
    default:
      dataGI.graph_ext_info.graph_ext_status_caption = status.label
      dataGI.graph_ext_info.graph_ext_status_id = status.value
      handleSetLoadedDataGI(dataGI)
    }
    if(val === region.graph_ext_status_id) {
      handleSetIsDirtyDocument(false)
    }
  }

  // Вызов модалки редактирования документа основания
  const editFoundationRow = values => modalService.open(EditFoundationDocumentDrawer, {
    values,
    data: loadedDataGI?.graph_ext_info?.graph_ext_list_document_npa || [],
    cb: resultFoundationEditModalClose,
  })

  // Вызов модалки удаления документа основания
  const deleteFoundationRow = data => modalService.open(DeleteFoundationDocumentModal, {
    data,
    cb: resultFoundationDeleteModalClose,
  })

  // Вызов модалки для добавления Документа основания
  const handleAddFoundationDoc = () => modalService.open(AddFoundationDocumentDrawer, {
    data: loadedDataGI?.graph_ext_info?.graph_ext_list_document_npa || [],
    cb: resultFoundationModalClose,
  })

  // Callback для модалки удаления Документа основания
  function resultFoundationDeleteModalClose (isAccess, data) {
    if(isAccess) {
      handleSetIsDirtyDocument(true)
      const newArray = cloneDeep(loadedDataGI)
      newArray.graph_ext_info.graph_ext_list_document_npa = newArray.graph_ext_info.graph_ext_list_document_npa.filter(item => item.uuid_code !== data.uuid_code)
      handleSetLoadedDataGI(newArray)
    }
  }

  // Callback для Модалки редактирования Документы основания
  function resultFoundationEditModalClose (isAccess, formValues) {
    if(isAccess) {
      const newArray = cloneDeep(loadedDataGI)
      let resultOk = true
      newArray.graph_ext_info.graph_ext_list_document_npa.forEach((element) => {
        if((element.document_name === formValues.document_name
          || element.document_number === formValues.document_number) && element.uuid_code !== formValues.uuid_code) {
          resultOk = false
        }
      })
      if(!resultOk) {
        return
      }
      newArray.graph_ext_info.graph_ext_list_document_npa.map((element) => {
        if(element.uuid_code === formValues.uuid_code) {
          element.document_name = formValues.document_name
          element.document_number = formValues.document_number
        }
        return element
      })
      handleSetIsDirtyDocument(true)
      handleSetLoadedDataGI(newArray)
    }
  }

  // Callback для Модалки добавления Документы основания
  function resultFoundationModalClose (isAccess, formValues) {
    if(isAccess) {
      const newArray = cloneDeep(loadedDataGI)
      let resultOk = true
      newArray.graph_ext_info.graph_ext_list_document_npa.forEach((element) => {
        if(element.document_name === formValues.document_name
          || element.document_number === formValues.document_number) {
          resultOk = false
        }
      })
      if(resultOk) {
        newArray.graph_ext_info.graph_ext_list_document_npa.push({ ...formValues, uuid_code: uuidv4() })
        handleSetLoadedDataGI(newArray)
        handleSetIsDirtyDocument(true)
      }
    }
  }

  // Смена Дата начала действия региональных настроек
  async function changeDataRegionStartedOn (value) {
    if(parseInt(currentStatus?.value) === 2 || parseInt(currentStatus?.value) === 1) { // Действующий || Черновик
      const resultCheckStatus = await checkStatus({ startedOn : moment(value).format() })

      if(resultCheckStatus.ret_code)
        handleSetTooltipInfo({ show: 'startedOn', text: resultCheckStatus.ret_message, prevTooltip: 'startedOn' })
      else
        handleSetTooltipInfo({ show: false, text: '', prevTooltip: false })
    }

    handleSetDataRegionStartedOn(value)

    if(dataGI.graph_ext_info)
      dataGI.graph_ext_info.graph_ext_started_on = moment(value).format()
    else {
      dataGI.graph_ext_info = {}
      dataGI.graph_ext_info.graph_ext_started_on = moment(value).format()
      dataGI.graph_ext_info.graph_ext_expirated_on = null
    }

    handleSetLoadedDataGI(dataGI)
  }

  // Смена Дата окончания действия региональных настроек
  async function changeDataRegionExpiratedOn (value) {
    if(parseInt(currentStatus?.value) === 2 || parseInt(currentStatus?.value) === 1) { // Действующий || Черновик
      const resultCheckStatus = await checkStatus({ expiratedOn : moment(value).format() })

      if(resultCheckStatus.ret_code)
        handleSetTooltipInfo({ show: 'expiratedOn', text: resultCheckStatus.ret_message, prevTooltip: 'expiratedOn' })
      else
        handleSetTooltipInfo({ show: false, text: '', prevTooltip: false })
    }

    handleSetDataRegionExpiratedOn(value)

    if(dataGI.graph_ext_info)
      dataGI.graph_ext_info.graph_ext_expirated_on = moment(value).format()
    else {
      dataGI.graph_ext_info = {}
      dataGI.graph_ext_info.graph_ext_started_on = null
      dataGI.graph_ext_info.graph_ext_expirated_on = moment(value).format()
    }

    handleSetLoadedDataGI(dataGI)
  }

  // Проверка статуса дат и статусов
  function checkStatus (args) {
    setCheckStatusLoading(true)
    return dispatch(loadCheckStatus({
      graph_id: id,
      graph_ext_id: revisionId,
      status_id: args?.statusId?.value || currentStatus?.value || dataGI.graph_ext_info.graph_ext_status_id,
      started_on: args?.startedOn || dataRegionStartedOn,
      expirated_on: args?.expiratedOn || dataRegionExpiratedOn,
    }))
      .then((res) => {
        if(res.payload.ret_code !== 0 && parseInt(args?.statusId?.value ?? currentStatus?.value) !== 1)
          handleSetIsBlockSaveBtn(true)
        else {
          handleSetIsDirtyDocument(true)
          handleSetIsBlockSaveBtn(false)
        }

        setCheckStatusLoading(false)
        return res.payload
      }, e => console.error(e))
  }

  return <div>
    <Card
      loading={graphModel.loading}
      error={graphModel.error}
      className='mb-16'
      title='Данные федерального маршрута'
      data={[
        {
          title: 'Статус',
          data: graph?.graph_status_name
            ? <DocStatusTag className='table-row-tag'>{graph?.graph_status_name}</DocStatusTag>
            : '—',
        },
        {
          title: 'Профиль ОМП',
          data: graph?.graph_vmcl_name
            ? graph?.graph_vmcl_name
            : '—',
        },
        {
          title: 'Наименование документа-основания',
          data: graph?.graph_document_name
            ? graph?.graph_document_name
            : '—',
        },
        {
          title: 'Дата начала действия федерального маршрута',
          data: graph?.graph_started_on
            ? moment(graph?.graph_started_on).format('DD.MM.YYYY')
            :'-',
        },
        {
          title: 'Дата окончания действия федерального маршрута',
          data: graph?.graph_expirated_on
            ? moment(graph?.graph_expirated_on).format('DD.MM.YYYY')
            :'-',
        },
      ]}
    />
    <Card
      loading={graphModel.loading}
      error={graphModel.error}
      className='mb-16'
      title='Данные региональной маршрутизации'
      data={[
        {
          title: 'Статус региональной маршрутизации',
          data: dataGI?.graph_ext_info?.graph_ext_status_caption
            ? <Tooltip
              open={tooltipInfo.show === 'status'}
              destroyTooltipOnHide
              title={tooltipInfo.text}
              getPopupContainer={() => wrapper.current}
              onOpenChange={(open) => {
                handleSetTooltipInfo({
                  show: open && tooltipInfo.prevTooltip === 'status' ? 'status' : false,
                  text: tooltipInfo.text,
                  prevTooltip: tooltipInfo.prevTooltip ? tooltipInfo.prevTooltip : 'status',
                })
              }}
            >{getComboboxStatus(dataGI?.graph_ext_info?.graph_ext_status_caption)}</Tooltip>
            : '—',
        },
        {
          title: 'Дата начала действия региональных настроек',
          data: <Tooltip
            open={tooltipInfo.show === 'startedOn'}
            title={tooltipInfo.text}
            destroyTooltipOnHide
            getPopupContainer={() => wrapper.current}
            placement='right'
            onOpenChange={(open) => {
              handleSetTooltipInfo({
                show: open && tooltipInfo.prevTooltip === 'startedOn' ? 'startedOn' : false,
                text: tooltipInfo.text,
                prevTooltip: tooltipInfo.prevTooltip ? tooltipInfo.prevTooltip : 'startedOn',
              })
            }}
          >
            <DatePicker
              allowClear={null}
              value={dataRegionStartedOn}
              onChange={changeDataRegionStartedOn}
              // Если статус Действующий, то редактирование недоступно
              disabled={isBlockByStatus || checkStatusLoading || accessContext.readonly || !!(String((isEmpty(currentStatus)
                ? statuses.filter(item => item.label === dataGI?.graph_ext_info?.graph_ext_status_caption)?.[0]
                : currentStatus)?.value) === '2')}
              status={tooltipInfo.prevTooltip === 'startedOn'  ? 'error' : ''}
            />
          </Tooltip>,
        },
        {
          title: 'Дата окончания действия региональных настроек',
          data: <Tooltip
            open={tooltipInfo.show === 'expiratedOn'}
            title={tooltipInfo.text}
            destroyTooltipOnHide
            getPopupContainer={() => wrapper.current}
            placement='bottom'
            onOpenChange={(open) => {
              handleSetTooltipInfo({
                show: open && tooltipInfo.prevTooltip === 'expiratedOn' ? 'expiratedOn' : false,
                text: tooltipInfo.text,
                prevTooltip: tooltipInfo.prevTooltip ? tooltipInfo.prevTooltip : 'expiratedOn',
              })
            }}
          ><DatePicker
              allowClear={null}
              value={dataRegionExpiratedOn}
              status={tooltipInfo.prevTooltip === 'expiratedOn'  ? 'error' : ''}
              onChange={changeDataRegionExpiratedOn}
              disabled={isBlockByStatus || checkStatusLoading || accessContext.readonly}
            />
          </Tooltip>,
        },
        {
          title: 'Автор региональных настроек',
          data: region?.graph_ext_created_by_fio || '—',
        },
      ]}
    />
    <div style={{ marginBottom: '70px' }}>
      <div className='table-header mb-0 d-flex justify-content-between'>
        <h3>Документы—основания</h3>
        <IfNotReadonly>
          <Button
            icon='plus_cr_fr'
            label='Добавить'
            disabled={isBlockByStatus}
            onClick={handleAddFoundationDoc}
          />
        </IfNotReadonly>
      </div>
      <DataGrid
        style={{ height: '300px',  borderLeft: '1px solid #D5DEDF' }}
        columns={columns}
        data={updatedFoundationData?.map((i, c) => ({ ...i, rowKey: c }))}
        rowKey='rowKey'
        loading={graphModel.loading}
        error={graphModel.error}
      />
    </div>
  </div>
}
