import { createSlice } from '@reduxjs/toolkit'
import { initialModel } from '@src/utils'
import extraReducers from './lib/thunks'
import { CHANGE_USER_CURRENT_ROLE, PS_VERSIONS, LOAD_USER } from './lib/models'

const initialState = {
  auth: null,
  authLoaded: false,
  authLoading: false,
  authError: null,

  [CHANGE_USER_CURRENT_ROLE]: initialModel,
  [PS_VERSIONS]: initialModel,
  [LOAD_USER]: initialModel,
}

const securitySlice = createSlice({
  name: 'security',
  initialState,
  extraReducers,
})

export default securitySlice.reducer
export * from './lib/selectors'
export * from './lib/thunks'
