import { useHistory } from 'react-router-dom'
import { useAudit } from '@src/hooks'

export const LinkWithAudit = ({ to, children, ...props }) => {
  const history = useHistory()
  const logToAudit = useAudit(props)

  const handleClick = (e) => {
    e.preventDefault()
    logToAudit()
    history.push(to)
  }

  return <a onClick={handleClick} href={to}>{children}</a>
}
