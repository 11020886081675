import { useUserHasResource } from '@src/hooks'
import { Acl } from '@src/utils/types'

interface HasResourceProps {
  children: React.ReactNode
  allowAcl: Acl
  deniedComponent?: React.ReactNode | null
}

/**
 * Компонент проверяет есть ли у текущего пользователя хотя бы один из переданных ресурсов доступа (allowAcl),
 * если есть - рендерит children, иначе рендерит deniedComponent
 * @param {HasResourceProps}        HasResourceProps                  - основной props
 * @param {React.ReactNode}         HasResourceProps.children         - компонент, который отображается если есть доступ
 * @param {Acl}                     HasResourceProps.allowAcl         - строка или массив строк ресурсов доступа
 * @param {React.ReactNode | null}  HasResourceProps.deniedComponent  - компонент, который отображается если нет доступа
 */
export const IfHasResource = ({ children, allowAcl, deniedComponent = null }: HasResourceProps) => {
  const has = useUserHasResource(allowAcl)

  return has ? children : deniedComponent
}
