import { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { v4 as uuidv4 } from 'uuid'

import './RadioGroup.scss'

export default class RadioGroup extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    name: PropTypes.string,
    className: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      name: PropTypes.string,
    })),
    direction: PropTypes.bool,
  }

  constructor (...props) {
    super(...props)
    this.group = uuidv4()
  }

  handleChange = (event) => {
    const value = event.currentTarget.value
    this.props.onChange(value)
  }

  render () {
    const { value, name, disabled, className, options, direction } = this.props

    return (
      <div className={cn('root-radio-group', direction ? 'row' : '', className)}>
        {
          options.map((option, index) => (
            <div key={`radio-${name}-${index}`} className='root-radio-group-radio'>
              <input
                type='radio'
                disabled={disabled}
                checked={value === option.value}
                name={this.group}
                value={option.value}
                onChange={this.handleChange}
              />
              <label className={disabled ? 'disabled' : null} htmlFor={`radio-${name}-${index}`}>{option.name}</label>
            </div>
          ))
        }
      </div>
    )
  }
}
