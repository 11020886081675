import { useRef, forwardRef, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Icon } from '@src/components'

import './Accordion.scss'

/**
 * @class Accordion
 * @param title {string} Main inscription (always visible)
 * @param titleData {object} Inserts component in main header
 * @param data {object} An object describing basic information (only visible when the accordion is open)
 * @param className {string} Custom styles
 */
export const Accordion = forwardRef((props, ref) => {
  const [collapsed, setCollapsed]  = useState(props.defaultCollapsed)

  const accordionRef = useRef(null)

  const getComponent = (Component, props) => {
    return <Component {...props} />
  }

  const handleCollapse = () => {
    if((!props.data && !props.dataComponent) || props.disabled)
      return false

    setCollapsed(!collapsed)
  }

  useImperativeHandle(ref, () => {
    return {
      expand () {
        if((!props.data && !props.dataComponent) || props.disabled)
          return false
        setCollapsed(false)
      },
      scrollIntoView () {
        accordionRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',

        })
      },
    }
  }, [props.data, props.dataComponent, props.disabled])

  return (
    <div className={cn(
      'root-accordion',
      props.className,
      (collapsed || props.collapsed) && 'root-accordion-collapsed',
      props.isBlueGray && 'blue-gray',
      props.clinicalRecommendation && 'root-clinical-recommendation',
    )}>
      <div ref={accordionRef} />
      <div
        className={cn('root-accordion-title-container', props.clinicalRecommendation && 'clinical-recommendation-title')}
        onClick={handleCollapse}
      >
        {
          props.clinicalRecommendation
            ? <div className='icon-wrapper-title'><Icon icon='check_cr_fr'/></div>
            : null
        }
        {
          props.index !== undefined
            ? (
              <div className='accordion-title-container-index-board'>
                <span className='accordion-title-container-index-board-index'>{props.index}</span>
                <span className='accordion-title-container-index-board-title' title={props.title}>{props.title}</span>
              </div>
            )
            : <>
              {!!props.title && <span className='accordion-title-container-title' title={props.title}>{props.title}</span>}
              {
                props.clinicalRecommendation
                  ? <div className='clinical-recommendation-title-description'>Выполнено: <span className='count'>2 из 4</span></div>
                  : null
              }
            </>
        }
        <div className={cn('root-accordion-title-container-data-container',
          props.clinicalRecommendation ? 'clinical-recommendation-title-data-container' : '')}>
          {
            props.titleData
              ? getComponent(props.titleData.component, props.titleData.props)
              : null
          }
          {
            props.titleJsx
              ? props.titleJsx
              : null
          }
          {
            (props.data || props.dataComponent) && !props.withoutChevron
              ? <Icon className={cn('root-accordion-icon', (collapsed || props.collapsed) && 'icon-collapsed')} icon='chevron' />
              : null
          }
        </div>
      </div>
      {
        <div className={cn('root-accordion-data', collapsed || props.collapsed ? 'root-accordion-data-collapsed' : '', props.dataContainerClassName)}>
          {
            props.data
              ? props.data.map((data, i) => (
                <span className='root-accordion-data-collapsed-span' key={i}>{data.text}</span>
              ))
              : null
          }
          {
            props.dataComponent
              ? props.dataComponent
              : null
          }
        </div>
      }
    </div>
  )
})

Accordion.propTypes = {
  title: PropTypes.string,
  titleData: PropTypes.shape({
    component: PropTypes.func,
    props: PropTypes.object,
  }),
  titleJsx: PropTypes.node,
  data: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.node,
  })),
  dataComponent: PropTypes.node,
  className: PropTypes.string,
  isBlueGray: PropTypes.bool,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  clinicalRecommendation: PropTypes.bool,
  defaultCollapsed: PropTypes.bool,
  withoutChevron: PropTypes.bool,
  collapsed: PropTypes.bool,
  dataContainerClassName: PropTypes.string,
}
