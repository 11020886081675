import { useCallback, useMemo, useState } from 'react'
import FileSaver from 'file-saver'
import { debounce } from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'
import { JOURNAL__SEMD } from '@src/constants/lib/pathUrls'
import { Combobox, DateRangePicker, MaskedTextInput, Tag, TextInput, DataGrid, AppNav, Button } from '@src/components'
import { AUDIT_PS, NOTIFICATION_TYPES, TAG_COLORS } from '@src/constants'
import { isValidMomentDate } from '@src/utils'
import { selectDictMoModel, selectMclListModel } from '@src/modules/dictionaries/store'
import { loadJournalSemdList, selectExtSystemsListModel, selectJournalSemdListModel, selectSemdDocTypeListModel,
  selectSemdStatusListModel, setJournalFilters, selectJournalFilters } from '@src/modules/journal/store'
import { remotesManager, withAuthorization } from '@src/remotes'
import { pushNotification, selectAccessToken } from '@src/store'
import { ButtonWithAudit } from '@src/components/Button/Button'
import { withMntAudit } from '@src/hoc'
import './JournalSemdListPage.scss'

const _JournalSemdListPage = ({ logToAudit }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [exportLoading, setExportLoading] = useState(false)

  const journalSemdListModel = useSelector(selectJournalSemdListModel)
  const mclList = useSelector(selectMclListModel).payload
  const extSystemsList = useSelector(selectExtSystemsListModel).payload
  const semdStatusList = useSelector(selectSemdStatusListModel).payload
  const semdDocTypeList = useSelector(selectSemdDocTypeListModel).payload
  const organisationsList = useSelector(selectDictMoModel)
  const accessToken = useSelector(selectAccessToken)
  const journalFilters = useSelector(selectJournalFilters)

  const listArgs = useCallback((filters) => {
    if(!filters) return {}

    return {
      dateFrom: isValidMomentDate(filters.inserted_on?.[0], moment().startOf('day')).toISOString(true),
      dateTo: isValidMomentDate(filters.inserted_on?.[1], moment().endOf('day')).toISOString(true),
      calc_count: true,
      ...(filters.message_id ? { messageId: filters.message_id } : null),
      ...(filters.client_message_id ? { clientMessageId: filters.client_message_id } : null),
      ...(filters.description ? { description: filters.description } : null),
      ...(filters.mo_dept_oid ? { moDeptOid: filters.mo_dept_oid } : null),
      ...(filters.ext_system_caption ? { exSystemId: filters.ext_system_caption } : null),
      ...(filters.message_status ? { messageStatusId: filters.message_status } : null),
      ...(filters.doc_type_name ? { docType: filters.doc_type_name.map(i => semdDocTypeList.data.find(j => j.ref_value_uid === i)?.id) } : null),
      ...(filters.stage_name ? { stageName: filters.stage_name } : null),
      ...(filters.vmcl ? { vmcl: filters.vmcl } : null),
      ...(filters.mo_oid ? { moOid: filters.mo_oid } : null),
    }
  }, [semdDocTypeList.data])

  const onPaginationChange = useCallback((value) => {
    const pageSize = value?.pagination?.pageSize || 25
    const currentPage = value?.pagination?.currentPage - 1 || 0
    dispatch(loadJournalSemdList({
      ...listArgs(value?.filters),
      control: {
        range: {
          chunk_start: pageSize * currentPage,
          chunk_end: pageSize * currentPage + pageSize - 1,
        },
        sorts: [{ field: 'inserted_on', sort: 'desc' }],
      },
    }))
  }, [dispatch, listArgs])

  const handleSearch = useCallback((value) => {
    if(!value?.isInvalid) {
      const args = listArgs(value?.filters)

      logToAudit({
        auditPs: AUDIT_PS.JOURNAL,
        auditMessage: 'Поиск в Журнале СЭМД',
        auditDescription: { args },
      })

      onPaginationChange(value)
    }
  }, [listArgs, logToAudit, onPaginationChange])

  const debouncedHandleSearch = debounce(handleSearch, 500)

  const getVmclCaption = useCallback((vmcl) => {
    const mcl = mclList.data.find(mcl => mcl.id === vmcl)
    return mcl ? mcl.short_name : vmcl || '-'
  }, [mclList.data])

  const STAGES = useMemo(() => {
    return {
      XSD: 'проверка XSD',
      FLK: 'проверка ФЛК',
      SaveToDB: 'сохранение в БД',
      Receive: 'получение СЭМД',
    }
  }, [])

  const handleRowDoubleClick = useCallback(row => history.push(`${JOURNAL__SEMD}/${row.message_id}/common`), [history])

  const handleRowAuxClick = useCallback(row => window.open(`${JOURNAL__SEMD}/${row.message_id}/common`, '_blank'), [])

  const getAuthorByMoOid = useCallback((mo_oid) => {
    if (!organisationsList.loaded || !mo_oid)
      return '-'
    else
      return (organisationsList.payload.data.filter(i => String(i.mo_id) === String(mo_oid))[0]?.name_short) || '-'
  }, [organisationsList.loaded, organisationsList.payload.data])

  const tableHeaders = useMemo(() => {
    return {
      inserted_on: {
        width: 200,
        widthExport: 15,
        dataIndex: 'inserted_on',
        dataRender: inserted_on => inserted_on ? moment(inserted_on).local().format('DD.MM.YYYY HH:mm:ss') : '',
        header: {
          title: 'Период',
          component: DateRangePicker,
          props: {
            showTime: true,
            defaultValue: [moment().startOf('day'), moment().endOf('day')],
          },
        },
      },
      mo_oid: {
        width: 120,
        widthExport: 30,
        dataIndex: 'mo_oid',
        dataRender: mo_oid => getAuthorByMoOid(mo_oid),
        header: {
          title: 'МО-автор СЭМД',
          component: Combobox,
          props: {
            placeholder: 'Все',
            showSearch: true,
            options: (organisationsList.payload?.data ?? []).map(i => ({ label: i.name_short, value: i.mo_id })),
            allowClear: true,
          },
        },
      },
      vmcl: {
        dataIndex: 'vmcl_name',
        dataRender: vmcl_name => getVmclCaption(vmcl_name),
        width: 80,
        widthExport: 30,
        header: {
          title: 'Направление ОМП',
          component: Combobox,
          props: {
            placeholder: 'Все',
            options: mclList.data.map(mcl => ({ label: mcl.short_name, value: mcl.id })),
            mode: 'multiple',
            allowClear: true,
          },
        },
      },
      ext_system_caption: {
        dataIndex: 'ext_system_caption',
        width: 100,
        widthExport: 30,
        header: {
          title: 'Внешняя система',
          component: Combobox,
          props: {
            placeholder: 'Все',
            options: extSystemsList.data.map(sys => ({ label: sys.caption, value: sys.id })),
            mode: 'multiple',
            allowClear: true,
          },
        },
      },
      client_message_id: {
        dataIndex: 'client_message_id',
        width: 100,
        widthExport: 45,
        header: {
          title: 'ID сообщения клиента',
          component: MaskedTextInput,
          props: {
            mask: [
              /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, '-',
              /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, '-',
              /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, '-',
              /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, '-',
              /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/,
            ],
            icon: 'search',
            isInvalid: e => e && !(e.length === 0 || e.replace(/[-_*]/g, '').length === 32),
            allowClear: true,
          },
        },
      },
      doc_type_name: {
        dataIndex: 'doc_type_name',
        width: 100,
        widthExport: 30,
        header: {
          title: 'Тип документа',
          component: Combobox,
          props: {
            placeholder: 'Все',
            options: semdDocTypeList.data.map(type => ({ label: type.caption_short_w_code, value: type.ref_value_uid })),
            mode: 'multiple',
            allowClear: true,
          },
        },
      },
      mo_dept_oid: {
        dataIndex: 'mo_dept_oid',
        width: 80,
        widthExport: 30,
        header: {
          title: 'OID СП',
          component: TextInput,
          props: {
            icon: 'search',
            isInvalid: e => e && !(e.length === 0 || e.length > 2),
            allowClear: true,
          },
        },
      },
      message_id: {
        dataIndex: 'message_id',
        width: 130,
        widthExport: 40,
        header: {
          title: 'Регистрационное ID сообщения',
          component: MaskedTextInput,
          props: {
            mask: [
              /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, '-',
              /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, '-',
              /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, '-',
              /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, '-',
              /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/, /[\da-f]/,
            ],
            icon: 'search',
            isInvalid: e => e && !(e.length === 0 || e.replace(/[-_*]/g, '').length === 32),
            allowClear: true,
          },
        },
      },
      message_status: {
        dataIndex: 'message_status',
        dataRender: (message_status, row) => row.message_status_id ? <Tag className='table-row-tag' color={getTagColorByStatusId(row.message_status_id)}>{message_status}</Tag> : message_status,
        width: 100,
        widthExport: 20,
        header: {
          title: 'Статус',
          component: Combobox,
          props: {
            placeholder: 'Все',
            options: semdStatusList.data.map(status => ({ label: status.status, value: status.status_id })),
            mode: 'multiple',
            allowClear: true,
          },
        },
      },
      stage_name: {
        dataIndex: 'stage_name',
        dataRender: stage_name => STAGES[stage_name] ?? '',
        width: 100,
        widthExport: 15,
        header: {
          title: 'Этап ошибки',
          component: Combobox,
          props: {
            placeholder: 'Все',
            options: Object.keys(STAGES).map(key => ({ value: key, label: STAGES[key] })),
            mode: 'multiple',
            allowClear: true,
          },
        },
      },
      description: {
        dataIndex: 'description',
        width: 80,
        widthExport: 50,
        header: {
          title: 'Текст ошибки',
          component: TextInput,
          props: {
            icon: 'search',
            isInvalid: e => e && !(e.length === 0 || e.length > 2),
            allowClear: true,
          },
        },
      },
    }
  }, [STAGES, extSystemsList.data, getAuthorByMoOid, getVmclCaption, mclList.data, organisationsList.payload?.data, semdDocTypeList.data, semdStatusList.data])

  const getTagColorByStatusId = (id) => {
    switch (id) {
    case -1: case 3: case 5: return TAG_COLORS.KRAYOLA_THISTLE
    case 0: case 2: case 4: return TAG_COLORS.BEIGE
    case 1: return TAG_COLORS.GREEN_TEA
    default: return TAG_COLORS.GREY_100
    }
  }

  const handleExport = async () => {
    setExportLoading(true)

    try {
      const response = await remotesManager.BACKEND_API.post('/journal/incoming/get_list', {
        ...listArgs(journalFilters.filters),
        export: {
          ext: 'xlsx',
          type: 'table',
          fields: Object.values(tableHeaders).map(column => ({ key: column.dataIndex, title: column.header.title, width: column.widthExport })),
        },
        control: {
          datamode: 'full',
          sorts: [{ field: 'inserted_on', sort: 'desc' }],
        },
      }, withAuthorization(accessToken, { responseType: 'blob' }))

      const fileName = response.headers?.['content-disposition']?.split('filename=')[1]
      FileSaver.saveAs(response.data, fileName)
    } catch (e) {
      dispatch(pushNotification({ type: NOTIFICATION_TYPES.ERROR, message: e.message }))
    } finally {
      setExportLoading(false)
    }
  }

  const handleResetFilters = () => {
    dispatch(setJournalFilters(null))
    handleSearch()
  }

  return (
    <div className='root-journal-semd-list-page'>
      <AppNav
        title='Журнал СЭМД'
        breadcrumbs={[
          { label: 'Администрирование', link: JOURNAL__SEMD },
          { label: 'Журнал СЭМД', link: JOURNAL__SEMD },
        ]}
      />

      <div className='list-controls'>
        <Button
          label='Сбросить фильтры'
          onClick={handleResetFilters}
          icon='close_cr_fr'
          disabled={!journalFilters?.isDirty}
        />
        <ButtonWithAudit
          icon='exports'
          loading={exportLoading}
          onClick={handleExport}
          label='Экспорт XLS'
          auditPs={AUDIT_PS.JOURNAL}
          auditMessage='Экспорт журнала СЭМД'
        />
      </div>
      <DataGrid
        className='journal-semd-grid'
        columns={tableHeaders}
        data={journalSemdListModel.payload?.data ?? []}
        rowKey='message_id'
        loading={journalSemdListModel.loading}
        error={journalSemdListModel.error}
        value={journalFilters}

        onReset={handleSearch}
        onChange={args => dispatch(setJournalFilters(args))}
        onFilterChange={debouncedHandleSearch}
        onRowDoubleClick={handleRowDoubleClick}
        onRowAuxClick={handleRowAuxClick}
        pagination
        paginationTotal={Number(journalSemdListModel.payload?.dataLength) ?? 0}
        onPaginationChange={onPaginationChange}
      />
    </div>
  )
}

const JournalSemdListPage = withRouter(_JournalSemdListPage)
export const JournalSemdListPageWithAudit = withMntAudit(JournalSemdListPage)
