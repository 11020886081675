import { thunkCreator } from '@src/utils'
import * as models from '../models'

export const [
  loadPatientStatusSszOks, loadPatientStatusSszOksExtra,
] = thunkCreator(models.PATIENT_STATUS_SSZ_OKS, '/patient-monitoring/get_patient_status_ssz_oks',
  { targetModel: models.PATIENT_STATUS })

export const [
  loadPatientStatusSszOnmk, loadPatientStatusSszOnmkExtra,
] = thunkCreator(models.PATIENT_STATUS_SSZ_ONMK, '/patient-monitoring/get_patient_status_ssz_onmk',
  { targetModel: models.PATIENT_STATUS })

export default {
  ...loadPatientStatusSszOksExtra,
  ...loadPatientStatusSszOnmkExtra,
}
