import moment from 'moment'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from '@src/components'
import { ButtonWithAudit } from '@src/components/Button/Button'
import { AUDIT_PS } from '@src/constants'
import { withMntAudit } from '@src/hoc'
import { useMountEffect } from '@src/hooks'
import { TabStatusBase } from '@src/modules/monitoring/components'
import { loadPatientLastEhr, loadPatientStatusOnko, selectPatientLastEhrModel, selectPatientStatusModel } from '@src/modules/monitoring/store'
import { modalService } from '@src/services'
import { RecordModalWithAudit } from '../../components/RecordModal/RecordModal'

const TabStatus = ({ patientId, occasionPatientId, ...props }) => {
  const dispatch = useDispatch()
  const patientStatusModel = useSelector(selectPatientStatusModel)
  const patientLastEhrModel = useSelector(selectPatientLastEhrModel)

  const status = patientStatusModel.payload?.data?.[0]
  const lastEhr = patientLastEhrModel?.payload?.data?.[0]

  useMountEffect(() => {
    dispatch(loadPatientStatusOnko({ args: { patient_id: patientId, occasion_patient_id: occasionPatientId } }))
    dispatch(loadPatientLastEhr({
      args: { patient_id: patientId },
      control: {
        range: { chunk_start: 0, chunk_end: 0 },
        sorts: [
          { field: 'begin_on', sort: 'desc' },
        ],
      },
    }))
  })

  const handleOpenLastEhrProtocol = useCallback(() => modalService.open(RecordModalWithAudit, {
    patientId,
    ehrMsgId: lastEhr.msg_id,
    title: lastEhr.ehr_type_name,
    auditPs: AUDIT_PS.PATIENTMON,
    auditMessage: `Просмотр документа ${lastEhr.ehr_type_name}`,
    auditDescription: { ehr_msg_id: lastEhr.msg_id },
  }), [lastEhr?.ehr_type_name, lastEhr?.msg_id, patientId])

  return <TabStatusBase {...props}>
    <Card
      loading={patientStatusModel.loading}
      error={patientStatusModel.error}
      style={{ flexBasis: '60%' }}
      className='mr-16'
      title='Диагноз'
      data={status && [
        {
          title: 'Первая дата установки диагноза ЗНО',
          data: status.diag_date
            ? moment(status.diag_date).format('DD.MM.YYYY')
            : '—',
        },
        {
          title: 'Дата установки',
          data: status.diag_date_start
            ? moment(status.diag_date_start).format('DD.MM.YYYY')
            : '—',
        },
        {
          title: 'Диагноз МКБ-10',
          data: `${status.mkb_code} ${status.mkb_name}`  || '—',
        },
        {
          title: 'Стадия',
          data: status.current_route_stage || '—',
        },
        {
          title: 'Сторона поражения',
          data: status.side || '—',
        },
        {
          title: 'Метод подтверждения диагноза',
          data: status.confirmation_method || '—',
        },
        {
          title: 'Степень обоснованности диагноза',
          data: status.validity_degree || '—',
        },
        {
          title: 'Отдаленные метастазы',
          data: status.distant_metastases || '—',
        },
        {
          title: 'Сопутствующие диагнозы',
          data: status.concomitant_diagnosis || '—',
        },
      ]}
    />

    <Card
      loading={patientLastEhrModel.loading}
      error={patientLastEhrModel.error}
      style={{ flexBasis: '40%' }}
      title='Последние медицинские документы'
      headerFuncPointer={() => lastEhr && <ButtonWithAudit
        label='Протокол'
        onClick={handleOpenLastEhrProtocol}
        icon='protocol'
      />}
      data={lastEhr && [
        {
          title: 'Исследования',
          data: lastEhr.ehr_type_name || '-',
        },
        {
          title: 'Дата проведения',
          data: lastEhr.begin_on ? moment(lastEhr.begin_on).format('DD.MM.YYYY') : '-',
        },
        {
          title: 'Медицинская организация',
          data: lastEhr.mo_name || '-',
        },
      ]}
    />
  </TabStatusBase>
}

export const TabStatusWithAudit = withMntAudit(TabStatus)

TabStatusWithAudit.tabTitle = 'Статус'
TabStatusWithAudit.tabPath = 'status'
