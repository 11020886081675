import React from 'react'
import { remotesManager } from '@src/remotes'
import { loadConfig } from './index'

export default class ConfigLoader extends React.Component {
  constructor (props) {
    super(props)
    this.state ={
      isLoaded: false,
    }
  }

  componentDidMount () {
    loadConfig().then((config) => {
      remotesManager.initialize(config)
      this.setState({ isLoaded: true })
    })
  }

  render () {
    if(!this.state.isLoaded) {
      return this.props.loading ? this.props.loading() : null
    }

    return this.props.ready(this.state.config)
  }
}
