import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { Tabs } from 'antd'
import { loadPatientInfo, selectPatientInfoModel, resetPatientCard } from '@src/modules/monitoring/store'
import { AppNav } from '@src/components'
import { getFullname } from '@src/utils'
import { withMntAudit } from '@src/hoc'
import './PatientCard.scss'
import { AUDIT_PS } from '@src/constants'

class _PatinetCard extends Component {
  static propTypes = {
    basepath: PropTypes.string.isRequired,
    navTitle: PropTypes.string.isRequired,
    tabs: PropTypes.array,

    patientId: PropTypes.number,
    occasionPatientId: PropTypes.number,
    ehrDescriptionId: PropTypes.number,

    patientInfoModel: PropTypes.object,
    loadPatientInfo: PropTypes.func,

    patientCardTitleRenderer: PropTypes.func,
    patientCardBreadCrumbsTitleRender: PropTypes.func,
  }

  static defaultProps = {
    patientCardTitleRenderer: fullname => fullname,
    patientCardBreadCrumbsTitleRender: data => getFullname(data),
  }

  componentDidMount () {
    this.props.loadPatientInfo({ args: { patient_id: this.props.patientId } })
  }

  componentWillUnmount () {
    this.props.resetPatientCard()
  }

  get path () {
    return `${this.props.basepath}/patient/${this.props.patientId}/${this.props.occasionPatientId}/${this.props.ehrDescriptionId}`
  }

  get tabActiveKey () {
    const Tab = this.props.tabs.find(Tab => this.props.location.pathname.endsWith(Tab.tabPath))
    return Tab?.tabPath || this.props.tabs[0].tabPath
  }

  render () {
    const { patientId, patientInfoModel, occasionPatientId, ehrDescriptionId, tabs, patientCardTitleRenderer, patientCardBreadCrumbsTitleRender } = this.props
    const fullname = getFullname(patientInfoModel?.payload?.data)
    const birthDate = patientInfoModel?.payload?.data?.birth_date
    const sex = patientInfoModel?.payload?.data?.gender

    return (
      <div className='root-patient-card'>
        <AppNav
          title={patientCardBreadCrumbsTitleRender
            ? patientCardBreadCrumbsTitleRender(patientInfoModel?.payload?.data)
            : patientCardTitleRenderer(fullname)
          }
          age={birthDate ? moment().diff(birthDate, 'years') : null}
          sex={sex}
          breadcrumbs={[
            { label: this.props.navTitle, link: this.props.basepath },
            { label: this.props.patientCardTitleRenderer(fullname) },
          ]}
          tabs={tabs.map(Tab => ({ label: Tab.tabTitle, link: `${this.path}/${Tab.tabPath}` }))}
        />
        <Tabs
          activeKey={this.tabActiveKey}
          renderTabBar={() => null}
          items={tabs.map(TabWithAudit => ({
            key: TabWithAudit.tabPath,
            children: <TabWithAudit
              patientId={patientId}
              occasionPatientId={occasionPatientId}
              ehrDescriptionId={ehrDescriptionId}
              auditPs={AUDIT_PS.PATIENTMON}
              auditMessage={`Просмотр вкладки ${TabWithAudit.tabTitle} пациента ${fullname}`}
            />,
          }))}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    patientInfoModel: selectPatientInfoModel(state),
    patientId: Number(ownProps.match.params.patientId),
    occasionPatientId: Number(ownProps.match.params.occasionPatientId),
    ehrDescriptionId: Number(ownProps.match.params.ehrDescriptionId),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPatientCard: args => dispatch(resetPatientCard(args)),
    loadPatientInfo: args => dispatch(loadPatientInfo(args)),
  }
}

const PatientCard = withRouter(connect(mapStateToProps, mapDispatchToProps)(_PatinetCard))
export const PatientCardWithAudit = withMntAudit(PatientCard)
