import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Waypoint } from 'react-waypoint'
import { isNil } from 'lodash'
import { Loader, ErrorText, Combobox } from '@src/components'
import { loadNsiDictVersionRecords, loadNsiDictVersions, selectNsiFrnsiDictRecordsListModal } from '@src/modules/nsi/store'
import './contentTab.scss'

class _ContentTab extends Component {
  static propTypes = {
    refbookId: PropTypes.number,
    dict: PropTypes.object,
    nsiFrnsiDicts: PropTypes.object,

    loadNsiDictVersions: PropTypes.func,
    loadNsiDictVersionRecords: PropTypes.func,
  }

  constructor (props) {
    super(props)

    this.state = {
      currentPage: 1,
      previousLength: 0,
      versionInput: this.props.dict
        ? this.props.dict?.last_version.version_tag
        : null,
    }
  }

  async componentDidMount () {
    const { refbookId, loadNsiDictVersions } = this.props

    if (!this.versions) {
      await loadNsiDictVersions({
        pathParams: { refbook_identifier: refbookId },
        queryParams: { sorts: [{ field: 'version_tag', sort: 'asc' }] },
      })

      const lastVer = this.props.dict.last_version
      if (lastVer) {
        this.loadVersionRecords(lastVer.version_tag)
        this.setState({ versionInput: lastVer.version_tag })
      }
    }
  }

  get currentVersion () {
    return this.state.versionInput
      ? (this.versions?.payload ?? []).find(v => v.version_tag === this.state.versionInput)
      : null
  }

  get headers () {
    const properties = this.currentVersion?.item_schema_definition?.properties || {}
    return Object.keys(properties).map(key => ({ key, title: properties[key].title }))
  }

  get versions () {
    return this.props.nsiFrnsiDicts?.[this.props.refbookId]?.versions
  }

  loadVersionRecords = (tag) => {
    this.props.loadNsiDictVersionRecords({
      pathParams: {
        refbook_identifier: this.props.refbookId,
        version_tag: tag,
      },
      queryParams: {
        limit: this.state.currentPage * 100,
      },
    })
  }

  handleVersionSelect = (val) => {
    this.setState({ versionInput: val, currentPage: 1, previousLength: 0 }, () => this.loadVersionRecords(val))
  }

  handleLoadMore = () => {
    this.setState(p => ({ currentPage: p.currentPage + 1, previousLength: this.props.nsiFrnsiDictRecordsListModal?.payload.length }),
      () => this.loadVersionRecords(this.state.versionInput))
  }

  render () {
    const loadingVersions = !this.versions || this.versions.loading

    return (
      <div className='root-dict-content-tab'>
        {
          loadingVersions
            ? <Loader />
            : this.versions.error
              ? <ErrorText>{this.versions.error}</ErrorText>
              : (
                <div className='dict-content-tab-content-view'>
                  <div className='dict-content-tab-actions'>
                    <div>
                      <label>Версия: </label>
                      <Combobox
                        className='minw-100'
                        showSearch
                        value={this.state.versionInput}
                        onChange={val => this.setState({ versionInput: val }, () => this.handleVersionSelect(val))}
                        options={this.versions.payload.map(v => ({ label: v.version_tag, value: v.version_tag }))}
                      />
                    </div>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        { this.headers.map(h => <th key={h.key + this.state.versionInput}>{h.title}</th>) }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (this.props.nsiFrnsiDictRecordsListModal?.payload ?? []).map((r, rindex) => (
                          <tr key={rindex + this.state.versionInput}>
                            { this.headers.map(header => <td key={header.key}>{r.data[header.key] || ''}</td>) }
                          </tr>
                        ))
                      }
                      {
                        !isNil(this.currentVersion) && this.state.previousLength < this.props.nsiFrnsiDictRecordsListModal?.payload?.length
                          ? <tr className='table-scroll-loader'>
                            <td colSpan={this.headers?.length}>
                              {
                                this.props.nsiFrnsiDictRecordsListModal.loading
                                  ? <Loader className='root-dict-content-tab-loader' />
                                  : <Waypoint onEnter={this.handleLoadMore} />
                              }
                            </td>
                          </tr>
                          : null
                      }
                    </tbody>
                  </table>
                </div>
              )
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    nsiFrnsiDictRecordsListModal: selectNsiFrnsiDictRecordsListModal(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadNsiDictVersions: args => dispatch(loadNsiDictVersions(args)),
    loadNsiDictVersionRecords: args => dispatch(loadNsiDictVersionRecords(args)),
  }
}

export const ContentTab = connect(mapStateToProps, mapDispatchToProps)(_ContentTab)
