import axios from 'axios'

const withAuthorization = (authorization, config = {}) => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Bearer ${authorization}`,
  },
})

class RemotesManager {
  constructor () {
    this.BACKEND_AUTH = null
    this.BACKEND_API = null
    this.GAR_API = null
  }

  initialize (config) {
    this.BACKEND_AUTH =  axios.create({
      baseURL: config.api.auth.uri,
      timeout: 60000,
    })

    this.BACKEND_API =  axios.create({
      baseURL: config.api.api.uri,
      timeout: 60000,
    })

    this.GAR_API =  axios.create({
      baseURL: config.api.api.gar_uri,
      timeout: 60000,
    })
  }
}

const remotesManager = new RemotesManager()

export {
  remotesManager,
  withAuthorization,
}
