import { Component } from 'react'
import PropTypes from 'prop-types'
import { modalService } from '@src/services'
import { MkbSelectModal } from '@src/modals'
import { Mkb10Input } from './Mkb10Input'

export class Mkb10InputWithState extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.shape({
      ref_item_code: PropTypes.string,
      ref_item_id: PropTypes.string,
      _haschilds: PropTypes.bool,
    })),
  }

  constructor (props) {
    super(props)

    this.state = {
      mkbIdInput: (this.props?.value  || []).reduce((acc, mkb) => ({ ...acc, [mkb.ref_item_id]: mkb }), {}),
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        mkbIdInput: (this.props?.value  || []).reduce((acc, mkb) => ({ ...acc, [mkb.ref_item_id]: mkb }), {}),
      })
    }
  }

  handleChange = () => {
    const { mkbIdInput } = this.state

    this.props.onChange(mkbIdInput && Object.keys(mkbIdInput).length
      ? Object.keys(mkbIdInput).map(key => ({
        ref_item_code: mkbIdInput[key].ref_item_code,
        ref_item_id: mkbIdInput[key].ref_item_id,
        haschilds: !!mkbIdInput[key]._haschilds,
      }))
      : []
    )
  }

  handleMkb10Click = () => {
    modalService.open(MkbSelectModal, {
      onSelect: this.handleMkbSelectModalSelect,
      mkbs: this.state.mkbIdInput,
    })
  }

  handleMkbSelectModalSelect = (mkbs) => {
    this.setState({
      mkbIdInput: mkbs,
    }, this.handleChange)
  }

  handleDeleteMkbInput = (mkb_ref_item_id) => {
    this.setState(prevState => ({
      mkbIdInput: Object.entries(prevState.mkbIdInput).reduce((acc, [key, value]) => ({
        ...acc,
        ...(mkb_ref_item_id === value.ref_item_id ? {} : { [key]: value }),
      }), {}),
    }), this.handleChange)
  }

  render () {
    return <Mkb10Input
      addMkbInput={(mkbs) => {
        this.setState({ mkbIdInput: { ...this.state.mkbIdInput, ...mkbs } }, this.handleChange)
      }}
      openMkbModal={this.handleMkb10Click}
      mkbsIdInput={this.state.mkbIdInput}
      icon='link'
      deleteMkbInput={this.handleDeleteMkbInput}
    />
  }
}
