import { Form } from 'antd'
import { get } from 'lodash'
import React, { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { findOneInTree } from '@src/utils'
import { selectAttributesTreeForSelector } from '@src/modules/methodology/store'

// Контекст формы, содержащий текущие номер дизъюнкта и конъюнкта
export const FormContext = React.createContext({})

// По attribute_id формы получаем attribute_node из дерева атрибутов
// По возможности передавать attribute_node через пропсы, тк использование
// useConjunctAttributeNode приводит к обходу дерева
export const useConjunctAttributeNode = () => {
  const [attribute_id] = useConjunctAttributeId()
  const treeData = useSelector(selectAttributesTreeForSelector)

  const attribute_node = useMemo(() => {
    return findOneInTree(treeData, node => node.id === attribute_id)
  }, [attribute_id, treeData])

  return attribute_node
}

// Общий для всех хуков полей формы интерфейс возвращаемого значения:
// [
//   значение поля формы,
//   setter поля формы,
//   shouldUpdate поля формы, возвращает true, если в форме изменилось значение
// ]
const useDisjunct = () => {
  const form = Form.useFormInstance()
  const formContext = useContext(FormContext)

  const namePath = [
    'condition_disjunct',
    formContext.currentDisjunctFieldName,
  ]

  return [
    form.getFieldValue(namePath),
    (value, addNamePath = []) => form.setFieldValue([...namePath, ...addNamePath], value),
    (prevValue, curValue, addNamePath = []) => get(prevValue, [...namePath, ...addNamePath]) !== get(curValue, [...namePath, ...addNamePath]),
  ]
}

const useConjunct = () => {
  const [disjunct, setDisjunct, disjunctShouldUpdate] = useDisjunct()
  const formContext = useContext(FormContext)

  const namePath = [
    'condition_conjunct',
    formContext.currentConjunctFieldName,
  ]

  return [
    get(disjunct, namePath),
    (value, addNamePath = []) => setDisjunct(value, [...namePath, ...addNamePath]),
    (prevValue, curValue, addNamePath = []) => disjunctShouldUpdate(prevValue, curValue, [...namePath, ...addNamePath]),
  ]
}

export const useConjunctAttributeId = () => {
  const [conjunct, setConjunct, conjunctShouldUpdate] = useConjunct()

  return [
    conjunct.attribute_id,
    value => setConjunct(value, ['attribute_id']),
    (prevValue, curValue) => conjunctShouldUpdate(prevValue, curValue, ['attribute_id']),
  ]
}

export const useConjunctOperationId = () => {
  const [conjunct, setConjunct, conjunctShouldUpdate] = useConjunct()

  return [
    conjunct.operation_id,
    value => setConjunct(value, ['operation_id']),
    (prevValue, curValue) => conjunctShouldUpdate(prevValue, curValue, ['operation_id']),
  ]
}

export const useConjunctLogicalNoApplied = () => {
  const [conjunct, setConjunct, conjunctShouldUpdate] = useConjunct()

  return [
    conjunct.logical_no_applied,
    value => setConjunct(value, ['logical_no_applied']),
    (prevValue, curValue) => conjunctShouldUpdate(prevValue, curValue, ['logical_no_applied']),
  ]
}

export const useConjunctConditionDnfId = () => {
  const [conjunct, setConjunct, conjunctShouldUpdate] = useConjunct()

  return [
    conjunct.condition_dnf_id,
    value => setConjunct(value, ['condition_dnf_id']),
    (prevValue, curValue) => conjunctShouldUpdate(prevValue, curValue, ['condition_dnf_id']),
  ]
}

export const useConjunctValueDescription = () => {
  const [conjunct, setConjunct, conjunctShouldUpdate] = useConjunct()

  return [
    conjunct.value_description,
    (value, addNamePath = []) => setConjunct(value, ['value_description', ...addNamePath]),
    (prevValue, curValue) => conjunctShouldUpdate(prevValue, curValue, ['value_description']),
  ]
}
// VD = Value Description
export const useVDTypeCode = () => {
  const [vd, setVd, vdShouldUpdate] = useConjunctValueDescription()

  return [
    vd.type_code,
    value => setVd(value, ['type_code']),
    (prevValue, curValue) => vdShouldUpdate(prevValue, curValue, ['type_code']),
  ]
}
// VD = Value Description
export const useVDValue = () => {
  const [vd, setVd, vdShouldUpdate] = useConjunctValueDescription()

  return [
    vd.value,
    value => setVd(value, ['value']),
    (prevValue, curValue) => vdShouldUpdate(prevValue, curValue, ['value']),
  ]
}
// VD = Value Description
export const useVDValueLabel = () => {
  const [vd, setVd, vdShouldUpdate] = useConjunctValueDescription()

  return [
    vd.value_label,
    value => setVd(value, ['value_label']),
    (prevValue, curValue) => vdShouldUpdate(prevValue, curValue, ['value_label']),
  ]
}
// Если хотя бы один из shouldUpdate возвращает true, то обновить Form.Item
export const shouldUpdateOR = (...shouldUpdates) => (prevValue, curValue) => {
  return shouldUpdates.reduce((acc, cur) => acc || cur(prevValue, curValue), false)
}
