import { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Card } from '@src/components'
import './passportTab.scss'

export class PassportTab extends Component {
  static propTypes = {
    dict: PropTypes.object,
  }

  get passportData () {
    const { dict } = this.props
    var data = []

    for (let id of dict.identifier) {
      data.push({
        title: 'Идентификатор',
        data: Object.keys(id)[0] || '-',
      })
      data.push({
        title: 'Значение',
        data: id[Object.keys(id)[0]] || '-',
      })
    }

    return [
      ...data,
      {
        title: 'Краткое наименование',
        data: dict.short_name || '-',
      },
      {
        title: 'Полное наименование',
        data: dict.full_name || '-',
      },
      {
        title: 'Тип',
        data: dict?.markers?.ref_type?.name || '-',
      },
      {
        title: 'Группа',
        data: dict?.markers?.ref_group?.name || '-',
      },
      {
        title: 'Источник',
        data: dict?.source?.name || '-',
      },
      {
        title: 'Аннотация',
        html: dict.description || '-',
      },
      {
        title: 'Дата последней публикации',
        data: dict?.last_version?.accept_date
          ? moment(dict?.last_version?.accept_date).format('DD.MM.YYYY')
          : '—',
      },
    ]
  }

  render () {
    return (
      <div className='root-dict-passport-tab'>
        <div className='dict-passport-tab-content-view'>
          <Card
            data={this.passportData}
            title='Справочник'
          />
        </div>
      </div>
    )
  }
}
