import cn from 'classnames'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useMountEffect } from '@src/hooks'
import { Button, Combobox, DateRangePicker, ExportXlsWithAudit, LinearProgress, SortIcon } from '@src/components'
import { loadIntegrationList, loadIntegrationFilterMo, loadIntegrationFilterExtSystems, loadIntegrationFilterEhr, loadIntegrationFilterDeparts,
  selectIntegrationReportListModel, selectAnalyticsIntegrationFilterMoModel, selectAnalyticsIntegrationFilterExtSystemsModel,
  selectAnalyticsIntegrationFilterEhrModel, selectAnalyticsIntegrationFilterDepartsModel, resetIntegrationReportState } from '@src/modules/analytics/store'

import { AUDIT_PS } from '@src/constants'
import { withMntAudit } from '@src/hoc'
import './IntegrationReport.scss'

const initialState = {
  moSearch: [],
  extSystemSearch: [],
  ehrSearch: [],
  departsSearch: [],
  sortField: '',
  selectedBeginOn: moment(),
  selectedEndOn: moment(),
  sortAsc: true,
}

const IntegrationReport = ({ mclId, title, xlsTitles, titleNumberOfTransmittedData, tableFiltersData, tableNumberOfTransmittedData }) => {
  const [moSearch, setMoSearch] = useState(initialState.moSearch)
  const [extSystemSearch, setExtSystemSearch] = useState(initialState.extSystemSearch)
  const [ehrSearch, setEhrSearch] = useState(initialState.ehrSearch)
  const [departsSearch, setDepartsSearch] = useState(initialState.departsSearch)
  const [sortField, setSortField] = useState(initialState.sortField)
  const [selectedBeginOn, setSelectedBeginOn] = useState(initialState.selectedBeginOn)
  const [selectedEndOn, setSelectedEndOn] = useState(initialState.selectedEndOn)
  const [sortAsc, setSortAsc] = useState(initialState.sortAsc)

  const dispatch = useDispatch()

  const integrationReportListModel = useSelector(selectIntegrationReportListModel)
  const integrationFilterMoModel = useSelector(selectAnalyticsIntegrationFilterMoModel)
  const integrationFilterrExtSystemsModel = useSelector(selectAnalyticsIntegrationFilterExtSystemsModel)
  const integrationFilterrEhrModel = useSelector(selectAnalyticsIntegrationFilterEhrModel)
  const integrationFilterrDepartsModel = useSelector(selectAnalyticsIntegrationFilterDepartsModel)

  useMountEffect(() => {
    dispatch(loadIntegrationFilterMo({ args: { mcl: mclId } }))
    dispatch(loadIntegrationFilterExtSystems({ args: { mcl: mclId } }))
    dispatch(loadIntegrationFilterEhr({ args: { mcl: mclId } }))
    dispatch(loadIntegrationFilterDeparts({ args: { mcl: mclId } }))

    return () => dispatch(resetIntegrationReportState())
  })

  const moList = useMemo(() => {
    return integrationFilterMoModel.payload ? integrationFilterMoModel.payload.map(v => ({ value: v.name_short })) : []
  }, [integrationFilterMoModel.payload])

  const extSystemsList = useMemo(() => {
    return integrationFilterrExtSystemsModel.payload ? integrationFilterrExtSystemsModel.payload.map(v => ({ value: v.caption })) : []
  }, [integrationFilterrExtSystemsModel.payload])

  const ehrList = useMemo(() => {
    return integrationFilterrEhrModel.payload ? integrationFilterrEhrModel.payload.map(v => ({ value: v.type_ehr_id })) : []
  }, [integrationFilterrEhrModel.payload])

  const departsList = useMemo(() => {
    return integrationFilterrDepartsModel.payload ? integrationFilterrDepartsModel.payload.map(e => ({ value: e.mo_dept_oid })) : []
  }, [integrationFilterrDepartsModel.payload])

  useEffect(() => {
    if(!selectedBeginOn || !selectedEndOn) return false
    dispatch(loadIntegrationList({
      args: {
        filters: {
          mcl: mclId,
          mo_names: moSearch,
          ext_system: extSystemSearch,
          ehr: ehrSearch,
          date_from: selectedBeginOn.format('YYYY-MM-DD'),
          date_to: selectedEndOn.format('YYYY-MM-DD'),
          depart_codes: departsSearch,
        },
      },
    }))
  }, [departsSearch, dispatch, ehrSearch, extSystemSearch, mclId, moSearch, selectedBeginOn, selectedEndOn])

  const xlsData = useMemo(() => {

    return integrationReportListModel.payload
      ? integrationReportListModel.payload.data.map(report => ([
        report.caption ?? '-',
        report.name_short ?? '-',
        report.mo_dept_oid ?? '-',
        report.type_ehr_id ?? '-',
        ...tableNumberOfTransmittedData.map(i => report[i] ? Number(report[i]) : '-'),
      ]))
      : []
  }, [integrationReportListModel.payload, tableNumberOfTransmittedData])

  const handleReset = useCallback(() => {
    setMoSearch(initialState.moSearch)
    setExtSystemSearch(initialState.extSystemSearch)
    setEhrSearch(initialState.ehrSearch)
    setDepartsSearch(initialState.departsSearch)
    setSortField(initialState.sortField)
    setSelectedBeginOn(initialState.selectedBeginOn)
    setSelectedEndOn(initialState.selectedEndOn)
    setSortAsc(initialState.sortAsc)
  }, [setMoSearch, setExtSystemSearch, setEhrSearch, setDepartsSearch, setSortField, setSelectedBeginOn, setSelectedEndOn, setSortAsc])

  const handleSortClick = (field) => {
    setSortAsc(prevState => sortField === field ? !prevState : true)
    setSortField(field)
  }

  const sortFn = useMemo(() => {
    if(tableNumberOfTransmittedData.includes(sortField)) {
      return (a, b) => {
        return sortAsc
          ? ('' + String(get(a, sortField))).localeCompare(get(b, sortField), 'ru-RU', { numeric: true })
          : ('' + String(get(b, sortField))).localeCompare(get(a, sortField), 'ru-RU', { numeric: true })
      }
    }
    else
      return (a, b) => {
        return sortAsc
          ? String(('' + get(a, sortField))).localeCompare(get(b, sortField), 'ru-RU')
          : String(('' + get(b, sortField))).localeCompare(get(a, sortField), 'ru-RU')
      }
  }, [tableNumberOfTransmittedData, sortAsc, sortField])

  const filteredList = useMemo(() => {
    if(integrationReportListModel?.payload?.data) {
      return integrationReportListModel?.payload?.data?.length
        ? [...integrationReportListModel.payload.data].sort(sortFn)
        : []
    }
    return []
  }, [integrationReportListModel?.payload?.data, sortFn])

  const isFilters = useMemo(() => {
    return extSystemSearch.length || moSearch.length || departsSearch.length || ehrSearch.length
  }, [departsSearch, ehrSearch, extSystemSearch, moSearch])

  return (
    <div
      className='root-report-page'
    >
      <LinearProgress className='report-content-loader-overlay' hidden={!integrationReportListModel.loading} />
      <div className='report-content'>
        <div className='report-content-top'>
          <div className='report-filter'>
            <div className='report-filter-item'>
              <label className='report-filter-label'>Период</label>
              <DateRangePicker
                name='date-filter'
                className='report-date-input'
                value={[selectedBeginOn, selectedEndOn]}
                onChange={(dates) => {
                  setSelectedBeginOn(dates[0])
                  setSelectedEndOn(dates[1])
                }}
              />
            </div>
            <div className='report-buttons'>
              <Button
                label='Сбросить'
                onClick={handleReset}
                icon='close_cr_fr'
                title='Сбросить фильтры'
                disabled={
                  !ehrSearch.length && !moSearch.length &&
                  !extSystemSearch.length && !sortField &&
                  selectedBeginOn === initialState.selectedBeginOn &&
                  selectedEndOn === initialState.selectedEndOn
                }
              />
              <ExportXlsWithAudit
                className='filled-button'
                icon='exports'
                xlsData={xlsData}
                disabled={isEmpty(integrationReportListModel?.payload?.data)}
                xlsTitles={xlsTitles}
                fileName={title}
                subTitle={`Период: ${moment(selectedBeginOn).format('DD.MM.YYYY')} - ${moment(selectedEndOn).format('DD.MM.YYYY')}`}
                auditPs={AUDIT_PS.ANALYTIC}
                auditMessage={`Экспорт таблицы ${title}`}
              />
            </div>
          </div>
        </div>
        <div className={cn('report-content-view', integrationReportListModel.loading ? 'report-content-view-loading' : null)}>
          <table>
            <thead>
              <tr>
                <th rowSpan='2'>
                  <div className='table-header'>
                    <div className='table-header-container'>
                      <div className='table-header-title' title='Внешнаяя система'>Внешнаяя система</div>
                      <SortIcon
                        className='sort-icon'
                        sortField='caption'
                        currentSortField={sortField}
                        sortType={sortAsc}
                        onClick={() => handleSortClick('caption')}
                      />
                    </div>
                    <Combobox
                      className='w-100p'
                      onChange={(value) => {
                        setExtSystemSearch(value)
                      }}
                      value={extSystemSearch}
                      options={extSystemsList}
                      loading={integrationFilterrExtSystemsModel.loading}
                      mode='multiple'
                      allowClear
                      disabled={!integrationReportListModel?.payload?.data.length && !isFilters}
                    />
                  </div>
                </th>
                <th rowSpan='2'>
                  <div className='table-header'>
                    <div className='table-header-container'>
                      <div className='table-header-title' title='Наименование МО'>Наименование МО</div>
                      <SortIcon
                        className='sort-icon'
                        sortField='name_short'
                        currentSortField={sortField}
                        sortType={sortAsc}
                        onClick={() => handleSortClick('name_short')}
                      />
                    </div>
                    <Combobox
                      className='w-100p'
                      onChange={(value) => {
                        setMoSearch(value)
                      }}
                      value={moSearch}
                      options={moList}
                      loading={integrationFilterMoModel.loading}
                      mode='multiple'
                      allowClear
                      disabled={!integrationReportListModel?.payload?.data.length && !isFilters}
                    />
                  </div>
                </th>
                <th rowSpan='2'>
                  <div className='table-header'>
                    <div className='table-header-container'>
                      <div className='table-header-title' title='OID СП'>OID СП</div>
                      <SortIcon
                        className='sort-icon'
                        sortField='mo_dept_oid'
                        currentSortField={sortField}
                        sortType={sortAsc}
                        onClick={() => handleSortClick('mo_dept_oid')}
                      />
                    </div>
                    <Combobox
                      className='w-100p'
                      onChange={(value) => {
                        setDepartsSearch(value)
                      }}
                      value={departsSearch}
                      options={departsList}
                      loading={integrationFilterrDepartsModel.loading}
                      mode='multiple'
                      allowClear
                      disabled={!integrationReportListModel?.payload?.data.length && !isFilters}
                    />
                  </div>
                </th>
                <th rowSpan='2'>
                  <div className='table-header'>
                    <div className='table-header-container'>
                      <div className='table-header-title' title='Тип СЭМД'>Тип СЭМД</div>
                      <SortIcon
                        className='sort-icon'
                        sortField='type_ehr_id'
                        currentSortField={sortField}
                        sortType={sortAsc}
                        onClick={() => handleSortClick('type_ehr_id')}
                      />
                    </div>
                    <Combobox
                      className='w-100p'
                      onChange={(value) => {
                        setEhrSearch(value)
                      }}
                      value={ehrSearch}
                      options={ehrList}
                      loading={integrationFilterrEhrModel.loading}
                      mode='multiple'
                      allowClear
                      disabled={!integrationReportListModel?.payload?.data.length && !isFilters}
                    />
                  </div>
                </th>
                <th colSpan={titleNumberOfTransmittedData.colSpan} width={titleNumberOfTransmittedData.width}>
                  <div className='table-header table-header-center' title={titleNumberOfTransmittedData.title}>{titleNumberOfTransmittedData.title}</div>
                </th>
              </tr>
              <tr>
                {
                  tableFiltersData.map(field => (
                    <th key={`table-filter-${field.title}`}>
                      <div className='table-header'>
                        <div className='table-header-container'>
                          <div className='table-header-title group' title={field.title}>{field.title}</div>
                          <SortIcon
                            className='sort-icon'
                            sortField={field.name}
                            currentSortField={sortField}
                            sortType={sortAsc}
                            onClick={() => handleSortClick(field.name)}
                          />
                        </div>
                      </div>
                    </th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              {
                filteredList.map((report, reportIndex) => (
                  <tr key={`report-record-${reportIndex}`}>
                    <td>{report.caption || '-'}</td>
                    <td>{report.name_short || '-'}</td>
                    <td>{report.mo_dept_oid || '-'}</td>
                    <td>{report.type_ehr_id || '-'}</td>
                    {
                      tableNumberOfTransmittedData.map((field, fieldIndex) => <td key={`report-record-${reportIndex}-${fieldIndex}`}>{report[field]}</td>)
                    }
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const IntegrationReportExternal = ({ mclId, title }) => {

  const data = {
    mclId,
    title,

    xlsTitles: [
      'Внешняя система',
      'Наименование МО',
      'OID СП',
      'Тип СЭМД',
      'Общее количество переданных в BARS.STAR СЭМД',
      'Обработаны успешно',
      'Находятся в обработке',
      'С ошибкой обработки',
    ],

    titleNumberOfTransmittedData: {
      colSpan: 4,
      title: 'Количество переданных в BARS.STAR СЭМД',
      width: 360,
    },

    tableFiltersData: [
      {
        title: 'Всего',
        name: 'cnt_ehr_id_all_external',
      },
      {
        title: 'Обработаны успешно',
        name: 'cnt_ehr_id_success',
      },
      {
        title: 'Находятся в обработке',
        name: 'cnt_ehr_id_in_work',
      },
      {
        title: 'С ошибкой обработки',
        name: 'cnt_ehr_id_error',
      },
    ],

    tableNumberOfTransmittedData: [
      'cnt_ehr_id_all_external',
      'cnt_ehr_id_success',
      'cnt_ehr_id_in_work',
      'cnt_ehr_id_error',
    ],
  }

  return <IntegrationReport {...data}/>
}

const IntegrationReportVimis = ({ mclId, title }) => {

  const data = {
    mclId,
    title,

    xlsTitles: [
      'Внешняя система',
      'Наименование МО',
      'OID СП',
      'Тип СЭМД',
      'Всего',
      'Отправлено в ВИМИС',
      'Принято в ВИМИС',
      'Ошибка обработки в ВИМИС',
      'Не удалось отправить в ВИМИС',
    ],

    titleNumberOfTransmittedData: {
      colSpan: 5,
      title: 'Количество переданных СЭМД в ВИМИС',
      width: 800,
    },

    tableFiltersData: [
      {
        title: 'Всего',
        name: 'cnt_ehr_id_all_vimis',
      },
      {
        title: 'Отправлено в ВИМИС',
        name: 'cnt_ehr_id_send_vimis',
      },
      {
        title: 'Принято в ВИМИС',
        name: 'cnt_ehr_id_recive_vimis',
      },
      {
        title: 'Ошибка обработки в ВИМИС',
        name: 'cnt_ehr_id_error_vimis',
      },
      {
        title: 'Не удалось отправить в ВИМИС',
        name: 'cnt_ehr_id_not_send_vimis',
      },
    ],

    tableNumberOfTransmittedData: [
      'cnt_ehr_id_all_vimis',
      'cnt_ehr_id_send_vimis',
      'cnt_ehr_id_recive_vimis',
      'cnt_ehr_id_error_vimis',
      'cnt_ehr_id_not_send_vimis',
    ],
  }

  return <IntegrationReport {...data}/>
}

export const IntegrationReportExternalWithAudit = withMntAudit(IntegrationReportExternal)
export const IntegrationReportVimisWithAudit = withMntAudit(IntegrationReportVimis)
