import { TextInput } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class DueDateColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.DUE_DATE

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Срок гестации (недель, дней)',
        component: TextInput,
        defaultValue: '',
        props: {
          icon: 'search',
          allowClear: true,
        },
      },
      width: 150,
      dataIndex: 'due_date',
      dataRender: due_date => due_date ? due_date : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let requestFilters = []

    if (filters?.due_date) {
      requestFilters = [{ field: 'due_date', value: filters?.due_date, operator: 'like_both', invalid: false }]
    }

    if (nullSearches[REGISTER_COLUMNS.DUE_DATE]) {
      requestFilters = [{ field: REGISTER_COLUMNS.DUE_DATE, value: null, operator: '=', invalid: false }]
    }

    return {
      control: {
        filters: requestFilters,
      },
    }
  }
}
