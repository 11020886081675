import { isString } from 'lodash'
import { store } from '@src/store'
import { concatObjects } from '@src/utils'

import * as Columns from './Columns'

export class RegisterColumnsController {
  constructor (initialColumns = []) {
    this._columns = initialColumns.map((column) => {
      const { type, settings } = isString(column) ? { type: column, settings: {} } : column

      const Column = Object.values(Columns).find(col => col.type === type)
      return new Column(store, settings)
    })
  }

  get columns () {
    return this._columns.reduce((acc, col) => ({
      ...acc,
      [col.type]: col.column,
    }), {})
  }

  requestArgs = (...args) => {
    return this._columns.reduce((acc, col) => concatObjects(acc, col.requestArgs(...args)), {})
  }
}
