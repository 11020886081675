import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import { Button, Checkbox, Drawer, Loader, TextInput } from '@src/components'
import { loadMkb10Diagnosis, selectMkb10Diagnosis } from '@src/modules/monitoring/store'

import './MkbSelectModal.scss'

const initialState = {
  selectedMkbIds: {},
  expandedMkbs: {},
  filterMkbText: '',
}

class _MkbSelectModal extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    onSelect: PropTypes.func,
    mkbs: PropTypes.object,

    mkb10Diagnosis: PropTypes.object,
  }

  constructor (props) {
    super(props)

    this.state = {
      ...initialState,
      selectedMkbIds: props.mkbs || {},
    }
    this.debouncedHandleSearch = debounce(this.loadFilteredMkb, 600)
  }

  componentDidMount () {
    this.props.loadMkb10Diagnosis({ args: {}, controls: {} })
  }

  componentDidUpdate (prevProps) {
    if((prevProps.mkb10Diagnosis !== this.props.mkb10Diagnosis) && this.state.filterMkbText) {
      const allSetExpand = (arr) => {
        arr?.forEach((i) => {
          !this.state.expandedMkbs[i.ref_item_id] && this.switchExpandMkb(i)
          i?.children?.length && allSetExpand(i.children)
        })
      }
      allSetExpand(this.props.mkb10Diagnosis['root']?.payload?.data)
    }
  }

  handleReset = () => {
    this.setState({ ...initialState })
    this.props.loadMkb10Diagnosis({ args: {}, controls: {} })
  }

  handleSelect = () => {
    const { selectedMkbIds } = this.state

    const mkbs = Object.keys(selectedMkbIds)
      .filter(key => !!selectedMkbIds[key])
      .reduce((acc, cur) => {
        return {
          ...acc,
          [cur]: selectedMkbIds[cur],
        }
      }, {})

    this.props.onSelect(mkbs)
    this.props.onClose()
  }

  recursiveGetMkbs = (mkb) => {
    const { expandedMkbs } = this.state
    const { mkb10Diagnosis } = this.props
    const arrMkbs = this.state.filterMkbText ? mkb.children : mkb10Diagnosis[mkb.ref_item_id]?.payload?.data
    const childs = arrMkbs && mkb._haschilds && expandedMkbs[mkb.ref_item_id]
      ? arrMkbs.reduce((acc, m) => {
        return [
          ...acc,
          ...this.recursiveGetMkbs(m),
        ]
      }, [])
      :[]

    return [mkb, ...childs]
  }

  recursiveGetMkbParents = (mkb) => {
    const { selectedMkbIds } = this.state

    return [
      mkb,
      ...(mkb.ref_parent_id === null || !selectedMkbIds[mkb.ref_parent_id]
        ? []
        : this.recursiveGetMkbParents(selectedMkbIds[mkb.ref_parent_id])
      ),
    ]
  }

  recursiveSelect = (mkb) => {
    const mkbs = this.recursiveGetMkbs(mkb).reduce((acc, cur) => {
      return {
        ...acc,
        [cur.ref_item_id]: cur,
      }
    }, {})

    this.setState(prevState => ({
      selectedMkbIds: {
        ...prevState.selectedMkbIds,
        ...mkbs,
      },
    }))
  }

  recursiveDeselect = (mkb) => {
    const mkbChilds = this.recursiveGetMkbs(mkb)
    const mkbParents = this.recursiveGetMkbParents(mkb)

    const mkbs = [...mkbChilds, ...mkbParents].reduce((acc, cur) => {
      return {
        ...acc,
        [cur.ref_item_id]: false,
      }
    }, {})

    this.setState(prevState => ({
      selectedMkbIds: {
        ...prevState.selectedMkbIds,
        ...mkbs,
      },
    }))
  }

  handleMkbRowSelect = (mkb) => {
    this.state.selectedMkbIds[mkb.ref_item_id] ? this.recursiveDeselect(mkb) : this.recursiveSelect(mkb)
  }

  loadFilteredMkb = (text) => {
    const { filterMkbText } = this.state
    const { loadMkb10Diagnosis } = this.props
    filterMkbText.length > 2 && loadMkb10Diagnosis({ args: {}, controls: {}, hidValue: null, filterValue: text, makeTree: true })
    if(!filterMkbText) {
      this.props.loadMkb10Diagnosis({ args: {}, controls: {} })
      this.setState({ expandedMkbs: {} })
    }
  }

  handleMkbFilter = (text) => {
    this.setState({ filterMkbText: text })
    this.debouncedHandleSearch(text)
  }

  switchExpandMkb = async (mkb) => {
    const { mkb10Diagnosis, loadMkb10Diagnosis } = this.props
    const { selectedMkbIds } = this.state

    if (mkb._haschilds && !this.state.filterMkbText && (!mkb10Diagnosis[mkb.ref_item_id] || mkb10Diagnosis[mkb.ref_item_id].error)) {
      await loadMkb10Diagnosis({ args: {}, controls: {}, hidValue: mkb.ref_item_id })
    }
    this.setState(prevState => ({
      expandedMkbs: {
        ...prevState.expandedMkbs,
        [mkb.ref_item_id]: !prevState.expandedMkbs[mkb.ref_item_id],
      },
    }), () => selectedMkbIds[mkb.ref_item_id] && this.recursiveSelect(mkb))
  }

  renderMkbSubTree = (hidValue) => {
    const { mkb10Diagnosis } = this.props
    const { expandedMkbs, selectedMkbIds } = this.state
    const arrMkbDiagnosis = Array.isArray(hidValue) ? hidValue : mkb10Diagnosis[hidValue]?.payload?.data

    return arrMkbDiagnosis?.map(mkb => (
      <Fragment key={`mkb-${mkb.ref_item_id}`}>
        <li className='mkb-select-modal-mkb'>
          {
            mkb10Diagnosis[mkb.ref_item_id]?.loading
              ? <Loader small />
              :  mkb._haschilds
                ? <span className='expand' onClick={() => this.switchExpandMkb(mkb)}>{expandedMkbs[mkb.ref_item_id] ? '-' : '+'}</span>
                : <span className='expand placeholder'>=</span>
          }
          <Checkbox
            className='mkb-select-modal-mkb-checkbox'
            onChange={() => this.handleMkbRowSelect(mkb)}
            value={!!selectedMkbIds[mkb.ref_item_id]}
          />
          <div
            className='mkb-select-modal-mkb-title'
            title={mkb.full_mkb_name}
            onClick={() => this.handleMkbRowSelect(mkb)}
          >{mkb.full_mkb_name}</div>
        </li>
        {
          mkb._haschilds && expandedMkbs[mkb.ref_item_id]
            ? mkb10Diagnosis[mkb.ref_item_id]?.loaded || this.state.filterMkbText
              ? (
                <ul>
                  {
                    this.state.filterMkbText && !!mkb?.children?.length
                      ? this.renderMkbSubTree(mkb.children)
                      : this.renderMkbSubTree(mkb.ref_item_id)
                  }
                </ul>
              )
              : null
            : null
        }
      </Fragment>
    ))
  }

  renderMkbTree = () => {
    return <ul className='tree-root'>
      { this.renderMkbSubTree('root') }
    </ul>
  }

  keyHandler = ({ key }) => {
    if (key === 'Enter') this.handleSelect()
  }

  render () {
    const { mkb10Diagnosis, ...props } = this.props

    return (
      <Drawer
        {...props}
        title='Диагноз МКБ-10'
        className='root-mkb-select-modal'
        width={1000}
        footer={<DrawerFooter onReset={this.handleReset} onSelect={this.handleSelect} />}
        onKeyDown={this.keyHandler}
      >
        <div className='mkb-select-modal-content'>
          <TextInput
            autofocus
            className='mb-8'
            name='MbkFilterInput'
            value={this.state.filterMkbText}
            onChange={this.handleMkbFilter}
            icon='search'
            placeholder= 'Наименование'
          />
          {
            mkb10Diagnosis?.root?.loaded
              ? (
                <div className='mkb-select-modal-tree'>
                  { this.renderMkbTree() }
                </div>
              )
              : <Loader />
          }
        </div>
      </Drawer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    mkb10Diagnosis: selectMkb10Diagnosis(state),
  }
}

const mapDispatchToProps = dispatch => ({
  loadMkb10Diagnosis: args => dispatch(loadMkb10Diagnosis(args)),
})

export const MkbSelectModal = connect(mapStateToProps, mapDispatchToProps)(_MkbSelectModal)

const DrawerFooter = ({ onReset, onSelect }) => {
  return (
    <div className='mkb-select-modal-actions'>
      <Button
        label='Очистить выбранное'
        onClick={onReset}
      />
      <Button
        className='ml-8'
        label='Выбрать'
        isSuccess
        onClick={onSelect}
      />
    </div>
  )
}
