import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Card, LinkWithAudit } from '@src/components'
import { loadPatientStatusOcc, selectPatientStatusModel } from '@src/modules/monitoring/store'
import { TabStatusBase } from '@src/modules/monitoring/components'
import { getFullname } from '@src/utils'
import { AUDIT_PS, URLS } from '@src/constants'
import { useMountEffect } from '@src/hooks'
import { withMntAudit } from '@src/hoc'

const TabStatus = ({ patientId, occasionPatientId, ...props }) => {
  const dispatch = useDispatch()
  const patientStatusModel = useSelector(selectPatientStatusModel)

  const status = patientStatusModel.payload?.data

  useMountEffect(() => {
    if (!patientStatusModel.loaded && !patientStatusModel.loading) {
      dispatch(loadPatientStatusOcc({ args: { card_type: '4', patient_id: patientId, occasion_patient_id: occasionPatientId } }))
    }
  })

  return <TabStatusBase {...props}>
    <Card
      title='Новорожденный'
      loading={patientStatusModel.loading}
      error={patientStatusModel.error}
      style={{ flexBasis: '40%' }}
      className='mr-16'
      data={status && [
        {
          title: 'ФИО матери',
          data: getFullname(status),
        },
        {
          title: 'Пол',
          data: status.gender ?? '-',
        },
        {
          title: 'Дата рождения',
          data: status.birth_date ? moment(status.birth_date).format('DD.MM.YYYY') : '-',
        },
        {
          title: 'Дата перинатальной смерти',
          data: status.death_date ? moment(status.death_date).format('DD.MM.YYYY H:mm') : '-',
        },
        {
          title: 'Масса тела при рождении(грамм)',
          data: status.birthweight ?? '-',
        },
        {
          title: 'Длина тела при рождении(см)',
          data: status.newborn_length ?? '-',
        },
        {
          title: 'Номер при рождении',
          data: status.child_number ?? '-',
        },
        {
          title: 'Дата выписки из стационара',
          data: status.discharge_date ? moment(status.discharge_date).format('DD.MM.YYYY') : '-',
        },
        {
          title: 'Исход пребывания в стационаре',
          data: status.hospital_result ?? '-',
        },
      ]}
    />
    <div style={{ flexBasis: '60%' }}>
      <Card
        title='Диагноз'
        loading={patientStatusModel.loading}
        error={patientStatusModel.error}
        className='mb-16'
        data={status && [
          {
            title: 'Основной диагноз',
            data: status.diagnosis || '—',
          },
          {
            title: 'Сопутствующее заболевание',
            data: status.concomitant_diagnosis || '—',
          },
        ]}
      />
      <Card
        title='Данные о матери'
        loading={patientStatusModel.loading}
        error={patientStatusModel.error}
        data={status && [
          {
            title: 'Фамилия Имя Отчество',
            data: () => <LinkWithAudit
              to={`${URLS.MONITORING__AKINEO__PREGNANT}/patient/${status.mother_patient_id}/${status.mother_occasion_patient_id}/status`}
              auditPs={AUDIT_PS.PATIENTMON}
              auditMessage='Просмотр карточки матери'
            >{getFullname(status)}</LinkWithAudit>,
          },
          {
            title: 'Дата рождения',
            data: status.mother_birthdate ? moment(status.mother_birthdate).format('DD.MM.YYYY') : '-',
          },
          {
            title: 'Возраст',
            data: status.mother_birthdate ? moment().diff(status.mother_birthdate, 'years') : '',
          },
        ]}
      />
    </div>
  </TabStatusBase>
}


export const TabStatusWithAudit = withMntAudit(TabStatus)

TabStatusWithAudit.tabTitle = 'Статус'
TabStatusWithAudit.tabPath = 'status'
