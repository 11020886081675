import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { selectOperationValueIdListModel } from '@src/modules/methodology/store'
import { ActionMenu, Icon } from '@src/components'
import { AccessContext } from '@src/context'
import { VALUE_CODE_DEFAULT_VALUE_MAP } from '@src/modules/methodology/pages/MethRegConditionsPage/AttributesDrawer/constants'
import { useConjunctAttributeNode, useConjunctOperationId, useConjunctLogicalNoApplied, useVDTypeCode, useVDValue, useVDValueLabel } from './FormContext'
import { getAvailableOperationList } from './utils'

export const OperationSelector = () => {
  const accessContext = useContext(AccessContext)
  const attribute_node = useConjunctAttributeNode()
  const operationValueIdListModel = useSelector(selectOperationValueIdListModel)

  const [operation_id, setOperationId] = useConjunctOperationId()
  const [logical_no_applied, setLogicalNoApplied] = useConjunctLogicalNoApplied()
  const [vdTypeCode, setVDTypeCode] = useVDTypeCode()
  const [, setVDValue] = useVDValue()
  const [, setVDValueLabel] = useVDValueLabel()

  const operationOptions = useMemo(() => {
    const operations = getAvailableOperationList(operationValueIdListModel.payload?.data, attribute_node.attr_type_id)
    return operations.map((op) => {
      const caption = op.logical_no_applied
        ? `Не ${op.operation_name?.toLowerCase()}`
        : op.operation_name

      return {
        ...op,
        iconTitle: caption,
        label: caption,
        onClick: (operation_option) => {
          setOperationId(operation_option.operation_id)
          setLogicalNoApplied(operation_option.logical_no_applied)

          // Если изменился type_code значения, с которым работает операция, сбрасываем значение на дефолтное
          if (vdTypeCode !== operation_option.value_type_code) {
            setVDTypeCode(operation_option.value_type_code)
            setVDValue(VALUE_CODE_DEFAULT_VALUE_MAP[operation_option.value_type_code])
            setVDValueLabel(null)
          }
        },
      }
    })
  }, [attribute_node.attr_type_id, operationValueIdListModel.payload?.data, setLogicalNoApplied, setOperationId, setVDTypeCode, setVDValue, setVDValueLabel, vdTypeCode])

  const selectedOperation = operationOptions.find(operation_option => operation_option.operation_id === operation_id
    && operation_option.logical_no_applied === logical_no_applied)

  return <ActionMenu options={operationOptions} disabled={accessContext.readonly}>
    <div
      className={cn('root-operation-selector w-32 h-32 minw-32 mx-8 d-flex align-items-center justify-content-center', accessContext.readonly && 'disabled')}
      title={selectedOperation?.label}
    >
      {selectedOperation?.icon && <Icon className='h-16 w-16' icon={selectedOperation?.icon} />}
    </div>
  </ActionMenu>

}
