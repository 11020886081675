import { Combobox } from '@src/components'
import { selectDictMoModel } from '@src/modules/dictionaries/store'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class DnMoId extends RegisterColumn {
  static type = REGISTER_COLUMNS.DN_MO_ID

  constructor (store, settings) {
    const column = {
      header: {
        title: 'МО, осуществляющая ДН',
        component: Combobox,
        props: {
          allowClear: true,
          mode: 'multiple',
          options: selectDictMoModel(store.getState()).payload?.data?.map(mo => ({
            label: mo.name_full,
            value: mo.mo_id,
            title: mo.name_full,
          })),
        },
        defaultValue: [],
      },
      width: 300,
      dataIndex: 'mo_name', // mo_oid
      dataRender: mo_name => mo_name ? mo_name : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let requestArgs = []

    if (filters?.mo_name && filters?.mo_name.length) {
      requestArgs = [{ field: 'mo_oid', value: filters.mo_name, operator: '=', invalid: false, cast: 'right-any' }]
    }

    if (nullSearches[this.type]) {
      requestArgs = [{ field: 'mo_oid', operator: '=', value: null, invalid: false }]
    }

    return {
      control: {
        filters: requestArgs,
      },
    }
  }
}
