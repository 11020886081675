import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { AppNav, InternalError404 } from '@src/components'
import { AUDIT_PS } from '@src/constants'
import { useMountEffect } from '@src/hooks'
import { PatientCardWithAudit, PatientListWithAudit } from '@src/modules/monitoring/components'
import { resetPatientList } from '@src/modules/monitoring/store'
import { usePageAppSettings } from '@src/hooks/useAppSettings'

export const Register = ({
  list_type,
  columns,
  tabs,
  basepath,
  navTitle,
  patientCardTitleRenderer,
  patientCardBreadCrumbsTitleRender,
  rowKey,
}) => {
  // Проверка occasion, если нет то Nan для корректной работы ссылки, иначе не обозначается текущая вкладка
  const getPatientCardLinkPath = useCallback(({ patient_id, occasion_patient_id, ehr_description_id }) => {
    return `${basepath}/patient/${patient_id}/${occasion_patient_id ? occasion_patient_id : NaN}/${ehr_description_id}/status`
  }, [basepath])

  const dispatch = useDispatch()

  useMountEffect(() => {
    return () => dispatch(resetPatientList())
  })

  const pageAppSettings = usePageAppSettings()

  const enabledColumns = useMemo(() => {
    return columns.filter((column) => {
      const column_type = column?.type || column
      return !pageAppSettings?.columns?.[column_type]?.disabled
    })
  }, [columns, pageAppSettings])

  return (
    <Switch>
      <Route path={`${basepath}/patient/:patientId/:occasionPatientId/:ehrDescriptionId/`}>
        <PatientCardWithAudit
          basepath={basepath}
          navTitle={navTitle}
          tabs={tabs}
          patientCardTitleRenderer={patientCardTitleRenderer}
          patientCardBreadCrumbsTitleRender={patientCardBreadCrumbsTitleRender}
          auditPs={AUDIT_PS.PATIENTMON}
          auditMessage={`Просмотр карточки пациента раздела "${navTitle}"`}
        />
      </Route>

      <Route path={basepath}>
        <AppNav
          title={navTitle}
          breadcrumbs={[
            {
              label: navTitle,
              link: basepath,
            },
          ]}
        />
        <PatientListWithAudit
          title={navTitle}
          list_type={list_type}
          getPatientCardLinkPath={getPatientCardLinkPath}
          columns={enabledColumns}
          auditPs={AUDIT_PS.PATIENTMON}
          auditMessage={`Просмотр раздела ${navTitle}`}
          rowKey={rowKey}
        />
      </Route>
      <Route component={InternalError404} />
    </Switch>
  )
}

Register.propTypes = {
  list_type: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  basepath: PropTypes.string.isRequired,
  navTitle: PropTypes.string.isRequired,
  tabs: PropTypes.array,
  patientCardTitleRenderer: PropTypes.func,
  patientCardBreadCrumbsTitleRender: PropTypes.func,
}
