import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useParams } from 'react-router-dom'

import { AppNav, InternalError404, LoadedOrError } from '@src/components'
import { JOURNAL__SEMD } from '@src/constants/lib/pathUrls'
import { useLoadModels, useMountEffect } from '@src/hooks'
import { LongDataModal } from '@src/modals'
import { loadJournalMessageAttributes, loadJournalMsgIndex, loadJournalOutgoingMessage, loadJournalProcessingLog, selectMessageAttributesModel,
  selectMsgIndexModel, selectOutgoingMessageModel, selectProcessingLogModel } from '@src/modules/journal/store'
import { modalService } from '@src/services'

import { withMntAudit } from '@src/hoc'
import { AUDIT_PS } from '@src/constants'
import { CommonTabWithAudit } from './tabs/CommonTab/CommonTab'
import { OutgoingTabWithAudit } from './tabs/OutgoingTab/OutgoingTab'
import { ProcessingTabWithAudit } from './tabs/ProcessingTab/ProcessingTab'

import './JournalSemdDetailsPage.scss'

const JournalSemdDetailsPage = () => {
  const { msgId } = useParams()

  const dispatch = useDispatch()

  const journalMessageAttributesModel = useSelector(selectMessageAttributesModel)
  const journalOutgoingMessageModel = useSelector(selectOutgoingMessageModel)
  const journalProcessingLogModel = useSelector(selectProcessingLogModel)
  const journalMsgIndexModel = useSelector(selectMsgIndexModel)

  const [loaded, loading, error] = useLoadModels(
    [journalMessageAttributesModel, loadJournalMessageAttributes],
    [journalOutgoingMessageModel, loadJournalOutgoingMessage],
    [journalProcessingLogModel, loadJournalProcessingLog],
    [journalMsgIndexModel, loadJournalMsgIndex],
  )


  const handleModalShow = (data) => {
    modalService.open(LongDataModal, {
      title: 'Содержимое документа',
      data: data,
    })
  }

  useMountEffect(() => {
    dispatch(loadJournalMessageAttributes({ msgId }))
    dispatch(loadJournalOutgoingMessage({ msgId }))
    dispatch(loadJournalProcessingLog({ msgId }))
    dispatch(loadJournalMsgIndex({ msgId }))
  })

  return (
    <div className='root-journal-semd-details-page'>
      <AppNav
        title='Просмотр данных запроса'
        breadcrumbs={[
          {
            label: 'Администрирование',
            link: JOURNAL__SEMD,
          },
          {
            label: 'Журнал СЭМД',
            link: `${JOURNAL__SEMD}`,
          },
          {
            label: 'Просмотр данных запроса',
          },
        ]}
        tabs={[
          { label: 'Общие сведения', link: `${JOURNAL__SEMD}/${msgId}/common` },
          { label: 'Обработка', link: `${JOURNAL__SEMD}/${msgId}/processing` },
          { label: 'Исходящие сообщения', link: `${JOURNAL__SEMD}/${msgId}/outgoing` },
        ]}
      />
      {
        <LoadedOrError loaded={loaded} loading={loading} error={error}>
          <Switch>
            <Route path={`${JOURNAL__SEMD}/:msgId/common`}>
              <CommonTabWithAudit
                infoMsg={journalMsgIndexModel.payload?.data}
                attributes={journalMessageAttributesModel.payload?.data}
                showModal={handleModalShow}
                auditPs={AUDIT_PS.JOURNAL}
                auditMessage='Просмотр вкладки Общие сведения карточки СЭМД'
              />
            </Route>
            <Route path={`${JOURNAL__SEMD}/:msgId/processing`}>
              <ProcessingTabWithAudit
                processingLog={journalProcessingLogModel.payload?.data}
                showModal={handleModalShow}
                auditPs={AUDIT_PS.JOURNAL}
                auditMessage='Просмотр вкладки Обработка карточки СЭМД'
              />
            </Route>
            <Route path={`${JOURNAL__SEMD}/:msgId/outgoing`}>
              <OutgoingTabWithAudit
                outgoing={journalOutgoingMessageModel.payload?.data}
                showModal={handleModalShow}
                auditPs={AUDIT_PS.JOURNAL}
                auditMessage='Просмотр вкладки Исходящие сообщения карточки СЭМД'
              />
            </Route>
            <Route component={InternalError404}/>
          </Switch>
        </LoadedOrError>
      }
    </div>
  )
}

export const JournalSemdDetailsPageWithAudit = withMntAudit(JournalSemdDetailsPage)
