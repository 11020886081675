import moment from 'moment'
import { DateRangePicker } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'
import { requestArgsDateRange } from './columnsReqArgs'

export class BirthDateColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.BIRTH_DATE

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Дата рождения',
        component: DateRangePicker,
        defaultValue: [null, null],
        props: {
          allowClear: true,
          allowEmpty: [true, true],
          allDay: true,
          disabledDate: currentDate => currentDate && currentDate.isAfter(moment()),
        },
      },
      width: 250,
      dataIndex: 'birth_date',
      dataRender: birth_date => birth_date ? `${moment(birth_date).format('DD.MM.YYYY')} (${moment().diff(birth_date, 'years')})` : '-',
      sort: true,
    }

    super(column, settings)
  }

  _requestArgs = reqArgs => requestArgsDateRange(this.type, reqArgs)
}
