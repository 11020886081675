import { isNil } from 'lodash'
import { useMemo } from 'react'
import { Pane } from './Pane'

export const ValueSelectorPanel = ({ values, valuesMap, selectedValues, selectionDisabled, onSelectValue, onDeselectValue }) => {
  const headers = useMemo(() => {
    return [
      {
        field_caption: 'ID',
        field_name: 'ref_item_id',
        grid_size: 'x2',
      },
      {
        field_caption: 'КОД',
        field_name: 'ref_item_code',
        grid_size: 'x3',
      },
      {
        field_caption: 'НАИМЕНОВАНИЕ',
        field_name: 'ref_item_name',
        grid_size: 'x7',
      },
    ]
  }, [])

  const leftList = useMemo(() => {
    return values.filter(v => !selectedValues.includes(v.value))
  }, [values, selectedValues])

  const rightList = useMemo(() => {
    return selectedValues.map(v => (valuesMap[v])).filter(v => !isNil(v))
  }, [valuesMap, selectedValues])

  return <div className='root-value-selector-panel'>
    <div className='d-flex h-100p'>
      <div className='selector-panel-column'>
        <Pane headers={headers} values={leftList} actionIcon='plus_cr_fr' disabled={selectionDisabled} actionOnClick={onSelectValue} />
      </div>
      <div className='selector-panel-column'>
        <Pane headers={headers} values={rightList} actionIcon='trash_can' actionOnClick={onDeselectValue} />
      </div>
    </div>
  </div>
}
