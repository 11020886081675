import { TextInput } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class MedBirthCertificateColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.MED_BIRTH_CERTIFICATE

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Серия и номер МСР',
        component: TextInput,
        defaultValue: '',
        props: {
          icon: 'search',
          allowedChars: /[0-9]/,
        },
      },
      width: 150,
      dataIndex: 'med_birth_certificate_sn',
      dataRender: med_birth_certificate_sn => med_birth_certificate_sn ? med_birth_certificate_sn : '-',
      sort: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters }) {
    return {
      control: {
        filters: filters?.med_birth_certificate_sn
          ? [{ field: 'med_birth_certificate_sn', value: filters?.med_birth_certificate_sn, operator: 'like_both', invalid: false }]
          : [],
      },
    }
  }
}
