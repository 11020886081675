import moment from 'moment'
import { DateRangePicker } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'
import { requestArgsDateRange } from './columnsReqArgs'

export class MotherBirthdateColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.MOTHER_BIRTHDATE

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Дата рождения матери',
        component: DateRangePicker,
        defaultValue: [null, null],
        props: {
          allowClear: true,
          allDay: true,
          allowEmpty: [true, true],
          disabledDate: currentDate => currentDate && currentDate.isAfter(moment()),
        },
      },
      width: 250,
      dataIndex: 'mother_birthdate',
      dataRender: date => date ? `${moment(date).format('DD.MM.YYYY')} (${moment().diff(date, 'years')})` : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs = reqArgs => requestArgsDateRange(this.type, reqArgs)
}
