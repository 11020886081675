import { useState, useMemo, useCallback } from 'react'
import { useDebounce } from '@src/hooks'
import { useMountEffect } from '@src/hooks'
import { Button, Modal, Checkbox, Combobox, Icon } from '@src/components'
import { remotesManager } from '@src/remotes'
import config from '@src/config'
import './TerritoryMoModal.scss'

const APPROVE_AOLEVEL = [5, 6, 7]

export const TerritoryMoModal = ({ onOk, ...props }) => {
  const [region, setRegion] = useState(false)
  const [territorialDivision, setTerritorialDivision] = useState(0)
  const [selectedTerritory, setSelectedTerritory] = useState(null)
  const [selectedStreet, setSelectedStreet] = useState(null)
  const [selectedHouse, setSelectedHouse] = useState([])
  const [cur_region, set_cur_region] = useState(null)
  const [houseCount, set_houseCount] = useState(null)

  useMountEffect(async () => {
    try {
      const response = await remotesManager.GAR_API.get('/api/region/code', {
        params: {
          code: config.app.region_code,
        },
      })
      set_cur_region(response.data)
    } catch(e) {
      console.error(e)
    }
  })

  const handleOk = () => {
    onOk && onOk({
      aolevel: get_aolevel,
      territory_name: getTerritoryFullName,
      aoguid_list: get_aoguid_list,
      houseList: selectedHouse,
    })
    props.onClose()
  }

  const get_aolevel = useMemo(() => {
    if(region) return cur_region?.[0]?.AOLEVEL
    if(selectedHouse?.length) return 99
    if(selectedStreet) return selectedStreet.AOLEVEL
    return selectedTerritory?.AOLEVEL
  }, [cur_region, region, selectedHouse, selectedStreet, selectedTerritory])

  const get_aoguid_list = useMemo(() => {
    if(region) return [cur_region?.[0]?.AOGUID]
    if(selectedHouse?.length) return selectedHouse?.map(i => i.HOUSEGUID)
    if(selectedStreet) return [selectedStreet.AOGUID]
    return [selectedTerritory?.AOGUID]
  }, [selectedHouse, selectedStreet, selectedTerritory, cur_region, region])

  const getTerritoryFullName = useMemo(() => {
    if(region) return cur_region?.[0]?.RELNAME
    if(selectedHouse?.length) return `${selectedHouse[0]?.RELNAME}; ${selectedHouse.slice(1)
      .map(i => i.OFFNAME ? i.OFFNAME : '' + (i.BUILDNUM ? ', ' : '') + i.BUILDNUM ?? '' + (i.STRUCNUM ? ', ' : '' + i.STRUCNUM ?? ''))?.join(', ')}`
    if(selectedStreet) return `${selectedStreet.PARENTNAME}, ${selectedStreet.RELNAME}`
    return selectedTerritory?.RELNAME
  }, [selectedHouse, selectedStreet, selectedTerritory, cur_region, region])

  return (
    <Modal
      title='Выбор территории для определения МО'
      className='root-territory-mo-modal'
      width={715}
      footer={<AlertFooter
        onOk={handleOk}
        onCancel={props.onClose}
        region={region}
        selectedTerritory={selectedTerritory}
      />}
      {...props}
    >
      <ModalBody {...{ region, setRegion, territorialDivision, set_houseCount,
        setTerritorialDivision, selectedTerritory, setSelectedTerritory, houseCount,
        selectedStreet, setSelectedStreet, selectedHouse, setSelectedHouse }} />
    </Modal>
  )
}

const ModalBody = ({ region, setRegion, territorialDivision, setTerritorialDivision, set_houseCount, houseCount,
  selectedTerritory, setSelectedTerritory, selectedStreet, setSelectedStreet, selectedHouse, setSelectedHouse }) => {
  const [territoryList, setterritoryList] = useState([])
  const [loadingTerritoryList, setLoadingTerritoryList] = useState(false)
  const [streetList, setStreetList] = useState([])
  const [loadingStreetList, setLoadingStreetList] = useState(false)
  const [evenHouse, setEvenHouse] = useState(0)
  const [houseList, setHouseList] = useState([])
  const [houseLoading, setHouseLoading] = useState(false)
  const [allHouses, setAllHouses] = useState(false)

  const handleSearchTerritory = useCallback(async (searchText) => {
    setLoadingTerritoryList(p => !p)
    try {
      const response = await remotesManager.GAR_API.get('/api/place', {
        params: {
          munHierarchy: !!territorialDivision,
          prefix: searchText,
          limit: 300,
          region_code: config.app.region_code,
        },
      })
      setterritoryList(response.data)
    }  catch(e) {
      console.error(e)
    } finally {
      setLoadingTerritoryList(p => !p)
    }
  }, [territorialDivision])

  const debouncedSearchTerritory = useDebounce(handleSearchTerritory, 200, {}, [territorialDivision])

  const handleSearchHouse = useCallback(async (searchText, AOGUID=houseCount > 0 ? selectedTerritory?.AOGUID : selectedStreet?.AOGUID) => {
    setHouseLoading(p => !p)
    try {
      const response = await remotesManager.GAR_API.get('/api/house/search', {
        params: {
          aoguid: AOGUID,
          prefix: searchText,
          limit: 300,
          munHierarchy: !!territorialDivision,
        },
      })
      setHouseList(response.data)
    } catch(e) {
      console.error(e)
    } finally {
      setHouseLoading(p => !p)
    }
  }, [houseCount, selectedTerritory?.AOGUID, selectedStreet?.AOGUID, territorialDivision])

  const debouncedSearchHouse = useDebounce(handleSearchHouse, 200, {}, [selectedStreet, territorialDivision])

  const handleSearchStreet = useCallback(async (searchText) => {
    setLoadingStreetList(p => !p)
    try {
      const response = await remotesManager.GAR_API.get('/api/street', {
        params: {
          place_aoguid: selectedTerritory?.AOGUID,
          prefix: searchText,
          limit: 300,
          munHierarchy: !!territorialDivision,
        },
      })
      setStreetList(response.data)
    } catch(e) {
      console.error(e)
    } finally {
      setLoadingStreetList(p => !p)
    }
  }, [selectedTerritory?.AOGUID, territorialDivision])

  const checkHouseCount = async (AOGUID) => {
    try {
      const response_count = await remotesManager.GAR_API.get('/api/addrobject/houseCount', {
        params: {
          aoguid: AOGUID,
          all: true,
        },
      })

      if(response_count?.data > 0) {
        set_houseCount(response_count?.data)
        handleSearchHouse(null, AOGUID)
      }
    } catch(e) {
      console.error(e)
    }
  }

  const debouncedSearchStreet = useDebounce(handleSearchStreet, 200, {}, [selectedTerritory, territorialDivision])

  const handleSelectTerritory = (e, e_obj) => {
    setSelectedTerritory(e_obj)
    checkHouseCount(e_obj.AOGUID)

    setSelectedStreet(null)
    setStreetList([])
    setEvenHouse(0)
    setSelectedHouse([])
    setHouseList([])
    set_houseCount(null)
    setAllHouses(false)
  }

  const handleSelectStreet = (e, e_obj) => {
    setSelectedStreet(e_obj)
    handleSearchHouse(null, e_obj.AOGUID)

    setEvenHouse(0)
    setSelectedHouse([])
    setHouseList([])
    setAllHouses(false)
  }

  const handleChengeTerritoryDeviation = (e) => {
    setTerritorialDivision(e)

    setterritoryList([])
    setSelectedTerritory(null)
    setSelectedStreet(null)
    setStreetList([])
    setEvenHouse(0)
    setSelectedHouse([])
    setHouseList([])
    set_houseCount(null)
    setAllHouses(false)
  }

  const handleSelectEvenHouse = (e) => {
    setEvenHouse(e)

    setSelectedHouse([])
    setAllHouses(false)
  }

  const houseListOptions = useMemo(() => houseList
    ?.map(i => ({ label: [i.OFFNAME, i.BUILDNUM, i.STRUCNUM].filter(i => i).join(', '), value: i.HOUSEID, title: i.RELNAME, ...i }))
    ?.filter(i => evenHouse === 0
      ? true
      : evenHouse === 1
        ? parseInt(i.HOUSENUM, 10) % 2 === 0 // isEven
        : Math.abs(parseInt(i.HOUSENUM, 10) % 2) === 1) // isOdd
  , [evenHouse, houseList])

  const handleSelectAllHouses = useCallback((value) => {
    if (value) setSelectedHouse(houseListOptions)
    else setSelectedHouse([])

    setAllHouses(value)
  }, [houseListOptions, setSelectedHouse])

  return (
    <div className='territory-mo-modal-body'>
      <div className='territory-mo-modal-body-block'>
        <Checkbox Label='Весь регион' value={region} onChange={setRegion} />
      </div>
      {
        !region && (<>
          <div className='territory-mo-modal-body-block'>
            <p className='territory-mo-modal-body-block-title'>Территориальное деление</p>
            <Combobox
              value={territorialDivision}
              onChange={handleChengeTerritoryDeviation}
              options={[
                {
                  value: 1,
                  label: 'Муниципальное деление',
                },
                {
                  value: 0,
                  label: 'Административно-территориальное деление',
                },
              ]}
            />
          </div>

          <div className='territory-mo-modal-body-block'>
            <p className='territory-mo-modal-body-block-title'>Территория</p>
            <Combobox
              options={territoryList?.map(i => ({ label: i.RELNAME, value: i.AOID, ...i }))}
              onSearch={search => search.length >= 3 && debouncedSearchTerritory(search)}
              showSearch
              placeholder='Введите наименование'
              loading={loadingTerritoryList}
              value={selectedTerritory}
              onSelect={handleSelectTerritory}
            />
          </div>

          <div className='territory-mo-modal-body-block'>
            <div className='d-flex'>
              <p className='territory-mo-modal-body-block-title'>Улица</p>
              {
                selectedTerritory && !~APPROVE_AOLEVEL.findIndex(i => i === selectedTerritory?.AOLEVEL) && (
                  <div className='territory-mo-modal-icon' title='Для выбора улицы выберите в поле "Территория" город, населенный пункт или микрорайон'>
                    <Icon icon='info_cr_fr' />
                  </div>
                )
              }
            </div>
            <Combobox
              options={streetList?.map(i => ({ label: i.OFFNAME, value: i.AOID, ...i }))}
              onSearch={search => search.length >= 3 && debouncedSearchStreet(search)}
              showSearch
              placeholder='Введите наименование'
              loading={loadingStreetList}
              disabled={houseCount > 0 ? true : !~APPROVE_AOLEVEL.findIndex(i => i === selectedTerritory?.AOLEVEL)}
              value={selectedStreet}
              onSelect={handleSelectStreet}
            />
          </div>

          <div className='territory-mo-modal-body-block'>
            <div className='territory-mo-modal-body-sub-block'>
              <p className='territory-mo-modal-body-block-title'>Дом (чётный/нечётный)</p>
              <Combobox
                value={evenHouse}
                onSelect={handleSelectEvenHouse}
                disabled={houseCount > 0 ? false : !selectedStreet}
                options={[
                  {
                    label: 'Все',
                    value: 0,
                  },
                  {
                    label: 'Четные',
                    value: 1,
                  },
                  {
                    label: 'Нечетные',
                    value: 2,
                  },
                ]}
              />
            </div>
            <div className='territory-mo-modal-body-sub-block mg-l-12'>
              <p className='territory-mo-modal-body-block-title'>Номер дома</p>
              <Combobox
                options={houseListOptions}
                onSearch={search => search.length >= 3 && debouncedSearchHouse(search)}
                showSearch
                showArrow
                allowClear
                autoClearSearchValue
                placeholder='Выберите'
                loading={houseLoading}
                disabled={allHouses || (houseCount > 0 ? false : !selectedStreet)}
                onChange={(e, e_obj) => setSelectedHouse(e_obj)}
                mode='multiple'
                maxTagCount={0}
                value={selectedHouse}
                maxTagPlaceholder={omittedValues => `Выбрано: ${omittedValues.length}`}
              />
            </div>
            <div className='territory-mo-modal-body-sub-block maxw-40 mg-l-12'>
              <p className='territory-mo-modal-body-block-title'>Все</p>
              <Checkbox
                disabled={houseCount > 0 ? false : !selectedStreet}
                value={allHouses}
                onChange={handleSelectAllHouses}
              />
            </div>
          </div>
        </>)}
    </div>
  )
}

const AlertFooter = ({ onOk, onCancel, region, selectedTerritory }) => {
  return (
    <div className='territory-mo-modal-footer'>
      <Button onClick={onCancel} className='mg-r-8' label='Отмена' />
      <Button disabled={!region && !selectedTerritory} onClick={onOk} isSuccess label='Применить' icon='check_cr_fr_white' />
    </div>
  )
}
