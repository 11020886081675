import { TreeSelect } from 'antd'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  selectAttributesTreeForSelector,
  selectAttributesTreeModel, selectValueTypeListModel,
} from '@src/modules/methodology/store'

export const AttributeSelector = ({ onPickAttribute }) => {
  const attributesTreeModel = useSelector(selectAttributesTreeModel)
  const valueTypeListModel = useSelector(selectValueTypeListModel)

  // Если у узлов верхнего уровня только по одному потомку, автоматически разворачиваем эти узлы для удобства использования
  const defaultExpandedKeys = useMemo(() => {
    if (!attributesTreeModel.loaded)
      return false

    if (attributesTreeModel.payload?.data.length === 1) {

      let current = attributesTreeModel.payload.data[0]
      const expanded = [current.id]

      while (current.children.length === 1) {
        expanded.push(current.children[0].id)
        current = current.children[0]
      }

      return expanded
    }
  }, [attributesTreeModel.loaded, attributesTreeModel.payload?.data])

  const treeData = useSelector(selectAttributesTreeForSelector)

  return <TreeSelect
    className='attribute-tree-selector'
    popupClassName='attribute-tree-selector-dropdown'
    loading={attributesTreeModel.loading || valueTypeListModel.loading}
    treeData={treeData}
    placeholder='Выберите атрибут'
    showSearch
    treeIcon
    treeLine={{ showLeafIcon: false }}
    treeDefaultExpandedKeys={defaultExpandedKeys}
    treeNodeFilterProp='caption'
    listHeight={512}
    fieldNames={{
      label: 'caption',
      value: 'id',
    }}
    value={null}
    onSelect={onPickAttribute} />
}
