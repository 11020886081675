import {
  initialClinrecCardState, initialClinrecTableState,
  initialMethRefbookSelectorState, initialMethRegConditionsFormState, initialMethRegConditionsState, initialMethRoutesCardState,
  initialMethRoutesTableState, initialNpaCardState, initialNpaTableState, initialMethCopyConditionModalState,
} from './initialState'

const reducers = {
  resetClinrecTableState: state => ({ ...state, ...initialClinrecTableState }),
  resetClinrecCardState : state => ({ ...state, ...initialClinrecTableState, ...initialClinrecCardState }),
  resetNpaTableState : state => ({ ...state, ...initialNpaTableState }),
  resetNpaCardState : state => ({ ...state, ...initialNpaTableState, ...initialNpaCardState }),
  resetMethRoutesTableState : state => ({ ...state, ...initialMethRoutesTableState }),
  resetMethRoutesCardState : state => ({ ...state, ...initialMethRoutesCardState }),
  resetMethRegConditionsState: state => ({ ...state, ...initialMethRegConditionsState }),
  resetMethRefbookSelectorState: state => ({ ...state, ...initialMethRefbookSelectorState }),
  resetMethRegConditionsFormState: state => ({ ...state, ...initialMethRegConditionsFormState }),
  resetCopyConditionModalState: state => ({ ...state, ...initialMethCopyConditionModalState }),
}

export default reducers
