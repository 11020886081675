import React from 'react'
import cn from 'classnames'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { AppNav, Button, Icon, Loader, TextInput } from '@src/components'
import { normalizeStr, PropTypes } from '@src/utils'
import { AUDIT_PS, URLS } from '@src/constants'
import { Alert, AlertInput } from '@src/modals'
import { selectAdminRoleResourcesListModel, selectAdminRolesListModel, copyAdminRole, removeAdminRole,
  selectAdminCopyRoleModel, selectAdminRemoveRoleModel } from '@src/modules/admin/store'
import { modalService } from '@src/services'
import { withAudit } from '@src/hoc/withAudit/withAudit'
import { CreateRoleDrawerWithAudit } from '../CreateRoleDrawer/CreateRoleDrawer'
import { EmployeesDrawerWithAudit } from './EmployeesDrawer/EmployeesDrawer'
import './AdminRolesPageTable.scss'

class _AdminRolesPageTable extends React.Component {
  static propTypes = {
    adminRolesList: PropTypes.array,
    setSelectedRole: PropTypes.func,
    adminRoleResourcesListModel: PropTypes.model,
    logToAudit: PropTypes.func,
  }

  constructor (props) {
    super(props)

    this.state = {
      roleInput: '',
      levelInput: '',
      mclInput: '',
      selectedRole: null,
    }
  }

  get filteredRolesList () {
    const { roleInput, levelInput, mclInput } = this.state

    return this.props.adminRolesList
      .filter(role => roleInput ? !role.role_name ? false : normalizeStr(role.role_name).includes(normalizeStr(roleInput)) : true)
      .filter(role => levelInput ? !role.response_level ? false : normalizeStr(role.response_level).includes(normalizeStr(levelInput)) : true)
      .filter(role => mclInput ? !role.mcl_profile ? false : normalizeStr(role.mcl_profile).includes(normalizeStr(mclInput)) : true)
  }

  employees = (role_name, id, user_count) => {
    if(user_count === 0)
      return 'нет'

    return (
      <div className='people-employees' onClick={() => modalService.open(EmployeesDrawerWithAudit, {
        roleId: id,
        auditPs: AUDIT_PS.ADMIN,
        auditMessage: `Просмотр списка пользователей роли ${role_name}`,
      })}>
        <Icon icon='people_16_outline' />
        <span>{user_count}</span>
      </div>
    )
  }

  handleOpenDrawer = (role) => {
    modalService.open(CreateRoleDrawerWithAudit, { selectedRole: role })
  }

  handleCopyRole = async (roleName) => {
    const arg = {
      role_name: roleName,
      main_page: this.state.selectedRole.main_page,
      mcl_profile_id: this.state.selectedRole.mcl_profile_id,
      response_level_id: this.state.selectedRole.response_level_id,
      role_code: this.state.selectedRole.role_code,
    }

    this.props.logToAudit({
      auditPs: AUDIT_PS.ADMIN,
      auditMessage: `Копирование роли ${this.state.selectedRole.role_name}`,
      auditDescription: { arg },
    })

    await this.props.copyAdminRole(arg)
    return this.props.adminCopyRoleModel.error
  }

  handleRemoveRole = async () => {
    const arg = { roleId: this.state.selectedRole.id }

    this.props.logToAudit({
      auditPs: AUDIT_PS.ADMIN,
      auditMessage: `Удаление роли ${this.state.selectedRole.role_name}`,
      auditDescription: { arg },
    })
    await this.props.removeAdminRole(arg)
    return this.props.adminRemoveRoleModel.error
  }

  render () {
    const { adminRoleResourcesListModel } = this.props
    const { roleInput, mclInput, levelInput, selectedRole } = this.state
    const loading = adminRoleResourcesListModel.loading

    return (
      <div className='root-admin-roles-page'>
        <AppNav
          title='Прикладные роли'
          breadcrumbs={[
            {
              label: 'Администрирование',
              link: URLS.ADMIN__ROLES,
            },
            {
              label: 'Прикладные роли',
              link: URLS.ADMIN__ROLES,
            },
          ]}
        />

        <div className='admin-roles-content'>
          {
            loading
              ? (
                <div className='admin-roles-content-loader-overlay'>
                  <Loader />
                </div>
              )
              : null
          }
          <div className={cn('admin-roles-content-view', loading ? 'admin-roles-content-view-loading' : null)}>
            <div className='admin-roles-actions'>
              <Button label='Добавить роль' onClick={() => this.handleOpenDrawer()} icon='plus_cr_fr' />
              <Button
                label='Копировать роль'
                disabled={!selectedRole}
                onClick={() => modalService.open(AlertInput, {
                  onOk: this.handleCopyRole,
                  okText: 'Копировать',
                  title: `Копирование роли "${selectedRole.role_name}"`,
                  value: `${selectedRole.role_name} Копия`,
                })}
                icon='file_copy'
              />
              <Button
                label='Редактировать роль'
                disabled={!selectedRole}
                onClick={() => this.handleOpenDrawer(this.state.selectedRole)}
                icon='file_copy'
              />
              <Button
                label='Удалить роль'
                disabled={!selectedRole}
                onClick={() => modalService.open(Alert, {
                  warn: true,
                  onOk: this.handleRemoveRole,
                  okText: 'Удалить',
                  title: 'Удаление роли',
                  children: 'Вы уверены, что хотите удалить роль?',
                })}
                isSecondary
                icon='trash_can'
              />
            </div>

            <div className='admin-roles-panels'>
              {
                adminRoleResourcesListModel.error
                  ? <div className='error'>{adminRoleResourcesListModel.error}</div>
                  : (
                    <div className='admin-roles-roles-list'>
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <div>Роль</div>
                              <TextInput
                                name='roleInput'
                                value={roleInput}
                                onChange={val => this.setState({ roleInput: val })}
                                icon='search'
                              />
                            </th>
                            <th>
                              <div>Уровень</div>
                              <TextInput
                                name='levelInput'
                                value={levelInput}
                                onChange={val => this.setState({ levelInput: val })}
                                icon='search'
                              />
                            </th>
                            <th>
                              <div>Направление ОМП</div>
                              <TextInput
                                name='mclInput'
                                value={mclInput}
                                onChange={val => this.setState({ mclInput: val })}
                                icon='search'
                              />
                            </th>
                            <th>Пользователи</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.filteredRolesList.map((role, i) => (
                              <tr
                                key={i}
                                onClick={() => this.setState({ selectedRole: role })}
                                onDoubleClick={() => this.props.history.push(`${URLS.ADMIN__ROLES__ROLE}/${role.id}`)}
                                className={cn(role.id === selectedRole?.id && 'selected')}
                              >
                                <td>{role.role_name || '-'}</td>
                                <td>{role.response_level || '-'}</td>
                                <td>{role.mcl_profile || '-'}</td>
                                <td>{this.employees(role.role_name, role.id, role.user_count)}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    adminRolesList: selectAdminRolesListModel(state).payload.data,
    adminRoleResourcesListModel: selectAdminRoleResourcesListModel(state),
    adminCopyRoleModel: selectAdminCopyRoleModel(state),
    adminRemoveRoleModel: selectAdminRemoveRoleModel(state),
  }
}

const mapDispatchToProps = dispatch => ({
  copyAdminRole: args => dispatch(copyAdminRole(args)),
  removeAdminRole: args => dispatch(removeAdminRole(args)),
})


const AdminRolesPageTable = withRouter(connect(mapStateToProps, mapDispatchToProps)(_AdminRolesPageTable))
export const AdminRolesPageTableWithAudit = withAudit(AdminRolesPageTable)
