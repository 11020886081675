import _ from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { selectAppSettingsModel } from '@src/store'

/**
 * @description Хук предоставляет объект настроек приложения
 * По-умолчанию предоставляется объект со всеми настройками приложения
 * @param path путь в дереве настроек
 * @returns appSettings
 */
export const useAppSettings = (path: string | string[] | undefined) => {
  const appSettingsModel = useSelector(selectAppSettingsModel)

  return (path ? _.get(appSettingsModel.payload, path) : appSettingsModel.payload) || {}
}

/**
 * @description Хук предоставляет объект настроек приложения для конкретной страницы
 * По-умолчанию предоставляется объект с настройками текущей страницы
 * Текущая страница пределяется по path параметру <Route>
 * @param page опциональный ключ страницы, настройки которой нужно вернуть
 * @returns pageAppSettings
 */
export const usePageAppSettings = (page: string | undefined) => {
  const appSettings = useAppSettings('pages')
  const routeMatch = useRouteMatch()

  const pageKey = useMemo(() => {
    return page || routeMatch.path
  }, [page, routeMatch.path])

  return appSettings?.[pageKey] || {}
}
