import { createSelector } from 'reselect'
import * as models from './models'

export const selectAnalytics = state => state.analytics

export const selectIntegrationReportListModel = createSelector(
  selectAnalytics,
  analytics => analytics[models.ANALYTICS_INTEGRATION_LIST],
)

export const selectAnalyticsIntegrationFilterMoModel = createSelector(
  selectAnalytics,
  analytics => analytics[models.ANALYTICS_INTEGRATION_FILTER_MO],
)

export const selectAnalyticsIntegrationFilterExtSystemsModel = createSelector(
  selectAnalytics,
  analytics => analytics[models.ANALYTICS_INTEGRATION_FILTER_EXT_SYSTEMS],
)

export const selectAnalyticsIntegrationFilterEhrModel = createSelector(
  selectAnalytics,
  analytics => analytics[models.ANALYTICS_INTEGRATION_FILTER_EHR],
)

export const selectAnalyticsIntegrationFilterDepartsModel = createSelector(
  selectAnalytics,
  analytics => analytics[models.ANALYTICS_INTEGRATION_FILTER_DEPARTS],
)