import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import 'moment/locale/ru'
import locale from 'antd/es/locale/ru_RU'
import { ConfigProvider as AntConfigProvider } from 'antd'
import { initSecurity, selectAuthModel } from '@src/store'
import { InternalError404, Loader } from '@src/components'
import { AUDIT_PS } from '@src/constants'
import { MainPageWithAudit } from './pages'
import './App.scss'

class App extends Component {
  static propTypes = {
    auth: PropTypes.object,
    initSecurity: PropTypes.func,
    authModel: PropTypes.shape({
      loaded: PropTypes.bool,
      loading: PropTypes.bool,
      error: PropTypes.string,
      payload: PropTypes.object,
    }),
  }

  componentDidMount () {
    const params = new URLSearchParams(window.location.search)
    const authCode = params.get('code')
    this.props.initSecurity(authCode)
  }

  render () {
    const { authModel } = this.props

    return (
      <AntConfigProvider
        locale={locale}
        renderEmpty={() => null}
      >
        <BrowserRouter>
          <Switch>
            <Route path='/'>
              {
                authModel.loading || !authModel.loaded
                  ? (
                    <div className='app-loading'>
                      <Loader />
                    </div>
                  )
                  : <MainPageWithAudit
                    auditPs={AUDIT_PS.ADMIN}
                    auditMessage='Начало новой сессии'
                    auth={authModel.payload}
                  />
              }
            </Route>
            <Route component={InternalError404}/>
          </Switch>
        </BrowserRouter>
      </AntConfigProvider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authModel: selectAuthModel(state),
  }
}

const mapDispatchToProps = {
  initSecurity,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
