import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs } from 'antd'
import { selectDocumentModel, loadDocument, loadRevisionData, loadRevisionsList, resetNpaCardState } from '@src/modules/methodology/store'
import { AppNav, LoadedOrError } from '@src/components'
// import { AppNav, Tag } from '@src/components'
// import { TAG_COLORS } from '@src/constants'                                                                    //VIMISVIMIS-21048 VIMISVIMIS-21051
import { AUDIT_PS, URLS } from '@src/constants'
// import { TabText } from '@src/modules/methodology/components/TabText/TabText'                                  //VIMISVIMIS-21048 VIMISVIMIS-21051
import { useMountEffect } from '@src/hooks'
import { TabInformationWithAudit } from '../../components/NpaTabs/TabInformation/TabInformation'
// import { TabStaffingStandards } from '../../components/NpaTabs/TabStaffingStandards/TabStaffingStandards'      //VIMISVIMIS-21048 VIMISVIMIS-21051
// import { TabEquipmentStandards } from '../../components/NpaTabs/TabEquipmentStandards/TabEquipmentStandards'   //VIMISVIMIS-21048 VIMISVIMIS-21051
import { TabRoutesWithAudit } from '../../components/NpaTabs/TabRoutes/TabRoutes'

import './NpaDataPage.scss'

const TABS = [
  TabInformationWithAudit,
  // TabText,
  // TabStaffingStandards,
  // TabEquipmentStandards,                                                                                       //VIMISVIMIS-21048 VIMISVIMIS-21051
  TabRoutesWithAudit,
]

export const NpaDataPage = () => {
  const [selectedRevisionId, setSelectedRevisionId] = useState(null)
  const match = useRouteMatch()

  const npaInfoModel = useSelector(selectDocumentModel)
  const npaId = match.params.id

  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  useMountEffect(() => {
    dispatch(loadRevisionsList({ args: { document_id: npaId } }))
    dispatch(loadDocument({ args: { docId: npaId } }))

    return () => dispatch(resetNpaCardState())
  })

  useEffect(() => {
    if(npaInfoModel.payload?.revision_id) {
      const urlRevisionId = new URLSearchParams(location.search).get('revisionId')
      const docRevisionId = npaInfoModel.payload?.revision_id

      const initialRevision = urlRevisionId || docRevisionId
      if (initialRevision) setSelectedRevisionId(initialRevision)
    }
  }, [location.search, npaInfoModel.payload?.revision_id])

  const titleString = useMemo(() => {
    if(npaInfoModel.loaded)
      return npaInfoModel.payload?.name_short

    return ' '
  }, [npaInfoModel.loaded, npaInfoModel.payload?.name_short])

  const titleJsxString = () => {
    if(npaInfoModel.loaded)
      return <span className='npa-data-title'>
        {npaInfoModel.payload?.name_short}
        {/* <Tag color={TAG_COLORS.BEIGE}>{npaInfoModel.payload?.number}</Tag>     //VIMISVIMIS-21048 VIMISVIMIS-21051*/}
      </span>

    return null
  }

  useEffect(() => {
    if(selectedRevisionId) {
      dispatch(loadRevisionData({ revision_id: selectedRevisionId }))
      history.replace({
        pathname: location.pathname,
        search: new URLSearchParams({ revisionId: selectedRevisionId }).toString(),
      })
    }
  }, [dispatch, history, location.pathname, selectedRevisionId])

  const tabActiveKey = useMemo(() => {
    const Tab = TABS.find(Tab => location.pathname.endsWith(Tab.tabPath))
    return Tab?.tabPath || TABS[0].tabPath
  }, [location.pathname])

  return (
    <div className='root-npa-data-page'>
      <AppNav
        title={titleJsxString}
        breadcrumbs={[
          { label: 'Методология', link: URLS.METHODOLOGY },
          { label: 'Порядки ОМП', link: URLS.METHODOLOGY__NPA__DATA },
          {
            label: titleString?.length > 30 ? `${titleString.slice(0, 30)}...` : titleString,
          },
        ]}

        tabs={TABS.map(tab => ({
          label: tab.tabTitle,
          link: `${URLS.METHODOLOGY__NPA__DATA}/${npaId}/${tab.tabPath}${location.search}`,
        }))}
      />

      <LoadedOrError loaded={npaInfoModel.loaded} loading={npaInfoModel.loading} error={npaInfoModel.error}>
        <Tabs
          activeKey={tabActiveKey}
          renderTabBar={() => null}
          items={TABS.map(TabWithAudit => ({
            key: TabWithAudit.tabPath,
            children: <TabWithAudit selectedRevisionId={selectedRevisionId} onRevisionChange={setSelectedRevisionId}
              auditPs={AUDIT_PS.METHOD}
              auditMessage={`Просмотр вкладки '${TabWithAudit.tabTitle}' карточки Порядка ОМП ${titleString ?? '-'}`}
            />,
          }))}
        />
      </LoadedOrError>
    </div>
  )
}
