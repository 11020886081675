import jwt_decode from 'jwt-decode'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { remotesManager, withAuthorization } from '@src/remotes'
import { getAuthUri, getAuthParams, getRefreshParams, getLogoutParams, extraCreator } from '@src/utils'
import config from '@src/config'
import { selectRefreshToken, selectAccessToken } from './selectors'
import { CHANGE_USER_CURRENT_ROLE, PS_VERSIONS, LOAD_USER } from './models'

export const initSecurity = createAsyncThunk(
  'security/init',
  async (authCode, { dispatch, rejectWithValue }) => {
    if (!authCode) {
      return rejectWithValue('Нет кода авторизации')
    }

    const { data } = await remotesManager.BACKEND_AUTH.post('/realms/master/protocol/openid-connect/token', new URLSearchParams({
      ...getAuthParams({ redirectSuffix: window.location.pathname }),
      code: authCode,
    }).toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    if (!data.refresh_token) return rejectWithValue('Не удалось получить токен пользователя')
    const decodedAT = jwt_decode(data.access_token)
    const decodedRT = jwt_decode(data.refresh_token)
    const timeout = Math.min((decodedAT.exp - decodedAT.iat), (decodedRT.exp - decodedRT.iat))
    setTimeout(() => dispatch(refreshToken()), timeout  * 4/5 * 1000)
    return data
  },
)

export const refreshToken = createAsyncThunk(
  'security/refreshToken',
  async (arg, { dispatch, getState, rejectWithValue }) => {
    const refresh_token = selectRefreshToken(getState())

    try {
      const { data } = await remotesManager.BACKEND_AUTH.post('/realms/master/protocol/openid-connect/token', new URLSearchParams({
        ...getRefreshParams({ redirectSuffix: window.location.pathname }),
        refresh_token: refresh_token,
      }).toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })

      if (!data.refresh_token) return rejectWithValue('Не удалось обновить токен пользователя')
      const decodedAT = jwt_decode(data.access_token)
      const decodedRT = jwt_decode(data.refresh_token)
      const timeout = Math.min((decodedAT.exp - decodedAT.iat), (decodedRT.exp - decodedRT.iat))
      setTimeout(() => dispatch(refreshToken()), timeout  * 4/5 * 1000)
      return data
    } catch (e) {
      console.error('can not refresh token', e)
      dispatch(logout())
    }
  },
)

export const logout = createAsyncThunk(
  'security/logout',
  async (arg, { getState }) => {
    const refresh_token = selectRefreshToken(getState())

    const redirectParams = new URLSearchParams({
      ...getLogoutParams(),
      refresh_token: refresh_token,
    }).toString()
    window.location.replace(config.api.auth.uri + '/realms/master/protocol/openid-connect/logout?' + redirectParams)
  },
)

export const loadUser = createAsyncThunk(
  'security/loadUser',
  async (arg, { getState }) => {
    const accessToken = selectAccessToken(getState())
    const { data: uesrInfo } = await remotesManager.BACKEND_AUTH.get('/realms/master/protocol/openid-connect/userinfo', withAuthorization(accessToken))
    const { data } = await remotesManager.BACKEND_API.post('/admin/users', {
      args: {
        id: uesrInfo?.sub,
        login: uesrInfo?.preferred_username,
        snils: uesrInfo?.snils,
        email: uesrInfo?.email,
        first_name: uesrInfo?.given_name,
        last_name: uesrInfo?.family_name,
        middle_name: uesrInfo?.middle_name,
      },
    }, withAuthorization(accessToken))

    return data
  },
)

export const getPsVersions = createAsyncThunk(
  'security/getPsVersions',
  async (arg, { getState }) => {
    const accessToken = selectAccessToken(getState())

    const [packages, journal, passport, storage, method, monitor, admin, analytics, personal, psi] = (await Promise.all([
      remotesManager.BACKEND_API.get('/netcore-tasks/info', withAuthorization(accessToken)),
      remotesManager.BACKEND_API.post('/journal/version', {}, withAuthorization(accessToken)),
      remotesManager.BACKEND_API.post('/passport/version', {}, withAuthorization(accessToken)),
      remotesManager.BACKEND_API.post('/storage/info', {}, withAuthorization(accessToken)),
      remotesManager.BACKEND_API.post('/method/info', {}, withAuthorization(accessToken)),
      remotesManager.BACKEND_API.post('/patient-monitoring/info', {}, withAuthorization(accessToken)),
      remotesManager.BACKEND_API.post('/admin/version', {}, withAuthorization(accessToken)),
      remotesManager.BACKEND_API.post('/analytics/version', {}, withAuthorization(accessToken)),
      remotesManager.BACKEND_API.post('/personal/version', {}, withAuthorization(accessToken)),
      remotesManager.BACKEND_API.get('/psi/api/info', withAuthorization(accessToken)),
    ])).map(r => r.data)

    return {
      packages, journal, passport, storage, method, monitor, admin, analytics, personal, psi,
    }
  },
)

export const changeUserCurrentRole = createAsyncThunk(
  'security/changeUserCurrentRole',
  async ({ userProfileId, userId }, { getState }) => {
    const accessToken = selectAccessToken(getState())

    const { data } = await remotesManager.BACKEND_API.post('/admin/roles/user/set_main', {
      args: {
        id: userProfileId,
        user_id: userId,
      },
    }, withAuthorization(accessToken))
    return data
  },
)

const extraReducers = {
  [initSecurity.pending]: (state) => {
    state.authLoading = true
    state.authLoaded = false
    state.authError = null
  },
  [initSecurity.fulfilled]: (state, action) => {
    state.authLoading = false
    state.authLoaded = true
    state.authError = null
    state.auth = action.payload
  },
  [initSecurity.rejected]: (state, action) => {
    state.authLoading = false
    state.authLoaded = false
    state.authError = action.payload || action.error?.error || action.error?.message || 'Error in initSecurity.rejected'

    window.location.replace(getAuthUri({ redirectSuffix: window.location.pathname }))
  },

  ...extraCreator(loadUser, LOAD_USER),
  [loadUser.fulfilled]: (state, action) => {
    state[LOAD_USER].payload = {
      user: action.payload.user,
      roles: action.payload.roles,
      currentRole: action.payload.roles.find(r => r.is_main),
    }
    state[LOAD_USER].loading = false
    state[LOAD_USER].loaded = true
    state[LOAD_USER].error = null
  },

  [refreshToken.fulfilled]: (state, action) => {
    state.auth = action.payload
  },
  [refreshToken.rejected]: (state, action) => {
    console.error('refreshToken.rejected', action)
  },

  ...extraCreator(changeUserCurrentRole, CHANGE_USER_CURRENT_ROLE),
  [changeUserCurrentRole.fulfilled]: (state) => {
    state[CHANGE_USER_CURRENT_ROLE].loaded = true
    state[CHANGE_USER_CURRENT_ROLE].error = null
    state[CHANGE_USER_CURRENT_ROLE].loading = false
    window.location.replace('/')
  },

  ...extraCreator(getPsVersions, PS_VERSIONS),
}

export default extraReducers
