import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Drawer, Icon, LoadedOrError } from '@src/components'
import { withMntAudit } from '@src/hoc'
import { useMountEffect } from '@src/hooks'
import { clearAdminExtSystemsHistory, getExtSystemsHistory, selectAdminExtSystemsHistory } from '@src/modules/admin/store'
import './ShowExtHistoryModal.scss'

const ShowExtHistoryModal = ({ ext, ...props }) => {
  const dispatch = useDispatch()

  const extSystemsHistoryModel = useSelector(selectAdminExtSystemsHistory)
  useMountEffect(() => {
    dispatch(getExtSystemsHistory({ ex_system_id: ext.id }))
    return () => dispatch(clearAdminExtSystemsHistory())
  })

  return (
    <Drawer
      className='root-show-ext-history-modal'
      title='История изменений'
      width={560}
      {...props}
    >
      <LoadedOrError error={extSystemsHistoryModel.error} loaded={extSystemsHistoryModel.loaded} loading={extSystemsHistoryModel.loading}>
        <table>
          <thead>
            <tr>
              <th>Дата применения</th>
              <th>Тип операции</th>
              <th>Автор</th>
            </tr>
          </thead>
          <tbody>
            {
              extSystemsHistoryModel.payload?.data?.map((i, c) => (
                <tr key={`show-ext-item-${c}`}>
                  <td>{moment(i.date).format('DD.MM.YYYY HH:mm:ss')}</td>
                  <td>
                    <div className='show-ext-item-type'>
                      <Icon icon={getIconOperation(i.oper_name)} />
                      <span>{i.oper_name}</span>
                    </div>
                  </td>
                  <td>{[i.last_name, i.first_name].join(' ')}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </LoadedOrError>
    </Drawer>
  )
}

const getIconOperation = (name) => {
  switch(name) {
  case 'Приостановлено': return 'play_16_regular'
  case 'Возобновлено': return 'play_green'
  case 'Редактирование': return 'edit_all_green'

  default: return 'play_16_regular'
  }
}


export const ShowExtHistoryModalWithAudit = withMntAudit(ShowExtHistoryModal)
