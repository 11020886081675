import { createSelector } from 'reselect'
import { RootState } from '..'
import * as models from './models'

export const selectUi = (state: RootState) => state.ui

export const selectUserPreferences = createSelector(
  selectUi,
  ui => ui[models.USER_PREFERENCES],
)

export const selectGetUiSettingsModel = createSelector(
  selectUi,
  ui => ui[models.GET_UI_SETTINGS],
)

export const selectPostUiSettingsModel = createSelector(
  selectUi,
  ui => ui[models.POST_UI_SETTINGS],
)

export const selectAppSettingsModel = createSelector(
  selectUi,
  ui => ui[models.APP_SETTINGS],
)
