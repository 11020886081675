export const TAG_COLORS = {
  DUSTY_BLUE: 'dusty-blue',
  LAVENDER: 'lavender',
  LIGHT_LAVENDER: 'light-lavender',
  KRAYOLA_THISTLE: 'krayola-thistle',
  ALMOND_CRAIOLA: 'almond-craiola',
  BEIGE: 'beige',
  LIGHT_BEIGE: 'light-beige',
  GREEN_TEA: 'green-tea',
  GREY_100: 'gray-100',
  LIGHT_PINK: 'light-pink',
  LIGHT_PURPLE: 'light-purple',
  LIGHT_GREEN: 'light-green',
}
