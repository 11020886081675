import { useCallback, useRef, useState } from 'react'
import { FixedSizeList } from 'react-window'
import { IconWithArea, IfNotReadonly } from '@src/components'
import { useResizeObserver } from '@src/components/DataGrid/hooks'
import { cn } from '@src/utils'

export const Pane = ({ headers, values, disabled, actionIcon, actionOnClick }) => {
  const MIN_LIST_HEIGHT = 500
  const HEADER_ROW_HEIGHT = 38
  const ROW_HEIGHT = 50
  const ref = useRef()
  const [listHeight, setListHeight] = useState(MIN_LIST_HEIGHT)

  const handleResize = useCallback(() => {
    setListHeight((ref.current?.offsetHeight || MIN_LIST_HEIGHT) - HEADER_ROW_HEIGHT)
  }, [])

  useResizeObserver(ref, handleResize)

  return <div className='root-pane' ref={ref}>
    <div className='pane-headers grid-13'>
      {headers.map(h => <div key={h.field_name} className={cn(h.grid_size, h.field_name)}>{h.field_caption}</div>)}
      <div className='x1'></div>
    </div>
    <FixedSizeList
      height={listHeight}
      itemSize={ROW_HEIGHT}
      itemCount={values.length}
      className='pane-values'
    >
      {({ index, style }) => {
        const value = values[index]

        return <div style={style} key={value.value} className='pane-value-row grid-13 x13'>
          {headers.map(h => <div key={h.field_name} className={cn(h.grid_size, h.field_name)} title={value[h.field_name]}>
            {value[h.field_name] && <span>{value[h.field_name]}</span>}
          </div>)}
          <div className={cn('pane-value-row-actions x1', disabled && 'disabled')}>
            <IfNotReadonly>
              <IconWithArea icon={actionIcon} onClick={() => actionOnClick(value.value)} />
            </IfNotReadonly>
          </div>
        </div>
      }}
    </FixedSizeList>
  </div>
}
