import { createSlice } from '@reduxjs/toolkit'
import { initialModel } from '@src/utils'
import type { StateModel } from '@src/utils/types'
import * as models from './lib/models'
import reducers from './lib/reducers'
import extraReducers from './lib/thunks'

const initialState = {
  [models.NSI_FRNSI_DICT_LIST]: initialModel as StateModel<ResponsePsiList>,
  [models.IMPORT_NSI_FRNSI_DICT]: initialModel,
  [models.NSI_FRNSI_DICTS_RECORDS_LIST]: initialModel,
  [models.NSI_FRNSI_DICTS]: {},
}

export const journalSlice = createSlice({
  name: 'nsi',
  initialState,
  reducers,
  extraReducers,
})

export default journalSlice.reducer

export const { resetImportNsiFrnsiDict } = journalSlice.actions
export * from './lib/thunks'
export * from './lib/selectors'
