import { initialModel } from '@src/utils'
import * as models from './models'

export const initialPatientCardState = {
  [models.PATINT_INFO]: initialModel,
  [models.PERSON_INFO]: initialModel,
  [models.PATIENT_MED_RECORDS]: initialModel,
  [models.PATIENT_STATUS]: initialModel,
  [models.PATIENT_STATUS_AKINEO_MOTHER]: initialModel,
  [models.PATIENT_SERVICE_LIST]: initialModel,
  [models.OCCASION_INFO]: initialModel,
  [models.PATIENT_LAST_EHR]: initialModel,
  [models.PATIENT_NEONATAL_SCREENING]: initialModel,
  [models.PATIENT_STATUS_CHILD]: initialModel,
  [models.PATIENT_STATUS_INFECTION]: initialModel,
  [models.PATIENT_INFO_INFECTION]: initialModel,
  [models.EMERGENCY_NOTICE_WIDGET_INFECTION]: initialModel,
  [models.DN_WIDGET_INFECTION]: initialModel,
  [models.PATIENT_EMERGENCY_NOTICE]: initialModel,
  [models.PATIENT_EMERGENCY_NOTICE_WIDGET]: initialModel,
}

export const initialPatientListState = {
  [models.PATIENT_LIST_FILTERS]: null,
  [models.PATIENT_LIST]: initialModel,
  [models.PATIENT_LIST_COUNT]: initialModel,
}

export const initialState = {
  ...initialPatientListState,
  ...initialPatientCardState,
}
