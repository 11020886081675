import { RangeInput } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class BirthweightColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.BIRTHWEIGHT_COLUMN

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Масса тела при рождении',
        component: RangeInput,
        defaultValue: ['', ''],
        props: {
          allowedChars: /[0-9]/,
        },
      },
      width: 200,
      dataIndex: 'birthweight',
      dataRender: birthweight => birthweight ? birthweight : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let requestFilters = [
      ...(filters?.[REGISTER_COLUMNS.BIRTHWEIGHT_COLUMN]?.[0]
        ? [{ field: 'birthweight', value: filters[REGISTER_COLUMNS.BIRTHWEIGHT_COLUMN][0], operator: '>=', invalid: false }] : []),
      ...(filters?.[REGISTER_COLUMNS.BIRTHWEIGHT_COLUMN]?.[1]
        ? [{ field: 'birthweight', value: filters[REGISTER_COLUMNS.BIRTHWEIGHT_COLUMN][1], operator: '<=', invalid: false }] : []),
    ]

    if (nullSearches[REGISTER_COLUMNS.BIRTHWEIGHT_COLUMN]) {
      requestFilters = [{
        field: 'birthweight',
        value: null,
        operator: '=',
        invalid: false,
      }]
    }

    return {
      control: {
        filters: requestFilters,
      },
    }
  }
}
