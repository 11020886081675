import { Modal as AntModal } from 'antd'
import './Modal.scss'

/**
 * @see Documentation @link https://ant.design/components/modal
 */
export const Modal = ({ onClose, ...props }) => {
  return <AntModal
    wrapClassName='root-modal'
    centered
    onCancel={onClose}
    footer={null}
    {...props}
  />
}
