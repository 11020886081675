import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import { Button, Drawer, Switch, TextInput } from '@src/components'
import { insertExternalSystem, loadExternalSystemsList, selectAdminInsertExternalSystemModel } from '@src/modules/admin/store'

import { withMntAudit } from '@src/hoc'
import './AddExtModal.scss'


class _AddExtModal extends Component {
  static propTypes = {
    edit: PropTypes.bool,
    editingExt: PropTypes.object,
    onClose: PropTypes.func,
    loadExternalSystemsList: PropTypes.func,
    insertExternalSystem: PropTypes.func,

    adminInsertExternalSystemModel: PropTypes.shape({
      loaded: PropTypes.bool,
      loading: PropTypes.bool,
      error: PropTypes.string,
      payload: PropTypes.object,
    }),

    logToAudit: PropTypes.func,
  }

  constructor (props) {
    super(props)

    const { edit, editingExt } = props

    this.state = {
      id: edit ? editingExt.id : null,
      exSystemTypeInput: edit ? editingExt.ex_system_type_id : null,
      subjectIdInput: edit ? editingExt.subject_id : null,
      auth_token: edit ? editingExt.auth_token : '',
      isActiveInput: edit ? editingExt.is_active : false,
      isBlockedInput: edit ? editingExt.is_blocked : false,
      validForm: false,
      loadError: null,
      changedReason: false,
    }
  }

  formikOnSubmit = async (values) => {
    const arg = {
      id: this.state.id,
      caption: values.captionInput,
      client_entity_id: values.clientEntityIdInput,
      auth_token: values.auth_token,
      ex_system_type_id: this.state.exSystemTypeInput,
      is_active: this.state.isActiveInput,
      is_blocked: this.state.isBlockedInput,
      reason: values.reasonInput,
      subject_id: this.state.subjectIdInput,
    }

    this.props.logToAudit({
      auditMessage: this.props.edit ? `Редактирование внешней системы ${values.clientEntityIdInput}` : `Добавление новой внешней системы ${values.captionInput}`,
      auditDescription: { arg },
    })
    await this.props.insertExternalSystem(arg)
    if (this.props.adminInsertExternalSystemModel.error)
      this.setState({ loadError: this.props.adminInsertExternalSystemModel.error })
    else {
      this.props.loadExternalSystemsList()
      !this.props.insertExternalSystem.loading && this.props.onClose()
    }
  }

  formikInit = () => {
    const { edit, editingExt } = this.props

    return {
      clientEntityIdInput: edit ? editingExt.client_entity_id : '',
      auth_token: edit ? editingExt.auth_token || '' : '',
      captionInput: edit ? editingExt.caption : '',
      reasonInput: edit ? editingExt.reason : '',
    }
  }

  formikValidate = (values) => {
    const errors = {}
    if (!values.reasonInput && this.state.isBlockedInput) errors.reasonInput = 'Основание'
    if (!values.auth_token) errors.auth_token = 'Токен авторизации'
    if (!values.captionInput) errors.captionInput = 'Наименование'

    !!Object.values(errors).length ? this.setState({ validForm: true }) : this.setState({ validForm: false })

    return errors
  }

  changedSwithReason = (val) => {
    this.setState({ isBlockedInput: val })
    this.props.edit && this.setState(prevState => ({ changedReason: !prevState.changedReason }))
  }

  renderFooter = (values, errors, handleSubmit) => {
    const { adminInsertExternalSystemModel } = this.props


    return <div className='add-ext-modal-actions'>
      <Button
        className='mr-16'
        loading={adminInsertExternalSystemModel.loading}
        disabled={!values.captionInput || (values.isBlockedInput && !values.reasonInput) }
        label='Отмена'
        onClick={this.props.onClose}
      />
      <Button
        loading={adminInsertExternalSystemModel.loading}
        disabled={ this.state.validForm }
        label='Сохранить'
        onClick={handleSubmit}
        icon='check_cr_fr_white'
        isSuccess
      />
    </div>
  }

  render () {
    const { edit, ...props } = this.props

    return (
      <Formik
        initialValues={this.formikInit()}
        onSubmit={this.formikOnSubmit}
        validate={this.formikValidate}
        validateOnChange={ this.state.validForm}
        validateOnBlur={this.state.validForm}
      >
        {({
          values,
          errors,
          handleSubmit,
        }) => (
          <Drawer
            className='root-add-ext-modal'
            {...props}
            width={560}
            title={edit ? 'Изменение внешней системы' : 'Добавление внешней системы'}
            footer={this.renderFooter(values, errors, handleSubmit)}
          >
            <Form>
              <div className='add-ext-modal-content'>
                <div className='add-ext-modal-content-field'>
                  <div className='add-ext-modal-content-field-title'>Идентификатор ИС</div>
                  <Field
                    component={TextInput}
                    name='clientEntityIdInput'
                  />
                </div>
                <div className='add-ext-modal-content-field'>
                  <div className='add-ext-modal-content-field-title'>Токен авторизации&nbsp;*</div>
                  <Field
                    component={TextInput}
                    className={ errors.auth_token ? 'input-error' : ''}
                    name='auth_token'
                  />
                </div>

                <div className='add-ext-modal-content-field'>
                  <div className='add-ext-modal-content-field-title'>Наименование&nbsp;*</div>
                  <Field
                    component={TextInput}
                    className={errors.captionInput ? 'input-error' : ''}
                    name='captionInput'
                  />
                </div>

                <div className='add-ext-modal-content-cb'>
                  <Switch
                    className='add-ext-modal-content-cb-input'
                    name='isBlockedInput'
                    checked={this.state.isBlockedInput}
                    onChange={checked => this.changedSwithReason(checked)}
                  />
                  <div className='add-ext-modal-content-cb-title'>Приостановка</div>
                </div>

                <div className='add-ext-modal-content-field'>
                  <div className='add-ext-modal-content-field-title'>
                    {
                      (edit ? this.state.changedReason : this.state.isBlockedInput) ? <div>Основание&nbsp;*</div> : <div>Основание</div>
                    }
                  </div>
                  <Field
                    component={TextInput}
                    className={ errors.reasonInput ? 'input-error' : ''}
                    disabled={edit ? !this.state.changedReason : !this.state.isBlockedInput}
                    name='reasonInput'
                  />
                </div>
              </div>
              {
                !!Object.values(errors).length && <div className='add-ext-modal-error'>Заполните обязательные поля</div>
              }
              <div className='add-ext-modal-error'>{this.state.loadError}</div>
            </Form>
          </Drawer>
        )}
      </Formik>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    adminInsertExternalSystemModel: selectAdminInsertExternalSystemModel(state),
  }
}

const mapDispatchToProps = dispatch => ({
  insertExternalSystem: args => dispatch(insertExternalSystem(args)),
  loadExternalSystemsList: args => dispatch(loadExternalSystemsList(args)),
})

const AddExtModal = connect(mapStateToProps, mapDispatchToProps)(_AddExtModal)
export const AddExtModalWithAudit = withMntAudit(AddExtModal)
