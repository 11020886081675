import { Mkb10InputWithState } from '@src/modules/monitoring/components/Mkb10Input/Mkb10InputWithState'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class DiagnosisColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.DIAGNOSIS

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Диагноз (МКБ-10)',
        component: Mkb10InputWithState,
        defaultValue: [],
      },
      width: 500,
      dataIndex: 'diagnosis',
      dataRender: (diagnosis, row) => row.dn_taking_mkb10 && row.dn_taking_mkb10_code
        ? `${row.dn_taking_mkb10_code} (${row.dn_taking_mkb10})`
        : diagnosis
          ? diagnosis
          : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let mkb_id = filters?.[this.type]?.length ? filters?.[this.type] : undefined

    if (nullSearches[this.type]) mkb_id = null

    return {
      args: {
        mkb_id,
      },
    }
  }
}
