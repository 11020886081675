import { thunkCreator, thunkCreatorInfinityScroll } from '@src/utils'
import * as models from '../models'

export const [
  loadPatientList, loadPatientListExtra,
] = thunkCreatorInfinityScroll(models.PATIENT_LIST, '/patient-monitoring/get_patient_list_occ')

export const [
  loadPatientListCount, loadPatientListCountExtra,
] = thunkCreator(models.PATIENT_LIST_COUNT, '/patient-monitoring/get_patient_list_count_occ')

export const [
  loadPatientStatusOcc, loadPatientStatusOccExtra,
] = thunkCreator(models.PATIENT_STATUS_OCC, '/patient-monitoring/get_patient_status_occ',
  { targetModel: models.PATIENT_STATUS })

export const [
  loadPatientInfo, loadPatientInfoExtra,
] = thunkCreator(models.PATINT_INFO, '/patient-monitoring/get_patient_info')

export const [
  loadPersonInfo, loadPersonInfoExtra,
] = thunkCreator(models.PERSON_INFO, '/personal/get_person_info')

export const [
  loadPatientMedRecords, loadPatientMedRecordsExtra,
] = thunkCreator(models.PATIENT_MED_RECORDS, '/patient-monitoring/get_patient_ehr_occ')

export const [
  loadPatientServiceList, loadPatientServiceListExtra,
] = thunkCreator(models.PATIENT_SERVICE_LIST, '/patient-monitoring/get_servicelist_diag_medic')

export const [
  loadOccasionInfo, loadOccasionInfoExtra,
] = thunkCreator(models.OCCASION_INFO, '/patient-monitoring/get_occasion_info')

export const [
  loadPatientLastEhr, loadPatientLastEhrExtra,
] = thunkCreator(models.PATIENT_LAST_EHR, '/patient-monitoring/get_patient_ehr_occ')

export default {
  ...loadPatientListExtra,
  ...loadPatientListCountExtra,
  ...loadPatientStatusOccExtra,
  ...loadPatientInfoExtra,
  ...loadPersonInfoExtra,
  ...loadPatientMedRecordsExtra,
  ...loadPatientServiceListExtra,
  ...loadOccasionInfoExtra,
  ...loadPatientLastEhrExtra,
}
