import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from '@src/components'
import { loadPatientStatusProfDn, selectPatientStatusModel } from '@src/modules/monitoring/store'
import { useMountEffect } from '@src/hooks'
import { withMntAudit } from '@src/hoc'
import { TabStatusBase } from '@src/modules/monitoring/components'

const TabStatus = ({ occasionPatientId, ...props }) => {
  const dispatch = useDispatch()
  const patientStatusModel = useSelector(selectPatientStatusModel)

  const status = patientStatusModel.payload?.data

  useMountEffect(() => {
    dispatch(loadPatientStatusProfDn({ occasion_patient_id: occasionPatientId }))
  })

  return <TabStatusBase {...props}>
    <Card
      loading={patientStatusModel.loading}
      error={patientStatusModel.error}
      style={{ flexBasis: '50%' }}
      title='Диагноз'
      data={status && [
        {
          title: 'Основной диагноз МКБ-10',
          data: status.dn_last_diagnosis || '—',
        },
        {
          title: 'Дата установки',
          data: status.dn_reason_doc_begin_on_local ? moment(status.dn_reason_doc_begin_on_local).format('DD.MM.YYYY') : '—',
        },
        {
          title: 'Сопутствующие диагнозы',
          data: '—',
        },
        {
          title: 'Дата постановки на ДН',
          data: status.dn_taking_begin_on_local ? moment(status.dn_taking_begin_on_local).format('DD.MM.YYYY') : '—',
        },
        {
          title: 'Дата снятия с ДН',
          data: status.dn_end_on_local ? moment(status.dn_end_on_local).format('DD.MM.YYYY') : '—',
        },
      ]}
    />
  </TabStatusBase>
}

export const TabStatusWithAudit = withMntAudit(TabStatus)

TabStatusWithAudit.tabTitle = 'Статус'
TabStatusWithAudit.tabPath = 'status'
