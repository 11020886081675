import { Component } from 'react'
import cn from 'classnames'

import './LinearProgress.scss'

export default class LinearProgress extends Component {
  render () {
    const { className, hidden } = this.props

    return <div className={cn('root-linear-progress', hidden && 'hidden', className)}>
      <div className='bar primary'>
        <span className='bar-inner' />
      </div>
      <div className='bar secondary'>
        <span className='bar-inner' />
      </div>
    </div>
  }
}
