import { isBoolean } from 'lodash'
import { Combobox } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'


export class DischargeColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.DISCHARGE


  constructor (store, settings) {
    const column = {
      header: {
        title: 'Выписан из стационара',
        component: Combobox,
        props: {
          allowClear: true,
          placeholder: 'Все',
          options: [
            { label: 'Да', value: true, title: 'Да' },
            { label: 'Нет', value: false, title: 'Нет' },
          ],
        },
      },
      width: 300,
      dataIndex: 'is_discharge',
      dataRender: is_discharge => typeof is_discharge === 'boolean' ? (is_discharge ? 'Да' : 'Нет') : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let requestFilters = []

    if (isBoolean(filters?.is_discharge)) {
      requestFilters = [{ field: 'is_discharge', value: filters.is_discharge, operator: '=', invalid: false }]
    }

    if (nullSearches[this.type]) {
      requestFilters = [{ field: this.type, operator: '=', value: null, invalid: false }]
    }

    return {
      control: {
        filters: requestFilters,
      },
    }
  }
}
