// subject for refactor
import _ from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { remotesManager, withAuthorization } from '@src/remotes'
import { selectAccessToken } from '@src/store'
import { mapDataWithMeta } from '@src/utils'
import * as models from '../models'

export const loadMkb10Diagnosis = createAsyncThunk(
  'monitoring/zno/loadMkb10Diagnosis',
  async ({ args, hidValue=null, filterValue='', makeTree = false }, { getState, rejectWithValue }) => {
    const accessToken = selectAccessToken(getState())
    try {
      const { data } = await remotesManager.BACKEND_API.post('/patient-monitoring/get_mkb_dictionary', {
        args: {
          token: accessToken,
          ...args,
        },
        control: {
          datamode: 'tree',
          filters: [{ field: 'full_mkb_name', value: filterValue, operator: 'like_both', invalid: false }],
          range: { chunk_start: 0, chunk_end: 99 },
          sorts: [
            { field: 'ref_item_id', sort: 'asc' },
          ],
          treeMode: {
            filterByHid: !filterValue,
            hidField: 'ref_parent_id',
            keyField: 'ref_item_id',
            hidValue: !filterValue ? hidValue : null,
          },
        },
      }, withAuthorization(accessToken))
      if (data.error) throw new Error(data.error)
      return {
        makeTree: makeTree,
        filter: filterValue,
        data: mapDataWithMeta(data),
      }
    } catch (e) {
      console.error(e)
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

const extraReducers = {
  [loadMkb10Diagnosis.pending]: (state, action) => {
    const keys = [models.MKB_10_DIAGNOSIS, action.meta.arg.hidValue ? action.meta.arg.hidValue : 'root']
    _.set(state, keys, {
      loading: true,
      loaded: false,
      error: null,
    })
  },

  [loadMkb10Diagnosis.rejected]: (state, action) => {
    const keys = [models.MKB_10_DIAGNOSIS, action.meta.arg.hidValue ? action.meta.arg.hidValue : 'root']
    _.set(state, keys, {
      loading: false,
      loaded: false,
      error: action?.payload || action.error?.error || action.error?.message || `Error in ${loadMkb10Diagnosis.rejected}`,
    })
  },

  [loadMkb10Diagnosis.fulfilled]: (state, action) => {
    const keys = [models.MKB_10_DIAGNOSIS, action.meta.arg.hidValue ? action.meta.arg.hidValue : 'root']

    const funcTreeMaker = (items, ref_item_id = null, link = 'ref_parent_id') => {
      return  action.payload.data &&
      items
        .filter(item => item[link] === ref_item_id)
        .map(item => ({ ...item, children: funcTreeMaker(items, item.ref_item_id) })) }
    const treesMkbs = action.payload.filter && action.payload.makeTree && funcTreeMaker(action.payload.data)
    !action.payload.makeTree
      ?
      _.set(state, keys, {
        payload: action.payload,
        loading: false,
        loaded: true,
        error: null,
      })
      :
      state[models.MKB_10_DIAGNOSIS] = {
        'root': {
          payload: {
            data:  [
              ...treesMkbs,
            ],
          },
          loaded: true,
          error: null,
          loading: false,
        },
      }
  },
}

export default extraReducers
