import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

import { IfHasResource, InternalError403, InternalError404, LoadedOrError } from '@src/components'
import { ACLS, AUDIT_PS, URLS } from '@src/constants'
import { withAcl } from '@src/hoc'
import { useLoadModels, useMountEffect } from '@src/hooks'
import { loadDocProfiles, loadDocTypes, loadMethRouteStatusList, loadMethVmclList, resetClinrecTableState, resetMethRoutesTableState, resetNpaTableState,
  selectDocProfilesModel, selectDocTypesModel, selectMethRouteStatusList, selectMethVmclList } from '@src/modules/methodology/store'

import config from '@src/config'
import { ClinRecPageWithAudit } from '../ClinRecPage/ClinRecPage'
import { NpaPageWithAudit } from '../NpaPage/NpaPage'
import { MethRoutesPageWithAudit } from '../MethRoutesPage/MethRoutesPage'
import './MethodologyPage.scss'


const MethodologyPage = () => {
  const [loaded, loading, error] = useLoadModels(
    [useSelector(selectDocTypesModel), loadDocTypes({})],
    [useSelector(selectDocProfilesModel), loadDocProfiles({})],
    [useSelector(selectMethRouteStatusList), loadMethRouteStatusList({})],
    [useSelector(selectMethVmclList), loadMethVmclList({})],
  )
  const dispatch = useDispatch()

  useMountEffect(() => {
    return () => {
      dispatch(resetClinrecTableState())
      dispatch(resetNpaTableState())
      dispatch(resetMethRoutesTableState())
    }
  })

  return <div className='root-methodology-page'>
    <LoadedOrError loaded={loaded} loading={loading} error={error}>
      <Switch>
        <Route path={URLS.METHODOLOGY__NPA}>
          <IfHasResource allowAcl={ACLS.METHOD_DOCUMENTS_NPA_READ} deniedComponent={<InternalError403 />}>
            <NpaPageWithAudit
              auditPs={AUDIT_PS.METHOD}
              auditMessage='Просмотр таблицы Порядков ОМП'
            />
          </IfHasResource>
        </Route>
        <Route path={URLS.METHODOLOGY__CLINREC}>
          <IfHasResource allowAcl={ACLS.METHOD_DOCUMENTS_CLINREC_READ} deniedComponent={<InternalError403 />}>
            <ClinRecPageWithAudit
              auditPs={AUDIT_PS.METHOD}
              auditMessage='Просмотр таблицы КР'
            />
          </IfHasResource>
        </Route>
        {
          config?.app?.modules?.enabled?.regional_routes
            ?
            <Route path={URLS.METHODOLOGY__ROUTES} deniedComponent={<InternalError403 />}>
              <IfHasResource allowAcl={ACLS.METHOD_GRAPH_READ}>
                <MethRoutesPageWithAudit
                  auditPs={AUDIT_PS.METHOD}
                  auditMessage='Просмотр таблицы Маршрутов'
                />
              </IfHasResource>
            </Route>
            : null
        }
        <Route path={URLS.METHODOLOGY}>
          <Redirect to={URLS.METHODOLOGY__NPA} />
        </Route>
        <Route component={InternalError404}/>
      </Switch>
    </LoadedOrError>
  </div>
}

export const MethodologyPageWithAcl = withAcl(MethodologyPage)
