import { useMemo, useCallback } from 'react'
import { Icon } from '@src/components'
import { PropTypes } from '@src/utils'
import { Combobox } from '../Combobox/Combobox'
import './Pagination.scss'

export const Pagination = ({ pageSize, total, shown, currentPage, onPageChange, onPageSizeChange }) => {
  const PAGE_SIZES = [
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ]

  const countRows = useMemo(() => pageSize, [pageSize])

  const countPage = useMemo(() => {
    return ((total - (total % countRows)) / countRows) + 1
  }, [countRows, total])

  const pageOptions = useMemo(() => {
    let array = []
    for(let i = 1; i <= countPage; i++) {
      array.push({ value: i })
    }
    return array
  }, [countPage])

  const handlerArrow = useCallback((value) => {
    onPageChange(currentPage + value > 0 && currentPage + value <= countPage ? currentPage + value : currentPage)
  }, [countPage, currentPage, onPageChange])

  const fromRowsShown = useMemo(() => {
    return countRows * currentPage - countRows + 1
  }, [countRows, currentPage])

  const byRowsShown = useMemo(() => {
    return fromRowsShown + shown - 1 <= total ? fromRowsShown + shown - 1 : total
  }, [fromRowsShown, shown, total])

  return <div className='root-pagination mt-auto'>
    <div className='pagination-numbers px-32'>
      <div>
        <Icon className='pagination-numbers-arrow' icon='arrow_left' onClick={() => handlerArrow(-1)} />
        <Combobox
          className='pagination-select ml-24 mr-8'
          value={currentPage}
          options={pageOptions}
          onChange={value => onPageChange(value)}
        />
        <span>
          из {countPage || 0}
        </span>
        <Icon className='pagination-numbers-arrow ml-24' icon='arrow_right' onClick={() => handlerArrow(1)} />
      </div>
      <div className='ml-auto'>
        <span>
          {fromRowsShown === byRowsShown || !byRowsShown || currentPage === 0 ? null : fromRowsShown + '-'}{currentPage === 0 ? 0 : byRowsShown || 0} из {total || 0}
        </span>
      </div>
    </div>
    <div className='pagination-show'>
      <span>
        Показывать по:
      </span>
      <Combobox
        className='pagination-select ml-8'
        value={countRows}
        options={PAGE_SIZES}
        onChange={value => onPageSizeChange(value)}
      />
    </div>
  </div>
}

Pagination.propTypes = {
  shown: PropTypes.number,           // Отображено записей в таблице
  total: PropTypes.number,           // Всего записей
  pageSize: PropTypes.number,        // Текущий размер страницы
  currentPage: PropTypes.number,     // Текущая страница
  onPageChange: PropTypes.func,      // callback, вызывается при изменении текущей страницы, принимает параметром новый номер страницы
  onPageSizeChange: PropTypes.func,  // callback, вызывается при изменении размера страницы, принимает параметром новый размер страницы
}
