import moment from 'moment'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Icon } from '@src/components'
import { useMountEffect } from '@src/hooks'
import { loadMethUpdateTimestamp, selectLastMethDocsModel } from '@src/modules/methodology/store'
import { cn } from '@src/utils'

import './UpdateTimestamp.scss'

export const UpdateTimestamp = ({ className }) => {
  const updateTimestampModel = useSelector(selectLastMethDocsModel)
  const dispatch = useDispatch()

  const timestamp = useMemo(() => {
    const lastEndOn = updateTimestampModel.payload?.last_end_on
    return lastEndOn ? moment(lastEndOn).format('DD.MM.YYYY HH:mm:ss') : ''
  }, [updateTimestampModel])

  useMountEffect(() => {
    if (!updateTimestampModel.loaded && !updateTimestampModel.loading)
      dispatch(loadMethUpdateTimestamp())
  })

  return <div className={cn('root-update-timestamp', className, !timestamp && 'timestamp-loading')}>
    <Icon className='timestamp-icon' icon='info_cr_fr' />
    <span className='mr-4'>Последнее обновление федеральных НПА:</span>
    <span>{timestamp}</span>
  </div>
}
