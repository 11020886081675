import cn from 'classnames'
import { debounce } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { Waypoint } from 'react-waypoint'
import { PropTypes } from '@src/utils'
import { Alert } from '@src/modals'
import { normalizeStr } from '@src/utils'
import { modalService } from '@src/services'
import { adminUserDelete, loadAdminUserList, loadAdminUserRoles, selectAdminUserListModel, selectRolesByUserId } from '@src/modules/admin/store'
import { AUDIT_PS, URLS } from '@src/constants'
import { AppNav, Checkbox, Loader, SortIcon, TextInput } from '@src/components'
import { ButtonWithAudit } from '@src/components/Button/Button'
import { withMntAudit } from '@src/hoc'
import { AdminUsersRolesWithAudit } from '../AdminUsersRoles/AdminUsersRoles'
import './AdminUsersPageTable.scss'

class _AdminUsersPageTable extends React.Component {
  static propTypes = {
    loadAdminUserList: PropTypes.func,
    loadAdminUserRoles: PropTypes.func,
    rolesByUserId: PropTypes.object,
    adminUserListModel: PropTypes.model,
    logToAudit: PropTypes.func,
  }

  constructor (props) {
    super(props)

    this.state = {
      loginSearch: '',
      loginSearchInputValue: '',
      sort: 'login',
      sortAsc: true,
      currentPage: 0,
      selectedFromCheckboxUserList: [],
      selectedUser: null,
    }
    this.debouncedHandleSearch = debounce(this.handleSearch, 1000)
  }

  componentDidMount = () => this.handleSearch()

  componentDidUpdate () {
    if(this.filteredUserList.length && this.state.selectedFromCheckboxUserList.length !== this.filteredUserList.length)
      this.setState({ selectedFromCheckboxUserList: [...Array(this.filteredUserList.length)].fill(false) })
  }

  handleSearch = () => this.props.loadAdminUserList({ args: this.listArgs, controls: this.listControls })

  get listArgs () { return { searchText: this.state.loginSearch } }

  get listRange () { return { chunk_start: this.state.currentPage * 100, chunk_end: this.state.currentPage * 100 + 98 } }

  get selectedUserCount () { return this.state.selectedFromCheckboxUserList.filter(i => i).length }

  handleLoadMore = () => {
    const model = this.props.adminUserListModel

    if (!model.loading && this.props.adminUserListModel.payload.hasMoreToLoad) {
      this.setState(p => ({
        currentPage: p.currentPage ? p.currentPage + 1 : 1,
      }), this.handleSearch)
    }
  }

  get listControls () {
    return {
      sorts: [],
      range: this.listRange,
      filters: [],
    }
  }

  handleUserRowClick = (user) => {
    const { rolesByUserId, loadAdminUserRoles } = this.props

    this.setState({ selectedUser: user })

    !rolesByUserId[user.id] && loadAdminUserRoles({ args: { user_id: user.id } })
  }

  sortFn = () => {
    const { sort, sortAsc } = this.state

    switch (sort) {
    case 'login':
      return (a, b) => {
        return sortAsc
          ? ('' + a.login).localeCompare(b.login)
          : ('' + b.login).localeCompare(a.login)
      }
    case 'fio':
      return (a, b) => {
        return sortAsc
          ? ('' + a.fio).localeCompare(b.fio)
          : ('' + b.fio).localeCompare(a.fio)
      }
    default: break
    }
  }

  changeSort = (sort) => {
    this.setState(prevState => ({
      sort,
      sortAsc: prevState.sort === sort ? !prevState.sortAsc : true,
    }))
  }

  get filteredUserList () {
    const { adminUserListModel } = this.props

    return [...adminUserListModel.payload?.data ?? []].sort(this.sortFn())
  }

  handleCheckboxClick (e, i) {
    e.stopPropagation()
    this.setState(p => ({ selectedFromCheckboxUserList: [...p.selectedFromCheckboxUserList].map((e, k) => k === i ? !e : e) }))
  }

  handleDleteUsers = () => {
    let promises = []
    const arg = { users: [] }

    for(let i = 0; i < this.state.selectedFromCheckboxUserList.length; i++) {
      if(this.state.selectedFromCheckboxUserList[i]) {
        promises.push(this.props.adminUserDelete(this.filteredUserList[i].id))
        arg.users.push(this.filteredUserList[i])
      }
    }

    this.props.logToAudit({
      auditPs: AUDIT_PS.ADMIN,
      auditMessage: `Деактивация ${this.selectedUserCount > 1 ? `${this.selectedUserCount} пользователей` : 'пользователя'}`,
      auditDescription: { ...arg },
    })

    Promise.all(promises).then(this.handleSearch)
  }

  render () {
    const { adminUserListModel } = this.state
    const loading = adminUserListModel?.loading

    return (
      <div className='root-admin-users-page'>
        <AppNav
          title='Пользователи'
          breadcrumbs={[
            {
              label: 'Администрирование',
              link: URLS.ADMIN__USERS,
            },
            {
              label: 'Пользователи',
              link: URLS.ADMIN__USERS,
            },
          ]}
        />
        <div className='admin-users-roles-container'>
          <div className='admin-users-roles-table'>
            <div className='register-pretable'>
              <TextInput
                onChange={e => this.setState({
                  currentPage: 0,
                  loginSearch: normalizeStr(e),
                  loginSearchInputValue: e,
                  selectedUser: null,
                }, this.debouncedHandleSearch)}
                value={this.state.loginSearchInputValue}
                allowClear
                icon='search'
                placeholder='Введите логин или ФИО пользователя'
                className='minw-300 maxw-300 mr-8'
              />
              <ButtonWithAudit
                icon='electrical_plug'
                isSecondary
                disabled={Boolean(!this.selectedUserCount)}
                className='register-pretable-right-button'
                label='Деактивировать'
                onClick={() => modalService.open(Alert, {
                  warn: true,
                  onOk: this.handleDleteUsers,
                  okText: 'Деактивировать',
                  title: `Деактивировать ${this.selectedUserCount > 1 ? 'пользователей' : 'пользователя' }`,
                  children: `Вы уверены, что хотите деактивировать ${this.selectedUserCount > 1 ? `${this.selectedUserCount} пользователей` : 'пользователя'}?`,
                })}
              />
            </div>
            <div className='admin-users-content'>
              {
                loading
              && <div className='admin-users-content-loader-overlay'>
                <Loader />
              </div>
              }
              <div className={cn('admin-users-content-view', loading ? 'admin-users-content-view-loading' : null)}>
                <table className='root-admin-users-page-table'>
                  <thead className='admin-users-table-head'>
                    <tr>
                      <th>
                        <Checkbox
                          onChange={() => {
                            this.selectedUserCount === this.state.selectedFromCheckboxUserList.length
                              ? this.setState(p => ({ selectedFromCheckboxUserList: [...p.selectedFromCheckboxUserList].fill(false) }))
                              : this.setState(p => ({ selectedFromCheckboxUserList: [...p.selectedFromCheckboxUserList].fill(true) }))
                          }}
                          value={Boolean(this.state.selectedFromCheckboxUserList.length === this.selectedUserCount
                          && this.state.selectedFromCheckboxUserList.length)}
                        />
                      </th>
                      <th width='30%'>
                        <div className='table-header'>
                          <div className='table-header-title'>
                            <div>Логин</div>
                            <SortIcon
                              className='sort-icon'
                              sortField='login'
                              currentSortField={this.state.sort}
                              sortType={this.state.sortAsc}
                              onClick={() => this.changeSort('login')}
                            />
                          </div>
                        </div>
                      </th>
                      <th width='70%'>
                        <div className='table-header'>
                          <div className='table-header-title'>
                            <div>ФИО</div>
                            <SortIcon
                              className='sort-icon'
                              sortField='fio'
                              currentSortField={this.state.sort}
                              sortType={this.state.sortAsc}
                              onClick={() => this.changeSort('fio')}
                            />
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.filteredUserList.map((user, i) => (
                        <tr
                          className={cn(this.state.selectedFromCheckboxUserList[i] && 'selected-checkbox',
                            this.state.selectedUser?.id === user.id && 'selected-user')}
                          onClick={() => this.handleUserRowClick(user)}
                          key={i}
                        >
                          <th onClick={e => this.handleCheckboxClick.call(this, e, i)}>
                            <Checkbox value={this.state.selectedFromCheckboxUserList[i]} />
                          </th>
                          <th>{user.login}</th>
                          <th>{user.fio}</th>
                        </tr>
                      ))
                    }
                    {
                      this.filteredUserList.length > 99
                        ?
                        <tr className='table-scroll-loader'>
                          <td colSpan='6'>
                            {
                              this.props.adminUserListModel.loading
                                ? <Loader />
                                : <Waypoint onEnter={this.handleLoadMore} />
                            }
                          </td>
                        </tr>
                        : null
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='roles-container'>
            <AdminUsersRolesWithAudit user={this.state.selectedUser} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    adminUserListModel: selectAdminUserListModel(state),
    rolesByUserId: selectRolesByUserId(state),
  }
}

const mapDispatchToProps = dispatch => ({
  loadAdminUserList: args => dispatch(loadAdminUserList(args)),
  loadAdminUserRoles: args => dispatch(loadAdminUserRoles(args)),
  adminUserDelete: args => dispatch(adminUserDelete(args)),
})

export const AdminUsersPageTableWithAudit = withMntAudit(connect(mapStateToProps, mapDispatchToProps)(_AdminUsersPageTable))
