import { useMemo, useEffect, useState, useCallback } from 'react'
import { debounce, isFunction } from 'lodash'

/**
 * @function useResizeObserver
 * @description Вызывает с задержкой 300мс {cb} каждый раз при изменении в размерах ref содержимого или рамок поля
 * @param {React.RefObject<HTMLInputElement>} ref
 * @param {Function} cb
 */
export const useResizeObserver = (ref, cb) => {
  const [, setResizeObserver] = useState(null)
  const debouncedCb = useMemo(() => debounce(cb, 300, { leading: true }), [cb])

  useEffect(() => {
    if (ref.current) {
      const ro = new ResizeObserver(debouncedCb)
      ro.observe(ref.current,)
      setResizeObserver(ro)
      return () => ro?.disconnect()
    }
  }, [ref, debouncedCb])
}

export const useRowKey = (data, rowKey) => {
  const getKeyForRowIndex = useCallback((index) => {
    const row = data[index]

    return isFunction(rowKey)
      ? rowKey(index, data)
      : row[rowKey]
  }, [data, rowKey])
  return [getKeyForRowIndex]
}
