import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Loader, Modal } from '@src/components'
import { remotesManager, withAuthorization } from '@src/remotes'
import { pushNotification, selectAccessToken } from '@src/store'
import { useMountEffect } from '@src/hooks'
import { NOTIFICATION_TYPES } from '@src/constants'
import { withMntAudit } from '@src/hoc'

import './RecordModal.scss'

const RecordModal = ({ title, ehrMsgId, patientId, ...props }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [html, setHtml] = useState(null)
  const accessToken = useSelector(selectAccessToken)
  const dispatch = useDispatch()

  useMountEffect(() => {
    const loadDocument = async () => {
      setIsLoading(true)
      try {
        const { data } = await remotesManager.BACKEND_API.get('/patient-monitoring/get_patient_document',
          withAuthorization(accessToken, { params: { msg_id: ehrMsgId, patient_id: patientId } }))
        setHtml(data)
      } catch (e) {
        dispatch(pushNotification({ type: NOTIFICATION_TYPES.ERROR, message: e?.response?.data?.error || e.message }))
      } finally {
        setIsLoading(false)
      }
    }
    loadDocument()
  }, [])

  return <Modal
    className='root-record-modal'
    title={title}
    {...props}
    width={732}
    footer={<RecordModalFooter disabled={isLoading || !html}/>}
  >
    <div className='record-modal'>
      {
        isLoading
          ? <Loader />
          : <iframe className='record-modal-document' id='printf' name='printf' title={title} srcDoc={html}/>
      }
    </div>
  </Modal>
}

export const RecordModalWithAudit = withMntAudit(RecordModal)

const RecordModalFooter = ({ disabled }) => {
  const handlePrint = () => {
    window.frames['printf'].focus()
    window.frames['printf'].print()
  }

  return <Button
    className='record-modal-button'
    label='Распечатать'
    icon='print'
    onClick={handlePrint}
    disabled={disabled}
    isSuccess
  />
}
