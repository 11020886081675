import { Popover } from 'antd'
import { isFunction } from 'lodash'
import { useCallback, useState } from 'react'
import { ErrorText } from '../ErrorText/ErrorText'
import { Icon } from '../Icon/Icon'
import { Loader } from '../Loader/Loader'
import './ActionMenu.scss'

export const ActionMenu = ({ children, options, loading, error, disabled }) => {
  const [open, setOpen] = useState(false)

  const closeMenu = useCallback(() => setOpen(false), [])

  return <Popover
    trigger='click'
    placement='bottomRight'
    content={<MenuOptions options={options} loading={loading} error={error} closeMenu={closeMenu}/>}
    overlayClassName='root-action-menu-overlay'
    open={open}
    onOpenChange={() => !disabled && setOpen(!open)}
  >
    {children}
  </Popover>
}

const MenuOptions = ({ options, loading, error, closeMenu }) => {
  return <div className='action-menu-options minw-280 minh-42' onClick={e => e.stopPropagation()}>
    {
      loading
        ? <Loader small />
        : error
          ? <ErrorText>{error}</ErrorText>
          : options.map((opt, index) => <Option
            key={index}
            option={opt}
            index={index}
            closeMenu={closeMenu}
          />)
    }
  </div>
}

const Option = ({ option, index, closeMenu  }) => {
  const { onClick, icon, label, iconTitle } = option

  const handleClick = () => {
    if (isFunction(onClick)) onClick(option, index)
    closeMenu()
  }

  return <div className='menu-option' onClick={handleClick}>
    { icon ? <Icon className='mr-8 minw-20 minh-20 w-20 h-20' icon={icon} title={iconTitle}/> : null }
    <span>{label}</span>
  </div>
}
