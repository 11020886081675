import { thunkCreator } from '@src/utils'
import * as models from '../models'

export const [
  loadPatientStatusOnko, loadPatientStatusOnkoExtra,
] = thunkCreator(models.PATIENT_STATUS_ONKO, '/patient-monitoring/get_patient_status_onko',
  { targetModel: models.PATIENT_STATUS })

export default {
  ...loadPatientStatusOnkoExtra,
}
