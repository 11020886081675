import { Combobox } from '@src/components'
import { selectDictDnReason } from '@src/modules/dictionaries/store'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class DnReasonId extends RegisterColumn {
  static type = REGISTER_COLUMNS.DN_REASON_ID

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Причина снятия с ДН',
        component: Combobox,
        props: {
          allowClear: true,
          mode: 'multiple',
          options: selectDictDnReason(store.getState()).payload?.map(mo => ({
            label: mo.name,
            value: mo.row_code,
            title: mo.name_full,
          })),
        },
        defaultValue: [],
      },
      width: 300,
      dataIndex: 'dn_reason',
      dataRender: dn_reason => dn_reason ? dn_reason : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let requestArgs = []

    if (filters?.dn_reason && filters?.dn_reason.length) {
      requestArgs = [{ field: 'dn_reason_code', value: filters.dn_reason, operator: '=', invalid: false, cast: 'right-any' }]
    }

    if (nullSearches[this.type]) {
      requestArgs = [{ field: 'dn_reason_code', operator: '=', value: null, invalid: false }]
    }

    return {
      control: {
        filters: requestArgs,
      },
    }
  }
}
