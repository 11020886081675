import { createSelector } from 'reselect'
import type { RootState } from '@src/store'
import { NSI_FRNSI_DICT_LIST, NSI_FRNSI_DICTS, IMPORT_NSI_FRNSI_DICT, NSI_FRNSI_DICTS_RECORDS_LIST } from './models'

export const selectNsi = (state: RootState) => state.nsi

export const selectNsiDictListModel = createSelector(
  selectNsi,
  nsi => nsi[NSI_FRNSI_DICT_LIST],
)

export const selectNsiFrnsiDicts = createSelector(
  selectNsi,
  nsi => nsi[NSI_FRNSI_DICTS],
)

export const selectNsiFrnsiDictRecordsListModal = createSelector(
  selectNsi,
  nsi => nsi[NSI_FRNSI_DICTS_RECORDS_LIST],
)

export const selectFrnsiImportModel = createSelector(
  selectNsi,
  nsi => nsi[IMPORT_NSI_FRNSI_DICT],
)
