import { isArray } from 'lodash'
import moment from 'moment'
import { useState, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DataGrid, DateRangePicker, Icon, TextInput, ColumnsSettings } from '@src/components'
import { AUDIT_PS, DEFAULT_ROW_HEIGHT, SORT_ORDER, URLS } from '@src/constants'
import { useAppSettings } from '@src/hooks/useAppSettings'
import { withMntAudit } from '@src/hoc'
import { useMountEffect, useColumns } from '@src/hooks'
import { loadPatientMedRecords, selectPatientMedRecordsModel } from '@src/modules/monitoring/store'
import { modalService } from '@src/services'
import { normalizeStr } from '@src/utils'

import { RecordModalWithAudit } from '../RecordModal/RecordModal'
import { ServicesModalWithAudit } from './servicesModal/ServicesModal'
import './TabMedRecords.scss'

const TabMedRecords = ({ patientId }) => {
  const dispatch = useDispatch()
  const medRecordsModel = useSelector(selectPatientMedRecordsModel)
  const [gridValue, setGridValue] = useState(null)
  const pageAppSettings = useAppSettings()
  const isServicesHidden = pageAppSettings?.tabs?.tab_med_records?.columns?.services?.disabled

  const COLUMNS = useMemo(() => ({
    begin_on: {
      header: {
        title: 'Дата',
        component: DateRangePicker,
        props: {
          allowEmpty: [true, true],
          allowClear: true,
          disabledDate: currentDate => currentDate && currentDate.isAfter(moment()),
        },
        defaultValue: [null, null],
      },
      enabled: true,
      width: 250,
      dataIndex: 'begin_on',
      dataRender: begin_on => moment(begin_on).format('DD.MM.YYYY'),
      sort: true,
      defaultSort: SORT_ORDER.DESC,
    },
    ...stringColumn('ehr_type_name', 'Медицинские документы', 300, true),
    ...(
      isServicesHidden
        ? {
          service_code: {
            header: {
              title: 'Перечень кодов оказанных медицинских услуг',
              component: TextInput,
              props: {
                icon: 'search',
                allowClear: true,
              },
              defaultValue: '',
            },
            enabled: true,
            width: 150,
            dataIndex: 'service_list',
            titleRender: services => services?.map(s => s.service_code).join(',\n'),
            dataRender: services => dataMultiRender(services?.map(s => s.service_code)),
          },
          service_name: {
            header: {
              title: 'Наименование оказанных медицинских услуг',
              component: TextInput,
              props: {
                icon: 'search',
                allowClear: true,
              },
              defaultValue: '',
            },
            enabled: true,
            width: 250,
            dataIndex: 'service_list',
            titleRender: services => services?.map(s => s.service_name).join(',\n'),
            dataRender: services => dataMultiRender(services?.map(s => s.service_name)),
          },
        }
        : {
          services: {
            header: 'Услуги',
            enabled: true,
            width: 100,
            dataIndex: 'service_list',
            titleRender: services => isArray(services) ? services.length : 0,
            dataRender: (services, row) => <ServicesIcon services={services} row={row}/>,
          },
        }
    ),
    ...stringColumn('mo_name', 'Медицинская организация', 250, true),
    ...stringColumn('diagnosis', 'Диагноз МКБ-10', 350, true),
    ...stringColumn('employee_fullname', 'Врач', 300, true),
    ...stringColumn('employee_post', 'Должность', 250, true),
    semd: {
      header: 'СЭМД',
      width: 100,
      dataIndex: 'msg_id',
      titleRender: () => 'Просмотр карточки СЭМД',
      dataRender: msg_id => <SemdIcon msgId={msg_id}/>,
    },
    ...stringColumn('event_id', 'Идентификатор документа', 100),
  }), [isServicesHidden])

  const [enabledColumns, columnOptions, toggleColumn] = useColumns(COLUMNS)



  useMountEffect(() => {
    dispatch(loadPatientMedRecords({ args: { patient_id: patientId } }))
  })

  const handleRowDoubleClick = row => modalService.open(RecordModalWithAudit, {
    patientId,
    ehrMsgId: row.msg_id,
    title: row.ehr_type_name,
    auditPs: AUDIT_PS.PATIENTMON,
    auditMessage: `Просмотр документа ${row.ehr_type_name}`,
    auditDescription: { ehr_msg_id: row.msg_id },
  })

  const data = medRecordsModel.payload?.data
  const filteredData = useMemo(() => {
    return gridValue && isArray(data)
      ? data
        .filter(row => dateFilter(row, gridValue.filters, 'begin_on'))
        .filter(row => stringFilter(row, gridValue.filters, 'ehr_type_name'))
        .filter(row => stringFilter(row, gridValue.filters, 'mo_name'))
        .filter(row => arrayFilter(row, gridValue.filters, 'service_list', 'service_code'))
        .filter(row => arrayFilter(row, gridValue.filters, 'service_list', 'service_name'))
        .filter(row => stringFilter(row, gridValue.filters, 'diagnosis'))
        .filter(row => stringFilter(row, gridValue.filters, 'employee_fullname'))
        .filter(row => stringFilter(row, gridValue.filters, 'employee_post'))
        .filter(row => stringFilter(row, gridValue.filters, 'event_id'))
        .sort(sortFn(gridValue.sort))
      : []
  }, [data, gridValue])

  const getItemSize = useCallback((index) => {
    const len = filteredData[index]?.service_list?.length ?? 0
    const res = DEFAULT_ROW_HEIGHT + ((len < 2 ? 1 : len) - 1) * 20
    return res
  }, [filteredData])

  return <div className='root-tab-medrecords'>
    <ColumnsSettings
      className='root-tab-medrecords-icon-setting'
      columnOptions={columnOptions}
      onToggleColumn={toggleColumn}
    />
    <DataGrid
      columns={enabledColumns}
      data={filteredData}
      onRowDoubleClick={handleRowDoubleClick}
      rowKey='msg_id'
      value={gridValue}
      onChange={setGridValue}
      loading={medRecordsModel.loading}
      error={medRecordsModel.error}
      {...(
        isServicesHidden
          ? { variableSizeList: {
            itemSize: getItemSize,
          } }
          : {}
      )}
    />
  </div>
}

const dataMultiRender = dataCell => (
  <div className='cell-column'>
    {
      dataCell?.map(el => <p key={el} className='cell-column-text'>{el}</p>)
    }
  </div>
)

const ServicesIcon = ({ services, row }) => {
  const handleServiceClick = () => {
    modalService.open(ServicesModalWithAudit, {
      services: services,
      auditPs: AUDIT_PS.PATIENTMON,
      auditMessage: `Просмотр списка услуг документа ${row.ehr_type_name}`,
      auditDescription: { ehr_msg_id: row.msg_id },
    })
  }

  return isArray(services) && services.length
    ? <div className='services-icon'>
      <Icon
        onClick={handleServiceClick}
        icon='doc'
        alt='Показать услуги'
      />
    </div>
    : null
}

const SemdIcon = ({ msgId }) => {
  const history = useHistory()
  const handleSemdClick = () => history.push(`${URLS.JOURNAL__SEMD}/${msgId}/common`)

  return <div className='services-icon'>
    <Icon
      onClick={handleSemdClick}
      icon='doc'
      alt='Показать СЭМД'
    />
  </div>
}

const stringColumn = (column, title, width, enabled = false) => ({
  [column]: {
    header: {
      title: title,
      component: TextInput,
      props: {
        icon: 'search',
        allowClear: true,
      },
      defaultValue: '',
    },
    enabled,
    width,
    dataIndex: column,
    sort: true,
  },
})

const stringFilter = (row, filters, column) => {
  return normalizeStr(row[column]).includes(normalizeStr(filters[column]))
}

const arrayFilter = (row, filters, column, field) => {
  if (!filters[field]) return true
  return row[column]?.find(e => normalizeStr(e[field]).includes(normalizeStr(filters[field])))
}

const dateFilter = (row, filters, column) => {
  const date = row[column]
  const start = filters[column] && filters[column][0]
  const end = filters[column] && filters[column][1]
  if (!date) return (start || end) ? false : true

  const startDate = start || moment('1900-01-01')
  const endDate = end || moment()
  const rowDate = moment(date)

  return rowDate.isSameOrAfter(startDate, 'day') && rowDate.isSameOrBefore(endDate, 'day')
}

const sortFn = ({ column, order }) => {
  switch (column) {
  case 'begin_on':
    return (a, b) => {
      const timeA = new Date(a.begin_on).getTime()
      const timeB = new Date(b.begin_on).getTime()
      return order === SORT_ORDER.ASC
        ? timeA - timeB
        : timeB - timeA
    }
  default:
    return (a, b) => {
      return order === SORT_ORDER.ASC
        ? ('' + a[column]).localeCompare(b[column])
        : ('' + b[column]).localeCompare(a[column])
    }
  }
}

export const TabMedRecordsWithAudit = withMntAudit(TabMedRecords)

TabMedRecordsWithAudit.tabTitle = 'Медицинские документы'
TabMedRecordsWithAudit.tabPath = 'records'
