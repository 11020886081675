export const requestArgsDateRange = (FIELD, { filters, nullSearches }) => {
  let requestFilters = []

  if (filters?.[FIELD] && (filters[FIELD][0] || filters[FIELD][1])) {
    requestFilters = [{
      field: FIELD,
      value: filters[FIELD].map(d => d ? d.local().toISOString(true) : '').join(','),
      operator: '<@',
      invalid: false,
      fieldtype: 'D',
    }]
  }

  if (nullSearches[FIELD]) {
    requestFilters = [{ field: FIELD, operator: '=', value: null, invalid: false }]
  }

  return {
    control: {
      filters: requestFilters,
    },
  }
}