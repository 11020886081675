import { createAsyncThunk } from '@reduxjs/toolkit'
import { thunkCreator, thunkCreatorInfinityScroll, extraCreator } from '@src/utils'
import { pushNotification } from '@src/store'
import { remotesManager, withAuthorization } from '@src/remotes'
import { selectAccessToken } from '@src/store'
import * as models from '../models'

export const [
  loadMethRoutesList, loadMethRoutesListExtra,
] = thunkCreatorInfinityScroll(models.METH_ROUTES_LIST, '/method/get_list_graphs_csp')

export const [
  loadMethRoutesListCount, loadMethRoutesListCountExtra,
] = thunkCreator(models.METH_ROUTES_LIST_COUNT, '/method/get_list_graphs_csp', { extractor: d => d?.data?.[0] })

export const [
  loadMethRouteStatusList, loadMethRouteStatusListExtra,
] = thunkCreator(models.METH_ROUTES_STATUS_LIST, '/method/get_list_graph_status')

export const [
  loadMethVmclList, loadMethVmclListExtra,
] = thunkCreator(models.METH_VMCL_LIST, '/method/get_vmcls')

export const [
  loadGraphVersions, loadGraphVersionsExtra,
] = thunkCreator(models.GRAPH_VERSIONS, '/method/get_versions_graph_csp')

export const [
  loadCheckStatus, loadCheckStatusExtra,
] = thunkCreator(models.CHECK_STATUS, '/method/check_status_csp',  { extractor: d => d })

export const [
  loadGraph, loadGraphExtra,
] = thunkCreator(models.GRAPH, '/method/get_graph_csp')

export const [
  loadGraphDescription, loadGraphDescriptionExtra,
] = thunkCreator(models.METH_GRAPH_DESCRIPTION, '/method/get_graph_description_csp')

export const [
  loadGetConditionsListCsp, loadGetConditionsListCspExtra,
] = thunkCreator(models.GET_CONDITIONS_LIST_CSP, '/method/get_conditions_list_csp')

export const [
  loadDeleteConditionCsp, loadDeleteConditionCspExtra,
] = thunkCreator(models.DELETE_CONDITIONS_CSP, '/method/delete_condition_csp')

export const [
  getAttributeTree, getAttributeTreeExtra,
] = thunkCreator(models.ATTRIBUTE_TREE, '/method/get_attribute_tree')

export const [
  getValueTypeList, getValueTypeListExtra,
] = thunkCreator(models.VALUE_TYPE_LIST, '/method/get_type_list')

// Таблица-объединение операций и value_type; meth_cc.operation_value_type;
export const [
  getOperationValueIdList, getOperationValueIdListExtra,
] = thunkCreator(models.OPERATION_VALUE_ID_LIST, '/method/get_operation_list')

export const [
  loadGetAttributeTreeByType, loadGetAttributeTreeByTypeExtra,
] = thunkCreator(models.GET_ATTRIBUTE_TREE_BY_TYPE, '/method/get_attribute_tree_by_type')

export const [
  loadDictDeviationValue, loadDictDeviationValueExtra,
] = thunkCreator(models.DICT_DEVIATION_VALUE, '/method/get_dict_deviation_value')

export const putGraph = createAsyncThunk(models.PUT_GRAPH,
  async (args, { getState, rejectWithValue, dispatch }) => {
    try {
      const responce = await remotesManager.BACKEND_API.post('/method/put_graph_csp', args, withAuthorization(selectAccessToken(getState())))
      if (responce.error) throw new Error(responce.error)
      dispatch(pushNotification({ type: 'success', message: 'Сохранение успешно', autoclose: true }))
      return responce
    } catch(e) {
      return rejectWithValue(e?.response?.data?.error || e.error || e.message)
    }
  },
)

// Получение настроек референсного атрибута для конструктора условий
export const [
  getRefbookDataAttribute, getRefbookDataAttributeExtra,
] = thunkCreator(models.REFBOOK_DATA_ATTRIBUTE, '/method/get_data_attribute')

// Получение данных значения референсного атрибута для панели выбора значений
export const [
  getReferenceAttribute, getReferenceAttributeExtra,
] = thunkCreator(models.REFERENCE_ATTRIBUTE, '/method/get_reference_attribute')

export const [
  getConditionForm, getConditionFormExtra,
] = thunkCreator(models.GET_CONDITION_FORM, '/method/bff/csp/get_condition_form', { extractor: d => d })

export const [
  postConditionForm, postConditionFormExtra,
] = thunkCreator(models.POST_CONDITION_FORM, '/method/bff/csp/post_condition_form', { extractor: d => d })


// *** Модалка копирования регионального условия

export const [getCopyModalRoutes, getCopyModalRoutesExtra] =
  thunkCreator(models.COPY_CONDITION_MODAL_ROUTES, '/method/get_list_graphs_csp')
export const [getCopyModalVersions, getCopyModalVersionsExtra] =
  thunkCreator(models.COPY_CONDITION_MODAL_VERSIONS, '/method/get_versions_graph_csp')
export const [getCopyModalStates, getCopyModalStatesExtra] =
  thunkCreator(models.COPY_CONDITION_MODAL_STATES, '/method/get_graph_description_csp')
export const [getCopyModalConditions, getCopyModalConditionsExtra] =
  thunkCreator(models.COPY_CONDITION_MODAL_CONDITIONS, '/method/get_conditions_list_csp')
export const [getCopyModalSourceCondition, getCopyModalSourceConditionExtra] =
  thunkCreator(models.COPY_CONDITION_MODAL_SOURCE_CONDITION, '/method/bff/csp/get_condition_form', { extractor: d => d })
export const [postCopyModalSaveCondition, postCopyModalSaveConditionExtra] =
  thunkCreator(models.COPY_CONDITION_MODAL_SAVE_CONDITION, '/method/bff/csp/post_condition_form', { extractor: d => d })

// ***

const extraReducers = {
  ...loadMethRoutesListExtra,
  ...loadMethRoutesListCountExtra,
  ...loadMethRouteStatusListExtra,
  ...loadMethVmclListExtra,
  ...loadGraphVersionsExtra,
  ...loadGraphExtra,
  ...extraCreator(putGraph, models.PUT_GRAPH),
  ...loadGraphDescriptionExtra,
  ...loadCheckStatusExtra,
  ...loadGetConditionsListCspExtra,
  ...loadDeleteConditionCspExtra,
  ...getAttributeTreeExtra,
  ...getOperationValueIdListExtra,
  ...getValueTypeListExtra,
  ...getRefbookDataAttributeExtra,
  ...getReferenceAttributeExtra,
  ...loadGetAttributeTreeByTypeExtra,
  ...loadDictDeviationValueExtra,
  ...getConditionFormExtra,
  ...postConditionFormExtra,
  ...getCopyModalRoutesExtra,
  ...getCopyModalVersionsExtra,
  ...getCopyModalStatesExtra,
  ...getCopyModalConditionsExtra,
  ...getCopyModalSourceConditionExtra,
  ...postCopyModalSaveConditionExtra,
}

export default extraReducers
