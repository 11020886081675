import { Combobox } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'


export class TmsResultColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.TMS_RESULT


  constructor (store, settings) {
    const column = {
      header: {
        title: 'Наличие патологии на неонатальном скрининге ТМС',
        component: Combobox,
        props: {
          allowClear: true,
          placeholder: 'Все',
          options: [
            { label: 'Обнаружено', value: 'Обнаружено', title: 'Обнаружено' },
            { label: 'Не обнаружено', value: 'Не обнаружено', title: 'Не обнаружено' },
          ],
        },
      },
      width: 300,
      dataIndex: 'tms_result',
      dataRender: tms_result => tms_result ? tms_result : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let requestFilters = []

    if (filters?.tms_result) {
      requestFilters = [{ field: 'tms_result', value: filters.tms_result, operator: '=', invalid: false }]
    }

    if (nullSearches[this.type]) {
      requestFilters = [{ field: this.type, operator: '=', value: '-', invalid: false }]
    }

    return {
      control: {
        filters: requestFilters,
      },
    }
  }
}
