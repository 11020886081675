import { forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { icons } from '@src/assets'
import './Icon.scss'

export const Icon = forwardRef(({ icon, onClick, className, ...props }, ref) => {
  const SVG = useMemo(() => {
    return icons[icon]
  }, [icon])

  return (
    <SVG
      ref={ref}
      className={cn('root-icon', className)}
      onClick={onClick}
      {...props}
    />
  )
})

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
}
