import { useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'
import { AppNav, IntegrationReportExternalWithAudit, IntegrationReportVimisWithAudit, InternalError404 } from '@src/components'
import { AUDIT_PS, URLS } from '@src/constants'
import { withAcl } from '@src/hoc'
import ReportLink from './ReportLink'
import { reportsFactory } from './reports'
import './ReportsPage.scss'

const ReportsPage = () => {
  const { REPORTS_INTEGRATION, REPORTS_VIMIS } = useMemo(() => {
    return reportsFactory()
  }, [])
  return (
    <div className='root-reports-page'>
      <Switch>
        {
          REPORTS_INTEGRATION.map(report => (
            <Route key={report.mclId} path={report.path}>
              <AppNav
                title={report.title}
                breadcrumbs={[
                  { label: 'Отчеты', link: URLS.ANALYTICS__REPORTS },
                  { label: report.title, link: report.path },
                ]}
              />
              <IntegrationReportExternalWithAudit
                mclId={report.mclId}
                title={report.title}
                auditPs={AUDIT_PS.ANALYTIC}
                auditMessage={`Просмотр страницы ${report.title}`}
              />
            </Route>
          ))
        }

        {
          REPORTS_VIMIS.map(report => (
            <Route key={report.mclId} path={report.path}>
              <AppNav
                title={report.title}
                breadcrumbs={[
                  { label: 'Отчеты', link: URLS.ANALYTICS__REPORTS },
                  { label: report.title, link: report.path },
                ]}
              />
              <IntegrationReportVimisWithAudit
                mclId={report.mclId}
                title={report.title}
                auditPs={AUDIT_PS.ANALYTIC}
                auditMessage={`Просмотр страницы ${report.title}`}
              />
            </Route>
          ))
        }

        <Route path={URLS.ANALYTICS__REPORTS}>
          <AppNav
            title='Отчеты'
            breadcrumbs={[
              { label: 'Отчеты', link: URLS.ANALYTICS__REPORTS },
            ]}
          />

          <div className='report-links'>
            {
              REPORTS_INTEGRATION.map(report => (
                <ReportLink
                  key={report.mclId}
                  enabled={report.enabled}
                  to={report.path}
                  title={report.title}
                  allowAcl={report.allowAcl}
                />
              ))
            }
            <div className='mt-16'></div>
            {
              REPORTS_VIMIS.map(report => (
                <ReportLink
                  key={report.mclId}
                  enabled={report.enabled}
                  to={report.path}
                  title={report.title}
                  allowAcl={report.allowAcl}
                />
              ))
            }
          </div>
        </Route>
        <Route component={InternalError404}/>
      </Switch>
    </div>
  )
}

export const ReportsPageWithAcl = withAcl(ReportsPage)
