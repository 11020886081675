import { thunkCreator } from '@src/utils'
import * as models from '../models'

export const [
  loadPatientStatusProfPmoD, loadPatientStatusProfPmoDExtra,
] = thunkCreator(models.PATIENT_STATUS_PROF_PMO_D, '/patient-monitoring/get_patient_status_prof_pmo_d',
  { targetModel: models.PATIENT_STATUS })

export const [
  loadPatientStatusProfPmoTeen, loadPatientStatusProfPmoTeenExtra,
] = thunkCreator(models.PATIENT_STATUS_PROF_PMO_TEEN, '/patient-monitoring/get_patient_status_prof_pmo_teenager',
  { targetModel: models.PATIENT_STATUS })

export const [
  loadPatientStatusProfImm, loadPatientStatusProfImmExtra,
] = thunkCreator(models.PATIENT_STATUS_PROF_IMM, '/patient-monitoring/get_patient_status_prof_imm',
  { targetModel: models.PATIENT_STATUS })

export const [
  loadPatientStatusProfDn, loadPatientStatusProfDnExtra,
] = thunkCreator(models.PATIENT_STATUS_PROF_DN, '/patient-monitoring/get_patient_status_prof_dn',
  { targetModel: models.PATIENT_STATUS })

export default {
  ...loadPatientStatusProfPmoDExtra,
  ...loadPatientStatusProfPmoTeenExtra,
  ...loadPatientStatusProfImmExtra,
  ...loadPatientStatusProfDnExtra,
}
