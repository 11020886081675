import { Form, Input } from 'antd'
import { Icon, IfNotReadonly } from '@src/components'
import { useConjunctAttributeNode, useConjunctOperationId, useConjunctLogicalNoApplied, useVDTypeCode, shouldUpdateOR, useConjunctAttributeId } from './FormContext'
import { OperationSelector } from './OperationSelector'
import { ConjunctValue } from './ConjunctValue'

export const Conjunct = ({ onDeleteConjunct }) => {
  const attribute_node = useConjunctAttributeNode()

  return <div className='root-conjunct d-flex'>
    {
      attribute_node
        ? <ConjunctFields attribute_node={attribute_node}/>
        : <ConjunctAttributeNotFound />
    }
    <IfNotReadonly>
      <div onClick={onDeleteConjunct} className='conjunct-delete-icon w-32 h-32 d-flex justify-content-center align-items-center'>
        <Icon className='w-16 h-16' icon='cancel_s_outline_16' />
      </div>
    </IfNotReadonly>
  </div>
}

const ConjunctFields = ({ attribute_node }) => {
  const [, , operationIdShouldUpdate] = useConjunctOperationId()
  const [, , logicalNoAppliedShouldUpdate] = useConjunctLogicalNoApplied()
  const [, , typeCodeShouldUpdate] = useVDTypeCode()
  return <>
    <Input
      readOnly
      className='conjunct-attribute'
      placeholder='Выберите атрибут'
      prefix={attribute_node.icon}
      value={attribute_node.caption}
      title={attribute_node.attr_path}
    />

    <Form.Item
      noStyle
      shouldUpdate={shouldUpdateOR(operationIdShouldUpdate, logicalNoAppliedShouldUpdate)}
    >
      {() => <OperationSelector />}
    </Form.Item>

    <div className='conjunct-value'>
      <Form.Item noStyle shouldUpdate={typeCodeShouldUpdate}>
        {() => <ConjunctValue />}
      </Form.Item>
    </div>
  </>
}

const ConjunctAttributeNotFound = () => {
  const [attribute_id] = useConjunctAttributeId()

  return <Input
    readOnly
    status='warning'
    value={`Атрибут с id '${attribute_id}' не определен в дереве атрибутов`}
  />
}
