import { Select } from 'antd'
import cn from 'classnames'
import { normalizeStr } from '@src/utils'
import './Combobox.scss'

/**
 * @see Documentation @link https://ant.design/components/select
 */
export const Combobox = ({ className, ...props }) => {
  const filterOption = (inputValue, option) => {
    return normalizeStr(option.label || option.value).includes(normalizeStr(inputValue))
  }

  const maxTagPlaceholder = (tags) => {
    return tags.length > 1
      ? `Выбрано: ${tags.length}`
      : tags[0]?.label
  }

  return (
    <Select
      className={cn('root-combobox', className)}
      showArrow
      filterOption={filterOption}
      autoClearSearchValue={false}
      maxTagPlaceholder={maxTagPlaceholder}
      maxTagCount={0}
      {...props}
    />
  )
}
