import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppNav, TextInput, LoadedOrError } from '@src/components'
import { URLS } from '@src/constants'
import { loadAdminRoleResourcesList, selectAdminRoleResourcesListModel } from '@src/modules/admin/store'
import { normalizeStr } from '@src/utils'
import { withMntAudit } from '@src/hoc'
import './AdminResourcesPage.scss'

const AdminResourcesPage = () => {
  const dispatch = useDispatch()
  const resourcesModel = useSelector(selectAdminRoleResourcesListModel)

  const [filterText, setFilterText] = useState('')
  const [resourcesList, setResources] = useState([])

  useEffect(() => {
    !resourcesModel.payload && dispatch(loadAdminRoleResourcesList())
  }, [resourcesModel, dispatch])

  useEffect(() => {
    const filteredResources = resourcesModel.payload?.filter(i => normalizeStr(i.title).includes(normalizeStr(filterText)) ||
      normalizeStr(i.path).includes(normalizeStr(filterText))
    )
    setResources(filteredResources)
  }, [resourcesModel, filterText])


  return(
    <div className='root-resources'>
      <AppNav
        title='Ресурсы'
        breadcrumbs={[
          {
            label: 'Администрирование',
            link: URLS.ADMIN__RESOURCES,
          },
          {
            label: 'Ресурсы',
            link: URLS.ADMIN__RESOURCES,
          },
        ]}
      />
      <LoadedOrError loaded={resourcesModel.loaded} loading={resourcesModel.loading} error={resourcesModel.error}>
        <div>
          <div className='resources-action'>
            <div className='input-label'>Поиск ресурса</div>
            <TextInput
              className='add-filtered-input'
              value={filterText}
              onChange={setFilterText}
              icon='search'
            />
          </div>
          <table>
            <thead>
              <tr>
                <th>Ресурсы</th>
                <th>Путь ресурса</th>
              </tr>
            </thead>
            <tbody>
              {
                resourcesList?.map(i => (
                  <tr key={i.path}>
                    <td>{i.title}</td>
                    <td>{i.path}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </LoadedOrError>
    </div>
  )
}

export const AdminResourcesPageWithAudit = withMntAudit(AdminResourcesPage)
