import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Card, DocStatusTag } from '@src/components'
// import { Card, DocStatusTag, Tag } from '@src/components'
// import { TAG_COLORS } from '@src/constants'                                                       //VIMISVIMIS-21048 VIMISVIMIS-21051
import { selectMclListModel } from '@src/modules/dictionaries/store'
import { selectDocumentModel } from '@src/modules/methodology/store'
import { withMntAudit } from '@src/hoc'

const TabInformation = () => {
  const npaInfoModel = useSelector(selectDocumentModel)
  const mclListModel = useSelector(selectMclListModel)
  const cardData = useMemo(() => {
    const data = npaInfoModel?.payload

    return !data
      ? null
      : [
        {
          title: 'Статус документа',
          data: data?.document_status ? <DocStatusTag>{data.document_status}</DocStatusTag> : '-',
        },
        {
          title: 'Форма документа',
          data: data?.form_caption ?? '-',
        },
        {
          title: 'Профиль ОМП',
          data: data?.profile_caption ?? '-',
        },
        {
          title: 'Направление',
          data: mclListModel.loaded && data.mcl_id
            ? mclListModel.payload.data.find(e => String(e.id) === String(data.mcl_id))?.short_name ?? '-'
            : '-',
        },
        {
          title: 'Обратная сила',
          data: data?.retroaction ? 'Да' : 'Нет',
        },
        // {
        //   title: 'Номер',
        //   data: () => data?.number ? <Tag color={TAG_COLORS.LIGHT_BEIGE}>{data?.number}</Tag> : '-',
        // },                                                                                           //VIMISVIMIS-21048 VIMISVIMIS-21051
        {
          title: 'Полное наименование',
          data: data?.name_full ?? '-',
        },
        {
          title: 'Краткое наименование',
          data: data?.name_short ?? '-',
        },
      ]
  }, [npaInfoModel, mclListModel])

  return (
    <div className='pa-16' style={{ maxWidth: 700 }}>
      <Card title='О документе' data={cardData} loading={npaInfoModel.loading}/>
    </div>
  )
}

export const TabInformationWithAudit = withMntAudit(TabInformation)

TabInformationWithAudit.tabTitle = 'Информация'
TabInformationWithAudit.tabPath = 'information'
