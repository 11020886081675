import { MaskedTextInput } from '@src/components'
import { formatSnils, stripSnils } from '@src/utils'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class SnilsColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.SNILS

  constructor (store, settings) {
    const column = {
      header: {
        title: 'СНИЛС',
        component: MaskedTextInput,
        defaultValue: '',
        props: {
          mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/],
          placeholderChar: '_',
          placeholder: '___-___-___ __',
          icon: 'search',
          isInvalid: snils => snils && snils.length !== 0 && snils.replace(/[#\s-_]/g, '').length < 3,
          allowClear: true,
        },
      },
      width: 150,
      dataIndex: 'snils',
      dataRender: formatSnils,
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let snils = undefined

    if (filters?.snils) snils = stripSnils(filters?.snils)

    if (nullSearches.snils) snils = null

    return {
      args: {
        snils,
      },
    }
  }
}
