import { useMemo } from 'react'
import { isArray } from 'lodash'
import { useSelector } from 'react-redux'
import { selectCurrentRole } from '@src/store'
import { Acl } from '@src/utils/types'

/**
 * Хук проверяет есть ли доступ
 * @param {Acl} acl   строка или массив строк ресурсов доступа
 * @returns {boolean} имеется ли доступ, возвращает тип boolean
 */
export const useUserHasResource = (acl: Acl): boolean => {
  const currentRole = useSelector(selectCurrentRole)

  const has = useMemo(() => {
    const acls = isArray(acl) ? acl : [acl]

    return Boolean(isArray(currentRole.acl) && acls.find(acl => currentRole.acl.includes(acl)))
  }, [acl, currentRole.acl])

  return has
}
