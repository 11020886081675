import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

/**
 * @description Хук позволяет прозрачно получать и устанавливать query параметры URL
 * @param {Function} [pack] - дополнительная предобработка объекта перед установкой параметров в URL
 * @param {Function} [unpack] - дополнительная постобработка объекта параметров, полученного из URL
 * @returns {[Object, Function]}
 */
export const useUrlQuery = (unpack = p => p, pack = p => p) => {
  const history = useHistory()
  const location = useLocation()

  const query = useMemo(() => {
    const params = new URLSearchParams(location.search)

    let result = {}
    for (const key of params.keys()) {
      result = {
        ...result,
        [key]: params.getAll(key), // значение всегда будет завернуто в массив
      }
    }
    return unpack(result)
  }, [location.search, unpack])

  const setQuery = useCallback((params) => {
    const query = new URLSearchParams(pack(params)).toString()
    history.replace({ pathname: location.pathname, search: query })
  }, [history, location.pathname, pack])

  return [query, setQuery]
}
