import { Button } from '@src/components'
import { Icon } from '../Icon/Icon'
import './InternalError.scss'

const InternalError = ({ number, header, icon, reasons }) => {

  return <div className='root-internal-error'>
    {
      icon
        ? <Icon className='error-icon mb-56' icon={icon} />
        : null
    }
    {
      number
        ? <h1>{number}</h1>
        : null
    }
    {
      header
        ? <h2>{header}</h2>
        : null
    }
    <a href='/'>
      <Button className='my-44' label='Перейти на главную страницу' icon='error_button_icon' />
    </a>
    {
      reasons
        ?
        <div className='error-content'>
          <div className='error-content-header ml-24'>
            Причина
          </div>
          <ul className='error-content-reasons ml-24'>
            {
              reasons.map((reason, index) => <li className='mb-24 ml-24' key={`error-reason-${number}-${index}`}>{reason}</li>)
            }
          </ul>
        </div>
        : null
    }
  </div>
}

export const InternalError400 = () => {
  const error = {
    number: 400,
    header: 'На сервере произошла ошибка',
    reasons: [
      // eslint-disable-next-line max-len
      'Данные ошибки возвращаются в случае, если запрос получен, не может быть обработан сервером. Причины могут быть очень разными и не всегда могут быть правильно интерпретированы со стороны клиента.',
    ],
    icon: 'error_400',
  }

  return <InternalError {...error} />
}

export const InternalError403 = () => {
  const error = {
    number: 403,
    header: 'Доступ запрещен',
    reasons: [
      'авторизация успешна, но для данного пользователя доступ запрещен;',
      'для доступа к странице сайта требуется авторизация на нем самом  либо в прокси-сервере.',
    ],
    icon: 'error_403',
  }

  return <InternalError {...error} />
}

export const InternalError404 = () => {
  const error = {
    number: 404,
    header: 'Ресурс не найден',
    reasons: [
      'страница сайта была удалена или перемещена;',
      'запрошенный ресурс действительно отсутствует, т.к. его адрес был введен пользователем вручную неверно;',
      'заголовок страницы был изменен, и система управления сайтом автоматически изменила ее URI (универсальный идентификатор ресурса);',
      'нарушена целостность сайта в результате проблем с программным или аппаратным обеспечением, например, стерт или недоступен индексный файл, картинка, видеоролик и т.д.',
    ],
    icon: 'error_404',
  }

  return <InternalError {...error} />
}

export const InternalError500 = () => {
  const error = {
    number: 500,
    header: `На сервере произошла непредвиденная ошибка.
    Обратитесь в техническую поддержку.`,
    reasons: [
      'ошибки в программном коде сайта;',
      'проблемы с настройкой веб-сервера.',
    ],
    icon: 'error_500',
  }

  return <InternalError {...error} />
}

export const InternalError503 = () => {
  const error = {
    number: 503,
    header: `Сервер временно не доступен.
    Пожалуйста, повторите попытку позднее.`,
    reasons: [
      'проблемы с серверным программным обеспечение: веб-сервер, базы данных, кеширующие прокси-сервера, акселераторы выполнения кода;',
      'сервер действительно отсутствует в Сети из-за проблем с его аппаратурой, питанием, сетью, в которой он находится.',
    ],
    icon: 'error_503',
  }

  return <InternalError {...error} />
}
