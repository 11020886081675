import { useContext } from 'react'
import { AccessContext } from '@src/context'

interface NotReadonlyProps {
  children: React.ReactNode
  deniedComponent?: React.ReactNode | null
}

/**
 * Компонент проверяет есть ли у текущего пользователя readonly,
 * если есть - рендерит children, иначе deniedComponent
 * @param {NotReadonlyProps}        NotReadonlyProps                  - основной props
 * @param {React.ReactNode}         NotReadonlyProps.children         - компонент, который отображается если не только для чтения
 * @param {React.ReactNode | null}  NotReadonlyProps.deniedComponent  - компонент, который отображается если только для чтения
 */
export const IfNotReadonly = ({ children, deniedComponent = null }: NotReadonlyProps) => {
  const accessContext = useContext(AccessContext)

  return (
    accessContext.readonly
      ? deniedComponent
      : children
  )
}
