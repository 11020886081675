import { isArray } from 'lodash'

/**
 * Осуществляет обход дерева tree и ищет такой узел, для которого cb(node) === true
 * Обход заканчивается. как только найден искомый объект
 * @param {*} tree дерево, объект с полем children, либо массив таких объектов
 * @param {(node) => boolean} cb функция, определяет соответствие узла дерева искомому объекту. Если возвращает true, то объект считается найденым
 * @param {string} [childrenKey='children'] ключ в node для определения массива потомков. По-умолчанию 'children'
 * @return node
 */
export const findOneInTree = (tree, cb, childrenKey='children') => {
  if (!tree) return null

  let t = isArray(tree) ? { [childrenKey]: tree } : tree
  let result = cb(t) && t
  if(result) return result

  for(const node of t[childrenKey]) {
    result = findOneInTree(node, cb, childrenKey)
    if(result) return result
  }

  return null
}
