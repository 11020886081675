import { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './RangeInput.scss'

export default class RangeInput extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.array,
    onChange: PropTypes.func,
    name: PropTypes.string,
    allowedChars: PropTypes.instanceOf(RegExp),
    className: PropTypes.string,
  }

  static defaultProps = {
    disabled: false,
  }

  handleStartChange = (event) => {
    let value = event.target.value

    if (this.props.allowedChars) value = filterAllowed(value, this.props.allowedChars)

    if (value !== this.props.value[0]) {
      this.props.onChange([
        value,
        this.props.value[1],
      ])
    }
  }

  handleEndChange = (event) => {
    let value = event.target.value
    if (this.props.allowedChars) value = filterAllowed(value, this.props.allowedChars)

    if (value !== this.props.value[1]) {
      this.props.onChange([
        this.props.value[0],
        value,
      ])
    }
  }

  render () {
    const { value, name, placeholder, className, disabled } = this.props

    return (
      <div className={cn('root-range-input', className)}>
        <input
          disabled={disabled}
          onChange={this.handleStartChange}
          className='range-input-input'
          type='text'
          value={value?.[0] || ''}
          id={`${name}-start`}
        >
        </input>
        <span>-</span>
        <input
          disabled={disabled}
          placeholder={placeholder}
          onChange={this.handleEndChange}
          className='range-input-input'
          type='text'
          value={value?.[1] || ''}
          id={`${name}-end`}
        >
        </input>
      </div>
    )
  }
}

const filterAllowed = (str, allowed) => str = str.split('').reduce((acc, char) => acc + (allowed.test(char) ? char : ''), '')
