import { initialModel } from '@src/utils'
import * as models from './models'

const reducers = {
  updateExternalSystem: (state, action) => {
    const index = state[models.ADMIN_EXTERNAL_SYSTEMS_LIST].payload.data.findIndex(ext => ext.id === action.payload[0].id)
    state[models.ADMIN_EXTERNAL_SYSTEMS_LIST].payload.data[index] = action.payload[0]
  },
  resetAdminUsersList: state => ({ ...state, [models.ADMIN_USERS_LIST]: initialModel }),
  clearAdminExtSystemsHistory: state => ({ ...state, [models.ADMIN_EXT_SYSTEMS_HISTORY]: initialModel }),
}

export default reducers
