import { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Popover } from 'antd'
import { useDebounce } from '@src/hooks/useDebounce'
import { loadMkb10Diagnosis, selectMkb10Diagnosis } from '@src/modules/monitoring/store'
import { Icon, Loader } from '@src/components'
import './Mkb10Input.scss'

const TAG_WIDTH = 70
const TAG_SLOTS_FOR_INPUT = 2
const TAG_MIN_COUNT = 1

const MkbInputTag = ({ deleteMkb, mkb }) => {
  return(
    <div className='mkb-input-tag'>
      <div>{mkb.ref_item_code}</div>
      <Icon className='icon-delete-tag' icon='close' onClick={() => deleteMkb(mkb.ref_item_id)} />
    </div>
  )
}

const DropdownList = ({ mkbsList, addMkb, ...props }) => {
  return(
    <div {...props}>
      {
        mkbsList?.root?.loading
          ? <Loader className='mkb-drop-down-loader' />
          : mkbsList?.root?.payload?.data?.map(mkb => (
            <div key={mkb.ref_item_id} className='mkb-drop-down-item' onClick={() => addMkb(mkb)} title={mkb.full_mkb_name}>
              {mkb.full_mkb_name}
            </div>
          ))
      }
    </div>
  )
}

export const Mkb10Input = ({ addMkbInput, openMkbModal, icon, mkbsIdInput, name, deleteMkbInput }) => {
  const [searchText, setSearchText] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [mkbsList, setmkbsList] = useState()
  const [changedMkbs, setChangedMkb] = useState({})
  const rootInputRef = useRef()
  const dispatch = useDispatch()
  const mkbsModel = useSelector(selectMkb10Diagnosis)

  const mkbsTagList = Object.values(changedMkbs)
  const displayedTags = mkbsTagList.slice(
    0,
    Math.max(
      TAG_MIN_COUNT,
      Math.floor((rootInputRef?.current?.clientWidth ?? 0) / TAG_WIDTH) - TAG_SLOTS_FOR_INPUT)
  )

  const debouncedLoadMkb10Diagnosis = useDebounce(searchText => dispatch(
    loadMkb10Diagnosis({ args: {}, controls: {}, hidValue: null, filterValue: searchText })), 500)

  const clearMkbArray = () => {
    setmkbsList(null)
  }

  useEffect(() => {
    setmkbsList(mkbsModel)
  }, [mkbsModel])

  useEffect(() => {
    setChangedMkb(mkbsIdInput)
  }, [mkbsIdInput])

  useEffect(() => {
    searchText.length > 2 && debouncedLoadMkb10Diagnosis(searchText)
    searchText ? setOpenModal(true) : setOpenModal(false)
  }, [searchText, debouncedLoadMkb10Diagnosis])

  useEffect(() => {
    !openModal && clearMkbArray()
  }, [openModal, mkbsTagList])

  const addMkb = (mkb) => {
    setChangedMkb({ ...changedMkbs, [mkb.ref_item_id]: mkb })
    setSearchText('')
    setOpenModal(false)
    addMkbInput({ ...changedMkbs, [mkb.ref_item_id]: mkb })
  }

  const onKeyDownHandle = (e) => {
    const { key } = e
    if (key === 'Backspace' && !searchText && mkbsTagList.length) {
      e.preventDefault()
      const poppedMkb = mkbsTagList.pop()
      deleteMkbInput(poppedMkb.ref_item_id)
    }
  }

  return(
    <Popover
      content={<DropdownList mkbsList={mkbsList} addMkb={addMkb} />}
      overlayClassName='mkb-drop-down-list'
      overlayStyle={{
        width: rootInputRef?.current?.clientWidth,
        minWidth: '150px',
      }}
      open={openModal}
      trigger='focus'
      placement='bottom'
      showArrow={false}
      onOpenChange={setOpenModal}
    >
      <div ref={rootInputRef} className='root-mkb10-input'>
        <div className='mkb-input-wrap'>
          {
            displayedTags?.map(mkb => <MkbInputTag
              key={mkb.ref_item_id}
              mkb={mkb}
              deleteMkb={deleteMkbInput}/>,
            )
          }
          {
            mkbsTagList.length - displayedTags.length
              ?<div className='mkb-input-tag' title={mkbsTagList.map(mkb => mkb.ref_item_code).join('; ')}>
                <div>+{mkbsTagList.length - displayedTags.length}</div>
              </div>
              : null
          }
          <input
            value={searchText}
            autoComplete='off'
            type='text'
            onKeyDown={onKeyDownHandle}
            name={name}
            className='mkb-input'
            onChange={e => setSearchText(e.target.value)}
          />
          <Icon className='icon-link-mkb-modal-open' alt='' icon={icon} onClick={openMkbModal} />
        </div>
      </div>
    </Popover>
  )
}

Mkb10Input.propTypes = {
  addMkbInput: PropTypes.func,
  openMkbModal: PropTypes.func,
  icon: PropTypes.string,
  mkbsIdInput: PropTypes.object,
  deleteMkbInput: PropTypes.func,
  name: PropTypes.string,
}
MkbInputTag.propTypes = {
  mkb: PropTypes.object,
  deleteMkb: PropTypes.func,
}
DropdownList.propTypes = {
  mkbsList: PropTypes.object,
  addMkb: PropTypes.func,
}
