import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import cn from 'classnames'
import { loadThesisList, selectThesisListModel } from '@src/modules/methodology/store'
import { Tag, TextInput, Loader } from '@src/components'
import { normalizeStr } from '@src/utils'
import { TAG_COLORS } from '@src/constants'
import { withMntAudit } from '@src/hoc'
import { RevisionSelector } from '../../RevisionSelector/RevisionSelector'
import './TabTheses.scss'

function TabTheses ({ selectedRevisionId, onRevisionChange }) {
  const [thesisInput, setThesisInput] = useState('')
  const [commentThesisInput, setCommentThesisInput] = useState('')
  const [convincingInput, setConvincingInput] = useState('')
  const [activityInput, setActivityInput] = useState('')
  const [serviceInput, setServiceInput] = useState('')

  const thesisList = useSelector(selectThesisListModel)
  const dispatch = useDispatch()

  useEffect(() => {
    selectedRevisionId && dispatch(loadThesisList({ args: { revisionId: selectedRevisionId } }))
  }, [dispatch, selectedRevisionId])

  const tableData = useMemo(() => {
    if(!Array.isArray(thesisList.payload?.data)) return []

    return thesisList.payload.data.map(thesis => ({
      caption: thesis?.caption ?? '',
      convincing: `${thesis?.convincing}/${thesis?.evidential}`,
      comment: thesis?.comment ?? '',
      activity: (thesis.activity ?? [...Array(1)]).map((activity_e) => {
        const activitiNameIsDisabled = activityInput
          ? !activity_e?.activity_name
            ? true
            : !normalizeStr(activity_e?.activity_name).includes(normalizeStr(activityInput))
          : false

        return {
          name: activity_e?.activity_name ?? '',
          isDisabled: activitiNameIsDisabled,
          service: (activity_e?.service ?? [...Array(1)]).map((serviceItem) => {
            const servicesCodeIsDisabled = serviceInput
              ? !serviceItem?.service_code
                ? true
                : !normalizeStr(serviceItem?.service_code).includes(normalizeStr(serviceInput))
              : false
            const servicesNameIsDisabled = serviceInput
              ? !serviceItem?.service
                ? true
                : !normalizeStr(serviceItem?.service).includes(normalizeStr(serviceInput))
              : false

            return {
              serviceName: serviceItem?.service ?? '',
              serviceCode: serviceItem?.service_code ?? '',
              isDisabled: activitiNameIsDisabled || !(!servicesCodeIsDisabled || !servicesNameIsDisabled),
            }
          }),
        }
      }),
    }))
      .filter(i => thesisInput ? !i.caption ? false : normalizeStr(i.caption).includes(normalizeStr(thesisInput)) : true)
      .filter(i => commentThesisInput ? !i.comment ? false : normalizeStr(i.comment).includes(normalizeStr(commentThesisInput)) : true)
      .filter(i => convincingInput ? !i.convincing ? false : normalizeStr(i.convincing).includes(normalizeStr(convincingInput)) : true)
      .filter(i => activityInput ? i.activity.filter(k => !k.isDisabled).length : true)
      .filter(i => serviceInput ? i.activity.filter(k => k.service.filter(t => !t.isDisabled).length).length : true)
  }, [activityInput, convincingInput, serviceInput, thesisInput, commentThesisInput, thesisList.payload?.data])

  return (
    <div className='root-theses-tab'>
      <div className='theses-card-container'>
        <RevisionSelector
          selectedRevisionId={selectedRevisionId}
          onRevisionChange={onRevisionChange}
        />
      </div>
      <div className='theses-card-data-container'>
        <div className='theses-tab-table-header'>
          <div className='theses-tab-table-header-container'>
            <div className='theses-tab-table-header-container-title'>Тезис рекомендация</div>
            <TextInput value={thesisInput} onChange={e => setThesisInput(e)} className='theses-tab-search' icon='search' />
          </div>
          <div className='theses-tab-table-header-container'>
            <div className='theses-tab-table-header-container-title'>Комментарий к тезису</div>
            <TextInput value={commentThesisInput} onChange={e => setCommentThesisInput(e)} className='theses-tab-search' icon='search' />
          </div>
          <div className='theses-tab-table-header-container'>
            <div className='theses-tab-table-header-container-title'>Убедительность/достоверность</div>
            <TextInput value={convincingInput} onChange={e => setConvincingInput(e)} className='theses-tab-search' icon='search' />
          </div>
          <div className='theses-tab-table-header-container'>
            <div className='theses-tab-table-header-container-title'>Лечебно-диагностические мероприятия</div>
            <TextInput value={activityInput} onChange={e => setActivityInput(e)} className='theses-tab-search' icon='search' />
          </div>
          <div className='theses-tab-table-header-container'>
            <div className='theses-tab-table-header-container-title'>Медицинские услуги</div>
            <TextInput value={serviceInput} onChange={e => setServiceInput(e)} className='theses-tab-search' icon='search' />
          </div>
        </div>
        <div className='root-theses-tab-table scroll-y'>
          {
            thesisList.loading
              ? <div className='grid-cell grid-column-start-5'><Loader /></div>
              : <TabThesesleBody thesisList={tableData} />
          }
        </div>
      </div>
    </div>
  )
}

const TabThesesleBody = ({ thesisList }) => {
  return thesisList.map((thesis, thesisItem) => {
    const serviceLength = thesis.activity.map(i => i.service.length).reduce((acc, num) => acc + num, 0)

    return (
      <Fragment key={thesisItem}>
        <div className={cn('grid-cell', `grid-row-start-${serviceLength}`)}>{thesis.caption}</div>
        <div className={cn('grid-cell', `grid-row-start-${serviceLength}`)}>{thesis.comment}</div>
        <div className={cn('grid-cell',  `grid-row-start-${serviceLength}`)}>{thesis.convincing}</div>
        {
          thesis.activity.map((activity_e, activity_i) => (
            <Fragment key={activity_i}>
              <div
                className={cn('grid-cell', `grid-row-start-${activity_e.service.length}`,
                  (activity_e.isDisabled || activity_e.service.filter(s => s.isDisabled).length === activity_e.service.length) && 'col-disabled')}
              >
                {activity_e.name}
              </div>
              {
                activity_e.service.map((service_e, service_i) => (
                  <div key={service_i} className={cn('grid-cell', 'cell-last', service_e.isDisabled && 'col-disabled')}>
                    <div className='cell-last-container'>
                      <span>{!!service_e.serviceCode && <Tag color={TAG_COLORS.GREY_100}>{service_e.serviceCode}</Tag>}</span>
                      <span>{service_e.serviceName}</span>
                    </div>
                  </div>
                ))}
            </Fragment>
          ))
        }
      </Fragment>
    )
  })
}

export const TabThesesWithAudit = withMntAudit(TabTheses)

TabThesesWithAudit.tabTitle = 'Тезисы'
TabThesesWithAudit.tabPath = 'theses'
