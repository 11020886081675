import { useMemo, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Icon, TextInput, EmptyState, Button, IfNotReadonly } from '@src/components'
import { normalizeStr } from '@src/utils'
import { selectDictMoModel } from '@src/modules/dictionaries/store'

export const SubblockTerritorial = ({ caption, territory_list, mo_list, handleAddition, handleCleaning,
  handleDeleteItemTerritory, handleDeleteItemMo, isTerritory }) => {
  const [search, setSearch] = useState('')
  const moModel = useSelector(selectDictMoModel)

  const filteredDataTerritory = useMemo(() => {
    return search
      ? territory_list?.filter(opt => normalizeStr(opt?.TERRITORY_NAME ?? opt.territory_name).includes(normalizeStr(search)))
      : territory_list
  }, [territory_list, search])

  const getMoByUuid = useCallback((uuid) => {
    return moModel.payload?.data?.find(i => i.mo_id === uuid)
  }, [moModel])

  const filteredDataMo = useMemo(() => {
    return search
      ? mo_list?.filter(opt => normalizeStr(getMoByUuid(opt)?.name_short).includes(normalizeStr(search)))
      : mo_list
  }, [getMoByUuid, mo_list, search])

  return <div className='territorial-condition-subblock'>
    <div className='territorial-condition-subblock-header'>
      <div className='territorial-condition-subblock-header-caption' title={caption}>{caption}</div>
      <div className='territorial-condition-subblock-header-board'>
        <IfNotReadonly>
          <Button
            className='territorial-condition-subblock-header-board-icon mr-8'
            icon={isTerritory || !mo_list?.length ? 'plus_cr_fr' : 'pencil_green'}
            isIconButton
            onClick={handleAddition}
            disabled={!isTerritory && !territory_list?.length}
          />
          <Button
            className='territorial-condition-subblock-header-board-icon'
            icon='eraser'
            isIconButton
            onClick={handleCleaning}
            disabled={isTerritory
              ? !territory_list?.length
              : !mo_list?.length
            }
          />
        </IfNotReadonly>
      </div>
      <div className='territorial-condition-subblock-header-search'>
        <TextInput
          placeholder='Поиск'
          icon='search'
          value={search}
          onChange={setSearch}
          allowClear
        />
      </div>
    </div>
    {
      filteredDataTerritory?.length
        ? <div className='territorial-condition-subblock-body scroll-y'>
          {
            isTerritory && [...filteredDataTerritory ?? []]
              ?.sort((a, b) => String(a?.TERRITORY_NAME ?? a?.territory_name ?? '').localeCompare(b?.TERRITORY_NAME ?? b?.territory_name ?? '', 'ru-RU'))
              ?.map((row, c) => <Row
                handleDeleteItem={() => handleDeleteItemTerritory(row)}
                name={row.TERRITORY_NAME ?? row.territory_name}
                key={c}
              />)
          }
          {
            filteredDataMo?.map(row => <Row
              handleDeleteItem={() => handleDeleteItemMo(row)}
              name={getMoByUuid(row)?.name_short}
              key={row}
            />)
          }
          {
            !isTerritory && !filteredDataMo?.length
              ? <EmptyState className='territorial-condition-empty' caption='Здесь пока ничего нет.' description='Для начала работы добавьте медицинские организации (МО)' />
              : null
          }
        </div>
        : null
    }
  </div>
}

const Row = ({ name, handleDeleteItem }) => {

  return <div className='territorial-condition-subblock-body-row'>
    <div className='territorial-condition-subblock-body-row-caption' title={name}>
      {name}
    </div>
    <IfNotReadonly>
      <div className='territorial-condition-subblock-body-row-board'>
        <Icon onClick={handleDeleteItem} className='territorial-condition-subblock-body-row-board-icon' icon='trash_can_green' />
      </div>
    </IfNotReadonly>
  </div>
}
