import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TabRouteBase } from '@src/modules/monitoring/components'
import { loadOccasionInfo, loadPatientStatusOcc, loadPersonInfo, selectOccasionInfoModel, selectPatientInfoModel, selectPatientStatusModel, selectPersonInfoModel } from '@src/modules/monitoring/store'
import { useMountEffect } from '@src/hooks'
import { withMntAudit } from '@src/hoc'

const TabRouteNewborn = ({ patientId, occasionPatientId, ...props }) => {
  const dispatch = useDispatch()
  const [paramsLoadRoutes, setParamsLoadRoutes] = useState(null)
  const [paramsLoadRoutesError, setParamsLoadRoutesError] = useState(null)
  const patientInfoModel = useSelector(selectPatientInfoModel)
  const patientStatusModel = useSelector(selectPatientStatusModel)
  const motherPersonInfoModel = useSelector(selectPersonInfoModel)
  const occasionInfoModel = useSelector(selectOccasionInfoModel)

  useMountEffect(() => {
    dispatch(loadOccasionInfo({ args: { occasion_patient_id: occasionPatientId } }))
    if (!patientStatusModel.loaded && !patientStatusModel.loading) {
      dispatch(loadPatientStatusOcc({ args: { card_type: '4', patient_id: patientId, occasion_patient_id: occasionPatientId } }))
    }
  })

  useEffect(() => {
    if (patientStatusModel.payload) {
      dispatch(loadPersonInfo({ args: { patient_id: patientStatusModel.payload?.data?.mother_patient_id } }))
    }
  }, [dispatch, patientStatusModel])

  useEffect(() => {
    const gender_code = patientInfoModel.payload?.data?.gender_code
    const mother_snils = motherPersonInfoModel.payload?.data?.[0]?.snils
    const nosology_code = occasionInfoModel.payload?.data?.[0]?.nosology_code
    const birth_date = patientStatusModel.payload?.data?.birth_date
    const child_number = patientStatusModel.payload?.data?.child_number
    const vmcl = occasionInfoModel.payload?.data?.[0]?.vmcl

    if (gender_code && mother_snils && nosology_code && birth_date && child_number && vmcl) {
      setParamsLoadRoutes({
        snils: mother_snils,
        nosology_code,
        birth_date,
        gender_code,
        child_number,
        vmcl,
      })
    } else {
      setParamsLoadRoutes(null)

      const errorMsg = [
        mother_snils ? null : 'СНИЛС матери',
        nosology_code ? null : 'Код нозологии',
        vmcl ? null : 'vmcl',
        birth_date ? null : 'Дата рождения новорожденного',
        gender_code ? null : 'gender_code',
        child_number ? null : 'Порядковый номер при рождении',
      ].filter(e => e).join(', ')
      setParamsLoadRoutesError(`Недостаточно параметров для получения маршрута: ${errorMsg}`)
    }
  }, [patientInfoModel, motherPersonInfoModel, occasionInfoModel, patientStatusModel])

  return <TabRouteBase
    patientId={patientId}
    occasionPatientId={occasionPatientId}
    paramsLoadRoutes={paramsLoadRoutes}
    paramsLoadRoutesError={paramsLoadRoutesError}
    {...props}
  />
}

export const TabRouteNewbornWithAudit = withMntAudit(TabRouteNewborn)

TabRouteNewbornWithAudit.tabTitle = 'Маршрут'
TabRouteNewbornWithAudit.tabPath = 'route'
