import { PATIENT_LIST_FILTERS } from './models'
import { initialPatientListState, initialPatientCardState } from './initialState'

const reducers = {
  setPatientListFilters: (state, action) => { state[PATIENT_LIST_FILTERS] = action.payload },
  resetPatientList: state => ({ ...state, ...initialPatientListState }),
  resetPatientCard: state => ({ ...state, ...initialPatientCardState }),
}

export default reducers
