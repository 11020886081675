import { TAG_COLORS } from './tagColors'

export const GENDERS = {
  MALE: 'Мужской',
  FEMALE: 'Женский',
  UNKNOWN: 'Неопределенный',
}

export const genderToTagColor = (gender) => {
  switch (gender) {
  case GENDERS.MALE: return TAG_COLORS.DUSTY_BLUE
  case GENDERS.FEMALE: return TAG_COLORS.LIGHT_LAVENDER
  case GENDERS.UNKNOWN: return TAG_COLORS.LIGHT_BEIGE
  default: return TAG_COLORS.GREY_100
  }
}
