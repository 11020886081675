import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ErrorBoundary } from 'react-error-boundary'
import { store, persistor } from '@src/store'
import ConfigLoader from '@src/config/ConfigLoader'
import { InternalError500 } from '@src/components'
import Splash from '@src/config/Splash/Splash'
import 'antd/dist/antd.less'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={InternalError500}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConfigLoader
            ready={() => <App />}
            loading={() => <Splash />}
          />
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
