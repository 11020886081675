import { selectDictDiseasesModel } from '@src/modules/dictionaries/store'
import { Combobox } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class DiseasesColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.DISEASES


  constructor (store, settings) {
    const column = {
      header: {
        title: 'Положительные рез-ты скрининга по заболеваниям',
        component: Combobox,
        props: {
          allowClear: true,
          mode: 'multiple',
          options: selectDictDiseasesModel(store.getState()).payload.map(disease => ({
            label: disease.name,
            value: disease.item_code,
            title: disease.name,
          })),
        },
        defaultValue: [],
      },
      width: 300,
      dataIndex: 'diseases_name',
      dataRender: diseases_name => diseases_name ?? '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let diseases_code = filters?.diseases_name?.length ? filters?.diseases_name : undefined

    if (nullSearches[this.type]) diseases_code = null

    return {
      args: {
        diseases_code,
      },
    }
  }
}
