import { selectDictMoModel } from '@src/modules/dictionaries/store'
import { Combobox } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class MoChildbirthColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.MO_CHILDBIRTH

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Наименование МО рождения',
        component: Combobox,
        props: {
          allowClear: true,
          mode: 'multiple',
          options: selectDictMoModel(store.getState()).payload.data.map(mo => ({
            label: mo.name_short,
            value: mo.mo_id,
            title: mo.name_full,
          })),
        },
        defaultValue: [],
      },
      width: 300,
      dataIndex: 'childbirth_mo_name',
      dataRender: childbirth_mo_name => childbirth_mo_name ? childbirth_mo_name : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    return {
      control: {
        filters: nullSearches.childbirth_mo_oid || (filters?.childbirth_mo_oid && filters?.childbirth_mo_oid.length)
          ? [{
            field: 'childbirth_mo_oid',
            value: nullSearches.childbirth_mo_oid ? null : filters.childbirth_mo_oid,
            operator: '=',
            invalid: false,
            cast: 'right-any',
          }]
          : [],
      },
    }
  }
}
