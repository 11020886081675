import { Combobox } from '@src/components'
import { selectDictDetectionPlace } from '@src/modules/dictionaries/store'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class DeathPlace extends RegisterColumn {
  static type = REGISTER_COLUMNS.DEATH_PLACE

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Место смерти',
        component: Combobox,
        props: {
          allowClear: true,
          mode: 'multiple',
          options: selectDictDetectionPlace(store.getState()).payload?.map(mo => ({
            label: mo.name,
            value: mo.item,
            title: mo.name,
          })),
        },
        defaultValue: [],
      },
      width: 300,
      dataIndex: 'death_place',
      dataRender: death_place => death_place ? death_place : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let requestArgs = []

    if (filters?.death_place && filters?.death_place.length) {
      requestArgs = [{ field: 'death_place_code', value: filters.death_place, operator: '=', invalid: false, cast: 'right-any' }]
    }

    if (nullSearches[this.type]) {
      requestArgs = [{ field: 'death_place_code', operator: '=', value: null, invalid: false }]
    }

    return {
      control: {
        filters: requestArgs,
      },
    }
  }
}
