import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from '@src/components'
import { loadPatientStatusAkineoCharacteristic, loadPatientStatusAkineoMother, selectPatientInfoModel, selectPatientStatusModel,
  selectPatientStatusAkineoMotherModel } from '@src/modules/monitoring/store'
import { getFullname } from '@src/utils'
import { useMountEffect } from '@src/hooks'
import { TabStatusBase } from '@src/modules/monitoring/components'
import { withMntAudit } from '@src/hoc'

const TabStatus = ({ occasionPatientId, ...props }) => {
  const dispatch = useDispatch()
  const patientStatusModel = useSelector(selectPatientStatusModel)
  const patientStatusAkineoMotherModel = useSelector(selectPatientStatusAkineoMotherModel)
  const patientInfoModel = useSelector(selectPatientInfoModel)

  const status = patientStatusModel.payload?.data?.[0]
  const mother = patientStatusAkineoMotherModel.payload?.data?.[0]
  const info = patientInfoModel.payload?.data

  useMountEffect(() => {
    dispatch(loadPatientStatusAkineoCharacteristic({ args: { occasion_patient_id: occasionPatientId } }))
    dispatch(loadPatientStatusAkineoMother({ args: { occasion_patient_id: occasionPatientId } }))
  })

  return <TabStatusBase {...props}>
    <Card
      title='Пациент'
      loading={patientStatusModel.loading || patientInfoModel.loading}
      error={patientStatusModel.error || patientInfoModel.error}
      style={{ flexBasis: '40%' }}
      className='mr-16'
      data={info && status && [
        {
          title: 'Имя',
          data: getFullname(info) || '-',
        },
        {
          title: 'Дата рождения',
          data: info.birth_date ? moment(info.birth_date).format('DD.MM.YYYY') : '-',
        },
        {
          title: 'Возраст',
          data: info.patient_age || '-',
        },
        ...(info.death_date
          ? [{
            title: 'Дата смерти',
            data: moment(info.death_date).format('DD.MM.YYYY'),
          }] : []),
        {
          title: 'Группа крови и резус-принадлежность',
          data: status.blood_group || status.rh_factor ? [(status.blood_group || ''), (status.rh_factor || '')].join(' ') : '-',
        },
        {
          title: 'Рост',
          data: status.height || '-',
        },
        {
          title: 'Масса тела до беременности',
          data: status.weight_before  || '-',
        },
        {
          title: 'Срок постановки на учет по беременности',
          data: status.registration_term || '-',
        },
        {
          title: 'Тип зачатия',
          data: status.conception_type_code || '-',
        },
        {
          title: 'Данная беременность по счету',
          data: status.pregnancy_number ? status.pregnancy_number : '-',
        },
        {
          title: 'Данные роды по счету',
          data: status.childbirth_number ? status.childbirth_number : '-',
        },
        {
          title: 'Дата родов',
          data: status.birth_date ? moment(status.birth_date).format('DD.MM.YYYY') : '-',
        },
        // VIMISVIMIS-14447
        // {
        //   title: 'Исход беременности',
        //   data: '',
        // },
        {
          title: 'Дата выписки из стационара',
          data: status.discharge_date ? moment(status.discharge_date).format('DD.MM.YYYY') : '-',
        },
      ]}
    />
    <Card
      title='Диагноз'
      loading={patientStatusModel.loading}
      error={patientStatusModel.error}
      style={{ flexBasis: '60%' }}
      data={mother && [
        // API? VIMISVIMIS-14587
        {
          title: 'Основной акушерский диагноз',
          data: mother?.diagnosis || '—',
        },
        {
          title: 'Срок беременности',
          data: mother?.term_birth
            ? <div
              style={mother?.term_birth > 45 ? { color: '#FF794D' } : {}}
              title={mother?.term_birth > 45
                ? 'Расчетный срок беременности превышает максимально допустимый, но нет данных о завершении беременности'
                : undefined}
            >{mother?.term_birth && Math.min(mother?.term_birth, 45)}</div>
            : '—',
        },
        {
          title: 'Количество плодов',
          data: mother?.fetuses_count ? mother?.fetuses_count : '—',
        },
      ]}
    />
  </TabStatusBase>
}

export const TabStatusWithAudit = withMntAudit(TabStatus)

TabStatusWithAudit.tabTitle = 'Статус'
TabStatusWithAudit.tabPath = 'status'
