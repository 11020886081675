import { Form } from 'antd'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Button, Drawer, IfNotReadonly, LoadedOrError } from '@src/components'
import { useLoadModels } from '@src/hooks'
import { AttributesForm } from '@src/modules/methodology/pages/MethRegConditionsPage/AttributesDrawer/AttributesForm'
import {
  getAttributeTree, getOperationValueIdList, selectAttributesTreeModel, selectOperationValueIdListModel,
} from '@src/modules/methodology/store'
import { AccessContext } from '@src/context'
import './AttributesDrawer.scss'
import { withMntAudit } from '@src/hoc'

const AttributesDrawer = ({ condition, readonly, onAccept, onClose, ...props }) => {
  const [form] = Form.useForm()

  const [loaded, loading, error] = useLoadModels(
    [useSelector(selectAttributesTreeModel), getAttributeTree({ base_limitation: true })],
    [useSelector(selectOperationValueIdListModel), getOperationValueIdList()],
  )

  const formInitialValues = useMemo(() => {
    if (!condition) {
      return {
        condition_comment: '',
        condition_name: '',
        condition_disjunct: [],
      }
    }
    return condition
  }, [condition])

  const handleFormSave = useCallback(async (formValues) => {
    onAccept(formValues)
    onClose()
  }, [onAccept, onClose])

  return (
    <AccessContext.Provider value={{ readonly }}>
      <Drawer
        className='root-attributes-drawer'
        title='Дополнительные параметры'
        placement='bottom'
        height='96%'
        maskClosable={false}
        onClose={onClose}
        footer={
          <IfNotReadonly>
            <AttributesDrawerFooter onSave={form.submit}/>
          </IfNotReadonly>
        }
        {...props}
      >
        <LoadedOrError loaded={loaded} loading={loading || !formInitialValues} error={error}>
          {
            <AttributesForm form={form} onFinish={handleFormSave} initialValues={formInitialValues}/>
          }
        </LoadedOrError>
      </Drawer>
    </AccessContext.Provider>
  )
}

const AttributesDrawerFooter = ({ onSave }) => {
  return <div className='h-44'>
    <div className='h-100p d-flex flex-direction-row align-items-center'>
      <Button
        className='ml-auto'
        isSuccess
        icon='check_cr_fr_white'
        label='Применить'
        onClick={onSave}
      />
    </div>
  </div>
}

export const AttributesDrawerWithAudit = withMntAudit(AttributesDrawer)
