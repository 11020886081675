import { isArray } from 'lodash'
import { Mkb10InputWithState } from '@src/modules/monitoring/components/Mkb10Input/Mkb10InputWithState'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class ConcomitantDiagList extends RegisterColumn {
  static type = REGISTER_COLUMNS.CONCOMITANT_DIAG_LIST

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Сопутствующие заболевания',
        component: Mkb10InputWithState,
        defaultValue: [],
      },
      width: 200,
      dataIndex: 'concomitant_mkb_code',
      dataRender: concomitant_mkb_code => isArray(concomitant_mkb_code) ? concomitant_mkb_code.join(', ') : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let concomitant_mkb_code = filters?.[this.type]?.length ? filters?.[this.type] : undefined

    if (nullSearches[this.type]) concomitant_mkb_code = null
    return {
      args: {
        concomitant_mkb_code,
      },
    }
  }
}
