import { isArray, isBoolean } from 'lodash'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, ColumnsSettings, DataGrid, Tag } from '@src/components'
import { TAG_COLORS, URLS } from '@src/constants'
import { useColumns, useDebounce } from '@src/hooks'
import {
  loadMethRoutesList,
  loadMethRoutesListCount, selectMethRoutesListCountModel, selectMethRoutesListModel,
} from '@src/modules/methodology/store'
import { cn, getRange } from '@src/utils'
import { MethTabs } from '../MethTabs/MethTabs'
import { useMethRoutesColumns } from './columns'
import './MethRoutesTable.scss'
const CHUNK_SIZE  = 100

const MethRoutesTable = () => {
  const [gridValue, setGridValue] = useState(null)

  const columns = useMethRoutesColumns()

  const [displayedColumns, columnOptions, toggleColumn] = useColumns(columns)
  const history = useHistory()
  const dispatch = useDispatch()
  const methRoutesListModel = useSelector(selectMethRoutesListModel)
  const methRoutesListCountModel = useSelector(selectMethRoutesListCountModel)

  const requestArgs = useCallback(({ filters, sort, nullSearches, rowCount = 0 }) => {
    return {
      args: {
        ...(isArray(filters.graph_status_id) && filters.graph_status_id.length ? { graph_status_id: filters.graph_status_id } : {}),
        ...(isArray(filters.graph_ext_status_id) && filters.graph_ext_status_id.length ? { graph_ext_status_id: filters.graph_ext_status_id } : {}),
      },
      control: {
        datamode: 'scroll',
        sorts: [{
          field: sort.column,
          sort: sort.order,
        }],
        range: getRange(rowCount, CHUNK_SIZE),
        filters: [
          ...(filters.graph_caption ? [{ field: 'graph_caption', value: filters.graph_caption, operator: 'like_both' }] : []),
          ...(filters.graph_vmcl_id ? [{ field: 'graph_vmcl_id', value: filters.graph_vmcl_id, operator: '=' }] : []),
          ...(filters.started_on?.[0] ? [{ field: 'started_on', value: filters.started_on[0].format('YYYY-MM-DD'), operator: '>=' }] : []),
          ...(filters.started_on?.[1] ? [{ field: 'started_on', value: filters.started_on[1].format('YYYY-MM-DD'), operator: '<=' }] : []),
          ...(filters.expirated_on?.[0] ? [{ field: 'expirated_on', value: filters.expirated_on[0].format('YYYY-MM-DD'), operator: '>=' }] : []),
          ...(filters.expirated_on?.[1] ? [{ field: 'expirated_on', value: filters.expirated_on[1].format('YYYY-MM-DD'), operator: '<=' }] : []),
          ...(isBoolean(filters.graph_ext_existence) ? [{ field: 'graph_ext_existence', value: filters.graph_ext_existence, operator: '=' }] : []),
          ...(filters.document_name ? [{ field: 'document_name', value: filters.document_name, operator: 'like_both' }] : []),
          ...(filters.graph_ext_version ? [{ field: 'graph_ext_version', value: filters.graph_ext_version, operator: 'like_both' }] : []),

          ...(nullSearches.expirated_on ? [{ field: 'expirated_on', value: null, operator: '=' }] : []),
          ...(nullSearches.graph_ext_existence ? [{ field: 'graph_ext_existence', value: null, operator: '=' }] : []),
          ...(nullSearches.graph_ext_status_id ? [{ field: 'graph_ext_status_id', value: null, operator: '=' }] : []),
          ...(nullSearches.document_name ? [{ field: 'document_name', value: null, operator: '=' }] : []),
          ...(nullSearches.graph_ext_version ? [{ field: 'graph_ext_version', value: '-', operator: '=' }] : []),
        ],
      },
    }
  }, [])
  const handleRowDoubleClick = (row) => {
    if(row.graph_id) {
      history.push(`${URLS.METHODOLOGY__ROUTES}/${row.graph_id}/${row.graph_ext_id}/general`)
    }
  }
  const handleRowAuxClick = (row) => {
    if(row.graph_id) {
      window.open(`${URLS.METHODOLOGY__ROUTES}/${row.graph_id}/${row.graph_ext_id}/general`, '_blank')
    }
  }
  const handleSearch = useCallback(({ filters, sort, nullSearches, isInvalid }) => {
    if (!isInvalid) {
      const args = requestArgs({ filters, sort, nullSearches })

      dispatch(loadMethRoutesList(args))
      dispatch(loadMethRoutesListCount({
        ...args,
        control: {
          ...args.control,
          count: true,
        },
      })) }
  }, [dispatch, requestArgs])

  const handleLoadMore = useCallback(({ filters, sort, nullSearches }, rowCount) => {
    if (!methRoutesListModel.loading && rowCount < methRoutesListCountModel?.payload?._count_) {
      dispatch(loadMethRoutesList(requestArgs({ filters, sort, nullSearches, rowCount })))
    }
  }, [dispatch, methRoutesListCountModel?.payload?._count_, methRoutesListModel.loading, requestArgs])

  const debouncedHandleSearch = useDebounce(handleSearch, 500)

  const handleResetFilters = useCallback(() => {
    setGridValue(null)
  }, [])

  return <div className='root-meth-routes-table'>
    <MethTabs label='Маршруты' link={URLS.METHODOLOGY__ROUTES}/>

    <div className='list-container'>
      <div className='list-controls'>
        <Button
          className='reset-filter-button'
          label='Сбросить фильтры'
          onClick={handleResetFilters}
          disabled={!gridValue || !gridValue.isDirty}
          icon='close_cr_fr'
        />
        <div className={cn('list-counter ml-auto h-32', methRoutesListCountModel.loading && 'loading')}>
          <span>Всего записей:</span>
          <Tag className='ml-8' color={TAG_COLORS.GREEN_TEA}>{methRoutesListCountModel.payload?._count_ || 0}</Tag>
        </div>
        <ColumnsSettings
          className='ml-16'
          columnOptions={columnOptions}
          onToggleColumn={toggleColumn}
        />
      </div>
      <DataGrid
        columns={displayedColumns}
        data={methRoutesListModel.payload?.data ?? []}
        rowKey='graph_id'
        loading={methRoutesListModel.loading}
        error={methRoutesListModel.error}
        value={gridValue}
        onReset={handleSearch}
        onChange={setGridValue}
        onFilterChange={debouncedHandleSearch}
        onSortChange={handleSearch}
        onNullSearchChange={handleSearch}
        onScrolledToBottom={handleLoadMore}
        onRowDoubleClick={handleRowDoubleClick}
        onRowAuxClick={handleRowAuxClick}
      />
    </div>
  </div>
}


export default MethRoutesTable
