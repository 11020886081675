import { useEffect, useMemo } from 'react'
import { AUDIT_PS, NOT_SET, SORT_ORDER } from '@src/constants'
import { useAudit, useDebounce } from '@src/hooks'
import { toLowerCaseWithoutSpaces } from '@src/utils'
import { StateModel } from '@src/utils/types'

export const useFilteredList = (nsiDictListModel: StateModel<ResponsePsiList>, value: DataGridValue | null) => {
  const tableData = useMemo(() => {
    if(!nsiDictListModel.payload?.data || !value) return []

    return nsiDictListModel.payload.data.map(dict => ({
      ...dict,
      identifier: dict?.identifier,
      type: dict?.source?.markers?.ref_type?.name,
      full_name: dict?.source?.full_name,
      state: dict?.local?.state?.name,
      is_local: !!dict?.local,
    }))
  }, [nsiDictListModel, value])

  const filteredData = useMemo(() => {
    if(!value) return []

    return tableData
      .filter(dict => toLowerCaseWithoutSpaces(dict.identifier).includes(toLowerCaseWithoutSpaces(value.filters?.identifier)))
      .filter(dict => matchOrNotSet(value.filters.type, dict.type))
      .filter(dict => toLowerCaseWithoutSpaces(dict.full_name).includes(toLowerCaseWithoutSpaces(value.filters?.full_name)))
      .filter(dict => matchOrNotSet(value.filters.state, dict.state))
      .sort(sortFn(value.sort.column, value.sort.order))
  }, [tableData, value])

  const selectedDicts = useMemo(() => {
    if (!value) return []

    const selected = Object.entries(value.selectedCheckboxes).filter(([, isSelected]) => isSelected).map(([key]) => key)

    return tableData.filter(dict => dict.identifier && selected.includes(dict.identifier))
  }, [value, tableData])

  const logToAudit = useAudit()
  const debouncedLogToAudit = useDebounce(logToAudit, 500, {}, [logToAudit])

  useEffect(() => {
    if(value) {
      debouncedLogToAudit({
        auditDescription: { args: value.filters },
        auditPs: AUDIT_PS.PSI,
        auditMessage: 'Поиск в справочнике ФРНСИ',
      })
    }
  }, [debouncedLogToAudit, logToAudit, value])

  return [filteredData, selectedDicts]
}

export type SelectedDicts = ReturnType<typeof useFilteredList>[1]

const matchOrNotSet = (filter: string[] | undefined, value: string | undefined) => {
  // Если фильтр не задан, возвращаем всё
  if (!filter?.length) return true

  return (value && filter.includes(value)) || // значение установлено, и оно совпадает
  (!value && filter.includes(NOT_SET))      // значение не установлено, и в фильтре выбрано NOT_SET
}

const sortFn = (column: string, order: string) => {
  switch (column) {
  default: return (a: any, b: any) => {
    return order === SORT_ORDER.ASC
      ? ('' + a[column]).localeCompare(b[column], 'ru-RU')
      : ('' + b[column]).localeCompare(a[column], 'ru-RU')
  }
  }
}
