import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from '@src/components'
import { loadPatientStatusProfImm, selectPatientStatusModel } from '@src/modules/monitoring/store'
import { useMountEffect } from '@src/hooks'
import { withMntAudit } from '@src/hoc'
import { TabStatusBase } from '@src/modules/monitoring/components'

const TabStatus = ({ patientId, occasionPatientId, props }) => {
  const dispatch = useDispatch()
  const patientStatusModel = useSelector(selectPatientStatusModel)

  const status = patientStatusModel.payload?.data

  useMountEffect(() => {
    dispatch(loadPatientStatusProfImm({ args: { patient_id: patientId, occasion_patient_id: occasionPatientId } }))
  })

  return <TabStatusBase {...props}>
    <Card
      loading={patientStatusModel.loading}
      error={patientStatusModel.error}
      style={{ flexBasis: '50%' }}
      title='Иммунизация'
      data={status && [
        {
          title: 'Этап схемы иммунизации',
          data: status.stage_immunization || '—',
        },
        {
          title: 'Дата иммунизации',
          data: status.occ_begin_on_local ? moment(status.occ_begin_on_local).format('DD.MM.YYYY') : '—',
        },
        {
          title: 'Препарат',
          data: status.drug || '—',
        },
      ]}
    />
  </TabStatusBase>
}

export const TabStatusWithAudit = withMntAudit(TabStatus)

TabStatusWithAudit.tabTitle = 'Статус'
TabStatusWithAudit.tabPath = 'status'
