import { isBoolean } from 'lodash'
import { Combobox } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'


export class StandartNeonatalScreeningResultColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.STANDART_NEONATAL_SCREENING_RESULT


  constructor (store, settings) {
    const column = {
      header: {
        title: 'Наличие патологии (НС)',
        component: Combobox,
        props: {
          allowClear: true,
          placeholder: 'Все',
          options: [
            { label: 'Есть', value: true, title: 'Есть' },
            { label: 'Нет', value: false, title: 'Нет' },
          ],
        },
      },
      width: 300,
      dataIndex: 'standart_neonatal_screening_result',
      dataRender: standart_neonatal_screening_result => typeof standart_neonatal_screening_result === 'boolean' ? (standart_neonatal_screening_result ? 'Есть' : 'Нет') : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let requestFilters = []

    if (isBoolean(filters?.standart_neonatal_screening_result)) {
      requestFilters = [{ field: 'standart_neonatal_screening_result', value: filters.standart_neonatal_screening_result, operator: '=', invalid: false }]
    }

    if (nullSearches[this.type]) {
      requestFilters = [{ field: this.type, operator: '=', value: null, invalid: false }]
    }

    return {
      control: {
        filters: requestFilters,
      },
    }
  }
}
