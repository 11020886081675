import { Combobox, Tag, TextInput } from '@src/components'
import { SORT_ORDER, TAG_COLORS } from '@src/constants'
import { NOT_SET } from '@src/constants'

const STATE_ACTIVE = 'Используется'
const STATE_DRAFT = 'Проект (черновик)'
const STATE_DELETE = 'Удален'

const STATE_LIST = [NOT_SET, STATE_ACTIVE, STATE_DRAFT, STATE_DELETE]

const TYPE_DICT = 'Справочник'
const TYPE_CODE = 'Кодификатор'
const TYPE_CLASS = 'Классификатор'

const TYPE_LIST = [NOT_SET, TYPE_DICT, TYPE_CODE, TYPE_CLASS]

const stateCodeToTagColor = (state: string) => {
  switch (state) {
  case STATE_ACTIVE: return TAG_COLORS.GREEN_TEA
  case STATE_DRAFT: return TAG_COLORS.LAVENDER
  case STATE_DELETE: return TAG_COLORS.KRAYOLA_THISTLE
  default: return TAG_COLORS.GREY_100
  }
}

const StateTag = ({ state }: { state: string | undefined }) => {
  return state
    ? <Tag color={stateCodeToTagColor(state)}>{state}</Tag>
    : <></>
}

export const columns = {
  identifier: {
    header: {
      title: 'Идентификатор',
      component: TextInput,
      props: {
        icon: 'search',
        allowClear: true,
      },
      defaultValue: '',
    },
    sort: true,
    dataIndex: 'identifier',
    width: 300,
  },
  type: {
    header: {
      title: 'Тип НСИ',
      component: Combobox,
      props: {
        mode: 'multiple',
        allowClear: true,
        options: TYPE_LIST.map(type => ({ value: type })),
      },
      defaultValue: [],
    },
    sort: true,
    dataIndex: 'type',
    width: 200,
  },
  full_name: {
    header: {
      title: 'Наименование',
      component: TextInput,
      props: {
        icon: 'search',
        allowClear: true,
      },
      defaultValue: '',
    },
    sort: true,
    defaultSort: SORT_ORDER.ASC,
    dataIndex: 'full_name',
    width: 800,
  },
  state: {
    header: {
      title: 'Статус',
      component: Combobox,
      props: {
        mode: 'multiple',
        allowClear: true,
        options: STATE_LIST.map(state => ({ value: state })),
      },
      defaultValue: [],
    },
    sort: true,
    dataIndex: 'state',
    dataRender: (state: string | undefined) => <StateTag state={state}></StateTag>,
    width: 200,
  },
  is_local: {
    header: 'Импортирован',
    sort: true,
    dataIndex: 'is_local',
    dataRender: (local: boolean) => local ? 'Да' : 'Нет',
    width: 100,
  },
}
