import React from 'react'
import { REGISTER_COLUMNS, URLS } from '@src/constants'
import { Register, TabMedRecordsWithAudit, TabPersonalWithAudit } from '@src/modules/monitoring/components'
import { TabStatusWithAudit } from './TabStatus'

export class RegisterOnkoPredzno extends React.Component {
  get basepath () {
    return URLS.MONITORING__ONKO__PREDZNO
  }

  get navTitle () {
    return 'Реестр пациентов с предопухолевыми заболеваниями'
  }

  get columns () {
    return [
      { type: REGISTER_COLUMNS.FULLNAME, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.SNILS, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.BIRTH_DATE, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.GENDER_CODE, settings: { column: { enabled: true } } },
      { type: REGISTER_COLUMNS.DIAGNOSIS, settings: { column: { enabled: true } } },
    ]
  }

  get tabs () {
    return [
      TabStatusWithAudit,
      TabMedRecordsWithAudit,
      TabPersonalWithAudit,
    ]
  }

  render () {
    return <Register
      list_type='2'
      columns={this.columns}
      basepath={this.basepath}
      navTitle={this.navTitle}
      tabs={this.tabs}
    />
  }
}
