import moment from 'moment'
import { DateRangePicker } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'
import { requestArgsDateRange } from './columnsReqArgs'

export class BiopsySamplingTimeColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.BIOPSY_SAMPLING_TIME

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Дата и время взятия крови',
        component: DateRangePicker,
        defaultValue: [null, null],
        props: {
          allowEmpty: [true, true],
          showTime: true,
          allowClear: true,
          disabledDate: currentDate => currentDate && currentDate.isAfter(moment()),
        },
      },
      width: 250,
      dataIndex: 'biopsy_sampling_time',
      dataRender: date => date ? moment.utc(date).format('DD.MM.YYYY HH:mm:ss') : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs = reqArgs => requestArgsDateRange(this.type, reqArgs)
}
