import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, DataGrid, Icon, Loader, Modal } from '@src/components'
import { withMntAudit } from '@src/hoc'
import { useMountEffect } from '@src/hooks'
import { Alert } from '@src/modals'
import { importNsiFrnsiDict, loadNsiDictList, resetImportNsiFrnsiDict, selectFrnsiImportModel } from '@src/modules/nsi/store'
import { modalService } from '@src/services'

import './ImportModal.scss'

const IMPORT_STATE_LOADING = 'loading'
const IMPORT_STATE_LOADED = 'loaded'
const IMPORT_STATE_ERROR = 'error'

const ImportModal = ({ dicts, onClose, ...props }) => {
  const frnsiImportModel = useSelector(selectFrnsiImportModel)
  const dispatch = useDispatch()

  const [importQueue, setImportQueue] = useState(dicts.map(dict => dict.identifier))
  const [importStates, setImportStates] = useState({})
  const [currentImport, setCurrentImport] = useState(null)
  const isImportFinished = useMemo(() => !importQueue.length && !currentImport, [importQueue, currentImport])
  const importedDictNumber = useMemo(() => {
    return dicts.length - importQueue.length - (currentImport ? 1 : 0)
  }, [currentImport, dicts.length, importQueue.length])

  const handleClose = useCallback(() => {
    if (!isImportFinished) {
      modalService.open(Alert, {
        warn: true,
        onOk: onClose,
        title: 'Импорт будет остановлен',
        children: 'Вы действительно хотите остановить импорт?',
        okText: 'Остановить',
      })
    } else {
      onClose()
    }
  }, [isImportFinished, onClose])

  useEffect(() => {
    if (currentImport) {
      const importState = frnsiImportModel.error
        ? IMPORT_STATE_ERROR
        : frnsiImportModel.loading
          ? IMPORT_STATE_LOADING
          : IMPORT_STATE_LOADED

      setImportStates(prev => ({
        ...prev,
        [currentImport]: importState,
      }))

      if (importState !== IMPORT_STATE_LOADING) {
        setCurrentImport(null)
      }
    } else {
      const [identifier, ...rest] = importQueue
      if (identifier) {
        setCurrentImport(identifier)
        setImportQueue(rest)
        dispatch(importNsiFrnsiDict({ pathParams: { refbook_identifier: identifier } }))
      }
    }
  }, [currentImport, dispatch, frnsiImportModel, importQueue])

  useMountEffect(() => {
    return () => {
      dispatch(resetImportNsiFrnsiDict())
      dispatch(loadNsiDictList({}))
    }
  })

  const tableData = useMemo(() => {
    return dicts.map(dict => ({
      ...dict,
      importState: importStates[dict.identifier],
    }))
  }, [dicts, importStates])

  return <Modal
    className='root-import-modal'
    title={`Импорт справочников (${importedDictNumber} / ${dicts.length})`}
    {...props}
    width={850}
    onClose={handleClose}
    footer={<ModalFooter
      onStop={handleClose}
      onReady={onClose}
      isImportFinished={isImportFinished}
    />}
  >
    <div className='import-modal'>
      <DataGrid
        columns={columns}
        data={tableData}
        rowKey='identifier'
      />
    </div>
  </Modal>
}

export const ImportModalWithAudit = withMntAudit(ImportModal)

const columns = {
  identifier: {
    header: 'Идентификатор',
    dataIndex: 'identifier',
    cellClassName: row => !row.importState || row.importState === IMPORT_STATE_LOADING ? 'cell-grey' : '',
    width: 250,
  },
  full_name: {
    header: 'Наименование',
    dataIndex: 'full_name',
    cellClassName: row => !row.importState || row.importState === IMPORT_STATE_LOADING ? 'cell-grey' : '',
    width: 500,
  },
  importState: {
    header: '',
    dataIndex: 'importState',
    titleRender: () => '',
    dataRender: importState => <ImportState importState={importState} />,
    width: 50,
  },
}

const ImportState = ({ importState }) => {
  return importState
    ? <div className='dict-import-cell w-100p h-100p'>
      {
        importState === IMPORT_STATE_LOADING
          ? <Loader small />
          : importState === IMPORT_STATE_LOADED
            ? <Icon icon='check_cr_fr' />
            : importState === IMPORT_STATE_ERROR
              ? <Icon icon='exclamation_mark_cr_fr' />
              : null
      }
    </div>
    : null
}

const ModalFooter = ({ onStop, onReady, isImportFinished }) => {
  return <div className='modal-footer'>
    {
      isImportFinished
        ? <Button
          className='ml-auto'
          label='Готово'
          onClick={onReady}
          isSuccess
        />
        : <Button
          className='ml-auto'
          label='Остановить'
          onClick={onStop}
          isSecondary
        />
    }
  </div>
}
