import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getPsVersions, selectPsVersionsModel } from '@src/store'
import { Loader } from '@src/components'

import './AboutPage.scss'
import { withAcl } from '@src/hoc'

class AboutPage extends Component {
  static propTypes = {
    getPsVersions: PropTypes.func,
    psVersionsModel: PropTypes.object,
  }

  componentDidMount () {
    this.props.getPsVersions()
  }

  renderPackages = (packages) => {
    return <div>
      <div className='header mt-16 mb-4'>Модули</div>
      {
        [packages.root, ...packages.info].map((p, index) => (
          <div className='row' key={`ext-${index}`}>
            <div className='mr-16' title={p?.decription ?? ''}>{p?.name}</div>
            <div>{p?.version}</div>
          </div>
        ))
      }
    </div>
  }

  renderSubsystems = () => {
    const payload = this.props.psVersionsModel?.payload || {}

    return <div>
      <div className='header mb-4'>Подсистемы</div>

      {
        Object.keys(payload)
          .filter(key => key !== 'packages')
          .map(key => (
            <div className='row' key={`sub-${key}`}>
              <div className='mr-16'>{key}</div>
              {
                payload?.[key]?.error
                  ? <div className='error'>{payload?.[key]?.error}</div>
                  : <>
                    <div className='mr-16' style={{ width: 400 }}>{payload?.[key]?.data?.description}</div>
                    <div>{payload?.[key]?.data?.version}</div>
                  </>
              }
            </div>
          ))
      }
    </div>
  }

  render () {
    const { psVersionsModel } = this.props

    return (
      <div className='about-page-root'>
        {
          psVersionsModel.error
            ? <div className='error'>{psVersionsModel.error}</div>
            : psVersionsModel.loading
              ? <Loader />
              : psVersionsModel.loaded
                ? (
                  <div>
                    {
                      // eslint-disable-next-line no-console
                      console.log(psVersionsModel.payload)
                    }
                    {
                      this.renderSubsystems()
                    }
                    {
                      this.renderPackages(psVersionsModel.payload.packages.data)
                    }
                  </div>
                )
                : null
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    psVersionsModel: selectPsVersionsModel(state),
  }
}

const mapDispatchToProps = dispatch => ({
  getPsVersions: () => dispatch(getPsVersions()),
})

export const AboutPageWithAcl = withAcl(connect(mapStateToProps, mapDispatchToProps)(AboutPage))