import { Component } from 'react'
import PropTypes from 'prop-types'

import { Drawer } from '@src/components'

import './LongDataModal.scss'

export class LongDataModal extends Component {
  static propTypes = {
    data: PropTypes.string,
    onClose: PropTypes.func,
  }

  textValue = () => {
    const v = this.props.data

    try {
      return JSON.stringify(JSON.parse(v), null, 2)
    } catch (e) {
      return v
    }
  }

  render () {
    return (
      <Drawer className='root-long-data-modal' width={1000} {...this.props}>
        <div className='long-data-modal-content'>
          <textarea className='long-data-modal-content-textarea' readOnly value={this.textValue()}/>
        </div>
      </Drawer>
    )
  }
}
