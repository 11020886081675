// import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useMountEffect } from '@src/hooks/useMountEffect'
import { useModels } from '@src/hooks/useModels'

/**
 * @description Хук принимает массивы вида [model, action], и возвращает аггрегированное состояние [loaded, loading, error]
 * action может отсутствовать для случаев, если загрузка модели происходит где-то еще
 * @param {...[model, action]} models
 * @returns {[loaded: boolean, loading: boolean, error: (string | null)]}
 */
export const useLoadModels = (...models) => {
  const dispatch = useDispatch()

  useMountEffect(() => {
    models.forEach(([model, action]) => {
      if (!model.loaded && action) dispatch(action)
    })
  })

  const [loaded, loading, error] = useModels(models.map(([model]) => model))

  return [loaded, loading, error]
}
