import { createSlice } from '@reduxjs/toolkit'
import { initialModel } from '@src/utils'

import * as models from './lib/models'
import reducers from './lib/reducers'
import extraReducers from './lib/thunks'

const initialState = {
  [models.DICT_MCL_LIST]: initialModel,
  [models.DICT_RESPONSE_LEVEL_LIST]: initialModel,
  [models.DICT_GENDERS]: initialModel,
  [models.DICT_PREGNANCY_OUTCOMES]: initialModel,
  [models.DICT_IMMUNIZATION]: initialModel,
  [models.DICT_MO]: initialModel,
  [models.DICT_REGION]: initialModel,
  [models.DICT_DISEASES]: initialModel,
  [models.DIC_DN_REASON]: initialModel,
  [models.DIC_RESULT_DETECTION]: initialModel,
  [models.DIC_DETECTION_PLACE]: initialModel,
  [models.DIC_DETECTION_PLACE_INFECTION]: initialModel,
  [models.DIC_VALIDITY_DEGREE]: initialModel,
  [models.DIC_NOTICE_TYPE]: initialModel,
  [models.DIC_STATUS_INFECTION]: initialModel,
  [models.DICT_TYPES_OF_NOTIFICATION]: initialModel,
}

export const journalSlice = createSlice({
  name: 'dictionaries',
  initialState,
  reducers,
  extraReducers,
})

export default journalSlice.reducer

export * from './lib/thunks'
export * from './lib/selectors'
