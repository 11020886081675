export const DESKTOP_MAINMENU_PATIENTMON_SHOW = 'desktop.mainmenu.patientmon.show'
export const DESKTOP_MAINMENU_PATIENTMON_PATIENTS_LIST_ONKO_SHOW = 'desktop.mainmenu.patientmon.patients_list_onko.show'
export const DESKTOP_MAINMENU_PATIENTMON_OKS_LIST_SHOW = 'desktop.mainmenu.patientmon.oks_list.show'
export const DESKTOP_MAINMENU_PATIENTMON_ONMK_LIST_SHOW = 'desktop.mainmenu.patientmon.onmk_list.show'
export const DESKTOP_MAINMENU_PATIENTMON_NEWBORN_LIST_SHOW = 'desktop.mainmenu.patientmon.newborn_list.show'
export const DESKTOP_MAINMENU_PATIENTMON_PREGNANTS_LIST_SHOW = 'desktop.mainmenu.patientmon.pregnants_list.show'
export const PATIENTMON_MENU_INF_REGISTRY_IB_SHOW = 'patientmon.menu.inf.registry_ib.show'
export const PATIENTMON_MENU_AKINEO_NNS_LIST_SHOW = 'patientmon.menu.akineo.nns_list.show'
export const PATIENTMON_MENU_PROF_DN_LIST_SHOW = 'patientmon.menu.prof.dn_list.show'
export const PATIENTMON_MENU_PROF_IMM_LIST_SHOW = 'patientmon.menu.prof.imm_list.show'
export const PATIENTMON_MENU_PROF_PMO_D_SHOW = 'patientmon.menu.prof.pmo_d.show'
export const PATIENTMON_MENU_PROF_PMO_TEENAGER_SHOW = 'patientmon.menu.prof.pmo_teenager.show'
export const PATIENTMON_MENU_INFECTION__EMERGENCY_NOTICE_SHOW = 'patientmon.menu.inf.emergency.view'

export const DESKTOP_MAINMENU_PSI_SHOW = 'desktop.mainmenu.psi.show'
export const DESKTOP_MAINMENU_PSI_DICT_SEARCH_SHOW = 'desktop.mainmenu.psi.dict_search.show'

export const DESKTOP_MAINMENU_ANALYZE_SHOW = 'desktop.mainmenu.analyze.show'
export const DESKTOP_MAINMENU_ANALYZE_MONITORING_SHOW = 'desktop.mainmenu.analyze.monitoring.show'

export const DESKTOP_MAINMENU_JOURNAL_SEARCH_JOURNAL_PG_SHOW = 'desktop.mainmenu.journal.search_journal_pg.show'
export const DESKTOP_MAINMENU_JOURNAL_ACTIONS_JOURNAL_SHOW = 'desktop.mainmenu.journal.actions_journal.show'

export const DESKTOP_MAINMENU_ADM_SHOW = 'desktop.mainmenu.adm.show'
export const DESKTOP_MAINMENU_ADM_USERS_SHOW = 'desktop.mainmenu.adm.users.show'
export const DESKTOP_MAINMENU_ADM_ROLES_SHOW = 'desktop.mainmenu.adm.roles.show'
export const DESKTOP_MAINMENU_ADM_EXTERNAL_SYSTEMS_SHOW = 'desktop.mainmenu.adm.external_systems.show'
export const DESKTOP_MAINMENU_ADM_RESOURCES_SHOW = 'desktop.mainmenu.adm.resources.show'
export const DESKTOP_MAINMENU_ADM_APPOINTMENTS_SHOW = 'desktop.mainmenu.adm.appointments.show'

export const METHOD_ACCESS = 'method.access'
export const METHOD_DOCUMENTS_NPA_READ = 'method.documents.npa.read'
export const METHOD_DOCUMENTS_CLINREC_READ = 'method.documents.clinrec.read'
export const METHOD_GRAPH_EDIT = 'method.graph.edit'
export const METHOD_GRAPH_READ = 'method.graph.read'

export const DESKTOP_MAINMENU_ANALYZE_MONITORING_ONKO_NEW_SHOW = 'desktop.mainmenu.analyze.monitoring.onko_new.show'
export const DESKTOP_MAINMENU_ANALYZE_MONITORING_AKINEO_NEW_SHOW = 'desktop.mainmenu.analyze.monitoring.akineo_new.show'
export const DESKTOP_MAINMENU_ANALYZE_MONITORING_SSZ_NEW_SHOW = 'desktop.mainmenu.analyze.monitoring.ssz_new.show'
export const DESKTOP_MAINMENU_ANALYZE_MONITORING_PROF_NEW_SHOW = 'desktop.mainmenu.analyze.monitoring.prof_new.show'

export const DESKTOP_MAINMENU_RISAR_SHOW = 'desktop.mainmenu.risar.show'

export const ANALYTICS_REPORTS_READ_REPORT_ONKO = 'analytics.reports.read.report_ONKO'
export const ANALYTICS_REPORTS_READ_REPORT_AKINEO = 'analytics.reports.read.report_AKINEO'
export const ANALYTICS_REPORTS_READ_REPORT_SSZ = 'analytics.reports.read.report_SSZ'
export const ANALYTICS_REPORTS_READ_REPORT_PROF = 'analytics.reports.read.report_PROF'
export const ANALYTICS_REPORTS_READ_REPORT_PROF_IB = 'analytics.reports.read.report_PROF_IB'

export const ANALYTICS_REPORTS_READ_REPORT_ONKO_IS = 'analytics.reports.read.report_ONKO_IS'
export const ANALYTICS_REPORTS_READ_REPORT_AKINEO_IS = 'analytics.reports.read.report_AKINEO_IS'
export const ANALYTICS_REPORTS_READ_REPORT_SSZ_IS = 'analytics.reports.read.report_SSZ_IS'
export const ANALYTICS_REPORTS_READ_REPORT_PROF_IS = 'analytics.reports.read.report_PROF_IS'
export const ANALYTICS_REPORTS_READ_REPORT_PROF_IB_IS = 'analytics.reports.read.report_PROF_IB_IS'
