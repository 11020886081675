import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ActionMenu, Combobox, ErrorText, Icon, Loader } from '@src/components'
import { useLoadModels } from '@src/hooks'
import { changeUserCurrentRole, getUiSettings, postUiSettings, selectChangeUserCurrentRoleModel, selectCurrentRole,
  selectGetUiSettingsModel, selectUser, selectUserRoles } from '@src/store'
import './UserRoleSelect.scss'

export const UserRoleSelect = ({ onClose }) =>  {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const currentRole = useSelector(selectCurrentRole)
  const changeUserCurrentRoleModel = useSelector(selectChangeUserCurrentRoleModel)
  const userRoles = useSelector(selectUserRoles)

  const [selectedRole, setSelectedRole] = useState(currentRole?.user_profile_id || null)

  const [, loading, error] = useLoadModels(
    [useSelector(selectGetUiSettingsModel), getUiSettings()]
  )

  const resetUserPreferences = useCallback(() => {
    dispatch(postUiSettings())
  }, [dispatch])

  const roleOptions = useMemo(() => {
    return userRoles.map(r => ({
      label: `${r.role_name} ${r.appointment ? `(${r.appointment})` : ''}`,
      value: r.user_profile_id,
    }))
  }, [userRoles])

  const handleRoleSelect = useCallback((role) => {
    if (role !== currentRole?.user_profile_id) {
      setSelectedRole(role)
      dispatch(changeUserCurrentRole({
        userProfileId: role,
        userId: user.id,
      }))
    }
  }, [currentRole?.user_profile_id, dispatch, user.id])

  return (
    <div className='root-user-role-select'>
      <div className='role-select-header'>
        <span className='role-select-header-title'>{user.first_name} {user.last_name}</span>
        <ActionMenu loading={loading} error={error} options={[
          { label: 'Сбросить пользовательские настройки', onClick: resetUserPreferences },
        ]}>
          <Icon className='ml-auto minw-24 minh-24' icon='settings' title='Пользовательские настройки'/>
        </ActionMenu>
        <Icon className='role-select-header-close ml-4 minw-24 minh-24' onClick={onClose} icon='close' />
      </div>
      {
        currentRole
          ? <>
            <div className='role-select-row'>
              <div className='role-select-row-title'>Направление ОМП</div>
              <div className='role-select-row-data'>{currentRole.mcl_short_name}</div>
            </div>
            <div className='role-select-row'>
              <div className='role-select-row-title'>Организация</div>
              <div className='role-select-row-data'>{currentRole.ouz_short || currentRole.mo_short}</div>
            </div>
            <div className='role-select-row'>
              <div className='role-select-row-title'>Должность</div>
              <div className='role-select-row-data'>{currentRole.appointment}</div>
            </div>
            <div className='role-select-row'>
              <div className='role-select-row-title'>Роль</div>
              {
                changeUserCurrentRoleModel.loading
                  ? <Loader />
                  : <Combobox
                    className='mt-8 w-100p'
                    onChange={handleRoleSelect}
                    value={selectedRole}
                    options={roleOptions}
                  />
              }
            </div>
          </>
          : <div>Нет назначенных ролей</div>
      }
      {
        changeUserCurrentRoleModel.error
          ? <ErrorText className='mt-16'>{changeUserCurrentRoleModel.error}</ErrorText>
          : null
      }
    </div>
  )
}
