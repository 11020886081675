import { Component } from 'react'
import { connect } from 'react-redux'
import { AppNav, Button, SortIcon, TextInput } from '@src/components'
import { Alert, AlertInput } from '@src/modals'
import { deleteAppointments, deleteRoleAppointments, insertAppointments, insertRoleAppointments, loadAppointmentsByPositions,
  loadAppointmentsByPositionsList, selectAdminRolesListModel, selectAppointmentsPosition, selectAppointmentsPositionList,
  updateAppointments } from '@src/modules/admin/store'
import { modalService } from '@src/services'
import { normalizeStr, PropTypes } from '@src/utils'
import { withMntAudit } from '@src/hoc'
import { AUDIT_PS } from '@src/constants'
import './AdminAppointmentsPage.scss'

class _AdminAppointmentsPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      roleInput: '',
      sortAsc: true,
      sort: 'none',
      selectedRoleId: null,
      selectedAppointmentName: null,
      selectedRoleName: null,
      selectedAddedAppointmentId: null,
      selectedAddedAppointmentValue: null,
      selectedPositionsRole: null,
      addAppointmentInputValue: null,
    }
  }

  static propTypes = {
    adminRolesList: PropTypes.array,
    appointmentsPosition: PropTypes.object,
    appointmentsPositionList: PropTypes.model,
    deleteAppointments: PropTypes.func,
    deleteRoleAppointments: PropTypes.func,
    insertAppointments: PropTypes.func,
    insertRoleAppointments: PropTypes.func,
    loadAppointmentsByPositions: PropTypes.func,
    loadAppointmentsByPositionsList: PropTypes.func,
    logToAudit: PropTypes.func,
    updateAppointments: PropTypes.func,
  }

  componentDidMount () {
    !this.props.appointmentsPositionList.loaded && this.props.loadAppointmentsByPositionsList()
  }

  sortFn = () => {
    const { sort, sortAsc } = this.state

    switch (sort) {
    case 'role':
      return (a, b) => {
        return sortAsc
          ? ('' + a.role_name).localeCompare(b.role_name)
          : ('' + b.role_name).localeCompare(a.role_name)
      }
    case 'direction':
      return (a, b) => {
        return sortAsc
          ? ('' + a.mcl_profile).localeCompare(b.mcl_profile)
          : ('' + b.mcl_profile).localeCompare(a.mcl_profile)
      }
    default: break
    }
  }

  get positionsList () {
    return this.props.appointmentsPositionList?.loaded
      ? this.props.appointmentsPositionList.payload.data
      : []
  }

  get positions () {
    const { selectedRoleId, selectedRoleName } = this.state
    const data = selectedRoleId && selectedRoleName ? this.props.appointmentsPosition[selectedRoleId][selectedRoleName].payload : null
    const arr = []

    try {
      arr.push(...data.data)
      return arr
    } catch {
      return arr
    }
  }

  get filteredRolesList () {
    const { roleInput } = this.state

    return this.props.adminRolesList
      .filter(role => roleInput  ? !role.role_name ? false : normalizeStr(role.role_name).includes(normalizeStr(roleInput))  : true)
      .sort(this.sortFn())
  }

  changeSort = (sort) => {
    this.setState(prevState => ({
      sort,
      sortAsc: prevState.sort === sort ? !prevState.sortAsc : true,
    }))
  }

  getRole = (roleId, roleName) => {
    this.setState({ selectedRoleId: roleId, selectedRoleName: roleName })
    this.props.loadAppointmentsByPositions({ roleId, roleName })
  }

  selectedAddedRole = (roleId, roleAppointment) => {
    this.setState({ selectedAddedAppointmentId: roleId, selectedAddedAppointmentValue: roleAppointment })
  }

  async addAppointment (roleId, appointmentId, roleName) {
    await  this.props.insertRoleAppointments({ roleId, appointmentId })
    this.props.loadAppointmentsByPositions({ roleId, roleName })

    this.props.logToAudit({
      auditPs: AUDIT_PS.ADMIN,
      auditMessage: 'Назначение должности на роль',
      auditDescription: { roleId, appointmentId, roleName },
    })
  }

  async deleteAppointmentRole (selectedRoleId, roleId, roleName) {
    await  this.props.deleteRoleAppointments({ appointment_id: selectedRoleId, role_id: roleId })
    this.props.loadAppointmentsByPositions({ roleId, roleName })
    this.setState({ selectedPositionsRole: null })
  }

  async deleteAppointment (id) {
    await this.props.deleteAppointments(id)
    this.props.loadAppointmentsByPositionsList()

    this.props.logToAudit({
      auditPs: AUDIT_PS.ADMIN,
      auditMessage: 'Удаление должности',
      auditDescription: { id },
    })
  }

  async insertAppointment (id, appointment) {
    await this.props.insertAppointments({ id, appointment })
    this.props.loadAppointmentsByPositionsList()

    this.props.logToAudit({
      auditPs: AUDIT_PS.ADMIN,
      auditMessage: 'Создание должности',
      auditDescription: { id, appointment },
    })
  }

  async updateAppointment (id, appointment) {
    await  this.props.updateAppointments({ id, appointment })
    this.props.loadAppointmentsByPositionsList()

    this.props.logToAudit({
      auditPs: AUDIT_PS.ADMIN,
      auditMessage: 'Редактирование должности',
      auditDescription: { id, appointment },
    })
  }

  handleDeleteAppointmentClick = () => {
    this.deleteAppointmentRole(this.state.selectedPositionsRole, this.state.selectedRoleId, this.state.selectedRoleName)

    this.props.logToAudit({
      auditPs: AUDIT_PS.ADMIN,
      auditMessage: 'Удаление должности с роли',
      auditDescription: { id: this.state.selectedRoleId },
    })
  }

  render () {
    return (
      <div className='root-admin-appointment-page'>
        <AppNav
          title='Должности'
          breadcrumbs={[
            {
              label: 'Администрирование',
              link: 'appointments',
            },
            {
              label: 'Должности',
              link: 'appointments',
            },
          ]}
        />
        <div className='appointment-wrapper'>
          <div className='appointment-block-left'>
            <div className='button-wrapper'>
              <TextInput
                placeholder='Введите роль'
                name='roleInput'
                icon='search'
                value={this.state.roleInput}
                onChange={e => this.setState({ roleInput: e })}
                className='appointment-text-input'
                allowClear
              />
            </div>
            <div className='appointment-roles-roles-list'>
              <table className='table-left'>
                <thead>
                  <tr>
                    <th>
                      <div className='th-content-wrapper'>
                        <span>Роль</span>
                        <SortIcon
                          className='sort-icon'
                          sortField='role'
                          currentSortField={this.state.sort}
                          sortType={this.state.sortAsc}
                          onClick={() => this.changeSort('role')}
                        />
                      </div>
                    </th>
                    <th>
                      <div className='th-content-wrapper'>
                        <span>Направление ОМП</span>
                        <SortIcon
                          className='sort-icon'
                          sortField='direction'
                          currentSortField={this.state.sort}
                          sortType={this.state.sortAsc}
                          onClick={() => this.changeSort('direction')}
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.filteredRolesList.map((role, i) => (
                      <tr
                        key={i}
                        onClick={() => this.getRole(role.id, role.role_name)}
                        className={role.id === this.state.selectedRoleId ? 'selected' : null}
                      >
                        <td>{role.role_name || '-'}</td>
                        <td>{role.mcl_profile || '-'}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div className='appointment-block-center'>
            <div className='button-wrapper'>
              <Button
                label='Удалить должность'
                disabled={!this.state.selectedPositionsRole}
                icon='trash_can'
                isSecondary
                onClick={() => { modalService.open(Alert, {
                  warn: true,
                  onOk: this.handleDeleteAppointmentClick,
                  title: 'Удаление должности',
                  children: `Вы действительно хотите удалить должность "${this.state.selectedAppointmentName}"?`,
                  okText: 'Удалить',
                }) }}
              />
            </div>
            <div className='appointment-roles-roles-list'>
              <table>
                <thead>
                  <tr>
                    <th>
                      <div>Должности роли</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.positions.map((role, i) => (
                      <tr
                        key={i}
                        className={role.id === this.state.selectedPositionsRole ? 'selected' : null}
                        onClick={() => this.setState({ selectedPositionsRole: role.id, selectedAppointmentName: role.name })}
                      >
                        <td>{role.name || '-'}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div className='appointment-block-right'>
            <div className='big-button-wrapper'>
              <Button
                label='<'
                disabled={!this.state.selectedAddedAppointmentId || !this.state.selectedRoleId}
                onClick={() => this.addAppointment(this.state.selectedRoleId, this.state.selectedAddedAppointmentId, this.state.selectedRoleName)}
              />
            </div>
            <div className='appointment-block-right-content-wrapper'>
              <div className='button-wrapper'>
                <Button
                  label='Добавить должность'
                  icon='plus_cr_fr'
                  onClick={() => { modalService.open(AlertInput, {
                    onOk: value => this.insertAppointment('null', value),
                    okText: 'Добавить',
                    title: 'Добавить должность',
                    inputProps: { placeholder: 'Введите название должности' },
                  }) }}
                  className='appointment-action-button'
                />
                <Button
                  label='Удалить должность'
                  disabled={!this.state.selectedAddedAppointmentId}
                  icon='trash_can'
                  isSecondary
                  onClick={() =>  modalService.open(Alert, {
                    warn: true,
                    onOk: () => this.deleteAppointment(this.state.selectedAddedAppointmentId),
                    title: 'Удаление должности',
                    children: `Удалить должность "${this.state.selectedAddedAppointmentValue}"?`,
                    okText: 'Удалить',
                  })}
                  className='appointment-action-button'
                />
                <Button
                  label='Редактировать должность'
                  icon='file_copy'
                  disabled={!this.state.selectedAddedAppointmentId}
                  onClick={() => { modalService.open(AlertInput, {
                    onOk: value => this.updateAppointment(this.state.selectedAddedAppointmentId, value),
                    okText: 'Редактировать',
                    title: 'Редактировать должность',
                    value: this.state.selectedAddedAppointmentValue,
                  }) }}
                  className='appointment-action-button'
                />
              </div>
              <div className='appointment-roles-roles-list'>
                <table>
                  <thead>
                    <tr>
                      <th>
                        <div>Должность</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.positionsList.map((role, i) => (
                        <tr
                          key={i}
                          className={role.id === this.state.selectedAddedAppointmentId ? 'selected' : null}
                          onClick={() => this.selectedAddedRole(role.id, role.appointment)}
                        >
                          <td>{role.appointment || '-'}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    adminRolesList: selectAdminRolesListModel(state).payload.data,
    appointmentsPosition: selectAppointmentsPosition(state),
    appointmentsPositionList: selectAppointmentsPositionList(state),
  }
}

const mapDispatchToProps = dispatch => ({
  loadAppointmentsByPositions: args => dispatch(loadAppointmentsByPositions(args)),
  loadAppointmentsByPositionsList: () => dispatch(loadAppointmentsByPositionsList()),
  insertRoleAppointments: args => dispatch(insertRoleAppointments(args)),
  deleteRoleAppointments: args => dispatch(deleteRoleAppointments(args)),
  deleteAppointments: args => dispatch(deleteAppointments(args)),
  insertAppointments: args => dispatch(insertAppointments(args)),
  updateAppointments: args => dispatch(updateAppointments(args)),
})

const AdminAppointmentsPage = connect(mapStateToProps, mapDispatchToProps)(_AdminAppointmentsPage)
export const AdminAppointmentsPageWithAudit = withMntAudit(AdminAppointmentsPage)
