import PropTypes from 'prop-types'

PropTypes.model = PropTypes.shape({
  payload: PropTypes.any,
  loaded: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.string,
})

export { PropTypes }
