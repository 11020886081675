import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useMountEffect } from '@src/hooks'
import { loadOccasionInfo, loadPersonInfo, selectOccasionInfoModel, selectPersonInfoModel } from '@src/modules/monitoring/store'
import { withMntAudit } from '@src/hoc'

import { TabRouteBase } from './TabRouteBase'
import './TabRoute.scss'

const TabRoute = ({ patientId, occasionPatientId, ...props }) => {
  const dispatch = useDispatch()
  const [paramsLoadRoutes, setParamsLoadRoutes] = useState(null)
  const [paramsLoadRoutesError, setParamsLoadRoutesError] = useState(null)
  const personInfoModel = useSelector(selectPersonInfoModel)
  const occasionInfoModel = useSelector(selectOccasionInfoModel)

  useMountEffect(() => {
    dispatch(loadOccasionInfo({ args: { occasion_patient_id: occasionPatientId } }))
    if (!personInfoModel.loaded && !personInfoModel.loading) dispatch(loadPersonInfo({ args: { patient_id: patientId } }))
  })

  useEffect(() => {
    const snils = personInfoModel.payload?.data?.[0]?.snils
    const nosology_code = occasionInfoModel.payload?.data?.[0]?.nosology_code
    const vmcl = occasionInfoModel.payload?.data?.[0]?.vmcl
    if(snils && nosology_code && vmcl) {
      setParamsLoadRoutes({
        snils,
        nosology_code,
        vmcl,
      })
    } else {
      setParamsLoadRoutes(null)

      const errorMsg = [
        snils ? null : 'СНИЛС',
        nosology_code ? null : 'Код нозологии',
        vmcl ? null : 'vmcl',
      ].filter(e => e).join(', ')
      setParamsLoadRoutesError(`Недостаточно параметров для получения маршрута: ${errorMsg}`)
    }
  }, [personInfoModel, occasionInfoModel])

  return <TabRouteBase
    patientId={patientId}
    occasionPatientId={occasionPatientId}
    paramsLoadRoutes={paramsLoadRoutes}
    paramsLoadRoutesError={paramsLoadRoutesError}
    {...props}
  />
}

export const TabRouteWithAudit = withMntAudit(TabRoute)

TabRouteWithAudit.tabTitle = 'Маршрут'
TabRouteWithAudit.tabPath = 'route'
