import cn from 'classnames'
import PropTypes from 'prop-types'
import { Component } from 'react'

import './Checkbox.scss'

export default class Checkbox extends Component {
  static propTypes = {
    value: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    Label: PropTypes.string,
    className: PropTypes.string,
  }

  handleChange = SyntheticEvent => this.props.onChange && this.props.onChange(SyntheticEvent.target.checked)

  handleLabelClick = () => {
    if(!this.props.disabled) this.props.onChange(!this.props.value)
  }

  render () {
    const { value, name, disabled, className, label, Label } = this.props

    return (
      <div className={cn('root-checkbox-container', disabled && 'disabled', className)}>
        { label && <p className='mg-r-8' onClick={this.handleLabelClick}>{label}</p> }
        <input
          disabled={disabled}
          onChange={this.handleChange}
          type='checkbox'
          checked={Boolean(value)}
          id={name}
          className='root-checkbox'
        >
        </input>
        { Label && <p className='mg-l-8' onClick={this.handleLabelClick}>{Label}</p> }
      </div>
    )
  }
}
