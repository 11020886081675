import cn from 'classnames'
import { isArray, isNil } from 'lodash'
import { Fragment, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Link } from 'react-router-dom'

import { AppTabs, GenderTag, Tag } from '@src/components'
import { TAG_COLORS } from '@src/constants'
import { relativeTimeWithPlural } from '@src/utils'

import './AppNav.scss'

const DEFAULT_DOCUMENT_TITLE = 'BARS.STAR'

export const AppNav = ({ title, breadcrumbs, className, sex, age, tabs }: AppNavProps) => {
  const [breadcrumbsEl, setBreadcrumbsEl] = useState<Element | null>(null)

  useEffect(() => {
    const title = isArray(breadcrumbs) && breadcrumbs.length
      ? breadcrumbs[breadcrumbs.length - 1].label || DEFAULT_DOCUMENT_TITLE
      : DEFAULT_DOCUMENT_TITLE
    document.title = title
  })

  useEffect(() => {
    setBreadcrumbsEl(document.getElementById('app-nav-breadcrumbs'))
    return () => { document.title = DEFAULT_DOCUMENT_TITLE }
  }, [])

  return <div className={cn(className, 'root-app-nav')}>
    {
      breadcrumbsEl && createPortal(
        <Fragment>
          {
            breadcrumbs.map((b, index) => (
              <div className='app-nav-breadcrumb' title={b.label} style={b.style} key={index}>
                {
                  b.link
                    ? <Link to={b.link}>{b.label}</Link>
                    : b.label
                }
                {
                  index < breadcrumbs.length - 1
                    ? <span>&nbsp;&nbsp;&nbsp;&nbsp;›&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    : null
                }
              </div>
            ))
          }
        </Fragment>,
        breadcrumbsEl,
      )
    }
    <div className='app-nav-title'>
      { title instanceof Function ? title() : title }
      { sex ? <GenderTag className='app-nav-tag'>{sex}</GenderTag> : null }
      { !isNil(age) ? <Tag color={TAG_COLORS.LIGHT_BEIGE} className='app-nav-tag'>{relativeTimeWithPlural(age, 'yy')}</Tag> : null }
    </div>
    { !!tabs && <AppTabs linkTabs tabs={tabs} /> }
  </div>
}
