import { Form } from 'antd'
import { isArray, isEmpty } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import {
  getCopyModalConditions, getCopyModalRoutes, getCopyModalSourceCondition, getCopyModalStates, getCopyModalVersions, postCopyModalSaveCondition, resetCopyConditionModalState,
  selectGetCopyModalConditionsModel, selectGetCopyModalRoutesModel, selectGetCopyModalSaveConditionModel, selectGetCopyModalSourceConditionModel,
  selectGetCopyModalStatesModel, selectGetCopyModalVersionsModel,
} from '@src/modules/methodology/store'
import { useMountEffect } from '@src/hooks'
import { ButtonWithAudit } from '@src/components/Button/Button'
import { Button, Combobox, If, Modal } from '@src/components'
import './CopyConditionModal.scss'
import { AUDIT_PS } from '@src/constants'

export const CopyConditionModal = ({ routeStateId, routeStateCode, routeVersionId, routeDocuments, conditionNumber, onSuccess, onClose, ...props }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  useMountEffect(() => {
    return () => dispatch(resetCopyConditionModalState())
  })

  // Список документов-оснований
  const documentsOptions = useMemo(() => {
    return routeDocuments.map(doc => ({
      value: doc.document_id,
      label: `№${doc?.document_number} ${doc?.document_name}`,
    }))
  }, [routeDocuments])

  // Список маршрутов
  const graphId = Form.useWatch('graphId', form)
  const routesModel = useSelector(selectGetCopyModalRoutesModel)
  useEffect(() => {
    if(!routesModel.loaded && !routesModel.loading) dispatch(getCopyModalRoutes({}))
  }, [dispatch, form, routesModel.loaded, routesModel.loading])
  const routesOptions = useMemo(() => {
    return routesModel?.payload?.data.map(route => ({
      value: route.graph_id,
      label: `[${route.graph_status_name}] ${route.graph_caption}`,
    }))
  }, [routesModel?.payload?.data])
  // Сбрасываем все нижестоящие поля при изменении graphId
  useEffect(() => {
    form.setFieldsValue({ versionId: undefined, stateId: undefined, conditionId: undefined })
  }, [form, graphId])

  // Список версий
  const versionId = Form.useWatch('versionId', form)
  const versionsModel = useSelector(selectGetCopyModalVersionsModel)
  useEffect(() => {
    if(graphId) dispatch(getCopyModalVersions({ args: { graph_id: graphId } }))
  }, [dispatch, form, graphId])
  const versionsOptions = useMemo(() => {
    return versionsModel?.payload?.data.map(version => ({
      value: version.region_graph_rev_id,
      label: `[${version.region_status_caption}] ${version.full_text}`,
    }))
  }, [versionsModel?.payload?.data])
  // Сбрасываем все нижестоящие поля при изменении versionId
  useEffect(() => {
    form.setFieldsValue({ stateId: undefined, conditionId: undefined })
  }, [form, versionId])

  // Список подэтапов
  const stateId = Form.useWatch('stateId', form)
  const statesModel = useSelector(selectGetCopyModalStatesModel)
  useEffect(() => {
    if(graphId && versionId) dispatch(getCopyModalStates({ graph_id: graphId, region_graph_id: versionId }))
  }, [dispatch, form, graphId, versionId])
  const statesOptions = useMemo(() => {
    return statesModel?.payload?.data?.[0]?.route_stage
      ?.map(stage => stage?.route_state)
      .flat(1)
      .slice()
      .sort((a, b) => parseInt(a.sort) - parseInt(b.sort))
      .map(state => ({
        value: state.route_state_id,
        state_code: state.state_code,
        label: `[${state.sort}] ${state.name}`,
      }))
  }, [statesModel?.payload?.data])
  // Сбрасываем все нижестоящие поля при изменении stateId
  useEffect(() => {
    form.setFieldsValue({ conditionId: undefined })
  }, [form, stateId])

  // Список условий
  const conditionsModel = useSelector(selectGetCopyModalConditionsModel)
  useEffect(() => {
    if(graphId && versionId && stateId) dispatch(getCopyModalConditions({ graph_ext_id: versionId, route_state_id: stateId }))
  }, [dispatch, graphId, stateId, versionId])
  const conditionsOptions = useMemo(() => {
    return conditionsModel?.payload?.data?.[0]?.route_state_condition
      ?.slice()
      .sort((a, b) => parseInt(a.number_condition) - parseInt(b.number_condition))
      .slice()
      .map(condition => ({
        value: condition.number_condition_id,
        label: `Региональное условие ${condition.number_condition}`,
      }))
  }, [conditionsModel?.payload?.data])

  // Копирование условия
  const sourceModel = useSelector(selectGetCopyModalSourceConditionModel)
  const copyModel = useSelector(selectGetCopyModalSaveConditionModel)
  const handleCopyCondition = useCallback(async (formValues) => {
    const source = await dispatch(getCopyModalSourceCondition({
      route_state_id: formValues.stateId,
      graph_ext_id: formValues.versionId,
      number_condition_id: formValues.conditionId,
    }))

    if (!source.error && source.payload) {
      // Заполняем структуру нового условия, зануляя связи исходного условия
      const save = await dispatch(postCopyModalSaveCondition({
        route_state_id: routeStateId,
        state_code: routeStateCode,
        graph_ext_id: routeVersionId,
        number_condition: conditionNumber,
        document_id: formValues.documentId,

        mo_territory_list: source.payload.mo_territory_list.map(moTer => ({
          ...moTer,
          uuid: uuidv4(), // генерируем новый uuid записи
        })),

        add_condition: source.payload.add_condition
          ? {
            ...source.payload.add_condition,
            condition_id: null, // Обнуляем id связи
            condition_disjunct: source.payload.add_condition.condition_disjunct?.map(disjunct => ({
              condition_conjunct: disjunct?.condition_conjunct?.map(conjunct => ({
                ...conjunct,
                condition_dnf_id: null, // Обнуляем id связи
                value_description: {
                  ...conjunct.value_description,
                  value_id: null, // Обнуляем id связи
                  value: isArray(conjunct.value_description.value)
                    ? conjunct.value_description.value.map(v => ({
                      ...v,
                      value_id: null, // Обнуляем id связи
                    }))
                    : conjunct.value_description.value,
                },
              })),
            })),

          }
          : null,

        time_condition: source.payload.time_condition
          ? {
            ...source.payload.time_condition,
            condition_id: null, // Обнуляем id связи
          }
          : null,
      }))

      if (!save.error && save.payload) {
        onSuccess({
          number_condition: save.payload.number_condition,
          number_condition_id: save.payload.number_condition_id,
          number_condition_uuid: save.payload.number_condition_uuid,
        })
        onClose()
      }
    }

  }, [conditionNumber, dispatch, onClose, onSuccess, routeStateCode, routeStateId, routeVersionId])

  return <Modal
    title='Копирование регионального условия'
    className='root-copy-condition-modal'
    centered={false}
    style={{ top: '250px' }}
    onClose={onClose}
    footer={<Footer onCancel={onClose} onCopy={form.submit} loading={sourceModel.loading || copyModel.loading}/>}
    {...props}
  >
    <Form
      form={form}
      layout='vertical'
      onFinish={handleCopyCondition}
      disabled={sourceModel.loading || copyModel.loading}
      validateMessages={{ required: 'Обязательное поле' }}
    >
      <Form.Item label='Документ-основание' name='documentId' rules={[{ required: true }]}>
        <Combobox
          placeholder='Выберите документ-основание'
          options={documentsOptions}
          className='w-100p'
          showSearch
        />
      </Form.Item>

      <Form.Item label='Наменование маршрута' name='graphId' rules={[{ required: true }]}>
        <Combobox
          placeholder='Выберите маршрут'
          disabled={routesModel.loading || !routesOptions || isEmpty(routesOptions)}
          loading={routesModel.loading}
          options={routesOptions}
          className='w-100p'
          showSearch
        />
      </Form.Item>

      <If condition={graphId}>
        <Form.Item label='Версия региональной маршрутизации' name='versionId' rules={[{ required: true }]}>
          <Combobox
            placeholder={!versionsModel.loading && isEmpty(versionsOptions) && 'Маршрут не содержит версий'}
            disabled={versionsModel.loading || !versionsOptions || isEmpty(versionsOptions)}
            loading={versionsModel.loading}
            options={versionsOptions}
            className='w-100p'
            showSearch
          />
        </Form.Item>
      </If>

      <If condition={versionId}>
        <Form.Item label='Подэтап маршрута' name='stateId' rules={[{ required: true }]}>
          <Combobox
            placeholder={!statesModel.loading && isEmpty(statesOptions) && 'Версия не содержит подэтапов'}
            disabled={statesModel.loading || !statesOptions || isEmpty(statesOptions)}
            loading={statesModel.loading}
            options={statesOptions}
            className='w-100p'
            showSearch
          />
        </Form.Item>
      </If>

      <If condition={stateId}>
        <Form.Item label='Региональное условие' name='conditionId' rules={[{ required: true }]}>
          <Combobox
            placeholder={!conditionsModel.loading && isEmpty(conditionsOptions) && 'Подэтап не содержит региональных условий'}
            disabled={conditionsModel.loading || !conditionsOptions || isEmpty(conditionsOptions)}
            loading={conditionsModel.loading}
            options={conditionsOptions}
            className='w-100p'
            showSearch
          />
        </Form.Item>
      </If>
    </Form>
  </Modal>
}

const Footer = ({ onCancel, onCopy, loading }) => {
  return <div className='footer d-flex justify-content-end'>
    <Button
      className='mr-8'
      label='Отменить'
      disabled={loading}
      onClick={onCancel}
    />
    <ButtonWithAudit
      className='copy-button'
      icon='copy'
      label='Копировать'
      isSuccess
      loading={loading}
      disabled={loading}
      onClick={onCopy}
      auditPs={AUDIT_PS.METHOD}
      auditMessage='Копирование регионального условия'
    />
  </div>
}
