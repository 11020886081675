import { thunkCreator } from '@src/utils'
import * as models from '../models'

export const [
  loadPatientStatusAkineoCharacteristic, loadPatientStatusAkineoCharacteristicExtra,
] = thunkCreator(models.PATIENT_STATUS_AKINEO_CHARACTERISTIC, '/patient-monitoring/get_patient_status_akineo_characteristic',
  { targetModel: models.PATIENT_STATUS })

export const [
  loadPatientStatusAkineoMother, loadPatientStatusAkineoMotherExtra,
] = thunkCreator(models.PATIENT_STATUS_AKINEO_MOTHER, '/patient-monitoring/get_patient_status_akineo_mother')

export const [
  loadPatientNeonatalScreening, loadPatientNeonatalScreeningExtra,
] = thunkCreator(models.PATIENT_NEONATAL_SCREENING, '/patient-monitoring/get_patient_neonatal_screening')

export const [
  loadPatientStatusChild, loadPatientStatusChildExtra,
] = thunkCreator(models.PATIENT_STATUS_CHILD, '/patient-monitoring/get_patient_status_akineo_child_vnz')

export default {
  ...loadPatientStatusAkineoCharacteristicExtra,
  ...loadPatientStatusAkineoMotherExtra,
  ...loadPatientNeonatalScreeningExtra,
  ...loadPatientStatusChildExtra,
}
