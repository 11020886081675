import moment from 'moment'
import { DateRangePicker } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'
import { requestArgsDateRange } from './columnsReqArgs'

export class NotificationDateColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.NOTIFICATION_DATE

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Дата извещения',
        component: DateRangePicker,
        defaultValue: [null, null],
        props: {
          allowClear: true,
          allDay: true,
          allowEmpty: [true, true],
          disabledDate: currentDate => currentDate && currentDate.isAfter(moment()),
        },
      },
      width: 250,
      dataIndex: 'begin_on',
      dataRender: begin_on => begin_on ? `${moment(begin_on).format('DD.MM.YYYY')}` : '-',
      sort: true,
    }

    super(column, settings)
  }

  _requestArgs = reqArgs => requestArgsDateRange(this.type, reqArgs)
}
