import moment from 'moment'
import { DateRangePicker } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'
import { requestArgsDateRange } from './columnsReqArgs'

export class AdvancedNeonatalScreeningResultDateColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.ADVANCED_NEONATAL_SCREENING_RESULT_DATE

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Дата результата скрининга (РНС)',
        component: DateRangePicker,
        defaultValue: [null, null],
        props: {
          allowClear: true,
          allDay: true,
          allowEmpty: [true, true],
          disabledDate: currentDate => currentDate && currentDate.isAfter(moment()),
        },
      },
      width: 250,
      dataIndex: 'advanced_neonatal_screening_result_date',
      dataRender: date => date ? moment(date).format('DD.MM.YYYY') : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs = reqArgs => requestArgsDateRange(this.type, reqArgs)
}
