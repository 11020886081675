import cn from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { withAcl } from '@src/hoc'

class ReportLink extends React.Component {
  static propTypes = {
    enabled: PropTypes.bool,
    to: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
  }

  render () {
    const { to, title, enabled, className } = this.props
    return enabled
      ? <p className={cn('root-report-link', className)}><Link to={to}>{title}</Link></p>
      : null
  }
}

export default withAcl(ReportLink)
