import cn from 'classnames'
import { isBoolean } from 'lodash'
import { useEffect } from 'react'
import { NOTIFICATION_TYPES } from '@src/constants'
import { Icon } from '@src/components'
import './Notification.scss'

const Notification = ({ type, children, autoclose, onClose }) => {
  const autocloseMs = autoclose && isBoolean(autoclose)
    ? 3000 // default 3000ms
    : autoclose

  useEffect(() => {
    let timeoutId = null
    if(autocloseMs) {
      timeoutId = setTimeout(() => onClose(), autocloseMs)
    }

    return () => timeoutId && clearTimeout(timeoutId)
  }, [autocloseMs, onClose])

  const typeToLabel = (type) => {
    switch (type) {
    case NOTIFICATION_TYPES.SUCCESS: return 'Успешно'
    case NOTIFICATION_TYPES.ERROR: return 'Ошибка'
    case NOTIFICATION_TYPES.WARNING: return 'Предупреждение'
    case NOTIFICATION_TYPES.INFO: return 'Информация'
    default: return 'Успешно'
    }
  }

  return (
    <div className={cn('root-notification', type)}>
      <div className='notification-row'>
        <Icon className='notification-info-svg' icon={`notification_${type}`} />
        <div className='notification-content'>
          <h6 className='notification-title'>{typeToLabel(type)}</h6>
          {children}
        </div>
      </div>
      <Icon className='pa-8' onClick={onClose} icon='notification_close' alt='close' />
    </div>
  )
}

export default Notification
