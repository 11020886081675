import { Button, Modal } from '@src/components'
import './DeleteFoundationDocumentModal.scss'

export const DeleteFoundationDocumentModal = ({ data, cb, onClose, ...props }) => {
  return <Modal
    className='custom-modal'
    title={'Вы действительно хотите удалить документ-основание'}
    width={732}
    onClose={onClose}
    footer={<RecordModalFooter cb={cb} onClose={onClose} data={data}/>}
    {...props}
  >
    <div>
      <div className='block'>
        <div className='title'>
          <p>Наименование регионального НПА</p>
        </div>
        <div className='text'>
          <p>{data.document_name}</p>
        </div>
      </div>

      <div className='block gray'>
        <div className='title'>
          <p>Номер регионального НПА</p>
        </div>
        <div className='text'>
          <p>{data?.document_number}</p>
        </div>
      </div>
    </div>
  </Modal>
}

const RecordModalFooter = ({ cb, onClose, data }) => {
  const handleBtn = (e, val) => {
    cb(val, data)
    onClose()
  }

  return <div
    className='buttons-wrapper'
    style={{ display: 'flex', justifyContent: 'right' }}
  >
    <Button
      className='mr-8'
      label='Отмена'
      onClick={e => handleBtn(e, false)}
    />
    <Button
      label='Удалить'
      onClick={e => handleBtn(e, true)}
      isSecondary
    />
  </div>
}
