import { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { withAcl } from '@src/hoc'
import { AUDIT_PS, URLS } from '@src/constants'
import { InternalError404 } from '@src/components'
import { NsiFrnsiListPageWithAudit } from '../NsiFrnsiListPage/NsiFrnsiListPage'
import { NsiFrnsiDictPageWithAudit } from '../NsiFrnsiDictPage/NsiFrnsiDictPage'
import './NsiPage.scss'

class NsiPage extends Component {
  render () {
    return (
      <div className='root-nsi-page'>
        <Switch>
          <Route path={`${URLS.NSI__FRNSI__DICT}/:refbookId`}>
            <NsiFrnsiDictPageWithAudit
              auditPs={AUDIT_PS.PSI}
              auditMessage='Просмотр справочника ФРНСИ'
            />
          </Route>
          <Route path={URLS.NSI__FRNSI}>
            <NsiFrnsiListPageWithAudit
              auditPs={AUDIT_PS.PSI}
              auditMessage='Просмотр списка справочников ФРНСИ'
            />
          </Route>
          <Route component={InternalError404}/>
        </Switch>
      </div>
    )
  }
}

export const NsiPageWithAcl = withAcl(NsiPage)
