import { Form, Input } from 'antd'
import { useContext } from 'react'
import { Badge, Icon, IfNotReadonly } from '@src/components'
import { AccessContext } from '@src/context'
import { FormContext } from './FormContext'
import { Disjunct } from './Disjunct'

export const AttributesForm = ({ form, onFinish, initialValues }) => {
  const accessContext = useContext(AccessContext)

  return <Form
    className='root-attributes'
    form={form}
    layout='vertical'
    onFinish={onFinish}
    initialValues={initialValues}
    disabled={accessContext.readonly}
  >
    <Form.Item hidden name='condition_id'><Input /></Form.Item>
    <div className='attributes-characteristics'>
      <div className='characteristics-title h-52 pa-16'>
        <span>Характеристики условия</span>
      </div>

      <div className='characteristics-inputs px-16 py-24'>
        <Form.Item
          name='condition_name'
          label='Наименование условия'
          rules={[{ required: true }]}
        >
          <Input placeholder='Введите наименование' />
        </Form.Item>
        <Form.Item
          name='condition_comment'
          label='Текстовое описание обоснования условия'
          rules={[{ required: true }]}
        >
          <Input.TextArea rows={4} placeholder='Введите текстовое описание обоснования условия' />
        </Form.Item>
      </div>
    </div>

    <Form.List name='condition_disjunct'>
      {(fields, disjunctOperation) => <div className='attributes-conditions'>
        {fields.map(((field, index) => <FormContext.Provider key={field.key} value={{
          currentDisjunctFieldName: field.name,
        }}>
          <Disjunct
            title={`Дополнительное условие ${index + 1}`}
            onDeleteDisjunct={() => disjunctOperation.remove(field.name)}
          />
        </FormContext.Provider>))}
        <IfNotReadonly>
          <div onClick={() => disjunctOperation.add({})} className='attributes-add-disjunct d-flex align-items-center justify-content-center pa-16'>
            <Badge>или</Badge>
            <Icon className='mr-8 w-16 h-16' icon='plus_cr_fr' />
            <span>Добавить дополнительное условие</span>
          </div>
        </IfNotReadonly>
      </div>}
    </Form.List>
  </Form>
}
