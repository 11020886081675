import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppNav, DataGrid } from '@src/components'
import { URLS } from '@src/constants'
import { useColumns } from '@src/hooks'
import { loadJournalAuditList, selectJournalAuditListModel } from '@src/modules/journal/store'

import { withMntAudit } from '@src/hoc'
import { COLUMNS } from './columns'
import { JournalAuditControls } from './JournalAuditControls'
import './JournalAuditPage.scss'

const JournalAuditPage = () => {
  const [enabledColumns, columnOptions, toggleColumn] = useColumns(COLUMNS)

  const dispatch = useDispatch()
  const journalAuditListModel = useSelector(selectJournalAuditListModel)

  const handleFiltersChange = useCallback((args) => {
    dispatch(loadJournalAuditList(args))
  }, [dispatch])

  return <div className='root-journal-audit-page'>
    <AppNav
      title='Журнал действий пользователей'
      breadcrumbs={[
        { label: 'Администрирование', link: URLS.JOURNAL__AUDIT },
        { label: 'Журнал действий пользователей', link: URLS.JOURNAL__AUDIT },
      ]}
    />
    <JournalAuditControls
      listModel={journalAuditListModel}
      onChange={handleFiltersChange}
      columnOptions={columnOptions}
      onToggleColumn={toggleColumn}
    />
    <DataGrid
      className='audit-grid'
      columns={enabledColumns}
      rowKey='msg_id'
      data={journalAuditListModel.payload?.data || []}
      loading={journalAuditListModel.loading}
      error={journalAuditListModel.error}
    />
  </div>
}

export const JournalAuditPageWithAudit = withMntAudit(JournalAuditPage)
