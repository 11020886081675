import React from 'react'
import cn from 'classnames'
import { connect } from 'react-redux'
import { Notification } from '@src/components'
import { removeNotification, selectNotifications } from '@src/store'

import './AppNotificationStack.scss'

class AppNotificationStack extends React.Component {
  closeAll = () => {
    this.props.notifications.forEach(n => this.props.removeNotification(n.notificationId))
  }

  render () {
    return (
      <div className={cn('root-app-notification-stack')}>
        {
          this.props.notifications.length > 1
            ? <p onClick={this.closeAll} className='notification-button'>Скрыть все</p>
            : null
        }
        {
          this.props.notifications.map(n => <Notification
            key={n.notificationId}
            type={n?.type}
            autoclose={n.autoclose}
            onClose={() => this.props.removeNotification(n.notificationId)}
          >{n?.message}</Notification>)
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    notifications: selectNotifications(state),
  }
}

const mapDispatchToProps = dispatch => ({
  removeNotification: notificationId => dispatch(removeNotification(notificationId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AppNotificationStack)
