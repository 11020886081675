import { Popover } from 'antd'
import cn from 'classnames'
import { useMemo, useState } from 'react'
import { normalizeStr } from '@src/utils'
import { TextInput } from '@src/components'
import { Icon } from '../Icon/Icon'
import { Switch } from '../Switch/Switch'
import './ColumnsSettings.scss'

export const ColumnsSettings = ({ className, columnOptions, onToggleColumn }) => {
  return <Popover
    content={<SettingsPopover columnOptions={columnOptions} onToggleColumn={onToggleColumn}/>}
    trigger='click'
    placement='bottomRight'
    overlayClassName='root-columns-settings-overlay'
  >
    <Icon
      className={cn('root-columns-settings', className)}
      icon='settings'
      title='Отображение колонок'
    />
  </Popover>
}

const SettingsPopover = ({ columnOptions, onToggleColumn }) => {
  const [search, setSearch] = useState('')

  const onlyOneEnabled = useMemo(() => {
    return columnOptions.filter(opt => opt.enabled).length === 1
  }, [columnOptions])

  const filteredOptions = useMemo(() => {
    return search
      ? columnOptions.filter(opt => normalizeStr(opt?.label?.title || opt.label).includes(normalizeStr(search)))
      : columnOptions
  }, [columnOptions, search])

  return <div>
    {
      columnOptions.length > 19 &&
      <div className='column-settings-search'>
        <TextInput
          placeholder='Наименование столбца'
          icon='search'
          value={search}
          onChange={setSearch}
          allowClear
        />
      </div>
    }
    <div className='column-settings-switches'>
      {
        filteredOptions.map(opt => (
          <div className='switch-row h-26' key={opt.key} onClick={() => !(opt.enabled && onlyOneEnabled) && onToggleColumn(opt.key)}>
            <Switch size='small' checked={opt.enabled} disabled={opt.enabled && onlyOneEnabled}/>
            <span className='ml-8'>{opt?.label?.title || opt.label}</span>
          </div>
        ))
      }
    </div>
  </div>
}
