import { useEffect, useMemo } from 'react'

/**
 * @description Хук выполняется только раз, при монтировании компонента
 * Аналог `useEffect(cb, [])`
 * @param {callback} cb
 * @returns
 */
export const useMountEffect = (cb) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(cb, [])
}

/**
 * @description Memo выполняется только раз, при монтировании компонента
 * Аналог `useMemo(cb, [])`
 * @param {callback} cb
 * @returns
 */
export const useMountMemo = (cb) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(cb, [])
}
