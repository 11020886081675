import moment from 'moment'
import { useMemo } from 'react'
import { Card } from '@src/components'
import { withMntAudit } from '@src/hoc'
import './CommonTab.scss'

const CommonTab = ({ infoMsg, attributes, showModal }) => {

  const cardData = useMemo(() => {

    return [
      {
        title: 'Дата получения запроса',
        data: infoMsg.client_msg_datetime
          ? moment(infoMsg.client_msg_datetime).format('DD.MM.YYYY')
          : '-',
      },
      {
        title: 'Идентификатор сообщения',
        data: infoMsg.msg_id || '-',
      },
    ]
  }, [infoMsg])

  const attrData = useMemo(() => {

    return [
      ...attributes.map(attr => ({
        title: attr.attribute_name,
        ...(
          attr.attribute_value.length <= 150
            ? { data: attr.attribute_value }
            : { action: {
              onClick: () => showModal(attr.attribute_value),
              label: 'Просмотр',
            } }
        ),
      })),
    ]
  }, [attributes, showModal])

  return (
    <div className='root-journal-semd-details-common-tab'>
      <Card
        className='card'
        data={cardData}
        title='Общие сведения'
      />
      <Card
        className='card'
        data={attrData}
        title='Атрибуты запроса'
      />
    </div>
  )
}

export const CommonTabWithAudit = withMntAudit(CommonTab)
