import cn from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { ActionMenu, AppNav, Button, Icon, Loader, SwitchWithAudit } from '@src/components'
import { ButtonWithAudit } from '@src/components/Button/Button'
import { AUDIT_PS, URLS } from '@src/constants'
import { withMntAudit } from '@src/hoc'
import {
  insertExternalSystem,
  loadExternalSystemsList,
  resumeAllExternalSystems,
  selectAdminExternalSystemsListModel, selectAdminResumeAllExternalSystems, selectAdminSuspendAllExternalSystems, suspendAllExternalSystems,
  updateExternalSystem,
} from '@src/modules/admin/store'
import { modalService } from '@src/services'
import { AddExtModalWithAudit } from './AddExtModal/AddExtModal'
import './AdminExternalsPage.scss'
import { ShowExtHistoryModalWithAudit } from './ShowExtHistoryModal/ShowExtHistoryModal'

class _AdminExternalsPage extends Component {
  static propTypes = {
    loadExternalSystemsList: PropTypes.func,
    insertExternalSystem: PropTypes.func,
    updateExternalSystem: PropTypes.func,
    suspendAllExternalSystems: PropTypes.func,
    resumeAllExternalSystems: PropTypes.func,

    adminExternalSystemsListModel: PropTypes.shape({
      loaded: PropTypes.bool,
      loading: PropTypes.bool,
      error: PropTypes.string,
      payload: PropTypes.object,
    }),
  }

  constructor (props) {
    super(props)

    this.state = {
      selectedExt: null,
      switchIsLoading: {},
    }
  }

  componentDidMount = async () => {
    !this.props.adminExternalSystemsListModel.loaded && this.loadExternalSystemsList()
  }

  loadExternalSystemsList = () => this.props.loadExternalSystemsList({ control: { datamode: 'full', sorts: [] } })

  get isSuspendAllEnabled () {
    const extList = this.props.adminExternalSystemsListModel.payload?.data || []
    return extList.find(ext => ext.is_blocked === false)
  }

  get isResumeAllEnabled () {
    const extList = this.props.adminExternalSystemsListModel.payload?.data || []
    return extList.find(ext => ext.is_blocked === true)
  }

  handleEditExt = (ext) => {
    modalService.open(AddExtModalWithAudit, {
      edit: true,
      editingExt: ext,
      auditPs: AUDIT_PS.ADMIN,
      auditMessage: `Просмотр формы редактирования внешней системы ${ext.caption}`,
    })
  }

  handleHistoryExt (ext) {
    modalService.open(ShowExtHistoryModalWithAudit, {
      ext: ext,
      auditPs: AUDIT_PS.ADMIN,
      auditMessage: `Просмотр истории изменений внешней системы ${ext.caption}`,
    })
  }

  handleAddExt = () => {
    modalService.open(AddExtModalWithAudit, {
      auditPs: AUDIT_PS.ADMIN,
      auditMessage: 'Просмотр формы добавления внешней системы',
    })
  }

  handleBlockedChange = async (ext, index) => {
    this.setState(prevState => ({
      switchIsLoading: { ...prevState.switchIsLoading, [index]: !prevState.switchIsLoading[index] },
    }))

    const action = await this.props.insertExternalSystem({
      args: {
        id: ext.id,
        caption: ext.caption,
        auth_token: ext.auth_token,
        client_entity_id: ext.client_entity_id,
        ex_system_type_id: ext.ex_system_type_id,
        is_active: ext.is_active,
        is_blocked: !ext.is_blocked,
        reason: ext.reason || 'Blocked',
        subject_id: ext.subject_id,
      },
    })
    this.props.updateExternalSystem(action.payload.data)

    this.setState(prevState => ({
      switchIsLoading: { ...prevState.switchIsLoading, [index]: !prevState.switchIsLoading[index] },
    }))
  }

  suspendAll = async () => {
    await this.props.suspendAllExternalSystems({ args: { reason :'Переход системы в тестовый режим' } })
    this.loadExternalSystemsList()
  }

  resumeAll = async () => {
    await this.props.resumeAllExternalSystems({ args: { reason :'Переход системы в тестовый режим' } })
    this.loadExternalSystemsList()
  }

  render () {
    const { adminExternalSystemsListModel, adminSuspendAllExternalSystemsModel, adminResumeAllExternalSystemsModel } = this.props

    return (
      <div className='root-admin-externals-page'>
        <AppNav
          title='Внешние Системы'
          breadcrumbs={[
            {
              label: 'Администрирование',
              link: URLS.ADMIN__EXTERNALS,
            },
            {
              label: 'Внешние Системы',
              link: URLS.ADMIN__EXTERNALS,
            },
          ]}
        />
        <div className='admin-externals-content'>
          {
            adminExternalSystemsListModel.loading
              ? (
                <div className='admin-externals-content-loader-overlay'>
                  <Loader />
                </div>
              )
              : null
          }
          <div className={cn('admin-externals-content-view', adminExternalSystemsListModel.loaded ? null : 'admin-externals-content-view-loading')}>
            <div className='admin-externals-actions'>
              <div className='admin-externals-actions-left'>
                <ButtonWithAudit
                  label='Приостановить все'
                  onClick={this.suspendAll}
                  disabled={!this.isSuspendAllEnabled}
                  loading={adminSuspendAllExternalSystemsModel.loading}
                  auditPs={AUDIT_PS.ADMIN}
                  auditMessage='Приостановка всех внешних систем'
                />
                <ButtonWithAudit
                  label='Возобновить все'
                  onClick={this.resumeAll}
                  disabled={!this.isResumeAllEnabled}
                  loading={adminResumeAllExternalSystemsModel.loading}
                  auditPs={AUDIT_PS.ADMIN}
                  auditMessage='Возобновление всех внешних систем'
                />
              </div>
              <div className='admin-externals-actions-right'>
                <Button
                  label='Добавить'
                  onClick={this.handleAddExt}
                  icon='plus_cr_fr_white'
                  isSuccess
                />
              </div>
            </div>
            {
              adminExternalSystemsListModel.loaded
                ? (
                  <table>
                    <thead>
                      <tr>
                        <th>Идентификатор ИС</th>
                        <th>Наименование</th>
                        <th>Приостановка</th>
                        <th>Дата блокировки</th>
                        <th>Дата разблокировки</th>
                        <th>Основание</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        adminExternalSystemsListModel.payload.data.map((ext, i) => (
                          <tr key={ext.client_entity_id}>
                            <td>{ext.client_entity_id || '-'}</td>
                            <td>{ext.caption || '-'}</td>
                            <td className='align-center'>
                              <SwitchWithAudit
                                checked={ext.is_blocked}
                                onChange={() => this.handleBlockedChange(ext, i)}
                                loading={this.state.switchIsLoading[i]}
                                auditPs={AUDIT_PS.ADMIN}
                                auditMessage={`${ext.is_blocked ? 'Возобновление' : 'Приостановка'} внешней системы ${ext.caption}`}
                              />
                            </td>
                            <td>{ext.last_blocked_on ? moment(ext.last_blocked_on).format('DD.MM.YYYY HH:mm:ss') : ''}</td>
                            <td>{ext.last_unblocked_on ? moment(ext.last_unblocked_on).format('DD.MM.YYYY HH:mm:ss') : ''}</td>
                            <td>{ext.reason || ''}</td>
                            <td className='actions'>
                              <ActionMenu options={[
                                { label: 'Редактирование', icon: 'edit_green', onClick: () => this.handleEditExt(ext) },
                                { label: 'История изменений', icon: 'history_green', onClick: () => this.handleHistoryExt(ext) },
                              ]}>
                                <Icon
                                  className='external-more-icon'
                                  icon='more_cr_fr'
                                  title='Действия'
                                />
                              </ActionMenu>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                )
                : adminExternalSystemsListModel.error
                  ? <div>Error: {adminExternalSystemsListModel.error}</div>
                  : null
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    adminExternalSystemsListModel: selectAdminExternalSystemsListModel(state),
    adminSuspendAllExternalSystemsModel: selectAdminSuspendAllExternalSystems(state),
    adminResumeAllExternalSystemsModel: selectAdminResumeAllExternalSystems(state),
  }
}

const mapDispatchToProps = dispatch => ({
  loadExternalSystemsList: args => dispatch(loadExternalSystemsList(args)),
  insertExternalSystem: args => dispatch(insertExternalSystem(args)),
  updateExternalSystem: args => dispatch(updateExternalSystem(args)),
  suspendAllExternalSystems: args => dispatch(suspendAllExternalSystems(args)),
  resumeAllExternalSystems: args => dispatch(resumeAllExternalSystems(args)),
})

const AdminExternalsPage = withRouter(connect(mapStateToProps, mapDispatchToProps)(_AdminExternalsPage))
export const AdminExternalsPageWithAudit = withMntAudit(AdminExternalsPage)
