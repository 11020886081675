import { selectDictValidityDegree } from '@src/modules/dictionaries/store'
import { Combobox } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class ValidityDegree extends RegisterColumn {
  static type = REGISTER_COLUMNS.VALIDITY_DEGREE

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Степень обоснованности диагноза',
        component: Combobox,
        props: {
          allowClear: true,
          mode: 'multiple',
          options: selectDictValidityDegree(store.getState()).payload?.map(mo => ({
            label: mo.name,
            value: mo.item_code,
            title: mo.name,
          })),
        },
        defaultValue: [],
      },
      width: 300,
      dataIndex: 'validity_degree',
      dataRender: validity_degree => validity_degree ? validity_degree : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let requestArgs = []

    if (filters?.validity_degree && filters?.validity_degree.length) {
      requestArgs = [{ field: 'validity_degree_code', value: filters.validity_degree, operator: '=', invalid: false, cast: 'right-any' }]
    }

    if (nullSearches[this.type]) {
      requestArgs = [{ field: 'validity_degree_code', operator: '=', value: null, invalid: false }]
    }

    return {
      control: {
        filters: requestArgs,
      },
    }
  }
}
