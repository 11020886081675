import { selectDictMoModel } from '@src/modules/dictionaries/store'
import { Combobox } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class AdvancedNeonatalScreeningLabMoColumn extends RegisterColumn {
  static type = REGISTER_COLUMNS.ADVANCED_NEONATAL_SCREENING_LAB_MO

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Наименование МО лаборатории (РНС)',
        component: Combobox,
        props: {
          allowClear: true,
          mode: 'multiple',
          options: selectDictMoModel(store.getState()).payload.data.map(mo => ({
            label: mo.name_short,
            value: mo.mo_id,
            title: mo.name_full,
          })),
        },
        defaultValue: [],
      },
      width: 300,
      dataIndex: 'advanced_neonatal_screening_lab_mo_name',
      dataRender: advanced_neonatal_screening_lab_mo_name => advanced_neonatal_screening_lab_mo_name ? advanced_neonatal_screening_lab_mo_name : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let requestFilters = []

    if (filters?.advanced_neonatal_screening_lab_mo_oid && filters?.advanced_neonatal_screening_lab_mo_oid.length) {
      requestFilters = [{
        field: 'advanced_neonatal_screening_lab_mo_oid',
        value: filters.advanced_neonatal_screening_lab_mo_oid,
        operator: '=',
        invalid: false,
        cast: 'right-any',
      }]
    }

    if (nullSearches[this.type]) {
      requestFilters = [{ field: this.type, operator: '=', value: null, invalid: false }]
    }

    return {
      control: {
        filters: requestFilters,
      },
    }
  }
}
