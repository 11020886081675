import { initialModel } from '@src/utils'
import * as models from './models'

const reducers = {
  resetImportNsiFrnsiDict: (state) => {
    state[models.IMPORT_NSI_FRNSI_DICT] = initialModel
  },
}

export default reducers
