import { isEqual, merge } from 'lodash'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { AppNav, DataGrid } from '@src/components'
import { NOTIFICATION_TYPES, URLS } from '@src/constants'
import { useMountEffect, useUrlQuery } from '@src/hooks'
import { loadNsiDictList, selectNsiDictListModel } from '@src/modules/nsi/store'
import { pushNotification } from '@src/store'
import { NOOP_FN } from '@src/utils'
import { withMntAudit } from '@src/hoc'

import { columns } from './columns'
import { FrnsiListControls } from './FrnsiListControls'
import { useFilteredList } from './hooks'
import './NsiFrnsiListPage.scss'
import { QueryPackedArgs, QueryUnpackedArgs } from './types'

const NsiFrnsiListPage = () => {
  const [gridValue, setGridValue] = useState<DataGridValue | null>(null)
  const nsiDictListModel = useSelector(selectNsiDictListModel)

  const [query, setQuery] = useUrlQuery(unpackQuery) as [QueryUnpackedArgs, (args: QueryUnpackedArgs) => void]
  const dispatch = useDispatch()
  const history = useHistory()

  useMountEffect(() => {
    dispatch(loadNsiDictList({}))
  })

  const handleGridReset = useCallback((value: DataGridValue | null) => {
    if (!value) return

    const queryValue = {
      filters: {
        identifier: query.identifier,
        full_name: query.full_name,
        state: query.state,
        type: query.type,
      },
      sort: {
        column: query.sort_column,
        order: query.sort_order,
      },
    }

    const valueWithQuery = merge({}, value, queryValue)
    if (!isEqual(valueWithQuery, value)) {
      setGridValue({
        ...valueWithQuery,
        isDirty: true,
      })
    }
  }, [query])

  const handleFilterSortChange = useCallback((value: DataGridValue | null) => {
    if (value) {
      setQuery({
        full_name: value.filters.full_name,
        identifier: value.filters.identifier,
        type: value.filters.type,
        state: value.filters.state,
        sort_column: value.sort.column,
        sort_order: value.sort.order,
      })
    }
  }, [setQuery])

  const handleResetFilters = useCallback(() => {
    setQuery({})
    setGridValue(null)
  }, [setQuery])

  const [filteredTableData, selectedDicts] = useFilteredList(nsiDictListModel, gridValue)

  const openDictPage = useCallback((row: (typeof filteredTableData)[number], inNewTab = false) => {
    if (row.is_local && row.local?.id) {
      const path = `${URLS.NSI__FRNSI__DICT}/${row.local.id}/passport`
      if (inNewTab) {
        window.open(path, '_blank')
      } else {
        history.push(path)
      }
    } else {
      dispatch(pushNotification({
        type: NOTIFICATION_TYPES.WARNING,
        message: `Справочник "${row.full_name}" не импортирован в систему`,
      }))
    }
  }, [dispatch, history])

  const handleRowDoubleClick = useCallback((row: (typeof filteredTableData)[number]) => {
    openDictPage(row)
  }, [openDictPage])

  const handleRowAuxClick = useCallback((row: (typeof filteredTableData)[number]) => {
    openDictPage(row, true)
  }, [openDictPage])

  return <div className='root-nsi-frnsi-page'>
    <AppNav
      title='Справочники ФРНСИ'
      breadcrumbs={[{ label: 'Справочники ФРНСИ', link: URLS.NSI__FRNSI }]}
    />

    <FrnsiListControls
      onResetFilters={handleResetFilters}
      canResetFilters={gridValue?.isDirty}
      selectedDicts={selectedDicts}
      isDictLoading={nsiDictListModel.loading}
      dictCount={filteredTableData.length}
    />

    <DataGrid
      selectable
      value={gridValue}
      columns={columns}
      data={filteredTableData}
      rowKey='identifier'
      onChange={setGridValue}
      loading={nsiDictListModel.loading}
      error={nsiDictListModel.error}
      onRowDoubleClick={handleRowDoubleClick}
      onRowAuxClick={handleRowAuxClick}
      onReset={handleGridReset}
      onFilterChange={handleFilterSortChange}
      onSortChange={handleFilterSortChange}
      onSelectionChange={NOOP_FN}
      pagination={undefined}
      paginationTotal={undefined}
      onPaginationChange={NOOP_FN}
      variableSizeList={undefined}
    />
  </div>
}

const unpackQuery = (args: QueryPackedArgs): QueryUnpackedArgs => {
  return {
    full_name: args.full_name?.[0],
    identifier: args.identifier?.[0],
    type: args.type?.[0] ? args.type[0].split(',') : undefined,
    state: args.state?.[0] ? args.state[0].split(',') : undefined,
    sort_column: args.sort_column?.[0],
    sort_order: args.sort_order?.[0],
  }
}

export const NsiFrnsiListPageWithAudit = withMntAudit(NsiFrnsiListPage)
