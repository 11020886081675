import { Tabs } from 'antd'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useParams } from 'react-router-dom'
import { AppNav, LoadedOrError } from '@src/components'
import { AUDIT_PS, URLS } from '@src/constants'
import { useMountEffect } from '@src/hooks'
import { TabGeneralWithAudit } from '@src/modules/methodology/components/MethRoutesTabs/TabGeneral/TabGeneral'
import { TabRouteDescriptionWithAudit } from '@src/modules/methodology/components/MethRoutesTabs/TabRouteDescription/TabRouteDescription'
import { MethRegConditionsPageWithAudit } from '@src/modules/methodology/pages/MethRegConditionsPage/MethRegConditionsPage'
import {
  loadGraph, loadGraphDescription, loadGraphVersions, resetMethRoutesCardState, selectGraph,
} from '@src/modules/methodology/store'
import './MethRouteCard.scss'

const TABS = [
  TabGeneralWithAudit,
  TabRouteDescriptionWithAudit,
]

const MethRouteCard = () => {
  const { graph_id: id, version: revisionId } = useParams()

  const dispatch = useDispatch()
  const graphCSP = useSelector(selectGraph)

  const graph = graphCSP?.payload?.data?.[0]?.graph || {}

  const tabActiveKey = () => {
    const Tab = TABS.find(Tab => window.location.pathname.endsWith(Tab.tabPath))
    return Tab?.tabPath || TABS[0].tabPath
  }

  useMountEffect(() => {
    dispatch(loadGraphVersions({ args: { graph_id: id } }))

    return () => dispatch(resetMethRoutesCardState())
  })

  useEffect(() => {
    dispatch(loadGraph({ graph_id: id, graph_ext_id: parseInt(revisionId) }))
    dispatch(loadGraphDescription({ graph_id: id, region_graph_id: parseInt(revisionId) }))
  }, [dispatch, id, revisionId])


  return (
    <Switch>
      <Route path={`${URLS.METHODOLOGY__ROUTES}/:graph_id/:version/conditions/:stage_id/:route_state_id`}>
        <MethRegConditionsPageWithAudit
          auditPs={AUDIT_PS.METHOD}
          auditMessage={'Просмотр формы регионального условия'}
        />
      </Route>
      <Route>
        <div className='root-clinrec-data-page'>
          <AppNav title={graph?.graph_caption || '-'}
            breadcrumbs={[
              { label: 'Методология', link: URLS.METHODOLOGY },
              { label: 'Маршруты', link: URLS.METHODOLOGY__ROUTES },
              { label: graph?.graph_caption || '-' },
            ]}
            tabs={TABS.map(tab => ({
              label: tab.tabTitle,
              link: `${URLS.METHODOLOGY__ROUTES}/${id}/${revisionId}/${tab.tabPath}${window.location.search}`,
            }))}
          />

          <LoadedOrError loaded={graphCSP.loaded} loading={graphCSP.loading} error={graphCSP.error}>
            <Tabs
              activeKey={tabActiveKey()}
              renderTabBar={() => null}
              items={TABS.map(TabWithAudit => ({
                key: TabWithAudit.tabPath,
                children: <TabWithAudit
                  auditPs={AUDIT_PS.METHOD}
                  auditMessage={`Просмотр вкладки '${TabWithAudit.tabTitle}' карточки маршрута ${graph?.graph_caption ?? '-'}`}
                />,
              })
              )}
            />
          </LoadedOrError>
        </div>
      </Route>
    </Switch>
  )

}

export default MethRouteCard
