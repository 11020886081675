export const FULLNAME = 'fullname'
export const CHILD_FULLNAME = 'child_fullname'
export const GENDER_CODE = 'gender_code'
export const GENDER_ID = 'gender_id'
export const BIRTH_DATE = 'birth_date'
export const SNILS = 'snils'
export const DIAGNOSIS = 'diagnosis'
export const DIAG_LIST = 'diag_list'
export const STAGE_IMMUNIZATION = 'stage_immunization'
export const MO_NAME = 'mo_name'
export const MO_CHILDBIRTH = 'childbirth_mo_oid'
export const OCC_BEGIN_ON = 'occ_begin_on'
export const CHILDBIRTH_NUMBER_COLUMN = 'childbirth_number'
export const BIRTHWEIGHT_COLUMN = 'birthweight'
export const PATIENT_AGE = 'patient_age'
export const GESTATIONAL_AGE = 'gestational_age'
export const PREGNANCY_OUTCOME = 'pregnancy_outcome'
export const POLICY_ENP = 'policy_enp'
export const CHILDBIRTH_REGION = 'childbirth_region_code'
export const MO_REGION = 'region_mo_code'
export const DAYS_LIFE = 'days_of_life'
export const BLOOD_TRANSFUSIONS = 'blood_transfusions'
export const TRANSFUSIONS_BEGIN_ON = 'transfusions_begin_on'
export const STANDART_NEONATAL_SCREENING_RESULT = 'standart_neonatal_screening_result'
export const STANDART_NEONATAL_SCREENING_RESULT_DATE = 'standart_neonatal_screening_result_date'
export const ADVANCED_NEONATAL_SCREENING_LAB_MO = 'advanced_neonatal_screening_lab_mo_oid'
export const ADVANCED_NEONATAL_SCREENING_RESULT = 'advanced_neonatal_screening_result'
export const ADVANCED_NEONATAL_SCREENING_RESULT_DATE = 'advanced_neonatal_screening_result_date'
export const DISEASES = 'diseases_name'
export const DISCHARGE = 'is_discharge'
export const DEATH_DATE = 'death_date'
export const TMS_LAB_MO = 'tms_lab_mo_oid'
export const TMS_RESULT_DATE = 'tms_result_date'
export const TMS_RESULT = 'tms_result'
export const MGI_LAB_MO = 'mgi_lab_mo_oid'
export const MGI_RESULT_DATE = 'mgi_result_date'
export const MGI_RESULT = 'mgi_result'
export const PATIENT_ID = 'patient_id'
export const MED_BIRTH_CERTIFICATE = 'med_birth_certificate_sn'
export const MED_BIRTH_CERTIFICATE_DATE = 'med_birth_certificate_date'
export const BIOPSY_SAMPLING_TIME = 'biopsy_sampling_time'
export const DUE_DATE = 'due_date'
export const BIRTH_ORDER = 'birth_order'
export const MOTHER_BIRTHDATE = 'mother_birthdate'
export const FIRST_DIAG_BEGIN_ON = 'first_diag_begin_on'
export const EMERGENCY_NOTIFICATION_FLAG = 'emergency_notification_flag'
export const COMPLICATION_DIAG_LIST = 'complication_mkb_code'
export const CONCOMITANT_DIAG_LIST = 'concomitant_mkb_code'
export const LAB_DIAGNOSTICS_LIST = 'has_lab_diagnostics'
export const MEDICATION_LIST = 'has_medication_list'
export const DN_DOC_LIST = 'is_dn'
export const DN_MO_ID = 'mo_name'
export const DN_END_ON = 'dn_end_on'
export const DN_REASON_ID = 'dn_reason'
export const OUTCOME_DATE = 'outcome_date'
export const INFECTION_STATUS = 'infection_status'
export const PATIENT_DEATH_INFO = 'death_date'
export const DEATH_DIAG = 'death_diagn'
export const DEATH_PLACE = 'death_place'
export const LABORATORY_CONFIRMATION_DIAGNOSIS = 'lab_confirmation'
export const NOTIFICATION_KIND = 'notification_kind'
export const DETECTION_PLACE = 'place_of_detection'
export const OUTCOME = 'outcome'
export const DOCUMENTATION_TYPE = 'type_documentation'
export const NOTIFICATION_DATE = 'begin_on'
export const DIAGNOSIS_DATE = 'diagnosis_date'
export const VALIDITY_DEGREE = 'validity_degree'
export const DIAG_DATE = 'diag_date'
