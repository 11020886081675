import { useCallback, useState } from 'react'
import { Input, Form, Alert } from 'antd'
import { isEmpty } from 'lodash'
import { Button, Drawer } from '@src/components'
const { TextArea } = Input

export const AddFoundationDocumentDrawer = ({ cb, data, onClose, ...props }) => {
  const [formAdd] = Form.useForm()
  const [isError, setIsError] = useState()
  const [isBlockSaveBtn, setIsBlockSaveBtn] = useState(true)
  const handleSetBlockSaveBtn = useCallback(value => setIsBlockSaveBtn(value), [])
  const handleSetError = useCallback(value => setIsError(value), [])

  function onChangeInput () {
    const name = formAdd.getFieldValue().document_name
    const number = formAdd.getFieldValue().document_number
    if(isEmpty(name) || isEmpty(number)) {
      handleSetBlockSaveBtn(true)
    } else {
      handleSetBlockSaveBtn(false)
      handleSetError()
    }
  }

  return <Drawer
    className='custom-modal'
    title='Добавление документа-основания'
    width={560}
    onClose={onClose}
    destroyOnClose
    {...props}
    footer={<RecordModalFooter
      cb={cb}
      onClose={onClose}
      form={formAdd}
      data={data}
      handleSetError={handleSetError}
      isBlockSaveBtn={isBlockSaveBtn}
      handleSetBlockSaveBtn={handleSetBlockSaveBtn}
    />}
  >
    <Form
      name='DocumentFoundationForm'
      form={formAdd}
      layout='vertical'
    >
      <Form.Item name='document_name' label='Наименование регионального НПА'>
        <TextArea
          rows={4}
          placeholder='Введите'
          onChange={onChangeInput}
          allowClear
        />
      </Form.Item>
      <Form.Item name='document_number' label='Номер регионального НПА'>
        <Input
          placeholder='Введите'
          allowClear
          onChange={onChangeInput}
        />
      </Form.Item>
    </Form>
    {isError &&
      <Alert type='error' showIcon description={isError}></Alert>
    }

  </Drawer>
}

const RecordModalFooter = ({ cb, onClose, form, data, handleSetError, isBlockSaveBtn, handleSetBlockSaveBtn }) => {
  function checkIsSuchData (values) {
    let result = true
    data.forEach((element) => {
      if((element.document_name === values.document_name
        || element.document_number === values.document_number)) {
        result = false
      }
    })
    return result
  }

  const handleBtn = (e, val) => {
    if(!val)
      return onClose()

    const formValues = form.getFieldsValue()

    if(isEmpty(formValues.document_name) || isEmpty(formValues.document_number)) {
      handleSetError('Поля обязательны для заполнения')
      handleSetBlockSaveBtn(true)
      return
    }
    if(checkIsSuchData(formValues)) {
      handleSetError()
      cb(val, formValues)
      onClose()
    } else {
      handleSetError('Такой НПА уже добавлен. Сохранение невозможно.')
      handleSetBlockSaveBtn(true)
    }
  }

  return <div
    className='buttons-wrapper'
    style={{ display: 'flex', justifyContent: 'right' }}
  >
    <Button
      className='mr-8'
      label='Отмена'
      onClick={e => handleBtn(e, false)}
    />
    <Button
      label='Добавить'
      icon='check_cr_fr_white'
      onClick={e => handleBtn(e, true)}
      disabled={isBlockSaveBtn}
      isSuccess

    />
  </div>
}
