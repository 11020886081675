import { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { icons } from '@src/assets'
import './SortIcon.scss'

export default class SortIcon extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    sortField: PropTypes.string,
    currentSortField: PropTypes.string,
    sortType: PropTypes.bool,           // TODO: sortType некорректное название. Должно быть либо sortAsc, либо тип PropTypes.oneOf([...SORT_ORDER])
  }

  render () {
    const { sortField, currentSortField, sortType } = this.props
    const SVG = icons[sortField === currentSortField ? (sortType ? 'sort_asc' : 'sort_desc') : 'sort_regular']

    return (
      <SVG
        className={cn('root-sort-icon', this.props.className)}
        onClick={this.props.onClick}
      />
    )
  }
}
