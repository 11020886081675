import { Button, Modal } from '@src/components'
import { withMntAudit } from '@src/hoc'
import './SaveDocBeforeExitModal.scss'

const SaveDocModal = ({ typeChange, valueInput, cb, onClose, ...props }) => {

  const title = 'Изменения небыли сохранены, вернуться чтобы сохранить?'

  return <Modal
    className='custom-modal'
    title={title}
    {...props}
    width={560}
    onClose={onClose}
    footer={<RecordModalFooter cb={cb} onClose={onClose} typeChange={typeChange} valueInput={valueInput}/>}
  >

  </Modal>
}

export const SaveDocBeforeExitModal = withMntAudit(SaveDocModal)

const RecordModalFooter = ({ cb, onClose, typeChange, valueInput }) => {
  const handleBtn = (e, val) => {
    onClose()
    return cb(val, typeChange, valueInput)
  }

  return <div
    className='buttons-wrapper'
    style={{ display: 'flex', justifyContent: 'right' }}
  >
    <Button
      className='mr-8'
      label='Отмена'
      onClick={e => handleBtn(e, false)}
    />
    <Button
      label='Сохранить'
      icon='check_cr_fr_white'
      onClick={e => handleBtn(e, true)}
      isSuccess

    />
  </div>
}
