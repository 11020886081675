import { TAG_COLORS } from './tagColors'

export const DEVIATION_KINDS = {
  POMP: 'Отклонения по порядку',
  PGG: 'Отклонения по ПГГ',
  KR: 'Отклонение по КР',
}

export const DEVIATION_KIND_CODES = {
  POMP: 1,
  PGG: 2,
  KR: 3,
}

export const deviationKindToTagColor = (deviation_kind) => {
  switch (deviation_kind) {
  case DEVIATION_KINDS.POMP: return TAG_COLORS.LIGHT_PINK
  case DEVIATION_KINDS.PGG: return TAG_COLORS.LIGHT_PURPLE
  case DEVIATION_KINDS.KR: return TAG_COLORS.LIGHT_BEIGE
  default: return TAG_COLORS.GREEN_TEA
  }
}

export const deviationKindToText = (deviation_kind) => {
  switch (deviation_kind) {
  case DEVIATION_KIND_CODES.POMP: return DEVIATION_KINDS.POMP
  case DEVIATION_KIND_CODES.PGG: return DEVIATION_KINDS.PGG
  case DEVIATION_KIND_CODES.KR: return DEVIATION_KINDS.KR
  default: return 0
  }
}
