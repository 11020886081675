import { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { MultiIcon } from '@src/components'
import { withAcl } from '@src/hoc'
import './SidebarMenuEntry.scss'

class SidebarMenuEntry extends Component {
  static propTypes = {
    entry: PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string,
      children: PropTypes.array,
      enabled: PropTypes.bool,
    }),
    onClick: PropTypes.func,
  }

  isEntryActive = (entry) => {
    const path = window.location.pathname
    return path.startsWith(entry.to)
      || (entry.children && entry.children.find(c => path.startsWith(c.to)))
  }

  render () {
    const { entry, onClick } = this.props

    return (
      entry.enabled
        ? (

          <div
            className={cn('sidebar-menu-entry', this.isEntryActive(entry) ? 'active' : null)}
            onClick={onClick}
            title={entry.label}
          >
            <MultiIcon
              className='sidebar-menu-multiicon'
              icon={entry.icon}
              active={!!this.isEntryActive(entry)}
            />
            <div className={cn('sidebar-menu-label', this.isEntryActive(entry) ? 'active' : null)}>{entry.label}</div>
            {
              entry.children.length
                ? <div className='sidebar-menu-chevron'>›</div>
                : null
            }
          </div>)
        : null
    )
  }
}

export default withAcl(SidebarMenuEntry)
