import { isEmpty, merge } from 'lodash'
import { concatObjects } from '@src/utils'

export class RegisterColumn {
  constructor (column, settings = {}) {
    this.type = this.constructor.type
    this.column = merge(column, settings.column)

    if (settings.requestArgs) {
      this._requestArgs = settings.requestArgs
    }
  }

  requestSorts ({ sort }) {
    return {
      control: {
        sorts: !isEmpty(sort) && sort.column === this.type ? [{ field: sort.column, sort: sort.order }] : [],
      },
    }
  }

  requestArgs (...args) {
    return concatObjects(this.requestSorts(...args), this._requestArgs(...args))
  }
}
