import { mapDataWithMeta, thunkCreator } from '@src/utils'
import * as models from './models'

export const [
  loadJournalSemdList, loadJournalSemdListExtra,
] = thunkCreator(models.JOURNAL_SEMD_LIST, '/journal/incoming/get_list', {
  extractor: d => ({
    data: mapDataWithMeta(d),
    dataLength: d?.dataLength,
  }),
})

export const [
  loadJournalMessageAttributes, loadJournalMessageAttributesExtra,
] = thunkCreator(models.JOURNAL_MESSAGE_ATTRIBUTES, '/journal/casdetail/get_attributes')

export const [
  loadJournalOutgoingMessage, loadJournalOutgoingMessageExtra,
] = thunkCreator(models.JOURNAL_OUTGOING_MESSAGE, '/journal/casdetail/get_out_msg')

export const [
  loadJournalProcessingLog, loadJournalProcessingLogExtra,
] = thunkCreator(models.JOURNAL_PROCESSING_LOG, '/journal/casdetail/get_processing')

export const [
  loadJournalMsgIndex, loadJournalMsgIndexExtra,
] = thunkCreator(models.JOURNAL_MSG_INDEX, '/journal/cass/get_by_id')

export const [
  loadExtSystemsList, loadExtSystemsListExtra,
] = thunkCreator(models.JOURNAL_EXT_SYSTEMS_LIST, '/admin/extsystems/all')

export const [
  loadJournalAuditList, loadJournalAuditListExtra,
] = thunkCreator(models.JOURNAL_AUDIT_LIST, '/journal/audit/list')

export const [
  loadSemdDocTypeList, loadSemdDocTypeListExtra,
] = thunkCreator(models.JOURNAL_SEMD_DOC_TYPE_LIST, '/storage/sms_logs/doc_types', { method: 'GET' })

export const [
  loadSemdStatusList, loadSemdStatusListExtra,
] = thunkCreator(models.JOURNAL_SEMD_STATUS_LIST, '/storage/sms_logs/message_statuses', { method: 'GET' })

export const [
  writeAuditLog, writeAuditLogExtra,
] = thunkCreator(models.JOURNAL_AUDIT_WRITE, '/journal/audit/write', { exclusive: false })

const extraReducers = {
  ...loadJournalSemdListExtra,
  ...loadJournalAuditListExtra,
  ...loadJournalMessageAttributesExtra,
  ...loadJournalOutgoingMessageExtra,
  ...loadJournalProcessingLogExtra,
  ...loadJournalMsgIndexExtra,
  ...loadExtSystemsListExtra,
  ...loadSemdDocTypeListExtra,
  ...loadSemdStatusListExtra,
  ...writeAuditLogExtra,
}

export default extraReducers
