import { isArray } from 'lodash'
import { Mkb10InputWithState } from '@src/modules/monitoring/components/Mkb10Input/Mkb10InputWithState'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class CompilicationDiagList extends RegisterColumn {
  static type = REGISTER_COLUMNS.COMPLICATION_DIAG_LIST

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Осложнение основного заболевания',
        component: Mkb10InputWithState,
        defaultValue: [],
      },
      width: 300,
      dataIndex: 'complication_mkb_code',
      dataRender: complication_mkb_code => isArray(complication_mkb_code) ? complication_mkb_code.join(', ') : '-',
      sort: true,
      nullSearch: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters, nullSearches }) {
    let complication_mkb_code = filters?.[this.type]?.length ? filters?.[this.type] : undefined

    if (nullSearches[this.type]) complication_mkb_code = null

    return {
      args: {
        complication_mkb_code,
      },
    }
  }
}
