import { pushNotification } from '@src/store'

export const refreshJwtMiddleware = () => next => (action) => {
  return next(action)
}

export const notificationsMiddleware = ({ dispatch }) => next => (action) => {
  if (action.type.endsWith('/rejected') && !action.meta.canceled) {
    console.error('NotificationsMiddleware', action)
    dispatch(pushNotification({ type: 'error', message: JSON.stringify(action.payload) }))
  }

  return next(action)
}
