import { Route, Switch } from 'react-router-dom'
import { AUDIT_PS, URLS } from '@src/constants'
import { withMntAudit } from '@src/hoc'
import { InternalError404 } from '@src/components'
import { AdminRolesPageRolesWithAudit } from './AdminRolesPageRoles/AdminRolesPageRoles'
import { AdminRolesPageTableWithAudit } from './AdminRolesPageTable/AdminRolesPageTable'

const AdminRolesPage = () => {
  return (
    <Switch>
      <Route path={`${URLS.ADMIN__ROLES__ROLE}/:roleId`}>
        <AdminRolesPageRolesWithAudit
          auditPs={AUDIT_PS.ADMIN}
          auditMessage='Просмотр ресурсов роли'
        />
      </Route>
      <Route path={URLS.ADMIN__ROLES}>
        <AdminRolesPageTableWithAudit />
      </Route>
      <Route component={InternalError404}/>
    </Switch>
  )
}

export const AdminRolesPageWithAudit = withMntAudit(AdminRolesPage)
