import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import { loadUser, selectUser, selectCurrentRole, selectLoadUserModel, selectAppSettingsModel, getAppSettings } from '@src/store'
import {
  loadDictResponseLevelList,
  selectDictResponseLevelListModel,
  loadDictGenders,
  selectDictGendersModel,
  loadDictImmunization,
  selectDictImmunizationModel,
} from '@src/modules/dictionaries/store'
import { AppHeader, AppSidebar, AppModalStack, AppNotificationStack, LoadedOrError, InternalError403, InternalError404 } from '@src/components'
import { ACLS, URLS } from '@src/constants'
import { useLoadModels, useMountEffect } from '@src/hooks'
import { MethodologyPageWithAcl } from '@src/modules/methodology'
import { MonitoringPageWithAcl } from '@src/modules/monitoring'
import { ReportsPageWithAcl } from '@src/modules/analytics'
import { JournalPageWithAcl } from '@src/modules/journal'
import { AdminPageWithAcl } from '@src/modules/admin'
import { NsiPageWithAcl } from '@src/modules/nsi'
import { withMntAudit } from '@src/hoc'
import config from '@src/config'

import { AboutPageWithAcl } from '../AboutPage/AboutPage'
import './MainPage.scss'

const MainPage = () => {
  const user = useSelector(selectUser)
  const currentRole = useSelector(selectCurrentRole)
  const history = useHistory()

  const [loaded, loading, error] = useLoadModels(
    [useSelector(selectLoadUserModel), loadUser()],
    [useSelector(selectDictResponseLevelListModel), loadDictResponseLevelList()],
    [useSelector(selectDictGendersModel), loadDictGenders()],
    [useSelector(selectDictImmunizationModel), loadDictImmunization()],
    [useSelector(selectAppSettingsModel), getAppSettings({ subsystem_code: 'general' })],
  )

  useMountEffect(() => {
    const query = new URLSearchParams(history.location.search)
    query.delete('code')

    history.replace({
      pathname: history.location.pathname,
      search: query.toString(),
    })
  })

  return <LoadedOrError loaded={loaded} loading={loading} error={error}>
    <div className='main-page-root'>
      <AppHeader />
      <AppSidebar />
      <AppModalStack />
      <AppNotificationStack />
      <div className='main-page-view'>
        {
          user
            ? (
              <Switch>
                <Route path={URLS.MONITORING}>
                  <MonitoringPageWithAcl
                    allowAcl={ACLS.DESKTOP_MAINMENU_PATIENTMON_SHOW}
                    deniedComponent={<InternalError403 />}
                  />
                </Route>
                {
                  config?.app?.modules?.enabled?.methodology
                    ? <Route path={URLS.METHODOLOGY}>
                      <MethodologyPageWithAcl
                        allowAcl={ACLS.METHOD_ACCESS}
                        deniedComponent={<InternalError403 />}
                      />
                    </Route>
                    : null
                }
                <Route path={URLS.ANALYTICS__REPORTS}>
                  <ReportsPageWithAcl
                    allowAcl={ACLS.DESKTOP_MAINMENU_ANALYZE_MONITORING_SHOW}
                    deniedComponent={<InternalError403 />}
                  />
                </Route>
                <Route path={URLS.JOURNAL}>
                  <JournalPageWithAcl
                    allowAcl={ACLS.DESKTOP_MAINMENU_ADM_SHOW}
                    deniedComponent={<InternalError403 />}
                  />
                </Route>
                <Route path={URLS.ADMIN}>
                  <AdminPageWithAcl
                    allowAcl={ACLS.DESKTOP_MAINMENU_ADM_SHOW}
                    deniedComponent={<InternalError403 />}
                  />
                </Route>
                <Route path={URLS.NSI}>
                  <NsiPageWithAcl
                    allowAcl={ACLS.DESKTOP_MAINMENU_PSI_SHOW}
                    deniedComponent={<InternalError403 />}
                  />
                </Route>
                <Route path={URLS.ABOUT}>
                  <AboutPageWithAcl
                    allowAcl={ACLS.DESKTOP_MAINMENU_ADM_SHOW}
                    deniedComponent={<InternalError403 />}
                  />
                </Route>
                <Route exact path='/'>
                  {
                    currentRole?.main_page
                      ? <Redirect to={currentRole.main_page}/>
                      : null
                  }
                </Route>
                <Route component={InternalError404}/>
              </Switch>
            )
            : null
        }
      </div>
    </div>
  </LoadedOrError>
}

export const MainPageWithAudit = withMntAudit(MainPage)
