import { Combobox } from '@src/components'
import { REGISTER_COLUMNS } from '@src/constants'
import { RegisterColumn } from './RegisterColumn'

export class EmergencyNotificationFlag extends RegisterColumn {
  static type = REGISTER_COLUMNS.EMERGENCY_NOTIFICATION_FLAG

  constructor (store, settings) {
    const column = {
      header: {
        title: 'Экстренное извещение',
        component: Combobox,
        props: {
          allowClear: true,
          options: [
            {
              label: 'Есть',
              value: true,
            },
            {
              label: 'Нет',
              value: false,
            },
          ],
        },
      },
      width: 200,
      dataIndex: 'emergency_notification_flag',
      dataRender: emergency_notification_flag => typeof emergency_notification_flag === 'boolean' ? emergency_notification_flag ? 'Есть' : 'Нет' : '-',
      sort: true,
    }

    super(column, settings)
  }

  _requestArgs ({ filters }) {
    return {
      control: {
        filters: typeof filters.emergency_notification_flag === 'boolean'
          ? [{
            field: 'emergency_notification_flag',
            value: String(filters.emergency_notification_flag),
            operator: '=',
            invalid: false,
            fieldtype: 'TS',
          }]
          : [],
      },
    }
  }
}
